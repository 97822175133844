import Reports from '../../api/Reports'
import Operations from '../../api/Operations'
import { StwOperation } from '../../api'
import { __ } from '../../shared/i18n'

export type HomepagePath = {
  id: string
  description: string
  path: string
}

export const STATIC_PATHS: HomepagePath[] = [
  {
    id: 'places',
    description: __('menu.places'),
    path: '/configuration/places',
  },
  {
    id: 'zones',
    description: __('menu.zones'),
    path: '/configuration/zones',
  },
  {
    id: 'users',
    description: __('menu.users'),
    path: '/configuration/usersRoles/users',
  },
  {
    id: 'capabilitiesGroup',
    description: __('menu.capabilitiesGroup'),
    path: '/configuration/usersRoles/capabilitiesGroup',
  },
  {
    id: 'roles',
    description: __('menu.roles'),
    path: '/configuration/usersRoles/roles',
  },
  {
    id: 'roleMappings',
    description: __('menu.roleMappings'),
    path: '/configuration/usersRoles/roleMappings',
  },
  {
    id: 'operations',
    description: __('menu.operations'),
    path: '/configuration/settings/operations',
  },
  {
    id: 'itemConfigurations',
    description: __('menu.itemConfigurations'),
    path: '/configuration/settings/itemConfigurations',
  },
  {
    id: 'enumerationTypes',
    description: __('menu.enumerationTypes'),
    path: '/configuration/usersRoles/enumerations/types',
  },
  {
    id: 'enumerationValues',
    description: __('menu.enumerationValues'),
    path: '/configuration/usersRoles/enumerations/values',
  },
  {
    id: 'rfidReaders',
    description: __('menu.rfidReaders'),
    path: '/devices/rfidReaders',
  },
  {
    id: 'antennas',
    description: __('menu.antennas'),
    path: '/devices/antennas',
  },
  {
    id: 'barcodeReaders',
    description: __('menu.barcodeReaders'),
    path: '/devices/barcodeReaders',
  },
  {
    id: 'controllers',
    description: __('menu.deviceControllers'),
    path: '/devices/controllers',
  },
  {
    id: 'xspans',
    description: __('menu.xspans'),
    path: '/devices/xspans',
  },
  {
    id: 'scanners',
    description: __('menu.scanners'),
    path: '/devices/scanners',
  },
  {
    id: 'printers',
    description: __('menu.printers'),
    path: '/devices/printers',
  },
  {
    id: 'balances',
    description: __('menu.balances'),
    path: '/devices/balances',
  },
  // {
  //   id: 'conveyors',
  //   description: __('menu.conveyors'),
  //   path: '/'
  // },
  {
    id: 'workstations',
    description: __('menu.workstations'),
    path: '/devices/workstations',
  },
]

export class HomepageUtility {
  public static getPagePathById = async (id: string) => {
    const paths = await HomepageUtility.getPagePaths()
    return paths.find((path) => path.id === id)
  }

  public static getPagePaths = async () => {
    const paths = await Promise.all([HomepageUtility.getReportsPaths(), HomepageUtility.getOperationLogsPaths()])

    return [...STATIC_PATHS, ...paths[0], ...paths[1]]
  }

  private static async getReportsPaths(): Promise<HomepagePath[]> {
    try {
      const paths: HomepagePath[] = []

      const reports = await Reports.reports()

      reports.forEach((report) =>
        paths.push({
          id: report.id,
          description: report.description,
          path: `/reports/${report.id}`,
        })
      )

      return paths
    } catch (error) {
      return []
    }
  }

  private static async getOperationLogsPaths(): Promise<HomepagePath[]> {
    const paths: HomepagePath[] = []
    const operations = await Operations.search<StwOperation>({ enabled: true })
    if (operations && operations.content) {
      operations.content.forEach((operation) =>
        paths.push({
          id: `${operation.type}-${operation.id}`,
          description: operation.description!,
          path: `/operationlogs/${operation.type}/${operation.id}`,
        })
      )
    }
    return paths
  }
}
