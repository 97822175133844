import { DetailCell, StwChecklistType } from 'core/api'

/* eslint-disable no-template-curly-in-string */
export const DEFAULT_PAGE_SIZE = 25

export const PRODUCT_IMAGE_LIST_SIZE = 'small'
export const PRODUCT_IMAGE_DETAIL_SIZE = 'large'
export const MAX_SIZE_LIMIT = 2147483640

export const ENABLED_TABLE_COLUMN_FILTER = true

export const SkeltonAttribute: DetailCell[] = [
  { attribute: 'skeleton0', columns: 8 },
  { attribute: 'skeleton1', columns: 8 },
  { attribute: 'skeleton2', columns: 8 },
]

export const SkeletonAttributeReport: DetailCell[] = [
  { attribute: 'skeleton0', columns: 8 },
  { attribute: 'skeleton1', columns: 8 },
  { attribute: 'skeleton2', columns: 8 },
  { attribute: 'skeleton3', columns: 8 },
  { attribute: 'skeleton4', columns: 8 },
  { attribute: 'skeleton5', columns: 8 },
  { attribute: 'skeleton6', columns: 8 },
  { attribute: 'skeleton7', columns: 8 },
  { attribute: 'skeleton8', columns: 8 },
  { attribute: 'skeleton9', columns: 8 },
  { attribute: 'skeleton10', columns: 8 },
  { attribute: 'skeleton11', columns: 8 },
  { attribute: 'skeleton12', columns: 8 },
  { attribute: 'skeleton13', columns: 8 },
  { attribute: 'skeleton14', columns: 8 },
]

export const FormValidateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
}

export const ZoneTypes = [
  {
    id: 'STOCK',
    description: 'STOCK',
  },
  {
    id: 'LOST',
    description: 'LOST',
  },
  {
    id: 'IN_TRANSIT',
    description: 'IN_TRANSIT',
  },
  {
    id: 'SOLD',
    description: 'SOLD',
  },
]

export const PlaceTypes = [
  {
    id: 'SUPPLIER',
    description: 'SUPPLIER',
  },
  {
    id: 'WAREHOUSE',
    description: 'WAREHOUSE',
  },
  {
    id: 'STORE',
    description: 'STORE',
  },
  {
    id: 'CUSTOMER',
    description: 'CUSTOMER',
  },
]

export const CheckListTypes = [
  {
    id: StwChecklistType.CHECKLIST_BY_ITEM,
    description: StwChecklistType.CHECKLIST_BY_ITEM,
  },
  {
    id: StwChecklistType.CHECKLIST_BY_PRODUCT_TYPE,
    description: StwChecklistType.CHECKLIST_BY_PRODUCT_TYPE,
  },
]
export const IdentifierTypes = [
  {
    id: 'UHF_TAG',
    description: 'UHF_TAG',
  },
  {
    id: 'NFC_TAG',
    description: 'NFC_TAG',
  },
  {
    id: 'SIMPLE_ITEM_IDENTIFIER',
    description: 'SIMPLE_ITEM_IDENTIFIER',
  },
]

export const OperationTypes = [
  {
    id: 'ENCODING',
    description: 'ENCODING',
  },
  {
    id: 'TRANSFER_TO_ZONE',
    description: 'TRANSFER_TO_ZONE',
  },
  {
    id: 'INBOUND',
    description: 'INBOUND',
  },
  {
    id: 'OUTBOUND',
    description: 'OUTBOUND',
  },
  {
    id: 'INVENTORY',
    description: 'INVENTORY',
  },
  {
    id: 'INVENTORY_PARCEL',
    description: 'INVENTORY_PARCEL',
  },
  {
    id: 'TAG_INFO',
    description: 'TAG_INFO',
  },
  {
    id: 'SIMPLE_ITEM_LIST',
    description: 'SIMPLE_ITEM_LIST',
  },
  {
    id: 'SIMPLE_PARCEL_LIST',
    description: 'SIMPLE_PARCEL_LIST',
  },
  {
    id: 'CYCLE_COUNT',
    description: 'CYCLE_COUNT',
  },
  {
    id: 'PRINT',
    description: 'PRINT',
  },
  {
    id: 'PHASETO_PHASE',
    description: 'PHASETO_PHASE',
  },
  {
    id: 'PACKING',
    description: 'PACKING',
  },
  {
    id: 'SORTING',
    description: 'SORTING',
  },
  {
    id: 'PICKING',
    description: 'PICKING',
  },
  {
    id: 'SIMPLE_ITEM_SESSION_LIST',
    description: 'SIMPLE_ITEM_SESSION_LIST',
  },
]

export const BooleanComboValues = [
  {
    id: 'TRUE',
    description: 'YES',
  },
  {
    id: 'FALSE',
    description: 'NO',
  },
]

export const BooleanComboEnabledValues = [
  {
    id: 'TRUE',
    description: 'Abilitata',
  },
  {
    id: 'FALSE',
    description: 'Disabilitata',
  },
]

export const xspanDeviceControllerTypeFilters = {
  type: 'XSPAN_MANAGER',
}

export const XspanModes = [
  {
    id: 'HIGH_PERFORMANCE',
    description: 'HIGH_PERFORMANCE',
  },
  {
    id: 'HIGH_SENSITIVITY',
    description: 'HIGH_SENSITIVITY',
  },
]

export const XspanFieldsOfView = [
  {
    id: 'WIDE',
    description: 'WIDE',
  },
  {
    id: 'NARROW',
    description: 'NARROW',
  },
  {
    id: 'AUTO',
    description: 'AUTO',
  },
]

export const scannerDeviceControllerTypeFilters = {
  type: 'SCANNER_MANAGER',
}

export const scannerOperationFilters = {
  types: ['TRANSFER_TO_ZONE', 'SIMPLE_ITEM_LIST'],
  clients: ['scanner'],
}

export const XspanOperationFilters = {
  types: ['TRANSFER_TO_ZONE', 'SIMPLE_ITEM_LIST'],
  clients: ['xspan'],
}

export const printerDeviceControllerTypeFilters = {
  type: 'PRINTER_MANAGER',
}

export const PrinterTypes = [
  {
    id: 'zebra-card-printer',
    description: 'Zebra Card Printer',
  },
  {
    id: 'zebra',
    description: 'Zebra',
  },
  {
    id: 'epson',
    description: 'Epson',
  },
  {
    id: 'avery',
    description: 'Avery',
  },
]

export const BarcodeReaderType = [
  {
    id: 'DATALOGIC_BARCODE_READER',
    description: 'Datalogic Barcode Reader',
  },
]

export const BalanceType = [
  {
    id: 'STANDARD',
    description: 'Standard',
  },
]

export const WorkstationDeviceManagerUrlType = [
  {
    id: 'http:',
    description: 'HTTP://',
  },
  {
    id: 'https:',
    description: 'HTTPS://',
  },
]

export const WorkstationDeviceManagerWebSocketType = [
  {
    id: 'ws:',
    description: 'WS://',
  },
  {
    id: 'wss:',
    description: 'WSS://',
  },
]

export const RfidReaderTypes = [
  {
    id: 'caen-slate',
    description: 'Caen HEX',
  },
  {
    id: 'nordic-id-sampo-native',
    description: 'Nordic ID Sampo Native',
  },
  {
    id: 'kit-nlo-labid',
    description: 'kit NLO Labid',
  },
  {
    id: 'feig-nfc',
    description: 'Feig NFC',
  },
  {
    id: 'feig-cpr74',
    description: 'Feig CPR74 (USB)',
  },
  {
    id: 'impinj-xarray',
    description: 'Inpinj XArray',
  },
  {
    id: 'nordic-id-sampo-labid',
    description: 'Nordic ID Sampo Labid',
  },
  {
    id: 'impinj-speedway-octane',
    description: 'Impinj Speedway Octane',
  },
  {
    id: 'zebra-fx-series',
    description: 'Zebra FX Series',
  },
  {
    id: 'caen-quattro',
    description: 'Caen Quattro',
  },
  {
    id: 'rfid-emulator',
    description: 'Emulatore Rfid',
  },
  {
    id: 'sensormatic',
    description: 'Sensormatic',
  }
]

export const RfidReaderConnectionModes = [
  {
    id: 'ETHERNET',
    description: 'ETHERNET',
  },
  {
    id: 'USB',
    description: 'USB',
  },
]

export const RfidReaderReaderModes = [
  {
    id: 'AUTO_SET_DENSE_READER',
    description: 'AUTO_SET_DENSE_READER',
  },
  {
    id: 'AUTO_SET_DENSE_READER_DEEP_SCAN',
    description: 'AUTO_SET_DENSE_READER_DEEP_SCAN',
  },
  {
    id: 'DENSE_READER_M4',
    description: 'DENSE_READER_M4',
  },
  {
    id: 'DENSE_READER_M4_TWO',
    description: 'DENSE_READER_M4_TWO',
  },
  {
    id: 'DENSE_READER_M8',
    description: 'DENSE_READER_M8',
  },
  {
    id: 'HYBRID',
    description: 'HYBRID',
  },
  {
    id: 'MAX_MILLER',
    description: 'MAX_MILLER',
  },
  {
    id: 'MAX_THROUGHPUT',
    description: 'MAX_THROUGHPUT',
  },
]

export const RfidReaderSearchModes = [
  {
    id: 'DUAL_TARGET',
    description: 'DUAL_TARGET',
  },
  {
    id: 'SINGLE_TARGET',
    description: 'SINGLE_TARGET',
  },
]

export const RfidReaderSession = [
  {
    id: '0',
    description: '0',
  },
  {
    id: '1',
    description: '1',
  },
  {
    id: '2',
    description: '2',
  },
  {
    id: '3',
    description: '3',
  },
]

export const RfidReaderStartStopTypes = [
  {
    id: 'FALLING',
    description: 'FALLING',
  },
  {
    id: 'RISING',
    description: 'RISING',
  },
]

export const RfidReaderTriggerTimeoutSelectValues = [
  {
    id: 'triggerEnabled',
    description: 'Trigger enabled',
  },
  {
    id: 'timeoutEnabled',
    description: 'Timeout enabled',
  },
]

export const RfidReaderGpoModes = [
  {
    id: 'NORMAL',
    description: 'NORMAL',
  },
  {
    id: 'PULSED',
    description: 'PULSED',
  },
  {
    id: 'READER_OPERATIONAL_STATUS',
    description: 'READER_OPERATIONAL_STATUS',
  },
  {
    id: 'LLRP_CONNECTION_STATUS',
    description: 'LLRP_CONNECTION_STATUS',
  },
  {
    id: 'READER_INVENTORY_STATUS',
    description: 'READER_INVENTORY_STATUS',
  },
  {
    id: 'NETWORK_CONNECTION_STATUS',
    description: 'NETWORK_CONNECTION_STATUS',
  },
  {
    id: 'READER_INVENTORY_TAGS_STATUS',
    description: 'READER_INVENTORY_TAGS_STATUS',
  },
]

export const DeviceControllerTypes = [
  {
    id: 'PRINTER_MANAGER',
    description: 'Printer Manager',
  },
  {
    id: 'SCANNER_MANAGER',
    description: 'Scanner Manager',
  },
  {
    id: 'CONVEYOR_MANAGER',
    description: 'Conveyor Manager',
  },
  {
    id: 'XSPAN_MANAGER',
    description: 'Xspan Manager',
  },
]

export const CapabilitiesGroupFeatureTypes = [
  {
    id: 'OPERATION',
    description: 'Operation',
  },
  {
    id: 'REPORT',
    description: 'Report',
  },
  {
    id: 'SYSTEM',
    description: 'System',
  },
  {
    id: 'CONFIGURATION',
    description: 'Configuration',
  },
]

export const rfidAntennaZoneFilter = {
  type: ['STOCK'],
}

export const ScalesTypes = [
  {
    id: 'standard',
    description: 'Standard',
  },
]

export const OperationClients = [
  {
    id: 'station',
    description: 'station',
  },
  {
    id: 'mobile',
    description: 'mobile',
  },
  {
    id: 'xspan',
    description: 'xspan',
  },
  {
    id: 'scanner',
    description: 'scanner',
  },
  {
    id: 'conveyor',
    description: 'conveyor',
  },
]

export const PickingFlowDiscriminators = [
  {
    id: 'identifier',
    description: 'identifier',
  },
  {
    id: 'product',
    description: 'product',
  },
  {
    id: 'model',
    description: 'model',
  },
]

export const ApplicationClients = [
  {
    id: 'station',
    description: 'station',
  },
  {
    id: 'handheld',
    description: 'handheld',
  },
  {
    id: 'xspan',
    description: 'xspan',
  },
  {
    id: 'scanner',
    description: 'scanner',
  },
  {
    id: 'conveyor',
    description: 'conveyor',
  },
  {
    id: 'controlpanel',
    description: 'control panel',
  },
]

export const ApplicationTypes = [
  {
    id: 'SYSTEM',
    description: 'SYSTEM',
  },
  {
    id: 'CLIENT',
    description: 'CLIENT',
  },
  {
    id: 'ALL',
    description: 'ALL',
  },
]

export const RfidReaderEmulationModeSelectValues = [
  {
    id: 'RANDOM_EPC',
    description: 'Random EPC',
  },
  {
    id: 'EPC_LIST',
    description: 'EPC list',
  },
]

export const RfidReaderReaderMemoryBanks = [
  {
    id: 'EPC',
    description: 'EPC',
  },
  {
    id: 'TID',
    description: 'TID',
  },
]

export const ConveyorsOperationFilters = {
  //types: [],
  clients: ['conveyor'],
}

export const TemplateTypeValues = [
  {
    id: 'FREEMARKER',
    description: 'FREEMARKER',
  },
  {
    id: 'FREEMARKER_FOP',
    description: 'FREEMARKER_FOP',
  },
]

export const TemplateOutputTypeValues = [
  {
    id: 'PDF',
    description: 'PDF',
  },
  {
    id: 'CSV',
    description: 'CSV',
  },
  {
    id: 'PLAIN_TEXT',
    description: 'PLAIN_TEXT',
  },
]
