import { Component } from 'react'
import { Space } from 'antd'
import { StwPlace } from '../../api'

interface Props {
  record: any
  showDetail?: (...args: any[]) => any
}

export class AdvancedTableRowColumnUserPlaces extends Component<Props> {
  render() {
    const { record, showDetail } = this.props
    const userPlaces = record.userPlaces ?? []
    return (
      <Space
        style={{ display: 'block', cursor: userPlaces.length > 3 ? 'pointer' : 'auto' }}
        onClick={() => {
          if (userPlaces.length > 3) {
            showDetail?.(record.userPlaces)
          }
        }}
      >
        {userPlaces.slice(0, 3).map((userPlace) => {
          const getCodeDescription = (place: StwPlace) => {
            if (place && place.code) {
              return place.description ? `${place.code} - ${place.description}` : place.code
            }
            return 'n/a'
          }
          return <div key={userPlace.code}>{getCodeDescription(userPlace)}</div>
        })}
        {userPlaces.length > 3 && <div>...</div>}
      </Space>
    )
  }
}
