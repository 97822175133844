import {
  AdvancedTableColumn,
  AdvancedTableContentKind,
  AdvancedTableFilterKind,
  AdvancedTableFiltersDef,
} from '../../../components/AdvancedTable'
import {
  AdvancedFormField,
  AdvancedFormFieldBuilder,
  AdvancedFormInputType,
} from '../../../components/AdvancedForm/advancedFormTypes'
import { __ } from '../../../shared/i18n'
import { DetailCell } from '../../../api'
import { AdvancedTableRowColumn } from '../../../components'
import { showModal } from '../../../components/modalPageUtils'
import { CAPABILITIES, getActionsTableColumns } from '../../utility/capabilityUtility'

export const ROLE_MAPPINGS_FILTERS: AdvancedTableFiltersDef = [
  {
    key: 'equalCodes',
    label: __('fields.labels.code'),
    type: AdvancedTableFilterKind.STRING_LIST,
    column: 'code',
    col: 8,
  },
  {
    key: 'description',
    label: __('fields.labels.description'),
    type: AdvancedTableFilterKind.STRING,
    column: 'description',
    col: 8,
  },
  {
    key: 'roleIds',
    label: __('fields.labels.roles'),
    type: AdvancedTableFilterKind.AUTOCOMPLETE,
    column: 'roles',
    autocomplete: {
      endpoint: '/api/v1/roles',
      sort: 'code,asc',
      multiple: true,
    },
    col: 8,
  },
  {
    key: 'creationDate',
    label: __('fields.labels.creationDate'),
    type: AdvancedTableFilterKind.DATE_RANGE,
    column: 'creationDate',
    col: 8,
  },
  {
    key: 'lastModifyDate',
    label: __('fields.labels.lastModifyDate'),
    type: AdvancedTableFilterKind.DATE_RANGE,
    column: 'lastModifyDate',
    col: 8,
  },
]

export const ROLES_MAPPINGS_ROLES_COLUMNS: AdvancedTableColumn[] = [
  {
    key: 'code',
    dataIndex: 'code',
    title: __('fields.labels.code'),
    className: 'stw-medium',
  },
  {
    key: 'description',
    dataIndex: 'description',
    title: __('fields.labels.description'),
    className: 'stw-extra-medium',
  },
  {
    key: 'priority',
    dataIndex: 'priority',
    title: __('fields.labels.priority'),
  },
  {
    key: 'homepage',
    dataIndex: 'homepage',
    title: __('fields.labels.homepage'),
  },
  {
    key: 'capabilities',
    dataIndex: 'capabilities',
    title: __('fields.labels.capabilities'),
    type: AdvancedTableContentKind.MULTIPLE_STRING,
  },
  {
    key: 'operations',
    dataIndex: 'operations',
    title: __('fields.labels.operations'),
    className: 'stw-large',
    render: (text, record) => {
      const elements = record.operations.map((operation) => (
        <div>
          {operation.type}: {operation.code}
        </div>
      ))
      return <>{elements}</>
    },
  },
  {
    key: 'creationDate',
    dataIndex: 'creationDate',
    title: __('fields.labels.creationDate'),
    type: AdvancedTableContentKind.DATE_TIME,
  },
  {
    key: 'lastModifyDate',
    dataIndex: 'lastModifyDate',
    title: __('fields.labels.lastModifyDate'),
    type: AdvancedTableContentKind.DATE_TIME,
  },
]

export const ROLE_MAPPINGS_FIELDS: AdvancedFormField[] = [
  AdvancedFormFieldBuilder.buildText('description', [{ required: false }]),
  {
    key: 'code',
    type: AdvancedFormInputType.UNIQUE_TEXT,
    label: __('fields.labels.code'),
    placeholder: __('fields.placeholders.code'),
    rules: [{ required: true }],
    unique: {
      endpoint: '/api/v1/rolemappings',
      parameter: 'equalCodes',
    },
  },
  {
    key: 'roles',
    type: AdvancedFormInputType.OBJECT_LIST_WITH_CARDS,
    col: 24,
    placeholder: __('misc.select'),
    uniqueValueKey: 'id',
    autocomplete: {
      endpoint: '/api/v1/roles',
      sort: 'code,asc',
    },
    objectListWithCard: {
      handleView: (record) =>
        showModal({
          type: 'CAPABILITY_GROUP_LIST',
          data: { capabilityGroups: record.capabilityGroups },
        }),
    },
    rules: [{ required: true }],
  },
]

export const ROLE_MAPPINGS_COLUMNS: AdvancedTableColumn[] = [
  {
    key: 'code',
    dataIndex: 'code',
    title: __('fields.labels.code'),
    className: 'stw-medium',
  },
  {
    key: 'description',
    dataIndex: 'description',
    title: __('fields.labels.description'),
    hidden: true,
    className: 'stw-extra-medium',
  },
  {
    key: 'roles',
    dataIndex: 'roles',
    title: __('fields.labels.roles'),
    className: 'stw-extra-medium',
    sortable: false,
    render: (text, record) => {
      const elements = record.roles.map((role) => {
        const string = role.description ? role.code.concat(': ', role.description) : role.code
        return <div>{string}</div>
      })
      return <>{elements}</>
    },
  },
  {
    key: 'creationDate',
    dataIndex: 'creationDate',
    title: __('fields.labels.creationDate'),
    type: AdvancedTableContentKind.DATE_TIME,
  },
  {
    key: 'lastModifyDate',
    dataIndex: 'lastModifyDate',
    title: __('fields.labels.lastModifyDate'),
    type: AdvancedTableContentKind.DATE_TIME,
  },
  {
    key: 'action',
    fixedType: 'right',
    className: 'stw-small',
    sortable: false,
    unmanageable: true,
    render: (text, record) => (
      <AdvancedTableRowColumn
        actions={getActionsTableColumns(
          record.id,
          '/configuration/usersRoles/roleMappings',
          CAPABILITIES.RESOURCE_ROLE_MAPPING_EDIT
        )}
      />
    ),
  },
]

export const ROLE_MAPPINGS_DATA: DetailCell[] = [
  { title: 'id', attribute: 'id', columns: 24 },
  { title: 'code', attribute: 'code', columns: 12 },
  { title: 'description', attribute: 'description', columns: 12 },
  { title: 'Creation date', attribute: 'creationDate', type: 'date', columns: 12 },
  { title: 'Last modify date', attribute: 'lastModifyDate', type: 'date', columns: 12 },
]
