import { Component, createRef } from 'react'
import { Form, FormInstance } from 'antd'
import { AdvancedForm, StylewherePage, Section, DefaultHeader } from '../../../components'
import Places from '../../../api/Places'
import { ExtendRouteComponentProps } from '../../../types'
import { PLACE_FIELDS, PLACE_TYPES } from '../../../config/Pages/Places'
import { navigate } from '../../../shared/router'
import { __, T } from '../../../shared/i18n'
import { StwPlace } from '../../../api'
import { AttributeUtil } from '../../../config/utility/utility'
import { FormValidateMessages } from '../../../constants'
import { AdvancedFormInputType } from '../../../components/AdvancedForm/advancedFormTypes'
import { FormUtility } from '../../../config/utility/formUtility'
import { getFragmentObject, getBackURL } from '../../../shared/utils'

interface State {
  place: StwPlace
  loader: boolean
  isCodeEditableByDescription: boolean
  fragment: any | undefined
}

class PlaceForm extends Component<ExtendRouteComponentProps, State> {
  formRef = createRef<FormInstance>()

  constructor(props) {
    super(props)
    this.state = {
      place: {},
      loader: true,
      isCodeEditableByDescription: true,
      fragment: undefined,
    }
  }

  componentDidMount = async () => {
    const { match } = this.props
    let result
    if (match && match.params && match.params.placeId) {
      const placeId = match.params.placeId
      if (placeId !== '' && placeId !== 'create') {
        result = await Places.get(placeId)
      }
    }
    this.setState(
      {
        place: result || { createDefaultZones: true },
        isCodeEditableByDescription: !result,
        loader: false,
        fragment: getFragmentObject(result, 'code', __(T.place.create_new)),
      },
      this.updateFormFieldsValue
    )
  }

  updateFormFieldsValue = () => {
    if (this.formRef && this.formRef.current) {
      this.formRef.current.setFieldsValue(this.state.place)
    }
  }

  handleChange = (key, value) => {
    const { place, isCodeEditableByDescription } = this.state
    AttributeUtil.setAttribute(place, key, value)
    if (key === 'description' && isCodeEditableByDescription) {
      FormUtility.handleDescription(value, place, this.formRef)
    } else if (key === 'code') {
      this.setState({ isCodeEditableByDescription: false })
    } else if (key === 'attributes') {
      if (this.formRef && this.formRef.current) {
        this.formRef.current.setFieldsValue({ attributes: place.attributes })
      }
    }
    this.setState({
      place: place,
    })
  }

  store = () => {
    const { place } = this.state
    const { queryString } = this.props
    if (place.id) {
      Places.update<StwPlace>(place).then(() => {
        navigate(`/configuration/places${queryString ?? ''}`)
      })
    } else {
      Places.insert<StwPlace>(place).then((newPlace) => {
        if (newPlace && newPlace.id) {
          navigate(`/configuration/places${queryString ?? ''}`)
        }
      })
    }
  }

  render() {
    const { place, loader, fragment } = this.state
    const title = place.id ? __(T.place.edit) : __(T.place.create_new)
    const { breadcrumbs, queryString } = this.props
    return (
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        onFinish={this.store}
        style={{ width: '100%', height: '100%' }}
        ref={this.formRef}
        initialValues={place}
        validateMessages={FormValidateMessages}
        scrollToFirstError
      >
        <StylewherePage {...this.props} noOverflow fragment={fragment}>
          <DefaultHeader
            backPath={getBackURL(queryString, breadcrumbs)}
            title={loader ? '...' : title}
            skeleton={{ active: loader }}
            actions={[
              {
                label: __(T.misc.cancel),
                type: 'cancel',
                onClick: () => navigate(`/configuration/zones${queryString ?? ''}`),
              },
              {
                label: place.id !== '' ? __(T.misc.update) : __(T.misc.create),
                type: 'submit',
              },
            ]}
          />
          <Section customClass="stw-section-page paged-header scroll">
            <AdvancedForm
              record={place}
              store={this.store}
              editing={place.id !== undefined}
              handleChange={this.handleChange}
              parameters={{ types: PLACE_TYPES }}
              fields={
                place && place.id
                  ? PLACE_FIELDS
                  : [
                      ...PLACE_FIELDS,
                      {
                        key: 'createDefaultZones',
                        type: AdvancedFormInputType.SWITCH,
                        onlyCreate: true,
                      },
                    ]
              }
            />
          </Section>
        </StylewherePage>
      </Form>
    )
  }
}

export default PlaceForm
