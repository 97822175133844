import { Component } from 'react'
import { Skeleton, Row, Col } from 'antd'
import { Section, Page } from '..'
import { BreadcrumbsFragmentType, BreadcrumbsType } from '../PageBread'

interface Props {
  animate?: boolean
  breadcrumbs?: BreadcrumbsType
  fragment?: BreadcrumbsFragmentType[]
  location: any
  history: any
  match: any
}

export class SkeletonForm extends Component<Props> {
  render() {
    const { animate, fragment, location, history, match, breadcrumbs } = this.props
    return (
      <Page
        fragment={fragment}
        location={location}
        history={history}
        match={match}
        breadcrumbs={breadcrumbs}
        title="..."
        skeleton
        skeletonOptions={{ btnClass: 'create' }}
      >
        <Section fill>
          <Row gutter={[15, 15]}>
            <Col xs={24} xl={12}>
              <Skeleton className="stw-skeleton-form-label" active={animate} paragraph={{ rows: 0 }} />
              <Skeleton.Input className="stw-skeleton-full" active={animate} size="large" />
              <Skeleton className="stw-skeleton-form-label mt" active={animate} paragraph={{ rows: 0 }} />
              <Skeleton.Input className="stw-skeleton-full" active={animate} size="large" />
              <Skeleton className="stw-skeleton-form-label mt" active={animate} paragraph={{ rows: 0 }} />
              <Skeleton.Input className="stw-skeleton-full" active={animate} size="large" />
              <Skeleton className="stw-skeleton-form-label mt" active={animate} paragraph={{ rows: 0 }} />
              <Skeleton.Input className="stw-skeleton-full" active={animate} size="large" />
            </Col>
          </Row>
        </Section>
      </Page>
    )
  }
}
