import { Component } from 'react'
import { Row, Select } from 'antd'
import { Option } from 'antd/es/mentions'
import AutocompleteApi from '../../api/AutocompleteApi'

interface Props {
  value?: any
  onChange?: (value: any) => void
  placeholder: string
  withMultipleSelection?: boolean
  readonly?: boolean
  endpoint: string
  sort: string
  filters?: any
  hideSelectedValues?: boolean
  sizeAutocomplete?: number
  maxTagCount?: number
  dependencies?: string[]
  dependenciesParams?: any
  additionalParams?: any
}

interface State {
  selections: string[]
  suggestions: string[]
}

class SelectStringWithAutocompletion extends Component<Props, State> {
  searchTimeout?: any
  searchIdentifier = Math.random()

  constructor(props) {
    super(props)
    this.state = {
      selections: [],
      suggestions: [],
    }
  }

  componentDidMount = async () => {}

  componentDidUpdate = async () => {
    if (this.selectionsChanged()) {
      await this.updateValues()
    }
  }

  async getSelections(): Promise<any[]> {
    return Array.isArray(this.props.value ?? []) ? this.props.value ?? [] : [this.props.value]
  }

  selectionsChanged() {
    const currentSelections = Array.isArray(this.props.value ?? []) ? this.props.value ?? [] : [this.props.value]
    const previousSelections = this.state.selections
    return (
      currentSelections.length !== this.state.selections.length ||
      currentSelections.some(
        (currentSelection) => !previousSelections.some((previousSelection) => previousSelection === currentSelection)
      )
    )
  }

  async updateValues() {
    const selections = await this.getSelections()
    const suggestions = await this.getSuggestions()
    this.setState({ selections: selections, suggestions: suggestions })
  }

  getAdditionalFilters = () => {
    const { ...additionalFilters } = this.props.filters ?? {}
    return additionalFilters
  }

  renderOptions = () => {
    const data = [...this.state.suggestions]
    this.state.selections.filter((value) => data.indexOf(value) < 0).forEach((value) => data.push(value))
    return data
      .map((opt) => this.toOption(opt))
      .map((opt: any) => (
        <Option key={opt.value} value={opt.value}>
          {opt.label}
        </Option>
      ))
  }

  getSelectedValues = () => {
    return this.state.selections
  }

  getSuggestions = async (search?: string) => {
    const { endpoint, sort, sizeAutocomplete, dependencies, dependenciesParams, additionalParams } = this.props
    const filters = this.getAdditionalFilters()
    if (dependencies && dependenciesParams) {
      dependencies.filter((dep) => dependenciesParams[dep]).forEach((dep) => (filters[dep] = dependenciesParams[dep]))
    }
    if (additionalParams) {
      const keys = Object.keys(additionalParams)
      keys.map((key) => (filters[key] = additionalParams[key]))
    }
    if (endpoint && endpoint !== '') {
      return AutocompleteApi.searchNoPage<string>(endpoint, {
        page: 0,
        size: sizeAutocomplete || 20,
        sort: sort,
        search: search,
        excludedValues: this.getSelections(),
        ...filters,
      }).then((response) => response ?? [])
    }
    return []
  }

  handleChange = (value: any) => {
    const { onChange, withMultipleSelection } = this.props
    const { selections, suggestions } = this.state
    if (onChange) {
      const objects = [...selections, ...suggestions]
      if (withMultipleSelection) {
        onChange(value.map((aValue) => objects.find((object) => object === aValue)))
      } else {
        onChange(objects.find((object) => object === value))
      }
    }
  }

  handleSearch = async (search?: string) => {
    this.searchIdentifier = Math.random()
    const currentSearchIdentifier = this.searchIdentifier
    const suggestions = await this.getSuggestions(search)
    if (currentSearchIdentifier === this.searchIdentifier) {
      this.setState({ suggestions: suggestions })
    }
  }

  handleSearchWithDelay = (delay: number) => (search?: string) => {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout)
    }
    this.searchTimeout = setTimeout(() => this.handleSearch(search), delay)
  }

  toOption = (value: any) => {
    return {
      value: value,
      label: this.toOptionLabel(value),
    }
  }

  toOptionLabel = (entity: any) => {
    let label: string
    if (!entity) {
      return null
    }

    if (entity.username) {
      label = entity.username
    } else if (entity.description && entity.code) {
      label = `${entity.code}: ${entity.description}`
    } else if (entity.code) {
      label = entity.code
    } else if (entity.identifiers) {
      label = `${entity.product.code} ${entity.product.description ?? ''} ${entity.identifiers
        .map((itemIdentifier) => `${itemIdentifier.type}: ${itemIdentifier.code}`)
        .join(', ')}`
    } else {
      label = entity
    }
    return label
  }

  render() {
    const { placeholder, readonly, withMultipleSelection, maxTagCount } = this.props
    return (
      <Row gutter={24} className="stylewhere-select-with-autocompletion">
        <div style={{ flex: 1, flexDirection: 'row', width: '100%' }}>
          <Select
            value={this.getSelectedValues()}
            allowClear
            showSearch
            style={{ width: '100%' }}
            placeholder={placeholder}
            disabled={readonly}
            mode={withMultipleSelection ? 'multiple' : undefined}
            maxTagCount={maxTagCount}
            onSearch={this.handleSearchWithDelay(300)}
            onChange={this.handleChange}
            onClear={this.handleSearch}
            onFocus={() => this.handleSearch()}
            filterOption={false}
          >
            {this.renderOptions()}
          </Select>
        </div>
      </Row>
    )
  }
}

export default SelectStringWithAutocompletion
