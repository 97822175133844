import { Component, createRef, RefObject } from 'react'
import { Col, Form, FormInstance, Row } from 'antd'
import {
  StwFullRfidReader,
  StwFullRfidReaderForm,
  StwPlace,
  StwRfidReader,
  StwRfidReaderAntennas,
  StwRfidReaderType,
} from '../../api'
import RfidReaders from '../../api/RfidReaders'
import RfidReadersFull from '../../api/RfidReadersFull'
import { AttributeUtil } from '../../config/utility/utility'
import {
  FormValidateMessages,
  RfidReaderConnectionModes,
  RfidReaderEmulationModeSelectValues,
  RfidReaderGpoModes,
  RfidReaderReaderModes,
  RfidReaderSearchModes,
  RfidReaderSession,
  RfidReaderStartStopTypes,
  RfidReaderTriggerTimeoutSelectValues,
  RfidReaderTypes,
} from '../../constants'
import { AdvancedForm, AdvancedRfidReaderAntennaCard, Section, SectionTitle, SkeletonFormModal } from '..'
import { AdvancedFormButton, AdvancedFormField } from '../AdvancedForm/advancedFormTypes'
import { navigate } from '../../shared/router'
import {
  RFID_READERS_CAEN_QUATTRO_ADVANCED_FIELDS,
  RFID_READERS_CAEN_QUATTRO_FIELDS,
  RFID_READERS_CAEN_QUATTRO_TIMEOUT_ENABLED_FIELDS,
  RFID_READERS_CAEN_QUATTRO_TRIGGER_ENABLED_FIELDS,
  RFID_READERS_CAEN_SLATE_NORDIC_ID_SAMPO_NATIVE_FIELDS,
  RFID_READERS_FIELDS,
  RFID_READERS_IMPINJ_SPEEDWAY_OCTANE_ADVANCED_FIELDS,
  RFID_READERS_IMPINJ_SPEEDWAY_OCTANE_FIELDS,
  RFID_READERS_IMPINJ_SPEEDWAY_OCTANE_TIMEOUT_ENABLED_FIELDS,
  RFID_READERS_IMPINJ_SPEEDWAY_OCTANE_TRIGGER_ENABLED_FIELDS,
  RFID_READERS_NORDIC_ID_SAMPO_LABID_FIELDS,
  RFID_READERS_RFID_EMULATOR_FIELDS,
  RFID_READERS_ZEBRA_FX_SERIES_ADVANCED_FIELDS,
  RFID_READERS_ZEBRA_FX_SERIES_FIELDS,
  RFID_READERS_ZEBRA_FX_SERIES_TIMEOUT_ENABLED_FIELDS,
  RFID_READERS_ZEBRA_FX_SERIES_TRIGGER_ENABLED_FIELDS,
} from '../../config/Pages/Devices/RfidReaders'
import Places from '../../api/Places'
import { __ } from '../../shared/i18n'
import { mapFullToRfidReader, mapRfidReaderToFull } from './AdvancedRfidReaderFormUtils'

const FORM_NAME = 'form-page-reader'

interface Props {
  readerId?: string
  buttonActions?: AdvancedFormButton[]
  title?: string
  onSave?: any
  setFormName?: (...args: any[]) => any
  noPaddingPage?: boolean
  formWidth?: string
  cardCol?: number
  placeId?: string
  displayFormInRow?: boolean
}

interface State {
  rfidReader: StwFullRfidReaderForm
  generalFields: AdvancedFormField[]
  advancedFields: AdvancedFormField[]
  loader: boolean
  maxAntennaPortNumber: number
  fields: AdvancedFormField[]
}

export class AdvancedRfidReaderForm extends Component<Props, State> {
  formRef = createRef<FormInstance>()

  constructor(props) {
    super(props)

    if (this.props.setFormName) {
      this.props.setFormName(FORM_NAME)
    }

    this.state = {
      rfidReader: {},
      loader: true,
      fields: [],
      generalFields: [],
      advancedFields: [],
      maxAntennaPortNumber: 1,
    }
  }

  async componentDidMount() {
    await this.initialize()
  }

  shouldComponentUpdate = (nextProps) => {
    const nextRfidReaderId = nextProps.readerId
    const currentRfidReaderId = this.props.readerId
    if (nextRfidReaderId && nextRfidReaderId !== '' && nextRfidReaderId !== currentRfidReaderId) {
      this.setState({ loader: true }, this.initialize)
    }
    return true
  }

  async initialize() {
    const { readerId, placeId } = this.props
    const fields = RFID_READERS_FIELDS
    const baseFullReader = {
      totalAntennas: 0,
      antenna1: undefined,
      antenna2: undefined,
      antenna3: undefined,
      antenna4: undefined,
      antenna5: undefined,
      antenna6: undefined,
      antenna7: undefined,
      antenna8: undefined,
    }
    let fullReader = readerId ? {} : baseFullReader
    if (readerId) {
      const reader = await RfidReadersFull.get<StwRfidReaderAntennas>(readerId)
      fullReader = mapRfidReaderToFull(reader)
      this.initSettings(fullReader)
    } else if (placeId && placeId !== '') {
      fields[0].onlyCreate = true
      fields[3].onlyCreate = false
      const place = await Places.get<StwPlace>(placeId)
      AttributeUtil.setAttribute(fullReader, 'place', place)
      await this.handlePlaceChange(fullReader)
    }
    this.setState(
      {
        rfidReader: fullReader || {},
        fields: fields,
        loader: false,
      },
      this.updateFormFieldsValue
    )
  }

  updateFormFieldsValue = () => {
    if (this.formRef && this.formRef.current) {
      this.formRef.current.setFieldsValue(this.state.rfidReader)
    }
  }

  private initSettings(fullReader: StwFullRfidReader) {
    this.handleTypeChange(fullReader.deviceType!)
    switch (fullReader.deviceType) {
      case StwRfidReaderType.CAEN_HEX:
        this.setHiddenGeneralField(fullReader.connectionMode, 'ETHERNET', 'ipAddress', this.formRef)
        break
      case StwRfidReaderType.IMPINJ_SPEEDWAY_OCTANE:
        this.setRequiredAdvancedField(fullReader.keepAliveEnabled, true, 'keepAlivePeriod', this.formRef)
        this.setRequiredAdvancedField(fullReader.linkMonitorModeEnabled, true, 'linkDownThreshold', this.formRef)
        this.setRequiredGeneralField(fullReader.searchMode, 'SINGLE_TARGET', 'session', this.formRef)
        if (fullReader.triggerEnabled) {
          this.handleTriggerTimeoutEnabled('triggerEnabled', fullReader)
        } else if (fullReader.timeoutEnabled) {
          this.handleTriggerTimeoutEnabled('timeoutEnabled', fullReader)
        }
        break
      case StwRfidReaderType.RFID_EMULATOR:
        this.setRequiredGeneralField(fullReader.emulationMode, 'EPC_LIST', 'epcs', this.formRef)
        break
      case StwRfidReaderType.CAEN_QUATTRO:
        this.setRequiredGeneralField(fullReader.connectionMode, 'ETHERNET', 'ipAddress', this.formRef)
        if (fullReader.triggerEnabled) {
          this.handleTriggerTimeoutEnabled('triggerEnabled', fullReader)
        } else if (fullReader.timeoutEnabled) {
          this.handleTriggerTimeoutEnabled('timeoutEnabled', fullReader)
        }
        break
      case StwRfidReaderType.ZEBRA_FX_SERIES:
        if (fullReader.triggerEnabled) {
          this.handleTriggerTimeoutEnabled('triggerEnabled', fullReader)
        } else if (fullReader.timeoutEnabled) {
          this.handleTriggerTimeoutEnabled('timeoutEnabled', fullReader)
        }
        break
    }
  }

  handleChange = (key, value) => {
    const { rfidReader } = this.state
    AttributeUtil.setAttribute(rfidReader, key, value)
    switch (key) {
      case 'place':
        this.handlePlaceChange(rfidReader).then()
        break
      case 'deviceType':
        this.handleTypeChange(value)
        break
      case 'connectionMode':
        this.handleConnectionMode(value, rfidReader)
        break
      case 'searchMode':
        this.setRequiredGeneralField(value, 'SINGLE_TARGET', 'session', this.formRef)
        break
      case 'keepAliveEnabled':
        this.setRequiredAdvancedField(value, true, 'keepAlivePeriod', this.formRef)
        break
      case 'linkMonitorModeEnabled':
        this.setRequiredAdvancedField(value, true, 'linkDownThreshold', this.formRef)
        break
      case 'triggerTimeoutSelect':
        rfidReader.triggerEnabled = value === 'triggerEnabled'
        rfidReader.timeoutEnabled = value === 'timeoutEnabled'
        rfidReader.startType = undefined
        rfidReader.gpioStartPort = undefined
        rfidReader.stopType = undefined
        rfidReader.gpioStopPort = undefined
        rfidReader.debounceTime = undefined
        if (this.formRef && this.formRef.current) {
          this.formRef.current.setFieldsValue({
            startType: undefined,
            gpioStartPort: undefined,
            stopType: undefined,
            gpioStopPort: undefined,
            debounceTime: undefined,
          })
        }
        this.handleTriggerTimeoutEnabled(value, rfidReader)
        break
      case 'emulationMode':
        this.setRequiredGeneralField(value, 'EPC_LIST', 'epcs', this.formRef)
        break
      default:
        break
    }
    this.setState({ rfidReader })
  }

  getAntennaCard() {
    const { cardCol } = this.props
    const { maxAntennaPortNumber, rfidReader } = this.state
    const cards: any[] = []
    for (let i = 1; i <= maxAntennaPortNumber; i++) {
      let antenna
      if (rfidReader[`antenna${i}`]) {
        antenna = rfidReader[`antenna${i}`]
      }
      cards.push(
        <Col span={cardCol || 6} key={`Port ${i}`}>
          <AdvancedRfidReaderAntennaCard
            title={`Port ${i}`}
            readerPort={i}
            key={`Port ${i}`}
            antenna={antenna}
            rfidReader={rfidReader}
            handleChange={this.handleChangeAntenna}
            handleDelete={this.deleteAntenna}
            place={rfidReader.place}
          />
        </Col>
      )
    }

    return cards
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateCodeAndDescription = async (rfidReader: StwFullRfidReader, formRef: RefObject<FormInstance>) => {
    let code = ''
    let description = ''
    const placeId = rfidReader && rfidReader.place ? rfidReader.place.id : ''
    if (placeId && placeId !== '') {
      const place = await Places.get<StwPlace>(placeId)
      const balances = await RfidReaders.search<StwRfidReader>({ page: 0, size: 1, placeId: placeId })
      if (place && place.description) {
        code = `${place.code}_R${balances.totalElements + 1}`
        description = place.description && `${place.description}_R${balances.totalElements + 1}`
      }
    }
    return { code: code, description: description }
  }

  private handleTypeChange(type: StwRfidReaderType) {
    const { rfidReader } = this.state
    let portAntennaPortNumber = 1

    let base: AdvancedFormField[] = []
    let advanced: AdvancedFormField[] = []
    switch (type) {
      case StwRfidReaderType.CAEN_HEX:
      case StwRfidReaderType.NORDIC_ID_SAMPO_NATIVE:
        base = RFID_READERS_CAEN_SLATE_NORDIC_ID_SAMPO_NATIVE_FIELDS
        rfidReader.connectionMode = undefined
        rfidReader.ipAddress = ''
        rfidReader.readerMode = undefined
        rfidReader.searchMode = undefined
        rfidReader.keepAliveEnabled = false
        rfidReader.linkMonitorModeEnabled = false
        rfidReader.triggerTimeoutSelect = undefined
        rfidReader.triggerEnabled = false
        rfidReader.timeoutEnabled = false
        rfidReader.session = undefined
        rfidReader.maxConnectionTimeout = 3000
        rfidReader.keepAlivePeriod = undefined
        rfidReader.linkDownThreshold = undefined
        rfidReader.startType = undefined
        rfidReader.gpioStartPort = undefined
        rfidReader.stopType = undefined
        rfidReader.gpioStopPort = undefined
        rfidReader.debounceTime = undefined
        rfidReader.ports = []
        if (this.formRef && this.formRef.current) {
          this.formRef.current.setFieldsValue({
            connectionMode: undefined,
            ipAddress: '',
            readerMode: undefined,
            searchMode: undefined,
            keepAliveEnabled: false,
            linkMonitorModeEnabled: false,
            triggerTimeoutSelect: undefined,
            triggerEnabled: false,
            timeoutEnabled: false,
            maxConnectionTimeout: 3000,
            keepAlivePeriod: undefined,
            linkDownThreshold: undefined,
            startType: undefined,
            gpioStartPort: undefined,
            stopType: undefined,
            gpioStopPort: undefined,
            debounceTime: undefined,
            session: undefined,
            ports: [],
          })
        }
        break
      case StwRfidReaderType.SENSORMATIC:
      case StwRfidReaderType.IMPINJ_XARRAY:
        base = RFID_READERS_NORDIC_ID_SAMPO_LABID_FIELDS
        portAntennaPortNumber = type === StwRfidReaderType.SENSORMATIC ? 1 : 0
        rfidReader.connectionMode = undefined
        rfidReader.ipAddress = ''
        rfidReader.readerMode = undefined
        rfidReader.searchMode = undefined
        rfidReader.keepAliveEnabled = false
        rfidReader.linkMonitorModeEnabled = false
        rfidReader.triggerTimeoutSelect = undefined
        rfidReader.triggerEnabled = false
        rfidReader.timeoutEnabled = false
        rfidReader.session = undefined
        rfidReader.maxConnectionTimeout = 3000
        rfidReader.keepAlivePeriod = undefined
        rfidReader.linkDownThreshold = undefined
        rfidReader.startType = undefined
        rfidReader.gpioStartPort = undefined
        rfidReader.stopType = undefined
        rfidReader.gpioStopPort = undefined
        rfidReader.debounceTime = undefined
        rfidReader.ports = []
        if (this.formRef && this.formRef.current) {
          this.formRef.current.setFieldsValue({
            connectionMode: undefined,
            ipAddress: '',
            readerMode: undefined,
            searchMode: undefined,
            keepAliveEnabled: false,
            linkMonitorModeEnabled: false,
            triggerTimeoutSelect: undefined,
            triggerEnabled: false,
            timeoutEnabled: false,
            maxConnectionTimeout: 3000,
            keepAlivePeriod: undefined,
            linkDownThreshold: undefined,
            startType: undefined,
            gpioStartPort: undefined,
            stopType: undefined,
            gpioStopPort: undefined,
            debounceTime: undefined,
            session: undefined,
            ports: [],
          })
        }
        break
      case StwRfidReaderType.NORDIC_ID_SAMPO_LABID:
        base = RFID_READERS_NORDIC_ID_SAMPO_LABID_FIELDS
        rfidReader.connectionMode = undefined
        rfidReader.ipAddress = ''
        rfidReader.readerMode = undefined
        rfidReader.searchMode = undefined
        rfidReader.keepAliveEnabled = false
        rfidReader.linkMonitorModeEnabled = false
        rfidReader.triggerTimeoutSelect = undefined
        rfidReader.triggerEnabled = false
        rfidReader.timeoutEnabled = false
        rfidReader.session = undefined
        rfidReader.maxConnectionTimeout = 3000
        rfidReader.keepAlivePeriod = undefined
        rfidReader.linkDownThreshold = undefined
        rfidReader.startType = undefined
        rfidReader.gpioStartPort = undefined
        rfidReader.stopType = undefined
        rfidReader.gpioStopPort = undefined
        rfidReader.debounceTime = undefined
        rfidReader.ports = []
        if (this.formRef && this.formRef.current) {
          this.formRef.current.setFieldsValue({
            connectionMode: undefined,
            ipAddress: '',
            readerMode: undefined,
            searchMode: undefined,
            keepAliveEnabled: false,
            linkMonitorModeEnabled: false,
            triggerTimeoutSelect: undefined,
            triggerEnabled: false,
            timeoutEnabled: false,
            maxConnectionTimeout: 3000,
            keepAlivePeriod: undefined,
            linkDownThreshold: undefined,
            startType: undefined,
            gpioStartPort: undefined,
            stopType: undefined,
            gpioStopPort: undefined,
            debounceTime: undefined,
            session: undefined,
            ports: [],
          })
        }
        break
      case StwRfidReaderType.IMPINJ_SPEEDWAY_OCTANE:
        base = RFID_READERS_IMPINJ_SPEEDWAY_OCTANE_FIELDS
        advanced = RFID_READERS_IMPINJ_SPEEDWAY_OCTANE_ADVANCED_FIELDS
        portAntennaPortNumber = 4
        if (!rfidReader.id) {
          rfidReader.connectionMode = undefined
          rfidReader.readerMode = 'AUTO_SET_DENSE_READER'
          rfidReader.searchMode = 'DUAL_TARGET'
          rfidReader.keepAliveEnabled = false
          rfidReader.session = '0'
          rfidReader.maxConnectionTimeout = 3000
          rfidReader.linkMonitorModeEnabled = false
          rfidReader.triggerTimeoutSelect = undefined
          rfidReader.triggerEnabled = false
          rfidReader.timeoutEnabled = false
          if (this.formRef && this.formRef.current) {
            this.formRef.current.setFieldsValue({
              connectionMode: undefined,
              readerMode: 'AUTO_SET_DENSE_READER',
              searchMode: 'DUAL_TARGET',
              session: '0',
              keepAliveEnabled: false,
              linkMonitorModeEnabled: false,
              maxConnectionTimeout: 3000,
            })
          }
          this.setState({ rfidReader })
        }
        break
      case StwRfidReaderType.RFID_EMULATOR:
        base = RFID_READERS_RFID_EMULATOR_FIELDS
        if (!rfidReader.id) {
          rfidReader.emulationMode = undefined
          rfidReader.epcs = ''
          rfidReader.minEpcDelay = 1000
          rfidReader.maxEpcDelay = 5000
          if (this.formRef && this.formRef.current) {
            this.formRef.current.setFieldsValue({
              emulationMode: undefined,
              epcs: '',
              minEpcDelay: 1000,
              maxEpcDelay: 5000,
            })
          }
        }
        break
      case StwRfidReaderType.CAEN_QUATTRO:
        base = RFID_READERS_CAEN_QUATTRO_FIELDS
        advanced = RFID_READERS_CAEN_QUATTRO_ADVANCED_FIELDS
        portAntennaPortNumber = 4
        if (!rfidReader.id) {
          rfidReader.connectionMode = undefined
          rfidReader.ipAddress = ''
          rfidReader.maxConnectionTimeout = 3000
          rfidReader.memoryBank = 'EPC'
          rfidReader.socketPort = undefined
          rfidReader.gpioPolling = 100
          rfidReader.triggerTimeoutSelect = undefined
          rfidReader.triggerEnabled = false
          rfidReader.timeoutEnabled = false
          rfidReader.loginUsername = 'admin'
          rfidReader.loginPassword = 'change'
          if (this.formRef && this.formRef.current) {
            this.formRef.current.setFieldsValue({
              connectionMode: undefined,
              ipAddress: '',
              maxConnectionTimeout: 3000,
              memoryBank: 'EPC',
              socketPort: undefined,
              gpioPolling: 100,
              loginUsername: 'admin',
              loginPassword: 'change',
            })
          }
          this.setState({ rfidReader })
        }
        break
      case StwRfidReaderType.ZEBRA_FX_SERIES:
        base = RFID_READERS_ZEBRA_FX_SERIES_FIELDS
        advanced = RFID_READERS_ZEBRA_FX_SERIES_ADVANCED_FIELDS
        portAntennaPortNumber = 8
        if (!rfidReader.id) {
          rfidReader.ipAddress = ''
          rfidReader.maxConnectionTimeout = 3000
          rfidReader.socketPort = undefined
          rfidReader.rxSensitivity = 0
          rfidReader.triggerTimeoutSelect = undefined
          rfidReader.triggerEnabled = false
          rfidReader.timeoutEnabled = false
          rfidReader.loginUsername = 'admin'
          rfidReader.loginPassword = 'change'
          if (this.formRef && this.formRef.current) {
            this.formRef.current.setFieldsValue({
              ipAddress: '',
              maxConnectionTimeout: 3000,
              socketPort: undefined,
              rxSensitivity: 0,
              loginUsername: 'admin',
              loginPassword: 'change',
            })
          }
          this.setState({ rfidReader })
        }
        break
    }

    this.setState(
      {
        generalFields: base,
        advancedFields: advanced,
        maxAntennaPortNumber: portAntennaPortNumber,
      },
      this.hiddenField
    )
  }

  handleConnectionMode = (value: string, rfidReader: StwFullRfidReaderForm) => {
    if (rfidReader.deviceType === StwRfidReaderType.CAEN_QUATTRO) {
      this.setRequiredGeneralField(value, 'ETHERNET', 'ipAddress', this.formRef)
    } else {
      this.setHiddenGeneralField(value, 'ETHERNET', 'ipAddress', this.formRef)
      if (value !== 'ETHERNET') {
        AttributeUtil.setAttribute(rfidReader, 'ipAddress', '')
      }
    }
  }

  hiddenField = () => {
    const { rfidReader } = this.state
    switch (rfidReader.deviceType) {
      case StwRfidReaderType.CAEN_HEX:
      case StwRfidReaderType.NORDIC_ID_SAMPO_NATIVE:
        this.handleChange('connectionMode', undefined)
        break
      case StwRfidReaderType.NORDIC_ID_SAMPO_LABID:
        break
    }
  }

  private setRequiredAdvancedField(value, compareValue, key: string, formRef: RefObject<FormInstance>) {
    const { advancedFields } = this.state
    advancedFields
      .filter((field) => field.key === key)
      .forEach((field) => (field.rules = [{ required: value && value === compareValue }]))
    this.setState({ advancedFields: advancedFields })
    if (formRef.current) {
      formRef.current.validateFields([key]).then()
    }
  }

  private setRequiredGeneralField(value, compareValue, key: string, formRef: RefObject<FormInstance>) {
    const { generalFields } = this.state
    generalFields
      .filter((field) => field.key === key)
      .forEach((field) => (field.rules = [{ required: value && value === compareValue }]))
    this.setState({ generalFields: generalFields })
    if (formRef.current) {
      formRef.current.validateFields([key]).then()
    }
  }

  private setHiddenGeneralField(value, compareValue, key: string, formRef: RefObject<FormInstance>) {
    const { generalFields } = this.state
    generalFields.filter((field) => field.key === key).forEach((field) => (field.hidden = value !== compareValue))
    this.setState({ generalFields: generalFields })
    if (formRef.current) {
      formRef.current.validateFields([key]).then()
    }
  }

  private handleTriggerTimeoutEnabled(value, rfidReader: StwFullRfidReader) {
    switch (rfidReader.deviceType) {
      case StwRfidReaderType.IMPINJ_SPEEDWAY_OCTANE:
        this.setTriggerTimeoutFields(
          RFID_READERS_IMPINJ_SPEEDWAY_OCTANE_ADVANCED_FIELDS,
          RFID_READERS_IMPINJ_SPEEDWAY_OCTANE_TRIGGER_ENABLED_FIELDS,
          RFID_READERS_IMPINJ_SPEEDWAY_OCTANE_TIMEOUT_ENABLED_FIELDS,
          value,
          rfidReader
        )
        break

      case StwRfidReaderType.CAEN_QUATTRO:
        this.setTriggerTimeoutFields(
          RFID_READERS_CAEN_QUATTRO_ADVANCED_FIELDS,
          RFID_READERS_CAEN_QUATTRO_TRIGGER_ENABLED_FIELDS,
          RFID_READERS_CAEN_QUATTRO_TIMEOUT_ENABLED_FIELDS,
          value,
          rfidReader
        )
        break

      case StwRfidReaderType.ZEBRA_FX_SERIES:
        this.setTriggerTimeoutFields(
          RFID_READERS_ZEBRA_FX_SERIES_ADVANCED_FIELDS,
          RFID_READERS_ZEBRA_FX_SERIES_TRIGGER_ENABLED_FIELDS,
          RFID_READERS_ZEBRA_FX_SERIES_TIMEOUT_ENABLED_FIELDS,
          value,
          rfidReader
        )
        break
    }
  }

  private setTriggerTimeoutFields(
    baseSettings: AdvancedFormField[],
    triggerSettings: AdvancedFormField[],
    timeoutSetting: AdvancedFormField[],
    value: string,
    rfidReader: StwFullRfidReader
  ) {
    let settingsToRender = baseSettings
    if (value) {
      if (value === 'triggerEnabled') {
        rfidReader.triggerEnabled = true
        rfidReader.timeoutEnabled = false
        settingsToRender = [...settingsToRender, ...triggerSettings]
      } else if (value === 'timeoutEnabled') {
        rfidReader.triggerEnabled = false
        rfidReader.timeoutEnabled = true
        settingsToRender = [...settingsToRender, ...timeoutSetting]
      }
    } else {
      rfidReader.triggerEnabled = false
      rfidReader.timeoutEnabled = false
    }
    // serve per inserire i campi sopra prima del ports
    const indexPorts = settingsToRender.findIndex((setting) => setting.key === 'ports')
    settingsToRender.push(settingsToRender.splice(indexPorts, 1)[0])
    this.setState({ advancedFields: settingsToRender })
  }

  getBaseNewAntenna = (readerPort) => {
    const { rfidReader } = this.state
    const code = `${rfidReader.code}_A${rfidReader.totalAntennas}`
    const description = `${rfidReader.description}_A${rfidReader.totalAntennas}`
    return {
      readerPort: readerPort,
      code: code,
      description: description,
      txPower: 10,
      rxSensitivity: -80,
    }
  }

  handleChangeAntenna = (readerPort, key, value) => {
    const { rfidReader } = this.state
    const antenna = rfidReader[`antenna${readerPort}`]
    if (!antenna || (antenna && !antenna.readerPort)) {
      AttributeUtil.setAttribute(rfidReader, `antenna${readerPort}`, this.getBaseNewAntenna(readerPort))
      AttributeUtil.setAttribute(rfidReader, 'totalAntennas', (rfidReader.totalAntennas || 0) + 1)
    } else {
      AttributeUtil.setAttribute(rfidReader, key, value)
    }
    if (this.formRef && this.formRef.current) {
      this.formRef.current.setFieldsValue({
        [`antenna${readerPort}`]: rfidReader[`antenna${readerPort}`],
      })
    }
    this.setState({ rfidReader })
  }

  deleteAntenna = (readerPort) => {
    const { rfidReader } = this.state
    AttributeUtil.setAttribute(rfidReader, `antenna${readerPort}`, undefined)
    AttributeUtil.setAttribute(rfidReader, 'totalAntennas', (rfidReader.totalAntennas || 0) - 1)
    this.setState({ rfidReader })
  }

  async store() {
    const { rfidReader } = this.state
    const readerToSave: StwRfidReaderAntennas = mapFullToRfidReader(rfidReader)
    if (readerToSave.id) {
      RfidReadersFull.update<StwRfidReaderAntennas>(readerToSave).then((value) => this.onSave(value))
    } else {
      RfidReadersFull.insert<StwRfidReaderAntennas>(readerToSave).then((value) => this.onSave(value))
    }
  }

  onSave = async (responseRfidReader: StwRfidReaderAntennas) => {
    const { onSave } = this.props
    if (onSave) {
      onSave(responseRfidReader)
    } else {
      navigate(`/devices/rfidReaders`)
    }
  }

  private async handlePlaceChange(rfidReader: StwFullRfidReader) {
    const data: any = await this.updateCodeAndDescription(rfidReader, this.formRef)
    rfidReader.code = data.code
    rfidReader.description = data.description
    if (this.formRef && this.formRef.current) {
      this.formRef.current.setFieldsValue({
        code: data.code,
        description: data.description,
      })
    }
    this.setState({ rfidReader })
  }

  render() {
    const { buttonActions, title, noPaddingPage, formWidth, placeId, displayFormInRow } = this.props
    const { rfidReader, loader, advancedFields, fields, generalFields, maxAntennaPortNumber } = this.state
    const fromModal = placeId ? placeId !== '' : false
    const currentPlaceId = rfidReader.place ? rfidReader.place.id : ''
    return (
      <Row style={{ width: '100%', height: loader ? '100%' : 'auto' }}>
        {loader ? (
          <SkeletonFormModal fromModal={fromModal} animate />
        ) : (
          <Form
            id={FORM_NAME}
            ref={this.formRef}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            layout="vertical"
            onFinish={async () => {
              await this.store()
            }}
            style={{ width: '100%' }}
            initialValues={rfidReader}
            validateMessages={FormValidateMessages}
            scrollToFirstError={!fromModal}
          >
            <Section fill noPaddingPage={noPaddingPage}>
              <AdvancedForm
                record={rfidReader}
                fields={fields}
                title={title}
                width={formWidth || '70%'}
                store={this.store}
                editing={rfidReader.id !== undefined || fromModal}
                handleChange={this.handleChange}
                displayFormInRow={displayFormInRow}
                parameters={{
                  rfidReaderTypes: RfidReaderTypes,
                }}
              />
              {generalFields && generalFields.length > 0 && (
                <AdvancedForm
                  record={rfidReader}
                  fields={generalFields}
                  title={__('misc.baseOptions_title')}
                  store={this.store}
                  width={formWidth || '70%'}
                  editing={rfidReader.id !== undefined}
                  buttonActions={buttonActions}
                  handleChange={this.handleChange}
                  displayFormInRow={displayFormInRow}
                  parameters={{
                    rfidReaderConnectionModes: RfidReaderConnectionModes,
                    rfidReaderReaderModes: RfidReaderReaderModes,
                    rfidReaderSearchModes: RfidReaderSearchModes,
                    rfidReaderSession: RfidReaderSession,
                    rfidReaderEmulationModeSelectValues: RfidReaderEmulationModeSelectValues,
                  }}
                />
              )}
              {maxAntennaPortNumber > 0 && rfidReader.deviceType && currentPlaceId && currentPlaceId !== '' && (
                <Row gutter={24} style={{ marginTop: 20, marginBottom: 20 }}>
                  <Col span={24}>
                    <SectionTitle title={__('misc.antennas_title')} marginBottom={5} />
                  </Col>
                  {this.getAntennaCard()}
                </Row>
              )}
              {advancedFields && advancedFields.length > 0 && (
                <AdvancedForm
                  record={rfidReader}
                  fields={advancedFields}
                  title={__('misc.advancedOptions_title')}
                  store={this.store}
                  width={formWidth || '70%'}
                  editing={rfidReader.id !== undefined}
                  buttonActions={buttonActions}
                  handleChange={this.handleChange}
                  displayFormInRow={displayFormInRow}
                  parameters={{
                    rfidReaderStartStopTypes: RfidReaderStartStopTypes,
                    triggerTimeoutSelectValues: RfidReaderTriggerTimeoutSelectValues,
                    rfidReaderGpoModes: RfidReaderGpoModes,
                  }}
                />
              )}
            </Section>
          </Form>
        )}
      </Row>
    )
  }
}
