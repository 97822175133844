import { Component } from 'react'
import { ExtendRouteComponentProps } from '../../../types'
import { TableListReport, StylewherePage } from '../../../components'
import { getTableActionsReport, getHeaderActionsReport } from '../../../shared/utils'
import { __, T } from '../../../shared/i18n'
import RfidReaders from '../../../api/RfidReaders'

class RfidReadersList extends Component<ExtendRouteComponentProps> {
  render() {
    const reportId = 'rfidReader'
    return (
      <StylewherePage {...this.props} noOverflow>
        <TableListReport
          {...this.props}
          reportId={reportId}
          title={__(T.menu.rfidReaders)}
          reportActions={getTableActionsReport(reportId)}
          actions={getHeaderActionsReport(reportId, this.props.breadcrumbs)}
          disabledReportExport
          deleteRecord={{
            call: RfidReaders.deleteTableList,
            endpoint: RfidReaders.endpoint,
            title: `${__(T.misc.delete_record)} ${__(T.rfidReaders.rfidReader)}?`,
            subtitle: __(T.misc.delete_noundo),
          }}
        />
      </StylewherePage>
    )
  }
}
export default RfidReadersList
