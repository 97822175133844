import { Component } from 'react'
import { Col, Modal, Row, Typography } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import { __, T } from '../../shared/i18n'
import { StwBalance } from '../../api'
import { AdvancedBalanceForm } from '..'
import { AdvancedFormButton } from '../AdvancedForm/advancedFormTypes'

interface State {
  formName: string
}

interface Props {
  visible: boolean
  onCancel: () => void
  onConfirm: (balanceId: string) => void
  placeId: string
}

const { Title } = Typography

export class WorkstationBalanceCreationModal extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      formName: '',
    }
  }

  setFormName = (name: string) => {
    this.setState({ formName: name })
  }

  onSave = (balance: StwBalance) => {
    const { onConfirm } = this.props
    if (balance && balance.id) {
      onConfirm(balance.id)
    }
  }

  render() {
    const { visible, onCancel, placeId } = this.props
    const { formName } = this.state

    const btnSave: AdvancedFormButton = {
      label: __(T.misc.confirm),
      type: 'submit',
      primary: true,
      formName: formName,
    }

    const btnCancel: AdvancedFormButton = {
      label: __(T.misc.cancel),
      type: 'handler',
      primary: false,
      handlerCallback: () => onCancel(),
    }
    return (
      <Modal
        title={
          <Row gutter={22} style={{ marginTop: '20px' }}>
            <Col span={18}>
              <Title level={3}>
                <b>{__(T.scales.create_new)}</b>
              </Title>
            </Col>
            <Col span={6} style={{ justifyContent: 'flex-end', display: 'flex' }}>
              <CloseCircleOutlined style={{ fontSize: 22 }} onClick={() => onCancel()} />
            </Col>
          </Row>
        }
        footer={null}
        closable={false}
        open={visible}
        onCancel={() => onCancel()}
        width="50%"
        className="stw-workstation-rfid-antennas-modal stw-modal-form"
      >
        <AdvancedBalanceForm
          formWidth="100%"
          noPaddingPage
          balanceId=""
          placeId={placeId}
          onSave={this.onSave}
          setFormName={this.setFormName}
          buttonActions={[btnCancel, btnSave]}
          displayFormInRow
        />
      </Modal>
    )
  }
}
