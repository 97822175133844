/* eslint-disable func-names */
import { Component } from 'react'
import { Form, Input } from 'antd'
import { __ } from '../../shared/i18n'
import { AdvancedFormField } from './advancedFormTypes'
import { AttributeUtil } from '../../config/utility/utility'

interface Props {
  record: any
  item: AdvancedFormField
  name?: any
  handleChange: any
  readonly: boolean
  labelHidden?: boolean
  help?: any
}

export class AdvancedFormInputField extends Component<Props> {
  render() {
    const { readonly, item, handleChange, labelHidden, help, record } = this.props
    const value = AttributeUtil.getAttribute(record, item.key)
    return (
      <Form.Item
        name={item.key.split('.')}
        className="stylewhere-form-item"
        rules={item.rules}
        help={help || null}
        label={labelHidden ? null : __(`fields.labels.${item.key}`, item.label)}
      >
        <Input
          id={item.key}
          onChange={(e) => handleChange(e)}
          placeholder={item.placeholder || __(`fields.placeholders.${item.key}`, item.placeholder)}
          readOnly={readonly}
          disabled={readonly}
          defaultValue={value}
        />
      </Form.Item>
    )
  }
}
