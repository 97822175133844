import { Component } from 'react'
import { StylewherePage, TableList, AdvancedTableRowColumn } from '../../../../components'
import { ROLES_FILTERS } from '../../../../config/Pages/UsersRoles/Roles'
import { __, T } from '../../../../shared/i18n'
import {
  AdvancedTableConfig,
  AdvancedTableColumn,
  AdvancedTableContentKind,
} from '../../../../components/AdvancedTable'
import { ExtendRouteComponentProps } from '../../../../types'
import { CAPABILITIES, getActionsList, getActionsTableColumns } from '../../../../config/utility/capabilityUtility'
import Roles from '../../../../api/Roles'
import { StwRole } from '../../../../api'
import { getTabs } from '../../../../config/Pages/UsersRoles/Tabs'
import { HomepagePath, HomepageUtility } from '../../../../config/utility/homepageUtility'

interface State {
  tableConfig: AdvancedTableConfig | undefined
  homepagePaths: HomepagePath[]
}

class RoleList extends Component<ExtendRouteComponentProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      homepagePaths: [],
      tableConfig: undefined,
    }
  }

  componentDidMount = async () => {
    const paths = await HomepageUtility.getPagePaths()
    this.setState({
      tableConfig: {
        filterDefinitions: ROLES_FILTERS,
        manageColumnsPrefix: 'roles',
        activeDefaultClassColumn: true,
        columns: this.getTableColumns(),
      },
      homepagePaths: paths,
    })
  }

  getTableColumns = (): AdvancedTableColumn[] => {
    return [
      {
        key: 'code',
        dataIndex: 'code',
        title: __('fields.labels.code'),
        className: 'stw-medium',
      },
      {
        key: 'description',
        dataIndex: 'description',
        title: __('fields.labels.description'),
        className: 'stw-extra-medium',
      },
      {
        key: 'priority',
        dataIndex: 'priority',
        title: __('fields.labels.priority'),
        type: AdvancedTableContentKind.NUMBER,
      },
      {
        key: 'homepage',
        dataIndex: 'homepage',
        title: __('fields.labels.homepage'),
        render: (text, record) => this.getHomepageDescription(record),
      },
      {
        key: 'capabilityGroups',
        dataIndex: 'capabilityGroups',
        title: __('fields.labels.capabilityGroups'),
        sortable: false,
        render: (text, record) => {
          const elements = record.capabilityGroups.map((capabilityGroup) => <div>{capabilityGroup.code}</div>)
          return <>{elements}</>
        },
      },
      {
        key: 'operations',
        dataIndex: 'operations',
        title: __('fields.labels.operations'),
        sortable: false,
        className: 'stw-large',
        render: (text, record) => {
          const elements = record.operations.map((operation) => (
            <div>
              {operation.type}: {operation.code}
            </div>
          ))
          return <>{elements}</>
        },
      },
      {
        key: 'creationDate',
        dataIndex: 'creationDate',
        title: __('fields.labels.creationDate'),
        type: AdvancedTableContentKind.DATE_TIME,
      },
      {
        key: 'lastModifyDate',
        dataIndex: 'lastModifyDate',
        title: __('fields.labels.lastModifyDate'),
        type: AdvancedTableContentKind.DATE_TIME,
      },
      {
        key: 'action',
        className: 'stw-small',
        fixedType: 'right',
        sortable: false,
        unmanageable: true,
        render: (text, record) => (
          <AdvancedTableRowColumn
            actions={getActionsTableColumns(
              record.id,
              '/configuration/usersRoles/roles',
              CAPABILITIES.RESOURCE_ROLE_EDIT
            )}
          />
        ),
      },
    ]
  }

  getHomepageDescription = (role: StwRole): string => {
    const { homepagePaths } = this.state
    const path = homepagePaths.find((value) => value.id === role.homepage)
    return path ? path.description : 'n/a'
  }

  render() {
    const { tableConfig } = this.state
    return (
      <StylewherePage {...this.props} noOverflow>
        {tableConfig && (
          <TableList
            tabs={getTabs(3)}
            location={this.props.location}
            config={tableConfig}
            resource={{
              call: Roles.searchTableList,
              endpoint: Roles.endpoint,
            }}
            actions={getActionsList(
              CAPABILITIES.RESOURCE_ROLE_EDIT,
              __(T.role.create_new),
              '/configuration/usersRoles/roles/create/form'
            )}
          />
        )}
      </StylewherePage>
    )
  }
}

export default RoleList
