import { StwUser } from './types'
import { keycloak } from '../shared'
import AppStore from '../shared/AppStore'
import { api, responseErrorCheck } from './api'

export class Auth {
  static async login() {
    try {
      return await keycloak.init({ onLoad: 'login-required', checkLoginIframe: false })
    } catch (error) {
      return undefined
    }
  }

  static async logout(): Promise<void> {
    await AppStore.logout()
  }

  static async loggedUser(): Promise<StwUser | undefined> {
    const res = await api.get<StwUser>(`api/v1/users/loggedUser`)
    return responseErrorCheck<StwUser>(res)
  }

  static setHeaderAccessToken(token: string) {
    return api.setHeaders({
      // set headers to 'api' directly otherwise wont work
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `Bearer ${token || undefined}`,
    })
  }
}
