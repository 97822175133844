import { LoadingOutlined } from '@ant-design/icons'
import { Row, Typography } from 'antd'
import { Component } from 'react'
import { __ } from '../shared/i18n'

const { Text } = Typography

type SpinSize = 'small' | 'large' | 'default'

interface Props {
  tip?: string
  size?: SpinSize
  className?: string
  mt?: number
  vAlign?: string
  fontSize?: number
  disableTip?: boolean
}

export class Loader extends Component<Props> {
  render() {
    const { tip, className, mt, vAlign, fontSize, disableTip } = this.props
    const mTop = mt !== null && mt !== undefined ? mt : 30
    return (
      <Row
        className={className || ''}
        style={{
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: vAlign || 'center',
          marginTop: mTop,
          width: '100%',
        }}
      >
        {/* <Spin size={size || 'large'} tip={tip || 'Loading...'} /> */}
        <LoadingOutlined style={{ fontSize: fontSize || 50, marginBottom: 10 }} />
        {!disableTip && <Text>{tip || __('misc.loadingDate')}</Text>}
      </Row>
    )
  }
}
