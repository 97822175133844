/* eslint-disable func-names */
import { Component } from 'react'
import { Button, Space, Typography } from 'antd'
import { SettingOutlined } from '@ant-design/icons'
import {
  AdvancedTableColumns,
  AdvancedTableFiltersDef,
  AdvancedTableFilterValues,
  AdvancedTablePagination,
} from '../AdvancedTable/advancedTableTypes'
import { Filters, TableHeaderFilterButton, TableHeaderManagementButton } from '..'
import { __, T } from '../../shared/i18n'

interface Props {
  title?: string
  subtitle?: any
  marginBottom?: number
  noBorder?: boolean
  managementColumns?: (...args: any[]) => any
  visibleManagerColumns?: boolean
  visibleFilterForm?: boolean
  columns?: AdvancedTableColumns
  baseColumns?: AdvancedTableColumns
  filters?: AdvancedTableFiltersDef
  refreshTableColumns?: (...args: any[]) => any
  managementFilters?: (...args: any[]) => any
  addButton?: any
  parentPadding?: number
  configuration?: any
  manageColumnsPrefix?: string
  filterApplied?: number
  onFiltersChanged?: (filterKey: string, filterValue: any) => void
  onFiltersApplied?: (filterValues: AdvancedTableFilterValues) => void
  pagination?: AdvancedTablePagination
  filterPanelOpened?: (status) => void
  filterOpened?: boolean
  filterParameters?: any
  showExportModal?: (...args: any[]) => any
}

const { Title, Text } = Typography

export class SectionTitle extends Component<Props> {
  managementFilters = () => {
    const { visibleManagerColumns, managementColumns, filterPanelOpened, filterOpened } = this.props
    if (visibleManagerColumns && managementColumns) {
      managementColumns()
    }
    if (filterPanelOpened) {
      filterPanelOpened(!filterOpened)
    }
  }

  managerColumnsPanel = () => {
    const { filterPanelOpened, filterOpened, managementColumns } = this.props
    if (filterOpened && filterPanelOpened) {
      filterPanelOpened(!filterOpened)
    }
    managementColumns?.()
  }

  render() {
    const {
      title,
      subtitle,
      marginBottom,
      noBorder,
      columns,
      baseColumns,
      filters,
      visibleManagerColumns,
      visibleFilterForm,
      refreshTableColumns,
      managementColumns,
      addButton,
      configuration,
      parentPadding,
      manageColumnsPrefix,
      filterApplied,
      filterPanelOpened,
      onFiltersChanged,
      onFiltersApplied,
      pagination,
      filterOpened,
      filterParameters,
      managementFilters,
      showExportModal,
    } = this.props
    return (
      <Space
        direction="vertical"
        style={{
          marginBottom: marginBottom ?? 40,
          borderBottom: noBorder ? '0px' : '1px solid #ececec',
          marginLeft: parentPadding ? `-${parentPadding}px` : '-24px',
          marginRight: parentPadding ? `-${parentPadding}px` : '-24px',
          paddingLeft: parentPadding ? `${parentPadding}px` : '24px',
          paddingRight: parentPadding ? `${parentPadding}px` : '24px',
          paddingBottom: noBorder ? '0px' : '10px',
          display: 'block',
          width: 'auto',
        }}
        className="formTitle"
      >
        <Title className="stylewhere-form-title" level={3}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ flex: 1 }}>{title}</div>
            <div style={{ flex: 0, display: 'flex', flexDirection: 'row' }}>
              {addButton && (
                <Button
                  className={addButton.class || 'stylewhere-button-secondary'}
                  onClick={() => addButton.callback()}
                >
                  <Text style={{ color: addButton.color || '#FFF' }}>{addButton.label}</Text>
                </Button>
              )}

              {showExportModal && (
                <Button
                  style={{ marginRight: 10 }}
                  className="stylewhere-button-secondary"
                  onClick={() => showExportModal()}
                >
                  {__(T.misc.export)}
                </Button>
              )}
              <TableHeaderFilterButton
                active={filters && filters.length > 0}
                managementFilters={managementFilters ? managementFilters() : this.managementFilters()}
                filterOpened={visibleFilterForm}
                filterApplied={filterApplied}
                customStyle={{ marginLeft: addButton ? 10 : 0 }}
              />

              <TableHeaderManagementButton
                active={managementColumns && columns && columns.length > 0}
                columns={columns}
                baseColumns={baseColumns}
                managerColumnsPanel={this.managerColumnsPanel}
                refreshTableColumns={refreshTableColumns}
                managementColumns={managementColumns}
                visibleManagerColumns={visibleManagerColumns || false}
                manageColumnsPrefix={manageColumnsPrefix}
                customTop="42px"
              />
              {configuration && (
                <Button
                  style={{ padding: 0, alignItems: 'center', display: 'flex' }}
                  onClick={configuration}
                  type="link"
                >
                  <SettingOutlined style={{ color: '#333', fontSize: 18 }} /> {__(T.misc.attributeConfiguration)}
                </Button>
              )}
            </div>
          </div>
        </Title>
        {subtitle && <Text style={{ textAlign: 'left' }}>{subtitle}</Text>}
        <Filters
          managementFilters={managementFilters ? managementFilters() : this.managementFilters}
          filters={filters}
          filterParameters={filterParameters || {}}
          columns={columns}
          filterPanelOpened={filterPanelOpened || undefined}
          managementColumns={managementColumns}
          visibleManagerColumns={visibleManagerColumns}
          refreshTableColumns={refreshTableColumns}
          onFiltersChanged={onFiltersChanged}
          onFiltersApplied={onFiltersApplied}
          pagination={pagination}
          filterApplied={filterApplied}
          manageColumnsPrefix={manageColumnsPrefix}
          filterOpened={filterOpened}
          maxHeightPanel={400}
        />
      </Space>
    )
  }
}
