import { Component } from 'react'
import { StylewherePage, TableList } from '../../../components'
import { DEVICE_CONTROLLERS_COLUMNS, DEVICE_CONTROLLERS_FILTERS } from '../../../config/Pages/Devices/DeviceControllers'
import { BooleanComboValues, DeviceControllerTypes } from '../../../constants'
import { __, T } from '../../../shared/i18n'
import { AdvancedTableConfig } from '../../../components/AdvancedTable'
import { ExtendRouteComponentProps } from '../../../types'
import { CAPABILITIES, getActionsList } from '../../../config/utility/capabilityUtility'
import DeviceControllers from '../../../api/DeviceControllers'

const DEVICE_CONTROLLERS_TABLE_CONFIG: AdvancedTableConfig = {
  columns: DEVICE_CONTROLLERS_COLUMNS,
  filterDefinitions: DEVICE_CONTROLLERS_FILTERS,
  filterParameters: { enabledValues: BooleanComboValues, types: DeviceControllerTypes },
  manageColumnsPrefix: 'deviceControllers',
  activeDefaultClassColumn: true,
}

class DeviceControllersList extends Component<ExtendRouteComponentProps> {
  render() {
    return (
      <StylewherePage {...this.props} noOverflow>
        <TableList
          title={__(T.menu.deviceControllers)}
          location={this.props.location}
          config={DEVICE_CONTROLLERS_TABLE_CONFIG}
          resource={{
            call: DeviceControllers.searchTableList,
            endpoint: DeviceControllers.endpoint,
          }}
          actions={getActionsList(
            CAPABILITIES.RESOURCE_DEVICE_CONTROLLER_EDIT,
            __(T.deviceControllers.create_new),
            '/devices/controllers/create/form'
          )}
        />
      </StylewherePage>
    )
  }
}

export default DeviceControllersList
