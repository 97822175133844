import { Component } from 'react'
import { Row } from 'antd'
import { GridCardsRow } from '.'
import { Card } from '../types'

interface Props {
  cards: Card[]
  mTop?: number
}

export class GridCards extends Component<Props> {
  render() {
    const { cards, mTop } = this.props
    return (
      <Row className="stylewhere-cards" style={{ marginTop: mTop || 10 }}>
        {cards.map((item, i) => {
          return <GridCardsRow key={`card-${i}`} card={item} />
        })}
      </Row>
    )
  }
}
