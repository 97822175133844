import { ModalProps } from 'antd'
import { ExtendRouteComponentProps } from 'core/types'
import { ReactElement, ReactNode } from 'react'
import { RouteComponentProps } from 'react-router'
import { AdvancedTableContentKind, AdvancedTableFilterKind } from '../components/AdvancedTable'

/**
 * Utility type for search filters.
 * Transforms an union of strings to an object of search params compatible with api
 *
 * @example
 * type SF = 'code' | 'description' | 'place.id'
 * type SFV = SearchFiltersValues<SF>
 *   => { code?: string | string[], description?: string | string[], 'place.id'?: string | string[] }
 * const urlQuery = '?code=a&code=b&description=c'
 * const queryParams = extract<SF>(urlQuery): SFV => { code: ['a', 'b'], description: 'c' }
 * await api.get(..., queryParams)
 */
export type SearchFiltersValues<SF extends string = string> = Partial<Record<SF, string | string[]>>

export type PageTableProps = { breadcrumbs: any } & (RouteComponentProps | ExtendRouteComponentProps)

export interface BaseResource {
  id: string
  creationDate?: string
  lastModifyDate?: string | null
  code?: string
  description?: string

  [key: string]: any
}

export interface ItemIdentifier {
  id: string
  creationDate: string
  lastModifyDate: string
  code: string
  description?: string
  role?: any
  item?: any
  type: string
  identifierGroup?: any
  tid?: string
  group: string
  itemId: string | null
}

export interface TmrMovement {
  type: string
  id: string
  creationDate: string
  lastModifyDate?: string
  description?: any
  user: StwUser
  originPlace: StwPlace
  destinationPlace: StwPlace
  destinationZone?: StwZone
  reverse: boolean
  transfersQuantity: number
  encodingZone: StwZone
}

export interface TmrTransfer {
  id: string
  creationDate: string
  lastModifyDate?: string
  movement: TmrMovement
  origin?: any
  destination: StwZone
  item: StwItem
}

export interface ItemConfigurationDetail {
  identifierType: string
  required: boolean
  role: string
}

export type TmrStatus = 'ignored' | 'warning' | 'error'

export interface StwItemIdentifier {
  id: string
  type: string
  code: string
  itemId: string
  state: 'NOT_ASSOCIATED' | 'ASSOCIATED' | 'REPLACED_ASSOCIATION'
}

export interface StwItem {
  id: string
  creationDate?: string
  lastModifyDate?: string
  product: StwProduct
  type?: string
  // zone?: StwZone
  // zoneId?: string
  attributes?: StwAttributesMap
  // productType?: string
  identifiers: StwItemIdentifier[]
}

export interface DecodedItem<Statuses = string> {
  item: StwItem | null
  statuses: Statuses[]
}

interface StwBaseRfidAntenna {
  id?: string
  creationDate?: string
  lastModifyDate?: string
  code?: string
  description?: string
  readerPort?: number
  txPower?: number
  rxSensitivity?: number
  reader?: StwRfidReader
}

export interface StwRfidAntenna extends StwBaseRfidAntenna {
  zoneId?: string
  errorId?: string
}

export interface StwFullRfidAntenna extends StwBaseRfidAntenna {
  zone?: StwZone
}

export interface StwRfidReader {
  id?: string
  creationDate?: string
  lastModifyDate?: string
  code?: string
  description?: string
  place?: StwPlace
  deviceType?: StwRfidReaderType
  ports?: StwGpoPort[]
  settings?: SimpleSettingsMap
}

export interface StwRfidReaderAntennas extends StwRfidReader {
  antennas?: any[]
}

export interface StwRfidReaderWithCounter extends StwRfidReader {
  numAntennas?: number
}

export enum StwRfidReaderType {
  CAEN_HEX = 'caen-slate',
  NORDIC_ID_SAMPO_NATIVE = 'nordic-id-sampo-native',
  KIT_NLO_LABID = 'kit-nlo-labid',
  FEIG_NFC = 'feig-nfc',
  IMPINJ_XARRAY = 'impinj-xarray',
  NORDIC_ID_SAMPO_LABID = 'nordic-id-sampo-labid',
  IMPINJ_SPEEDWAY_OCTANE = 'impinj-speedway-octane',
  ZEBRA_FX_SERIES = 'zebra-fx-series',
  CAEN_QUATTRO = 'caen-quattro',
  RFID_EMULATOR = 'rfid-emulator',
  SENSORMATIC = 'sensormatic',
}

export interface StwFullRfidReader extends StwRfidReader {
  connectionMode?: 'ETHERNET' | 'USB'
  ipAddress?: string
  maxConnectionTimeout?: number
  readerMode?:
    | 'AUTO_SET_DENSE_READER'
    | 'AUTO_SET_DENSE_READER_DEEP_SCAN'
    | 'DENSE_READER_M4'
    | 'DENSE_READER_M4_TWO'
    | 'DENSE_READER_M8'
    | 'HYBRID'
    | 'MAX_MILLER'
    | 'MAX_THROUGHPUT'
  searchMode?: 'DUAL_TARGET' | 'SINGLE_TARGET'
  session?: string
  keepAliveEnabled?: boolean
  keepAlivePeriod?: number
  linkMonitorModeEnabled?: boolean
  linkDownThreshold?: number
  triggerTimeoutSelect?: 'triggerEnabled' | 'timeoutEnabled' | undefined
  triggerEnabled?: boolean
  timeoutEnabled?: boolean
  startType?: 'FALLING' | 'RISING'
  gpioStartPort?: number
  stopType?: 'FALLING' | 'RISING'
  gpioStopPort?: number
  debounceTime?: number
  timeout?: number
  emulationMode?: 'RANDOM_EPC' | 'EPC_LIST'
  epcs?: string
  minEpcDelay?: number
  maxEpcDelay?: number
  memoryBank?: 'EPC' | 'TID'
  socketPort?: number
  gpioPolling?: number
  loginUsername?: string
  loginPassword?: string
  rxSensitivity?: number
}

export interface StwFullRfidReaderForm extends StwRfidReader {
  connectionMode?: 'ETHERNET' | 'USB'
  ipAddress?: string
  maxConnectionTimeout?: number
  readerMode?:
    | 'AUTO_SET_DENSE_READER'
    | 'AUTO_SET_DENSE_READER_DEEP_SCAN'
    | 'DENSE_READER_M4'
    | 'DENSE_READER_M4_TWO'
    | 'DENSE_READER_M8'
    | 'HYBRID'
    | 'MAX_MILLER'
    | 'MAX_THROUGHPUT'
  searchMode?: 'DUAL_TARGET' | 'SINGLE_TARGET'
  session?: string
  keepAliveEnabled?: boolean
  keepAlivePeriod?: number
  linkMonitorModeEnabled?: boolean
  linkDownThreshold?: number
  triggerTimeoutSelect?: 'triggerEnabled' | 'timeoutEnabled'
  triggerEnabled?: boolean
  timeoutEnabled?: boolean
  startType?: 'FALLING' | 'RISING'
  gpioStartPort?: number
  stopType?: 'FALLING' | 'RISING'
  gpioStopPort?: number
  debounceTime?: number
  timeout?: number
  antenna1?: StwRfidAntenna
  antenna2?: StwRfidAntenna
  antenna3?: StwRfidAntenna
  antenna4?: StwRfidAntenna
  antenna5?: StwRfidAntenna
  antenna6?: StwRfidAntenna
  antenna7?: StwRfidAntenna
  antenna8?: StwRfidAntenna
  totalAntennas?: number
  emulationMode?: 'RANDOM_EPC' | 'EPC_LIST'
  epcs?: string
  minEpcDelay?: number
  maxEpcDelay?: number
  memoryBank?: 'EPC' | 'TID'
  socketPort?: number
  gpioPolling?: number
  loginUsername?: string
  loginPassword?: string
  rxSensitivity?: number
}

export interface StwGpoPort {
  id: string
  reader?: StwRfidReader
  creationDate?: string
  lastModifyDate?: string
  portNumber?: number
  pulseDuration?: number
  gpoMode?: StwGpoMode
}

export type StwGpoMode =
  | 'NORMAL'
  | 'PULSED'
  | 'READER_OPERATIONAL_STATUS'
  | 'LLRP_CONNECTION_STATUS'
  | 'READER_INVENTORY_STATUS'
  | 'NETWORK_CONNECTION_STATUS'
  | 'READER_INVENTORY_TAGS_STATUS'

export interface StwProduct {
  // also called "MasterData"
  id?: string
  code?: string
  creationDate?: string
  lastModifyDate?: string
  description?: string
  shortDescription?: string
  style?: StwValue
  material?: StwValue
  color?: StwValue
  size?: StwValue
  season?: string
  attributes?: StwAttributesMap
  rfidEnabled?: boolean
  drop?: string
}

export interface StwAttributesMap {
  [key: string]: StwValue
}

export interface StwValue {
  value?: string
  description?: string | null
}

export interface StwZone {
  id?: string
  creationDate?: string
  lastModifyDate?: string
  code?: string
  description?: string
  place?: StwPlace
  type?: 'SOLD' | 'STOCK' | 'LOST' | 'INBOUND'
  attributes?: SimpleAttributesMap
  defaultZone?: boolean
}

export interface StwJob {
  id?: string
}

export interface SimpleAttributesMap {
  [key: string]: string
}

export interface SimpleSettingsMap {
  [key: string]: any
}

export interface StwUser {
  id?: string
  creationDate?: string
  lastModifyDate?: string
  email?: string
  name?: string
  surname?: string
  username: string
  attributes?: SimpleAttributesMap
  capabilities?: string[]
  roles?: StwRole[]
  userPlaces?: StwPlace[]
  homepage?: string
}

export interface StwCapability {
  id?: string
  creationDate?: string
  lastModifyDate?: string
  code?: string
}

export interface StwCapabilitiesGroup {
  id?: string
  creationDate?: string
  lastModifyDate?: string
  code?: string
  description?: string
  featureType?: 'OPERATION' | 'REPORT' | 'SYSTEM' | 'CONFIGURATION'
  capabilities?: StwCapability[]
}

export interface StwPlace {
  classType?: string
  id?: string
  creationDate?: string
  lastModifyDate?: string
  code?: string
  description?: string
  type?: 'CUSTOMER' | 'STORE' | 'SUPPLIER' | 'WAREHOUSE'
  externalCode?: string
  address?: string
  countryCode?: string
  attributes?: SimpleAttributesMap
  labels?: string[]
  latitude?: string
  longitude?: string
  createDefaultZones?: boolean
}

export interface StwRole {
  id?: string
  creationDate?: any
  lastModifyDate?: any
  code?: string
  description?: string
  capabilities?: string[]
  capabilityGroups?: StwCapabilitiesGroup[]
  operations?: StwOperation[]
  priority?: number
  homepage?: string
  attributes?: SimpleAttributesMap
}

export interface StwRoleMapping {
  id?: string
  creationDate?: any
  lastModifyDate?: any
  code: string
  description?: string
  roles?: StwRole[]
}

export interface StwItemConfiguration {
  id?: string
  itemType?: string
  code?: string
  description?: any
  creationDate?: any
  lastModifyDate?: string
  details?: StwItemConfigurationDetail[]
}

export interface StwItemConfigurationDetail {
  identifierType?: IdentifierType
  role?: string
}

export interface StwItemType {
  id?: string
  itemType?: string
  code?: string
  description?: any
  creationDate?: any
  lastModifyDate?: string
  details?: StwItemConfigurationDetail[]
}

export type IdentifierType = 'UHF_TAG' | 'NFC_TAG' | 'SIMPLE_ITEM_IDENTIFIER' | string

export type IdentifierStatus = 'reading' | 'waiting' | 'confirmed' | 'error' | 'hidden' | 'associating'

export interface Identifiers {
  type: IdentifierType
  amount: number
}

export interface ShipmentParcel extends ShipmentParcelPreview {
  expectedItems: ShipmentParcelItem[]
  detectedItems: ShipmentParcelItem[]
  expectedTags: ShipmentParcelTag[]
  detectedTags: ShipmentParcelTag[]
  expectedUpcs: ShipmentParcelUpc[]
  detectedUpcs: ShipmentParcelUpc[]
  products: { [upc: string]: StwProduct }
  parcels?: ShipmentParcel[]
  groupedProducts?: GroupedItemsByProduct[]
}

export interface ShipmentParcelItem extends StwItem {
  id: string
  productCode: string
  zoneId?: string
  zoneCode?: string
  expected?: boolean
}

export interface ShipmentParcelUpc {
  upc: string
  quantity: number
  expected?: boolean
}

export interface ShipmentParcelTag {
  tag: string
  productCode?: string
  expected?: boolean
}

export interface ShipmentParcelPreview {
  header: ShipmentParcelHeader
  expectedCounter: ShipmentParcelCounter
  detectedCounter: ShipmentParcelCounter
  unexpectedCounter: ShipmentParcelCounter
  missingCounter: ShipmentParcelCounter
  parcels?: ShipmentParcelPreview[]
  parcelsCode?: string
}

export interface ShipmentParcelCounter {
  items: number
  tags: number
  upcs: number
}

export interface ShipmentParcelHeader {
  parcelCode: string
  parcelState: ParcelState
  shippingCode: string
  originPlaceCode?: string
  originPlace?: StwPlace
  destinationPlace: StwPlace
  configurationCode?: string
  operationType?: OperationType
  parcelAttributes?: any
  parcelCreationDate?: string
}

export type OperationType = 'SEND' | 'RECEIVE'

export type ParcelState =
  | 'DRAFT'
  | 'SEND_AWAIT'
  | 'READY_TO_SEND' // <-- used only in shipment mode (and only for parcels statuese)
  | 'OUTBOUND_ERROR'
  | 'IN_TRANSIT' // old release was -> SENT
  | 'IN_INBOUND'
  | 'RECEIVE_AWAIT'
  | 'READY_TO_RECEIVE' // <-- used only in shipment mode (and only for parcels statuese)
  | 'INBOUND_ERROR'
  | 'RECEIVED'
  | 'IN_OUTBOUND'

// eslint-disable-next-line @typescript-eslint/naming-convention
export type clientType = 'frontend' | 'mobile' | 'station'

export type BackendOperationConfigAttributes =
  | 'tmr.operation.config.reading.mode'
  | 'tmr.operation.config.inbound.mode'
  | 'tmr.operation.config.outbound.mode'
  | 'tmr.operation.config.missing.confirm'
  | 'tmr.operation.config.overqty.confirm'
  | 'tmr.operation.config.unexpected.confirm'
  | 'tmr.operation.config.force.confirm'
  | 'tmr.operation.config.remove.mode'
  | 'tmr.operation.config.custom.page'
  | 'tmr.operation.config.unknownItemsAction'
  | 'tmr.operation.config.itemvalidation'
  | 'tmr.operation.config.confirmmode'
  | 'tmr.operation.config.outbound.checklist.autocreate'
  | 'tmr.operation.config.visualization.mode'
  | 'tmr.operation.config.visualization.order'
  | 'tmr.operation.config.outbound.warning.states'
  | 'tmr.operation.config.outbound.error.states'
  | 'tmr.operation.config.outbound.ignore.states'
  | 'tmr.operation.config.outbound.require.states'
  | 'tmr.operation.config.inbound.warning.states'
  | 'tmr.operation.config.inbound.error.states'
  | 'tmr.operation.config.inbound.ignore.states'
  | 'tmr.operation.config.inbound.require.states'

export interface OperationConfigAttributes {
  readingMode?: 'rfid' | 'mixed' | 'barcode' // tmr.operation.config.reading.mode
  inboundMode?: 'parcelByParcel' | 'multiParcel' | 'shipment' // tmr.operation.config.inbound.mode
  outboundMode?: 'parcelByParcel' | 'shipment' // tmr.operation.config.outbound.mode
  canConfirmWithMissing?: 'no' | 'yes' | 'withWarning' // tmr.operation.config.missing.confirm
  canConfirmWithOverQuantity?: 'no' | 'yes' | 'withWarning' // tmr.operation.config.overqty.confirm
  canConfirmWithUnexpected?: 'no' | 'yes' | 'withWarning' // tmr.operation.config.unexpected.confirm
  canForceConfirm?: 'no' | 'yes' | 'withExpectedReadings' // tmr.operation.config.force.confirm
  removeMode?: 'sku' | 'rfid' | 'identifier' | 'none' // tmr.operation.config.remove.mode
  customPage?: string // eg. CustomInboundScreen // tmr.operation.config.custom.page
  outboundWarningStates?: string[]
  outboundErrorStates?: string[]
  outboundIgnoreStates?: string[]
  outboundRequireStates?: string[]
  inboundWarningStates?: string[]
  inboundErrorStates?: string[]
  inboundIgnoreStates?: string[]
  inboundRequireStates?: string[]

  // backend only Configurations
  itemValidation?: 'standard' | 'decode' | 'custom' // tmr.operation.config.itemvalidation
  unknownItemsAction?: 'createItemIfProductExists' | 'createItemAndProduct' | 'ignoreItem'
  confirmMode?: 'sync' | 'async' // tmr.operation.config.confirmmode
  outboundChecklistAutocreate?: 'yes' | 'no' // tmr.operation.config.outbound.checklist.autocreate

  // fe only configurations
  visualizationMode?: 'all' | 'missing' | 'expected' | 'unexpected' | 'invalid' | 'lastselected' // tmr.operation.config.visualization.mode
  visualizationOrder?: 'sku' | 'read' | 'error' // tmr.operation.config.visualization.order
}

export interface GroupedItemsByProduct {
  product: StwProduct
  expected: number
  detected: number
  unexpected: number
  status?: TmrStatus
  items: DecodedItem[]
}

export interface ShippingParcelEntry {
  id: string
  creationDate: string
  itemId: string
  parcelId: string
  detected: 0 | 1
  unexpected: 0 | 1
  productCode: string
  type: 'ITEM'
}

export interface ResourcePage<T> {
  content: T[]
  empty: boolean
  first: boolean
  last: boolean
  number: number
  numberOfElements: number
  size: number
  totalElements: number
  totalPages: number
}

export interface StwOperationAction {
  actionCode: string
  description: string
  showAsCPActionButton: boolean
}

export interface StwOperation {
  id?: string
  code?: string
  description?: string
  enabled?: boolean
  type?: StwOperationType
  formSchema?: string
  clients?: string[]
  displayPriority?: number
  options?: SimpleAttributesMap
  attributes?: SimpleAttributesMap
  actions?: StwOperationAction[]
  creationDate?: string
  lastModifyDate?: string
}

export type DetailCeilType =
  | 'string'
  | 'date'
  | 'codeDesc'
  | 'valueDesc'
  | 'user'
  | 'enum'
  | 'boolean'
  | 'stringList'
  | 'json'
  | 'custom'
  | 'number'
  | 'link'

export interface DetailCell {
  title?: string
  attribute: string
  columns?: number
  fillCols?: number
  type?: DetailCeilType
  render?: (record) => ReactNode
}

export type StwOperationType =
  | 'ENCODING'
  | 'TRANSFER_TO_ZONE'
  | 'INBOUND'
  | 'OUTBOUND'
  | 'INVENTORY'
  | 'INVENTORY_PARCEL'
  | 'TAG_INFO'
  | 'SIMPLE_ITEM_LIST'
  | 'SIMPLE_PARCEL_LIST'
  | 'CYCLE_COUNT'
  | 'PRINT'
  | 'PHASETO_PHASE'
  | 'PACKING'
  | 'PICKING'
  | 'SORTING'
  | 'SIMPLE_ITEM_SESSION_LIST'

export interface StwOperationOption {
  id?: string
  operationType?: string
  description?: string
  optionKey?: string
  creationDate?: string
  lastModifyDate?: string
}

export interface StwOperationLog {
  id?: string
}

export interface StwBaseReport {
  id: string
  description: string
}

export interface StwReportMetadata {
  id: string
  description: string
  columns: ReadonlyArray<StwReportColumnMetadata>
  filters: ReadonlyArray<StwReportFilterMetadata>
}

export interface StwReportColumnMetadata {
  parameter: string
  sort: string
  description: string
  type: AdvancedTableContentKind
  unsortable?: boolean
  technicalField?: boolean
  hidden?: boolean
}

export interface StwReportFilterMetadata {
  parameter: string
  columnParameter: string
  entityAttribute: string
  type: AdvancedTableFilterKind
  endpoint?: string
  sort?: string
  additionalParams?: any
  multiple?: boolean
  values?: string[]
  technical: boolean
  dependencies?: string[]
  autocompleteFieldLabel?: string
}

export interface StwReportQueryResult {
  [key: string]: any
}

export interface StwAsn {
  id: string
  code: string
  description: string
  originPlaceId?: string
  destinationPlaceId?: string
  intransitZoneId?: string
  clientPlaceId?: string
  operationId?: string
  creationUserId?: string
  lastModifyUserId?: string
  checklistId?: string
  ddtNumber?: string
  autoCreated?: boolean
  ddtDate?: string
  shippingDate?: string
  receivingDate?: string
  attributes?: StwAttributesMap
  creationDate?: string
  lastModifyDate?: string
}

interface StwBaseMovement {
  id: string
  description: string
  parcel?: StwParcel
  creationUserId?: string
  operationId?: string
  attributes?: StwAttributesMap
  parcelEntryQuantity?: number
  creationDate: string
}

interface StwMovement extends StwBaseMovement {
  asn?: StwAsn
  destinationZoneId?: string
  originPlaceId?: string
  destinationPlaceId?: string
  checklistId?: string
}

//Ho messo l'id per un bug del compilatore
export interface StwOutbound extends StwMovement {
  id: string
}

export interface StwInbound extends StwMovement {
  id: string
}

export interface StwPacking extends StwBaseMovement {
  destinationZoneId?: string
}

export enum MovementType {
  INBOUND = 'INBOUND',
  OUTBOUND = 'OUTBOUND',
  PACKING = 'PACKING',
}

interface StwFullBaseMovement {
  id: string
  type: MovementType
  description: string
  parcel?: StwParcel
  creationUser?: StwUser
  operation?: StwOperation
  attributes?: StwAttributesMap
  parcelEntryQuantity?: number
  creationDate: string
}

interface StwFullMovement extends StwFullBaseMovement {
  asn?: StwAsn
  destinationZone?: StwZone
  checklist?: StwChecklist
  originPlace?: StwPlace
  destinationPlace?: StwPlace
}

export interface StwFullOutbound extends StwFullMovement {
  totalDetected: number
  totalUnexpected: number
  totalExpected: number
}

export interface StwFullInbound extends StwFullMovement {
  totalDetected: number
  totalUnexpected: number
  totalExpected: number
}

export interface StwFullPacking extends StwFullBaseMovement {
  destinationZone?: StwZone
}

export interface StwParcel {
  id: string
  code: string
  description: string
  creationUserId?: string
  lastModifyUserId?: string
  operationId?: string
  shippingDate?: string
  receivingDate?: string
  palletCode?: string
  clientPlaceId?: string
  weight?: string
  state?: string
  zoneId?: string
  attributes?: StwAttributesMap
  asnId?: string
  checklistId?: string
  errorMessage?: string
  parcelEntryQuantity?: number
  creationDate: string
  lastModifyDate: string
}

export interface StwFullParcel {
  id: string
  code: string
  description: string
  creationUser?: StwUser
  lastModifyUser?: StwUser
  operation?: StwOperation
  shippingDate?: string
  receivingDate?: string
  palletCode?: string
  clientPlace?: StwPlace
  weight?: string
  state?: string
  zone?: StwZone
  attributes?: StwAttributesMap
  asn?: StwAsn
  checklist?: StwChecklist
  errorMessage?: string
  parcelEntryQuantity?: number
  creationDate: string
  lastModifyDate: string
}

export interface StwParcelEntry {
  id: string
  creationDate: string
  itemId?: string
  parcelId: string
  detected: number
  unexpected: number
  productCode: string
  type: 'ITEM' | 'PRODUCT'
}

export interface StwFullParcelEntry {
  id: string
  creationDate: string
  item?: StwItem
  parcel: StwParcel
  detected: number
  unexpected: number
  product?: StwProduct
  type: 'ITEM' | 'PRODUCT'
}

export interface StwProductQuantity {
  productCode: string
  quantity: number
}

export interface StwProductQuantityUI {
  product: StwProduct
  quantity: number
}

export interface StwCreateChecklistCommand {
  code?: string
  checklistPlaceId?: string
  destinationPlaceId?: string
  operationType?: string
  attributes: StwAttributesMap
  createParcel: boolean
  createAsn: boolean
  type: string
  operationId?: string
}

export interface StwCreateChecklistCommandUI extends StwCreateChecklistCommand {
  products?: StwProductQuantityUI[]
  items?: StwItem[]
}

export interface StwCreateChecklistByItemCommand extends StwCreateChecklistCommand {
  itemIds: string[]
  // itemIdentifiers: string[]
}

export interface StwCreateChecklistByProductCommand extends StwCreateChecklistCommand {
  products: StwProductQuantity[]
}

export interface StwChecklist {
  id: string
  type: StwChecklistType
  code: string
  operationType: string
  checklistPlaceId: string
  destinationPlaceId?: string
  attributes: StwAttributesMap
  creationUserId: string
  lastModifyUserId: string
  confirmationUserId: string
  confirmationDate: string
  itemIds: string[]
  products: StwChecklistProduct[]
  creationDate: string
  lastModifyDate: string
}

export interface StwChecklistProduct {
  quantity: number
  productCode: string
  attributes?: StwAttributesMap
}

export interface StwFullChecklist {
  id: string
  type: StwChecklistType
  code: string
  operationType: string
  checklistPlace: StwPlace
  destinationPlace: StwPlace
  attributes?: StwAttributesMap
  creationUser?: StwUser
  lastModifyUser?: StwUser
  confirmationDate: string
  items?: StwItem[]
  confirmationUser: StwUser
  products?: StwChecklistProduct[] | StwFullChecklistProduct[]
  creationDate: string
  lastModifyDate: string
  itemIds?: string[]
}

export interface StwFullChecklistProduct {
  quantity: number
  product: StwProduct
  attributes?: StwAttributesMap
}

export enum StwChecklistType {
  'CHECKLIST_BY_ITEM' = 'CHECKLIST_BY_ITEM',
  'CHECKLIST_BY_PRODUCT_TYPE' = 'CHECKLIST_BY_PRODUCT',
}

export interface StwPallet {
  id: string
  code: string
  description: string
  status: string | null
  carrierCode: string
  attributes?: StwAttributesMap
  creationDate: string
  lastModifyDate: string
}

interface StwBaseXspan {
  id?: string
  creationDate?: string
  lastModifyDate?: string
  code?: string
  description?: string
  deviceController?: StwDeviceController
  autoStart?: boolean
  ipAddress?: string
  txPower?: number
  mode?: StwXspanMode
  fieldOfView?: StwXspanFieldOfView
  tagAgeInterval?: number
  keepAliveEnabled?: boolean
  maxConnectionTimeout?: number
  keepAlivePeriod?: number
  linkMonitorModeEnabled?: boolean
  linkDownThreshold?: number
  transferToZoneEnabled?: boolean
  operation?: StwOperation
  attributes?: StwAttributesMap
}

export interface StwXspan extends StwBaseXspan {
  userId?: string
  placeId?: string
  sector2ZoneId?: string
  sector3ZoneId?: string
}

export interface StwFullXspan extends StwBaseXspan {
  user?: StwUser
  place?: StwPlace
  sector2Zone?: StwZone
  sector3Zone?: StwZone
}

export type StwXspanMode = 'HIGH_PERFORMANCE' | 'HIGH_SENSITIVITY'

export type StwXspanFieldOfView = 'WIDE' | 'NARROW' | 'AUTO'

export interface StwDeviceController {
  id?: string
  code: string
  description?: string
  type: string
  locked?: boolean
  lastSeen?: string
  creationDate?: string
  lastModifyDate?: string
}

export interface StwScanner {
  id?: string
  code?: string
  description?: string
  deviceController?: StwDeviceController
  autoStart?: boolean
  targetZone?: StwZone
  user?: StwUser
  rfidAntennas?: string[]
  operation?: StwOperation
  transferToZoneEnabled?: boolean
  lifespan?: number
  creationDate?: string
  lastModifyDate?: string
  attributes?: StwAttributesMap
}

export interface StwFullScanner extends StwScanner {
  rfidFullAntennas?: StwRfidAntenna[]
}

export interface StwEditableScanner extends StwScanner {
  place?: StwPlace
  reader?: StwRfidReader
}

export interface StwScannerStatus {
  id: string
  deviceId: string
  status: 'RUNNING' | 'STOPPED'
  attributes?: StwAttributesMap
  creationDate: string
  lastModifyDate: string
}

export interface StwPrinter {
  id?: string
  code?: string
  description?: string
  deviceController?: StwDeviceController
  place?: StwPlace
  zone?: StwZone
  deviceType?: 'zebra-card-printer' | 'zebra' | 'epson' | 'avery'
  settings?: SimpleAttributesMap
  attributes?: SimpleAttributesMap
  creationDate?: string
  lastModifyDate?: string
}

export interface StwBarcodeReader {
  id?: string
  code?: string
  description?: string
  place?: StwPlace
  deviceType?: string
  settings?: SimpleAttributesMap
  creationDate?: string
  lastModifyDate?: string
}

export interface StwFullBarcodeReader extends StwBarcodeReader {
  ipAddress?: string
  dataSocketPort?: number
  maxConnectionTimeout?: number
  keepAliveEnabled?: boolean
  keepAlivePeriod?: number
  keepAliveSocketPort?: number
}

export interface StwBalance {
  id?: string
  code?: string
  description?: string
  place?: StwPlace
  deviceType?: string
  settings?: SimpleAttributesMap
  creationDate?: string
  lastModifyDate?: string
}

interface StwBaseWorkstation {
  id?: string
  code?: string
  description?: string
  deviceManagerUrl?: string
  deviceManagerWebsocket?: string
  attributes?: SimpleAttributesMap
  creationDate?: string
  lastModifyDate?: string
}

export interface StwWorkstation extends StwBaseWorkstation {
  placeId?: string
  rfidAntennaIds?: string[]
  balanceId?: string
}

export interface StwWorkstationURL {
  protocol: string
  hostname: string
  port: number
}

export interface StwFullWorkstation extends StwBaseWorkstation {
  place?: StwPlace
  rfidAntennas?: StwRfidAntenna[]
  balance: StwBalance
}

export interface StwEnumerationTypes {
  id?: string
  code?: string
  description?: string
  attributeKeys?: string[]
  creationDate?: string
  lastModifyDate?: string
}

export interface StwEnumerationValues {
  id?: string
  code?: string
  description?: string
  enumerationType?: StwEnumerationTypes
  attributes?: SimpleAttributesMap
  creationDate?: string
  lastModifyDate?: string
}

export interface StwItemConfigurationDetails {
  id: string
  itemConfigurationId?: string
  role?: string
  identifierType?: IdentifierType
}

export interface StwItemIdentifierValue {
  id: string
  creationDate?: string
  lastModifyDate?: string
  type?: IdentifierType
  state?: IdentifierStatus
  code?: string
  group?: string
  role?: string
  itemId?: string
  tid?: string
  ndef?: string
}

export interface ReportCheckListDTO {
  code: string
  type: string
  operationType: string
  operationId: string
  itemIds: string[]
  checklistPlaceId: string
  destinationPlaceId: string
  attributes: string[]
}

interface StwBaseItemEvent {
  id: string
  eventType:
    | 'ITEM_ATTRIBUTE_DELETE'
    | 'ITEM_ATTRIBUTE_UPDATE'
    | 'ITEM_CONFIGURATION_REWRITED'
    | 'ITEM_CREATED'
    | 'ITEM_PRODUCT_UPDATED'
    | 'ITEM_TRANSFERRED'
    | 'ITEM_OPERATION_COMPLETED'
  creationDate?: string
  user?: StwUser
  item?: StwItem
  eventDate?: string
}

export interface StwFullItemEvent extends StwBaseItemEvent {
  keyItemAttributes?: string[]
  attributes?: StwAttributesMap
  itemAttributes?: StwAttributesMap
  itemType?: string
  zone?: StwZone
  itemProduct?: StwProduct
  destinationZone?: StwZone
  operationType?: StwOperationType
  operationId?: string
  operationInstanceId?: string
  operationLabel?: string
  itemConfigurationDetails?: StwItemConfigurationDetails[]
  itemIdentifiers?: StwItemIdentifierValue[]
  details?: any
}

interface StwBaseInventoryParcel {
  id?: string
  description?: string
  closeDate?: string
  state?: StwInventoryParcelState
}

export type StwInventoryParcelState = 'OPEN' | 'CLOSED'

export interface StwInventoryParcel extends StwBaseInventoryParcel {
  placeId?: string
  creationUserId?: string
  closeUserId?: string
  zoneIds?: string[]
  operationId?: string
  ignoreZoneIds?: string[]
}

export interface StwFullInventoryParcel extends StwBaseInventoryParcel {
  place?: StwPlace
  creationUser?: StwUser
  closeUser?: StwUser
  zones?: StwZone[]
  operation?: StwOperation
  ignoreZones?: StwZone[]
}

interface StwBaseInventory {
  id?: string
  creationDate?: string
  lastModifyDate?: string
  description?: string
  place?: StwPlace
  closeDate?: string
  zones?: StwZone[]
  state?: StwInventoryState
  checkZone?: StwZone
  lostZone?: StwZone
  ignoreZones?: StwZone[]
  filter?: StwPartialInventoryFilter
  sessionNumber?: number
}

export interface StwListAttributesMap {
  [key: string]: string[]
}

interface StwPartialInventoryFilter {
  product: StwListAttributesMap
}

export type StwInventoryParcelSelect = 'OPEN' | 'CLOSED'

export type StwInventoryState =
  | 'CREATED'
  | 'POPULATING'
  | 'POPULATE_ERROR'
  | 'OPEN'
  | 'MOVE_AND_CLOSE'
  | 'CLOSING'
  | 'CLOSE_ERROR'
  | 'CLOSED'
  | 'ARCHIVED'

export interface StwInventory extends StwBaseInventory {
  creationUserId?: string
  closeUserId?: string
  operationId?: string
}

export interface StwFullInventory extends StwBaseInventory {
  creationUser?: StwUser
  closeUser?: StwUser
  operation?: StwOperation
  mode?: StwInventoryMode
}

export type StwInventoryMode = 'NONE' | 'STW_STOCK' | 'EXTERNAL_BY_ITEM' | 'EXTERNAL_BY_PRODUCT'

export interface StwInventoryCounters {
  status?: string
  barcodeExpected?: number
  barcodeDetected?: number
  barcodeDetectedByUser?: number
  barcodeUnexpected?: number
  barcodeMissing?: number
  rfidExpected?: number
  rfidDetected?: number
  rfidDetectedByUser?: number
  rfidUnexpected?: number
  rfidMissing?: number
}

export interface StwAttribute {
  id?: string
  entity: string
  code?: string
  description?: string
  defaultValue?: string
  creationDate?: string
  lastModifyDate?: string
}

export interface StwNotification {
  id?: string
  targetUser?: string
  description?: string
  icon?: string
  link?: string
  read?: boolean
  creationDate?: string
  lastModifyDate?: string
}

export interface StwFlow {
  id?: string
  code?: string
  description?: string
  discriminatorKey?: string
  operationId?: string
  sortingEnabled?: boolean
  creationDate?: string
  lastModifyDate?: string
}

export interface StwFullFlow extends StwFlow {
  operation?: StwOperation
}

export interface StwPicking {
  id?: string
  description?: string
  state?: string
  closingDate?: string
  closingUserId?: string
  operationPlaceId?: string
  sortingId?: string
  pickingEntries?: StwPickingEntry[]
  flow?: StwFlow
  creationDate?: string
  lastModifyDate?: string
}

export interface StwPickingEntry {
  id?: string
  code?: string
  quantity?: number
  pickedQuantity?: number
  attributes?: SimpleAttributesMap
  originZone?: StwZone
  pickingId?: string
  creationDate?: string
  lastModifyDate?: string
  product?: {
    upc?: string
    description?: string
    model?: string
  }
}

export interface ModalType extends Partial<ModalProps> {
  id?: string
  title?: string
  actions?: { text: string; variant: string; onPress: () => void }[]
  body?: (() => ReactElement) | ReactElement
  modal?: JSX.Element
  visible?: boolean
  onCancel?: any
}

export enum ApplicationType {
  CLIENT = 'CLIENT',
  SYSTEM = 'SYSTEM',
  ALL = 'ALL',
}

export interface StwApplication {
  id?: string
  code?: string
  description?: string
  value?: string
  type?: ApplicationType
  publicAccessible?: boolean
  clients?: string
  creationDate?: string
  lastModifyDate?: string
}

export interface StwConveyor {
  id?: string
  externalId?: string
  description?: string
  zone?: StwZone
  user?: StwUser
  operation?: StwOperation
  settings?: StwAttributesMap
  attributes?: StwAttributesMap
  creationDate?: string
  lastModifyDate?: string
}

export enum TemplateType {
  FREEMARKER = 'FREEMARKER',
  FREEMARKER_FOP = 'FREEMARKER_FOP',
}

export enum TemplateOutputType {
  PDF = 'PDF',
  CSV = 'CSV',
  PLAIN_TEXT = 'PLAIN_TEXT',
}

export interface StwTemplate {
  id?: string
  code?: string
  description?: string
  optLock?: number
  templateType?: TemplateType
  templateBody?: string
  outputType?: TemplateOutputType
  creationDate?: string
  lastModifyDate?: string
  creationUser?: string
  lastModifyUser?: string
}
