import { TablePaginationConfig } from 'antd'
import { Key, SorterResult } from 'antd/lib/table/interface'
import {
  AdvancedTableColumns,
  AdvancedTableConfig,
  AdvancedTableFiltersDef,
  AdvancedTableFilterValues,
  AdvancedTablePagination,
  AdvancedTableSortValues,
} from './advancedTableTypes'
import {
  advancedTableSortDirectionFromSortOrder,
  advancedTableSortValuesFromLocation,
  advancedTableSortValuesToQueryParameters,
} from './advancedTableSort'
import {
  advancedTableFilterValuesFromLocation,
  advancedTableFilterValuesToQueryParameters,
  advancedTableFilterValuesToQueryParametersFromLocation,
} from './advancedTableFilter'

import { DEFAULT_PAGE_SIZE } from '../../constants'

export function buildApiParams(pagination: AdvancedTablePagination, config: AdvancedTableConfig) {
  return {
    page: pagination.pageNumber! - 1,
    size: pagination.pageSize,
    sort: advancedTableSortValuesToQueryParameters(pagination.sortValues),
    ...advancedTableFilterValuesToQueryParameters(config.filterDefinitions, pagination.filterValues),
  }
}

export function buildPagination(
  location: any,
  filterDefinitions: AdvancedTableFiltersDef,
  columns: AdvancedTableColumns
): AdvancedTablePagination {
  return {
    pageNumber: getPageNumberFrom(location), // current page number
    pageSize: getPageSizeFrom(location),
    sortValues: advancedTableSortValuesFromLocation(location, columns), // current sort values
    filterValues: advancedTableFilterValuesFromLocation(location, filterDefinitions), // current filter values
  }
}

export function buildQueryParamsOnPaginationChange(
  location: any,
  config: AdvancedTableConfig,
  pagination: TablePaginationConfig,
  sorts: SorterResult<any> | SorterResult<any>[]
) {
  return {
    page: pagination.current === 1 ? undefined : pagination.current,
    sort: sortsToQueryParameters(sorts),
    size: pagination.pageSize,
    ...advancedTableFilterValuesToQueryParametersFromLocation(location, config.filterDefinitions),
  }
}

export function buildQueryParamsOnFilterChange(
  location: any,
  filterValues: AdvancedTableFilterValues,
  config: AdvancedTableConfig
) {
  return {
    page: undefined,
    size: undefined,
    sort: getSortsFrom(location),
    ...advancedTableFilterValuesToQueryParameters(config.filterDefinitions, filterValues),
  }
}

function getSortsFrom(location: any) {
  return new URLSearchParams(location.search).getAll('sort')
}

function getPageNumberFrom(location: any) {
  const queryParameters = new URLSearchParams(location.search)
  const pageNumber = queryParameters.get('page')
  return pageNumber ? Number.parseInt(pageNumber, 10) : 1
}

function getPageSizeFrom(location: any) {
  const queryParameters = new URLSearchParams(location.search)
  const size = queryParameters.get('size')
  return size ? Number.parseInt(size, 10) : DEFAULT_PAGE_SIZE
}

export function sortsToQueryParameters(sorts: SorterResult<any> | SorterResult<any>[]) {
  return (Array.isArray(sorts) ? sorts : [sorts])
    .filter((sort) => sort.order)
    .map((sort) => {
      const sortField = Array.isArray(sort.field) ? sort.field : [sort.field]
      return `${sortField.join('.')},${advancedTableSortDirectionFromSortOrder(sort.order)}`
    })
}

function converAntdKeyToString(keys: Key | readonly Key[]): string {
  return (Array.isArray(keys) ? [...keys] : [keys]).map((key) => key)[0]
}

export function advancedSortToApiValue(sort: AdvancedTableFilterValues): string {
  return Object.keys(sort)
    .map((key) => `${key},${sort[key]}`)
    .join()
}

export function convertAntdSortsToAdvancedTableSorts(
  sorts: SorterResult<any> | SorterResult<any>[]
): AdvancedTableSortValues | undefined {
  const convertedSorts = (Array.isArray(sorts) ? [...sorts] : [sorts])
    .filter((sort) => sort.order)
    .map((sort) => {
      let res: any
      if (sort.order) {
        res = {}

        res[converAntdKeyToString(sort.field!)] = advancedTableSortDirectionFromSortOrder(sort.order)
      }
      return res
    })
  if (convertedSorts.length > 0) {
    return convertedSorts[0]
  }
  return {}
}
