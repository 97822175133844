import { Component, createRef } from 'react'
import { Badge, Button, Row, Skeleton, Space, Typography } from 'antd'
import { PageHeader as AntdPageHeader } from '@ant-design/pro-layout'
import { navigate } from '../shared/router'
import {
  AdvancedTableColumns,
  AdvancedTableConfig,
  AdvancedTableFilterValues,
  AdvancedTablePagination,
} from './AdvancedTable'
import { AdvancedTableManagerColumns } from './AdvancedTable/AdvancedTableManagerColumns'
import { __, T } from '../shared/i18n'
import { PageHeaderTitle, Filters } from '.'

import IconBack from '../../icon/icon-back-button.png'
import IconSearchBlack from '../../icon/icon-search-black.png'
import IconSearch from '../../icon/icon-search.png'
import IconManageColumnsBlack from '../../icon/icon-manage-columns-black.png'
import IconManageColumns from '../../icon/icon-manage-columns.png'
import IconReload from '../../icon/icon-reload-black.png'

interface Props {
  title?: string
  actions?: ReadonlyArray<React.ReactNode>
  backPath?: string
  tabs?: ReadonlyArray<{ label: string; url: string; active: boolean; hidden: boolean }>
  pageHeaderClassName?: string
  numberData?: any
  managementColumns?: (...args: any[]) => any
  visibleManagerColumns?: boolean
  columns?: AdvancedTableColumns
  refreshTableColumns?: (...args: any[]) => any
  filters?: AdvancedTableConfig
  onFiltersChanged?: (filterKey: string, filterValue: any) => void
  onFiltersApplied?: (filterValues: AdvancedTableFilterValues) => void
  pagination?: AdvancedTablePagination
  filterApplied?: number
  manageColumnsPrefix?: string
  reloadData?: () => void
  filterPanelOpened: (status) => void
  filterOpened: boolean
  multipleTitle?: any
  skeleton?: boolean
  skeletonOptions?: any
}

interface State {
  selectedTab?: string
  maxHeightPanel: number
}

const { Text } = Typography

export class PageHeader extends Component<Props, State> {
  wrapperRef
  keyboardEvents: any[] = []
  timerKeyboardEvents

  constructor(props) {
    super(props)
    this.state = {
      selectedTab: '',
      maxHeightPanel: 500,
    }
    this.wrapperRef = createRef<HTMLDivElement>()
  }

  componentDidMount() {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  }

  handleResize = () => {
    const { innerHeight: height } = window
    const h = height - 138
    this.setState({ maxHeightPanel: h })
  }

  hasTabs = () => {
    const { tabs } = this.props
    return tabs !== undefined && tabs.length > 0
  }

  getTabs = () => {
    const { selectedTab } = this.state
    const { tabs } = this.props
    return (
      <Row className="stylewhere-tab-page">
        {tabs!
          .filter((tab) => !tab.hidden)
          .map((tab) => (
            <Button
              key={tab.label}
              title={tab.label}
              onClick={() => (tab.active ? {} : this.handleTabButtonClick(tab))}
              className={`ant-page-header-tab ${selectedTab === tab.label || tab.active ? 'selected' : ''}`}
            >
              {tab.label}
            </Button>
          ))}
      </Row>
    )
  }

  getExtras = () => {
    const {
      actions,
      numberData,
      filterApplied,
      filters,
      managementColumns,
      columns,
      visibleManagerColumns,
      refreshTableColumns,
      manageColumnsPrefix,
      reloadData,
      skeleton,
      skeletonOptions,
      filterOpened,
    } = this.props
    const { maxHeightPanel } = this.state
    const tmp: any = []
    if (skeleton) {
      if (skeletonOptions && skeletonOptions.createBtn) {
        tmp.push(<Skeleton.Button className="stw-skeleton-button create" active size="default" shape="round" />)
      }
      const btnClass = (skeletonOptions && skeletonOptions.btnClass) || ''
      if (skeletonOptions && !skeletonOptions.multiple) {
        tmp.push(<Skeleton.Button className={`stw-skeleton-button ${btnClass}`} active size="default" shape="round" />)
        tmp.push(<Skeleton.Button className={`stw-skeleton-button ${btnClass}`} active size="default" shape="round" />)
      }
    } else {
      if (actions) {
        tmp.push(actions)
      }
      if (numberData) {
        tmp.push(
          <Space size="small">
            <Text strong>{numberData.from}</Text>
            <Text>{__(T.misc.to)}</Text>
            <Text strong>{numberData.to}</Text>
            <Text>{__(T.misc.of)}</Text>
            <Text strong>{numberData.total}</Text>
          </Space>
        )
      }

      if (filters && filters.filterDefinitions && filters.filterDefinitions.length > 0) {
        tmp.push(
          <Button
            onClick={() => this.managementFilters()}
            className={filterOpened ? 'stylewhere-button-secondary btn-filter' : 'stylewhere-button-default btn-filter'}
          >
            {filterApplied && filterApplied > 0 ? (
              <Badge
                size="default"
                style={{ backgroundColor: '#1890ff', fontSize: 11, fontWeight: 700 }}
                count={filterApplied}
              >
                <div style={{ backgroundImage: `url(${filterOpened ? IconSearch : IconSearchBlack})` }} />
              </Badge>
            ) : (
              <div style={{ backgroundImage: `url(${filterOpened ? IconSearch : IconSearchBlack})` }} />
            )}
          </Button>
        )
      }

      if (managementColumns && columns && columns.length > 0) {
        tmp.push(
          <Row className="stylewhere-columns-manager-btn">
            <Space
              style={{
                cursor: 'pointer',
                backgroundColor: visibleManagerColumns ? '#333' : '#FFF',
              }}
              onClick={() => this.managerColumnsPanel()}
              size="small"
            >
              <div
                style={{
                  backgroundImage: `url(${visibleManagerColumns ? IconManageColumns : IconManageColumnsBlack})`,
                }}
              />
            </Space>
            {managementColumns && (
              <AdvancedTableManagerColumns
                columns={columns || []}
                customTop="50px"
                refreshTableColumns={refreshTableColumns || undefined}
                managementColumns={managementColumns}
                visibleManagerColumns={visibleManagerColumns || false}
                manageColumnsPrefix={manageColumnsPrefix}
                maxHeightPanel={maxHeightPanel}
              />
            )}
          </Row>
        )
      }
      if (reloadData) {
        tmp.push(
          <Row className="stylewhere-columns-manager-btn reload">
            <Space style={{ cursor: 'pointer' }} onClick={() => reloadData()} size="small">
              <div style={{ backgroundImage: `url(${IconReload})` }} />
            </Space>
          </Row>
        )
      }
    }
    return tmp
  }

  managerColumnsPanel = () => {
    const { filterPanelOpened, filterOpened } = this.props
    if (filterOpened) {
      filterPanelOpened(!filterOpened)
    }
    this.callManagementColumns()
  }

  callManagementColumns = () => {
    const { managementColumns } = this.props
    managementColumns?.()
  }

  handleTabButtonClick = (tab: { label: string; url: string }) => {
    this.setState({ selectedTab: tab.label })
    navigate(tab.url)
  }

  managementFilters = () => {
    const { visibleManagerColumns, managementColumns, filterPanelOpened, filterOpened } = this.props
    if (visibleManagerColumns && managementColumns) {
      managementColumns()
    }
    filterPanelOpened(!filterOpened)
  }

  getPageTitle = () => {
    const { title, multipleTitle, skeleton, skeletonOptions } = this.props
    if (skeleton) {
      if (skeletonOptions && skeletonOptions.multiple) {
        return (
          <PageHeaderTitle
            list={[
              { widthLabel: '60%', widthDesc: '100%' },
              { class: 'small', widthLabel: '60%', widthDesc: '100%' },
              { class: 'small', widthLabel: '100%', widthDesc: '80%' },
            ]}
            skeleton
          />
        )
      }
      return (
        <Skeleton
          className={`stw-skeleton-base ${(skeletonOptions && skeletonOptions.titleClass) || ''}`}
          title={false}
          round
          active
          paragraph={{ rows: 1, width: '100%' }}
        />
      )
    }
    if (this.hasTabs()) {
      return this.getTabs()
    }
    if (multipleTitle && multipleTitle.length > 0) {
      return <PageHeaderTitle list={multipleTitle} />
    }
    return title
  }

  render() {
    const {
      backPath,
      pageHeaderClassName,
      filters,
      columns,
      filterPanelOpened,
      managementColumns,
      visibleManagerColumns,
      refreshTableColumns,
      onFiltersChanged,
      onFiltersApplied,
      pagination,
      filterApplied,
      manageColumnsPrefix,
      filterOpened,
    } = this.props
    const { maxHeightPanel } = this.state
    return (
      <Row style={{ width: '100%', position: 'relative', marginBottom: 15 }}>
        <AntdPageHeader
          className={pageHeaderClassName || ''}
          style={{ width: '100%' }}
          ghost={false}
          title={this.getPageTitle()}
          extra={this.getExtras()}
          onBack={backPath ? () => navigate(backPath) : undefined}
          backIcon={<div style={{ backgroundImage: `url(${IconBack})` }} />}
        />
        <Filters
          managementFilters={this.managementFilters}
          filters={filters ? filters.filterDefinitions : []}
          filterParameters={filters ? filters.filterParameters : {}}
          columns={columns}
          filterPanelOpened={filterPanelOpened}
          managementColumns={managementColumns}
          visibleManagerColumns={visibleManagerColumns}
          refreshTableColumns={refreshTableColumns}
          onFiltersChanged={onFiltersChanged}
          onFiltersApplied={onFiltersApplied}
          pagination={pagination}
          filterApplied={filterApplied}
          manageColumnsPrefix={manageColumnsPrefix}
          filterOpened={filterOpened}
          maxHeightPanel={maxHeightPanel}
        />
      </Row>
    )
  }
}
