import { Component } from 'react'
import { KeyValueGrid, StylewherePage, NotFound, Section, DefaultHeader, TableListReport } from '../../../components'
import { SkeletonAttributeReport } from '../../../constants'
import { __, T } from '../../../shared/i18n'
import { StwReportQueryResult, DetailCell, StwOperation, StwOperationAction } from '../../../api'
import { ExtendRouteComponentProps } from '../../../types'
import { AdvancedTableSortDirection } from '../../../components/AdvancedTable'
import Reports from '../../../api/Reports'
import { showModal } from '../../../components/modalPageUtils'
import Operations from '../../../api/Operations'
import { getFragmentObject, getMultipleTitle, getBackURL, getHeaderOperationActions } from '../../../shared/utils'

interface State {
  reportInstance: StwReportQueryResult | undefined
  configuration: DetailCell[]
  loader: boolean
  operationInstanceId: string | undefined
  actions: StwOperationAction[]
}

export default class SortingOperationDetail extends Component<ExtendRouteComponentProps, State> {
  state: State = {
    reportInstance: undefined,
    configuration: [],
    loader: true,
    operationInstanceId: undefined,
    actions: [],
  }

  componentDidMount = async () => {
    const { match } = this.props
    if (match && match.params && match.params.operationInstanceId) {
      const params = { operationId: match.params.operationId }
      const reportId = await Operations.getReportId(match.params.operationId)
      const instance: any = await Reports.reportInstance(reportId, match.params.operationInstanceId, params)
      if (instance && instance.id) {
        const operation: StwOperation = await Operations.get<StwOperation>(match.params.operationId)
        const configuration = await Reports.getConfiguration(reportId, params)
        this.setState({
          loader: false,
          reportInstance: instance,
          configuration: configuration,
          operationInstanceId: match.params.operationInstanceId,
          actions: operation && operation.actions && operation.actions !== null ? operation.actions : [],
        })
      } else {
        this.setState({
          loader: false,
        })
      }
    } else {
      this.setState({
        loader: false,
      })
    }
  }

  showDetail = (record) => {
    showModal({
      type: 'SORTING_GROUP_READS',
      data: { productId: record.productId, sortingGroupEntryId: record.id },
    })
  }

  render = () => {
    const { breadcrumbs, queryString, match } = this.props
    const { reportInstance, loader, configuration, operationInstanceId, actions } = this.state
    const fragment = getFragmentObject(reportInstance, 'id')
    return (
      <StylewherePage {...this.props} noOverflow fragment={fragment}>
        <DefaultHeader
          backPath={getBackURL(queryString, breadcrumbs)}
          multipleTitle={getMultipleTitle(reportInstance)}
          title={__(T.misc.not_found)}
          skeleton={{ active: loader, options: { multiple: true } }}
          actions={
            reportInstance
              ? getHeaderOperationActions(
                  'logistics',
                  'sorting',
                  actions,
                  match.params.operationId,
                  operationInstanceId
                )
              : []
          }
        />
        <Section customClass={`stw-section-page paged-header ${reportInstance || loader ? 'scroll transparent' : ''}`}>
          {reportInstance || loader ? (
            <>
              <Section>
                <KeyValueGrid
                  skeleton={loader}
                  fields={loader ? SkeletonAttributeReport : configuration}
                  data={reportInstance}
                />
              </Section>
              {reportInstance && (
                <TableListReport
                  {...this.props}
                  reportId="sortingGroupEntry"
                  actions={[]}
                  fixedTableHeight
                  disabledReportExport
                  headerType="boxed"
                  title={__('fields.labels.sortingGroupEntries')}
                  customColumnPrefix="sortingDetailGroupEntries"
                  reportActions={{
                    active: true,
                    onlyedit: false,
                    onClick: this.showDetail,
                  }}
                  reportParams={{ sortingId: reportInstance.id }}
                  sortValues={{ priority: AdvancedTableSortDirection.asc }}
                  disableLocation
                />
              )}
            </>
          ) : (
            <NotFound fullheight title={__(T.misc.noRecordFoundTitle)} />
          )}
        </Section>
      </StylewherePage>
    )
  }
}
