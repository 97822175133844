import { Component } from 'react'
import { StylewherePage, TableList } from '../../../components'
import { getZoneColumns, getZoneFilters } from '../../../config/Pages/Zones'
import { __, T } from '../../../shared/i18n'
import { AdvancedTableConfig } from '../../../components/AdvancedTable'
import { ExtendRouteComponentProps } from '../../../types'
import { CAPABILITIES, getActionsList } from '../../../config/utility/capabilityUtility'
import Zones from '../../../api/Zones'
import { ZoneTypes } from '../../../constants'

const ZONE_TABLE_CONFIG: AdvancedTableConfig = {
  columns: getZoneColumns(true),
  filterDefinitions: getZoneFilters(true),
  filterParameters: { types: ZoneTypes },
  manageColumnsPrefix: 'zones',
  entity: 'zone',
  activeDefaultClassColumn: true,
}

class ZoneList extends Component<ExtendRouteComponentProps> {
  render() {
    return (
      <StylewherePage {...this.props} noOverflow>
        <TableList
          title={__(T.menu.zones)}
          location={this.props.location}
          config={ZONE_TABLE_CONFIG}
          resource={{
            call: Zones.searchTableList,
            endpoint: Zones.endpoint,
          }}
          actions={getActionsList(
            CAPABILITIES.RESOURCE_ZONE_EDIT,
            __(T.zone.create_new),
            '/configuration/zones/create/form'
          )}
        />
      </StylewherePage>
    )
  }
}

export default ZoneList
