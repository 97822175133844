import { Component } from 'react'
import { Row, Col, Typography, Button } from 'antd'
import { MenuEntryType, SubmenuEntryType } from '../../types'
import { hasAnyCapabilities } from '../../config/utility/capabilityUtility'
import { __ } from '../../shared/i18n'
import { SidebarMenuNotification } from '..'

interface Props {
  selectedMenu?: MenuEntryType
  submenuSelected?: SubmenuEntryType
  submenuClickHandler: any
  seeAllHandler: any
  handleNotificationDetail: any
}

const { Text } = Typography

export class SidebarMenuChild extends Component<Props> {
  canRenderSubmenuElement = (element: SubmenuEntryType): boolean => {
    return hasAnyCapabilities(...(element.capabilities ?? [])) && !element.hidden
  }

  isSelected = (path) => {
    const { submenuSelected } = this.props
    return (
      submenuSelected &&
      (submenuSelected.path === path || (submenuSelected.selectedPath && submenuSelected.selectedPath === path))
    )
  }

  render() {
    const { selectedMenu, submenuClickHandler, seeAllHandler, handleNotificationDetail } = this.props
    return (
      <Row className="stw-sider-submenu-row list">
        <Col span={24} className="stw-scroller">
          {selectedMenu &&
            selectedMenu.submenus.map((submenu, i) => {
              return this.canRenderSubmenuElement(submenu) ? (
                <div key={i}>
                  {selectedMenu.notifications ? (
                    <div className={`stw-sider-submenu-element notification ${!submenu.read ? ' active' : ''}`}>
                      <SidebarMenuNotification handleNotificationDetail={handleNotificationDetail} item={submenu} />
                    </div>
                  ) : (
                    <Text
                      className={`stw-sider-submenu-element${this.isSelected(submenu.path) ? ' active' : ''}`}
                      key={i}
                      onClick={(event) => submenuClickHandler(submenu, event)}
                    >
                      <a href={submenu.path}>{submenu.label}</a>
                    </Text>
                  )}
                </div>
              ) : null
            })}
          {selectedMenu && selectedMenu.notifications && (
            <Button className="stylewhere-button-default" onClick={() => seeAllHandler()}>
              {__('misc.notifications.seeAll')}
            </Button>
          )}
        </Col>
      </Row>
    )
  }
}
