import { Component } from 'react'
import { DetailCell, StwOperation } from '../../../../api'
import { GridData, StylewherePage, DefaultHeader, Section, NotFound } from '../../../../components'
import { __, T } from '../../../../shared/i18n'
import { OPERATIONS_DATA } from '../../../../config/Pages/Settings/Operations'
import Operations from '../../../../api/Operations'
import { ExtendRouteComponentProps } from '../../../../types'
import { SkeltonAttribute } from '../../../../constants'
import { getMultipleTitle, getBackURL, getFragmentObject, canRenderAttributes } from '../../../../shared/utils'

interface State {
  operation: StwOperation
  optionFields: DetailCell[]
  loader: boolean
  attributeFields: DetailCell[]
}

class OperationsDetail extends Component<ExtendRouteComponentProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      operation: {},
      optionFields: [],
      attributeFields: [],
      loader: true,
    }
  }

  componentDidMount = async () => {
    let operation: StwOperation = {}

    const optionFields: DetailCell[] = []
    const attributeFields: DetailCell[] = []

    if (this.props.match) {
      const operationId = this.props.match.params.operationId
      if (operationId) {
        operation = await Operations.get<StwOperation>(operationId)
        if (operation.options) {
          for (const key in operation.options) {
            if (Object.prototype.hasOwnProperty.call(operation.options, key)) {
              optionFields.push({ title: key, attribute: key, columns: 8 })
            }
          }
        }
        if (operation.attributes) {
          for (const key in operation.attributes) {
            if (Object.prototype.hasOwnProperty.call(operation.attributes, key)) {
              attributeFields.push({ title: key, attribute: key, columns: 8 })
            }
          }
        }
      }
    }

    this.setState({
      operation: operation,
      optionFields: optionFields,
      attributeFields: attributeFields,
      loader: false,
    })
  }

  render() {
    const { operation, optionFields, attributeFields, loader } = this.state
    const { breadcrumbs, queryString } = this.props
    const viewOperations = loader || (operation.options && Object.keys(operation.options).length > 0)
    const fragment = getFragmentObject(operation, 'code')
    return (
      <StylewherePage {...this.props} noOverflow fragment={fragment}>
        <DefaultHeader
          backPath={getBackURL(queryString, breadcrumbs)}
          multipleTitle={getMultipleTitle(operation)}
          title={__(T.misc.not_found)}
          skeleton={{ active: loader, options: { multiple: true } }}
        />
        <Section customClass={`stw-section-page paged-header ${operation || loader ? 'scroll transparent' : ''}`}>
          {operation || loader ? (
            <>
              <GridData fields={OPERATIONS_DATA} record={operation || {}} skeleton={loader} />
              {viewOperations && (
                <GridData
                  title={__(T.misc.options_title)}
                  fields={loader ? SkeltonAttribute : optionFields}
                  record={loader ? [] : operation.options}
                  skeleton={loader}
                />
              )}
              {canRenderAttributes(operation, attributeFields, loader) && (
                <GridData
                  title={__(T.misc.attributes_title)}
                  fields={loader ? SkeltonAttribute : attributeFields}
                  record={loader ? [] : operation.attributes}
                  skeleton={loader}
                />
              )}
            </>
          ) : (
            <NotFound title={__(T.misc.noRecordFoundTitle)} />
          )}
        </Section>
      </StylewherePage>
    )
  }
}

export default OperationsDetail
