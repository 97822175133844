import { Component, createRef } from 'react'
import { Form, FormInstance } from 'antd'
import { AdvancedForm, StylewherePage, Section, DefaultHeader } from '../../../../components'
import { ExtendRouteComponentProps } from '../../../../types'
import EnumerationTypes from '../../../../api/EnumerationTypes'
import { TYPES_FIELDS } from '../../../../config/Pages/Settings/EnumerationTypes'
import { navigate } from '../../../../shared/router'
import { __, T } from '../../../../shared/i18n'
import { StwEnumerationTypes } from '../../../../api'
import { AttributeUtil } from '../../../../config/utility/utility'
import { FormValidateMessages } from '../../../../constants'
import { FormUtility } from '../../../../config/utility/formUtility'
import { getFragmentObject, getBackURL } from '../../../../shared/utils'

interface State {
  typeId: string
  type: StwEnumerationTypes
  loader: boolean
  isCodeEditableByDescription: boolean
}

class EnumerationTypesForm extends Component<ExtendRouteComponentProps, State> {
  formRef = createRef<FormInstance>()

  constructor(props) {
    super(props)
    this.state = {
      typeId: '',
      type: {},
      loader: true,
      isCodeEditableByDescription: true,
    }
  }

  componentDidMount() {
    this.initialize()
  }

  initialize = async () => {
    let result
    if (this.props.match) {
      const typeId = this.props.match.params.typeId
      if (typeId !== '' && typeId !== 'create') {
        result = await EnumerationTypes.get(typeId)
      }
    }
    this.setState(
      {
        typeId: result ? result.id : '',
        type: result || {},
        isCodeEditableByDescription: !result,
        loader: false,
      },
      this.updateFormFieldsValue
    )
  }

  updateFormFieldsValue = () => {
    if (this.formRef && this.formRef.current) {
      this.formRef.current.setFieldsValue(this.state.type)
    }
  }

  handleChange = (key, value) => {
    const { type, isCodeEditableByDescription } = this.state
    AttributeUtil.setAttribute(type, key, value)
    if (key === 'description' && isCodeEditableByDescription) {
      FormUtility.handleDescription(value, type, this.formRef)
    } else if (key === 'code') {
      this.setState({ isCodeEditableByDescription: false })
    }
    this.setState({
      type: type,
    })
  }

  store = () => {
    const { type, typeId } = this.state
    const { queryString } = this.props
    if (typeId !== '') {
      EnumerationTypes.update<StwEnumerationTypes>(type).then(() => {
        navigate(`/configuration/enumerations${queryString ?? ''}`)
      })
    } else {
      EnumerationTypes.insert<StwEnumerationTypes>(type).then((z) => {
        if (z && z.id) {
          navigate(`/configuration/enumerations${queryString ?? ''}`)
        }
      })
    }
  }

  render() {
    const { type, typeId, loader } = this.state
    const title = typeId !== '' ? __('enumeration.types.edit') : __('enumeration.types.create_new')
    const { breadcrumbs, queryString } = this.props
    const fragment = !loader ? getFragmentObject(type, 'id', __('enumeration.types.create_new')) : undefined
    return (
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        onFinish={this.store}
        style={{ width: '100%', height: '100%' }}
        ref={this.formRef}
        initialValues={type}
        validateMessages={FormValidateMessages}
        scrollToFirstError
      >
        <StylewherePage {...this.props} noOverflow fragment={fragment}>
          <DefaultHeader
            backPath={getBackURL(queryString, breadcrumbs)}
            title={loader ? '...' : title}
            skeleton={{ active: loader }}
            actions={[
              {
                label: __(T.misc.cancel),
                type: 'cancel',
                onClick: () => navigate(`/configuration/enumerations${queryString ?? ''}`),
              },
              {
                label: type.id && type.id !== '' ? __(T.misc.update) : __(T.misc.create),
                type: 'submit',
              },
            ]}
          />
          <Section customClass="stw-section-page paged-header scroll">
            <AdvancedForm record={type} handleChange={this.handleChange} fields={TYPES_FIELDS} store={this.store} />
          </Section>
        </StylewherePage>
      </Form>
    )
  }
}

export default EnumerationTypesForm
