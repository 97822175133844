import { Component } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Row } from 'antd'
import Products from '../../../api/Products'
import { DetailCell, StwProduct } from '../../../api'
import { NotFound, Section, ProductDetailBox, TableListReport } from '../../../components'
import { __, T } from '../../../shared/i18n'
import { PRODUCT_MODAL_FIELDS } from '../../../config/Pages/Reports'
import { getDetailDataWithAttributesMapping } from '../../../shared/utils'
import { ExtendRouteComponentProps } from '../../../types'

interface Props extends ExtendRouteComponentProps {
  inventoryId: string
  productId: string
}

interface State {
  product?: StwProduct
  detailData?: DetailCell[]
  loader: boolean
}

export class InventoryEntryItemReads extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      product: undefined,
      detailData: PRODUCT_MODAL_FIELDS,
      loader: true,
    }
  }

  async componentDidMount() {
    await this.initialize()
  }

  initialize = async () => {
    const { productId } = this.props
    const data: DetailCell[] = await getDetailDataWithAttributesMapping(
      PRODUCT_MODAL_FIELDS,
      'product',
      2,
      'valueDesc',
      12
    )
    const product = await Products.get<StwProduct>(productId)
    this.setState({
      product: product,
      detailData: data,
      loader: false,
    })
  }

  render() {
    const { product, detailData, loader } = this.state
    const { inventoryId } = this.props
    return (
      <Section
        parentPadding={15}
        title={product ? `${__('fields.labels.product')} ${product?.description || product?.id}` : <LoadingOutlined />}
      >
        {loader || product ? (
          <>
            <ProductDetailBox loader={loader} product={product} data={detailData || PRODUCT_MODAL_FIELDS} />
            {product && (
              <TableListReport
                {...this.props}
                reportId="inventoryEntry"
                actions={[]}
                fixedTableHeight
                disabledReportExport
                headerType="unset"
                title={__('fields.labels.pickingEntries')}
                disabledColumnPrefix
                reportActions={{
                  active: true,
                  onlyedit: false,
                  fieldId: 'itemId',
                }}
                reportParams={{ productId: product.id, inventoryId: inventoryId }}
                disableLocation
                disableColumnFilters
                numberRecodPage={10}
                pathDetail="reports/item"
                tableBorder
              />
            )}
          </>
        ) : (
          <Row style={{ marginTop: 15 }}>
            <NotFound title={__(T.misc.noRecordFoundTitle)} />
          </Row>
        )}
      </Section>
    )
  }
}
