import { Component } from 'react'
import { Space, Typography } from 'antd'
import { PaginationRecordData } from '../../AdvancedTable'
import { AdvancedTablePagination } from '../../AdvancedTable/advancedTableTypes'
import { __, T } from '../../../shared/i18n'

const { Text } = Typography

interface Props {
  totalElements: number
  pagination: AdvancedTablePagination
  disabledPagination?: boolean
}

export class TableNumberPage extends Component<Props> {
  getPageNumber = () => {
    const { pagination, totalElements, disabledPagination } = this.props
    const pageNumber = pagination.pageNumber || 1
    const size = pagination.pageSize || 10
    const to = pageNumber * size
    return {
      active: !disabledPagination,
      from: (pageNumber - 1) * size + 1,
      to: to < totalElements ? to : totalElements,
      total: totalElements,
    }
  }
  render() {
    const { totalElements } = this.props
    const numberData: PaginationRecordData = this.getPageNumber()
    return numberData && numberData.active && totalElements > 1 ? (
      <Space size="small" className="stw-pagination-numbering">
        <Text strong>{numberData.from}</Text>
        <Text>{__(T.misc.to)}</Text>
        <Text strong>{numberData.to}</Text>
        <Text>{__(T.misc.of)}</Text>
        <Text strong>{totalElements}</Text>
      </Space>
    ) : null
  }
}
