class Storage {
  prefix = 'control-panel::'

  async save(key: string, data: any): Promise<void> {
    return localStorage.setItem(this.prefix + key, JSON.stringify(data))
  }

  async load(key: string, defaultValue?: any): Promise<any | undefined> {
    const data = await localStorage.getItem(this.prefix + key)
    return data && data !== 'undefined' ? JSON.parse(data) : defaultValue
  }

  async remove(key: string): Promise<void> {
    return localStorage.removeItem(this.prefix + key)
  }

  async purgeAllData(): Promise<void> {
    return localStorage.clear()
  }
}

export default new Storage()
