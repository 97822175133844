import { Component } from 'react'
import { Skeleton } from 'antd'

interface Props {
  animate?: boolean
  showAvatar?: boolean
  height: number
}

const COLUMNS = [
  { size: '' },
  { size: 'large', width: '70%', widthData: '80%' },
  { size: 'small', width: '70%', widthData: '90%' },
  { size: '' },
  { size: 'small', width: '90%', widthData: '40%' },
  { size: '' },
  { size: 'small', action: true },
]

interface State {
  rows: any
  height: number | undefined
  columnHeight: number
}

export class SkeletonTable extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      height: undefined,
      rows: [],
      columnHeight: 76,
    }
  }

  componentDidMount() {
    this.getRows(this.props.height)
  }

  shouldComponentUpdate = (nextProps) => {
    const { height } = this.state
    if (nextProps.height && nextProps.height !== height) {
      this.getRows(nextProps.height)
    }
    return true
  }

  getRows = (height) => {
    let colHeight = this.state.columnHeight
    if (height) {
      const count = Math.ceil(height / colHeight)
      if (count * colHeight > height) {
        colHeight = Math.ceil(height / count)
      }
      const rows: any[] = []
      for (let c = 0; c < count; c++) {
        rows.push({ key: c })
      }
      this.setState({ rows: rows, height: height, columnHeight: colHeight })
    }
  }

  render() {
    const { animate, showAvatar } = this.props
    const { rows, height, columnHeight } = this.state
    return (
      <div className="stw-skeleton-table">
        <div className="stw-skeleton-table-row">
          {COLUMNS.map((col, i) => {
            return (
              <div key={`skeleton-col-${i}`} className={`stw-skeleton-table-row-column ${col.size}`}>
                {col.action ? null : (
                  <Skeleton
                    className="stw-skeleton-base"
                    title={false}
                    round
                    active={animate}
                    paragraph={{ rows: 1, width: col.width || '50%' }}
                  />
                )}
              </div>
            )
          })}
        </div>
        <div className="stw-skeleton-table-list" style={{ height: height }}>
          {rows.map((row) => {
            return (
              <div key={`skeleton-row-${row.key}`} className="stw-skeleton-table-row">
                {COLUMNS.map((col, i) => {
                  return (
                    <div
                      key={`skeleton-col-${i}`}
                      style={{ height: columnHeight }}
                      className={`stw-skeleton-table-row-column ${col.size}`}
                    >
                      {col.action ? (
                        <div className="actions">
                          <Skeleton.Button
                            className="stw-skeleton-button"
                            active={animate}
                            size="default"
                            shape="circle"
                          />
                          <Skeleton.Button
                            className="stw-skeleton-button"
                            active={animate}
                            size="default"
                            shape="circle"
                          />
                        </div>
                      ) : (
                        <Skeleton
                          className="stw-skeleton-base"
                          title={false}
                          round
                          active={animate}
                          paragraph={{ rows: 1, width: col.widthData || '80%' }}
                        />
                      )}
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
        <div className="stw-skeleton-table-pagination">
          <div className="stw-skeleton-table-pagination-left">
            <Skeleton
              className="stw-skeleton-base"
              title={false}
              round
              active={animate}
              paragraph={{ rows: 1, width: '100%' }}
            />
            <Skeleton.Button className="stw-skeleton-button" active={animate} size="default" shape="round" />
          </div>
          <div className="stw-skeleton-table-pagination-right">
            <Skeleton.Button className="stw-skeleton-button" active={animate} size="default" shape="round" />
            <Skeleton.Button className="stw-skeleton-button" active={animate} size="default" shape="round" />
            <Skeleton.Button className="stw-skeleton-button" active={animate} size="default" shape="round" />
            <Skeleton.Button className="stw-skeleton-button" active={animate} size="default" shape="round" />
            <Skeleton.Button className="stw-skeleton-button" active={animate} size="default" shape="round" />
            <Skeleton.Button className="stw-skeleton-button" active={animate} size="default" shape="round" />
            <Skeleton
              className="stw-skeleton-base small"
              title={false}
              round
              active={animate}
              paragraph={{ rows: 1, width: '100%' }}
              avatar={showAvatar}
            />
            <Skeleton.Button className="stw-skeleton-button medium" active={animate} size="default" shape="round" />
            <Skeleton.Button className="stw-skeleton-button" active={animate} size="default" shape="round" />
            <Skeleton.Button className="stw-skeleton-button large" active={animate} size="default" shape="round" />
          </div>
        </div>
      </div>
    )
  }
}
