import {
  AdvancedTableColumn,
  AdvancedTableContentKind,
  AdvancedTableFilterKind,
  AdvancedTableFiltersDef,
} from '../../../components/AdvancedTable'
import {
  AdvancedFormField,
  AdvancedFormFieldBuilder,
  AdvancedFormInputType,
} from '../../../components/AdvancedForm/advancedFormTypes'
import { __ } from '../../../shared/i18n'
import { DetailCell } from '../../../api'
import { AdvancedTableRowColumn } from '../../../components'
import { CAPABILITIES, getActionsTableColumns } from '../../utility/capabilityUtility'

export const BALANCES_FILTERS: AdvancedTableFiltersDef = [
  {
    key: 'equalCodes',
    label: __('fields.labels.code'),
    type: AdvancedTableFilterKind.STRING_LIST,
    column: 'code',
    col: 8,
  },
  {
    key: 'description',
    label: __('fields.labels.description'),
    type: AdvancedTableFilterKind.STRING,
    column: 'description',
    col: 8,
  },
  {
    key: 'deviceType',
    label: __('fields.labels.deviceType'),
    type: AdvancedTableFilterKind.STRING,
    column: 'deviceType',
    col: 8,
  },
  {
    key: 'placeIds',
    label: __('fields.labels.place'),
    type: AdvancedTableFilterKind.AUTOCOMPLETE,
    column: 'place',
    col: 8,
    autocomplete: {
      endpoint: '/api/v1/places',
      sort: 'code,asc',
      multiple: true,
    },
  },
  {
    key: 'creationDate',
    label: __('fields.labels.creationDate'),
    type: AdvancedTableFilterKind.DATE_RANGE,
    column: 'creationDate',
    col: 8,
  },
  {
    key: 'lastModifyDate',
    label: __('fields.labels.lastModifyDate'),
    type: AdvancedTableFilterKind.DATE_RANGE,
    column: 'lastModifyDate',
    col: 8,
  },
]

export const BALANCES_FIELDS: AdvancedFormField[] = [
  AdvancedFormFieldBuilder.buildAutocomplete(
    'place',
    '/api/v1/places',
    'code,asc',
    undefined,
    undefined,
    [{ required: true }],
    __('fields.labels.place'),
    __('fields.placeholders.place')
  ),
  {
    key: 'code',
    type: AdvancedFormInputType.UNIQUE_TEXT,
    label: __('fields.labels.code'),
    placeholder: __('fields.placeholders.code'),
    rules: [{ required: true }],
    unique: {
      endpoint: '/api/v1/balances',
      parameter: 'equalCodes',
    },
  },
  AdvancedFormFieldBuilder.buildText('description'),
  AdvancedFormFieldBuilder.buildText('settings.ipAddress', [{ required: true }]),
  AdvancedFormFieldBuilder.buildText('settings.port', [{ required: true }]),
  AdvancedFormFieldBuilder.buildSelect(
    'deviceType',
    'scalesTypes',
    [{ required: true }],
    undefined,
    undefined,
    undefined,
    undefined,
    false,
    undefined,
    true
  ),
]

export const BALANCES_COLUMNS: AdvancedTableColumn[] = [
  {
    key: 'code',
    dataIndex: 'code',
    title: __('fields.labels.code'),
  },
  {
    key: 'description',
    dataIndex: 'description',
    title: __('fields.labels.description'),
  },
  {
    key: 'deviceType',
    dataIndex: 'deviceType',
    title: __('fields.labels.deviceType'),
    type: AdvancedTableContentKind.STRING,
  },
  {
    key: 'place',
    dataIndex: 'place',
    title: __('fields.labels.place'),
    type: AdvancedTableContentKind.CODE_DESC,
  },
  {
    key: 'settings.ipAddress',
    type: AdvancedTableContentKind.STRING,
  },
  {
    key: 'creationDate',
    dataIndex: 'creationDate',
    title: __('fields.labels.creationDate'),
    type: AdvancedTableContentKind.DATE_TIME,
  },
  {
    key: 'lastModifyDate',
    dataIndex: 'lastModifyDate',
    title: __('fields.labels.lastModifyDate'),
    type: AdvancedTableContentKind.DATE_TIME,
  },
  {
    key: 'action',
    className: 'stw-small',
    fixedType: 'right',
    unmanageable: true,
    hidden: false,
    sortable: false,
    render: (text, record) => (
      <AdvancedTableRowColumn
        actions={getActionsTableColumns(record.id, '/devices/balances', CAPABILITIES.RESOURCE_BALANCE_EDIT)}
      />
    ),
  },
]

export const BALANCES_DATA: DetailCell[] = [
  { title: __('fields.labels.code'), attribute: 'code', columns: 12 },
  { title: __('fields.labels.description'), attribute: 'description', columns: 12 },
  { title: __('fields.labels.place'), attribute: 'place', columns: 12, type: 'codeDesc' },
  { title: __('fields.labels.deviceType'), attribute: 'deviceType', columns: 12 },
  { title: __('fields.labels.settings.ipAddress'), attribute: 'settings.ipAddress', columns: 12 },
  { title: __('fields.labels.settings.port'), attribute: 'settings.port', columns: 12 },
  { title: __('fields.labels.creationDate'), attribute: 'creationDate', type: 'date', columns: 12 },
  { title: __('fields.labels.lastModifyDate'), attribute: 'lastModifyDate', type: 'date', columns: 12 },
]
