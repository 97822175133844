import BaseResource, { PageResult } from './BaseResource'
import { StwBarcodeReader, StwFullBarcodeReader } from './types'

export default class BarcodeReaders extends BaseResource {
  static endpoint = '/api/v1/barcodeReaders' // /edge

  static async getFullBarcodeReader(readerId: string): Promise<StwFullBarcodeReader> {
    const barcodeReader = await this.get<StwBarcodeReader>(readerId)
    return {
      ...barcodeReader,
      ipAddress: barcodeReader.settings && barcodeReader.settings.ipAddress,
      dataSocketPort: barcodeReader.settings && +barcodeReader.settings.dataSocketPort,
      maxConnectionTimeout: barcodeReader.settings && +barcodeReader.settings.maxConnectionTimeout,
      keepAliveEnabled: barcodeReader.settings && !!barcodeReader.settings.keepAliveEnabled,
      keepAlivePeriod: barcodeReader.settings && +barcodeReader.settings.keepAlivePeriod,
      keepAliveSocketPort: barcodeReader.settings && +barcodeReader.settings.keepAliveSocketPort,
    }
  }

  static async searchFull(params = {}): Promise<PageResult<StwFullBarcodeReader>> {
    const barcodeReaders = await BarcodeReaders.search<StwBarcodeReader>(params)
    return {
      ...barcodeReaders,
      content: barcodeReaders.content.map((barcodeReader) => ({
        ...barcodeReader,
        ipAddress: barcodeReader.settings && barcodeReader.settings.ipAddress,
        dataSocketPort: barcodeReader.settings && +barcodeReader.settings.dataSocketPort,
        maxConnectionTimeout: barcodeReader.settings && +barcodeReader.settings.maxConnectionTimeout,
        keepAliveEnabled: barcodeReader.settings && !!barcodeReader.settings.keepAliveEnabled,
        keepAlivePeriod: barcodeReader.settings && +barcodeReader.settings.keepAlivePeriod,
        keepAliveSocketPort: barcodeReader.settings && +barcodeReader.settings.keepAliveSocketPort,
      })),
    }
  }
}
