import { Component, createRef } from 'react'
import { Form, FormInstance } from 'antd'
import { StwRole } from '../../../../api'
import { ExtendRouteComponentProps } from '../../../../types'
import Roles from '../../../../api/Roles'
import { __, T } from '../../../../shared/i18n'
import { navigate } from '../../../../shared/router'
import { AdvancedForm, StylewherePage, DefaultHeader, Section } from '../../../../components'
import { FormValidateMessages } from '../../../../constants'
import { ROLES_FIELDS } from '../../../../config/Pages/UsersRoles/Roles'
import { AttributeUtil } from '../../../../config/utility/utility'
import { FormUtility } from '../../../../config/utility/formUtility'
import { HomepagePath, HomepageUtility } from '../../../../config/utility/homepageUtility'
import { getFragmentObject, getBackURL } from '../../../../shared/utils'

interface State {
  roleId?: string
  role: StwRole
  loader: boolean
  isCodeEditableByDescription: boolean
  homepagePaths: HomepagePath[]
}

class RoleForm extends Component<ExtendRouteComponentProps, State> {
  formRef = createRef<FormInstance>()

  constructor(props) {
    super(props)
    this.state = {
      roleId: undefined,
      role: {},
      loader: true,
      isCodeEditableByDescription: true,
      homepagePaths: [],
    }
  }

  componentDidMount() {
    this.initialize()
  }

  initialize = async () => {
    let result: StwRole = {}
    if (this.props.match) {
      const roleId = this.props.match.params.roleId
      if (roleId && roleId !== 'create') {
        result = await Roles.get<StwRole>(roleId)
      }
    }
    const paths = await HomepageUtility.getPagePaths()
    result.priority = result.priority ?? 5
    this.setState(
      {
        roleId: result ? result.id! : undefined,
        role: result || {},
        isCodeEditableByDescription: !result.id,
        homepagePaths: paths,
        loader: false,
      },
      this.updateFormFieldsValue
    )
  }

  updateFormFieldsValue = () => {
    if (this.formRef && this.formRef.current) {
      this.formRef.current.setFieldsValue(this.state.role)
    }
  }

  handleChange = (key, value) => {
    const { role, isCodeEditableByDescription } = this.state
    AttributeUtil.setAttribute(role, key, value)
    if (key === 'description' && isCodeEditableByDescription) {
      FormUtility.handleDescription(value, role, this.formRef)
    } else if (key === 'code') {
      this.setState({ isCodeEditableByDescription: false })
    } else if (key === 'attributes') {
      if (this.formRef && this.formRef.current) {
        this.formRef.current.setFieldsValue({ attributes: role.attributes })
      }
    }
    this.setState({ role: role })
  }

  store = () => {
    const { role, roleId } = this.state
    const { queryString } = this.props
    if (roleId) {
      Roles.update<StwRole>(role).then(() => {
        navigate(`/configuration/usersRoles/roles${queryString ?? ''}`)
      })
    } else {
      Roles.insert<StwRole>(role).then((newRole) => {
        if (newRole && newRole.id) {
          navigate(`/configuration/usersRoles/roles${queryString ?? ''}`)
        }
      })
    }
  }

  render() {
    const { breadcrumbs, queryString } = this.props
    const { role, roleId, loader, homepagePaths } = this.state
    const title = roleId ? __(T.role.edit) : __(T.role.create_new)
    const fragment = !loader ? getFragmentObject(role, 'id', __(T.role.create_new)) : undefined
    return (
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        onFinish={this.store}
        style={{ width: '100%', height: '100%' }}
        ref={this.formRef}
        initialValues={role}
        validateMessages={FormValidateMessages}
        scrollToFirstError
      >
        <StylewherePage {...this.props} noOverflow fragment={fragment}>
          <DefaultHeader
            backPath={getBackURL(queryString, breadcrumbs)}
            title={loader ? '...' : title}
            skeleton={{ active: loader }}
            actions={[
              {
                label: __(T.misc.cancel),
                type: 'cancel',
                onClick: () => navigate(`/configuration/usersRoles/roles${queryString ?? ''}`),
              },
              {
                label: roleId ? __(T.misc.update) : __(T.misc.create),
                type: 'submit',
              },
            ]}
          />
          <Section customClass="stw-section-page paged-header scroll">
            <AdvancedForm
              record={role}
              fields={ROLES_FIELDS}
              store={this.store}
              editing={role.id !== undefined}
              handleChange={this.handleChange}
              parameters={{
                homepagePaths: homepagePaths,
              }}
            />
          </Section>
        </StylewherePage>
      </Form>
    )
  }
}

export default RoleForm
