import { AppConfiguration } from '../types'

const index: AppConfiguration = {
  //base
  endpoint: process.env.REACT_APP_BACKEND_URL ?? 'https://api.stylewhere-dev.temera.it/',
  keycloakRealm: process.env.REACT_APP_KEYCLOAK_REALM ?? 'temera',
  keycloakUrl: process.env.REACT_APP_KEYCLOAK_URL ?? 'https://sso-dev.temera.it/',
  keycloakClientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID ?? 'stw-core-controlpanel',
  //login: admin / kcMsUbt3Oqyk*H%

  //cucinelli develop
  /*endpoint: 'https://api.cucinelli-stw-dev.temera.it/',
  keycloakRealm: 'cucinelli-dev',
  keycloakUrl: 'https://sso-dev.temera.it/',
  keycloakClientId: 'stw-core-station',*/
  //login: admintemera / Temera2022!

  //cucinelli quality
  /*endpoint: 'https://api.cucinelli-stw-qty.temera.it/',
  keycloakRealm: 'cucinelli',
  keycloakUrl: 'https://sso-dev.temera.it/',
  keycloakClientId: 'stw-core-controlpanel',*/
  //login daniele.ceci@temera.it / 20230113.dc

  //valentino develop
  /*endpoint: 'https://api.valentino-stw-dev.temera.it/',
  keycloakRealm: 'valentino-dev',
  keycloakUrl: 'https://sso-dev.temera.it/',
  keycloakClientId: 'stw-core-controlpanel',*/
  //login: admin / Temera2022!

  //moncler dev
  /*endpoint: 'https://api.moncler-stw-dev.temera.it/',
  keycloakRealm: 'moncler-dev',
  keycloakUrl: 'https://sso-dev.temera.it/',
  keycloakClientId: 'stw-core-station',*/
  //login admintemera / Temera2023!
}

export default index
