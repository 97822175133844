import { Component } from 'react'
import { AdvancedTable, GridData, StylewherePage, DefaultHeader, NotFound, Section } from '../../../components'
import Workstations from '../../../api/Workstations'
import { __, T } from '../../../shared/i18n'
import { AdvancedTableSortDirection } from '../../../components/AdvancedTable'
import { ExtendRouteComponentProps } from '../../../types'
import { WORKSTATION_ANTENNAS_COLUMNS, WORKSTATIONS_DATA } from '../../../config/Pages/Devices/Workstations'
import { StwFullWorkstation, DetailCell } from '../../../api'
import { DEFAULT_PAGE_SIZE } from '../../../constants'
import {
  getDetailDataWithAttributesMapping,
  getMultipleTitle,
  getBackURL,
  getFragmentObject,
} from '../../../shared/utils'

interface State {
  workstation: StwFullWorkstation | undefined
  loader: boolean
  detailData: DetailCell[]
}

class WorkstationDetail extends Component<ExtendRouteComponentProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      workstation: undefined,
      loader: true,
      detailData: WORKSTATIONS_DATA,
    }
  }

  componentDidMount() {
    this.initialize()
  }

  initialize = async () => {
    let fullWorkstation
    const data: DetailCell[] = await getDetailDataWithAttributesMapping(WORKSTATIONS_DATA, 'workstation', 2)
    if (this.props.match) {
      const workstationId = this.props.match.params.workstationId
      fullWorkstation = await Workstations.getFullWorkstation(workstationId)
    }
    this.setState({
      loader: false,
      detailData: data,
      workstation: fullWorkstation,
    })
  }

  render() {
    const { workstation, loader, detailData } = this.state
    const { breadcrumbs, queryString } = this.props
    const totalElements = workstation && workstation.rfidAntennas ? workstation.rfidAntennas.length : 0
    const fragment = getFragmentObject(workstation, 'id')
    return (
      <StylewherePage {...this.props} noOverflow fragment={fragment}>
        <DefaultHeader
          backPath={getBackURL(queryString, breadcrumbs)}
          multipleTitle={getMultipleTitle(workstation)}
          title={__(T.misc.not_found)}
          skeleton={{ active: loader, options: { multiple: true } }}
        />
        <Section customClass={`stw-section-page paged-header ${workstation || loader ? 'scroll transparent' : ''}`}>
          {workstation || loader ? (
            <>
              <GridData fields={detailData} record={workstation || {}} skeleton={loader} />
              <Section>
                <AdvancedTable
                  loader={loader}
                  tableTitle={{ title: __(T.misc.antennas_title), emptyTitle: __(T.workstations.noAntennasFoundTitle) }}
                  pagination={{
                    pageNumber: 1,
                    pageSize: DEFAULT_PAGE_SIZE,
                    sortValues: { creationDate: AdvancedTableSortDirection.desc },
                  }}
                  columns={WORKSTATION_ANTENNAS_COLUMNS}
                  filters={{
                    filterDefinitions: [],
                  }}
                  content={[...((workstation && workstation.rfidAntennas) ?? [])]}
                  totalElements={totalElements}
                  disabledPagination
                  disabledNoDataFound
                  activeDefaultClassColumn
                />
              </Section>
            </>
          ) : (
            <NotFound fullheight title={__(T.misc.noRecordFoundTitle)} />
          )}
        </Section>
      </StylewherePage>
    )
  }
}

export default WorkstationDetail
