import dayjs from 'dayjs'

import { AttributeUtil } from '../../config/utility/utility'
import {
  AdvancedTableFilter,
  AdvancedTableFilterKind,
  AdvancedTableFiltersDef,
  AdvancedTableFilterValues,
} from './advancedTableTypes'
import { AdvancedFormField, AdvancedFormInputType } from '../AdvancedForm/advancedFormTypes'
import { __ } from '../../shared/i18n'

export function advancedTableFilterDefinitionsToAdvancedFormFields(
  filterDefinitions: ReadonlyArray<AdvancedTableFilter>
): Array<AdvancedFormField> {
  return filterDefinitions.map((filterDefinition) => advancedTableFilterDefinitionToAdvancedFormField(filterDefinition))
}

export function advancedTableFilterDefinitionToAdvancedFormField(
  filterDefinition: AdvancedTableFilter
): AdvancedFormField {
  switch (filterDefinition.type) {
    case AdvancedTableFilterKind.STRING:
      return {
        key: filterDefinition.key,
        label: filterDefinition.label,
        placeholder: filterDefinition.label,
        type: AdvancedFormInputType.TEXT,
        col: filterDefinition.col || 24,
        displayInRow: true,
      }
    case AdvancedTableFilterKind.STRING_LIST:
      return {
        key: filterDefinition.key,
        label: filterDefinition.label,
        placeholder: filterDefinition.label,
        type: AdvancedFormInputType.STRING_LIST,
        col: filterDefinition.col || 24,
        displayInRow: true,
        maxTagCount: filterDefinition.maxTagCount || 2,
      }
    case AdvancedTableFilterKind.BOOLEAN:
      return {
        key: filterDefinition.key,
        label: filterDefinition.label,
        placeholder: filterDefinition.label,
        type: AdvancedFormInputType.SELECT,
        option: 'enabledValues',
        col: filterDefinition.col || 24,
        displayInRow: true,
      }
    case AdvancedTableFilterKind.ENUM:
      return {
        key: filterDefinition.key,
        label: filterDefinition.label,
        placeholder: filterDefinition.label,
        type: AdvancedFormInputType.SELECT,
        option: filterDefinition.select!.option,
        col: filterDefinition.col || 24,
        displayInRow: true,
      }
    case AdvancedTableFilterKind.SELECT:
      return {
        key: filterDefinition.key,
        label: filterDefinition.label,
        placeholder: __(`fields.placeholders.${filterDefinition.key}`, filterDefinition.label),
        type: AdvancedFormInputType.SELECT,
        option: filterDefinition.option,
        col: filterDefinition.col || 24,
        displayInRow: true,
        selectWithCustomSelection: filterDefinition.selectWithCustomSelection,
        maxTagCount: filterDefinition.maxTagCount || 2,
      }
    case AdvancedTableFilterKind.AUTOCOMPLETE:
      return {
        key: filterDefinition.key,
        label: filterDefinition.label,
        placeholder: filterDefinition.label,
        type: AdvancedFormInputType.AUTOCOMPLETE,
        option: filterDefinition.option,
        autocomplete: {
          endpoint: filterDefinition.autocomplete ? filterDefinition.autocomplete.endpoint : '',
          sort: filterDefinition.autocomplete ? filterDefinition.autocomplete.sort : '',
          multiple: filterDefinition.autocomplete ? filterDefinition.autocomplete!.multiple : false,
          withIdValues: true,
          fieldLabel: filterDefinition.autocomplete ? filterDefinition.autocomplete.fieldLabel : '',
        },
        col: filterDefinition.col || 24,
        displayInRow: true,
        maxTagCount: filterDefinition.maxTagCount || 2,
      }
    case AdvancedTableFilterKind.STRING_AUTOCOMPLETE:
      return {
        key: filterDefinition.key,
        label: filterDefinition.label,
        placeholder: __(`fields.placeholders.${filterDefinition.key}`, filterDefinition.label),
        type: AdvancedFormInputType.STRING_AUTOCOMPLETE,
        option: filterDefinition.option,
        autocomplete: {
          endpoint: filterDefinition.autocomplete ? filterDefinition.autocomplete.endpoint : '',
          sort: filterDefinition.autocomplete ? filterDefinition.autocomplete.sort : '',
          multiple: filterDefinition.autocomplete ? filterDefinition.autocomplete!.multiple : false,
          additionalParams: filterDefinition.autocomplete ? filterDefinition.autocomplete.additionalParams || {} : {},
          withIdValues: true,
        },
        col: filterDefinition.col || 24,
        displayInRow: true,
        maxTagCount: filterDefinition.maxTagCount || 2,
        dependencies: filterDefinition.dependencies || [],
      }
    case AdvancedTableFilterKind.DATE_RANGE:
      return {
        key: filterDefinition.key,
        label: filterDefinition.label,
        placeholder: filterDefinition.label,
        type: AdvancedFormInputType.RANGE_DATE,
        col: filterDefinition.col || 24,
        displayInRow: true,
        format: filterDefinition.format || 'DD/MM/YYYY HH:mm:ss',
      }
    default:
      return {
        key: filterDefinition.key,
        label: filterDefinition.label,
        placeholder: filterDefinition.label,
        type: AdvancedFormInputType.NOT_IMPLEMENTED,
        col: filterDefinition.col || 24,
        option: filterDefinition.type,
        displayInRow: true,
      }
  }
}

export function advancedTableFilterValuesFromLocation(location, filterDefinitions?: AdvancedTableFiltersDef) {
  const queryParameters = new URLSearchParams(location.search)
  return filterDefinitions
    ? filterDefinitions.reduce((filters, definition) => {
        switch (definition.type) {
          case AdvancedTableFilterKind.STRING:
          case AdvancedTableFilterKind.ENUM:
          case AdvancedTableFilterKind.BOOLEAN: {
            const filterValue = queryParameters.get(definition.key)
            AttributeUtil.setAttribute(filters, definition.key, filterValue ?? undefined)
            return filters
          }
          case AdvancedTableFilterKind.STRING_LIST:
          case AdvancedTableFilterKind.SELECT:
          case AdvancedTableFilterKind.STRING_AUTOCOMPLETE:
          case AdvancedTableFilterKind.AUTOCOMPLETE: {
            const filterValues = queryParameters.getAll(definition.key).map((filterValue) => filterValue.trim())
            AttributeUtil.setAttribute(filters, definition.key, filterValues)
            return filters
          }
          case AdvancedTableFilterKind.DATE_RANGE: {
            const filterStartValue = queryParameters.get(`${definition.key}Start`)
            const filterEndValue = queryParameters.get(`${definition.key}End`)
            if (filterStartValue || filterEndValue) {
              AttributeUtil.setAttribute(filters, definition.key, [
                filterStartValue && dayjs(filterStartValue, "YYYY-MM-DD'T'HH:mm:ss"),
                filterEndValue && dayjs(filterEndValue, "YYYY-MM-DD'T'HH:mm:ss"),
              ])
            }

            return filters
          }
          default:
            return filters
        }
      }, {})
    : []
}

export function advancedTableFilterValuesToQueryParameters(
  filterDefinitions: AdvancedTableFiltersDef,
  filters?: AdvancedTableFilterValues
) {
  const res = filterDefinitions
    .map((filterDefinition) => ({
      key: filterDefinition.key,
      value: AttributeUtil.getAttribute(filters, filterDefinition.key),
      type: filterDefinition.type,
    }))
    .filter((filter) => filter.value)
    .reduce((filterParameters, filterDefinition) => {
      const filterValue = AttributeUtil.getAttribute(filters, filterDefinition.key)
      switch (filterDefinition.type) {
        case AdvancedTableFilterKind.SELECT:
        case AdvancedTableFilterKind.STRING:
        case AdvancedTableFilterKind.ENUM:
        case AdvancedTableFilterKind.STRING_LIST:
        case AdvancedTableFilterKind.AUTOCOMPLETE:
        case AdvancedTableFilterKind.STRING_AUTOCOMPLETE:
        case AdvancedTableFilterKind.BOOLEAN:
          return {
            ...filterParameters,
            [filterDefinition.key]: filterValue,
          }
        case AdvancedTableFilterKind.DATE_RANGE: {
          let params = { ...filterParameters }
          if (filterValue[0]) {
            const date: string = (filterValue[0]._d || filterValue[0]).toString()
            const tzIndex = date.indexOf('+')
            const timezone = tzIndex > -1 ? date.substring(tzIndex) : '+0000'
            params = {
              ...params,
              [`${filterDefinition.key}Start`]: `${dayjs(filterValue[0]._d || filterValue[0]).format(
                'YYYY-MM-DDTHH:mm:ss'
              )}${timezone}`,
            }
          }

          if (filterValue[1]) {
            const date: string = (filterValue[1]._d || filterValue[1]).toString()
            const tzIndex = date.indexOf('+')
            const timezone = tzIndex > -1 ? date.substring(tzIndex) : '+0000'
            params = {
              ...params,
              [`${filterDefinition.key}End`]: `${dayjs(filterValue[1]._d || filterValue[1]).format(
                'YYYY-MM-DDTHH:mm:ss'
              )}${timezone}`,
            }
          }

          return params
        }
        default:
          return filterParameters
      }
    }, {})
  return res
}

export function advancedTableFilterValuesToQueryParametersFromLocation(
  location,
  filterDefinitions: AdvancedTableFiltersDef
) {
  const filterValues = advancedTableFilterValuesFromLocation(location, filterDefinitions)
  return advancedTableFilterValuesToQueryParameters(filterDefinitions, filterValues)
}
