import { Component } from 'react'
import { Col, Typography, Row } from 'antd'

const { Title } = Typography

interface Props {
  iconBkgColor: string
  bkgColor?: string
  col: number
  counter: number
  counter2?: number
  minH?: number
  mTop: number
}

export class GridCardsRowBody extends Component<Props> {
  getCounter = () => {
    const { counter, counter2 } = this.props
    if (counter2 !== undefined && counter2 >= 0) {
      return `${counter}/${counter2}`
    }
    return counter || 0
  }

  render() {
    const { col, minH, mTop, bkgColor, iconBkgColor } = this.props
    return (
      <Col span={col || 24}>
        <Row
          style={{ marginTop: mTop, minHeight: minH || 140, backgroundColor: bkgColor || iconBkgColor }}
          className="stylewhere-card-body"
        >
          <Title level={1}>{this.getCounter()}</Title>
        </Row>
      </Col>
    )
  }
}
