import { Component, createRef, RefObject } from 'react'
import { Form, FormInstance } from 'antd'
import { ExtendRouteComponentProps } from '../../../types'
import { AdvancedForm, StylewherePage, DefaultHeader, Section } from '../../../components'
import {
  FormValidateMessages,
  xspanDeviceControllerTypeFilters,
  XspanFieldsOfView,
  XspanModes,
  XspanOperationFilters,
} from '../../../constants'
import { navigate } from '../../../shared/router'
import { __, T } from '../../../shared/i18n'
import { XSPAN_FIELDS } from '../../../config/Pages/Devices/Xspans'
import { StwFullXspan, StwPlace, StwXspan, StwZone } from '../../../api'
import Xspans from '../../../api/Xspans'
import { AttributeUtil } from '../../../config/utility/utility'
import { DeviceUtility } from '../../../config/utility/deviceUtility'
import { getFragmentObject, getBackURL } from '../../../shared/utils'

interface State {
  xspan: StwFullXspan
  sector2ZoneFilters: { [filterKey: string]: any }
  sector3ZoneFilters: { [filterKey: string]: any }
  keepAlivePeriodRules: { required: boolean }
  linkDownThresholdRules: { required: boolean }
  loader: boolean
}

export default class XspanForm extends Component<ExtendRouteComponentProps, State> {
  formRef = createRef<FormInstance>()

  constructor(props) {
    super(props)
    this.state = {
      xspan: {
        autoStart: true,
      },
      sector2ZoneFilters: { type: 'STOCK', placeId: '', excludedIds: [] },
      sector3ZoneFilters: { type: 'STOCK', placeId: '', excludedIds: [] },
      keepAlivePeriodRules: { required: false },
      linkDownThresholdRules: { required: false },
      loader: true,
    }
  }

  componentDidMount = async () => {
    let xspan: StwFullXspan = this.state.xspan
    if (this.props.match) {
      const xspanId = this.props.match.params.xspanId
      if (xspanId !== '' && xspanId !== 'create') {
        xspan = await Xspans.getFull(xspanId)
      }
    }

    const { sector2ZoneFilters, sector3ZoneFilters } = this.state
    const placeId = xspan.place?.id ?? undefined
    sector2ZoneFilters.placeId = placeId
    sector3ZoneFilters.placeId = placeId
    const sector3ZoneId = xspan.sector3Zone?.id ?? undefined
    sector2ZoneFilters.excludedIds = sector3ZoneId ? [sector3ZoneId] : []
    const sector2ZoneId = xspan.sector2Zone?.id ?? undefined
    sector3ZoneFilters.excludedIds = sector2ZoneId ? [sector2ZoneId] : []

    this.setState(
      {
        xspan: xspan,
        sector2ZoneFilters: sector2ZoneFilters,
        sector3ZoneFilters: sector3ZoneFilters,
        loader: false,
      },
      this.updateFormFieldsValue
    )
  }

  updateFormFieldsValue = () => {
    if (this.formRef && this.formRef.current) {
      this.formRef.current.setFieldsValue(this.state.xspan)
    }
  }

  private static updateCodeAndDescription(xspan: StwFullXspan, formRef: RefObject<FormInstance>) {
    Xspans.search<StwXspan>({ page: 0, size: 1, placeId: xspan.place!.id })
      .then((xspans) => {
        return {
          code: xspan.place!.code && `${xspan.place!.code}_X${xspans.totalElements + 1}`,
          description: xspan.place!.description && `${xspan.place!.description}_X${xspans.totalElements + 1}`,
        }
      })
      .then(({ code, description }) => {
        DeviceUtility.setFieldValue(xspan, 'code', code, formRef)
        DeviceUtility.setFieldValue(xspan, 'description', description, formRef)
      })
  }

  private static updateSectorZones(
    xspan: StwFullXspan,
    sector2ZoneFilters,
    sector3ZoneFilters,
    formRef: RefObject<FormInstance>
  ) {
    XspanForm.updateSectorZone(xspan, sector2ZoneFilters, 'sector2Zone', formRef)
    XspanForm.updateSectorZone(xspan, sector3ZoneFilters, 'sector3Zone', formRef)
    const sector3ZoneId = xspan.sector3Zone?.id ?? undefined
    sector2ZoneFilters.excludedIds = sector3ZoneId ? [sector3ZoneId] : []
    const sector2ZoneId = xspan.sector2Zone?.id ?? undefined
    sector3ZoneFilters.excludedIds = sector2ZoneId ? [sector2ZoneId] : []
  }

  private static updateSectorZone(
    xspan: StwFullXspan,
    sectorZoneFilter,
    sectorZoneAttribute: string,
    formRef: RefObject<FormInstance>
  ) {
    sectorZoneFilter.placeId = xspan.place?.id ?? undefined
    DeviceUtility.setFieldValue(xspan, sectorZoneAttribute, undefined, formRef)
  }

  async store() {
    const { xspan } = this.state
    const { queryString } = this.props
    if (xspan.id) {
      await Xspans.updateFull(xspan)
      navigate(`/devices/xspans${queryString ?? ''}`)
    } else {
      const newXspan = await Xspans.insertFull(xspan)
      if (newXspan && newXspan.id) {
        navigate(`/devices/xspans${queryString ?? ''}`)
      }
    }
  }

  handleChange(key, value) {
    const { keepAlivePeriodRules, linkDownThresholdRules, xspan } = this.state
    switch (key) {
      case 'place':
        this.handlePlaceChange(value)
        break
      case 'sector2Zone':
        this.handleSector2ZoneChange(value)
        break
      case 'sector3Zone':
        this.handleSector3ZoneChange(value)
        break
      case 'keepAliveEnabled':
        this.handleFieldEnabled('keepAliveEnabled', value)
        XspanForm.updateFieldEnabledRules(keepAlivePeriodRules, xspan.keepAliveEnabled).then(() => {
          this.setState({ keepAlivePeriodRules })
        })
        break
      case 'linkMonitorModeEnabled':
        this.handleFieldEnabled('linkMonitorModeEnabled', value)
        XspanForm.updateFieldEnabledRules(linkDownThresholdRules, xspan.linkMonitorModeEnabled).then(() => {
          this.setState({ linkDownThresholdRules })
        })
        break
      default:
        this.handleDefaultChange(key, value)
    }
  }

  private handleDefaultChange(key: string, value: any) {
    const { xspan } = this.state
    AttributeUtil.setAttribute(xspan, key, value)
    if (key === 'attributes' && this.formRef && this.formRef.current) {
      this.formRef.current.setFieldsValue({ attributes: xspan.attributes })
    }
    this.setState({ xspan })
  }

  private handlePlaceChange(place?: StwPlace) {
    const { xspan, sector2ZoneFilters, sector3ZoneFilters } = this.state
    AttributeUtil.setAttribute(xspan, 'place', place)

    if (place) {
      XspanForm.updateCodeAndDescription(xspan, this.formRef)
    } else {
      DeviceUtility.resetCodeAndDescription(xspan, this.formRef)
    }

    XspanForm.updateSectorZones(xspan, sector2ZoneFilters, sector3ZoneFilters, this.formRef)
    this.setState({ xspan, sector2ZoneFilters, sector3ZoneFilters })
  }

  private handleSector2ZoneChange(sector2Zone: StwZone) {
    const { xspan, sector3ZoneFilters } = this.state
    AttributeUtil.setAttribute(xspan, 'sector2Zone', sector2Zone)
    const sector2ZoneId = xspan.sector2Zone?.id ?? undefined
    sector3ZoneFilters.excludedIds = sector2ZoneId ? [sector2ZoneId] : []
    this.setState({ xspan, sector3ZoneFilters })
  }

  private handleSector3ZoneChange(sector3Zone: StwZone) {
    const { xspan, sector2ZoneFilters } = this.state
    AttributeUtil.setAttribute(xspan, 'sector3Zone', sector3Zone)
    const sector3ZoneId = xspan.sector3Zone?.id ?? undefined
    sector2ZoneFilters.excludedIds = sector3ZoneId ? [sector3ZoneId] : []
    this.setState({ xspan, sector2ZoneFilters })
  }

  private handleFieldEnabled(field: string, fieldValue: boolean | undefined) {
    const { xspan } = this.state
    AttributeUtil.setAttribute(xspan, field, fieldValue)
    this.setState({ xspan })
  }

  private static async updateFieldEnabledRules(rules, fieldValue: boolean | undefined) {
    rules.required = !!fieldValue
  }

  render() {
    const { xspan, sector2ZoneFilters, sector3ZoneFilters, loader, linkDownThresholdRules, keepAlivePeriodRules } =
      this.state
    const { breadcrumbs, queryString } = this.props
    const title = xspan.id ? __(T.xspans.edit) : __(T.xspans.create_new)
    const fragment = !loader ? getFragmentObject(xspan, 'id', __(T.xspans.create_new)) : undefined
    return (
      <Form
        ref={this.formRef}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        onFinish={async () => {
          await this.store()
        }}
        style={{ width: '100%', height: '100%' }}
        initialValues={xspan}
        validateMessages={FormValidateMessages}
        scrollToFirstError
      >
        <StylewherePage {...this.props} noOverflow fragment={fragment}>
          <DefaultHeader
            backPath={getBackURL(queryString, breadcrumbs)}
            title={loader ? '...' : title}
            skeleton={{ active: loader }}
            actions={[
              {
                label: __(T.misc.cancel),
                type: 'cancel',
                onClick: () => navigate(`/devices/xspans${queryString ?? ''}`),
              },
              {
                label: xspan.id ? __(T.misc.update) : __(T.misc.create),
                type: 'submit',
              },
            ]}
          />
          <Section customClass="stw-section-page paged-header scroll">
            <AdvancedForm
              record={xspan}
              fields={XSPAN_FIELDS}
              store={() => this.store()}
              editing={xspan.id !== undefined}
              handleChange={(key, value) => this.handleChange(key, value)}
              parameters={{
                deviceControllerTypeFilters: xspanDeviceControllerTypeFilters,
                sector2ZonesFilters: sector2ZoneFilters,
                sector3ZonesFilters: sector3ZoneFilters,
                modes: XspanModes,
                fieldsOfView: XspanFieldsOfView,
                operationTypeFilters: XspanOperationFilters,
                linkDownThresholdRules: [linkDownThresholdRules],
                keepAlivePeriodRules: [keepAlivePeriodRules],
              }}
            />
          </Section>
        </StylewherePage>
      </Form>
    )
  }
}
