import {
  AdvancedTableColumn,
  AdvancedTableContentKind,
  AdvancedTableFilterKind,
  AdvancedTableFiltersDef,
} from '../../../components/AdvancedTable'
import {
  AdvancedFormAttributeType,
  AdvancedFormField,
  AdvancedFormFieldBuilder,
  AdvancedFormInputType,
} from '../../../components/AdvancedForm/advancedFormTypes'
import { __ } from '../../../shared/i18n'
import { AdvancedTableRowColumn } from '../../../components'
import { DetailCell } from '../../../api'
import { CAPABILITIES, getActionsTableColumns } from '../../utility/capabilityUtility'

export const OPERATION_FILTERS: AdvancedTableFiltersDef = [
  {
    key: 'equalCodes',
    label: __('fields.labels.code'),
    type: AdvancedTableFilterKind.STRING_LIST,
    column: 'code',
    col: 8,
  },
  {
    key: 'description',
    label: __('fields.labels.description'),
    type: AdvancedTableFilterKind.STRING,
    column: 'description',
    col: 8,
  },
  {
    key: 'type',
    label: __('fields.labels.type'),
    type: AdvancedTableFilterKind.ENUM,
    column: 'type',
    select: {
      option: 'types',
    },
    col: 8,
  },
  {
    key: 'enabled',
    label: __('fields.labels.enabled'),
    type: AdvancedTableFilterKind.BOOLEAN,
    column: 'enabled',
    col: 8,
  },
  {
    key: 'clients',
    label: __('fields.labels.clients'),
    type: AdvancedTableFilterKind.SELECT,
    option: 'operationClients',
    column: 'clients',
    col: 8,
    selectWithCustomSelection: true,
  },
  {
    key: 'creationDate',
    label: __('fields.labels.creationDate'),
    type: AdvancedTableFilterKind.DATE_RANGE,
    column: 'creationDate',
    col: 8,
  },
  {
    key: 'lastModifyDate',
    label: __('fields.labels.lastModifyDate'),
    type: AdvancedTableFilterKind.DATE_RANGE,
    column: 'lastModifyDate',
    col: 8,
  },
]

export const OPERATIONS_FIELD: AdvancedFormField[] = [
  AdvancedFormFieldBuilder.buildSelect(
    'type',
    'types',
    [{ required: true }],
    undefined,
    undefined,
    undefined,
    undefined,
    false,
    undefined,
    true
  ),
  {
    key: 'enabled',
    type: AdvancedFormInputType.SWITCH,
    placeholder: __('misc.disabled'),
    rules: [{ required: false }],
  },
  AdvancedFormFieldBuilder.buildText('description'),
  {
    key: 'code',
    type: AdvancedFormInputType.UNIQUE_TEXT,
    label: __('fields.labels.code'),
    placeholder: __('fields.placeholders.code'),
    rules: [{ required: true }],
    unique: {
      endpoint: '/api/v1/operations',
      parameter: 'equalCodes',
    },
  },
  {
    key: 'clients',
    type: AdvancedFormInputType.SELECT,
    placeholder: __('fields.placeholders.clients'),
    rules: [{ required: true }],
    option: 'operationClients',
    selectWithCustomSelection: true,
  },
  {
    key: 'displayPriority',
    type: AdvancedFormInputType.NUMBER,
    placeholder: __('misc.disabled'),
    rules: [{ required: true }],
    range: { min: 0 },
  },
  {
    key: 'options',
    type: AdvancedFormInputType.INLINE_OPTIONS,
    col: 24,
  },
  AdvancedFormFieldBuilder.buildAttribute('attributes', AdvancedFormAttributeType.SIMPLE, true),
  AdvancedFormFieldBuilder.buildAttribute('actions', AdvancedFormAttributeType.ACTION, true),
  {
    key: 'formSchema',
    type: AdvancedFormInputType.JSON,
    col: 24,
    marginBottom: 6,
  },
]

export const OPERATION_COLUMNS: AdvancedTableColumn[] = [
  {
    title: __(`fields.labels.code`),
    key: 'code',
    dataIndex: 'code',
  },
  {
    title: __(`fields.labels.description`),
    key: 'description',
    dataIndex: 'description',
    className: 'stw-extra-medium',
  },
  {
    title: __(`fields.labels.type`),
    key: 'type',
    dataIndex: 'type',
    className: 'stw-extra-medium',
  },
  {
    key: 'enabled',
    title: __(`fields.labels.enabled`),
    dataIndex: 'enabled',
    type: AdvancedTableContentKind.BOOLEAN,
    className: 'stw-small',
  },
  {
    key: 'clients',
    title: __(`fields.labels.clients`),
    dataIndex: 'clients',
    type: AdvancedTableContentKind.MULTIPLE_STRING,
    className: 'stw-extra-medium',
  },
  {
    key: 'displayPriority',
    title: __('fields.labels.displayPriority'),
    dataIndex: 'displayPriority',
    type: AdvancedTableContentKind.NUMBER,
    className: 'stw-small',
  },
  {
    key: 'creationDate',
    title: __(`fields.labels.creationDate`),
    dataIndex: 'creationDate',
    type: AdvancedTableContentKind.DATE_TIME,
  },
  {
    key: 'lastModifyDate',
    title: __(`fields.labels.lastModifyDate`),
    dataIndex: 'lastModifyDate',
    type: AdvancedTableContentKind.DATE_TIME,
  },
  {
    key: 'action',
    className: 'stw-extra-small',
    fixedType: 'right',
    unmanageable: true,
    hidden: false,
    sortable: false,
    render: (text, record) => (
      <AdvancedTableRowColumn
        actions={getActionsTableColumns(
          record.id,
          '/configuration/settings/operations',
          CAPABILITIES.RESOURCE_OPERATION_EDIT
        )}
      />
    ),
  },
]

export const OPERATIONS_DATA: DetailCell[] = [
  { title: 'id', attribute: 'id', columns: 24 },
  { title: __(`fields.labels.code`), attribute: 'code', columns: 12 },
  { title: __(`fields.labels.description`), attribute: 'description', columns: 12 },
  { title: __(`fields.labels.enabled`), attribute: 'enabled', columns: 12, type: 'boolean' },
  { title: __(`fields.labels.type`), attribute: 'type', columns: 12 },
  { title: __(`fields.labels.clients`), attribute: 'clients', columns: 12, type: 'stringList' },
  { title: __(`fields.labels.displayPriority`), attribute: 'displayPriority', columns: 12, type: 'number' },
  { title: __(`fields.labels.creationDate`), attribute: 'creationDate', type: 'date', columns: 12 },
  { title: __(`fields.labels.lastModifyDate`), attribute: 'lastModifyDate', type: 'date', columns: 12 },
  { title: 'formSchema', attribute: 'formSchema', columns: 24, type: 'json' },
]
