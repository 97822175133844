import AsyncJobs from 'core/api/AsyncJobs'
import { AdvancedTableRowColumn, KeyValueGrid } from 'core/components'
import { SVGAssets } from 'core/assets'
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons'
import { openModal } from 'core/shared/utils'
import { DetailCell } from '../../api'
import { AdvancedFormField, AdvancedFormFieldBuilder } from '../../components/AdvancedForm/advancedFormTypes'
import {
  AdvancedTableColumn,
  AdvancedTableContentKind,
  AdvancedTableFilterKind,
  AdvancedTableFiltersDef,
} from '../../components/AdvancedTable'
import { __ } from '../../shared/i18n'

export function getTagOptionsColor(value: string) {
  if (value === 'queued') return { color: 'orange' }
  if (value === 'done') return { color: 'green' }
  if (value === 'in_progress') return { color: 'blue' }
  return {}
}

export const JOB_FILTERS: AdvancedTableFiltersDef = [
  {
    key: 'description',
    label: __('fields.labels.description'),
    type: AdvancedTableFilterKind.STRING,
    column: 'description',
    col: 8,
  },
  {
    key: 'creationDate',
    label: __('fields.labels.creationDate'),
    type: AdvancedTableFilterKind.DATE_RANGE,
    column: 'creationDate',
    col: 8,
  },
  {
    key: 'lastModifyDate',
    label: __('fields.labels.lastModifyDate'),
    type: AdvancedTableFilterKind.DATE_RANGE,
    column: 'lastModifyDate',
    col: 8,
  },
]

export const fields: AdvancedFormField[] = [AdvancedFormFieldBuilder.buildText('description')]

const renderStatusChip = (value: string) => (
  <AdvancedTableRowColumn
    options={getTagOptionsColor(value)}
    tag
    value={
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {value === 'in_progress' && (
          <div style={{ marginRight: 5, height: 13, width: 13 }} className="spin">
            <SVGAssets.Spinner />
          </div>
        )}
        {__(`jobs.status.${value}`)}
      </div>
    }
  />
)

const renderDescription = (value: string) => {
  const descriptionToRender = value.slice(0, value.lastIndexOf('_')).replaceAll('_', ' ')

  return <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>{descriptionToRender}</div>
}

export const JOB_COLUMNS: AdvancedTableColumn[] = [
  {
    key: 'creationDate',
    title: __(`fields.labels.creationDate`),
    dataIndex: 'creationDate',
    type: AdvancedTableContentKind.DATE_TIME,
  },
  {
    title: __(`fields.labels.description`),
    key: 'description',
    dataIndex: 'description',
    className: 'stw-extra-medium',
  },
  {
    title: __(`fields.labels.creationUser`),
    key: 'creationUser.username',
    dataIndex: 'creationUser.username',
    className: 'stw-extra-medium',
  },
  {
    key: 'lastModifyDate',
    title: __(`fields.labels.lastModifyDate`),
    dataIndex: 'lastModifyDate',
    type: AdvancedTableContentKind.DATE_TIME,
  },
  {
    title: __(`fields.labels.status`),
    key: 'status',
    dataIndex: 'status',
    className: 'stw-small',
    render: (text) => renderStatusChip(text.toLowerCase()),
  },
  {
    key: 'action',
    width: 'small',
    fixedType: 'right',
    className: 'stw-small',
    sortable: false,
    unmanageable: true,
    render: (_text, record) => {
      const columns: DetailCell[] = [
        { title: __('fields.labels.id'), attribute: 'id', columns: 16 },
        {
          title: __(`fields.labels.status`),
          attribute: 'status',
          columns: 8,
          render: (r) => renderStatusChip(r.status.toLowerCase()),
        },
        {
          title: __('fields.labels.description'),
          attribute: 'description',
          columns: 24,
          render: (r) => renderDescription(r.description),
        },
        { title: __('fields.labels.creationUser'), attribute: 'creationUser.username', columns: 24 },
        { title: __('fields.labels.creationDate'), attribute: 'creationDate', columns: 12, type: 'date' },
        { title: __('fields.labels.lastModifyDate'), attribute: 'lastModifyDate', columns: 12, type: 'date' },
      ]

      const actions: any[] = [
        {
          content: <EyeOutlined />,
          type: 'default',
          onclick: () => {
            openModal({
              title: __('jobs.job_detail'),
              body: <KeyValueGrid fields={columns} data={record} />,
            })
          },
        },
      ]
      if (record.status === 'DONE') {
        
        actions.push({
          content: <DownloadOutlined style={{ height: 18, width: 18 }} />,
          type: 'default',
          onclick: async () => {
            try {
              const pom = document.createElement('a')
              let urlBlob
              
              if(  record?.jobData?.exportFileFormat === "xls" ) {
                const fileContent = await AsyncJobs.downloadFile(record.id, 'blob')
                const blob = new Blob([fileContent], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' })
                urlBlob = URL.createObjectURL(blob)
                pom.href = urlBlob
                pom.setAttribute('download', `${record.description}.xlsx`)
              } else {
                const fileContent = await AsyncJobs.downloadFile(record.id)
                const blob = new Blob([fileContent], { type: 'text/csv;charset=utf-8;' })
                urlBlob = URL.createObjectURL(blob)
                pom.href = urlBlob
                pom.setAttribute('download', `${record.description}.csv`)
              }
              
              pom.click()
              
              setTimeout(() => {
                URL.revokeObjectURL(urlBlob);
              }, 200);
            } catch (error) {
              //ignore
            }
          },
        })
      }
      return <AdvancedTableRowColumn actions={actions} />
    },
  },
]

export const JOB_DETAIL: DetailCell[] = [
  { title: __(`fields.labels.id`), attribute: 'id', columns: 24 },
  { title: __(`fields.labels.code`), attribute: 'code', columns: 12 },
  { title: __(`fields.labels.description`), attribute: 'description', columns: 12 },
  { title: __(`fields.labels.type`), attribute: 'type', columns: 12 },
  { title: __(`fields.labels.creationDate`), attribute: 'creationDate', columns: 12, type: 'date' },
  { title: __(`fields.labels.lastModifyDate`), attribute: 'lastModifyDate', columns: 12, type: 'date' },
]
