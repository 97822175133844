import { Component } from 'react'
import { StylewherePage, TableList } from '../../../components'
import { __, T } from '../../../shared/i18n'
import { AdvancedTableConfig } from '../../../components/AdvancedTable'
import { ExtendRouteComponentProps } from '../../../types'
import { CAPABILITIES, getActionsList } from '../../../config/utility/capabilityUtility'
import Templates from '../../../api/Templates'
import { TEMPLATES_COLUMNS, TEMPLATES_FILTERS } from '../../../config/Pages/Templates'
import { TemplateTypeValues, TemplateOutputTypeValues } from '../../../constants'

const TEMPLATES_SETTING_TABLE_CONFIG: AdvancedTableConfig = {
  columns: TEMPLATES_COLUMNS,
  filterDefinitions: TEMPLATES_FILTERS,
  filterParameters: { templateType: TemplateTypeValues, templateOutputType: TemplateOutputTypeValues },
  manageColumnsPrefix: 'templates',
  activeDefaultClassColumn: true,
}

class TemplatesList extends Component<ExtendRouteComponentProps> {
  render() {
    return (
      <StylewherePage {...this.props} noOverflow>
        <TableList
          title={__(T.menu.templates)}
          location={this.props.location}
          config={TEMPLATES_SETTING_TABLE_CONFIG}
          resource={{
            call: Templates.searchTableList,
            endpoint: Templates.endpoint,
          }}
          actions={getActionsList(
            CAPABILITIES.RESOURCE_TEMPLATES_EDIT,
            __(T.templates.create_new),
            '/configuration/templates/create/form'
          )}
        />
      </StylewherePage>
    )
  }
}

export default TemplatesList
