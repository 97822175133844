import { Component } from 'react'
import { StylewherePage, TableList } from '../../../components'
import { __, T } from '../../../shared/i18n'
import Places from '../../../api/Places'
import { PLACE_COLUMNS, PLACE_FILTERS, PLACE_TYPES } from '../../../config/Pages/Places'
import { AdvancedTableConfig } from '../../../components/AdvancedTable'
import { ExtendRouteComponentProps } from '../../../types'
import { CAPABILITIES, getActionsList } from '../../../config/utility/capabilityUtility'

const PLACE_TABLE_CONFIG: AdvancedTableConfig = {
  columns: PLACE_COLUMNS,
  filterDefinitions: PLACE_FILTERS,
  filterParameters: { types: PLACE_TYPES },
  manageColumnsPrefix: 'places',
  entity: 'place',
  activeDefaultClassColumn: true,
}

class PlacesList extends Component<ExtendRouteComponentProps> {
  render() {
    return (
      <StylewherePage {...this.props} noOverflow>
        <TableList
          title={__(T.menu.places)}
          location={this.props.location}
          config={PLACE_TABLE_CONFIG}
          resource={{ call: Places.searchTableList, endpoint: Places.endpoint }}
          actions={getActionsList(
            CAPABILITIES.RESOURCE_PLACE_EDIT,
            __('place.create_new'),
            '/configuration/places/create/form'
          )}
        />
      </StylewherePage>
    )
  }
}

export default PlacesList
