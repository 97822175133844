import { Component, createRef } from 'react'
import { Form, FormInstance, notification } from 'antd'
import { AdvancedForm, StylewherePage, Section, DefaultHeader } from '../../../../components'
import { ExtendRouteComponentProps } from '../../../../types'
import ItemConfigurations from '../../../../api/ItemConfigurations'
import { ITEM_CONFIGURATION_FIELDS as ItemsConfigurationFields } from '../../../../config/Pages/Settings/ItemConfigurations'
import { navigate } from '../../../../shared/router'
import { __, T } from '../../../../shared/i18n'
import { StwItemConfiguration } from '../../../../api'
import { AttributeUtil } from '../../../../config/utility/utility'
import { FormValidateMessages, IdentifierTypes } from '../../../../constants'
import { FormUtility } from '../../../../config/utility/formUtility'
import { getFragmentObject, getBackURL } from '../../../../shared/utils'

interface State {
  itemConfiguration: StwItemConfiguration
  loader: boolean
  isCodeEditableByDescription: boolean
}

class ItemConfigurationForm extends Component<ExtendRouteComponentProps, State> {
  formRef = createRef<FormInstance>()

  constructor(props) {
    super(props)
    this.state = {
      itemConfiguration: {},
      loader: true,
      isCodeEditableByDescription: true,
    }
  }

  componentDidMount() {
    this.initialize()
  }

  initialize = async () => {
    const { match } = this.props
    let result
    if (match) {
      const itemConfigurationId = match.params.itemConfigurationId
      if (itemConfigurationId !== '' && itemConfigurationId !== 'create') {
        result = await ItemConfigurations.get(itemConfigurationId)
        if (result) {
          const details = {}
          result.details.map((detail, i) => (details[`details${i}`] = detail))
          result.details = details
        }
      }
    }

    this.setState(
      {
        itemConfiguration: result || { details: [] },
        isCodeEditableByDescription: !result,
        loader: false,
      },
      this.updateFormFieldsValue
    )
  }

  updateFormFieldsValue = () => {
    if (this.formRef && this.formRef.current) {
      this.formRef.current.setFieldsValue(this.state.itemConfiguration)
    }
  }

  handleChange = async (key, value) => {
    const { itemConfiguration, isCodeEditableByDescription } = this.state
    let editable = isCodeEditableByDescription
    AttributeUtil.setAttribute(itemConfiguration, key, value)
    if (key === 'description' && isCodeEditableByDescription) {
      FormUtility.handleDescription(value, itemConfiguration, this.formRef)
    } else if (key === 'code') {
      editable = false
    } else if (key === 'details') {
      if (this.formRef && this.formRef.current) {
        this.formRef.current.setFieldsValue({ details: itemConfiguration.details })
      }
    }
    this.setState({
      itemConfiguration: itemConfiguration,
      isCodeEditableByDescription: editable,
    })
  }

  store = () => {
    const itemConfigurationToSave = this.state.itemConfiguration
    //si rimappano i details
    if (itemConfigurationToSave.details) {
      const keys = Object.keys(itemConfigurationToSave.details)
      const details: any[] = []
      for (let k = 0; k < keys.length; k++) {
        details.push(itemConfigurationToSave.details[keys[k]])
      }
      itemConfigurationToSave.details = details
    }
    // TODO da riportare nel componente AdvancedFormAttribute
    if (!itemConfigurationToSave.details || itemConfigurationToSave.details.length === 0) {
      notification.error({
        message: 'Validation error',
        description: 'Identifiers list cannot be empty',
        placement: 'bottomRight',
        duration: 3,
      })
      return
    }
    const { queryString } = this.props
    if (itemConfigurationToSave.id) {
      ItemConfigurations.update<StwItemConfiguration>(itemConfigurationToSave).then(() => {
        navigate(`/configuration/settings/itemConfigurations${queryString ?? ''}`)
      })
    } else {
      ItemConfigurations.insert<StwItemConfiguration>(itemConfigurationToSave).then((z) => {
        if (z && z.id) {
          navigate(`/configuration/settings/itemConfigurations${queryString ?? ''}`)
        }
      })
    }
  }

  render() {
    const { itemConfiguration, loader } = this.state
    const { breadcrumbs, queryString } = this.props
    const title = itemConfiguration.id ? __(T.itemconfiguration.edit) : __(T.itemconfiguration.create_new)
    const fragment = !loader
      ? getFragmentObject(itemConfiguration, 'id', __(T.itemconfiguration.create_new))
      : undefined
    return (
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        onFinish={this.store}
        style={{ width: '100%', height: '100%' }}
        ref={this.formRef}
        initialValues={itemConfiguration}
        validateMessages={FormValidateMessages}
        scrollToFirstError
      >
        <StylewherePage {...this.props} noOverflow fragment={fragment}>
          <DefaultHeader
            backPath={getBackURL(queryString, breadcrumbs)}
            title={loader ? '...' : title}
            skeleton={{ active: loader }}
            actions={[
              {
                label: __(T.misc.cancel),
                type: 'cancel',
                onClick: () => navigate(`/configuration/settings/itemConfigurations${queryString ?? ''}`),
              },
              {
                label: itemConfiguration.id && itemConfiguration.id !== '' ? __(T.misc.update) : __(T.misc.create),
                type: 'submit',
              },
            ]}
          />
          <Section customClass="stw-section-page paged-header scroll">
            <AdvancedForm
              record={itemConfiguration}
              handleChange={this.handleChange}
              parameters={{ attributeKeys: IdentifierTypes }}
              fields={ItemsConfigurationFields}
              editing={itemConfiguration.id !== undefined}
            />
          </Section>
        </StylewherePage>
      </Form>
    )
  }
}

export default ItemConfigurationForm
