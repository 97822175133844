import { Component } from 'react'
import { Skeleton, Row, Col } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { StwNotification } from '../../api'
import { getNotificationTime } from '../../shared/utils'

interface Props {
  notification: StwNotification
  loader: boolean
  handleDelete: any
  handleDetail: any
  canDelete: boolean
}

interface State {
  isread: boolean
}

export class NotificationRowElement extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      isread: false,
    }
  }

  componentDidMount() {
    this.init()
  }

  init = async () => {
    const { notification } = this.props
    this.setState({
      isread: notification.read !== undefined ? notification.read : false,
    })
  }

  shouldComponentUpdate = (nextProps) => {
    const { isread } = this.state
    if (nextProps.notification && nextProps.notification.read !== isread) {
      this.setState({
        isread: nextProps.notification.read,
      })
    }
    return true
  }

  delete = (e, id) => {
    e.preventDefault()
    e.stopPropagation()
    this.props.handleDelete(id)
  }

  openDetail = () => {
    const { notification, handleDetail } = this.props
    handleDetail(notification)
  }

  render() {
    const { isread } = this.state
    const { notification, loader, canDelete } = this.props
    return (
      <Row
        onClick={() => (loader ? {} : this.openDetail())}
        className={`stw-sider-notification-list${!isread ? ' new' : ''}`}
      >
        {loader ? (
          <Skeleton.Input active style={{ height: 30, borderRadius: 5 }} size="large" />
        ) : (
          <div className="stw-sider-notification-title">
            <div className={`status${!isread ? ' unread' : ''}`} />
            <span className="label">{notification.description}</span>
            <span className="time">{getNotificationTime(notification.creationDate)}</span>
            {canDelete && (
              <Col onClick={(event) => this.delete(event, notification.id)} className="status delete">
                <DeleteOutlined style={{ fontSize: 14 }} />
              </Col>
            )}
          </div>
        )}
      </Row>
    )
  }
}
