import { ModalType } from 'core/types'
import { api } from '../api'
import ApplicationSetting from '../api/ApplicationSetting'
import { changeLanguage as changeLanguage_i18n } from './i18n'
import { keycloak, Router, Storage } from '.'
import { Auth } from '../api/Auth'
import { ConnectionError } from './errors'
import { HomepageUtility } from '../config/utility/homepageUtility'
import { CAPABILITIES } from '../config/utility/capabilityUtility'

class AppStore {
  authToken?: string
  loggedUser?: any
  language = 'it'
  languages = ['it', 'en']
  emulation = false
  applicationSettings?: any[]

  openModal!: (modal: ModalType) => void
  closeModal!: (id: string) => void

  reloadRouting!: () => void

  async loadInitialData() {
    // check language
    this.language = await Storage.load('language', 'it')
    changeLanguage_i18n(this.language)
    await this.loadAuthToken()
    if (this.authToken) {
      try {
        this.loggedUser = await Auth.loggedUser()
        this.applicationSettings = await ApplicationSetting.client()
        if (this.loggedUser && this.loggedUser.homepage) {
          if (this.loggedUser.capabilities && this.loggedUser.capabilities.length > 0) {
            const homepagePaths = await HomepageUtility.getPagePaths()
            if (homepagePaths) {
              const homepage = homepagePaths.find((value) => value.id === this.loggedUser.homepage)
              if (homepage && window.location.pathname === '/') {
                Router.navigate(homepage.path)
              }
            }
          }
        }
      } catch (err) {
        if (err instanceof ConnectionError) {
          Router.navigate('/connection-error')
        } else {
          throw err
        }
      }
    }
  }

  getShowProductImageSettings() {
    let value = 'False'
    if (this.applicationSettings) {
      const setting = this.applicationSettings.find((element) => {
        return element.code === 'showProductImage'
      })
      if (setting) {
        value = setting.value
      }
    }
    return value
  }

  getShowProductImage() {
    const value = this.getShowProductImageSettings()
    return value.toLowerCase() === 'yes' || value === 'StylewherePrivateUrl' || value === 'PublicUrl'
  }

  async getEndpoint() {
    return Storage.load('endpoint', api.getBaseURL())
  }

  async changeLanguage(language: string) {
    this.language = language
    await Storage.save('language', language)
    changeLanguage_i18n(language)
  }

  async setEndpoint(endpoint) {
    await Storage.save('endpoint', endpoint)
    Router.reloadApp()
  }

  async saveAuthToken(token?: string) {
    this.authToken = token
    await Storage.save(`authToken`, token)
    return this.authToken
  }

  async logout() {
    Storage.remove(`authToken`)
    this.authToken = undefined
    this.loggedUser = undefined
    await keycloak.logout()
    if (this.reloadRouting) this.reloadRouting()
    else Router.reloadApp()
  }

  async loadAuthToken() {
    if (keycloak.token && keycloak.realm) {
      Auth.setHeaderAccessToken(keycloak.token)
      this.authToken = keycloak.token
    }

    return this.authToken
  }

  isAdmin = (): boolean => {
    if (this.loggedUser) {
      const userCapabilities = this.loggedUser.capabilities
      if (
        userCapabilities &&
        userCapabilities.length > 0 &&
        userCapabilities.includes(CAPABILITIES.SYSTEM_ADMINISTRATION_PERFORM)
      ) {
        return true
      }
    }
    return false
  }

  hasAnyCapabilities = (capabilities: string[]): boolean => {
    if (!this.loggedUser) {
      return false
    }

    if (!capabilities || capabilities!.length === 0) {
      return true
    }

    const userCapabilities = this.loggedUser.capabilities
    if (!userCapabilities || userCapabilities!.length === 0) {
      return false
    }

    if (userCapabilities.includes(CAPABILITIES.SYSTEM_ADMINISTRATION_PERFORM)) {
      return true
    }
    return capabilities.some((capability) => userCapabilities.includes(capability))
  }
}

export default new AppStore()
