import { Row } from 'antd'
import { EyeOutlined } from '@ant-design/icons'
import { DetailCell } from '../../api'
import { AdvancedTableRowColumn } from '../../components'
import { __ } from '../../shared/i18n'
import { AdvancedTableColumn, AdvancedTableContentKind } from '../../components/AdvancedTable'

export const PARCEL_ENTRIES_COLUMNS: AdvancedTableColumn[] = [
  {
    key: 'read',
    title: __(`fields.labels.read`),
    render(text, record) {
      return <AdvancedTableRowColumn options={{ background: '#333', color: '#FFF' }} value={record.totalDetected} tag />
    },
    sortable: false,
  },
  {
    key: 'expected',
    title: __(`fields.labels.expected`),
    render(text, record) {
      return record.totalExpected > 0 ? (
        <AdvancedTableRowColumn
          options={{ background: '#333', color: '#FFF' }}
          value={`${record.totalDetected} / ${record.totalExpected}`}
          tag
        />
      ) : (
        <>n/a</>
      )
    },
    sortable: false,
  },
  {
    key: 'unexpected',
    title: __(`fields.labels.unexpected`),
    render(text, record) {
      return record.totalExpected > 0 ? (
        <AdvancedTableRowColumn options={{ background: '#333', color: '#FFF' }} value={record.totalUnexpected} tag />
      ) : (
        <>n/a</>
      )
    },
    sortable: false,
  },
  {
    key: 'parcel.code',
    title: __(`fields.labels.parcelCode`),
    sortable: false,
    type: AdvancedTableContentKind.STRING,
  },
  {
    key: 'parcel.description',
    title: __(`fields.labels.parcelDescription`),
    sortable: false,
    type: AdvancedTableContentKind.STRING,
  },
  {
    key: 'data',
    title: __(`fields.labels.operationDate`),
    render(text, record) {
      return record.type === 'OUTBOUND' ? (
        <AdvancedTableRowColumn value={record.shippingDate} date format="DD/MM/YYYY HH:mm:ss" />
      ) : (
        <AdvancedTableRowColumn value={record.receivingDate} date format="DD/MM/YYYY HH:mm:ss" />
      )
    },
    sortable: false,
  },
  {
    key: 'operation.description',
    title: __(`fields.labels.operationDescription`),
    sortable: false,
    type: AdvancedTableContentKind.STRING,
  },
  {
    key: 'parcel.palletCode',
    title: __(`fields.labels.palletCode`),
    sortable: false,
    type: AdvancedTableContentKind.STRING,
  },
  {
    key: 'parcel.weight',
    title: __(`fields.labels.weight`),
    sortable: false,
    type: AdvancedTableContentKind.STRING,
  },
  {
    key: 'parcel.errorMessage',
    title: __(`fields.labels.errorMessage`),
    sortable: false,
    type: AdvancedTableContentKind.STRING,
  },
  {
    key: 'action',
    width: 'small',
    fixedType: 'right',
    sortable: false,
    unmanageable: true,
    className: 'stw-small',
    render: (text, record) => (
      <AdvancedTableRowColumn
        actions={[
          {
            content: <EyeOutlined />,
            type: 'default',
            path: `/reports/asn/${record.asn.id}/parcel/${record.parcel.id}`,
          },
        ]}
      />
    ),
  },
]

export const ITEMS_COLUMNS: AdvancedTableColumn[] = [
  {
    key: 'identifiers',
    dataIndex: 'identifiers',
    sortable: false,
    title: __('fields.labels.identifierCode'),
    render: (text, record) => {
      const elements = record.identifiers.map((identifier) => (
        <Row>
          {identifier.type}: {identifier.code}
        </Row>
      ))
      return <>{elements}</>
    },
  },
  {
    key: 'zone',
    sortable: false,
    render: (text, record) => {
      if (!record.zone) {
        return 'n/a'
      }

      return record.zone.description ? `${record.zone.code} - ${record.zone.description}` : record.zone.code
    },
  },
  {
    key: 'zone.place',
    sortable: false,
    title: __('fields.labels.place'),
    render: (text, record) => {
      if (!record.zone || !record.zone.place) {
        return 'n/a'
      }
      return record.zone.place.description
        ? `${record.zone.place.code} - ${record.zone.place.description}`
        : record.zone.place.code
    },
  },
  {
    key: 'product',
    sortable: false,
    render: (text, record) => {
      if (!record.product) {
        return 'n/a'
      }
      return record.product.description ? `${record.product.code} - ${record.product.description}` : record.product.code
    },
  },
  {
    key: 'creationDate',
    dataIndex: 'creationDate',
    sortable: false,
    title: __('fields.labels.creationDate'),
    type: AdvancedTableContentKind.DATE_TIME,
  },
  {
    key: 'lastModifyDate',
    dataIndex: 'lastModifyDate',
    sortable: false,
    title: __('fields.labels.lastModifyDate'),
    type: AdvancedTableContentKind.DATE_TIME,
  },
]

export const PRODUCTS_COLUMNS: AdvancedTableColumn[] = [
  {
    key: 'quantity',
    dataIndex: 'quantity',
    sortable: false,
    title: __('fields.labels.quantity'),
  },
  {
    key: 'product.code',
    dataIndex: 'productCode',
    sortable: false,
    title: __('fields.labels.code'),
  },
  {
    key: 'product.description',
    dataIndex: 'description',
    sortable: false,
    title: __('fields.labels.description'),
  },
  {
    key: 'product.style.value',
    dataIndex: 'styleValue',
    sortable: false,
    title: __('fields.labels.styleValue'),
  },
  {
    key: 'product.material.value',
    dataIndex: 'materialValue',
    sortable: false,
    title: __('fields.labels.materialValue'),
  },
  {
    key: 'product.size.value',
    dataIndex: 'sizeValue',
    sortable: false,
    title: __('fields.labels.sizeValue'),
  },
  {
    key: 'product.color.value',
    dataIndex: 'colorValue',
    sortable: false,
    title: __('fields.labels.colorValue'),
  },
  {
    key: 'product.season',
    dataIndex: 'season',
    sortable: false,
    title: __('fields.labels.season'),
  },
  {
    key: 'product.creationDate',
    dataIndex: 'creationDate',
    sortable: false,
    title: __('fields.labels.creationDate'),
    type: AdvancedTableContentKind.DATE_TIME,
  },
  {
    key: 'product.lastModifyDate',
    dataIndex: 'lastModifyDate',
    sortable: false,
    title: __('fields.labels.lastModifyDate'),
    type: AdvancedTableContentKind.DATE_TIME,
  },
]

export const PARCEL_CELLS: DetailCell[] = [
  { attribute: 'code', columns: 12 },
  { attribute: 'description', columns: 12 },
  { attribute: 'creationUser', type: 'user' },
  { attribute: 'creationDate', type: 'date' },
  { attribute: 'lastModifyUser', type: 'user' },
  { attribute: 'creationDate', type: 'date' },
  { attribute: 'zone', type: 'codeDesc' },
  { attribute: 'clientPlace', type: 'codeDesc' },
  { attribute: 'intransitZone', type: 'codeDesc' },
  { attribute: 'zone', type: 'codeDesc' },
  { attribute: 'operation', type: 'codeDesc' },
  { attribute: 'asn', type: 'codeDesc' },
  { attribute: 'checklist', type: 'codeDesc' },
  { attribute: 'parcelEntryQuantity' },
  { attribute: 'status' },
  { attribute: 'palletCode' },
  { attribute: 'weight' },
  { attribute: 'errorMessage' },
  { attribute: 'shippingDate', type: 'date' },
  { attribute: 'receivingDate', type: 'date' },
]

export const PALLET_DATA: DetailCell[] = [
  { title: __('fields.labels.code'), attribute: 'code', columns: 12 },
  { title: __('fields.labels.description'), attribute: 'description', columns: 12 },
  { title: __('fields.labels.attributesStato'), attribute: 'status', columns: 12 },
  { title: __('fields.labels.carrierCode'), attribute: 'carrierCode', columns: 12 },
  { title: __('fields.labels.creationDate'), attribute: 'creationDate', columns: 12, type: 'date' },
  { title: __('fields.labels.lastModifyDate'), attribute: 'lastModifyDate', columns: 12, type: 'date' },
]

export const PRODUCT_DETAILS: DetailCell[] = [
  { title: __('fields.labels.code'), attribute: 'code', columns: 12 },
  { title: __('fields.labels.description'), attribute: 'description', columns: 12 },
  { title: __('fields.labels.shortDescription'), attribute: 'shortDescription', columns: 12 },
  { title: __('fields.labels.color'), attribute: 'color', type: 'valueDesc', columns: 12 },
  { title: __('fields.labels.size'), attribute: 'size', type: 'valueDesc', columns: 12 },
  { title: __('fields.labels.material'), attribute: 'material', type: 'valueDesc', columns: 12 },
  { title: __('fields.labels.style'), attribute: 'style', type: 'valueDesc', columns: 12 },
  { title: __('fields.labels.season'), attribute: 'season', columns: 12 },
  { title: __('fields.labels.rfidEnabled'), attribute: 'rfidEnabled', type: 'boolean', columns: 12 },
  { title: __('fields.labels.drop'), attribute: 'drop', columns: 12 },
  { title: __('fields.labels.itemType'), attribute: 'itemType.code', columns: 12 },
  { title: __('fields.labels.creationDate'), attribute: 'creationDate', type: 'date', columns: 12 },
  { title: __('fields.labels.lastModifyDate'), attribute: 'lastModifyDate', type: 'date', columns: 12 },
]

export const PRODUCT_MODAL_FIELDS: DetailCell[] = [
  { title: __('fields.labels.code'), attribute: 'code', columns: 12 },
  { title: __('fields.labels.description'), attribute: 'description', columns: 12 },
  { title: __('fields.labels.color'), attribute: 'color.value', columns: 12 },
  { title: __('fields.labels.size'), attribute: 'size.value', columns: 12 },
  { title: __('fields.labels.material'), attribute: 'material.value', columns: 12 },
  { title: __('fields.labels.style'), attribute: 'style.value', columns: 12 },
  /*{ title: __('fields.labels.season'), attribute: 'season', columns: 12 },
  { title: __('fields.labels.rfidEnabled'), attribute: 'rfidEnabled', columns: 12 },
  { title: __('fields.labels.drop'), attribute: 'drop', columns: 12 },
  { title: __('fields.labels.creationDate'), attribute: 'creationDate', type: 'date', columns: 12 },
  { title: __('fields.labels.lastModifyDate'), attribute: 'lastModifyDate', type: 'date', columns: 12 },*/
]
