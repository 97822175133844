import { FormInstance } from 'antd'
import { RefObject } from 'react'
import { AttributeUtil } from './utility'

export class FormUtility {
  public static handleDescription(description: string, entity, formRef: RefObject<FormInstance>) {
    this.setCodeByDescription(description, entity, formRef)
  }

  private static setCodeByDescription(description: string, entity, formRef: RefObject<FormInstance>) {
    const code = description.toLowerCase().replaceAll(' ', '-')
    this.setFieldValue(entity, 'code', code, formRef)
  }

  public static setFieldValue(entity, field: string, value: any, formRef: RefObject<FormInstance>) {
    AttributeUtil.setAttribute(entity, field, value)
    this.setFormFieldValue(formRef, field, value)
  }

  private static setFormFieldValue(formRef: RefObject<FormInstance>, field: string, value: any | undefined) {
    if (formRef.current) {
      formRef.current.setFieldsValue({ [field]: value })
      // formRef.current.validateFields([field]).then()
    }
  }
}
