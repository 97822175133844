import { Component } from 'react'
import { Typography } from 'antd'
import { __ } from '../../shared/i18n'

const { Text } = Typography

export class AdvancedFormInlineOptionHeader extends Component {
  render() {
    return (
      <div className="stylewhere-editable-table-row header">
        <div className="stylewhere-editable-table-row-column">
          <Text strong>{__('misc.optionKey')}</Text>
        </div>
        <div className="stylewhere-editable-table-row-column">
          <Text strong>{__('misc.optionValue')}</Text>
        </div>
        <div className="stylewhere-editable-table-row-action" />
      </div>
    )
  }
}
