import { Component } from 'react'
import { Image, Row, Skeleton } from 'antd'
import AppStore from 'core/shared/AppStore'
import Images from '../api/Images'
import config from '../config'

interface Props {
  record: any
  mTop?: number
  size?: string
  skeleton?: boolean
}

interface State {
  imageURL: any
  imageExists: boolean
  loaded: boolean
}

export class DynamicImage extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      imageExists: false,
      imageURL: '',
      loaded: false,
    }
  }

  componentDidMount = () => {
    this._init()
  }

  _init = () => {
    this.getImageURL()
  }

  shouldComponentUpdate = (nextProps) => {
    const { loaded } = this.state
    const { skeleton } = this.props
    if (!loaded && nextProps.skeleton !== skeleton && nextProps.record) {
      this.getImageURL()
    }
    return true
  }

  getImageID = () => {
    const { record } = this.props
    let imageId = ''
    if (record) {
      if (record.attributes) {
        //pictureId mantenuto per i dati già salvati a database
        if (record.attributes.pictureId) {
          imageId = record.attributes.pictureId.value || ''
        } else if (record.attributes.imageId) {
          imageId = record.attributes.imageId.value || ''
        } else if (record.attributes.sketchId) {
          imageId = record.attributes.sketchId.value || ''
        }
      } else if (record._ImageId) {
        imageId = record._ImageId
      } else if (record._SkatchId) {
        imageId = record._SkatchId
      }
      if (imageId === '') {
        if (record.code) {
          imageId = `IMG-${record.code}`
        } else if (record.productCode) {
          imageId = `IMG-${record.productCode}`
        }
      }
    }
    return imageId
  }

  getImageURL = async () => {
    const { size, record } = this.props
    if (record) {
      let url = ''
      const settings = AppStore.getShowProductImageSettings()
      if (settings === 'StylewherePrivateUrl' || settings.toLowerCase() === 'yes') {
        const imageId = this.getImageID()
        if (imageId && imageId !== '') {
          const baseURL =
            !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? config.endpoint : config.endpoint
          url = `${baseURL}${Images.endpoint}?imageId=${imageId}&access_token=${AppStore.authToken}&size=${
            size || 'large'
          }`
        }
      } else {
        let publicUrl = ''
        if (record.attributes && record.attributes.imageId && record.attributes.imageId.value) {
          publicUrl = record.attributes.imageId.value
        } else if (record._ImageId) {
          publicUrl = record._ImageId.value || record._ImageId
        }
        if (publicUrl !== '') {
          try {
            const decode = JSON.parse(publicUrl)
            if (decode && decode.imageUrls && decode.imageUrls[size || 'large']) {
              url = decode.imageUrls[size || 'large']
            }
          } catch (e) {
            //console.log(e)
          }
        }
      }
      this.setState({ imageURL: url, imageExists: true, loaded: true })
    }
  }

  onError = (event) => {
    this.setState({ imageExists: false })
  }

  render() {
    const { imageURL, imageExists } = this.state
    const { mTop, skeleton } = this.props
    return (
      <Row style={{ marginTop: mTop || 0 }}>
        {skeleton ? (
          <Skeleton.Image className="stw-skeleton-base" style={{ width: '100%', height: 300, borderRadius: 10 }} />
        ) : (
          <Image
            className="detail-image"
            width="100%"
            title="product image"
            src={imageURL}
            placeholder
            preview={imageExists}
            onError={(event) => this.onError(event)}
          />
        )}
      </Row>
    )
  }
}
