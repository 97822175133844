import BaseResource, { PageResult } from './BaseResource'
import { StwUser } from './types'

export default class Users extends BaseResource {
  static endpoint = '/api/v1/users' ///profile

  static async searchFull(params = {}): Promise<PageResult<StwUser>> {
    const users = await Users.search<StwUser>(params)

    const fullUsers = await Promise.all(users.content.map((user) => user.id).map((id) => Users.get<StwUser>(id)))

    return {
      ...users,
      content: fullUsers,
    }
  }
}
