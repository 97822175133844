import { Component } from 'react'
import { Col, Modal, Row } from 'antd'
import { hideModal, ModalConfig, readModalConfig } from './modalPageUtils'
import { MovmentProductItemReads } from '../pages/Report/Parcel/MovmentProductItemReads'
import { InventoryEntryItemReads } from '../pages/Operations/Inventory/InventoryEntryItemReads'
import CapabilityGroupListModal from '../pages/Configuration/UsersRoles/RoleMappings/CapabilityGroupsModal'
import CapabilitiesModal from '../pages/Configuration/UsersRoles/Roles/CapabilitiesModal'
import SortingGroupReads from '../pages/Operations/Sorting/SortingGroupReads'
import PickingEntryReads from '../pages/Operations/Picking/PickingEntryReads'
import { ExtendRouteComponentProps } from '../types'

interface State {
  visible: boolean
  config?: ModalConfig
}

export class ModalPage extends Component<ExtendRouteComponentProps, State> {
  constructor(props) {
    super(props)
    this.state = { visible: false }
  }

  componentDidMount() {
    this.refresh()
  }

  componentDidUpdate(prevProps: Readonly<ExtendRouteComponentProps>) {
    if (this.props.location.search !== prevProps.location.search) {
      this.refresh()
    }
  }

  refresh() {
    const modalParam = readModalConfig(this.props.location.search)
    if (modalParam) {
      this.setState({
        visible: true,
        config: modalParam,
      })
    } else {
      this.setState({ visible: false })
    }
  }

  onCancel() {
    hideModal()
  }

  render() {
    const { visible, config } = this.state
    return (
      <Modal
        centered
        className="stw-modal-page"
        width="70%"
        open={visible}
        closable
        destroyOnClose
        onCancel={() => {
          this.onCancel()
        }}
        footer={null}
      >
        {renderModalContent(config)}
      </Modal>
    )
  }
}

function renderModalContent(config?: ModalConfig) {
  if (!config) {
    return <></>
  }
  switch (config.type) {
    case 'MOVEMENT_PRODUCT_ITEMS':
      return (
        <MovmentProductItemReads
          movementId={config.data.movId}
          movementType={config.data.movType}
          productId={config.data.prodId}
        />
      )
    case 'INVENTORY_ENTRY_ITEMS':
      return (
        <InventoryEntryItemReads location="" inventoryId={config.data.inventoryId} productId={config.data.productId} />
      )
    case 'CAPABILITY_GROUP_LIST':
      return <CapabilityGroupListModal capabilityGroups={config.data.capabilityGroups} />
    case 'CAPABILITY_LIST':
      return <CapabilitiesModal capabilities={config.data.capabilities} />
    case 'SORTING_GROUP_READS':
      return (
        <SortingGroupReads
          location=""
          productId={config.data.productId}
          sortingGroupEntryId={config.data.sortingGroupEntryId ? config.data.sortingGroupEntryId : config.data.sortingGroupId}
        />
      )
    case 'PICKING_ENTRY_READS':
      return <PickingEntryReads location="" pickingEntryId={config.data.pickingEntryId} />
    default:
      return (
        <Row>
          <Col span={24}>
            <pre style={{ textAlign: 'left' }}>data: {JSON.stringify(config, null, 1)}</pre>
          </Col>
        </Row>
      )
  }
}
