import BaseResource from './BaseResource'
import { StwScannerStatus } from './types'
import { api, responseErrorCheck } from './api'

export default class Scanners extends BaseResource {
  static endpoint = '/api/v1/scanners' ///edge

  static async getScannerStatus(id: string): Promise<StwScannerStatus> {
    return api.get<StwScannerStatus>(`${this.endpoint}/${id}/status`).then(responseErrorCheck)
  }

  static async startScanner(id: string) {
    return api.post(`${this.endpoint}/${id}/command/start`).then(responseErrorCheck)
  }

  static async stopScanner(id: string) {
    return api.post(`${this.endpoint}/${id}/command/stop`).then(responseErrorCheck)
  }
}
