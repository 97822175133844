import { __, T } from '../../../shared/i18n'
import { AdvancedFormField, AdvancedFormInputType } from '../../../components/AdvancedForm/advancedFormTypes'

export const OPTION_YES_NO_WARNING = [
  {
    id: 'yes',
    description: 'yes',
  },
  {
    id: 'no',
    description: 'no',
  },
  {
    id: 'withWarning',
    description: 'withWarning',
  },
]

export const OPTION_TRUE_FALSE = [
  {
    id: 'true',
    description: 'true',
  },
  {
    id: 'false',
    description: 'false',
  },
]

export const OPTION_SYNC_ASYNC = [
  {
    id: 'sync',
    description: 'sync',
  },
  {
    id: 'async',
    description: 'async',
  },
]

export const OPTION_EXPECTED_MODE = [
  {
    id: 'NONE',
    description: 'NONE',
  },
  {
    id: 'STW_STOCK',
    description: 'STW_STOCK',
  },
  {
    id: 'EXTERNAL_BY_ITEM',
    description: 'EXTERNAL_BY_ITEM',
  },
  {
    id: 'EXTERNAL_BY_PRODUCT',
    description: 'EXTERNAL_BY_PRODUCT',
  },
]

export const OPTION_READINGS_DISPLAY_MODE = [
  {
    id: 'groupedByProduct',
    description: 'groupedByProduct',
  },
  {
    id: 'item',
    description: 'item',
  },
]

export const OPTION_SHIPPING_MODE = [
  {
    id: 'parcelByParcel',
    description: 'parcelByParcel',
  },
  {
    id: 'multiParcel',
    description: 'multiParcel',
  },
  {
    id: 'shipment',
    description: 'shipment',
  },
]

export const OPTION_READING_MODE = [
  {
    id: 'rfid',
    description: 'rfid',
  },
  {
    id: 'mixed',
    description: 'mixed',
  },
  {
    id: 'barcode',
    description: 'barcode',
  },
]

export const OPTION_INVENTORY_POPULATE_UNKNOWN_ITEMS_ACTION = [
  {
    id: 'createItemIfProductExist',
    description: 'createItemIfProductExist',
  },
  {
    id: 'createItemAndProduct',
    description: 'createItemAndProduct',
  },
  {
    id: 'ignoreItem',
    description: 'ignoreItem',
  },
]

export const OPTION_ENCODING_INITIALTYPE = [
  {
    id: 'product',
    description: 'product',
  },
  {
    id: 'order',
    description: 'order',
  },
  {
    id: 'identifier',
    description: 'identifier',
  },
  {
    id: 'wam',
    description: 'wam',
  },
  {
    id: 'orderRow',
    description: 'orderRow',
  },
]

export const OPTION_ENCODING_PRODUCTIONORDERROW_SELECTION_MODE = [
  {
    id: 'byOrderAndRowCode',
    description: 'byOrderAndRowCode',
  },
  {
    id: 'byOrderAndSku',
    description: 'byOrderAndSku',
  },
  {
    id: 'byCode',
    description: 'byCode',
  },
]

export const OPTION_YES_NO = [
  {
    id: 'yes',
    description: 'yes',
  },
  {
    id: 'no',
    description: 'no',
  },
]

export const OPTION_IDENTIFIER_EXISTENCE = [
  {
    id: 'yes',
    description: 'yes',
  },
  {
    id: 'no',
    description: 'no',
  },
  {
    id: 'atLeastOne',
    description: 'atLeastOne',
  },
]

export const OPTION_TAG_SERIALIZATION = [
  {
    id: 'selfSerialized',
    description: 'selfSerialized',
  },
  {
    id: 'productSerial',
    description: 'productSerial',
  },
  {
    id: 'progressiveSerial',
    description: 'progressiveSerial',
  },
  {
    id: 'custom',
    description: 'custom',
  },
]

export const OPTION_UHF_WRITE = [
  {
    id: 'no',
    description: 'no',
  },
  {
    id: 'gs1',
    description: 'gs1',
  },
  {
    id: 'custom',
    description: 'custom',
  },
]

export const OPTION_FORCE_ASSOCIATION = [
  {
    id: 'yes',
    description: 'yes',
  },
  {
    id: 'no',
    description: 'no',
  },
  {
    id: 'withPin',
    description: 'withPin',
  },
  {
    id: 'afterRetry',
    description: 'afterRetry',
  },
  {
    id: 'withUserConfirmation',
    description: 'withUserConfirmation',
  },
]

export const OPTION_PRE_ENCODING_CHECK = [
  {
    id: 'no',
    description: 'no',
  },
  {
    id: 'byProductAttributes',
    description: 'byProductAttributes',
  },
  {
    id: 'custom',
    description: 'custom',
  },
]

export const OPTION_SESSION_UPDATE_POLICY = [
  {
    id: 'error',
    description: 'error',
  },
  {
    id: 'override',
    description: 'override',
  },
  {
    id: 'keepPreviousVersions',
    description: 'keepPreviousVersions',
  },
]

export const POST_CONFIRM_ACTION = [
  {
    id: 'disabled',
    description: 'disabled',
  },
  {
    id: 'keepInput',
    description: 'keepInput',
  },
  {
    id: 'changeIput',
    description: 'changeInput',
  },
]

export const PERSIST_PARCEL_CONTENT_SCANNINGS = [
  {
    id: 'onUserConfirm',
    description: 'onUserConfirm',
  },
  {
    id: 'onBatchRead',
    description: 'onBatchRead',
  }
]

export const READINGS_REMOVE_MODE = [
  {
    id: 'none',
    description: 'none',
  },
  {
    id: 'sku',
    description: 'sku',
  },
  {
    id: 'rfid',
    description: 'rfid',
  },
  {
    id: 'identifier',
    description: 'identifier',
  }
]

export const START_ASN_SCREEN = [
  {
    id: 'no',
    description: 'no',
  },
  {
    id: 'asnList',
    description: 'asnList',
  },
  {
    id: 'inputCode',
    description: 'inputCode',
  },
  {
    id: 'asnListAndInputCode',
    description: 'asnListAndInputCode',
  }
]

export const OPTIONS_DEFINITION: AdvancedFormField[] = [
  {
    key: 'options.customExtensionCode',
    description: "Permette di selezionare l'implementazione dell'extension point da utilizzare",
    type: AdvancedFormInputType.TEXT,
    defaultValue: 'default',
    placeholder: __(T.misc.insertValue),
    rules: [{ required: false }],
    col: 24,
  },
  {
    key: 'options.transitZoneCode',
    description: '',
    type: AdvancedFormInputType.AUTOCOMPLETE,
    defaultValue: '',
    placeholder: __('fields.placeholders.zone'),
    autocomplete: {
      endpoint: '/api/v1/zones',
      sort: 'code,asc',
      multiple: false,
      withIdValues: true,
      propertyId: 'code',
      selectionOption: {
        singleValue: true,
        filter: 'equalCodes',
      },
    },
    col: 24,
    option: 'transitZoneFilters',
    rules: [{ required: true }],
  },
  {
    key: 'options.canConfirmWithMissing',
    description: 'Definisce il comportamento in caso di quantità mancanti rispetto alla checklist',
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'withWarning',
    option: 'yes_no_warning',
    placeholder: __('fields.placeholders.selectOption'),
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.canConfirmWithOverQuantity',
    description: 'Definisce il comportamento in caso di quantità superiori rispetto alla checklist',
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'withWarning',
    option: 'yes_no_warning',
    placeholder: __('fields.placeholders.selectOption'),
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.canConfirmWithUnexpected',
    description: 'Definisce il comportamento in caso di letture inattese rispetto alla checklist',
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'withWarning',
    option: 'yes_no_warning',
    placeholder: __('fields.placeholders.selectOption'),
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.createChecklistOnConfirm',
    description:
      "Se abilitato al completamento dell'operazione di Outbound viene creata una checklist per il successivo inbound",
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'true',
    option: 'true_false',
    placeholder: __('fields.placeholders.selectOption'),
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.confirmMode',
    description: "Definisce se la conferma all'operazione arriverà in maniera asincrona dall’esterno",
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'sync',
    option: 'sync_async',
    placeholder: __('fields.placeholders.selectOption'),
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.hasChecklist',
    description: "Definisce se l'operation deve gestire una checklist",
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'false',
    option: 'true_false',
    placeholder: __('fields.placeholders.selectOption'),
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.retrieveExpectedMode',
    description: 'Permette di selezionare dove lo Stw deve recuperare l’atteso.',
    type: AdvancedFormInputType.SELECT,
    defaultValue: '',
    option: 'expected_mode',
    placeholder: __('fields.placeholders.selectOption'),
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.alignStockOnConfirm',
    description: "Permette di abilitare o meno l'allineamento dello stock dello Stw al termine dell’inventario.",
    type: AdvancedFormInputType.SELECT,
    defaultValue: '',
    option: 'true_false',
    placeholder: __('fields.placeholders.selectOption'),
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.autoSelectCheckZone',
    description:
      'Se true viene selezionata la zona di stock di default del place dell’inventario, se false deve essere indicata dall’utente tramite form schema. Nel secondo caso se non viene passata verrà lanciato un errore.',
    type: AdvancedFormInputType.SELECT,
    defaultValue: '',
    option: 'true_false',
    placeholder: __('fields.placeholders.selectOption'),
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.autoSelectLostZone',
    description:
      'Se true viene selezionata la zona di lost di default del place dell’inventario, se false deve essere indicata dall’utente tramite form schema. Nel secondo caso se non viene passata verrà lanciato un errore.',
    type: AdvancedFormInputType.SELECT,
    defaultValue: '',
    option: 'true_false',
    placeholder: __('fields.placeholders.selectOption'),
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.applyFilterOnPopulate',
    description:
      "In caso di modalità EXTERNAL_By_ITEM, se true, applica il filtro dell'inventario parziale anche all'atteso definito dal sistema esterno",
    type: AdvancedFormInputType.SELECT,
    defaultValue: '',
    option: 'true_false',
    placeholder: __('fields.placeholders.selectOption'),
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.checkRfidEnabled',
    description: 'In caso di lettura tramite barcode, controlla che il prodotto non abbia il flag rfidEnabled a true',
    type: AdvancedFormInputType.SELECT,
    defaultValue: '',
    option: 'true_false',
    placeholder: __('fields.placeholders.selectOption'),
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.enableTransferToDestinationZone',
    description: 'Se abilitato movimenta gli item sulla zona di destinazione',
    type: AdvancedFormInputType.SELECT,
    defaultValue: '',
    option: 'true_false',
    placeholder: __('fields.placeholders.selectOption'),
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.enableOriginZone',
    description: 'Se abilitato verifica la zona di origine degli item',
    type: AdvancedFormInputType.SELECT,
    defaultValue: '',
    option: 'true_false',
    placeholder: __('fields.placeholders.selectOption'),
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.hasMovement',
    description: "Definisce se l'operation deve gestire una checklist",
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'false',
    option: 'true_false',
    placeholder: __('fields.placeholders.selectOption'),
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.autostartAntenna',
    description:
      'Opzione utilizzata su "RFID Station" per accendere in automatico l\'antenna RFID quando si entra nella schermata di lettura.',
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'false',
    option: 'true_false',
    placeholder: __('fields.placeholders.selectOption'),
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.ignoreStates',
    description:
      'Stati sugli item da trattare come warning. Utilizzati nella decode per determinare se un item è da ignorare.',
    type: AdvancedFormInputType.SELECT,
    option: 'ignoreStates',
    select: { separator: ',' },
    selectWithCustomSelection: true,
    placeholder: __(T.misc.insertValue),
    rules: [{ required: false }],
    col: 24,
  },
  {
    key: 'options.ignoreWithReasonStates',
    description:
      'Stati sugli item da trattare come ignored with reason. Utilizzati nella decode per determinare se un item è da ignorare con motivazione.',
    type: AdvancedFormInputType.SELECT,
    option: 'ignoreWithReasonStates',
    select: { separator: ',' },
    selectWithCustomSelection: true,
    placeholder: __(T.misc.insertValue),
    rules: [{ required: false }],
    col: 24,
  },
  {
    key: 'options.warningStates',
    description:
      'Stati sugli item da trattare come errori. Utilizzati nella decode per determinare se un item è da segnalare come warning.',
    type: AdvancedFormInputType.SELECT,
    option: 'warningStates',
    select: { separator: ',' },
    selectWithCustomSelection: true,
    placeholder: __(T.misc.insertValue),
    rules: [{ required: false }],
    col: 24,
  },
  {
    key: 'options.errorStates',
    description:
      'Stati sugli item da trattare come ignore. Utilizzati nella decode per determinare se un item è da segnalare in errore.',
    type: AdvancedFormInputType.SELECT,
    option: 'errorStates',
    select: { separator: ',' },
    selectWithCustomSelection: true,
    placeholder: __(T.misc.insertValue),
    rules: [{ required: false }],
    col: 24,
  },
  {
    key: 'options.readingsDisplayMode',
    description:
      'Determina come sarà visualizzata la lista durante la lettura RFID. Se mostra la lista ragruppata per prodotto o semplicemente la lista degli item letti',
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'groupedByProduct',
    option: 'readings_display_mode',
    placeholder: __('fields.placeholders.selectOption'),
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.shippingMode',
    description: "Definisce la modalità con cui viene gestita l'operation",
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'parcelByParcel',
    option: 'shipping_mode',
    placeholder: __('fields.placeholders.selectOption'),
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.readingMode',
    description: 'La tipologia di letture attesa su mobile',
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'rfid',
    option: 'reading_mode',
    placeholder: __('fields.placeholders.selectOption'),
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.startWithRfidSearch',
    description: 'Definisce se recuperare il collo (checklist) a partire da una lettura RFID',
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'false',
    option: 'true_false',
    placeholder: __('fields.placeholders.selectOption'),
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.requireParcelCode',
    description:
      'Definisce se durante le operazioni il FE deve passare obbligatoriamente il codice del collo al BE. Se disabilitato il BE creerà il codice del collo in automatico.',
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'true',
    option: 'true_false',
    placeholder: __('fields.placeholders.selectOption'),
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.locationCode',
    description: 'Codice da utilizzare per la selezione dello shelf in cui spostare gli item raccolti.',
    type: AdvancedFormInputType.TEXT,
    defaultValue: '',
    placeholder: __(T.misc.insertValue),
    rules: [{ required: false }],
    col: 24,
  },
  {
    key: 'options.autoSelectSorting',
    description: "Definisce se abilitare l'auto selezione del sorting.",
    type: AdvancedFormInputType.SELECT,
    defaultValue: '',
    option: 'true_false',
    placeholder: __('fields.placeholders.selectOption'),
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.enablePicking',
    description: "Definisce se abilitare l'operazione di picking.",
    type: AdvancedFormInputType.SELECT,
    defaultValue: '',
    option: 'true_false',
    placeholder: __('fields.placeholders.selectOption'),
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.showCreateButton',
    description: 'Definisce se mostrare il pulsante di creazione nuovo elemento.',
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'true',
    option: 'true_false',
    placeholder: __('fields.placeholders.selectOption'),
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.showCloseButton',
    description: 'Definisce se mostrare il pulsante di chiusura.',
    type: AdvancedFormInputType.SELECT,
    defaultValue: '',
    option: 'true_false',
    placeholder: __('fields.placeholders.selectOption'),
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.showDeleteButton',
    description: 'Definisce se mostrare il pulsante di cancellazione.',
    type: AdvancedFormInputType.SELECT,
    defaultValue: '',
    option: 'true_false',
    placeholder: __('fields.placeholders.selectOption'),
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.inventoryPopulateUnknownItemsAction',
    description: "Definisce se l'operazione può creare item a sistema e con quali regole",
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'ignoreItem',
    option: 'inventory_populate_unknown_items_action',
    placeholder: __('fields.placeholders.selectOption'),
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.itemCreationConfigurationCode',
    description: 'Definisce il codice della itemConfiguration da utilizzare per la creazione di item',
    type: AdvancedFormInputType.TEXT,
    defaultValue: 'default',
    placeholder: __(T.misc.insertValue),
    rules: [{ required: false }],
    col: 24,
  },
  {
    key: 'options.initialType',
    description: "Definisce come riconoscere l'encoding da eseguire.",
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'product',
    option: 'encoding_initialtype',
    placeholder: __('fields.placeholders.initialtype'),
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.itemTypeConfig_',
    description:
      "Indica il codice della ItemConfiguration da usare, tra quelle valide, quando si fa l'encoding di un prodotto con itemType <XXX>",
    type: AdvancedFormInputType.AUTOCOMPLETE,
    placeholder: __('fields.placeholders.itemTypes'),
    autocomplete: {
      endpoint: '/api/v1/itemTypes',
      sort: 'code,asc',
      multiple: false,
    },
    col: 24,
    rules: [{ required: true }],
  },
  {
    key: 'options.productionOrderRowSelectionMode',
    description: "Indica in che modo deve essere iniziata l'operazione di encoding per commessa",
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'byOrderAndRowCode',
    option: 'productionOrderRowSelectionMode',
    placeholder: '',
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.skipItemEventPersistence',
    description: '',
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'true',
    option: 'true_false',
    placeholder: '',
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.autostopAntennaTimeout',
    description:
      'Definisce il tempo in millisecondi di attivazione dello stop automatico del lettore. Impostando il valore a 0 lo stop automatico non viene abilitato',
    type: AdvancedFormInputType.NUMBER,
    defaultValue: 0,
    placeholder: __(T.misc.insertValue),
    rules: [{ required: false }],
    col: 24,
  },
  {
    key: 'options.refreshCounterTimeout',
    description:
      'Definisce il tempo in millisecondi ogni quanto vengono aggiornati i contatori presenti per il dettaglio inventario nella Station. Impostando il valore a 0 lo stop automatico non viene abilitato',
    type: AdvancedFormInputType.NUMBER,
    defaultValue: 30000,
    placeholder: __(T.misc.insertValue),
    rules: [{ required: false }],
    col: 24,
  },
  {
    key: 'options.postConfirmAction',
    description:
      'Abilita/Disabilita il popup di conferma a fine operazione. Premendo “SI” con valore keepInput si rimane nella schermata di lettura mentre con changeIput si torna alla schermata di inserimento dati',
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'disabled',
    option: 'post_confirm_action',
    placeholder: '',
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.activeStorageForm',
    description: '',
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'false',
    option: 'true_false',
    placeholder: '',
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.checkIdentifierExistence',
    description: '',
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'no',
    option: 'identifierExistence',
    placeholder: '',
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.enableUhfWrite',
    description:
      "Abilita la scrittura dei tag UHF specificando il tipo di codifica. In caso di custom si invoca un metodo dell'EP",
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'no',
    option: 'uhfWrite',
    placeholder: '',
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.uhfTagToWriteRegex',
    description: 'In caso di multi tag indica il value al fine di identificare il tag da scrivere',
    type: AdvancedFormInputType.TEXT,
    defaultValue: '',
    placeholder: '',
    rules: [{ required: false }],
    col: 24,
  },
  {
    key: 'options.enableNfcWriteWithPrefix',
    description:
      "Abilita la scrittura NFC specificando l'url da scrivere prima dell'uid nel NDEF es. https://nfc.temera.it/8056385223BASD2",
    type: AdvancedFormInputType.TEXT,
    defaultValue: 'false',
    placeholder: '',
    rules: [{ required: false }],
    col: 24,
  },
  {
    key: 'options.checkNfcWithPrefix',
    description:
      "Abilita il controllo che il prefisso dell'ndef corrisponda a quello previsto. Il controllo è attivo solo se viene indicato il valore",
    type: AdvancedFormInputType.TEXT,
    defaultValue: '',
    placeholder: '',
    rules: [{ required: false }],
    col: 24,
  },
  {
    key: 'options.gs1EpcFilter',
    description: '',
    type: AdvancedFormInputType.TEXT,
    defaultValue: '',
    placeholder: '',
    rules: [{ required: false }],
    col: 24,
  },
  {
    key: 'options.gs1EpcPartition',
    description: '',
    type: AdvancedFormInputType.TEXT,
    defaultValue: '',
    placeholder: '',
    rules: [{ required: false }],
    col: 24,
  },
  {
    key: 'options.tagSerialization',
    description: 'Indica la tipologia di selezione del seriale',
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'selfSerialized',
    option: 'tagSerialization',
    placeholder: '',
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.checkWorkerOnProductionOrder',
    description: '',
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'yes',
    option: 'yes_no',
    placeholder: '',
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.checkIdentifierAndProductionOrderAssociation',
    description: '',
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'yes',
    option: 'yes_no',
    placeholder: '',
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.checkIdentifierAndProductionOrderAssociation',
    description: '',
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'no',
    option: 'yes_no',
    placeholder: '',
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.canEncodeWithOverQuantity',
    description: '',
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'no',
    option: 'yes_no',
    placeholder: '',
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.canForceAssociation',
    description: '',
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'no',
    option: 'forceAssociation',
    placeholder: '',
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.forcePin',
    description:
      'Se canForceAssociation è "withPin", questo campo contiene il pin da specificare per forzare l\'associazione.',
    type: AdvancedFormInputType.TEXT,
    defaultValue: '',
    placeholder: '',
    rules: [{ required: false }],
    col: 24,
  },
  {
    key: 'options.forceRetries',
    description:
      'Se canForceAssociation è "afterRetry", questo campo contiene il numero di retry dopo cui è possibile per forzare l\'associazione.',
    type: AdvancedFormInputType.NUMBER,
    defaultValue: 2,
    placeholder: '',
    rules: [{ required: false }],
    col: 24,
  },
  {
    key: 'options.writePass',
    description: '',
    type: AdvancedFormInputType.TEXT,
    defaultValue: '',
    placeholder: '',
    rules: [{ required: false }],
    col: 24,
  },
  {
    key: 'options.preEncodingCheck',
    description: '',
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'no',
    option: 'preEncodingCheck',
    placeholder: '',
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.canKillTag',
    description: 'Indica se deve essere possibile killare il tag uhf al fine di applicarne uno nuovo',
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'no',
    option: 'yes_no',
    placeholder: '',
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.uhfTagToKillRegEx',
    description: 'In caso di multi tag indica il value al fine di identificare il tag da scrivere',
    type: AdvancedFormInputType.TEXT,
    defaultValue: '',
    placeholder: '',
    rules: [{ required: false }],
    col: 24,
  },
  {
    key: 'options.enabledCheckItemZone',
    description:
      'Permette di attivare la verifica sulla zona degli item per le operazioni con readingsDisplayMode = item',
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'false',
    option: 'true_false',
    placeholder: '',
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.sessionUpdatePolicy',
    description:
      'Comportamento nel caso in cui venga inserito due volte una sessione con lo stesso code per una sessionList.',
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'error',
    option: 'session_update_policy',
    placeholder: __('fields.placeholders.selectOption'),
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.sessionDecodeReplacedIdentifiers',
    description: 'Valore da usare durante la Batch Validate per l’opzione di decodifica fetchReplacedIdentifier',
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'false',
    option: 'true_false',
    placeholder: __('fields.placeholders.selectOption'),
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.sessionListConfirmMode',
    description:
      'Se sync la conferma è istantanea, altrimenti si attende la conferma effettiva da parte di un meccanismo esterno',
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'sync',
    option: 'sync_async',
    placeholder: __('fields.placeholders.selectOption'),
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.sessionConfirmMode',
    description: 'Modalità di conferma delle sessioni, analoga a quella di conferma delle liste',
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'sync',
    option: 'sync_async',
    placeholder: __('fields.placeholders.selectOption'),
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.enableNfcWriteWithPrefix',
    description: '',
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'false',
    option: 'true_false',
    placeholder: __('fields.placeholders.selectOption'),
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.enableSoundFeedbackOk',
    description: '',
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'true',
    option: 'true_false',
    placeholder: __('fields.placeholders.selectOption'),
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.enableSoundFeedbackKo',
    description: '',
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'true',
    option: 'true_false',
    placeholder: __('fields.placeholders.selectOption'),
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.disableWorkstationCheck',
    description: '',
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'true',
    option: 'true_false',
    placeholder: __('fields.placeholders.selectOption'),
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.sessionRework',
    description: '',
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'no',
    option: 'yes_no',
    placeholder: __('fields.placeholders.selectOption'),
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.batchInterval',
    description: 'Attivazione batch durante la lettura dei tag',
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'false',
    option: 'true_false',
    placeholder: __('fields.placeholders.selectOption'),
    rules: [{ required: true }],
    col: 24,
  },
  {
    key: 'options.batchIntervalTime',
    description: 'Tempo di attesa in millisecondi per invio di ogni singolo batch di tag al server',
    type: AdvancedFormInputType.NUMBER,
    defaultValue: 1000,
    placeholder: __(T.misc.insertValue),
    rules: [{ required: false }],
    col: 24,
  },
  {
    key: 'options.batchIntervalTagCount',
    description: 'Numero massimo di tag contenuti in ogni singolo batch',
    type: AdvancedFormInputType.NUMBER,
    defaultValue: 200,
    placeholder: __(T.misc.insertValue),
    rules: [{ required: false }],
    col: 24,
  },
  {
    key: 'options.pickingOperationId',
    description: '',
    type: AdvancedFormInputType.AUTOCOMPLETE,
    placeholder: __('fields.placeholders.pickingOperation'),
    autocomplete: {
      endpoint: '/api/v1/operations?type=PICKING&enabled=TRUE',
      sort: 'code,asc',
      multiple: false,
      withIdValues: true,
      propertyId: 'id',
    },
    col: 24,
    rules: [{ required: false }],
  },
  {
    key: 'options.templatePath',
    description: 'Permette di specificare un template diverso rispetto a quello core',
    type: AdvancedFormInputType.TEXT,
    defaultValue: '',
    placeholder: __(T.misc.insertValue),
    rules: [{ required: false }],
    col: 24,
  },
  {
    key: 'options.itemDecoratorId',
    description: '',
    type: AdvancedFormInputType.TEXT,
    defaultValue: '',
    placeholder: __(T.misc.insertValue),
    rules: [{ required: false }],
    col: 24,
  },
  {
    key: 'options.persistParcelContentScannings',
    description:
      'Indica se aggiornare le letture ad ogni tag letto o solo alla fine con la conferma',
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'onUserConfirm',
    option: 'persist_parcel_content_scannings',
    placeholder: '',
    rules: [{ required: false }],
    col: 24,
  },
  {
    key: 'options.removeMode',
    description:
      'Da la possibilità di rimuovere solo parte delle letture effettuate',
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'none',
    option: 'readings_remove_mode',
    placeholder: '',
    rules: [{ required: false }],
    col: 24,
  },
  {
    key: 'options.canCreateParcels',
    description:
      'Da la possibilità di creare colli all\'interno di spedizioni',
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'none',
    option: 'true_false',
    placeholder: '',
    rules: [{ required: false }],
    col: 24,
  },
  {
    key: 'options.startAsnScreen',
    description:
      'Permette di scegliere la modalità di selezione/inserimento ASN',
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'none',
    option: 'start_asn_screen',
    placeholder: '',
    rules: [{ required: false }],
    col: 24,
  },
  {
    key: 'options.genericDiscrepancyMessage',
    description:
      'In caso di discrepanze mostra warning generico invece dei tipi di discrepanza',
    type: AdvancedFormInputType.SELECT,
    defaultValue: 'none',
    option: 'true_false',
    placeholder: '',
    rules: [{ required: false }],
    col: 24,
  }
]
