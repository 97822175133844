import {
  AdvancedTableColumn,
  AdvancedTableContentKind,
  AdvancedTableFilterKind,
  AdvancedTableFiltersDef,
} from '../../../components/AdvancedTable'
import {
  AdvancedFormField,
  AdvancedFormFieldBuilder,
  AdvancedFormInputType,
} from '../../../components/AdvancedForm/advancedFormTypes'
import { __ } from '../../../shared/i18n'
import { AdvancedTableRowColumn } from '../../../components'
import { DetailCell } from '../../../api'
import { CAPABILITIES, getActionsTableColumns } from '../../utility/capabilityUtility'

export const ITEM_TYPE_FILTERS: AdvancedTableFiltersDef = [
  {
    key: 'equalCodes',
    label: __('fields.labels.code'),
    col: 8,
    type: AdvancedTableFilterKind.STRING_LIST,
    column: 'code',
  },
  {
    key: 'description',
    label: 'Description',
    type: AdvancedTableFilterKind.STRING,
    column: 'description',
    col: 8,
  },
  {
    key: 'creationDate',
    label: __('fields.labels.creationDate'),
    type: AdvancedTableFilterKind.DATE_RANGE,
    column: 'creationDate',
    col: 8,
  },
  {
    key: 'lastModifyDate',
    label: __('fields.labels.lastModifyDate'),
    type: AdvancedTableFilterKind.DATE_RANGE,
    column: 'lastModifyDate',
    col: 8,
  },
]

export const ITEM_TYPE_FIELDS: AdvancedFormField[] = [
  AdvancedFormFieldBuilder.buildText('description'),
  {
    key: 'code',
    type: AdvancedFormInputType.UNIQUE_TEXT,
    label: __('fields.labels.code'),
    placeholder: __('fields.placeholders.code'),
    rules: [{ required: true }],
    onlyCreate: true,
    unique: {
      endpoint: '/api/v1/itemTypes',
      parameter: 'equalCodes',
    },
  },
]

export const ITEM_TYPE_COLUMNS: Array<AdvancedTableColumn> = [
  {
    title: __(`fields.labels.code`),
    dataIndex: 'code',
    key: 'code',
  },
  {
    title: __(`fields.labels.description`),
    key: 'description',
    dataIndex: 'description',
    className: 'stw-extra-medium',
  },
  {
    key: 'creationDate',
    title: __(`fields.labels.creationDate`),
    dataIndex: 'creationDate',
    type: AdvancedTableContentKind.DATE_TIME,
  },
  {
    key: 'lastModifyDate',
    title: __(`fields.labels.lastModifyDate`),
    dataIndex: 'lastModifyDate',
    type: AdvancedTableContentKind.DATE_TIME,
  },
  {
    key: 'action',
    className: 'stw-small',
    fixedType: 'right',
    unmanageable: true,
    hidden: false,
    sortable: false,
    render: (text, record) => (
      <AdvancedTableRowColumn
        actions={getActionsTableColumns(
          record.id,
          '/configuration/settings/itemTypes',
          CAPABILITIES.RESOURCE_ENUMERATION_TYPE_EDIT
        )}
      />
    ),
  },
]

export const ITEM_TYPES_DATA: DetailCell[] = [
  { title: 'id', attribute: 'id', columns: 24 },
  { title: 'code', attribute: 'code', columns: 12 },
  { title: 'description', attribute: 'description', columns: 12 },
  { title: 'Creation date', attribute: 'creationDate', type: 'date', columns: 12 },
  { title: 'Last modify date', attribute: 'lastModifyDate', type: 'date', columns: 12 },
]

export const ITEM_TYPE_DETAILS_COLUMN: Array<AdvancedTableColumn> = [
  {
    title: __(`fields.labels.identifierType`),
    dataIndex: 'identifierType',
    key: 'identifierType',
  },
  {
    title: __(`fields.labels.role`),
    key: 'role',
    dataIndex: 'role',
  },
]
