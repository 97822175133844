import { Component } from 'react'
import { List } from 'antd'
import { StwCapability } from '../../../../api'
import { Section } from '../../../../components'
import { __, T } from '../../../../shared/i18n'

interface Props {
  capabilities: StwCapability[]
}

class CapabilitiesModal extends Component<Props> {
  render() {
    const { capabilities } = this.props
    return (
      <Section parentPadding={15} title={`${__(T.titles.capabilities)}`}>
        <div
          id="scrollableDiv"
          style={{
            height: 400,
            overflow: 'auto',
            padding: '0px 16px 0px 0px',
          }}
        >
          <List
            split
            style={{ width: '100%', height: '1000' }}
            itemLayout="horizontal"
            dataSource={capabilities}
            renderItem={(capability, index) => (
              <List.Item key={index}>
                <List.Item.Meta title={capability.code} />
              </List.Item>
            )}
          />
        </div>
      </Section>
    )
  }
}

export default CapabilitiesModal
