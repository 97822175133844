import { Col, Form, FormInstance, Modal, notification, Space } from 'antd'
import { Component, createRef } from 'react'
import { StwOperationOption } from '../../api'
import { T, __ } from '../../shared/i18n'
import { AdvancedForm, ModalHeader, ModalAddButton } from '..'
import { AdvancedFormInputType, AdvancedFormField } from '../AdvancedForm/advancedFormTypes'
import { FormValidateMessages } from '../../constants'

interface Props {
  visible: boolean
  options: StwOperationOption[]
  save: (option) => void
  close: () => void
  optionsDefinition?: AdvancedFormField[]
}

interface State {
  newOption: {
    key: string
    value: string
  }
}

export class ModalAddOption extends Component<Props, State> {
  formRef = createRef<FormInstance>()

  constructor(props) {
    super(props)
    this.state = {
      newOption: {
        key: '',
        value: '',
      },
    }
  }

  shouldComponentUpdate = (nextProps) => {
    if (nextProps.visible && this.props.visible !== nextProps.visible) {
      const data = {
        key: '',
        value: '',
      }
      this.setState({
        newOption: data,
      })
      if (this.formRef && this.formRef.current) {
        this.formRef.current.setFieldsValue(data)
      }
    }
    return true
  }

  handleChange = (key, value) => {
    const { newOption } = this.state
    newOption[key] = value
    this.setState({ newOption })
  }

  sendNewOption = () => {
    const { options, save, optionsDefinition } = this.props
    const { newOption } = this.state
    if (
      optionsDefinition &&
      optionsDefinition.length > 0 &&
      optionsDefinition.find((option) => option.key === `options.${newOption.key}`)
    ) {
      this.openNotification(`${newOption.key} ${__(T.misc.systemOptionKey)}`)
    } else if (options && options.length > 0 && options.find((option) => option.optionKey === newOption.key)) {
      this.openNotification(`${newOption.key} ${__(T.misc.alreadyExist)}`)
    } else {
      save(newOption)
    }
  }

  openNotification = (text) => {
    notification.error({
      message: __(T.misc.validationError),
      description: text,
      placement: 'bottomRight',
      duration: 3,
    })
  }

  getFormField = (key, label) => {
    return [
      {
        key: key,
        label: __(`misc.option${label}`),
        placeholder: __(`misc.insert${label}`),
        type: AdvancedFormInputType.TEXT,
        rules: [{ required: true }],
        col: 24,
      },
    ]
  }

  render() {
    const { newOption } = this.state
    const { visible, close } = this.props
    return (
      <Modal
        centered
        title={<ModalHeader close={close} title={__(T.misc.addNewOption)} />}
        footer={null}
        closable={false}
        onCancel={() => close()}
        open={visible}
        width="30%"
        className="stw-workstation-rfid-antennas-modal"
      >
        <Col span={24}>
          <Form
            ref={this.formRef}
            layout="vertical"
            onFinish={async () => {
              this.sendNewOption()
            }}
            style={{ width: '100%', height: '100%' }}
            initialValues={newOption}
            validateMessages={FormValidateMessages}
          >
            <Space size="small" style={{ width: '100%' }} className="stylewhere-form-attributes" direction="vertical">
              <AdvancedForm
                record={newOption}
                handleChange={this.handleChange}
                fields={this.getFormField('key', 'Key')}
              />
              <AdvancedForm
                record={newOption}
                handleChange={this.handleChange}
                fields={this.getFormField('value', 'Value')}
              />
              <ModalAddButton mTop={5} pb={5} close={close} />
            </Space>
          </Form>
        </Col>
      </Modal>
    )
  }
}
