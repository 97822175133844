/* eslint-disable no-prototype-builtins */
import { AxiosRequestConfig } from 'axios'
import { notification } from 'antd'
import * as React from 'react'
import { api, responseErrorCheck } from '.'

export const MAX_PAGE_SIZE = 1000

export default class BaseResource {
  /**
   * resource endpoint eg. /products
   */
  static endpoint

  /**
   * Pagination config (global for all resources)
   */
  static defaultPaginationConfig = {
    offset: 0,
    limit: 50,
    orderBy: 'CREATION_DATE',
    asc: true,
  }

  /**
   * Post call
   *
   * @param {Object} path
   */
  static post<T>(path): Promise<T> {
    return api.post<T>(`${this.endpoint}/${path}`).then(responseErrorCheck)
  }

  /**
   * Get one Object based on the search params (eg. id: 435)
   *
   * @param {Object} param param string
   * @returns {Object}
   */
  static getURL<T>(param, requestConfig = undefined): Promise<T> {
    return api.get<T>(`${this.endpoint}?${param}`, requestConfig).then(responseErrorCheck)
  }

  /**
   * Get one Object based on the search params (eg. id: 435)
   *
   * @param {Object} id entity identifier
   * @returns {Object}
   */
  static get<T>(id, requestConfig = undefined): Promise<T> {
    return api.get<T>(`${this.endpoint}/${id}`, requestConfig).then(responseErrorCheck)
  }

  /**
   * Search resources
   *
   * @param {Object} params query params
   * @param requestConfig
   * @returns {Array}
   */
  static search<T>(params = {}, requestConfig?: AxiosRequestConfig): Promise<PageResult<T>> {
    return api.get<PageResult<T>>(`${this.endpoint}`, params, requestConfig).then(responseErrorCheck)
  }

  static searchTableList<T>(
    endpoint: string,
    params = {},
    requestConfig?: AxiosRequestConfig
  ): Promise<PageResult<any>> {
    return api.get<PageResult<T>>(`${endpoint}`, params, requestConfig).then(responseErrorCheck)
  }

  static deleteTableList(endpoint: string, id: string, requestConfig = undefined) {
    return api.delete(`${endpoint}/${id}`, requestConfig).then((res) => {
      if (res && (res.status === 200 || res.status === 204)) {
        notification.success({
          message: 'delete success',
          placement: 'bottomRight',
          duration: 3,
        })
      }

      return responseErrorCheck(res)
    })
  }

  /**
   * Configure pagination for all entities
   *
   * defaultConfig = {
   *   offset: 0,
   *   limit: 25,
   *   orderBy: 'CREATION_DATE',
   *   asc: true,
   * }
   *
   * @param {Object} cfg
   */
  static configureDefaultPagination(cfg, requestConfig = undefined) {
    if ((cfg.hasOwnProperty('offset'), requestConfig)) BaseResource.defaultPaginationConfig.offset = cfg.offset

    if (cfg.hasOwnProperty('limit')) BaseResource.defaultPaginationConfig.limit = cfg.limit

    if (cfg.hasOwnProperty('orderBy')) BaseResource.defaultPaginationConfig.orderBy = cfg.orderBy

    if (cfg.hasOwnProperty('asc')) BaseResource.defaultPaginationConfig.asc = cfg.asc
  }

  /**
   * Creates or Updates an entity
   *
   * @param {Object} data entity parameters
   * @returns {Object}
   */
  static update<T>(data, requestConfig = undefined): Promise<T> {
    return api.put(`${this.endpoint}`, data, requestConfig).then((res) => {
      if (res && (res.status === 200 || res.status === 204)) {
        notification.success({
          message: 'update success',
          placement: 'bottomRight',
          duration: 3,
        })
      }

      return responseErrorCheck(res)
    })
  }

  /* update by id */
  static updateById<T>(id, data, requestConfig = undefined): Promise<T> {
    return api.put(`${this.endpoint}/${id}`, data, requestConfig).then((res) => {
      if (res && (res.status === 200 || res.status === 204)) {
        notification.success({
          message: 'update success',
          placement: 'bottomRight',
          duration: 3,
        })
      }

      return responseErrorCheck(res)
    })
  }

  /**
   * Creates or Updates an entity
   *
   * @param {Object} data entity parameters
   * @returns {Object}
   */
  static insert<T>(data, requestConfig = undefined, message?: React.ReactNode): Promise<T> {
    return api.post(`${this.endpoint}`, data, requestConfig).then((res) => {
      if (res && (res.status === 200 || res.status === 204)) {
        notification.success({
          message: message ?? 'insert success',
          placement: 'bottomRight',
          duration: 3,
        })
      }

      return responseErrorCheck(res)
    })
  }

  /**
   * Delete a specific entity
   *
   * @param {String} id
   */
  static delete(id, requestConfig = undefined) {
    return api.delete(`${this.endpoint}/${id}`, requestConfig).then((res) => {
      if (res && (res.status === 200 || res.status === 204)) {
        notification.success({
          message: 'delete success',
          placement: 'bottomRight',
          duration: 3,
        })
      }

      return responseErrorCheck(res)
    })
  }

  static async searchByIds<T>(ids: (string | undefined)[]): Promise<{ [id: string]: T }[]> {
    ids = ids
      .filter((id) => id)
      //rimozione nulli
      .filter((v) => v)
      //rimozione doppioni
      .filter((v, index, arr) => arr.indexOf(v) === index)

    const map: { [id: string]: any }[] = []
    //se la lista è vuota restituisce un array vuoto
    if (ids.length === 0) {
      return []
    }
    const entities = await api.get<PageResult<T>>(`${this.endpoint}`, {
      ids: ids,
      size: ids.length,
    })
    if (entities && entities.data && entities.data.content) {
      entities.data!.content.forEach((e: any) => {
        //console.log(e)
        map[e.id!] = e
      })
    }
    return map
  }

  static async searchByCodes<T extends { [id: string]: any }>(...codes: any[]): Promise<{ [code: string]: T }[]> {
    codes = codes
      //rimozione nulli
      .filter((v) => v)
      //rimozione doppioni
      .filter((v, index, arr) => arr.indexOf(v) === index)

    const map: { [id: string]: any }[] = []

    //se la lista è vuota restituisce un array vuoto
    if (codes.length === 0) {
      return []
    }

    const entities = await api.get<PageResult<T>>(`${this.endpoint}`, {
      equalCodes: codes,
      size: codes.length,
    })
    if (entities && entities.data && entities.data.content) {
      entities.data!.content.forEach((e) => {
        map[e.code] = e
      })
    }
    return map
  }
}

export interface Pageable {
  pageNumber: number
  pageSize: number
  offset?: number
  paged?: boolean
  unpaged?: boolean
}

export interface PageResult<T> {
  map(arg0: (operation: any) => { label: any; path: string })

  content: Array<T>
  pageable: Pageable
  last?: boolean
  totalPages?: number
  totalElements: number
  first?: boolean
  // sort
  number?: number
  numberOfElements?: number
  size?: number
  empty?: boolean
}
