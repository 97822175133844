import { Component } from 'react'
import { DetailCell, StwRole } from '../../../../api'
import Roles from '../../../../api/Roles'
import { AdvancedTable, GridData, StylewherePage, DefaultHeader, NotFound, Section } from '../../../../components'
import { __, T } from '../../../../shared/i18n'
import { OPERATION_COLUMNS } from '../../../../config/Pages/Settings/Operations'
import { ExtendRouteComponentProps } from '../../../../types'
import { AdvancedTableContent, AdvancedTablePagination } from '../../../../components/AdvancedTable'
import { HomepagePath, HomepageUtility } from '../../../../config/utility/homepageUtility'
import { SkeltonAttribute } from '../../../../constants'
import {
  getDetailDataWithAttributesMapping,
  getMultipleTitle,
  getBackURL,
  getFragmentObject,
} from '../../../../shared/utils'

interface State {
  role: StwRole
  capabilitiesConfiguration: DetailCell[]
  loader: boolean
  homepagePaths: HomepagePath[]
  detailData: DetailCell[]
  tableContent: AdvancedTableContent<any>
  totalElements: number
  tablePagination: AdvancedTablePagination
}

class RoleDetail extends Component<ExtendRouteComponentProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      role: {},
      capabilitiesConfiguration: [],
      loader: true,
      homepagePaths: [],
      detailData: [],
      tableContent: [],
      totalElements: 0,
      tablePagination: {
        filterValues: undefined,
        pageSize: 100,
        sortValues: {},
        pageNumber: 1,
      },
    }
  }

  getDetailCells = (): DetailCell[] => {
    return [
      { title: 'id', attribute: 'id', columns: 24 },
      { title: __('fields.labels.code'), attribute: 'code', columns: 12 },
      { title: __('fields.labels.description'), attribute: 'description', columns: 12 },
      { title: __('fields.labels.priority'), attribute: 'priority', columns: 12 },
      {
        title: __('fields.labels.homepage'),
        attribute: 'homepage',
        columns: 12,
        type: 'custom',
        render: (record) => (this.state.loader ? 'n/a' : this.getHomepageDescription(record)),
      },
      {
        title: __('fields.labels.capabilityGroups'),
        attribute: 'capabilityGroups',
        columns: 12,
        type: 'custom',
        render: (record: StwRole) =>
          this.state.loader ? 'n/a' : this.getCodesLabelFromObjectsList(record.capabilityGroups ?? []),
      },
      {
        title: __('fields.labels.operations'),
        attribute: 'operations',
        columns: 12,
        type: 'custom',
        render: (record: StwRole) =>
          this.state.loader ? 'n/a' : this.getCodesLabelFromObjectsList(record.operations ?? []),
      },
      { title: __('fields.labels.creationDate'), attribute: 'creationDate', type: 'date', columns: 12 },
      { title: __('fields.labels.lastModifyDate'), attribute: 'lastModifyDate', type: 'date', columns: 12 },
    ]
  }

  componentDidMount() {
    this.initialize()
  }

  initialize = async () => {
    const { match } = this.props
    if (match) {
      const roleId = match.params.roleId
      if (roleId) {
        const role = await Roles.get<StwRole>(roleId).then((res) => res)
        const capabilitiesConfiguration = RoleDetail.getCapabilitiesConfiguration(role)
        const paths = await HomepageUtility.getPagePaths()
        const data: DetailCell[] = await getDetailDataWithAttributesMapping(this.getDetailCells(), 'role', 2)
        this.setState({
          role: role,
          detailData: data,
          tableContent: role.operations || [],
          totalElements: role.operations ? role.operations.length : 0,
          loader: false,
          homepagePaths: paths,
          capabilitiesConfiguration: capabilitiesConfiguration,
        })
      }
    }
  }

  private static getCapabilitiesConfiguration(role: StwRole) {
    const capabilitiesConfiguration: DetailCell[] = []
    if (role && role.capabilities) {
      role.capabilities.forEach((value, index) => {
        capabilitiesConfiguration.push({
          title: 'Capability '.concat(String(index)),
          attribute: value,
          columns: 8,
        })
      })
    }
    return capabilitiesConfiguration
  }

  getHomepageDescription = (homepage: string): string => {
    const { homepagePaths } = this.state
    const path = homepagePaths.find((value) => value.id === homepage)
    return path ? path.description : 'n/a'
  }

  getCodesLabelFromObjectsList = (list: any[]): string => {
    if (list.length === 0) {
      return 'n/a'
    }
    return list
      .map((value) => value.code!)
      .reduce((previousValue, currentValue) => previousValue.concat(' - ').concat(currentValue))
  }

  render() {
    const { role, loader, capabilitiesConfiguration, totalElements, detailData, tableContent, tablePagination } =
      this.state
    const { breadcrumbs, queryString } = this.props
    const viewCapabilities = loader || (role && role.capabilities && role.capabilities.length > 0)
    const fragment = getFragmentObject(role, 'code')
    return (
      <StylewherePage {...this.props} noOverflow fragment={fragment}>
        <DefaultHeader
          backPath={getBackURL(queryString, breadcrumbs)}
          multipleTitle={getMultipleTitle(role)}
          title={__(T.misc.not_found)}
          skeleton={{ active: loader, options: { multiple: true } }}
        />
        <Section customClass={`stw-section-page paged-header ${role || loader ? 'scroll transparent' : ''}`}>
          {role || loader ? (
            <>
              <GridData
                fields={detailData.length > 0 ? detailData : this.getDetailCells()}
                record={role}
                skeleton={loader}
              />
              <Section>
                <AdvancedTable
                  loader={loader}
                  tableTitle={{ title: __(T.misc.operations_title), emptyTitle: __(T.role.noOperationsFoundTitle) }}
                  pagination={tablePagination}
                  columns={OPERATION_COLUMNS}
                  filters={{
                    filterDefinitions: [],
                  }}
                  content={tableContent}
                  totalElements={totalElements}
                  disabledNoDataFound
                  disabledPagination
                  activeDefaultClassColumn
                />
              </Section>
              {viewCapabilities && (
                <GridData
                  title={__(T.misc.capabilities_title)}
                  fields={loader ? SkeltonAttribute : capabilitiesConfiguration}
                  record={loader ? [] : role.capabilities}
                  skeleton={loader}
                />
              )}
            </>
          ) : (
            <NotFound fullheight title={__(T.misc.noRecordFoundTitle)} />
          )}
        </Section>
      </StylewherePage>
    )
  }
}

export default RoleDetail
