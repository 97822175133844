import { Component } from 'react'
import { Modal } from 'antd'
import { __ } from '../../shared/i18n'
import { KeyValueGrid, ModalHeader } from '..'
import { StwEnumerationValues } from '../../api'
import { VALUES_DETAIL } from '../../config/Pages/Settings/EnumerationValues'
import { uniqueDataList } from '../../shared/utils'

interface Props {
  visible: boolean
  close: any
  value: StwEnumerationValues | undefined
}

export class ModalValueDetail extends Component<Props> {
  render() {
    const { visible, close, value } = this.props
    const fields = uniqueDataList(VALUES_DETAIL, value, 2)
    return (
      <Modal
        title={<ModalHeader close={close} title={__('misc.value')} />}
        centered
        open={visible}
        closable={false}
        footer={null}
        onCancel={() => close()}
        width="70%"
        className="stw-workstation-rfid-antennas-modal"
      >
        {value && <KeyValueGrid fields={fields} data={value} />}
      </Modal>
    )
  }
}
