import { Component } from 'react'
import { EditOutlined } from '@ant-design/icons'
import { Button, Col, InputNumber, Row, Space, notification } from 'antd'
import { StwProductQuantityUI } from '../../api'
import { AdvancedTable } from '../AdvancedTable'
import { checklistProductColumns } from '../../config/Pages/Checklists'
import SelectWithAutocompletion from './SelectWithAutocompletion'
import { __, T } from '../../shared/i18n'
import { AdvancedFormModalChecklistQuantity } from '../AdvancedForm/AdvancedFormModalChecklistQuantity'

interface Props {
  value?: StwProductQuantityUI[]
  onChange?: (value: any) => void
  placeholder: string
  endpoint: string
  sort: string
  filters?: any
  uniqueValueError?: string
}

interface State {
  selectWithAutocompletionValue: any
  inputNumberValue: any
  isChecklistProductQuantityEditModalVisible: boolean
  checklistProductQuantityEditModalData: StwProductQuantityUI
}

class ProductListWithAutocompletion extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      selectWithAutocompletionValue: undefined,
      inputNumberValue: 0,
      isChecklistProductQuantityEditModalVisible: false,
      checklistProductQuantityEditModalData: {
        product: {},
        quantity: 0,
      },
    }
  }

  handleAdd = () => {
    const { onChange } = this.props
    const { selectWithAutocompletionValue, inputNumberValue } = this.state
    if (selectWithAutocompletionValue) {
      if (onChange) {
        onChange([...this.getSelectedValues(), { product: selectWithAutocompletionValue, quantity: inputNumberValue }])
      }
      this.setState({ selectWithAutocompletionValue: undefined, inputNumberValue: 0 })
    } else {
      notification.error({
        message: __(T.misc.validationError),
        description: __(T.checklist.productRequired),
        placement: 'bottomRight',
        duration: 3,
      })
    }
  }

  handleEdit = (productQuantity: StwProductQuantityUI) => {
    this.setState({
      checklistProductQuantityEditModalData: productQuantity,
      isChecklistProductQuantityEditModalVisible: true,
    })
  }

  confirmEdit = (confirm: boolean, quantity: number) => {
    const { checklistProductQuantityEditModalData } = this.state
    if (confirm) {
      checklistProductQuantityEditModalData.quantity = quantity
    }
    this.setState({
      isChecklistProductQuantityEditModalVisible: false,
      checklistProductQuantityEditModalData: checklistProductQuantityEditModalData,
    })
  }

  handleRemove = (productQuantity: StwProductQuantityUI) => {
    if (this.props.onChange) {
      this.props.onChange(
        this.props.value?.filter(
          (currentProductQuantity) => currentProductQuantity.product.id !== productQuantity.product.id
        ) ?? []
      )
    }
  }

  getFilters = () => {
    const { excludedIds, ...filters } = this.props.filters ?? {}
    return {
      ...filters,
      excludedIds: Array.from(
        new Set([...(excludedIds ?? []), this.getSelectedValues().map((selectedValue) => selectedValue.id)])
      ),
    }
  }

  getSelectedValues = () => {
    const { value } = this.props
    return Array.isArray(value ?? []) ? value ?? [] : [value]
  }

  render() {
    const { selectWithAutocompletionValue, inputNumberValue } = this.state
    const { placeholder, endpoint, sort } = this.props
    const selectedValues = this.getSelectedValues()
    return (
      <Row gutter={24}>
        {selectedValues.length > 0 && (
          <Col span={24} style={{ marginBottom: '25px' }}>
            <AdvancedTable
              pagination={{
                pageNumber: 1,
                pageSize: selectedValues.length,
                sortValues: {},
              }}
              columns={checklistProductColumns(this.handleEdit, this.handleRemove)}
              filters={{ filterDefinitions: [] }}
              content={selectedValues}
              totalElements={selectedValues.length}
              disabledScroll
              disabledPagination
            />
          </Col>
        )}
        <Col span={24}>
          <Space className="stylewhere-form-attributes" style={{ display: 'flex', marginBottom: 8 }}>
            <div>
              <p style={{ padding: 0, marginBottom: 5 }}>
                <b>{__('fields.labels.product')}</b>
              </p>
              <SelectWithAutocompletion
                value={selectWithAutocompletionValue}
                onChange={(value: any) => this.setState({ selectWithAutocompletionValue: value })}
                placeholder={placeholder}
                endpoint={endpoint}
                sort={sort}
                filters={this.getFilters()}
              />
            </div>
            <div>
              <p style={{ padding: 0, marginBottom: 5 }}>
                <b>{__('fields.labels.quantity')}</b>
              </p>
              <InputNumber
                style={{ height: '40px', borderRadius: '5px', width: '100%' }}
                value={inputNumberValue}
                onChange={(value: any) => this.setState({ inputNumberValue: value })}
                min={0}
                placeholder={__('fields.placeholders.quantity')}
                inputMode="numeric"
              />
            </div>
            <div>
              <p style={{ padding: 0, marginBottom: 5 }}>
                <b>&nbsp;</b>
              </p>
              <Button className="stylewhere-button-secondary" onClick={() => this.handleAdd()} block>
                {__(T.misc.add_new)}
              </Button>
            </div>
          </Space>
        </Col>
        <AdvancedFormModalChecklistQuantity
          visible={this.state.isChecklistProductQuantityEditModalVisible}
          callback={this.confirmEdit}
          icon={<EditOutlined style={{ fontSize: 46 }} />}
          productQuantity={this.state.checklistProductQuantityEditModalData}
        />
      </Row>
    )
  }
}

export default ProductListWithAutocompletion
