import { Component } from 'react'
import { Row, Space } from 'antd'
import { AdvancedTableColumns } from '../../AdvancedTable'
import { AdvancedTableManagerColumns } from '../..'

import IconManageColumnsBlack from '../../../../icon/icon-manage-columns-black.png'
import IconManageColumns from '../../../../icon/icon-manage-columns.png'

interface Props {
  active?: boolean
  filterOpened?: boolean
  visibleManagerColumns: boolean
  managerColumnsPanel: () => void
  managementColumns?: (...args: any[]) => any
  columns?: AdvancedTableColumns
  baseColumns?: AdvancedTableColumns
  refreshTableColumns?: (...args: any[]) => any
  manageColumnsPrefix?: string
  maxHeightPanel?: number
  customTop?: string
}

export class TableHeaderManagementButton extends Component<Props> {
  render() {
    const {
      managementColumns,
      maxHeightPanel,
      manageColumnsPrefix,
      refreshTableColumns,
      columns,
      baseColumns,
      visibleManagerColumns,
      managerColumnsPanel,
      active,
      customTop,
    } = this.props
    return active ? (
      <Row className="stylewhere-columns-manager-btn">
        <Space
          style={{
            cursor: 'pointer',
            backgroundColor: visibleManagerColumns ? '#333' : '#FFF',
          }}
          onClick={() => managerColumnsPanel()}
          size="small"
        >
          <div
            style={{
              backgroundImage: `url(${visibleManagerColumns ? IconManageColumns : IconManageColumnsBlack})`,
            }}
          />
        </Space>
        {managementColumns && (
          <AdvancedTableManagerColumns
            columns={columns || []}
            baseColumns={baseColumns || []}
            customTop={customTop || '50px'}
            refreshTableColumns={refreshTableColumns}
            managementColumns={managementColumns}
            visibleManagerColumns={visibleManagerColumns || false}
            manageColumnsPrefix={manageColumnsPrefix}
            maxHeightPanel={maxHeightPanel}
          />
        )}
      </Row>
    ) : null
  }
}
