import { Component } from 'react'
import { Button, Col, Row, Typography } from 'antd'
import { SectionTitle } from './SectionTitle'
import { StwOperationOption } from '../../api'
import { AdvancedFormInlineOptionHeader, AdvancedFormInlineOptionSingle } from '..'
import { __ } from '../../shared/i18n'

interface Props {
  title?: string
  subtitle?: string
  options: StwOperationOption[]
  record: any
  handleChangeOptions: any
  handleRemoveOption: any
  showAddOption?: () => void
  parameters?: any
}

const { Text } = Typography

export class AdvancedFormInlineOptions extends Component<Props> {
  render() {
    const { title, subtitle, options, record, handleChangeOptions, handleRemoveOption, showAddOption, parameters } =
      this.props
    return (
      <Row style={{ marginTop: 0, marginBottom: 0 }}>
        <Col xxl={24} xl={24} xs={24} md={24} lg={24}>
          <SectionTitle title={title} subtitle={subtitle} marginBottom={20} />
        </Col>
        {options.length > 0 && <AdvancedFormInlineOptionHeader />}
        {options.length > 0 &&
          options.map((option, i) => {
            return (
              <AdvancedFormInlineOptionSingle
                key={`option-element-${i}`}
                option={option}
                handleChangeOptions={handleChangeOptions}
                handleRemoveOption={handleRemoveOption}
                record={record}
                parameters={parameters}
              />
            )
          })}
        {options && (
          <Row
            className="stylewhere-form-title"
            style={{ marginTop: options.length > 0 ? 15 : 0, width: '100%' }}
            justify="start"
          >
            <Button style={{ height: 40 }} className="stylewhere-button-secondary" onClick={showAddOption}>
              <Text style={{ color: '#FFF' }}>{__('misc.addNewOption')}</Text>
            </Button>
          </Row>
        )}
      </Row>
    )
  }
}
