import { Component, createRef } from 'react'
import { Row, Skeleton } from 'antd'
import { PageHeader as AntdPageHeader } from '@ant-design/pro-layout'
import {
  AdvancedTableColumns,
  AdvancedTableConfig,
  AdvancedTableFilterValues,
  AdvancedTablePagination,
} from '../AdvancedTable'
import { Filters, TableHeaderFilterButton, TableHeaderManagementButton, Tabs } from '..'
import { navigate } from '../../shared/router'
import { StylewhereTab } from './Tabs'

interface Props {
  title?: string
  actions?: ReadonlyArray<React.ReactNode>
  tabs?: StylewhereTab[]
  pageHeaderClassName?: string
  managementColumns?: (...args: any[]) => any
  visibleManagerColumns?: boolean
  columns?: AdvancedTableColumns
  refreshTableColumns?: (...args: any[]) => any
  filters?: AdvancedTableConfig
  onFiltersChanged?: (filterKey: string, filterValue: any) => void
  onFiltersApplied?: (filterValues: AdvancedTableFilterValues) => void
  pagination?: AdvancedTablePagination
  filterApplied?: number
  manageColumnsPrefix?: string
  filterPanelOpened?: (status) => void
  filterOpened?: boolean
  skeleton?: any
  headerType?: 'paged' | 'boxed' | 'unset'
  disabledMaxHeightPanel?: boolean
}

interface State {
  maxHeightPanel: number
  selectedTab?: string
}

export class TableListHeader extends Component<Props, State> {
  wrapperRef
  keyboardEvents: any[] = []
  timerKeyboardEvents

  constructor(props) {
    super(props)
    this.state = {
      maxHeightPanel: 500,
      selectedTab: '',
    }
    this.wrapperRef = createRef<HTMLDivElement>()
  }

  componentDidMount() {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  }

  handleResize = () => {
    const { innerHeight: height } = window
    const h = height - 138
    this.setState({ maxHeightPanel: h })
  }

  getExtras = () => {
    const {
      actions,
      filterApplied,
      filters,
      managementColumns,
      columns,
      visibleManagerColumns,
      refreshTableColumns,
      manageColumnsPrefix,
      skeleton,
      filterOpened,
    } = this.props
    const { maxHeightPanel } = this.state
    const tmp: any = []
    if (skeleton.active) {
      if (skeleton.options && skeleton.options.createBtn) {
        tmp.push(<Skeleton.Button key="1" className="stw-skeleton-button create" active size="default" shape="round" />)
      }
      const btnClass = (skeleton.options && skeleton.options.btnClass) || ''
      if (skeleton.options) {
        tmp.push(
          <Skeleton.Button key="2" className={`stw-skeleton-button ${btnClass}`} active size="default" shape="round" />
        )
        tmp.push(
          <Skeleton.Button key="3" className={`stw-skeleton-button ${btnClass}`} active size="default" shape="round" />
        )
      }
    } else {
      if (actions) {
        tmp.push(actions)
      }

      tmp.push(
        <TableHeaderFilterButton
          key="header_filter_buttons"
          active={filters && filters.filterDefinitions && filters.filterDefinitions.length > 0}
          managementFilters={this.managementFilters}
          filterOpened={filterOpened}
          filterApplied={filterApplied}
        />
      )
      tmp.push(
        <TableHeaderManagementButton
          key="header_management_buttons"
          active={managementColumns && columns && columns.length > 0}
          columns={columns}
          managerColumnsPanel={this.managerColumnsPanel}
          refreshTableColumns={refreshTableColumns}
          managementColumns={managementColumns}
          visibleManagerColumns={visibleManagerColumns || false}
          manageColumnsPrefix={manageColumnsPrefix}
          maxHeightPanel={maxHeightPanel}
        />
      )
    }
    return tmp
  }

  managerColumnsPanel = () => {
    const { filterPanelOpened, filterOpened } = this.props
    if (filterOpened && filterPanelOpened) {
      filterPanelOpened(!filterOpened)
    }
    this.callManagementColumns()
  }

  callManagementColumns = () => {
    const { managementColumns } = this.props
    managementColumns?.()
  }

  managementFilters = () => {
    const { visibleManagerColumns, managementColumns, filterPanelOpened, filterOpened } = this.props
    if (visibleManagerColumns && managementColumns) {
      managementColumns()
    }
    filterPanelOpened && filterPanelOpened(!filterOpened)
  }

  getPageTitle = () => {
    const { title, skeleton, tabs } = this.props
    if (skeleton.active) {
      return (
        <Skeleton
          className={`stw-skeleton-base ${(skeleton.options && skeleton.options.titleClass) || ''}`}
          title={false}
          round
          active
          paragraph={{ rows: 1, width: '100%' }}
        />
      )
    }
    if (tabs) {
      return <Tabs tabs={tabs || []} selected={this.state.selectedTab || ''} change={this.handleTabButtonClick} />
    }
    return title
  }

  handleTabButtonClick = (tab: { label: string; url: string }) => {
    this.setState({ selectedTab: tab.label })
    navigate(tab.url)
  }

  render() {
    const {
      pageHeaderClassName,
      filters,
      columns,
      filterPanelOpened,
      managementColumns,
      visibleManagerColumns,
      refreshTableColumns,
      onFiltersChanged,
      onFiltersApplied,
      pagination,
      filterApplied,
      manageColumnsPrefix,
      filterOpened,
      headerType,
      disabledMaxHeightPanel,
    } = this.props
    const { maxHeightPanel } = this.state
    return (
      <Row className={`stylewhere-header-row ${headerType || ''}`}>
        <AntdPageHeader
          className={pageHeaderClassName || ''}
          style={{ width: '100%' }}
          ghost={false}
          title={this.getPageTitle()}
          extra={this.getExtras()}
        />
        <Filters
          managementFilters={this.managementFilters}
          filters={filters ? filters.filterDefinitions : []}
          filterParameters={filters ? filters.filterParameters : {}}
          columns={columns}
          filterPanelOpened={filterPanelOpened}
          managementColumns={managementColumns}
          visibleManagerColumns={visibleManagerColumns}
          refreshTableColumns={refreshTableColumns}
          onFiltersChanged={onFiltersChanged}
          onFiltersApplied={onFiltersApplied}
          pagination={pagination}
          filterApplied={filterApplied}
          manageColumnsPrefix={manageColumnsPrefix}
          filterOpened={filterOpened}
          maxHeightPanel={maxHeightPanel}
          disabledMaxHeightPanel={disabledMaxHeightPanel}
        />
      </Row>
    )
  }
}
