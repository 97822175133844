import { Component } from 'react'
import { notification } from 'antd'
import { DetailCell, StwReportQueryResult } from '../../../api'
import { KeyValueGrid, StylewherePage, DefaultHeader, NotFound, Section, TableListReport } from '../../../components'
import { __, T } from '../../../shared/i18n'
import { SkeletonAttributeReport } from '../../../constants'
import { getFragmentObject, getMultipleTitle, getBackURL } from '../../../shared/utils'
import { ExtendRouteComponentProps } from '../../../types'
import Operations from '../../../api/Operations'
import SimpleItemSessionList from '../../../api/SimpleItemSessionList'
import Reports from '../../../api/Reports'
import { CAPABILITIES, hasAnyCapabilities } from '../../../config/utility/capabilityUtility'

interface State {
  reportInstance?: StwReportQueryResult
  configuration: DetailCell[]
  loader: boolean
}

class SessionItemSessionListOperationDetail extends Component<ExtendRouteComponentProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      configuration: [],
      loader: true,
    }
  }

  async componentDidMount(): Promise<void> {
    await this.initialize()
  }

  async initialize() {
    const { match } = this.props
    if (match && match.params && match.params.operationInstanceId) {
      const params = { operationId: match.params.operationId }
      const reportId = await Operations.getReportId(match.params.operationId)
      const instance: any = await Reports.reportInstance(reportId, match.params.operationInstanceId, params)
      if (instance && instance.id) {
        const configuration = await Reports.getConfiguration(reportId, params)
        this.setState({
          reportInstance: instance,
          configuration: configuration,
          loader: false,
        })
      } else {
        this.setState({
          loader: false,
        })
      }
    } else {
      this.setState({
        loader: false,
      })
    }
  }

  getDetailPath = () => {
    const { match } = this.props
    return `/operationlogs/SIMPLE_ITEM_SESSION_LIST/${match.params.operationId}/${match.params.operationInstanceId}/simpleItemSession`
  }

  exportPdf = async () => {
    const { reportInstance } = this.state
    if (reportInstance) {
      try {
        const rest = await SimpleItemSessionList.executeAction(
          reportInstance.id,
          this.props.match.params.operationId,
          'printMission'
        )
        if (rest) {
          if (rest instanceof Blob) {
            const file = await new Blob([rest], { type: 'application/pdf' })
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file)

            const link = document.createElement('a')
            link.href = fileURL
            link.download = `${reportInstance.code}.pdf`
            link.click()
          } else {
            notification.error({
              message: __(T.misc.export_pdf),
              description: __(T.errors.pdf),
              duration: 5, // to change for live
              placement: 'bottomRight',
            })
          }
        }
      } catch (error) {
        //console.log('ERROR')
        notification.error({
          message: __(T.misc.export_pdf),
          description: __(T.errors.pdf),
          duration: 5, // to change for live
          placement: 'bottomRight',
        })
      }
    }
  }

  getExportButton = () => {
    const headerActions: any[] = [
      {
        type: 'cancel',
        buttonClass: 'stylewhere-button-primary',
        onClick: () => this.exportPdf(),
        loading: false,
        label: __(T.misc.export_pdf),
      },
    ]
    return headerActions
  }

  render(): JSX.Element {
    const { breadcrumbs, queryString } = this.props
    const { configuration, reportInstance, loader } = this.state
    const fragment = getFragmentObject(reportInstance, 'id')
    return (
      <StylewherePage {...this.props} noOverflow fragment={fragment}>
        <DefaultHeader
          backPath={getBackURL(queryString, breadcrumbs)}
          multipleTitle={getMultipleTitle(reportInstance)}
          title={__(T.misc.not_found)}
          skeleton={{ active: loader, options: { multiple: true } }}
          actions={reportInstance ? this.getExportButton() : []}
        />
        <Section customClass={`stw-section-page paged-header ${reportInstance || loader ? 'scroll transparent' : ''}`}>
          {reportInstance || loader ? (
            <Section>
              <KeyValueGrid
                fields={loader ? SkeletonAttributeReport : configuration}
                data={reportInstance}
                skeleton={loader}
              />
              {reportInstance && (
                <TableListReport
                  {...this.props}
                  reportId="simpleItemSession"
                  actions={[]}
                  fixedTableHeight
                  disabledReportExport
                  headerType="boxed"
                  title={__(T.simpleItemSessionList.sessions)}
                  titleEmpty={__(T.simpleItemSessionList.noSessionsFound)}
                  customColumnPrefix="sessionListDetail"
                  reportActions={{
                    active: hasAnyCapabilities(CAPABILITIES.RESOURCE_SIMPLE_ITEM_SESSION_LIST_VIEW),
                    onlyedit: false,
                  }}
                  reportParams={{ listId: reportInstance.id }}
                  disableLocation
                  disabledNoDataFound
                  hiddenEmptyTable
                  pathDetail={this.getDetailPath()}
                />
              )}
            </Section>
          ) : (
            <NotFound fullheight title={__(T.misc.noRecordFoundTitle)} />
          )}
        </Section>
      </StylewherePage>
    )
  }
}

export default SessionItemSessionListOperationDetail
