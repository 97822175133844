import { Component, createRef, RefObject } from 'react'
import { Form, FormInstance } from 'antd'
import { ExtendRouteComponentProps } from '../../../types'
import { __, T } from '../../../shared/i18n'
import { AttributeUtil } from '../../../config/utility/utility'
import { navigate } from '../../../shared/router'
import { FormValidateMessages, printerDeviceControllerTypeFilters, PrinterTypes } from '../../../constants'
import { AdvancedForm, StylewherePage, DefaultHeader, Section } from '../../../components'
import { PRINTERS_FIELDS } from '../../../config/Pages/Devices/Printers'
import Printers from '../../../api/Printers'
import { StwPrinter } from '../../../api'
import { DeviceUtility } from '../../../config/utility/deviceUtility'
import { FormUtility } from '../../../config/utility/formUtility'
import { getFragmentObject, getBackURL } from '../../../shared/utils'

interface State {
  printer: StwPrinter
  zoneFilters: { [filterKey: string]: any }
  loader: boolean
}

class PrinterForm extends Component<ExtendRouteComponentProps, State> {
  formRef = createRef<FormInstance>()

  constructor(props) {
    super(props)
    this.state = {
      printer: {},
      zoneFilters: { placeId: '', type: 'STOCK' },
      loader: true,
    }
  }

  componentDidMount = async () => {
    let printer: StwPrinter = this.state.printer
    if (this.props.match) {
      const printerId = this.props.match.params.printerId
      if (printerId !== '' && printerId !== 'create') {
        printer = await Printers.get<StwPrinter>(printerId)
      }
    }

    const { zoneFilters } = this.state
    zoneFilters.placeId = printer.place?.id ?? undefined

    this.setState(
      {
        printer: printer,
        loader: false,
        zoneFilters: zoneFilters,
      },
      this.updateFormFieldsValue
    )
  }

  updateFormFieldsValue = () => {
    if (this.formRef && this.formRef.current) {
      this.formRef.current.setFieldsValue(this.state.printer)
    }
  }

  private static updateCodeAndDescription(printer: StwPrinter, formRef: RefObject<FormInstance>) {
    Printers.search<StwPrinter>({ page: 0, size: 1, placeId: printer.place!.id })
      .then((printers) => {
        return {
          code: `${printer.place!.code}_P${printers.totalElements + 1}`,
          description: printer.place!.description && `${printer.place!.description}_P${printers.totalElements + 1}`,
        }
      })
      .then(({ code, description }) => {
        DeviceUtility.setFieldValue(printer, 'code', code, formRef)
        DeviceUtility.setFieldValue(printer, 'description', description, formRef)
      })
  }

  handleChange = (key, value) => {
    const { printer, zoneFilters } = this.state
    AttributeUtil.setAttribute(printer, key, value)
    if (key === 'place') {
      this.handlePlaceChange(value, printer, zoneFilters)
    } else if (key === 'attributes') {
      if (this.formRef && this.formRef.current) {
        this.formRef.current.setFieldsValue({ attributes: printer.attributes })
      }
    } else if (key === 'deviceType') {
      this.handleDeviceType(value, printer)
    }
    this.setState({ printer })
  }

  private handlePlaceChange(value, printer: StwPrinter, zoneFilters: { [p: string]: any }) {
    if (value) {
      PrinterForm.updateCodeAndDescription(printer, this.formRef)
      zoneFilters.placeId = printer.place!.id
    } else {
      DeviceUtility.resetCodeAndDescription(printer, this.formRef)
      zoneFilters.placeId = undefined
      FormUtility.setFieldValue(printer, 'zone', undefined, this.formRef)
    }
    this.setState({ zoneFilters })
  }

  private handleDeviceType(value: string, printer: StwPrinter) {
    if (!printer.settings) {
      printer.settings = {}
    }
    switch (value) {
      case 'zebra-card-printer':
      case 'epson':
      case 'avery':
        printer.settings!.port = '9100'
        break
      case 'zebra':
        printer.settings!.port = '6101'
        break
      default:
        printer.settings!.port = ''
        break
    }
    if (this.formRef && this.formRef.current) {
      this.formRef.current.setFieldsValue({ settings: printer.settings })
    }
  }

  async store() {
    const { printer } = this.state
    if (printer.zone && !printer.zone.id) {
      printer.zone = undefined
    }

    if (printer.id) {
      Printers.update<StwPrinter>(printer).then((value) => {
        this.redirect(value)
      })
    } else {
      Printers.insert<StwPrinter>(printer).then((value) => {
        this.redirect(value)
      })
    }
  }

  private redirect(printer: StwPrinter) {
    if (printer && printer.id) {
      navigate(`/devices/printers${this.props.queryString ?? ''}`)
    }
  }

  render() {
    const { printer, zoneFilters, loader } = this.state
    const { breadcrumbs, queryString } = this.props
    const title = printer.id ? __(T.printers.edit) : __(T.printers.create_new)
    const fragment = !loader ? getFragmentObject(printer, 'id', __(T.printers.create_new)) : undefined
    return (
      <Form
        ref={this.formRef}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        onFinish={async () => {
          await this.store()
        }}
        style={{ width: '100%', height: '100%' }}
        initialValues={printer}
        validateMessages={FormValidateMessages}
        scrollToFirstError
      >
        <StylewherePage {...this.props} noOverflow fragment={fragment}>
          <DefaultHeader
            backPath={getBackURL(queryString, breadcrumbs)}
            title={loader ? '...' : title}
            skeleton={{ active: loader }}
            actions={[
              {
                label: __(T.misc.cancel),
                type: 'cancel',
                onClick: () => navigate(`/devices/printers${queryString ?? ''}`),
              },
              {
                label: printer.id ? __(T.misc.update) : __(T.misc.create),
                type: 'submit',
              },
            ]}
          />
          <Section customClass="stw-section-page paged-header scroll">
            <AdvancedForm
              record={printer}
              fields={PRINTERS_FIELDS}
              store={this.store}
              editing={printer.id !== undefined}
              handleChange={this.handleChange}
              parameters={{
                deviceControllerTypeFilters: printerDeviceControllerTypeFilters,
                zoneFilters: zoneFilters,
                printerTypes: PrinterTypes,
              }}
            />
          </Section>
        </StylewherePage>
      </Form>
    )
  }
}

export default PrinterForm
