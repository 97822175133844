import { Component } from 'react'
import { Typography } from 'antd'

const { Text } = Typography

interface Props {
  configuration: any
  entity?: string
  erasable?: boolean
}

export class AdvancedFormAttributesHeader extends Component<Props> {
  render() {
    const { configuration, entity, erasable } = this.props
    const canDelete = (!entity || entity === '') && erasable
    return (
      <div className="stylewhere-editable-table-row header">
        {configuration.columns &&
          configuration.columns.map((column, i) => {
            return (
              <div
                key={`header-column-${i}`}
                className={`stylewhere-editable-table-row-column${
                  !canDelete && i === configuration.columns.length - 1 ? ' nomr' : ''
                }`}
              >
                <Text strong>{column.label}</Text>
              </div>
            )
          })}
        {canDelete && <div className="stylewhere-editable-table-row-action" />}
      </div>
    )
  }
}
