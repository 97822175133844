import { Component } from 'react'
import { GridData, StylewherePage, DefaultHeader, NotFound, Section } from '../../../../components'
import { __, T } from '../../../../shared/i18n'
import { ExtendRouteComponentProps } from '../../../../types'
import { DetailCell, StwCapabilitiesGroup, StwCapability } from '../../../../api'
import CapabilitiesGroup from '../../../../api/CapabilitiesGroup'
import { CAPABILITY_GROUPS_DATA } from '../../../../config/Pages/UsersRoles/CapabilitiesGroup'
import { SkeltonAttribute } from '../../../../constants'
import { getMultipleTitle, getBackURL, getFragmentObject } from '../../../../shared/utils'

interface State {
  capabilitiesGroup: StwCapabilitiesGroup
  capabilities?: any
  capabilitiesConfiguration: DetailCell[]
  loader: boolean
}

class CapabilitiesGroupDetail extends Component<ExtendRouteComponentProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      capabilitiesGroup: {},
      loader: true,
      capabilitiesConfiguration: [],
    }
  }

  componentDidMount() {
    this.initialize()
  }

  initialize = async () => {
    let capabilitiesGroup
    let capabilitiesConfiguration: DetailCell[] = []
    let capabilities = {}

    if (this.props.match) {
      const capabilitiesGroupId = this.props.match.params.capabilitiesGroupId
      if (capabilitiesGroupId) {
        capabilitiesGroup = await CapabilitiesGroup.get<StwCapabilitiesGroup>(capabilitiesGroupId).then((res) => res)
        if (capabilitiesGroup && capabilitiesGroup.capabilities) {
          capabilitiesConfiguration = CapabilitiesGroupDetail.getCapabilitiesConfiguration(
            capabilitiesGroup.capabilities
          )
          capabilities = CapabilitiesGroupDetail.getCapabilities(capabilitiesGroup.capabilities)
        }
      }
    }
    this.setState({
      capabilitiesGroup: capabilitiesGroup,
      loader: false,
      capabilitiesConfiguration: capabilitiesConfiguration,
      capabilities: capabilities,
    })
  }

  private static getCapabilitiesConfiguration(capabilities: StwCapability[]): DetailCell[] {
    const capabilitiesConfiguration: DetailCell[] = []

    capabilities.forEach((value, index) => {
      capabilitiesConfiguration.push({
        title: this.getCapabilityTitleKey(index),
        attribute: this.getCapabilityTitleKey(index),
        columns: 8,
      })
    })

    return capabilitiesConfiguration
  }

  private static getCapabilities(capabilities: StwCapability[]) {
    const capabilitiesConfiguration = {}
    capabilities.forEach((value, index) => {
      capabilitiesConfiguration[this.getCapabilityTitleKey(index)] = value.code!
    })
    return capabilitiesConfiguration
  }

  private static getCapabilityTitleKey(index: number) {
    return 'Capability '.concat(String(index))
  }

  render() {
    const { capabilitiesGroup, loader, capabilitiesConfiguration, capabilities } = this.state
    const { breadcrumbs, queryString } = this.props
    const viewCapabilities =
      loader || (capabilitiesGroup && capabilitiesGroup.capabilities && capabilitiesGroup.capabilities.length > 0)
    const fragment = getFragmentObject(capabilitiesGroup, 'capabilitiesGroupname')
    return (
      <StylewherePage {...this.props} noOverflow fragment={fragment}>
        <DefaultHeader
          backPath={getBackURL(queryString, breadcrumbs)}
          multipleTitle={getMultipleTitle(capabilitiesGroup, ['id', 'username'])}
          title={__(T.misc.not_found)}
          skeleton={{ active: loader, options: { multiple: true } }}
        />
        <Section
          customClass={`stw-section-page paged-header ${capabilitiesGroup || loader ? 'scroll transparent' : ''}`}
        >
          {capabilitiesGroup || loader ? (
            <>
              <GridData fields={CAPABILITY_GROUPS_DATA} record={capabilitiesGroup || {}} skeleton={loader} />
              {viewCapabilities && (
                <GridData
                  title={__(T.misc.capabilities_title)}
                  fields={loader ? SkeltonAttribute : capabilitiesConfiguration}
                  record={loader ? [] : capabilities}
                  skeleton={loader}
                />
              )}
            </>
          ) : (
            <NotFound fullheight title={__(T.misc.noRecordFoundTitle)} />
          )}
        </Section>
      </StylewherePage>
    )
  }
}

export default CapabilitiesGroupDetail
