import { Component } from 'react'
import { Button, Col, Input, Modal, Row, Typography } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import { StwBalance } from '../../api'
import Balances from '../../api/Balances'
import { __ } from '../../shared/i18n'
import WorkstationBalanceSelectionModalCardList from './WorkstationBalanceSelectionModalCardList'
import { Loader } from '..'
import { CAPABILITIES, hasAnyCapabilities } from '../../config/utility/capabilityUtility'
import { MAX_SIZE_LIMIT } from '../../constants'

type StwBalanceWithSelection = {
  balance: StwBalance
  selected: boolean
}

interface Props {
  placeId?: string
  balanceId?: string
  visible: boolean
  onCancel: () => void
  onConfirm: (selected: string) => void
  handleNewScale: () => void
  refreshCounter?: number
  workstationId: string
}

interface State {
  balancesWithSelection: StwBalanceWithSelection[]
  loader: boolean
  currentPlaceId: string
  refresh: number
}

const { Title } = Typography

export class WorkstationBalanceSelectionModal extends Component<Props, State> {
  loading = false

  constructor(props) {
    super(props)
    this.state = {
      balancesWithSelection: [],
      loader: true,
      currentPlaceId: '',
      refresh: 0,
    }
  }

  componentDidMount() {
    const { placeId } = this.props
    if (placeId && placeId !== '') {
      this.loading = true
      this.attachLoaderBalance(placeId, 0)
    }
  }

  attachLoaderBalance = (placeId, counter) => {
    this.setState(
      {
        loader: true,
        balancesWithSelection: [],
        currentPlaceId: placeId,
        refresh: counter,
      },
      this.loadBalance
    )
  }

  loadBalance = async () => {
    const { currentPlaceId } = this.state
    const balances = await this.getBalancesWithSelection(currentPlaceId)
    this.setState({ balancesWithSelection: balances, loader: false })
    this.loading = false
  }

  shouldComponentUpdate = (nextProps) => {
    const { balanceId, visible } = this.props
    const { currentPlaceId, refresh } = this.state
    const tmp = this.state.balancesWithSelection
    if (nextProps.placeId !== '') {
      if (nextProps.visible && nextProps.visible !== visible) {
        if (tmp.length === 0 || refresh !== nextProps.refreshCounter || currentPlaceId !== nextProps.placeId) {
          if (!this.loading) {
            this.loading = true
            this.attachLoaderBalance(nextProps.placeId, nextProps.refreshCounter)
          }
        } else {
          for (let s = 0; s < tmp.length; s++) {
            tmp[s].selected = false
          }

          const selectedRfidAntennaIndex = tmp.findIndex((record) => record.balance.id === balanceId)
          if (selectedRfidAntennaIndex >= 0) {
            tmp[selectedRfidAntennaIndex].selected = true
          }

          this.setState({
            balancesWithSelection: tmp,
          })
        }
      }
    }
    return true
  }

  async getBalancesWithSelection(placeId: string, search = ''): Promise<StwBalanceWithSelection[]> {
    return Balances.search<StwBalance>({
      size: MAX_SIZE_LIMIT,
      search: search,
      placeId: placeId,
      freeOrWorkstationId: this.props.workstationId,
    })
      .then((response) => response.content)
      .then((balances) => (balances ? balances.map((balance) => this.decorateWithSelection(balance)) : []))
  }

  decorateWithSelection(balance: StwBalance): StwBalanceWithSelection {
    return {
      balance: balance,
      selected: balance.id === this.props.balanceId,
    }
  }

  onSelect = (balanceId: string, selected: boolean) => {
    const tmp = this.state.balancesWithSelection
    for (let s = 0; s < tmp.length; s++) {
      tmp[s].selected = false
    }
    const selectedIndex = tmp.findIndex((record) => record.balance.id === balanceId)
    if (selectedIndex >= 0) {
      tmp[selectedIndex].selected = selected
      this.setState({
        balancesWithSelection: tmp,
      })
    }
  }

  confirmChoose = () => {
    const { balancesWithSelection } = this.state
    const { onConfirm } = this.props
    let selected = ''
    for (let s = 0; s < balancesWithSelection.length; s++) {
      if (balancesWithSelection[s].selected) {
        selected = balancesWithSelection[s].balance.id || ''
      }
    }
    onConfirm(selected)
  }

  render() {
    const { visible, onCancel, handleNewScale } = this.props
    const { loader, currentPlaceId } = this.state
    return (
      <Modal
        title={
          <Row gutter={22} style={{ marginTop: '20px' }}>
            <Col span={18}>
              <Title level={3}>
                <b>{__('misc.associate_scales')}</b>
              </Title>
            </Col>
            <Col span={6} style={{ justifyContent: 'flex-end', display: 'flex' }}>
              <CloseCircleOutlined style={{ fontSize: 22 }} onClick={() => onCancel()} />
            </Col>
          </Row>
        }
        closable={false}
        open={visible}
        okText="Confirm"
        onOk={() => this.confirmChoose()}
        onCancel={() => onCancel()}
        width="80%"
        className="stw-workstation-rfid-antennas-modal"
      >
        {!loader && (
          <Row gutter={24}>
            <Col span={24} style={{ marginBottom: '20px' }}>
              <Input
                style={{ borderRadius: '10px' }}
                placeholder="Search"
                suffix={
                  hasAnyCapabilities(CAPABILITIES.RESOURCE_BALANCE_EDIT) && (
                    <div>
                      {/*<SearchOutlined style={{ fontSize: 22 }} />*/}
                      <Button onClick={() => handleNewScale()} className="stylewhere-button-primary">
                        {__('scales.create_new')}
                      </Button>
                    </div>
                  )
                }
                onChange={(event) => {
                  this.getBalancesWithSelection(currentPlaceId, event.target.value).then((balancesWithSelection) =>
                    this.setState({ balancesWithSelection })
                  )
                }}
              />
            </Col>
            <Col span={24}>
              <WorkstationBalanceSelectionModalCardList
                balancesWithSelection={this.state.balancesWithSelection}
                onSelect={this.onSelect}
              />
            </Col>
          </Row>
        )}
        {loader && <Loader />}
      </Modal>
    )
  }
}
