/* eslint-disable func-names */
import { Col, FormInstance, Row } from 'antd'
import { Component, Fragment, RefObject } from 'react'
import dayjs from 'dayjs'
import {
  AdvancedFormAttributes,
  AdvancedFormAutocompleteField,
  AdvancedFormButtons,
  AdvancedFormDateField,
  AdvancedFormInlineOptions,
  AdvancedFormInputCheckListProductList,
  AdvancedFormInputField,
  AdvancedFormInputNumberField,
  AdvancedFormInputObjectFormList,
  AdvancedFormInputObjectList,
  AdvancedFormJsonEditorField,
  AdvancedFormOptions,
  AdvancedFormRangeDateField,
  AdvancedFormSelectAutocompleteField,
  AdvancedFormSelectField,
  AdvancedFormSliderField,
  AdvancedFormStringAutocompleteField,
  AdvancedFormStringListField,
  AdvancedFormSwitchField,
  AdvancedFormTextareaField,
  AdvancedFormUrlEditorField,
  AdvancedFormWorkstationBalanceField,
  AdvancedFormWorkstationRfidAntennasField,
  AdvancedFormInputObjectListWithCards,
  SectionTitle,
} from '.'
import {
  AdvancedFormAttributeType,
  AdvancedFormButton,
  AdvancedFormField,
  AdvancedFormInputType,
} from './AdvancedForm/advancedFormTypes'
import { __ } from '../shared/i18n'
import { AttributeUtil } from '../config/utility/utility'
import AdvancedFormUniqueInputField from './AdvancedForm/AdvancedFormUniqueInputField'

interface Props {
  fields: AdvancedFormField[]
  record: any
  handleChange: (key, value) => void
  handleApplyFilters?: () => void
  handleChangeOptions?: any
  handleRemoveOption?: any
  parameters?: any
  store?: any
  title?: string
  subtitle?: string
  editing?: boolean
  width?: string
  buttonActions?: AdvancedFormButton[]
  showBalanceModal?: any
  showRfidAntennasModal?: any
  callbackAdd?: () => void
  displayFormInRow?: boolean
  formRef?: RefObject<FormInstance>
  labelHidden?: boolean
  showAddOption?: () => void
  help?: any
}

interface State {
  currentRecord: any
}

export class AdvancedForm extends Component<Props, State> {
  componentDidMount() {
    this.setState({ currentRecord: this.props.record ?? {} })
  }

  componentDidUpdate() {
    this.checkCurrentRecord()
  }

  checkCurrentRecord() {
    //TODO: potrebbe essere possibile dover aggiornare il currentRecord nelle form di ricerca inline nei dettagli
    // console.log('this.props.record', this.props.record)
    // console.log('this.state.currentRecord', this.state.currentRecord)
  }

  dateRangeChangeHandler(item, values) {
    const { handleChange } = this.props
    const dateValues = values
      ? values
          .filter((value) => value !== null)
          .map((value) => dayjs(value._d).format(item.format || 'DD/MM/YYYY HH:mm:ss'))
      : []

    handleChange(item.key, dateValues)
  }

  handleChangeDate = (item, value) => {
    const { handleChange } = this.props
    handleChange(item.key, dayjs(value._d).format(item.format || 'DD/MM/YYYY HH:mm:ss'))
  }

  handleChange(key, value) {
    if (this.props.handleChange) {
      this.props.handleChange(key, value)
    }
    const { currentRecord } = this.state
    AttributeUtil.setAttribute(currentRecord, key, value)
    this.setState(currentRecord)
  }

  render() {
    const { fields, record, title, subtitle, width, editing, buttonActions, displayFormInRow } = this.props
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const thisRef = this
    return (
      <Row style={{ flexDirection: 'column', width: width || '100%' }}>
        <Col span={24}>
          {title && title !== '' && <SectionTitle title={title} subtitle={subtitle} />}
          <Row gutter={24}>
            {fields.map(function (item, i) {
              return !item.hidden ? (
                <Fragment key={`field${i}`}>
                  <Col
                    xxl={displayFormInRow ? 24 : item.col || 12}
                    xl={displayFormInRow ? 24 : item.col || 12}
                    xs={24}
                    md={displayFormInRow ? 24 : (item.col || 12) + (item.displayInRow ? 0 : 2)}
                    lg={displayFormInRow ? 24 : (item.col || 12) + (item.displayInRow ? 0 : 2)}
                  >
                    {thisRef.renderField(item, record, !(!editing || (editing && !item.onlyCreate)))}
                  </Col>
                  {!item.displayInRow && !displayFormInRow && (
                    <Col
                      xxl={(item.displayInlineCol || 24) - (item.col || 12)}
                      xl={(item.displayInlineCol || 24) - (item.col || 12)}
                      md={(item.displayInlineCol || 24) - ((item.col || 12) + (item.displayInRow ? 0 : 2))}
                      lg={(item.displayInlineCol || 24) - ((item.col || 12) + (item.displayInRow ? 0 : 2))}
                      xs={0}
                    />
                  )}
                </Fragment>
              ) : null
            })}
          </Row>
          {buttonActions && <AdvancedFormButtons actions={buttonActions} />}
        </Col>
      </Row>
    )
  }

  renderField(item: AdvancedFormField, record: any, readonly: boolean) {
    const {
      handleApplyFilters,
      labelHidden,
      parameters,
      handleChangeOptions,
      showAddOption,
      handleRemoveOption,
      help,
    } = this.props

    const { currentRecord } = this.state ?? {}
    switch (item.type) {
      case AdvancedFormInputType.TEXT:
        return (
          <AdvancedFormInputField
            record={record}
            item={item}
            handleChange={(event) => this.handleChange(event.target.id, event.target.value)}
            readonly={readonly || (item.readonly !== undefined && item.readonly(record))}
            labelHidden={labelHidden}
            help={help}
          />
        )
      case AdvancedFormInputType.UNIQUE_TEXT:
        return (
          <AdvancedFormUniqueInputField
            record={record}
            name={item.key}
            label={item.label}
            rules={item.rules}
            placeholder={item.placeholder}
            handleChange={(event) => this.handleChange(event.target.id, event.target.value)}
            endpoint={item.unique!.endpoint}
            parameter={item.unique!.parameter}
            errorMessage={item.unique!.errorMessage}
            readonly={readonly || (item.readonly !== undefined && item.readonly(record))}
          />
        )
      case AdvancedFormInputType.STRING_LIST:
        return (
          <AdvancedFormStringListField
            record={record}
            item={item}
            handleChange={(value) => this.handleChange(item.key, value)}
            readonly={readonly || (item.readonly !== undefined && item.readonly(record))}
            labelHidden={labelHidden}
            handleApplyFilters={handleApplyFilters}
            maxTagCount={item.maxTagCount}
          />
        )
      case AdvancedFormInputType.TEXTAREA:
        return (
          <AdvancedFormTextareaField
            item={item}
            record={record}
            handleChange={(event) => this.handleChange(event.target.id, event.target.value)}
            readonly={readonly || (item.readonly !== undefined && item.readonly(record))}
            labelHidden={labelHidden}
            marginBottom={item.marginBottom || 24}
          />
        )
      case AdvancedFormInputType.SELECT:
        return (
          <AdvancedFormSelectField
            record={record}
            item={item}
            handleChange={(value) => this.handleChange(item.key, value)}
            options={item.option && parameters[item.option] ? parameters[item.option] : null}
            readonly={readonly || (item.readonly !== undefined && item.readonly(record))}
            labelHidden={labelHidden}
            help={help}
            withCustomSelection={item.selectWithCustomSelection}
            maxTagCount={item.maxTagCount}
            separator={item.select?.separator}
          />
        )
      case AdvancedFormInputType.SELECT_AUTOCOMPLETE:
        return (
          <AdvancedFormSelectAutocompleteField
            item={item}
            record={record}
            handleChange={(value) => this.handleChange(item.key, value)}
            options={item.option && parameters[item.option] ? parameters[item.option] : null}
            readonly={readonly || (item.readonly !== undefined && item.readonly(record))}
            labelHidden={labelHidden}
            help={help}
          />
        )
      case AdvancedFormInputType.DATE:
        return (
          <AdvancedFormDateField
            record={record}
            item={item}
            handleChange={(value) => this.handleChangeDate(item, value)}
          />
        )
      case AdvancedFormInputType.RANGE_DATE:
        return (
          <AdvancedFormRangeDateField
            item={item}
            record={record}
            labelHidden={labelHidden}
            handleChange={(values) => this.dateRangeChangeHandler(item, values)}
          />
        )
      case AdvancedFormInputType.SWITCH:
        return (
          <AdvancedFormSwitchField
            record={record}
            item={item}
            labelHidden={labelHidden}
            handleChange={(value) => this.handleChange(item.key, value)}
          />
        )
      case AdvancedFormInputType.AUTOCOMPLETE:
        return (
          <AdvancedFormAutocompleteField
            handleChange={(value) => this.handleChange(item.key, value)}
            label={item.label}
            withoutLabel={labelHidden}
            rules={[...(item.rules ?? []), ...(parameters && item.ruleParameter ? parameters[item.ruleParameter] : [])]}
            name={item.key}
            placeholder={item.placeholder}
            selector={item.autocomplete!.propertyId}
            endpoint={item.autocomplete!.endpoint}
            sort={item.autocomplete!.sort}
            filters={parameters && parameters[item.option !== undefined ? item.option : 'undefined']}
            readonly={readonly || (item.readonly !== undefined && item.readonly(record))}
            withMultipleSelection={item.autocomplete!.multiple}
            withIdValues={item.autocomplete!.withIdValues}
            maxTagCount={item.maxTagCount}
            fieldLabel={item.autocomplete!.fieldLabel}
            autoSelectable={item.autoSelectable}
            selectionOption={item.autocomplete ? item.autocomplete.selectionOption : undefined}
          />
        )
      case AdvancedFormInputType.STRING_AUTOCOMPLETE:
        return (
          <AdvancedFormStringAutocompleteField
            handleChange={(value) => this.handleChange(item.key, value)}
            label={item.label}
            withoutLabel={labelHidden}
            rules={[...(item.rules ?? []), ...(parameters && item.ruleParameter ? parameters[item.ruleParameter] : [])]}
            name={item.key}
            placeholder={item.placeholder}
            endpoint={item.autocomplete!.endpoint}
            sort={item.autocomplete!.sort}
            filters={parameters && parameters[item.option !== undefined ? item.option : 'undefined']}
            additionalParams={
              item.autocomplete && item.autocomplete.additionalParams ? item.autocomplete.additionalParams : []
            }
            readonly={readonly || (item.readonly !== undefined && item.readonly(record))}
            withMultipleSelection={item.autocomplete!.multiple}
            maxTagCount={item.maxTagCount}
            dependencies={item.dependencies}
            dependenciesParams={currentRecord}
          />
        )
      case AdvancedFormInputType.NUMBER:
        return (
          <AdvancedFormInputNumberField
            record={record}
            item={item}
            handleChange={(value) => this.handleChange(item.key, value)}
            labelHidden={labelHidden}
            rules={[
              ...(item.rules ?? []),
              ...(parameters && item && item.ruleParameter ? parameters[item.ruleParameter] : []),
            ]}
          />
        )
      case AdvancedFormInputType.ATTRIBUTES:
        return (
          <AdvancedFormAttributes
            attribute={item.key}
            item={item}
            record={record}
            title={item.placeholder || __(`misc.${item.key}_title`)}
            subtitle={__(`misc.${item.key}_subtitle`)}
            subtype={item.subtype || AdvancedFormAttributeType.SIMPLE}
            updateValue={(value) => this.handleChange(item.key, value)}
            parameters={parameters}
            entity={item.entity || ''}
          />
        )
      case AdvancedFormInputType.OPTIONS:
        return (
          <AdvancedFormOptions
            record={record}
            title={item.placeholder || __(`misc.${item.key}_title`)}
            subtitle={__(`misc.${item.key}_subtitle`)}
            options={parameters.options || []}
            handleChangeOptions={handleChangeOptions}
            handleRemoveOption={handleRemoveOption}
          />
        )
      case AdvancedFormInputType.INLINE_OPTIONS:
        return (
          <AdvancedFormInlineOptions
            record={record}
            title={item.placeholder || __(`misc.${item.key}_title`)}
            subtitle={__(`misc.${item.key}_subtitle`)}
            options={parameters.options || []}
            handleChangeOptions={handleChangeOptions}
            handleRemoveOption={handleRemoveOption}
            showAddOption={showAddOption}
            parameters={parameters}
          />
        )
      case AdvancedFormInputType.TITLE:
        return <SectionTitle title={item.key} subtitle={item.label} />
      case AdvancedFormInputType.CHECKLIST_PRODUCTS:
        return (
          <AdvancedFormInputCheckListProductList
            handleChange={(value) => this.handleChange(item.key, value)}
            label={item.label}
            rules={[...(item.rules ?? []), ...(parameters && item.ruleParameter ? parameters[item.ruleParameter] : [])]}
            name={item.key}
            placeholder={item.placeholder}
            endpoint={item.objectList!.endpoint}
            sort={item.objectList!.sort}
            uniqueValueError={item.uniqueValueError}
          />
        )
      case AdvancedFormInputType.OBJECT_LIST:
        return (
          <AdvancedFormInputObjectList
            handleChange={(value) => this.handleChange(item.key, value)}
            label={item.label}
            rules={[...(item.rules ?? []), ...(parameters && item.ruleParameter ? parameters[item.ruleParameter] : [])]}
            name={item.key}
            placeholder={item.placeholder}
            endpoint={item.objectList!.endpoint}
            sort={item.objectList!.sort}
            uniqueValueError={item.uniqueValueError}
            canDelete={item.objectList!.canDelete}
            columns={item.objectList!.columns}
            mapSelection={item.objectList!.mapSelection}
          />
        )
      case AdvancedFormInputType.OBJECT_LIST_WITH_CARDS:
        return (
          <AdvancedFormInputObjectListWithCards
            handleChange={(value) => this.handleChange(item.key, value)}
            handleView={item.objectListWithCard && item.objectListWithCard.handleView}
            label={item.label}
            rules={[...(item.rules ?? []), ...(parameters && item.ruleParameter ? parameters[item.ruleParameter] : [])]}
            name={item.key}
            placeholder={item.placeholder}
            selector={item.autocomplete!.propertyId}
            endpoint={item.autocomplete!.endpoint}
            sort={item.autocomplete!.sort}
            uniqueValueError={item.uniqueValueError}
            sizeAutocomplete={item.sizeAutocomplete}
            sorting={item.sorting}
          />
        )
      // TODO capire se possibile mergiare OBJECT_FORM_LIST, OBJECT_LIST e ATTRIBUTE
      case AdvancedFormInputType.OBJECT_FORM_LIST:
        return (
          <AdvancedFormInputObjectFormList
            record={record}
            handleChange={(value) => this.handleChange(item.key, value)}
            propertyId={item.objectList!.propertyId}
            attribute={item.key}
            columns={item.objectList!.columns}
            fields={item.objectList!.fields!}
            canDelete={item.objectList!.canDelete}
            parameters={parameters}
            uniqueValueKey={item.uniqueValueKey || null}
            uniqueValueError={item.uniqueValueError || __('misc.duplicateEntries')}
          />
        )
      case AdvancedFormInputType.SLIDER:
        return (
          <AdvancedFormSliderField
            item={item}
            handleChange={(value) => this.handleChange(item.key, value)}
            currentValue={AttributeUtil.getAttribute(record, item.key)}
          />
        )
      case AdvancedFormInputType.URL: {
        return (
          <AdvancedFormUrlEditorField
            keyPrefix={item.key}
            title={item.label || __(`misc.${item.key}_title`)}
            handleChange={(key, value) => this.handleChange(key, value)}
            protocols={parameters && parameters[item.option !== undefined ? item.option : 'undefined']}
            url={record[item.key]}
            rules={item.rules}
          />
        )
      }
      case AdvancedFormInputType.WORKSTATION_RFID_ANTENNAS:
        return (
          <AdvancedFormWorkstationRfidAntennasField
            rfidAntennaIds={record.rfidAntennaIds ?? []}
            placeId={record.placeId || ''}
            showRfidAntennasModal={this.props.showRfidAntennasModal}
            handleChange={(values) => this.handleChange(item.key, values)}
          />
        )
      case AdvancedFormInputType.WORKSTATION_BALANCE:
        return (
          <AdvancedFormWorkstationBalanceField
            balanceId={record.balanceId}
            placeId={record.placeId || ''}
            showBalanceModal={this.props.showBalanceModal}
            handleChange={(value) => this.handleChange(item.key, value)}
          />
        )
      case AdvancedFormInputType.JSON:
        return (
          <AdvancedFormJsonEditorField
            record={record}
            item={item}
            handleChange={(value) => this.handleChange(item.key, value)}
            readonly={readonly || (item.readonly !== undefined && item.readonly(record))}
            labelHidden={labelHidden}
            help={help}
          />
        )
      case AdvancedFormInputType.NOT_IMPLEMENTED:
      default: {
        return <div style={{ color: 'red' }}>{`Field ${item.option}-${item.type} undefined`}</div>
      }
    }
  }
}
