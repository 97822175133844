import { Component } from 'react'
import { StwApplication } from '../../../../api'
import { GridData, StylewherePage, DefaultHeader, NotFound, Section } from '../../../../components'
import { __, T } from '../../../../shared/i18n'
import { APPLICATION_DATA } from '../../../../config/Pages/Settings/ApplicationSettings'
import ApplicationSetting from '../../../../api/ApplicationSetting'
import { ExtendRouteComponentProps } from '../../../../types'
import { getMultipleTitle, getBackURL, getFragmentObject } from '../../../../shared/utils'

interface State {
  application: StwApplication
  loader: boolean
}

class ApplicationSettingDetail extends Component<ExtendRouteComponentProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      application: {},
      loader: true,
    }
  }

  componentDidMount() {
    this.initialize()
  }

  initialize = async () => {
    let application: StwApplication = {}
    if (this.props.match) {
      const applicationId = this.props.match.params.applicationId
      if (applicationId) {
        application = await ApplicationSetting.get<StwApplication>(applicationId)
      }
    }
    this.setState({
      application: application,
      loader: false,
    })
  }

  render() {
    const { application, loader } = this.state
    const { breadcrumbs, queryString } = this.props
    const fragment = getFragmentObject(application, 'code')
    return (
      <StylewherePage {...this.props} noOverflow fragment={fragment}>
        <DefaultHeader
          backPath={getBackURL(queryString, breadcrumbs)}
          multipleTitle={getMultipleTitle(application)}
          title={__(T.misc.not_found)}
          skeleton={{ active: loader, options: { multiple: true } }}
        />
        <Section customClass={`stw-section-page paged-header ${application || loader ? 'scroll transparent' : ''}`}>
          {application || loader ? (
            <GridData fields={APPLICATION_DATA} record={loader ? [] : application} skeleton={loader} />
          ) : (
            <NotFound fullheight title={__(T.misc.noRecordFoundTitle)} />
          )}
        </Section>
      </StylewherePage>
    )
  }
}

export default ApplicationSettingDetail
