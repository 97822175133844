import { Component } from 'react'
import { GridData, StylewherePage, DefaultHeader, NotFound, Section, TableListReport } from '../../../components'
import { DetailCell, StwReportQueryResult } from '../../../api'
import { __, T } from '../../../shared/i18n'
import { ExtendRouteComponentProps } from '../../../types'
import { SkeletonAttributeReport } from '../../../constants'
import Reports from '../../../api/Reports'
import { getMultipleTitle, getBackURL, getFragment } from '../../../shared/utils'
import { CAPABILITIES, hasAnyCapabilities } from '../../../config/utility/capabilityUtility'
import { BreadcrumbsFragmentType } from '../../../components/PageBread'

interface State {
  reportInstance?: StwReportQueryResult
  configuration?: DetailCell[]
  loader: boolean
  fragment?: BreadcrumbsFragmentType[]
}

class ReportSessionItemListDetail extends Component<ExtendRouteComponentProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      configuration: undefined,
      loader: true,
    }
  }

  componentDidMount() {
    this.initialize()
  }

  initialize = async () => {
    const { match } = this.props
    if (match && match.params && match.params.recordId) {
      const recordId = match.params.recordId
      const res = await Reports.report('simpleItemSession', {
        id: recordId,
        page: 0,
        size: 1,
      })
      if (res && res.content && res.content.length > 0) {
        const configuration = await Reports.getConfiguration('simpleItemSession')
        const fragments: BreadcrumbsFragmentType[] = []
        if (match.params.operationType && match.params.operationId && match.params.operationInstanceId) {
          fragments.push(
            getFragment(
              match.params.operationInstanceId,
              false,
              `/operationlogs/${match.params.operationType}/${match.params.operationId}/${match.params.operationInstanceId}`
            )
          )
        }
        fragments.push(getFragment(res.content[0].id, true, undefined))

        this.setState({
          reportInstance: res.content[0],
          configuration: configuration,
          loader: false,
          fragment: fragments,
        })
      } else {
        this.setState({ loader: false })
      }
    }
  }

  getDetailPath = () => {
    const { reportInstance } = this.state
    if (reportInstance) return `/reports/simpleItemSession/${reportInstance.id}/item`
    return ``
  }

  render() {
    const { configuration, reportInstance, loader, fragment } = this.state
    const { breadcrumbs, queryString } = this.props
    return (
      <StylewherePage {...this.props} noOverflow fragment={fragment}>
        <DefaultHeader
          backPath={!loader ? getBackURL(queryString, breadcrumbs) : ''}
          multipleTitle={getMultipleTitle(reportInstance)}
          title={__(T.misc.not_found)}
          skeleton={{ active: loader, options: { multiple: true } }}
        />
        <Section customClass={`stw-section-page paged-header ${reportInstance || loader ? 'scroll transparent' : ''}`}>
          {reportInstance || loader ? (
            <>
              <GridData
                fields={configuration || SkeletonAttributeReport}
                record={loader ? [] : reportInstance}
                skeleton={loader}
              />
              {reportInstance && (
                <TableListReport
                  {...this.props}
                  reportId="simpleItemSessionEntry"
                  actions={[]}
                  fixedTableHeight
                  disabledReportExport
                  headerType="boxed"
                  title={__(T.simpleItemSessionList.sessionEntries)}
                  titleEmpty={__(T.simpleItemSessionList.noSessionEntries)}
                  customColumnPrefix="checklistDetailEntries"
                  reportActions={{
                    active: hasAnyCapabilities(CAPABILITIES.RESOURCE_ITEM_VIEW),
                    onlyedit: false,
                    fieldId: 'itemId',
                  }}
                  reportParams={{ session: reportInstance.id }}
                  disableLocation
                  disabledNoDataFound
                  hiddenEmptyTable
                  pathDetail={this.getDetailPath()}
                />
              )}
            </>
          ) : (
            <NotFound fullheight title={__(T.misc.noRecordFoundTitle)} />
          )}
        </Section>
      </StylewherePage>
    )
  }
}

export default ReportSessionItemListDetail
