import { Component } from 'react'
import { Row } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { __, T } from '../../../shared/i18n'
import { DetailCell, StwProduct } from '../../../api'
import Products from '../../../api/Products'
import { Section, ProductDetailBox, TableListReport, NotFound } from '../../../components'
import { getDetailDataWithAttributesMapping } from '../../../shared/utils'
import { PRODUCT_MODAL_FIELDS } from '../../../config/Pages/Reports'
import { ExtendRouteComponentProps } from '../../../types'

interface Props extends ExtendRouteComponentProps {
  productId: string
  sortingGroupEntryId: string
}

interface State {
  loader: boolean
  product?: StwProduct
  productDetailData: DetailCell[]
}

export default class SortingGroupReads extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      loader: true,
      productDetailData: PRODUCT_MODAL_FIELDS,
    }
  }

  componentDidMount = () => {
    this.initialize()
  }

  initialize = async () => {
    const { productId } = this.props
    const dataProduct: DetailCell[] = await getDetailDataWithAttributesMapping(
      PRODUCT_MODAL_FIELDS,
      'product',
      0,
      'valueDesc',
      12
    )
    const product = await Products.get<StwProduct>(productId)
    this.setState({
      product: product,
      loader: false,
      productDetailData: dataProduct,
    })
  }

  getSectionTitle = () => {
    const { loader, product } = this.state
    return product || !loader ? __('fields.labels.sortingGroupReads') : <LoadingOutlined />
  }

  render = () => {
    const { product, loader, productDetailData } = this.state
    const { sortingGroupEntryId } = this.props
    return (
      <Section title={this.getSectionTitle()} parentPadding={15}>
        {loader || product ? (
          <>
            <ProductDetailBox loader={loader} product={product} data={productDetailData} />
            {product && (
              <TableListReport
                {...this.props}
                reportId="sortingGroupRead"
                actions={[]}
                fixedTableHeight
                disabledReportExport
                headerType="unset"
                disabledColumnPrefix
                reportParams={{ sortingGroupEntryId: sortingGroupEntryId }}
                disableLocation
                disableColumnFilters
                numberRecodPage={10}
                disabledReloadData
                sortValues={{}}
                tableBorder
              />
            )}
          </>
        ) : (
          <Row style={{ marginTop: 15 }}>
            <NotFound title={__(T.misc.noRecordFoundTitle)} />
          </Row>
        )}
      </Section>
    )
  }
}
