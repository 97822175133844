import { Component } from 'react'
import { List } from 'antd'
import { StwBalance } from '../../api'
import WorkstationBalanceSelectionModalCard from './WorkstationBalanceSelectionModalCard'

type StwBalanceWithSelection = {
  balance: StwBalance
  selected: boolean
}

interface Props {
  balancesWithSelection: StwBalanceWithSelection[]
  onSelect: (balanceId: string, selected: boolean) => void
}

export default class WorkstationBalanceSelectionModalCardList extends Component<Props> {
  render() {
    const { balancesWithSelection, onSelect } = this.props
    return (
      <List
        style={{ width: '100%' }}
        itemLayout="horizontal"
        dataSource={balancesWithSelection}
        renderItem={(balanceWithSelection: StwBalanceWithSelection, index) => (
          <WorkstationBalanceSelectionModalCard
            index={index}
            balanceWithSelection={balanceWithSelection}
            onSelect={onSelect}
          />
        )}
      />
    )
  }
}
