import { Component } from 'react'
import { Modal } from 'antd'
import dayjs from 'dayjs'
import { __ } from '../../shared/i18n'
import { KeyValueGrid, ModalHeader } from '..'
import { DetailCell } from '../../api'

interface Props {
  visible: boolean
  close: any
  notification?: any
}

export class ModalNotificationDetail extends Component<Props> {
  getNotificationColumns = () => {
    const { notification } = this.props
    const columns: DetailCell[] = [
      { title: __('fields.labels.description'), attribute: 'description', columns: 24 },
      { title: __('misc.date'), attribute: 'date', columns: 24 },
    ]
    if (notification.link) {
      columns.push({ title: __('misc.url'), attribute: 'link', columns: 24, type: 'link' })
    }
    return columns
  }

  getNotificationData = () => {
    const { notification } = this.props
    return {
      description: notification.description || '-',
      date: dayjs(notification.creationdate).format('DD/MM/YYYY HH:mm'),
      link: notification.link,
    }
  }

  render() {
    const { visible, close, notification } = this.props
    return (
      <Modal
        title={<ModalHeader close={() => close(undefined)} title={__('misc.notifications.detail')} />}
        centered
        open={visible}
        closable={false}
        footer={null}
        onCancel={() => close(undefined)}
        className="stw-workstation-rfid-antennas-modal"
      >
        {notification && <KeyValueGrid fields={this.getNotificationColumns()} data={this.getNotificationData()} />}
      </Modal>
    )
  }
}
