import BaseResource from './BaseResource'
import { StwOperationType, StwOperation } from './types'
import { api, responseErrorCheck } from './api'

export default class Operations extends BaseResource {
  static endpoint = '/api/v1/operations' ///profile

  static async getReportId(operationId): Promise<string> {
    const operation = await Operations.get<StwOperation>(operationId)
    return `OPLOG_${operation.type}`
  }

  static executeAction = async (prefix, operation, detailId, operationId, actionCode) => {
    try {
      const result = await api.post(`${prefix}/api/v1/operations/${operation}/${detailId}/executeAction`, {
        operationId: operationId,
        actionCode: actionCode,
        attributes: {},
      })
      return result && result.status && result.status === 204
    } catch (e) {
      return false
    }
  }

  static async getBatchValidateStatuses(operation: { id?: string; type?: StwOperationType }): Promise<string[]> {
    switch (operation.type) {
      case 'TRANSFER_TO_ZONE':
        return api
          .get<string[]>(`/api/v1/operations/transfersToZone/batchValidate/statuses/${operation.id ?? ''}`)
          .then(responseErrorCheck)
      case 'SIMPLE_ITEM_LIST':
        return api
          .get<string[]>(`/api/v1/operations/simpleItemList/batchValidate/statuses/${operation.id ?? ''}`)
          .then(responseErrorCheck)
      case 'INBOUND':
        return api
          .get<string[]>(`/api/v1/operations/inbound/batchValidate/statuses/${operation.id ?? ''}`)
          .then(responseErrorCheck)
      case 'OUTBOUND':
        return api
          .get<string[]>(`/api/v1/operations/outbound/batchValidate/statuses/${operation.id ?? ''}`)
          .then(responseErrorCheck)
      case 'PACKING':
        return api
          .get<string[]>(`/api/v1/operations/packing/batchValidate/statuses/${operation.id ?? ''}`)
          .then(responseErrorCheck)
      case 'PICKING':
        return api.get<string[]>(`/api/v1/operations/picking/batchValidate/statuses`).then(responseErrorCheck)
      default:
        return []
    }
  }
}
