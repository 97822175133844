import { Component } from 'react'
import { Col, Form, Row, Slider, Typography } from 'antd'
import { AdvancedFormField } from './advancedFormTypes'
import { __ } from '../../shared/i18n'

interface Props {
  item: AdvancedFormField
  currentValue: number
  handleChange: any
}

const { Text } = Typography

export class AdvancedFormSliderField extends Component<Props> {
  render() {
    const { item, handleChange, currentValue } = this.props
    return (
      <Row gutter={10} className="stylewhere-form-item" style={{ alignItems: 'center', margin: 0 }}>
        <Col span={20}>
          <Form.Item
            name={item.key.split('.')}
            rules={item.rules}
            label={item.label || __(`fields.labels.${item.key}`)}
          >
            <Slider
              style={{ width: '100%' }}
              id={item.key}
              value={currentValue ?? item.range!.min}
              min={item.range!.min}
              max={item.range!.max}
              onChange={(e) => handleChange(e)}
            />
          </Form.Item>
        </Col>
        <Col span={4} className="stylewhere-form-item-slider-input">
          <Text>{currentValue ?? 'n/a'}</Text>
        </Col>
      </Row>
    )
  }
}
