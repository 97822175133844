import { Component } from 'react'
import { Col, Row } from 'antd'
import { DetailCell } from '../../api'
import { KeyValueGrid, Section, SectionTitle } from '..'

interface Props {
  fields: DetailCell[]
  record: any
  title?: string
  nomb?: boolean
  skeleton?: boolean
}

export class GridData extends Component<Props> {
  render() {
    const { fields, record, title, nomb, skeleton } = this.props
    return (
      <Section nomb={nomb}>
        {title && title !== null && title !== '' && <SectionTitle title={title} marginBottom={20} />}
        <Row style={{ width: '100%' }}>
          <Col span={24}>
            <KeyValueGrid fields={fields} data={record} skeleton={skeleton} />
          </Col>
        </Row>
      </Section>
    )
  }
}
