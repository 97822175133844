import { __ } from '../../shared/i18n'
import {
  AdvancedFormField,
  AdvancedFormFieldBuilder,
  AdvancedFormInputType,
} from '../../components/AdvancedForm/advancedFormTypes'
import { AdvancedTableColumn, AdvancedTableFiltersDef } from '../../components/AdvancedTable'

export const ATTRIBUTE_FILTERS: AdvancedTableFiltersDef = []

export const ATTRIBUTE_ENTITIES = [
  { label: __('menu.places'), value: 'place' },
  { label: __('menu.zones'), value: 'zone' },
  { label: __('menu.users'), value: 'user' },
  { label: __('menu.roles'), value: 'role' },
  //{ label: 'Operation', value: 'operation' },
  { label: __('report.item'), value: 'item' },
  {
    label: __('report.product'),
    value: 'product',
    options: [
      {
        id: 'variantType',
        description: 'variantType',
        message:
          'Could be used to generate different barcodes for the same Model/Material/Color/Size (ex. for Sample and collection)',
      },
      {
        id: 'variantCode',
        description: 'variantCode',
        message: 'Variant Code. Additional information to determine the unicity of UPC',
      },
      {
        id: 'lot',
        description: 'lot',
        message:
          'Could be used to generate different barcodes for the same Model/Material/Color/Size produced in different lots (ex. Production Italy vs. China)',
      },
      {
        id: 'label',
        description: 'label',
        message:
          'Could be used to generate different barcodes for the same Model/Material/Color/Size produced in different Labels (ex. European vs. US)',
      },
      {
        id: 'width',
        description: 'width',
        message:
          'Could be used to generate different barcodes for the same Model/Material/Color/Size but differnt Width (expecially for Jeans)',
      },
      {
        id: 'measure',
        description: 'measure',
        message:
          'Could be used to generate different barcodes for the same Model/Material/Color/Size but differnt Width (expecially for fabric material)',
      },
      {
        id: 'externalUrl',
        description: 'externalUrl',
        message: 'External Web page to be open for product information',
      },
      {
        id: 'brand',
        description: 'brand',
        message: 'Brand the product. Will be used to limit visibility to the users',
      },
      { id: 'line', description: 'line', message: 'Product Line. Available for reporting' },
      { id: 'category', description: 'category', message: 'Product Category Code. ex. WSHOES / SLG / RTW /...' },
      {
        id: 'merchandiseClass',
        description: 'merchandiseClass',
        message: 'Merchandise Hierarchy. ex. SHOES / BAGS / ...',
      },
      {
        id: 'merchandiseSubclass',
        description: 'merchandiseSubclass',
        message: 'Merchandise Hierarchy. ex. WOMAN / MAN / KIDS',
      },
      {
        id: 'productionClass',
        description: 'productionClass',
        message: 'Production Hierarchy. ex. FABRIC / KNITWEAR',
      },
      {
        id: 'productionSubclass',
        description: 'productionSubclass',
        message: 'Production Hierarchy. ex. COLORED / NEUTRAL',
      },
      { id: 'department', description: 'department', message: 'Collection Hierarchy. ex. SHOES/ BAGS' },
      {
        id: 'subdepartment',
        description: 'subdepartment',
        message: 'Collection Hierarchy. ex. WOMAN SHOES / MAN SHOES',
      },
      { id: 'class', description: 'class', message: 'Collection Hierarchy. ex. HIGH HEEL / BALLERINA / SNEAKERS' },
      { id: 'subclass', description: 'subclass', message: 'Collection Hierarchy. ex. PUMP / SANDAL / ...' },
      { id: 'family', description: 'family', message: 'Collection Description. Available for reporting' },
      { id: 'theme', description: 'theme', message: 'Collection Description. Available for reporting' },
      { id: 'age', description: 'age', message: 'Collection Description. Available for reporting' },
      { id: 'collectionYear', description: 'collectionYear', message: 'Collection Year' },
      { id: 'collectionCode', description: 'collectionCode', message: 'Collection Code ex: Man /Woman' },
      { id: 'collectionEvent', description: 'collectionEvent', message: 'Event. ex. Valentine Day' },
      { id: 'marketingDescription', description: 'marketingDescription', message: '' },
      { id: 'cites', description: 'cites', message: '' },
      { id: 'compliance', description: 'compliance', message: '' },
      { id: 'customsCode', description: 'customsCode', message: 'TARIC Code' },
      { id: 'metal', description: 'metal', message: 'Metals' },
      { id: 'shape', description: 'shape', message: 'Shape' },
      { id: 'careInstructions', description: 'careInstructions', message: '' },
      { id: 'weigthUom', description: 'weigthUom', message: 'Unit of measure for Weights es. Kilograms, Grams' },
      { id: 'weigthValue', description: 'weigthValue', message: 'Weight' },
      { id: 'volumeUom', description: 'volumeUom', message: 'Unit of measure for Volumes. es. CM3' },
      { id: 'volumeValue', description: 'volumeValue', message: 'Volume' },
      { id: 'dimensionUom', description: 'dimensionUom', message: 'Unit of measure for Dimensions. es. CM' },
      { id: 'dimensionHeight', description: 'dimensionHeight', message: 'Product Box Height' },
      { id: 'dimensionWidth', description: 'dimensionWidth', message: 'Product Box Width' },
      { id: 'dimensionDepth', description: 'dimensionDepth', message: 'Product Box Depth' },
      {
        id: 'imageId',
        description: 'imageId',
        message:
          'Default value: IMG-”product code”. Indicates to FE the ID to be used to retrieve the image from the appropriate endpoint',
      },
      {
        id: 'sketchId',
        description: 'sketchId',
        message:
          'Default value: SKH-”product code”.  Indicates to FE the ID to be used to retrieve the sketch from the appropriate endpoint',
      },

      { id: 'barcode', description: 'barcode', message: '' },
      { id: 'ean', description: 'ean', message: '' },
    ],
  },
  /*{ label: __('report.asn'), value: 'shipment' },
  { label: __('report.parcel'), value: 'parcel' },
  { label: __('report.checklist'), value: 'checklist' },
  { label: __('report.pallet'), value: 'pallet' },*/

  { label: __('menu.workstation'), value: 'workstation' },
  { label: __('menu.printers'), value: 'printer' },
  { label: __('menu.scanners'), value: 'scanner' },
  { label: __('menu.xspans'), value: 'xspan' },
  { label: __('menu.scales'), value: 'scale' },
]

export const ATTRIBUTE_FIELDS: AdvancedFormField[] = [
  {
    key: 'attribute',
    type: AdvancedFormInputType.TEXT,
    rules: [{ required: true }],
  },
  AdvancedFormFieldBuilder.buildText('description'),
  AdvancedFormFieldBuilder.buildText('defaultValue'),
]

export const ATTRIBUTE_COLUMNS: AdvancedTableColumn[] = [
  {
    title: __(`fields.labels.code`),
    key: 'code',
    dataIndex: 'code',
    className: 'stw-medium',
  },
  {
    title: __(`fields.labels.description`),
    key: 'description',
    dataIndex: 'description',
    className: 'stw-extra-medium',
  },
  {
    title: __(`fields.labels.defaultValue`),
    key: 'defaultValue',
    dataIndex: 'defaultValue',
    className: 'stw-medium',
  },
]
