import { api, responseErrorCheck } from './api'
import BaseResource from './BaseResource'

export default class AsyncJobs extends BaseResource {
  static endpoint = '/api/v1/asyncJobs' // /base
  static endpointExport = '/api/v1/exports' // /base

  static async downloadFile(exportId: string, contentType = 'text') {
    let res 
    if(contentType === "blob"){
      res = await api.get<any>(`${this.endpointExport}`, {'exportId': exportId }, {responseType: 'blob'})  
    } else {
      res = await api.get<any>(`${this.endpointExport}?exportId=${exportId}`)
    }
    
    return responseErrorCheck<any>(res)
  }
}
