import { Component } from 'react'
import { ExtendRouteComponentProps } from '../../../types'
import { StylewherePage, DefaultHeader, Section, GridData, NotFound } from '../../../components'
import { __, T } from '../../../shared/i18n'
import { DetailCell, StwXspan } from '../../../api'
import Xspans from '../../../api/Xspans'
import {
  getDetailDataWithAttributesMapping,
  getMultipleTitle,
  getBackURL,
  getFragmentObject,
} from '../../../shared/utils'

interface State {
  xspan: StwXspan | undefined
  loader: boolean
  detailData: DetailCell[]
}

const XSPAN_CONFIGURATION: DetailCell[] = [
  { title: __('fields.labels.place'), attribute: 'place', columns: 12, type: 'codeDesc' },
  { title: __('fields.labels.code'), attribute: 'code', columns: 12 },
  { title: __('fields.labels.description'), attribute: 'description', columns: 12 },
  {
    title: __('fields.labels.deviceController'),
    attribute: 'deviceController',
    columns: 12,
    type: 'codeDesc',
  },
  { title: __('fields.labels.operation'), attribute: 'operation', columns: 12, type: 'codeDesc' },
  { title: __('fields.labels.ipAddress'), attribute: 'ipAddress', columns: 12 },
  { title: __('fields.labels.txPower'), attribute: 'txPower', columns: 12 },
  { title: __('fields.labels.mode'), attribute: 'mode', columns: 12 },
  { title: __('fields.labels.fieldOfView'), attribute: 'fieldOfView', columns: 12 },
  { title: __('fields.labels.tagAgeInterval'), attribute: 'tagAgeInterval', columns: 12 },
  { title: __('fields.labels.autoStart'), attribute: 'autoStart', columns: 12, type: 'boolean' },
  { title: __('fields.labels.maxConnectionTimeout'), attribute: 'maxConnectionTimeout', columns: 12 },
  {
    title: __('fields.labels.keepAliveEnabled'),
    attribute: 'keepAliveEnabled',
    columns: 12,
    type: 'boolean',
  },
  { title: __('fields.labels.keepAlivePeriod'), attribute: 'keepAlivePeriod', columns: 12 },
  {
    title: __('fields.labels.linkMonitorModeEnabled'),
    attribute: 'linkMonitorModeEnabled',
    columns: 12,
    type: 'boolean',
  },
  { title: __('fields.labels.linkDownThreshold'), attribute: 'linkDownThreshold', columns: 12 },
  { title: __('fields.labels.sector2Zone'), attribute: 'sector2Zone', columns: 12, type: 'codeDesc' },
  { title: __('fields.labels.sector3Zone'), attribute: 'sector3Zone', columns: 12, type: 'codeDesc' },
  { title: __('fields.labels.user'), attribute: 'user', columns: 12, type: 'user' },
  {
    title: __('fields.labels.transferToZoneEnabled'),
    attribute: 'transferToZoneEnabled',
    columns: 12,
    type: 'boolean',
  },
  { title: __(`fields.labels.creationDate`), attribute: 'creationDate', columns: 12, type: 'date' },
  { title: __(`fields.labels.lastModifyDate`), attribute: 'lastModifyDate', columns: 12, type: 'date' },
]

export default class XspanDetail extends Component<ExtendRouteComponentProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      xspan: undefined,
      loader: true,
      detailData: XSPAN_CONFIGURATION,
    }
  }

  componentDidMount = async () => {
    const { match } = this.props
    let xspan
    const data: DetailCell[] = await getDetailDataWithAttributesMapping(XSPAN_CONFIGURATION, 'xspan', 2)
    if (match && match.params && match.params.xspanId) {
      if (match.params.xspanId) {
        xspan = await Xspans.getFull(match.params.xspanId)
      }
    }
    this.setState({
      xspan: xspan,
      detailData: data,
      loader: false,
    })
  }

  render() {
    const { xspan, loader, detailData } = this.state
    const { breadcrumbs, queryString } = this.props
    const fragment = getFragmentObject(xspan, 'code')
    return (
      <StylewherePage {...this.props} noOverflow fragment={fragment}>
        <DefaultHeader
          backPath={getBackURL(queryString, breadcrumbs)}
          multipleTitle={getMultipleTitle(xspan)}
          title={__(T.misc.not_found)}
          skeleton={{ active: loader, options: { multiple: true } }}
        />
        <Section customClass={`stw-section-page paged-header ${xspan || loader ? 'scroll transparent' : ''}`}>
          {xspan || loader ? (
            <GridData fields={detailData || XSPAN_CONFIGURATION} record={xspan || {}} skeleton={loader} />
          ) : (
            <NotFound fullheight title={__(T.misc.noRecordFoundTitle)} />
          )}
        </Section>
      </StylewherePage>
    )
  }
}
