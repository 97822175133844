import { Component } from 'react'
import { DefaultReportDetail } from '../../../components'
import { ExtendRouteComponentProps } from '../../../types'

class ProductionOrderDetail extends Component<ExtendRouteComponentProps> {
  render() {
    const { match } = this.props
    return (
      <DefaultReportDetail
        {...this.props}
        reportId="productionorders"
        reportInstanceId={match.params.productionorderId}
      />
    )
  }
}

export default ProductionOrderDetail
