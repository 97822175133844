import { Component } from 'react'
import { ExtendRouteComponentProps } from '../../../types'
import { TableListReport, StylewherePage } from '../../../components'
import { getTableActionsReport, getHeaderActionsReport } from '../../../shared/utils'
import { __, T } from '../../../shared/i18n'
import RfidAntennas from '../../../api/RfidAntennas'

class RfidAntennasList extends Component<ExtendRouteComponentProps> {
  render() {
    const reportId = 'antennas'
    return (
      <StylewherePage {...this.props} noOverflow>
        <TableListReport
          {...this.props}
          reportId={reportId}
          title={__(T.menu.antennas)}
          reportActions={getTableActionsReport(reportId)}
          actions={getHeaderActionsReport(reportId, this.props.breadcrumbs)}
          disabledReportExport
          deleteRecord={{
            call: RfidAntennas.deleteTableList,
            endpoint: RfidAntennas.endpoint,
            title: `${__(T.misc.delete_record)} ${__(T.antennas.antenna)}?`,
            subtitle: __(T.misc.delete_noundo),
          }}
        />
      </StylewherePage>
    )
  }
}
export default RfidAntennasList
