import { Component } from 'react'
import { StylewherePage, DefaultHeader, Section, GridData, NotFound } from '../../../components'
import { DetailCell, StwConveyor } from '../../../api'
import { __, T } from '../../../shared/i18n'
import { ExtendRouteComponentProps } from '../../../types'
import Conveyors from '../../../api/Conveyors'
import { CONVEYOR_DATA } from '../../../config/Pages/Devices/Conveyors'
import { getMultipleTitle, getBackURL, getFragmentObject } from '../../../shared/utils'
import { SkeltonAttribute } from '../../../constants'

interface State {
  conveyor: StwConveyor | undefined
  attributeFields: DetailCell[]
  settingFields: DetailCell[]
  loader: boolean
}

class ConveyorDetail extends Component<ExtendRouteComponentProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      conveyor: undefined,
      attributeFields: [],
      settingFields: [],
      loader: true,
    }
  }

  componentDidMount = async () => {
    let conveyor
    const attributesConfiguration: DetailCell[] = []
    const settingsConfiguration: DetailCell[] = []
    if (this.props.match) {
      const conveyorId = this.props.match.params.conveyorId
      if (conveyorId) {
        conveyor = await Conveyors.get<StwConveyor>(conveyorId).then((res) => res)
        if (conveyor && conveyor.attributes) {
          const keys = Object.keys(conveyor.attributes)
          keys.forEach((key) => {
            attributesConfiguration.push({ title: key, attribute: key, columns: 8 })
          })
        }
        if (conveyor && conveyor.settings) {
          const keys = Object.keys(conveyor.settings)
          keys.forEach((key) => {
            settingsConfiguration.push({ title: key, attribute: key, columns: 8 })
          })
        }
      }
    }

    this.setState({
      conveyor: conveyor,
      loader: false,
      attributeFields: attributesConfiguration,
      settingFields: settingsConfiguration,
    })
  }

  render() {
    const { conveyor, loader, settingFields, attributeFields } = this.state
    const { breadcrumbs, queryString } = this.props
    const viewAttributes = loader || attributeFields.length > 0
    const viewSettings = loader || settingFields.length > 0
    const fragment = getFragmentObject(conveyor, 'code')
    return (
      <StylewherePage {...this.props} noOverflow fragment={fragment}>
        <DefaultHeader
          backPath={getBackURL(queryString, breadcrumbs)}
          multipleTitle={getMultipleTitle(conveyor, ['id', 'description'])}
          title={__(T.misc.not_found)}
          skeleton={{ active: loader, options: { multiple: true } }}
        />
        <Section customClass={`stw-section-page paged-header ${conveyor || loader ? 'scroll transparent' : ''}`}>
          {conveyor || loader ? (
            <>
              <GridData fields={CONVEYOR_DATA} record={conveyor || {}} skeleton={loader} />
              {viewAttributes && (
                <GridData
                  title={__('fields.labels.attributes.title')}
                  fields={loader ? SkeltonAttribute : attributeFields}
                  record={!conveyor ? [] : conveyor.attributes}
                  skeleton={loader}
                />
              )}
              {viewSettings && (
                <GridData
                  title={__(T.misc.settings_title)}
                  fields={loader ? SkeltonAttribute : settingFields}
                  record={!conveyor ? [] : conveyor.settings}
                  skeleton={loader}
                />
              )}
            </>
          ) : (
            <NotFound fullheight title={__(T.misc.noRecordFoundTitle)} />
          )}
        </Section>
      </StylewherePage>
    )
  }
}

export default ConveyorDetail
