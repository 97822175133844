import { Component } from 'react'
import { StwFullRfidAntenna, StwFullRfidReader } from '../../api'
import WorkstationRfidAntennasModalCollapse from './WorkstationRfidAntennasModalCollapse'

type RfidAntennasByRfidReader = {
  rfidReader: StwFullRfidReader
  rfidAntennasWithSelection: StwRfidAntennaWithSelection[]
}

type RfidAntennasByRfidReaderMap = {
  [rfidReaderId: string]: RfidAntennasByRfidReader
}

type StwRfidAntennaWithSelection = {
  rfidAntenna: StwFullRfidAntenna
  selected: boolean
}

interface Props {
  rfidAntennasWithSelection: StwRfidAntennaWithSelection[]
  onSelect: (rfidAntennaId: string, selected: boolean) => void
}

export default class WorkstationRfidAntennasModalCollapseList extends Component<Props> {
  render() {
    const { onSelect } = this.props
    return this.getRfidAntennasByRfidReader().map((rfidAntennasByRfidReader) => (
      <WorkstationRfidAntennasModalCollapse
        key={rfidAntennasByRfidReader.rfidReader.id}
        rfidReader={rfidAntennasByRfidReader.rfidReader}
        rfidAntennasWithSelection={rfidAntennasByRfidReader.rfidAntennasWithSelection}
        onSelect={onSelect}
      />
    ))
  }

  private getRfidAntennasByRfidReader(): RfidAntennasByRfidReader[] {
    return Object.values(
      this.props.rfidAntennasWithSelection
        .filter(WorkstationRfidAntennasModalCollapseList.hasValidRfidReader)
        .reduce((rfidAntennasByRfidReader, rfidAntennaWithSelection) => {
          const rfidReader = rfidAntennaWithSelection.rfidAntenna.reader!
          const rfidReaderId = rfidReader.id!
          rfidAntennasByRfidReader[rfidReaderId] = rfidAntennasByRfidReader[rfidReaderId] || {
            rfidReader,
            rfidAntennasWithSelection: [],
          }
          rfidAntennasByRfidReader[rfidReaderId].rfidAntennasWithSelection.push(rfidAntennaWithSelection)
          return rfidAntennasByRfidReader
        }, {} as RfidAntennasByRfidReaderMap)
    )
  }

  private static hasValidRfidReader(rfidAntennaWithSelection: StwRfidAntennaWithSelection): boolean {
    return (
      rfidAntennaWithSelection.rfidAntenna.reader !== undefined && rfidAntennaWithSelection.rfidAntenna.id !== undefined
    )
  }
}
