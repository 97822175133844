import { Component } from 'react'
import { Modal } from 'antd'
import { __ } from '../../shared/i18n'
import { KeyValueGrid, ModalHeader } from '..'
import { DetailCell } from '../../api'

interface Props {
  visible: boolean
  close: any
  userData: any
}

const USER_DATA: DetailCell[] = [
  { title: __('fields.labels.name'), attribute: 'name', columns: 12 },
  { title: __('fields.labels.surname'), attribute: 'surname', columns: 12 },
  { title: __('fields.labels.username'), attribute: 'username', columns: 12 },
  { title: __('fields.labels.email'), attribute: 'email', columns: 12 },
  { title: __('misc.roles_title'), attribute: 'roles', columns: 24 },
  { title: __('menu.places'), attribute: 'places', columns: 24 },
]

export class ModalUserData extends Component<Props> {
  getRoles = () => {
    const { userData } = this.props
    let str = userData.roles.length === 0 ? '-' : ''
    for (let r = 0; r < userData.roles.length; r++) {
      str += (str !== '' ? ', ' : '') + userData.roles[r].code
    }
    return str
  }

  getPlaces = () => {
    const { userData } = this.props
    let str = userData.places.length === 0 ? '-' : ''
    for (let r = 0; r < userData.places.length; r++) {
      str += (str !== '' ? ', ' : '') + userData.places[r].description
    }
    return str
  }

  getUserData = () => {
    const { userData } = this.props
    return {
      name: userData.name || '-',
      surname: userData.surname || '-',
      username: userData.username || '-',
      email: userData.email || '-',
      roles: this.getRoles(),
      places: this.getPlaces(),
    }
  }

  render() {
    const { visible, close } = this.props
    return (
      <Modal
        title={<ModalHeader close={close} title={__('misc.userInfo')} />}
        centered
        open={visible}
        closable={false}
        footer={null}
        onCancel={() => close()}
        className="stw-workstation-rfid-antennas-modal"
      >
        <KeyValueGrid fields={USER_DATA} data={this.getUserData()} />
      </Modal>
    )
  }
}
