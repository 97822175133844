import { Component } from 'react'
import { StwRoleMapping } from '../../../../api'
import { AdvancedTable, GridData, StylewherePage, DefaultHeader, NotFound, Section } from '../../../../components'
import { __, T } from '../../../../shared/i18n'
import { ExtendRouteComponentProps } from '../../../../types'
import { AdvancedTableContent, AdvancedTablePagination } from '../../../../components/AdvancedTable'
import RoleMappings from '../../../../api/RoleMappings'
import { ROLE_MAPPINGS_DATA, ROLES_MAPPINGS_ROLES_COLUMNS } from '../../../../config/Pages/UsersRoles/RoleMappings'
import { getMultipleTitle, getBackURL, getFragmentObject } from '../../../../shared/utils'

interface State {
  roleMapping: StwRoleMapping | undefined
  loader: boolean
  tableContent: AdvancedTableContent<any>
  totalElements: number
  tablePagination: AdvancedTablePagination
}

class RoleMappingDetail extends Component<ExtendRouteComponentProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      roleMapping: undefined,
      loader: true,
      tableContent: [],
      totalElements: 0,
      tablePagination: {
        filterValues: undefined,
        pageSize: 100,
        sortValues: {},
        pageNumber: 1,
      },
    }
  }

  componentDidMount() {
    this.initialize()
  }

  initialize = async () => {
    if (this.props.match) {
      const roleMappingId = this.props.match.params.roleMappingId
      if (roleMappingId) {
        const roleMapping = await RoleMappings.get<StwRoleMapping>(roleMappingId).then((res) => res)
        this.setState({
          roleMapping: roleMapping,
          tableContent: roleMapping.roles || [],
          totalElements: roleMapping.roles ? roleMapping.roles.length : 0,
          loader: false,
        })
      }
    }
  }

  render() {
    const { roleMapping, loader, totalElements, tableContent, tablePagination } = this.state
    const { breadcrumbs, queryString } = this.props
    const fragment = getFragmentObject(roleMapping, 'code')
    return (
      <StylewherePage {...this.props} noOverflow fragment={fragment}>
        <DefaultHeader
          backPath={getBackURL(queryString, breadcrumbs)}
          multipleTitle={getMultipleTitle(roleMapping)}
          title={__(T.misc.not_found)}
          skeleton={{ active: loader, options: { multiple: true } }}
        />
        <Section customClass={`stw-section-page paged-header ${roleMapping || loader ? 'scroll transparent' : ''}`}>
          {roleMapping || loader ? (
            <>
              <GridData fields={ROLE_MAPPINGS_DATA} record={roleMapping} skeleton={loader} />
              <Section>
                <AdvancedTable
                  loader={loader}
                  tableTitle={{ title: __(T.misc.roles_title), emptyTitle: __(T.roleMapping.noRolesFoundTitle) }}
                  pagination={tablePagination}
                  columns={ROLES_MAPPINGS_ROLES_COLUMNS}
                  filters={{
                    filterDefinitions: [],
                  }}
                  content={tableContent}
                  totalElements={totalElements}
                  disabledNoDataFound
                  disabledPagination
                  activeDefaultClassColumn
                />
              </Section>
            </>
          ) : (
            <NotFound fullheight title={__(T.misc.noRecordFoundTitle)} />
          )}
        </Section>
      </StylewherePage>
    )
  }
}

export default RoleMappingDetail
