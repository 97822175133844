import { Button, Card, Space } from 'antd'
import { Component } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { __ } from '../../shared/i18n'

interface Props {
  handleOnClick: any
}

export class AdvancedRfidReaderPlaceholderAntennaCard extends Component<Props> {
  render() {
    const { handleOnClick } = this.props

    return (
      <Card size="small" onClick={handleOnClick} className="swt-placeholder-antenna-card">
        <Space className="stw-placeholder-antenna-card-content">
          <Space className="stylewhere-table-actions">
            <Button type="default">
              <PlusOutlined title={__('misc.addAntenna')} />
            </Button>
          </Space>
          {__('misc.addAntenna')}
        </Space>
      </Card>
    )
  }
}
