import { Component } from 'react'
import { DetailCell, StwReportQueryResult } from '../../../api'
import { KeyValueGrid, StylewherePage, DefaultHeader, NotFound, Section } from '../../../components'
import { __, T } from '../../../shared/i18n'
import { SkeletonAttributeReport } from '../../../constants'
import { getFragmentObject, getMultipleTitle, getBackURL } from '../../../shared/utils'
import { ExtendRouteComponentProps } from '../../../types'
import Operations from '../../../api/Operations'
import Reports from '../../../api/Reports'

interface State {
  reportInstance?: StwReportQueryResult
  configuration: DetailCell[]
  loader: boolean
}

class OperationDetail extends Component<ExtendRouteComponentProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      configuration: [],
      loader: true,
    }
  }

  async componentDidMount(): Promise<void> {
    await this.initialize()
  }

  async initialize() {
    const { match } = this.props
    if (match && match.params && match.params.operationInstanceId) {
      const params = { operationId: match.params.operationId }
      const reportId = await Operations.getReportId(match.params.operationId)
      const instance: any = await Reports.reportInstance(reportId, match.params.operationInstanceId, params)
      if (instance && instance.id) {
        const configuration = await Reports.getConfiguration(reportId, params)
        this.setState({
          reportInstance: instance,
          configuration: configuration,
          loader: false,
        })
      } else {
        this.setState({
          loader: false,
        })
      }
    } else {
      this.setState({
        loader: false,
      })
    }
  }

  render(): JSX.Element {
    const { breadcrumbs, queryString } = this.props
    const { configuration, reportInstance, loader } = this.state
    const fragment = getFragmentObject(reportInstance, 'id')
    return (
      <StylewherePage {...this.props} noOverflow fragment={fragment}>
        <DefaultHeader
          backPath={getBackURL(queryString, breadcrumbs)}
          multipleTitle={getMultipleTitle(reportInstance)}
          title={__(T.misc.not_found)}
          skeleton={{ active: loader, options: { multiple: true } }}
        />
        <Section customClass={`stw-section-page paged-header ${reportInstance || loader ? 'scroll transparent' : ''}`}>
          {reportInstance || loader ? (
            <Section>
              <KeyValueGrid
                fields={loader ? SkeletonAttributeReport : configuration}
                data={reportInstance}
                skeleton={loader}
              />
            </Section>
          ) : (
            <NotFound fullheight title={__(T.misc.noRecordFoundTitle)} />
          )}
        </Section>
      </StylewherePage>
    )
  }
}

export default OperationDetail
