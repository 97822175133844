import { Component } from 'react'
import { ExtendRouteComponentProps } from '../../../../types'
import { TableListReport, StylewherePage } from '../../../../components'
import { getTableActionsReport, getHeaderActionsReport } from '../../../../shared/utils'
import { getTabs } from '../../../../config/Pages/UsersRoles/Tabs'

class UserList extends Component<ExtendRouteComponentProps> {
  render() {
    const reportId = 'users'
    return (
      <StylewherePage {...this.props} noOverflow>
        <TableListReport
          {...this.props}
          reportId={reportId}
          tabs={getTabs(1)}
          reportActions={getTableActionsReport(reportId)}
          actions={getHeaderActionsReport(reportId, this.props.breadcrumbs)}
          disabledReportExport
        />
      </StylewherePage>
    )
  }
}

export default UserList
