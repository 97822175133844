import { Component } from 'react'
import { StylewherePage, DefaultHeader, Section, GridData, NotFound } from '../../../components'
import { StwDeviceController } from '../../../api'
import DeviceControllers from '../../../api/DeviceControllers'
import { __, T } from '../../../shared/i18n'
import { ExtendRouteComponentProps } from '../../../types'
import { DEVICE_CONTROLLERS_DATA } from '../../../config/Pages/Devices/DeviceControllers'
import { getMultipleTitle, getBackURL, getFragmentObject } from '../../../shared/utils'

interface State {
  deviceController: StwDeviceController | undefined
  loader: boolean
}

class DeviceControllerDetail extends Component<ExtendRouteComponentProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      deviceController: undefined,
      loader: true,
    }
  }

  componentDidMount = async () => {
    let deviceController
    if (this.props.match) {
      const deviceControllerId = this.props.match.params.controllerId
      if (deviceControllerId) {
        deviceController = await DeviceControllers.get<StwDeviceController>(deviceControllerId).then((res) => res)
      }
    }

    this.setState({
      deviceController: deviceController || undefined,
      loader: false,
    })
  }

  render() {
    const { deviceController, loader } = this.state
    const { breadcrumbs, queryString } = this.props
    const fragment = getFragmentObject(deviceController, 'code')
    return (
      <StylewherePage {...this.props} noOverflow fragment={fragment}>
        <DefaultHeader
          backPath={getBackURL(queryString, breadcrumbs)}
          multipleTitle={getMultipleTitle(deviceController)}
          title={__(T.misc.not_found)}
          skeleton={{ active: loader, options: { multiple: true } }}
        />
        <Section
          customClass={`stw-section-page paged-header ${deviceController || loader ? 'scroll transparent' : ''}`}
        >
          {deviceController || loader ? (
            <GridData fields={DEVICE_CONTROLLERS_DATA} record={deviceController || {}} skeleton={loader} />
          ) : (
            <NotFound fullheight title={__(T.misc.noRecordFoundTitle)} />
          )}
        </Section>
      </StylewherePage>
    )
  }
}

export default DeviceControllerDetail
