import { Component, createRef, RefObject } from 'react'
import { Form, FormInstance } from 'antd'
import { ExtendRouteComponentProps } from '../../../types'
import { __, T } from '../../../shared/i18n'
import { AttributeUtil } from '../../../config/utility/utility'
import { navigate } from '../../../shared/router'
import { BarcodeReaderType, FormValidateMessages } from '../../../constants'
import { AdvancedForm, StylewherePage, DefaultHeader, Section } from '../../../components'
import BarcodeReaders from '../../../api/BarcodeReaders'
import { SimpleAttributesMap, StwBarcodeReader, StwFullBarcodeReader } from '../../../api'
import { BARCODE_READERS_FIELDS, BARCODE_READERS_KEEP_ALIVE_FIELDS } from '../../../config/Pages/Devices/BarcodeReaders'
import { DeviceUtility } from '../../../config/utility/deviceUtility'
import { AdvancedFormField } from '../../../components/AdvancedForm/advancedFormTypes'
import { FormUtility } from '../../../config/utility/formUtility'
import { getFragmentObject, getBackURL } from '../../../shared/utils'

interface State {
  barcodeReader: StwFullBarcodeReader
  loader: boolean
  columns: AdvancedFormField[]
  keepAliveRules: { required: boolean }
  dataSocketPortRules: { required: boolean }
}

class BarcodeReaderForm extends Component<ExtendRouteComponentProps, State> {
  formRef = createRef<FormInstance>()

  constructor(props) {
    super(props)
    this.state = {
      barcodeReader: {
        deviceType: 'DATALOGIC_BARCODE_READER',
        keepAliveEnabled: false,
      },
      keepAliveRules: { required: false },
      dataSocketPortRules: { required: false },
      columns: BARCODE_READERS_FIELDS,
      loader: true,
    }
  }

  async componentDidMount() {
    await this.initialize()
  }

  async initialize() {
    let barcodeReader: StwFullBarcodeReader = this.state.barcodeReader
    if (this.props.match) {
      const barcodeReaderId = this.props.match.params.barcodeReaderId
      if (barcodeReaderId !== '' && barcodeReaderId !== 'create') {
        barcodeReader = await BarcodeReaders.getFullBarcodeReader(barcodeReaderId)
        if (barcodeReader.keepAliveEnabled) {
          this.handleChange('keepAliveEnabled', true)
        }
        this.handleDeviceTypeChange(barcodeReader)
      }
    }

    this.setState(
      {
        barcodeReader: barcodeReader,
        loader: false,
      },
      this.updateFormFieldsValue
    )
  }

  updateFormFieldsValue = () => {
    if (this.formRef && this.formRef.current) {
      this.formRef.current.setFieldsValue(this.state.barcodeReader)
    }
  }

  private static updateCodeAndDescription(barcodeReader: StwFullBarcodeReader, formRef: RefObject<FormInstance>) {
    BarcodeReaders.search<StwFullBarcodeReader>({ page: 0, size: 1, placeId: barcodeReader.place!.id })
      .then((barcodeReaders) => {
        return {
          code: `${barcodeReader.place!.code}_B${barcodeReaders.totalElements + 1}`,
          description:
            barcodeReader.place!.description &&
            `${barcodeReader.place!.description}_B${barcodeReaders.totalElements + 1}`,
        }
      })
      .then(({ code, description }) => {
        DeviceUtility.setFieldValue(barcodeReader, 'code', code, formRef)
        DeviceUtility.setFieldValue(barcodeReader, 'description', description, formRef)
      })
  }

  private handlePlaceChange(value, barcodeReader: StwFullBarcodeReader) {
    if (value) {
      BarcodeReaderForm.updateCodeAndDescription(barcodeReader, this.formRef)
    } else {
      DeviceUtility.resetCodeAndDescription(barcodeReader, this.formRef)
    }
  }

  handleChange = (key, value) => {
    const { barcodeReader } = this.state
    AttributeUtil.setAttribute(barcodeReader, key, value)
    if (key === 'place') {
      this.handlePlaceChange(value, barcodeReader)
    } else if (key === 'keepAliveEnabled') {
      this.handleKeepAlive(barcodeReader, this.formRef)
    } else if (key === 'deviceType') {
      this.handleDeviceTypeChange(barcodeReader)
    }
    this.setState({ barcodeReader })
  }

  private handleKeepAlive(barcodeReader: StwFullBarcodeReader, formRef: RefObject<FormInstance>) {
    const { keepAliveRules } = this.state
    let columns: AdvancedFormField[]
    if (barcodeReader.keepAliveEnabled) {
      keepAliveRules.required = barcodeReader.deviceType === 'DATALOGIC_BARCODE_READER'
      columns = [...BARCODE_READERS_FIELDS, ...BARCODE_READERS_KEEP_ALIVE_FIELDS]
    } else {
      keepAliveRules.required = false
      columns = BARCODE_READERS_FIELDS
      FormUtility.setFieldValue(barcodeReader, 'keepAlivePeriod', null, formRef)
      FormUtility.setFieldValue(barcodeReader, 'keepAliveSocketPort', null, formRef)
    }

    this.setState({ columns, keepAliveRules })
  }

  async store() {
    const { barcodeReader } = this.state
    const simple = this.mapFullBarcodeReaderToBarcodeReader(barcodeReader)
    if (barcodeReader.id) {
      BarcodeReaders.update<StwBarcodeReader>(simple).then((value) => {
        this.redirect(value)
      })
    } else {
      BarcodeReaders.insert<StwBarcodeReader>(simple).then((value) => {
        this.redirect(value)
      })
    }
  }

  private handleDeviceTypeChange(barcodeReader: StwFullBarcodeReader) {
    const { dataSocketPortRules } = this.state
    dataSocketPortRules.required = barcodeReader.deviceType === 'DATALOGIC_BARCODE_READER'
    this.setState({ dataSocketPortRules }, () => this.formRef.current?.validateFields())
  }

  private redirect(barcodeReader: StwFullBarcodeReader) {
    if (barcodeReader && barcodeReader.id) {
      navigate(`/devices/barcodeReaders${this.props.queryString ?? ''}`)
    }
  }

  private mapFullBarcodeReaderToBarcodeReader = (full: StwFullBarcodeReader): StwBarcodeReader => {
    const settings: SimpleAttributesMap = {}

    if (full.ipAddress) {
      settings.ipAddress = full.ipAddress
    }
    if (full.dataSocketPort) {
      settings.dataSocketPort = full.dataSocketPort.toString()
    }
    if (full.maxConnectionTimeout) {
      settings.maxConnectionTimeout = full.maxConnectionTimeout.toString()
    }
    if (full.keepAliveEnabled) {
      settings.keepAliveEnabled = String(full.keepAliveEnabled)
    }
    if (full.keepAlivePeriod) {
      settings.keepAlivePeriod = full.keepAlivePeriod.toString()
    }
    if (full.keepAliveSocketPort) {
      settings.keepAliveSocketPort = full.keepAliveSocketPort.toString()
    }

    return {
      id: full.id,
      code: full.code,
      description: full.description,
      place: full.place,
      deviceType: full.deviceType,
      creationDate: full.creationDate,
      lastModifyDate: full.lastModifyDate,
      settings: settings,
    }
  }

  render() {
    const { barcodeReader, loader, keepAliveRules, dataSocketPortRules, columns } = this.state
    const { breadcrumbs, queryString } = this.props
    const title = barcodeReader.id ? __(T.barcodeReaders.edit) : __(T.barcodeReaders.create_new)
    const fragment = !loader ? getFragmentObject(barcodeReader, 'id', __(T.deviceControllers.create_new)) : undefined
    return (
      <Form
        ref={this.formRef}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        onFinish={async () => {
          await this.store()
        }}
        style={{ width: '100%', height: '100%' }}
        initialValues={barcodeReader}
        validateMessages={FormValidateMessages}
        scrollToFirstError
      >
        <StylewherePage {...this.props} noOverflow fragment={fragment}>
          <DefaultHeader
            backPath={getBackURL(queryString, breadcrumbs)}
            title={loader ? '...' : title}
            skeleton={{ active: loader }}
            actions={[
              {
                label: __(T.misc.cancel),
                type: 'cancel',
                onClick: () => navigate(`/devices/barcodeReaders${queryString ?? ''}`),
              },
              {
                label: barcodeReader.id ? __(T.misc.update) : __(T.misc.create),
                type: 'submit',
              },
            ]}
          />
          <Section customClass="stw-section-page paged-header scroll">
            <AdvancedForm
              record={barcodeReader}
              fields={columns}
              store={this.store}
              editing={barcodeReader.id !== undefined}
              handleChange={this.handleChange}
              parameters={{
                barcodeReaderType: BarcodeReaderType,
                keepAliveRules: [keepAliveRules],
                dataSocketPortRules: [dataSocketPortRules],
              }}
            />
          </Section>
        </StylewherePage>
      </Form>
    )
  }
}

export default BarcodeReaderForm
