import { Component } from 'react'
import { Button, Col, Space, Tag } from 'antd'
import dayjs from 'dayjs'
import { navigate } from 'core/shared/router'

interface Props {
  value?: any
  date?: boolean
  tag?: boolean
  options?: any
  format?: string
  number?: boolean
  actions?: any[]
}

export class AdvancedTableRowColumn extends Component<Props> {
  render() {
    const { value, date, format, actions, tag, options, number } = this.props
    return (
      <>
        {actions && (
          <Space className="stylewhere-table-actions">
            {actions.map((item, i) => {
              return (
                <Button
                  className="stylewhere-table-action-btn"
                  type={item.type || 'default'}
                  key={`actions${i}`}
                  onClick={item.onclick ? item.onclick : () => navigate(item.path)}
                >
                  {item.content}
                </Button>
              )
            })}
          </Space>
        )}
        {!actions && (
          <Space>
            {date && (
              <Col style={{ padding: 0 }} span={24}>
                {dayjs(value).format(format || 'DD/MM/YYYY')}
              </Col>
            )}
            {!date && !tag && !number && (
              <Col span={24} style={{ padding: 0 }}>
                {value !== null && value !== undefined && value !== '' ? value : 'n/a'}
              </Col>
            )}
            {!date && !tag && number && (
              <Col style={{ padding: 0 }} span={24}>
                {value}
              </Col>
            )}
            {!date && tag && (
              <Col span={24} style={{ padding: 0 }}>
                <Tag color={options?.color || 'default'}>{value}</Tag>
              </Col>
            )}
          </Space>
        )}
      </>
    )
  }
}
