import { Component, createRef } from 'react'
import { Form, FormInstance } from 'antd'
import { StwUser } from '../../../../api'
import { ExtendRouteComponentProps } from '../../../../types'
import Users from '../../../../api/Users'
import { __, T } from '../../../../shared/i18n'
import { navigate } from '../../../../shared/router'
import { AdvancedForm, StylewherePage, DefaultHeader, Section } from '../../../../components'
import { FormValidateMessages } from '../../../../constants'
import { USERS_FIELDS } from '../../../../config/Pages/UsersRoles/Users'
import { AttributeUtil } from '../../../../config/utility/utility'
import { getFragmentObject, getBackURL } from '../../../../shared/utils'

interface State {
  userId: string
  user: StwUser
  loader: boolean
}

class UserForm extends Component<ExtendRouteComponentProps, State> {
  formRef = createRef<FormInstance>()

  constructor(props) {
    super(props)
    this.state = {
      userId: '',
      user: {
        username: '',
      },
      loader: true,
    }
  }

  componentDidMount = async () => {
    let result
    if (this.props.match) {
      const userId = this.props.match.params.userId
      if (userId !== '' && userId !== 'create') {
        result = await Users.get<StwUser>(userId)
      }
    }
    this.setState(
      {
        userId: result ? result.id! : '',
        user: result || {},
        loader: false,
      },
      this.updateFormFieldsValue
    )
  }

  updateFormFieldsValue = () => {
    if (this.formRef && this.formRef.current) {
      this.formRef.current.setFieldsValue(this.state.user)
    }
  }

  handleChange = (key, value) => {
    const { user } = this.state
    AttributeUtil.setAttribute(user, key, value)
    if (key === 'attributes' && this.formRef && this.formRef.current) {
      this.formRef.current.setFieldsValue({ attributes: user.attributes })
    }
    this.setState({
      user: user,
    })
  }

  store = () => {
    const { user, userId } = this.state
    if (userId !== '') {
      Users.update<StwUser>(user).then(() => {
        navigate(`/configuration/usersRoles/users`)
      })
    } else {
      Users.insert<StwUser>(user).then((newUser) => {
        if (newUser && newUser.id) {
          navigate(`/configuration/usersRoles/users`)
        }
      })
    }
  }

  render() {
    const { user, userId, loader } = this.state
    const title = userId !== '' ? __('user.edit') : __('user.create_new')
    const { breadcrumbs, queryString } = this.props
    const fragment = !loader ? getFragmentObject(user, 'id', __(T.user.create_new)) : undefined
    return (
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        onFinish={this.store}
        style={{ width: '100%', height: '100%' }}
        initialValues={user}
        ref={this.formRef}
        validateMessages={FormValidateMessages}
        scrollToFirstError
      >
        <StylewherePage {...this.props} noOverflow fragment={fragment}>
          <DefaultHeader
            backPath={getBackURL(queryString, breadcrumbs)}
            title={loader ? '...' : title}
            skeleton={{ active: loader }}
            actions={[
              {
                label: __(T.misc.cancel),
                type: 'cancel',
                onClick: () => navigate(`/configuration/usersRoles/users${queryString ?? ''}`),
              },
              {
                label: userId !== '' ? __(T.misc.update) : __(T.misc.create),
                type: 'submit',
              },
            ]}
          />
          <Section customClass="stw-section-page paged-header scroll">
            <AdvancedForm
              record={user}
              fields={USERS_FIELDS}
              store={this.store}
              editing={user.id !== undefined}
              handleChange={this.handleChange}
            />
          </Section>
        </StylewherePage>
      </Form>
    )
  }
}

export default UserForm
