import { Component, createRef } from 'react'
import { Form, FormInstance } from 'antd'
import { AdvancedForm, StylewherePage, DefaultHeader, Section } from '../../../components'
import { ExtendRouteComponentProps } from '../../../types'
import Zones from '../../../api/Zones'
import { ZONE_FIELDS } from '../../../config/Pages/Zones'
import { navigate } from '../../../shared/router'
import { __, T } from '../../../shared/i18n'
import { StwZone } from '../../../api'
import { AttributeUtil } from '../../../config/utility/utility'
import { FormValidateMessages, ZoneTypes } from '../../../constants'
import { FormUtility } from '../../../config/utility/formUtility'
import { getFragmentObject, getBackURL } from '../../../shared/utils'

interface State {
  zoneId: string
  zone: StwZone
  loader: boolean
  isCodeEditableByDescription: boolean
  fragment: any | undefined
}

class ZoneForm extends Component<ExtendRouteComponentProps, State> {
  formRef = createRef<FormInstance>()

  constructor(props) {
    super(props)
    this.state = {
      zoneId: '',
      zone: {
        defaultZone: false,
      },
      loader: true,
      isCodeEditableByDescription: true,
      fragment: undefined,
    }
  }

  componentDidMount = async () => {
    const { match } = this.props
    let result
    if (match && match.params && match.params.zoneId) {
      if (match.params.zoneId !== '' && match.params.zoneId !== 'create') {
        result = await Zones.get(match.params.zoneId)
      }
    }
    this.setState(
      {
        zoneId: result ? result.id : '',
        zone: result ?? {
          defaultZone: false,
        },
        isCodeEditableByDescription: !result,
        loader: false,
        fragment: getFragmentObject(result, 'code', __(T.zone.create_new)),
      },
      this.updateFormFieldsValue
    )
  }

  updateFormFieldsValue = () => {
    if (this.formRef && this.formRef.current) {
      this.formRef.current.setFieldsValue(this.state.zone)
    }
  }

  handleChange = (key, value) => {
    const { zone, isCodeEditableByDescription } = this.state
    AttributeUtil.setAttribute(zone, key, value)
    if (key === 'description' && isCodeEditableByDescription) {
      FormUtility.handleDescription(value, zone, this.formRef)
    } else if (key === 'code') {
      this.setState({ isCodeEditableByDescription: false })
    } else if (key === 'attributes') {
      if (this.formRef && this.formRef.current) {
        this.formRef.current.setFieldsValue({ attributes: zone.attributes })
      }
    }
    this.setState({
      zone: zone,
    })
  }

  store = () => {
    const { zone, zoneId } = this.state
    const { queryString } = this.props
    if (zoneId !== '') {
      Zones.update<StwZone>(zone).then(() => {
        navigate(`/configuration/zones${queryString ?? ''}`)
      })
    } else {
      Zones.insert<StwZone>(zone).then((z) => {
        if (z && z.id) {
          navigate(`/configuration/zones${queryString ?? ''}`)
        }
      })
    }
  }

  render() {
    const { zone, zoneId, loader, fragment } = this.state
    const { breadcrumbs, queryString } = this.props
    const title = zoneId !== '' ? __(T.zone.edit) : __(T.zone.create_new)
    return (
      <Form
        ref={this.formRef}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        onFinish={this.store}
        style={{ width: '100%', height: '100%' }}
        initialValues={zone}
        validateMessages={FormValidateMessages}
        scrollToFirstError
      >
        <StylewherePage {...this.props} noOverflow fragment={fragment}>
          <DefaultHeader
            backPath={getBackURL(queryString, breadcrumbs)}
            title={loader ? '...' : title}
            skeleton={{ active: loader }}
            actions={[
              {
                label: __(T.misc.cancel),
                type: 'cancel',
                onClick: () => navigate(`/configuration/zones${queryString ?? ''}`),
              },
              {
                label: zoneId !== '' ? __(T.misc.update) : __(T.misc.create),
                type: 'submit',
              },
            ]}
          />
          <Section customClass="stw-section-page paged-header scroll">
            <AdvancedForm
              record={zone}
              handleChange={this.handleChange}
              parameters={{ types: ZoneTypes }}
              fields={ZONE_FIELDS}
              store={this.store}
            />
          </Section>
        </StylewherePage>
      </Form>
    )
  }
}

export default ZoneForm
