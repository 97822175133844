import { Component } from 'react'
import { StylewherePage, TableList } from '../../../components'
import { PRINTERS_COLUMNS, PRINTERS_FILTERS } from '../../../config/Pages/Devices/Printers'
import Printers from '../../../api/Printers'
import { __, T } from '../../../shared/i18n'
import { AdvancedTableConfig } from '../../../components/AdvancedTable'
import { ExtendRouteComponentProps } from '../../../types'
import { CAPABILITIES, getActionsList } from '../../../config/utility/capabilityUtility'
import { printerDeviceControllerTypeFilters, PrinterTypes } from '../../../constants'

const DEVICE_PRINTER_TABLE_CONFIG: AdvancedTableConfig = {
  columns: PRINTERS_COLUMNS,
  filterDefinitions: PRINTERS_FILTERS,
  filterParameters: {
    types: PrinterTypes,
    deviceControllerTypeFilters: printerDeviceControllerTypeFilters,
  },
  manageColumnsPrefix: 'printers',
  activeDefaultClassColumn: true,
}

class PrintersList extends Component<ExtendRouteComponentProps> {
  render() {
    return (
      <StylewherePage {...this.props} noOverflow>
        <TableList
          title={__(T.menu.printers)}
          location={this.props.location}
          config={DEVICE_PRINTER_TABLE_CONFIG}
          resource={{
            call: Printers.searchTableList,
            endpoint: Printers.endpoint,
          }}
          actions={getActionsList(
            CAPABILITIES.RESOURCE_PRINTER_EDIT,
            __('printers.create_new'),
            '/devices/printers/create/form'
          )}
        />
      </StylewherePage>
    )
  }
}

export default PrintersList
