import { Component } from 'react'
import { Button, List, Modal } from 'antd'
import { AttributeUtil } from '../../config/utility/utility'
import { __ } from '../../shared/i18n'

interface Props {
  visible: boolean
  title: string
  attributeCode: string
  attributeDescription?: string
  entities: any[] | null
  callback: any
}

export class ModalContentList extends Component<Props> {
  getListToRender(entities: any[], attributeCode, attributeDescription) {
    return entities.map((entity) => {
      const code = AttributeUtil.getAttribute(entity, attributeCode)
      const description = attributeDescription && AttributeUtil.getAttribute(entity, attributeDescription)

      return (
        <>
          <div className="modal-list-element-bold">{code}</div>
          {description && <div className="modal-list-element">{description}</div>}
        </>
      )
    })
  }

  render() {
    const { attributeCode, attributeDescription, entities, title, visible, callback } = this.props

    let toRender
    if (entities && entities.length > 0) {
      const listToRender = this.getListToRender(entities, attributeCode, attributeDescription)
      toRender = (
        <List
          size="large"
          dataSource={listToRender}
          renderItem={(item) => (
            <List.Item>
              <div>{item}</div>
            </List.Item>
          )}
        />
      )
    } else {
      toRender = <div>{__('misc.not_found')}</div>
    }

    return (
      <Modal
        centered
        open={visible}
        className="ant-modal-body-list"
        closable={false}
        title={title}
        footer={[
          <Button key="close" type="primary" onClick={callback}>
            {__('action.close')}
          </Button>,
        ]}
      >
        {toRender}
      </Modal>
    )
  }
}
