import { Component, createRef } from 'react'
import { Form, FormInstance } from 'antd'
import { AdvancedForm, StylewherePage, Section, DefaultHeader } from '../../../../components'
import ApplicationSetting from '../../../../api/ApplicationSetting'
import { ExtendRouteComponentProps } from '../../../../types'
import { APPLICATION_FIELD } from '../../../../config/Pages/Settings/ApplicationSettings'
import { navigate } from '../../../../shared/router'
import { __, T } from '../../../../shared/i18n'
import { StwApplication } from '../../../../api'
import { AttributeUtil } from '../../../../config/utility/utility'
import { FormValidateMessages, ApplicationTypes, ApplicationClients } from '../../../../constants'
import { FormUtility } from '../../../../config/utility/formUtility'
import { getFragmentObject, getBackURL } from '../../../../shared/utils'

interface State {
  application: StwApplication
  loader: boolean
  isCodeEditableByDescription: boolean
}

class ApplicationSettingForm extends Component<ExtendRouteComponentProps, State> {
  formRef = createRef<FormInstance>()

  constructor(props) {
    super(props)
    this.state = {
      application: {},
      loader: true,
      isCodeEditableByDescription: true,
    }
  }

  componentDidMount() {
    this.initialize()
  }

  initialize = async () => {
    const { match } = this.props
    let result
    if (match && match.params && match.params.applicationId) {
      const applicationId = match.params.applicationId
      if (applicationId !== '' && applicationId !== 'create') {
        result = await ApplicationSetting.get(applicationId)
      }
    }
    this.setState(
      {
        application: result || {},
        isCodeEditableByDescription: !result,
        loader: false,
      },
      this.updateFormFieldsValue
    )
  }

  updateFormFieldsValue = () => {
    if (this.formRef && this.formRef.current) {
      this.formRef.current.setFieldsValue(this.state.application)
    }
  }

  handleChange = (key, value) => {
    const { application, isCodeEditableByDescription } = this.state
    AttributeUtil.setAttribute(application, key, value)
    if (key === 'description' && isCodeEditableByDescription) {
      FormUtility.handleDescription(value, application, this.formRef)
    } else if (key === 'code') {
      this.setState({ isCodeEditableByDescription: false })
    }
    this.setState({
      application: application,
    })
  }

  store = () => {
    const { application } = this.state
    const { queryString } = this.props
    if (application.id) {
      ApplicationSetting.update<StwApplication>(application).then(() => {
        navigate(`/configuration/settings/applications${queryString ?? ''}`)
      })
    } else {
      ApplicationSetting.insert<StwApplication>(application).then((newPlace) => {
        if (newPlace && newPlace.id) {
          navigate(`/configuration/settings/applications${queryString ?? ''}`)
        }
      })
    }
  }

  render() {
    const { application, loader } = this.state
    const title = application.id ? __(T.application.edit) : __(T.application.create_new)
    const { breadcrumbs, queryString } = this.props
    const fragment = !loader ? getFragmentObject(application, 'id', __(T.itemconfiguration.create_new)) : undefined
    return (
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        onFinish={this.store}
        style={{ width: '100%', height: '100%' }}
        ref={this.formRef}
        initialValues={application}
        validateMessages={FormValidateMessages}
        scrollToFirstError
      >
        <StylewherePage {...this.props} noOverflow fragment={fragment}>
          <DefaultHeader
            backPath={getBackURL(queryString, breadcrumbs)}
            title={loader ? '...' : title}
            skeleton={{ active: loader }}
            actions={[
              {
                label: __(T.misc.cancel),
                type: 'cancel',
                onClick: () => navigate(`/configuration/settings/applications${queryString ?? ''}`),
              },
              {
                label: application.id ? __(T.misc.update) : __(T.misc.create),
                type: 'submit',
              },
            ]}
          />
          <Section customClass="stw-section-page paged-header scroll">
            <AdvancedForm
              record={application}
              store={this.store}
              editing={application.id !== undefined}
              handleChange={this.handleChange}
              parameters={{
                applicationTypes: ApplicationTypes,
                applicationClients: ApplicationClients,
              }}
              fields={APPLICATION_FIELD}
            />
          </Section>
        </StylewherePage>
      </Form>
    )
  }
}

export default ApplicationSettingForm
