import { Component } from 'react'
import { Button, Row } from 'antd'

export interface StylewhereTab {
  label: string
  url: string
  active?: boolean
  hidden?: boolean
}

interface Props {
  tabs: StylewhereTab[]
  selected: string
  change: (tab: StylewhereTab) => void
}

export class Tabs extends Component<Props> {
  render() {
    const { tabs, selected, change } = this.props
    return (
      <Row className="stylewhere-tab-page">
        {tabs!
          .filter((tab) => !tab.hidden)
          .map((tab) => (
            <Button
              key={tab.label}
              title={tab.label}
              onClick={() => (tab.active ? {} : change(tab))}
              className={`ant-page-header-tab ${selected === tab.label || tab.active ? 'selected' : ''}`}
            >
              {tab.label}
            </Button>
          ))}
      </Row>
    )
  }
}
