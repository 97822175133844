import BaseResource from './BaseResource'
import { SubmenuEntryType } from '../types'
import { __ } from '../shared/i18n'

export default class Notifications extends BaseResource {
  static endpoint = '/api/v1/notifications' ///base

  static getNotificationMenu(list) {
    const entries: SubmenuEntryType[] = []
    let tot = list ? list.length : 0
    if (tot > 6) tot = 6
    for (let c = 0; c < tot; c++) {
      entries.push({
        read: list[c].read,
        label: list[c].read ? __('misc.notifications.read') : __('misc.notifications.new'),
        description: list[c].description,
        id: list[c].id,
        creationDate: list[c].creationDate,
        link: list[c].link,
        getPath: () => {
          return ''
        },
      })
    }
    return entries
  }
}
