import { Component } from 'react'
import { StwBalance } from '../../../api'
import { ExtendRouteComponentProps } from '../../../types'
import { __, T } from '../../../shared/i18n'
import { navigate } from '../../../shared/router'
import { AdvancedBalanceForm, StylewherePage, DefaultHeader, Section } from '../../../components'
import { getFragmentObject, getBackURL } from '../../../shared/utils'

interface State {
  balanceId: string
  loader: boolean
  formName: string
}

class BalanceForm extends Component<ExtendRouteComponentProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      balanceId: '',
      loader: true,
      formName: '',
    }
  }

  componentDidMount = async () => {
    let balanceId
    if (this.props.match && this.props.match.params.balanceId !== 'create') {
      balanceId = this.props.match.params.balanceId
    }
    this.setState({
      balanceId: balanceId,
      loader: false,
    })
  }

  onSave = (balance: StwBalance) => {
    if (balance && balance.id) {
      navigate(`/devices/balances${this.props.queryString ?? ''}`)
    }
  }

  setFormName = (name: string) => {
    this.setState({ formName: name })
  }

  render() {
    const { balanceId, loader, formName } = this.state
    const { breadcrumbs, queryString } = this.props
    const title = balanceId ? __(T.scales.edit) : __(T.scales.create_new)
    const fragment = !loader ? getFragmentObject({ id: balanceId }, 'id', __(T.scales.create_new)) : undefined
    return (
      <StylewherePage {...this.props} noOverflow fragment={fragment}>
        <DefaultHeader
          backPath={getBackURL(queryString, breadcrumbs)}
          title={loader ? '...' : title}
          skeleton={{ active: loader }}
          actions={[
            {
              label: __(T.misc.cancel),
              type: 'cancel',
              onClick: () => navigate(`/devices/balances${queryString ?? ''}`),
            },
            {
              label: balanceId ? __(T.misc.update) : __(T.misc.create),
              type: 'submit',
              formName: formName,
            },
          ]}
        />
        <Section customClass="stw-section-page paged-header scroll">
          <AdvancedBalanceForm
            formWidth="100%"
            balanceId={balanceId}
            onSave={this.onSave}
            setFormName={this.setFormName}
          />
        </Section>
      </StylewherePage>
    )
  }
}

export default BalanceForm
