import { AdvancedTableRowColumn } from '../../components'
import { __ } from '../../shared/i18n'
import {
  AdvancedFormAttributeType,
  AdvancedFormField,
  AdvancedFormFieldBuilder,
  AdvancedFormInputType,
} from '../../components/AdvancedForm/advancedFormTypes'
import {
  AdvancedTableColumn,
  AdvancedTableContentKind,
  AdvancedTableFilterKind,
  AdvancedTableFilter,
} from '../../components/AdvancedTable'
import { DetailCell } from '../../api'
import { CAPABILITIES, getActionsTableColumns } from '../utility/capabilityUtility'

export function getTagOptionsColor(value: string) {
  if (value === 'STOCK') return { background: '#FF0000', color: '#FFF' }
  return { background: '#FFCD6C', color: '#755D2E' }
}

export function getZoneFilters(addPlace) {
  const filters: AdvancedTableFilter[] = [
    {
      key: 'equalCodes',
      label: __('fields.labels.code'),
      type: AdvancedTableFilterKind.STRING_LIST,
      column: 'code',
      col: 8,
    },
    {
      key: 'description',
      label: __('fields.labels.description'),
      type: AdvancedTableFilterKind.STRING,
      column: 'description',
      col: 8,
    },
    {
      key: 'type',
      label: __('fields.labels.type'),
      type: AdvancedTableFilterKind.ENUM,
      column: 'type',
      select: {
        option: 'types',
      },
      col: 8,
    },
  ]
  if (addPlace) {
    filters.push({
      key: 'placeIds',
      column: 'place',
      label: __('fields.labels.place'),
      type: AdvancedTableFilterKind.AUTOCOMPLETE,
      autocomplete: {
        endpoint: '/api/v1/places',
        sort: 'code,asc',
        multiple: true,
      },
      col: 8,
    })
  }
  filters.push(
    {
      key: 'creationDate',
      label: __('fields.labels.creationDate'),
      type: AdvancedTableFilterKind.DATE_RANGE,
      column: 'creationDate',
      col: 8,
    },
    {
      key: 'lastModifyDate',
      label: __('fields.labels.lastModifyDate'),
      type: AdvancedTableFilterKind.DATE_RANGE,
      column: 'lastModifyDate',
      col: 8,
    }
  )
  return filters
}

export const ZONE_FIELDS: AdvancedFormField[] = [
  AdvancedFormFieldBuilder.buildText('description'),
  {
    key: 'code',
    type: AdvancedFormInputType.UNIQUE_TEXT,
    label: __('fields.labels.code'),
    placeholder: __('fields.placeholders.code'),
    rules: [{ required: true }],
    unique: {
      endpoint: '/api/v1/zones',
      parameter: 'equalCodes',
    },
  },
  {
    key: 'place',
    type: AdvancedFormInputType.AUTOCOMPLETE,
    label: __('fields.labels.place'),
    placeholder: __('fields.placeholders.place'),
    autocomplete: {
      endpoint: '/api/v1/places',
      sort: 'code,asc',
      propertyId: 'id',
      multiple: false,
    },
    rules: [{ required: true }],
  },
  AdvancedFormFieldBuilder.buildSelect(
    'type',
    'types',
    [{ required: true }],
    undefined,
    undefined,
    undefined,
    __(`fields.placeholders.type`)
  ),
  {
    key: 'defaultZone',
    type: AdvancedFormInputType.SWITCH,
  },
  AdvancedFormFieldBuilder.buildAttribute('attributes', AdvancedFormAttributeType.SIMPLE, false, 'zone'),
]

export function getZoneColumns(addPlace) {
  const cols: AdvancedTableColumn[] = [
    {
      title: __(`fields.labels.code`),
      key: 'code',
      dataIndex: 'code',
      fixedType: 'left',
    },
    {
      title: __(`fields.labels.description`),
      key: 'description',
      dataIndex: 'description',
      className: 'stw-extra-medium',
    },
    {
      title: __(`fields.labels.type`),
      key: 'type',
      dataIndex: 'type',
      className: 'stw-small',
    },
  ]
  if (addPlace) {
    cols.push({
      title: __(`fields.labels.place`),
      key: 'place',
      dataIndex: 'place',
      sortable: false,
      type: AdvancedTableContentKind.CODE_DESC,
    })
  }
  cols.push(
    {
      key: 'defaultZone',
      title: __(`fields.labels.defaultZone`),
      dataIndex: 'defaultZone',
      type: AdvancedTableContentKind.BOOLEAN,
      className: 'stw-small',
    },
    {
      key: 'creationDate',
      title: __(`fields.labels.creationDate`),
      dataIndex: 'creationDate',
      type: AdvancedTableContentKind.DATE_TIME,
    },
    {
      key: 'lastModifyDate',
      title: __(`fields.labels.lastModifyDate`),
      dataIndex: 'lastModifyDate',
      type: AdvancedTableContentKind.DATE_TIME,
    },
    {
      key: 'action',
      width: 'small',
      fixedType: 'right',
      className: 'stw-small',
      sortable: false,
      unmanageable: true,
      render: (text, record) => (
        <AdvancedTableRowColumn
          actions={getActionsTableColumns(record.id, '/configuration/zones', CAPABILITIES.RESOURCE_ZONE_EDIT)}
        />
      ),
    }
  )
  return cols
}

export const ZONE_DETAIL: DetailCell[] = [
  { title: 'id', attribute: 'id', columns: 24 },
  { title: __(`fields.labels.code`), attribute: 'code', columns: 12 },
  { title: __(`fields.labels.description`), attribute: 'description', columns: 12 },
  { title: __(`fields.labels.type`), attribute: 'type', columns: 12 },
  { title: __(`fields.labels.place`), attribute: 'place', columns: 12, type: 'codeDesc' },
  { title: __(`fields.labels.defaultZone`), attribute: 'defaultZone', columns: 12, type: 'boolean' },
  { title: __(`fields.labels.creationDate`), attribute: 'creationDate', columns: 12, type: 'date' },
  { title: __(`fields.labels.lastModifyDate`), attribute: 'lastModifyDate', columns: 12, type: 'date' },
]
