import { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Page } from '../components'

export default class Dashboard extends Component<RouteComponentProps> {
  render() {
    const { location, history, match } = this.props
    return (
      <Page location={location} history={history} match={match}>
        Dashboard
      </Page>
    )
  }
}
