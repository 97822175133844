import { AdvancedTableRowColumn } from '../../../components'
import { __ } from '../../../shared/i18n'
import {
  AdvancedFormField,
  AdvancedFormFieldBuilder,
  AdvancedFormInputType,
  AdvancedFormAttributeType,
} from '../../../components/AdvancedForm/advancedFormTypes'
import {
  AdvancedTableColumn,
  AdvancedTableContentKind,
  AdvancedTableFilterKind,
  AdvancedTableFiltersDef,
} from '../../../components/AdvancedTable'
import { DetailCell } from '../../../api'
import { CAPABILITIES, getActionsTableColumns } from '../../utility/capabilityUtility'

export const TYPES_FILTERS: AdvancedTableFiltersDef = [
  {
    key: 'equalCodes',
    label: __('fields.labels.code'),
    type: AdvancedTableFilterKind.STRING_LIST,
    column: 'code',
    col: 8,
  },
  {
    key: 'description',
    label: __('fields.labels.description'),
    type: AdvancedTableFilterKind.STRING,
    column: 'description',
    col: 8,
  },
  {
    key: 'creationDate',
    label: __('fields.labels.creationDate'),
    type: AdvancedTableFilterKind.DATE_RANGE,
    column: 'creationDate',
    col: 8,
  },
  {
    key: 'lastModifyDate',
    label: __('fields.labels.lastModifyDate'),
    type: AdvancedTableFilterKind.DATE_RANGE,
    column: 'lastModifyDate',
    col: 8,
  },
]

export const TYPES_FIELDS: AdvancedFormField[] = [
  AdvancedFormFieldBuilder.buildText('description'),
  {
    key: 'code',
    type: AdvancedFormInputType.UNIQUE_TEXT,
    label: __('fields.labels.code'),
    placeholder: __('fields.placeholders.code'),
    rules: [{ required: true }],
    unique: {
      endpoint: '/api/v1/enumerationtypes',
      parameter: 'equalCodes',
    },
  },
  AdvancedFormFieldBuilder.buildAttribute('attributeKeys', AdvancedFormAttributeType.ENUMERATION, true),
]

export const TYPES_COLUMNS: AdvancedTableColumn[] = [
  {
    title: __(`fields.labels.code`),
    key: 'code',
    dataIndex: 'code',
    className: 'stw-extra-medium',
  },
  {
    title: __(`fields.labels.description`),
    key: 'description',
    dataIndex: 'description',
    className: 'stw-large',
  },
  {
    key: 'creationDate',
    title: __(`fields.labels.creationDate`),
    dataIndex: 'creationDate',
    type: AdvancedTableContentKind.DATE_TIME,
  },
  {
    key: 'lastModifyDate',
    title: __(`fields.labels.lastModifyDate`),
    dataIndex: 'lastModifyDate',
    type: AdvancedTableContentKind.DATE_TIME,
  },
  {
    key: 'action',
    className: 'stw-small',
    fixedType: 'right',
    sortable: false,
    unmanageable: true,
    render: (text, record) => (
      <AdvancedTableRowColumn
        actions={getActionsTableColumns(
          record.id,
          '/configuration/enumerations',
          CAPABILITIES.RESOURCE_ENUMERATION_TYPE_EDIT
        )}
      />
    ),
  },
]

export const TYPES_DETAIL: DetailCell[] = [
  { title: 'Id', attribute: 'id', columns: 12 },
  { title: __(`fields.labels.code`), attribute: 'code', columns: 12 },
  { title: __(`fields.labels.description`), attribute: 'description', columns: 12 },
  { title: __(`fields.labels.creationDate`), attribute: 'creationDate', type: 'date', columns: 12 },
  { title: __(`fields.labels.lastModifyDate`), attribute: 'lastModifyDate', type: 'date', columns: 12 },
]
