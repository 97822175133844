import { Component } from 'react'
import { GridData, StylewherePage, DefaultHeader, NotFound, Section } from '..'
import { StwReportQueryResult, DetailCell, StwReportColumnMetadata } from '../../api'
import { __, T } from '../../shared/i18n'
import Reports from '../../api/Reports'
import { AdvancedTableContentKind } from '../AdvancedTable'
import { ExtendRouteComponentProps } from '../../types'
import { getBackURL, getReportDescription } from '../../shared/utils'
import { SkeletonAttributeReport } from '../../constants'

interface State {
  reportInstance?: StwReportQueryResult
  configuration: DetailCell[]
  title?: string
  loader: boolean
}

interface Props extends ExtendRouteComponentProps {
  reportId: string
  reportInstanceId: string
}

export class DefaultReportDetail extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      reportInstance: undefined,
      loader: true,
      configuration: SkeletonAttributeReport,
    }
  }

  componentDidMount = async () => {
    const { reportId, reportInstanceId } = this.props
    const params = {}
    let reportInstance
    let configuration = SkeletonAttributeReport
    let description = ''
    if (reportInstanceId) {
      reportInstance = await this.getReportInstance(reportId, reportInstanceId, params)
      if (reportInstance && !reportInstance.errorId) {
        configuration = await this.getConfiguration(reportId, params)
        description = reportInstance ? getReportDescription(reportInstance) : ' '
      }
    }
    this.setState({
      reportInstance: reportInstance,
      configuration: configuration,
      title: description,
      loader: false,
    })
  }

  getReportInstance = async (reportId: string, reportInstanceId: string, params: any = {}) => {
    const report = await Reports.reportInstance(reportId, reportInstanceId, params)
    return report
  }

  getConfiguration = async (reportId: string, params: any = {}) => {
    const reportMetadata = await Reports.metadata(reportId, params)
    const detail: DetailCell[] = []
    for (let c = 0; c < reportMetadata.columns.length; c++) {
      detail.push(this.reportColumnMetadataToDetailCell(reportMetadata.columns[c]))
    }
    return detail
  }

  reportColumnMetadataToDetailCell(reportColumnMetadata: StwReportColumnMetadata): DetailCell {
    return {
      title: reportColumnMetadata.description ?? __(`fields.labels.${reportColumnMetadata.parameter}`),
      attribute: reportColumnMetadata.parameter,
      columns: 8,
      type: this.advancedTableContentKindToDetailCellType(reportColumnMetadata.type),
    }
  }

  advancedTableContentKindToDetailCellType(advancedTableContentKind: AdvancedTableContentKind) {
    switch (advancedTableContentKind) {
      case AdvancedTableContentKind.STRING:
      case AdvancedTableContentKind.MULTIPLE_STRING:
      case AdvancedTableContentKind.IDENTIFIERS:
      case AdvancedTableContentKind.STATUS:
      case AdvancedTableContentKind.NUMBER:
        return 'string'
      case AdvancedTableContentKind.DATE:
      case AdvancedTableContentKind.DATE_TIME:
        return 'date'
      case AdvancedTableContentKind.BOOLEAN:
        return 'boolean'
      default:
        return undefined
    }
  }

  render() {
    const { reportInstance, loader, configuration, title } = this.state
    const { breadcrumbs, queryString } = this.props
    const fragment = title ? [{ label: title, active: true }] : []
    return (
      <StylewherePage {...this.props} noOverflow fragment={fragment}>
        <DefaultHeader
          backPath={getBackURL(queryString, breadcrumbs)}
          title={title}
          skeleton={{ active: loader, options: { multiple: true } }}
        />
        <Section customClass={`stw-section-page paged-header ${reportInstance || loader ? 'scroll transparent' : ''}`}>
          {reportInstance || loader ? (
            <>
              <GridData fields={configuration} record={loader ? [] : reportInstance} skeleton={loader} />
            </>
          ) : (
            <NotFound fullheight title={__(T.misc.noRecordFoundTitle)} />
          )}
        </Section>
      </StylewherePage>
    )
  }
}
