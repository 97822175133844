import { Button, Row } from 'antd'
import { Component } from 'react'
import { T, __ } from '../../shared/i18n'

interface Props {
  close: () => void
  mTop: number
  confirmType?: any
  save?: () => void
  pb?: number
}

export class ModalAddButton extends Component<Props> {
  render() {
    const { close, mTop, confirmType, save, pb } = this.props
    return (
      <Row
        className="stw-modal-button ant-modal-footer"
        justify="center"
        style={{ marginTop: mTop, paddingBottom: pb || 30 }}
      >
        <Button style={{ marginRight: 0 }} className="ant-btn" onClick={() => close()}>
          {__(T.misc.cancel)}
        </Button>
        {confirmType ? (
          <Button className="ant-btn ant-btn-primary" htmlType={confirmType} onClick={() => (save ? save() : {})}>
            {__(T.misc.confirm)}
          </Button>
        ) : (
          <Button style={{ boxShadow: 'none' }} className="ant-btn ant-btn-primary" htmlType="submit">
            {__(T.misc.confirm)}
          </Button>
        )}
      </Row>
    )
  }
}
