import { Component } from 'react'
import { Button, Card, Row } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { StwFullRfidAntenna, StwFullRfidReader } from '../../api'
import AdvancedFormWorkstationRfidAntennasFieldCard from './AdvancedFormWorkstationRfidAntennasFieldCard'
import RfidAntennas from '../../api/RfidAntennas'
import { __ } from '../../shared/i18n'

type RfidAntennasByRfidReaderMap = {
  [rfidReaderId: string]: RfidAntennasByRfidReader
}

type RfidAntennasByRfidReader = { rfidReader: StwFullRfidReader; rfidAntennas: StwFullRfidAntenna[] }

interface Props {
  rfidAntennaIds: string[]
  handleChange: (value: any) => void
  onAddRfidAntenna: () => void
}

interface State {
  rfidAntennas: StwFullRfidAntenna[]
  rfidAntennaIds: string[]
}

export default class AdvancedFormWorkstationRfidAntennasFieldCardList extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      rfidAntennas: [],
      rfidAntennaIds: [],
    }
  }

  componentDidMount() {
    this.loadAntennas(this.props.rfidAntennaIds)
  }

  shouldComponentUpdate = (nextProps) => {
    const { rfidAntennaIds, rfidAntennas } = this.state
    if (
      JSON.stringify(rfidAntennaIds) !== JSON.stringify(nextProps.rfidAntennaIds) ||
      nextProps.rfidAntennaIds.length !== rfidAntennas.length
    ) {
      this.loadAntennas(nextProps.rfidAntennaIds)
    }
    return true
  }

  async componentDidUpdate(prevProps: Readonly<Props>) {
    const currentRfidAntennaIds = this.props.rfidAntennaIds
    const previousRfidAntennaIds = prevProps.rfidAntennaIds
    if (AdvancedFormWorkstationRfidAntennasFieldCardList.different(currentRfidAntennaIds, previousRfidAntennaIds)) {
      await this.updateRfidAntennas(this.props.rfidAntennaIds)
    }
  }

  loadAntennas = async (rfidAntennaIds) => {
    await this.updateRfidAntennas(rfidAntennaIds)
  }

  private removeRfidAntenna(rfidAntennaToRemove: StwFullRfidAntenna): void {
    const { rfidAntennaIds, handleChange } = this.props
    if (rfidAntennaToRemove) {
      const updatedRfidAntennaIds = rfidAntennaIds.filter((rfidAntennaId) => rfidAntennaId !== rfidAntennaToRemove.id)
      handleChange(updatedRfidAntennaIds)
    }
  }

  private removeRfidReader(rfidReaderId: string): void {
    const { handleChange } = this.props
    const { rfidAntennas } = this.state
    const updatedRfidAntennaIds = rfidAntennas
      .filter((rfidAntenna) => rfidAntenna.reader && rfidAntenna.reader.id !== rfidReaderId)
      .map((rfidAntenna) => rfidAntenna.id)
    handleChange(updatedRfidAntennaIds)
  }

  private static hasValidRfidReader(rfidAntenna: StwFullRfidAntenna): boolean {
    return rfidAntenna.reader !== undefined && rfidAntenna.reader.id !== undefined
  }

  private static different<T>(first: T[], latter: T[]): boolean {
    return !AdvancedFormWorkstationRfidAntennasFieldCardList.same(first, latter)
  }

  private static same<T>(first: T[], latter: T[]): boolean {
    return (
      AdvancedFormWorkstationRfidAntennasFieldCardList.containsAll(first, latter) &&
      AdvancedFormWorkstationRfidAntennasFieldCardList.containsAll(latter, first)
    )
  }

  private static containsAll<T>(first: T[], latter: T[]): boolean {
    return first.every((firstElement) => latter.includes(firstElement))
  }

  private async updateRfidAntennas(rfidAntennaIds: string[]): Promise<void> {
    if (rfidAntennaIds.length > 0) {
      const rfidAntennas = await RfidAntennas.searchFull({
        ids: rfidAntennaIds.length !== 0 ? rfidAntennaIds : [' '],
      }).then((response) => (response ? response.content : []))
      this.setState({ rfidAntennas: rfidAntennas, rfidAntennaIds: rfidAntennaIds })
    } else {
      this.setState({ rfidAntennas: [], rfidAntennaIds: [] })
    }
  }

  private static getRfidAntennasByRfidReader(rfidAntennas: StwFullRfidAntenna[]): RfidAntennasByRfidReader[] {
    return Object.values(
      rfidAntennas
        .filter(AdvancedFormWorkstationRfidAntennasFieldCardList.hasValidRfidReader)
        .reduce((rfidAntennasByRfidReader, currentRfidAntenna) => {
          const currentRfidReader = currentRfidAntenna.reader!
          const currentRfidReaderId = currentRfidReader.id!
          rfidAntennasByRfidReader[currentRfidReaderId] = rfidAntennasByRfidReader[currentRfidReaderId] || {
            rfidReader: currentRfidReader,
            rfidAntennas: [],
          }
          rfidAntennasByRfidReader[currentRfidReaderId].rfidAntennas.push(currentRfidAntenna)
          return rfidAntennasByRfidReader
        }, {} as RfidAntennasByRfidReaderMap)
    )
  }

  render() {
    return AdvancedFormWorkstationRfidAntennasFieldCardList.getRfidAntennasByRfidReader(this.state.rfidAntennas).map(
      ({ rfidReader, rfidAntennas }) => (
        <Card
          key={rfidReader.id}
          className="stw-grouping-card"
          title={
            <>
              <b>{rfidReader.code}</b> <br /> {__('fields.labels.type')}: <b>{rfidReader.deviceType}</b>
              {rfidReader.ipAddress && ` - ${__('fields.labels.ipAddress')} `}
              {rfidReader.ipAddress && <b>{rfidReader.ipAddress}</b>}
            </>
          }
          extra={
            <>
              <Button
                type="default"
                shape="circle"
                icon={<CloseOutlined />}
                onClick={() => this.removeRfidReader(rfidReader.id!)}
              />
            </>
          }
          bodyStyle={{ backgroundColor: '#ececec' }}
          style={{ marginBottom: '25px' }}
        >
          <Row gutter={24}>
            {Array.from({ length: 4 }, (_, rfidAntennaIndex) => (
              <AdvancedFormWorkstationRfidAntennasFieldCard
                key={rfidAntennaIndex}
                rfidAntenna={rfidAntennas[rfidAntennaIndex]}
                onRemove={() => this.removeRfidAntenna(rfidAntennas[rfidAntennaIndex])}
                onAdd={() => this.props.onAddRfidAntenna()}
              />
            ))}
          </Row>
        </Card>
      )
    )
  }
}
