import BaseResource from './BaseResource'
import { StwFullInventoryParcel, StwInventoryParcel, StwOperation, StwPlace, StwUser, StwZone } from './types'
import Places from './Places'
import Users from './Users'
import Zones from './Zones'
import Operations from './Operations'

export default class InventoryParcels extends BaseResource {
  static endpoint = '/api/v1/inventoryParcels' ///logistics

  static async getFull(inventoryParcelId: string): Promise<StwFullInventoryParcel> {
    const { placeId, creationUserId, closeUserId, zoneIds, operationId, ignoreZoneIds, ...inventoryParcel } =
      await this.get<StwInventoryParcel>(inventoryParcelId)
    const place = placeId ? await Places.get<StwPlace>(placeId) : undefined
    const creationUser = creationUserId ? await Users.get<StwUser>(creationUserId) : undefined
    const closeUser = closeUserId ? await Users.get<StwUser>(closeUserId) : undefined
    const zones = await Zones.searchByIds<StwZone>([...(zoneIds || []), ...(ignoreZoneIds || [])])
    const operation = operationId ? await Operations.get<StwOperation>(operationId) : undefined
    return {
      ...inventoryParcel,
      place: place,
      creationUser: creationUser,
      closeUser: closeUser,
      zones: zoneIds ? zoneIds.map((zoneId) => zones[`${zoneId}`]) : [],
      operation: operation,
      ignoreZones: ignoreZoneIds ? ignoreZoneIds.map((ignoreZoneId) => zones[`${ignoreZoneId}`]) : [],
    }
  }
}
