import BaseResource from './BaseResource'
import {
  StwAsn,
  StwChecklist,
  StwParcelEntry,
  StwFullParcel,
  StwOperation,
  StwParcel,
  StwPlace,
  StwUser,
  StwZone,
} from './types'
import Places from './Places'
import Users from './Users'
import Operations from './Operations'
import Zones from './Zones'
import Asns from './Asns'
import Checklists from './Checklists'

export default class Parcels extends BaseResource {
  static endpoint = '/api/v1/parcels' ///logistics

  static async getFullParcel(recordId: string): Promise<StwFullParcel> {
    const parcel = await this.get<StwParcel>(recordId)

    const places = await Places.searchByIds<StwPlace>([parcel.clientPlaceId])
    const checklists = await Checklists.searchByIds<StwChecklist>([parcel.checklistId])
    const asns = await Asns.searchByIds<StwAsn>([parcel.asnId])
    const zones = await Zones.searchByIds<StwZone>([parcel.zoneId])
    const users = await Users.searchByIds<StwUser>([parcel.creationUserId, parcel.lastModifyUserId])
    const operations = await Operations.searchByIds<StwOperation>([parcel.operationId])

    return {
      id: parcel.id,
      code: parcel.code,
      description: parcel.description,
      creationUser: users[`${parcel.creationUserId}`],
      lastModifyUser: users[`${parcel.creationUserId}`],
      operation: operations[`${parcel.operationId}`],
      shippingDate: parcel.shippingDate,
      receivingDate: parcel.receivingDate,
      palletCode: parcel.palletCode,
      clientPlace: parcel.clientPlaceId ? places[`${parcel.clientPlaceId}`] : undefined,
      weight: parcel.weight,
      state: parcel.state,
      zone: parcel.zoneId ? zones[`${parcel.zoneId}`] : undefined,
      attributes: parcel.attributes,
      asn: asns[`${parcel.asnId}`],
      checklist: parcel.checklistId ? checklists[`${parcel.checklistId}`] : undefined,
      errorMessage: parcel.errorMessage,
      parcelEntryQuantity: parcel.parcelEntryQuantity,
      creationDate: parcel.creationDate,
      lastModifyDate: parcel.lastModifyDate,
    }
  }

  static async getParcelEntries(recordId: string) {
    return this.get<StwParcelEntry>(`${recordId}/parcelEntries`)
  }
}
