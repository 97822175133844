import { Component } from 'react'
import { Space } from 'antd'
import { StylewherePage, TableList, AdvancedTableRowColumn, ModalContentList } from '../../../../components'
import { CAPABILITY_GROUPS_FILTERS } from '../../../../config/Pages/UsersRoles/CapabilitiesGroup'
import { CapabilitiesGroupFeatureTypes } from '../../../../constants'
import { __, T } from '../../../../shared/i18n'
import {
  AdvancedTableConfig,
  AdvancedTableColumn,
  AdvancedTableContentKind,
} from '../../../../components/AdvancedTable'
import { ExtendRouteComponentProps } from '../../../../types'
import { CAPABILITIES, getActionsList, getActionsTableColumns } from '../../../../config/utility/capabilityUtility'
import CapabilitiesGroup from '../../../../api/CapabilitiesGroup'
import { getTabs } from '../../../../config/Pages/UsersRoles/Tabs'
import { StwCapabilitiesGroup, StwCapability } from '../../../../api'

const TABLE_CONFIG: AdvancedTableConfig = {
  filterDefinitions: CAPABILITY_GROUPS_FILTERS,
  filterParameters: { types: CapabilitiesGroupFeatureTypes },
  manageColumnsPrefix: 'capabilitiesGroup',
  activeDefaultClassColumn: true,
}

interface State {
  modalDetailVisible: boolean
  modalDetailList: StwCapability[]
  loadColumns: boolean
}

class CapabilitiesGroupList extends Component<ExtendRouteComponentProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      modalDetailVisible: false,
      modalDetailList: [],
      loadColumns: false,
    }
  }

  componentDidMount() {
    this.setState({ loadColumns: true })
    TABLE_CONFIG.columns = this.getTableColumns()
  }

  getTableColumns = () => {
    const t: AdvancedTableColumn[] = [
      {
        key: 'code',
        dataIndex: 'code',
        title: __('fields.labels.code'),
      },
      {
        key: 'description',
        dataIndex: 'description',
        title: __('fields.labels.description'),
      },
      {
        key: 'featureType',
        dataIndex: 'featureType',
        title: __('fields.labels.featureType'),
      },
      {
        key: 'capabilities',
        dataIndex: 'capabilities',
        title: __('fields.labels.capabilities'),
        sortable: false,
        render: (text, record: StwCapabilitiesGroup) => {
          return (
            <Space
              style={{ display: 'block', cursor: 'pointer' }}
              onClick={() => this.showCapabilitiesDetail(record.capabilities)}
            >
              <div>{record.capabilities ? record.capabilities.length : 0}</div>
            </Space>
          )
        },
      },
      {
        key: 'creationDate',
        dataIndex: 'creationDate',
        title: __('fields.labels.creationDate'),
        type: AdvancedTableContentKind.DATE_TIME,
      },
      {
        key: 'lastModifyDate',
        dataIndex: 'lastModifyDate',
        title: __('fields.labels.lastModifyDate'),
        type: AdvancedTableContentKind.DATE_TIME,
      },
      {
        key: 'action',
        className: 'stw-small',
        fixedType: 'right',
        sortable: false,
        unmanageable: true,
        render: (text, record) => (
          <AdvancedTableRowColumn
            actions={getActionsTableColumns(
              record.id,
              '/configuration/usersRoles/capabilitiesGroup',
              CAPABILITIES.RESOURCE_CAPABILITY_GROUP_EDIT
            )}
          />
        ),
      },
    ]
    return t
  }

  showCapabilitiesDetail = (capabilities) => {
    this.setState({
      modalDetailVisible: true,
      modalDetailList: capabilities,
    })
  }

  hideCapabilitiesDetail = () => {
    this.setState({
      modalDetailVisible: false,
    })
  }

  render() {
    const { modalDetailList, modalDetailVisible, loadColumns } = this.state
    return (
      <StylewherePage {...this.props} noOverflow>
        {loadColumns && (
          <TableList
            tabs={getTabs(2)}
            location={this.props.location}
            config={TABLE_CONFIG}
            resource={{
              call: CapabilitiesGroup.searchTableList,
              endpoint: CapabilitiesGroup.endpoint,
            }}
            actions={getActionsList(
              CAPABILITIES.RESOURCE_CAPABILITY_GROUP_EDIT,
              __(T.capabilitiesGroup.create_new),
              '/configuration/usersRoles/capabilitiesGroup/create/form'
            )}
          />
        )}
        <ModalContentList
          visible={modalDetailVisible}
          title={__('fields.labels.capabilities')}
          attributeCode="code"
          entities={modalDetailList}
          callback={() => this.hideCapabilitiesDetail()}
        />
      </StylewherePage>
    )
  }
}

export default CapabilitiesGroupList
