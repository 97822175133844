/* eslint-disable func-names */
import { Component } from 'react'
import { Form, Space, Switch, Typography } from 'antd'
import { AttributeUtil } from '../../config/utility/utility'
import { __ } from '../../shared/i18n'
import { AdvancedFormField } from './advancedFormTypes'

interface Props {
  record: any
  item: AdvancedFormField
  handleChange: any
  labelHidden?: boolean
}

const { Text } = Typography

export class AdvancedFormSwitchField extends Component<Props> {
  render() {
    const { record, item, handleChange, labelHidden } = this.props
    const checked = AttributeUtil.getAttribute(record, item.key)
    return (
      <Form.Item
        name={item.key.split('.')}
        className="stylewhere-form-item"
        rules={item.rules}
        label={!labelHidden ? item.label || __(`fields.labels.${item.key}`) : null}
      >
        <Space style={{ alignItems: 'center', width: '100%' }}>
          <Switch onChange={(e) => handleChange(e)} checked={checked} />
          <Text>{checked ? __('misc.enabled') : __('misc.disabled')}</Text>
        </Space>
      </Form.Item>
    )
  }
}
