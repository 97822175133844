import { Component } from 'react'
import { GridData, StylewherePage, DefaultHeader, NotFound, Section } from '../../../components'
import { StwFullRfidAntenna, StwRfidAntenna, StwZone } from '../../../api'
import { __, T } from '../../../shared/i18n'
import { ExtendRouteComponentProps } from '../../../types'
import RfidAntennas from '../../../api/RfidAntennas'
import Zones from '../../../api/Zones'
import { ANTENNAS_DATA } from '../../../config/Pages/Devices/RfidAntennas'
import { getMultipleTitle, getBackURL, getFragmentObject } from '../../../shared/utils'

interface State {
  antenna: StwFullRfidAntenna
  loader: boolean
}

class RfidAntennaDetail extends Component<ExtendRouteComponentProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      antenna: {},
      loader: true,
    }
  }

  componentDidMount = async () => {
    const { match } = this.props
    let fullAntenna
    if (match) {
      if (match.params.antennaId) {
        const antenna = await RfidAntennas.get<StwRfidAntenna>(match.params.antennaId).then((res) => res)
        if (antenna && !antenna.errorId) {
          fullAntenna = await this.mapAntennaToFullAntenna(antenna)
        }
      }
    }
    this.setState({
      antenna: fullAntenna,
      loader: false,
    })
  }

  mapAntennaToFullAntenna = async (antenna: StwRfidAntenna) => {
    const zone = await Zones.get<StwZone>(antenna.zoneId)
    return {
      id: antenna.id,
      code: antenna.code,
      description: antenna.description,
      reader: antenna.reader,
      readerPort: antenna.readerPort,
      txPower: antenna.txPower,
      rxSensitivity: antenna.rxSensitivity,
      creationDate: antenna.creationDate,
      lastModifyDate: antenna.lastModifyDate,
      zone: zone,
    }
  }

  render() {
    const { breadcrumbs, queryString } = this.props
    const { antenna, loader } = this.state
    const fragment = getFragmentObject(antenna, 'code')
    return (
      <StylewherePage {...this.props} noOverflow fragment={fragment}>
        <DefaultHeader
          backPath={getBackURL(queryString, breadcrumbs)}
          multipleTitle={getMultipleTitle(antenna)}
          title={__(T.misc.not_found)}
          skeleton={{ active: loader, options: { multiple: true } }}
        />
        <Section customClass={`stw-section-page paged-header ${antenna || loader ? 'scroll transparent' : ''}`}>
          {antenna || loader ? (
            <GridData fields={ANTENNAS_DATA} record={antenna || {}} skeleton={loader} />
          ) : (
            <NotFound fullheight title={__(T.misc.noRecordFoundTitle)} />
          )}
        </Section>
      </StylewherePage>
    )
  }
}

export default RfidAntennaDetail
