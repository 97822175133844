import BaseResource, { PageResult } from './BaseResource'
import { StwRfidReader, StwRfidReaderWithCounter, StwRfidAntenna } from './types'
import RfidAntennas from './RfidAntennas'

export default class RfidReaders extends BaseResource {
  static endpoint = '/api/v1/rfidReaders' ///edge

  static async searchFull(params = {}): Promise<PageResult<StwRfidReaderWithCounter>> {
    //antennas = await RfidAntennas.search<StwRfidAntenna>({ readerId: fullReader.id }).then((value) => value.content)
    const rfidReaders = await this.search<StwRfidReader>(params)
    const tmp: StwRfidReaderWithCounter[] = []
    for (let r = 0; r < rfidReaders.content.length; r++) {
      const obj: StwRfidReaderWithCounter = rfidReaders.content[r]
      // eslint-disable-next-line no-await-in-loop
      const num = await RfidAntennas.search<StwRfidAntenna>({ readerId: obj.id, size: 1 }).then(
        (value) => value.totalElements
      )
      obj.numAntennas = num
      tmp.push(obj)
    }
    return {
      ...rfidReaders,
      content: tmp,
    }
  }
}
