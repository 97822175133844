import { Form } from 'antd'
import { Component } from 'react'
import { __ } from '../../shared/i18n'
import { AdvancedTableColumns } from '../AdvancedTable'
import TableWithAutocompletion from '../Input/TableWithAutocompletion'
import { SectionTitle } from './SectionTitle'

interface Props {
  handleChange: (value: any) => void
  label?: string
  rules?: any[]
  name: string
  placeholder?: string
  selector?: string
  endpoint: string
  sort: string
  filters?: any
  uniqueValueError?: string
  columns: AdvancedTableColumns
  canDelete?: boolean
  mapSelection?: (currentObject: any) => Promise<any>
}

export class AdvancedFormInputObjectList extends Component<Props> {
  render() {
    const {
      handleChange,
      rules,
      name,
      placeholder,
      endpoint,
      sort,
      filters,
      uniqueValueError,
      columns,
      canDelete,
      mapSelection,
    } = this.props
    return (
      <Form.Item
        name={name.split('.')}
        rules={rules}
        label={<SectionTitle noBorder title={__(`misc.${name}_title`)} marginBottom={20} />}
        className="stylewhere-form-item"
      >
        <TableWithAutocompletion
          onChange={handleChange}
          placeholder={placeholder ?? __(`fields.placeholders.${this.props.name}`)}
          endpoint={endpoint}
          sort={sort}
          filters={filters}
          uniqueValueError={uniqueValueError}
          columns={columns}
          canDelete={canDelete}
          mapSelection={mapSelection}
        />
      </Form.Item>
    )
  }
}
