import { Component } from 'react'
import { StylewherePage, TableList } from '../../../../components'
import { __, T } from '../../../../shared/i18n'
import { AdvancedTableConfig } from '../../../../components/AdvancedTable'
import { ExtendRouteComponentProps } from '../../../../types'
import { CAPABILITIES, getActionsList } from '../../../../config/utility/capabilityUtility'
import { getTabsSettings } from '../../../../config/Pages/Settings/Tabs'
import ApplicationSetting from '../../../../api/ApplicationSetting'
import { APPLICATION_COLUMNS, APPLICATION_FILTERS } from '../../../../config/Pages/Settings/ApplicationSettings'
import { ApplicationTypes, ApplicationClients, BooleanComboValues } from '../../../../constants'

const APPLICATION_SETTING_TABLE_CONFIG: AdvancedTableConfig = {
  columns: APPLICATION_COLUMNS,
  filterDefinitions: APPLICATION_FILTERS,
  filterParameters: {
    applicationTypes: ApplicationTypes,
    enabledValues: BooleanComboValues,
    applicationClients: ApplicationClients,
  },
  manageColumnsPrefix: 'applicationSettings',
  activeDefaultClassColumn: true,
}

class ApplicationSettingList extends Component<ExtendRouteComponentProps> {
  render() {
    return (
      <StylewherePage {...this.props} noOverflow>
        <TableList
          tabs={getTabsSettings(3)}
          location={this.props.location}
          config={APPLICATION_SETTING_TABLE_CONFIG}
          resource={{
            call: ApplicationSetting.searchTableList,
            endpoint: ApplicationSetting.endpoint,
          }}
          actions={getActionsList(
            CAPABILITIES.RESOURCE_APPLICATION_SETTING_EDIT,
            __(T.application.create_new),
            '/configuration/settings/applications/create/form'
          )}
        />
      </StylewherePage>
    )
  }
}

export default ApplicationSettingList
