/* eslint-disable func-names */
import { Component } from 'react'
import { Form, Select } from 'antd'
import { __ } from '../../shared/i18n'
import { AdvancedFormField } from './advancedFormTypes'

interface Props {
  record: any
  item: AdvancedFormField
  name?: any
  readonly: boolean
  handleChange: any
  labelHidden?: boolean
  handleApplyFilters?: () => void
  maxTagCount?: number
}

export class AdvancedFormStringListField extends Component<Props> {
  onInputKeyDown = (e) => {
    const { record, item } = this.props
    if (parseInt(e.keyCode, 10) === 13 && e.target.value === '') {
      const { handleApplyFilters } = this.props
      if (handleApplyFilters && record[item.key] && record[item.key].length > 0) handleApplyFilters()
      e.preventDefault()
      e.stopPropagation()
    }
  }

  render() {
    const { item, handleChange, readonly, labelHidden, maxTagCount } = this.props
    return (
      <Form.Item
        name={item.key.split('.')}
        className="stylewhere-form-item"
        rules={item.rules}
        label={labelHidden ? null : __(`fields.labels.${item.key}`, item.label)}
      >
        <Select
          mode="tags"
          maxTagCount={maxTagCount}
          disabled={readonly}
          popupClassName="stylewhere-dropdown-select-tags"
          style={{ width: '100%' }}
          tokenSeparators={[',']}
          placeholder={__(`fields.placeholders.${item.key}`, item.placeholder)}
          onInputKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => this.onInputKeyDown(e)}
          allowClear
          onChange={(value) => handleChange(value)}
        />
      </Form.Item>
    )
  }
}
