import { Component } from 'react'
import { Col, Row } from 'antd'
import { StylewherePage, Section, DefaultHeader, NotFound, KeyValueGrid, DynamicImage } from '../../../components'
import { DetailCell, StwProduct } from '../../../api'
import Products from '../../../api/Products'
import { __, T } from '../../../shared/i18n'
import { PRODUCT_IMAGE_DETAIL_SIZE } from '../../../constants'
import { PRODUCT_DETAILS } from '../../../config/Pages/Reports'
import {
  getMultipleTitle,
  getDetailDataWithAttributesMapping,
  getBackURL,
  getFragmentObject,
} from '../../../shared/utils'
import { ExtendRouteComponentProps } from '../../../types'
import AppStore from '../../../shared/AppStore'

interface State {
  record: StwProduct | undefined
  loader: boolean
  detailData: DetailCell[]
}

class ProductDetail extends Component<ExtendRouteComponentProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      record: undefined,
      loader: true,
      detailData: PRODUCT_DETAILS,
    }
  }

  componentDidMount() {
    this.initialize()
  }

  initialize = async () => {
    const { match } = this.props
    const data: DetailCell[] = await getDetailDataWithAttributesMapping(PRODUCT_DETAILS, 'product', 2, 'valueDesc', 12)
    let product: StwProduct = {}
    if (match && match.params && match.params.productId) {
      product = await Products.get<StwProduct>(match.params.productId).then((res) => res)
    }
    this.setState({
      record: product && product.id ? product : undefined,
      detailData: data,
      loader: false,
    })
  }

  render() {
    const { record, loader, detailData } = this.state
    const { breadcrumbs, queryString } = this.props
    const fragment = getFragmentObject(record, 'id')
    const ENABLED_PRODUCT_IMAGE = AppStore.getShowProductImage() && record
    return (
      <StylewherePage {...this.props} noOverflow fragment={fragment}>
        <DefaultHeader
          backPath={getBackURL(queryString, breadcrumbs)}
          multipleTitle={getMultipleTitle(record)}
          title={__(T.misc.not_found)}
          skeleton={{ active: loader, options: { multiple: true } }}
        />
        <Section customClass={`stw-section-page paged-header ${record || loader ? 'scroll transparent' : ''}`}>
          {record || loader ? (
            <Section>
              <Row>
                <Col span={ENABLED_PRODUCT_IMAGE ? 16 : 24}>
                  <KeyValueGrid data={record} fields={detailData || PRODUCT_DETAILS} skeleton={loader} />
                </Col>
                {ENABLED_PRODUCT_IMAGE && (
                  <Col span={8} style={{ paddingLeft: 20 }}>
                    <DynamicImage record={record} size={PRODUCT_IMAGE_DETAIL_SIZE} skeleton={loader} />
                  </Col>
                )}
              </Row>
            </Section>
          ) : (
            <NotFound fullheight title={__(T.misc.noRecordFoundTitle)} />
          )}
        </Section>
      </StylewherePage>
    )
  }
}

export default ProductDetail
