import { AxiosRequestConfig } from 'axios'
import BaseResource, { PageResult } from './BaseResource'
import {
  api,
  MovementType,
  responseErrorCheck,
  StwChecklist,
  StwFullInbound,
  StwFullParcelEntry,
  StwInbound,
  StwItem,
  StwOperation,
  StwParcel,
  StwParcelEntry,
  StwPlace,
  StwProduct,
  StwUser,
  StwZone,
} from '.'
import Parcels from './Parcels'
import Items from './Items'
import Products from './Products'
import Users from './Users'
import Places from './Places'
import Zones from './Zones'
import Operations from './Operations'
import Checklists from './Checklists'

export default class Inbounds extends BaseResource {
  static endpoint = '/api/v1/inbounds' // /logistics

  static async getByIdFull(id): Promise<StwFullInbound> {
    const inbound = await this.get<StwInbound>(id)
    const user = await Users.get<StwUser>(inbound.creationUserId)
    const places = await Places.searchByIds<StwPlace>([inbound.destinationPlaceId, inbound.originPlaceId])
    const zone = await Zones.get<StwZone>(inbound.destinationZoneId)
    const operation = await Operations.get<StwOperation>(inbound.operationId)
    const entries: PageResult<StwParcelEntry> = await Inbounds.parcelEntries(inbound.id, { size: 10000000 })

    let totalDetected = 0
    let totalUnexpected = 0
    let totalExpected = 0
    let checklist

    if (inbound.checklistId) {
      checklist = await Checklists.get<StwChecklist>(inbound.checklistId)
      if (checklist.itemIds) {
        totalExpected = checklist.itemIds.length
      } else {
        totalExpected = checklist.products.map((p) => p.quantity).reduce((q1, q2) => q1 + q2)
      }
    }
    entries.content.forEach((pe) => {
      totalDetected += pe.detected
      totalUnexpected += pe.unexpected
      const entry = entries.content.filter((e) => e.productCode === pe.productCode)[0]
      entry.detected += pe.detected
      entry.unexpected += pe.unexpected
    })

    return {
      ...inbound,
      type: MovementType.INBOUND,
      creationUser: user,
      destinationZone: zone,
      operation: operation,
      checklist: checklist,
      originPlace: places[`${inbound.originPlaceId}`],
      destinationPlace: places[`${inbound.destinationPlaceId}`],
      totalDetected: totalDetected,
      totalExpected: totalExpected,
      totalUnexpected: totalUnexpected,
    }
  }

  static async searchFull(params = {}): Promise<PageResult<StwFullInbound>> {
    const inbounds = await this.search<StwInbound>(params)
    const users = await Users.searchByIds<StwUser>(inbounds.content.map((pe) => pe.creationUserId))
    const places = await Places.searchByIds<StwPlace>([
      ...inbounds.content.map((pe) => pe.destinationPlaceId),
      ...inbounds.content.map((pe) => pe.originPlaceId),
    ])
    const zones = await Zones.searchByIds<StwZone>(inbounds.content.map((pe) => pe.destinationZoneId))
    const operations = await Operations.searchByIds<StwOperation>(inbounds.content.map((pe) => pe.operationId))
    const checklists = await Checklists.searchByIds<StwChecklist>(inbounds.content.map((pe) => pe.checklistId))
    const fullInbounds = inbounds.content.map((i) => {
      return Inbounds.parcelEntries(i.id, { size: 10000000 }).then((entries) => {
        let totalDetected = 0
        let totalUnexpected = 0
        let totalExpected = 0
        if (i.checklistId && checklists[`${i.checklistId}`]) {
          if (checklists[`${i.checklistId}`].itemIds) {
            totalExpected = checklists[`${i.checklistId}`].itemIds.length
          } else {
            totalExpected = checklists[`${i.checklistId}`].products.map((p) => p.quantity).reduce((q1, q2) => q1 + q2)
          }
        }
        entries.content.forEach((pe) => {
          totalDetected += pe.detected
          totalUnexpected += pe.unexpected
          const entry = entries.content.filter((e) => e.productCode === pe.productCode)[0]
          entry.detected += pe.detected
          entry.unexpected += pe.unexpected
        })
        return {
          ...i,
          type: MovementType.INBOUND,
          creationUser: users[`${i.creationUserId}`],
          destinationZone: zones[`${i.destinationZoneId}`],
          operation: operations[`${i.operationId}`],
          checklist: checklists[`${i.checklistId}`],
          originPlace: places[`${i.originPlaceId}`],
          destinationPlace: places[`${i.destinationPlaceId}`],
          totalDetected: totalDetected,
          totalExpected: totalExpected,
          totalUnexpected: totalUnexpected,
        }
      })
    })
    return {
      ...inbounds,
      content: await Promise.all(fullInbounds),
    }
  }

  static parcelEntries(
    id: string,
    params = {},
    requestConfig?: AxiosRequestConfig
  ): Promise<PageResult<StwParcelEntry>> {
    return api.get(`${this.endpoint}/${id}/parcelentries`, params, requestConfig).then(responseErrorCheck)
  }

  static async fullParcelentries(id: string, params = {}): Promise<PageResult<StwFullParcelEntry>> {
    const entries = await this.parcelEntries(id, params)
    const parcels = await Parcels.searchByIds<StwParcel>(entries.content.map((parcelEntry) => parcelEntry.parcelId))
    const items = await Items.searchByIds<StwItem>(entries.content.map((parcelEntry) => parcelEntry.itemId!))
    const products = await Products.searchByCodes<StwProduct>(
      entries.content.filter((parcelEntry) => parcelEntry.productCode).map((parcelEntry) => parcelEntry.productCode!)
    )
    const fullEntries: StwFullParcelEntry[] = entries.content.map(
      ({ parcelId, itemId, productCode, ...parcelEntry }) => ({
        ...parcelEntry,
        parcel: parcels[`${parcelId}`],
        item: items[`${itemId}`],
        product: products[`${productCode}`],
      })
    )
    return {
      ...entries,
      content: fullEntries,
    }
  }
}
