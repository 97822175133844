import { Component } from 'react'
import { Col, Row } from 'antd'
import { SectionTitle } from '.'

interface Props {
  title?: any
  style?: any
  children?: any
  fill?: boolean
  nomb?: boolean
  fullheight?: boolean
  noPaddingPage?: boolean
  scrollable?: boolean
  parentPadding?: number
  customClass?: string
}

export class Section extends Component<Props> {
  getClass = () => {
    const { fill, scrollable, nomb, fullheight, noPaddingPage, customClass } = this.props
    let sectionClass = 'stw-section-page'
    if (fill) sectionClass += ' fill'
    if (fullheight) sectionClass += ' fullheight'
    if (nomb) sectionClass += ' nomb'
    if (noPaddingPage) sectionClass += ' nopadding'
    if (scrollable) sectionClass += ' scrollable'
    if (customClass) sectionClass += ` ${customClass}`
    return sectionClass
  }

  render() {
    const { title, style, children, parentPadding } = this.props
    return (
      <div className={this.getClass()}>
        <Row style={{ ...style, textAlign: 'left' } || { textAlign: 'left' }}>
          {title && title !== null && title !== '' && (
            <Col span={24}>
              <SectionTitle title={title} marginBottom={1} parentPadding={parentPadding} />
            </Col>
          )}
          <Col span={24}>{children}</Col>
        </Row>
      </div>
    )
  }
}
