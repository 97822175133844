/* eslint-disable func-names */
import { Col, Row, Button, Typography } from 'antd'
import { Component } from 'react'
import {
  AdvancedFormAttributesHeader,
  AdvancedFormAttributesSingle,
  AdvancedFormModalAttribute,
  SectionTitle,
} from '..'
import { AttributeUtil } from '../../config/utility/utility'
import { __ } from '../../shared/i18n'
import {
  AdvancedFormAttribute,
  AdvancedFormAttributeType,
  AdvancedFormField,
  AdvancedFormInputType,
} from './advancedFormTypes'
import { StwAttribute } from '../../api'
import AttributeMappings from '../../api/AttributeMappings'
import { navigate } from '../../shared/router'
import {
  convertEnumerationAttributes,
  convertDetails,
  convertSimpleAttribute,
  convertProductAttribute,
} from '../../shared/utils'

interface Props {
  attribute: string
  item: AdvancedFormField
  title?: string
  subtitle?: string
  updateValue: (value) => void
  record: any
  parameters?: any
  subtype: AdvancedFormAttributeType
  entity?: string
}

interface State {
  attributes: AdvancedFormAttribute[]
  otherAttributes: AdvancedFormAttribute[]
  modalVisible: boolean
  attribute: AdvancedFormAttribute | null
  configuration: any
}

const { Text } = Typography

export class AdvancedFormAttributes extends Component<Props, State> {
  timerUpdates

  constructor(props) {
    super(props)
    this.state = {
      attributes: [],
      otherAttributes: [],
      attribute: null,
      modalVisible: false,
      configuration: {},
    }
  }

  componentDidMount() {
    this.initialize(this.props.record)
  }

  shouldComponentUpdate = (nextProps) => {
    const { record } = this.props
    const { attribute } = this.state
    if (attribute === null && JSON.stringify(record) !== JSON.stringify(nextProps.record)) {
      this.initialize(nextProps.record)
    }
    return true
  }

  getKeyColumn = (key, field, rules: any, acceptDuplicate = true, editable = true, option = '') => {
    return {
      key: key,
      field: field,
      label: __(`misc.${field}`),
      error: __(`misc.${field}Required`),
      placeholder: __(`fields.placeholders.${field}`),
      type: option === '' ? AdvancedFormInputType.TEXT : AdvancedFormInputType.SELECT,
      rules: rules,
      checkDuplicate: !acceptDuplicate,
      duplicate: __(`misc.${field}Duplicate`),
      option: option,
      editable: editable,
    }
  }

  initialize = async (record) => {
    const { subtype, attribute, item, entity } = this.props
    if (record) {
      let param = {}
      let tmp: AdvancedFormAttribute[] = []
      let otherAttrs: AdvancedFormAttribute[] = []
      if (subtype === AdvancedFormAttributeType.SIMPLE) {
        if (entity && entity !== '') {
          tmp = await this.getAttributeMapping()
          otherAttrs = convertSimpleAttribute(record[attribute], tmp)
        } else {
          tmp = convertSimpleAttribute(record[attribute], [])
        }
        param = {
          prefix: item.key || 'attributes',
          returnType: 'object',
          columns: [
            this.getKeyColumn('modalKey', 'key', [{ required: true }], false, false),
            this.getKeyColumn('modalValue', 'value', [{ required: !entity || entity === '' }]),
          ],
        }
      } else if (subtype === AdvancedFormAttributeType.IDENTIFIER) {
        param = {
          prefix: item.key || 'details',
          suffix: true,
          useIndex: true,
          returnType: 'array',
          columns: [
            this.getKeyColumn(
              'modalIdentifierType',
              'identifierType',
              [{ required: true }],
              true,
              false,
              'attributeKeys'
            ),
            this.getKeyColumn('modalRole', 'role', [
              {
                required: true,
                type: 'string',
                pattern: new RegExp(/^[A-Z_0-9]*$/),
                message: __('misc.allCaps'),
              },
            ]),
            {
              key: 'optional',
              field: 'optional',
              label: __(`misc.optional`),
              type: AdvancedFormInputType.SWITCH,
              editable: true,
              defaultValue: false,
              rules: [{ required: false }],
            },
            this.getKeyColumn('modalRegex', 'regexFilter', [
              {
                required: false,
                type: 'string',
              },
            ]),
          ],
        }
        tmp = convertDetails(AttributeUtil.getAttribute(record, attribute), param)
      } else if (subtype === AdvancedFormAttributeType.PRODUCT) {
        param = {
          prefix: item.key || 'attributes',
          suffix: true,
          returnType: 'object',
          columns: [
            this.getKeyColumn('modalKey', 'key', [{ required: true }], false, false),
            this.getKeyColumn('modalValue', 'value', [{ required: false }]),
            this.getKeyColumn('modalDescription', 'description', [{ required: false }]),
          ],
        }
        tmp = await this.getAttributeMapping()
        otherAttrs = convertProductAttribute(record[attribute], tmp)
      } else if (subtype === AdvancedFormAttributeType.ENUMERATION) {
        tmp = convertEnumerationAttributes(record[attribute])
        param = {
          prefix: item.key || 'attributes',
          returnType: 'array',
          columns: [this.getKeyColumn('modalKey', 'key', [{ required: true }], false, false)],
        }
      } else if (subtype === AdvancedFormAttributeType.ACTION) {
        param = {
          prefix: item.key || 'actions',
          suffix: true,
          useIndex: true,
          useIndexPrefix: 'actions',
          returnType: 'array',
          columns: [
            this.getKeyColumn('modalCode', 'actionCode', [{ required: true }], false, false),
            this.getKeyColumn('modalDescription', 'description', [{ required: false }]),
            {
              key: 'showAsCPActionButton',
              field: 'showAsCPActionButton',
              label: __(`misc.showAsCPActionButton`),
              type: AdvancedFormInputType.SWITCH,
              editable: true,
              defaultValue: true,
              rules: [{ required: false }],
            },
          ],
        }
        tmp = convertDetails(AttributeUtil.getAttribute(record, attribute), param)
      }
      if (!tmp) {
        tmp = []
      }
      this.setState(
        {
          attributes: tmp,
          otherAttributes: otherAttrs,
          configuration: param,
        },
        this.checkUpdates
      )
    }
  }

  checkUpdates = () => {
    const { subtype } = this.props
    //to set default value for antd validation form
    if (subtype === AdvancedFormAttributeType.SIMPLE) {
      this.updates()
    } else if (subtype === AdvancedFormAttributeType.ACTION) {
      this.updates()
    } else if (subtype === AdvancedFormAttributeType.IDENTIFIER) {
      this.updates()
    }
  }

  getAttributeMappingValue = (attribute) => {
    const { record, subtype } = this.props
    let value = attribute.defaultValue || ''
    if (record && record.attributes && record.attributes[attribute.code]) {
      if (subtype === AdvancedFormAttributeType.PRODUCT) {
        value = record.attributes[attribute.code].value
      } else {
        value = record.attributes[attribute.code]
      }
    }
    return value
  }

  getAttributeMappingDescription = (attribute) => {
    const { record } = this.props
    let value = ''
    if (record && record.attributes && record.attributes[attribute.code]) {
      value = record.attributes[attribute.code].description
    }
    return value
  }

  getAttributeMapping = async () => {
    const { entity, subtype } = this.props
    const results =
      entity !== ''
        ? await AttributeMappings.search<StwAttribute>({ entity: entity, size: 100, sort: 'code,asc' })
        : undefined
    const tmp: AdvancedFormAttribute[] = []
    if (results && results.content) {
      for (let c = 0; c < results.content.length; c++) {
        const obj: AdvancedFormAttribute = {
          key: results.content[c].code,
          value: this.getAttributeMappingValue(results.content[c]),
          message: results.content[c].description || '',
        }
        if (subtype === AdvancedFormAttributeType.PRODUCT) {
          obj.description = this.getAttributeMappingDescription(results.content[c])
        }
        tmp.push(obj)
      }
    }
    return tmp
  }

  add = () => {
    const { configuration } = this.state
    const tmp: AdvancedFormAttribute = { add: true }
    for (let c = 0; c < configuration.columns.length; c++) {
      tmp[configuration.columns[c].key] = configuration.columns?.[c]?.defaultValue ?? undefined
    }
    this.setState({
      attribute: tmp,
      modalVisible: true,
    })
  }

  edit = (key) => {
    const tmp: AdvancedFormAttribute[] = this.state.attributes
    const attr = tmp.find((attribute) => attribute.key === key)
    if (attr) {
      this.setState({
        attribute: attr,
        modalVisible: true,
      })
    }
  }

  remove = (index) => {
    const tmp: AdvancedFormAttribute[] = this.state.attributes
    if (index >= 0) {
      tmp.splice(index, 1)
      this.setState({ attributes: tmp }, this.updates)
    }
  }

  handleChangeAttribute = (index, field, value) => {
    const tmp: AdvancedFormAttribute[] = this.state.attributes
    if (index >= 0) {
      if (field === 'role' && value !== '') {
        value = value.toUpperCase()
      } /*else if (value === '') {
        value = undefined
      }*/
      tmp[index][field] = value
      this.setState({ attributes: tmp }, this.updates)
    }
  }

  confirmAttribute = (attribute) => {
    const { attributes, configuration } = this.state
    const tmp: any[] = attributes
    if (attribute.add) {
      const attr = {}
      for (let c = 0; c < configuration.columns.length; c++) {
        attr[configuration.columns[c].field] = attribute[configuration.columns[c].field] || ''
      }
      tmp.push(attr)
    } else {
      const index = tmp.findIndex((record) => record.key === `${attribute.key}_c`)
      if (index >= 0) {
        for (let c = 0; c < configuration.columns.length; c++) {
          tmp[index][configuration.columns[c].field] = attribute[configuration.columns[c].field] || ''
        }
      }
    }
    this.setState(
      {
        attributes: tmp,
        modalVisible: false,
        attribute: null,
      },
      this.updates
    )
  }

  closeModalAttribute = () => {
    this.setState({
      modalVisible: false,
      attribute: null,
    })
  }

  getAttribute = (attr, index = 1) => {
    const { configuration } = this.state
    const obj = {}
    for (let c = index; c < configuration.columns.length; c++) {
      if (configuration.columns[c].type === AdvancedFormInputType.SWITCH) {
        obj[configuration.columns[c].field] = attr[configuration.columns[c].field] || false
      } else {
        obj[configuration.columns[c].field] = attr[configuration.columns[c].field] || ''
      }
    }
    return obj
  }

  updates = () => {
    const { updateValue, subtype } = this.props
    const { configuration, attributes, otherAttributes } = this.state

    let a = 0
    const tmp: any[] = attributes
    const other: any[] = otherAttributes
    const attrs = {}
    if (configuration.returnType === 'object') {
      if (subtype === AdvancedFormAttributeType.PRODUCT) {
        for (a = 0; a < tmp.length; a++) {
          attrs[tmp[a].key] = this.getAttribute(tmp[a])
        }
        for (a = 0; a < other.length; a++) {
          attrs[other[a].key] = this.getAttribute(other[a])
        }
      } else {
        for (a = 0; a < tmp.length; a++) {
          attrs[tmp[a].key] = tmp[a].value
        }
        for (a = 0; a < other.length; a++) {
          attrs[other[a].key] = other[a].value
        }
      }
      updateValue(attrs)
    } else if (subtype === AdvancedFormAttributeType.ENUMERATION) {
      const tmp1: string[] = []
      for (a = 0; a < tmp.length; a++) {
        tmp1.push(tmp[a].key)
      }
      updateValue(tmp1)
    } else if (subtype === AdvancedFormAttributeType.ACTION) {
      const tmp1: any[] = []
      for (a = 0; a < tmp.length; a++) {
        tmp1[`actions${a}`] = this.getAttribute(tmp[a], 0)
      }
      updateValue(tmp1)
    } else {
      const tmp1 = {}
      for (a = 0; a < tmp.length; a++) {
        tmp1[`details${a}`] = this.getAttribute(tmp[a], 0)
      }
      updateValue(tmp1)
    }
  }

  configuration = () => {
    const { entity } = this.props
    if (entity && entity !== '') {
      navigate(`/configuration/entityAttributes/${entity}`)
    }
  }

  render() {
    const { attributes, modalVisible, attribute, configuration } = this.state
    const { title, subtitle, parameters, record, item, entity } = this.props
    return (
      <Row style={{ marginTop: 20, marginBottom: 20 }}>
        <Col span={24}>
          <SectionTitle
            configuration={entity && entity !== '' ? () => this.configuration() : undefined}
            title={title}
            subtitle={subtitle}
            marginBottom={attributes.length === 0 ? 5 : 20}
          />
        </Col>
        {attributes.length > 0 && (
          <AdvancedFormAttributesHeader entity={entity} erasable={item.erasable} configuration={configuration} />
        )}
        {attributes.length > 0 &&
          attributes.map((attr, i) => {
            return (
              <AdvancedFormAttributesSingle
                attribute={attr}
                handleChange={this.handleChangeAttribute}
                handleRemove={this.remove}
                record={record}
                configuration={configuration}
                parameters={parameters}
                key={`${item.key}_attr_${i}`}
                keyBlock={item.key}
                index={i}
                entity={entity}
                erasable={item.erasable}
              />
            )
          })}
        {item.activeAddModal && (
          <Row className="stylewhere-form-title" style={{ marginTop: 15, width: '100%' }} justify="start">
            <Button style={{ height: 40 }} className="stylewhere-button-secondary" onClick={() => this.add()}>
              <Text style={{ color: '#FFF' }}>{__(`misc.modal.${item.key}.add`)}</Text>
            </Button>
          </Row>
        )}
        <AdvancedFormModalAttribute
          visible={modalVisible}
          attributes={attributes}
          title={attribute && !attribute.add ? __(`misc.modal.${item.key}.edit`) : __(`misc.modal.${item.key}.add`)}
          attribute={attribute}
          parameters={parameters}
          configuration={configuration}
          close={this.closeModalAttribute}
          confirm={this.confirmAttribute}
        />
      </Row>
    )
  }
}
