import BaseResource from './BaseResource'
import { api, responseErrorCheck } from './api'

export default class ClientUserDatas extends BaseResource {
  static endpoint = '/api/v1/userClientData' // /profile

  static async insertByCode(code: string, data: any): Promise<void> {
    return api.post(`${this.endpoint}/${code}`, data).then(responseErrorCheck)
  }
}
