import { EditOutlined, EyeOutlined } from '@ant-design/icons'
import {
  AdvancedTableColumn,
  AdvancedTableContentKind,
  AdvancedTableFilterKind,
  AdvancedTableFiltersDef,
} from '../../../components/AdvancedTable'
import {
  AdvancedFormAttributeType,
  AdvancedFormField,
  AdvancedFormFieldBuilder,
  AdvancedFormInputType,
} from '../../../components/AdvancedForm/advancedFormTypes'
import { __ } from '../../../shared/i18n'
import { AdvancedTableRowColumn } from '../../../components'
import { DetailCell, StwFullWorkstation } from '../../../api'

export const WORKSTATION_FILTERS: AdvancedTableFiltersDef = [
  {
    key: 'equalCodes',
    label: __('fields.labels.code'),
    type: AdvancedTableFilterKind.STRING_LIST,
    column: 'code',
    col: 8,
  },
  {
    key: 'description',
    label: __('fields.labels.description'),
    type: AdvancedTableFilterKind.STRING,
    column: 'description',
    col: 8,
  },
  {
    key: 'placeIds',
    label: __('fields.labels.place'),
    type: AdvancedTableFilterKind.AUTOCOMPLETE,
    column: 'place',
    autocomplete: {
      endpoint: '/api/v1/places',
      sort: 'code,asc',
      multiple: true,
    },
    col: 8,
  },
  {
    key: 'creationDate',
    label: __('fields.labels.creationDate'),
    type: AdvancedTableFilterKind.DATE_RANGE,
    column: 'creationDate',
    col: 8,
  },
  {
    key: 'lastModifyDate',
    label: __('fields.labels.lastModifyDate'),
    type: AdvancedTableFilterKind.DATE_RANGE,
    column: 'lastModifyDate',
    col: 8,
  },
]

export const WORKSTATION_COLUMNS: AdvancedTableColumn[] = [
  {
    key: 'code',
    dataIndex: 'code',
    title: __('fields.labels.code'),
    fixedType: 'left',
  },
  {
    key: 'description',
    dataIndex: 'description',
    title: __('fields.labels.description'),
  },
  {
    key: 'place',
    dataIndex: 'place',
    title: __('fields.labels.place'),
    sortable: false,
    type: AdvancedTableContentKind.CODE_DESC,
  },
  {
    key: 'rfidAntennasNumber',
    dataIndex: 'rfidAntennasNumber',
    title: __('fields.labels.rfidAntennasNumber'),
    render: (text, record: StwFullWorkstation) => {
      return <AdvancedTableRowColumn value={record.rfidAntennas ? record.rfidAntennas.length : 0} number />
    },
  },
  {
    key: 'balance.code',
    dataIndex: 'balance',
    title: __('fields.labels.balance'),
    sortable: false,
  },
  {
    key: 'creationDate',
    dataIndex: 'creationDate',
    title: __('fields.labels.creationDate'),
    type: AdvancedTableContentKind.DATE_TIME,
  },
  {
    key: 'lastModifyDate',
    dataIndex: 'lastModifyDate',
    title: __('fields.labels.lastModifyDate'),
    type: AdvancedTableContentKind.DATE_TIME,
  },
  {
    key: 'action',
    width: 'small',
    fixedType: 'right',
    sortable: false,
    unmanageable: true,
    render: (text, record) => (
      <AdvancedTableRowColumn
        actions={[
          { content: <EditOutlined />, type: 'default', path: `/devices/workstations/${record.id}/form` },
          { content: <EyeOutlined />, type: 'default', path: `/devices/workstations/${record.id}` },
        ]}
      />
    ),
  },
]

export const WORKSTATION_FIELDS: Array<AdvancedFormField> = [
  {
    key: 'placeId',
    type: AdvancedFormInputType.AUTOCOMPLETE,
    rules: [{ required: true }],
    placeholder: __('fields.placeholders.place'),
    label: __('fields.labels.place'),
    autocomplete: {
      endpoint: '/api/v1/places',
      sort: 'code,asc',
      withIdValues: true,
    },
  },
  AdvancedFormFieldBuilder.buildText('description'),
  {
    key: 'code',
    type: AdvancedFormInputType.UNIQUE_TEXT,
    label: __('fields.labels.code'),
    placeholder: __('fields.placeholders.code'),
    rules: [{ required: true }],
    unique: {
      endpoint: '/api/v1/workstations',
      parameter: 'equalCodes',
    },
  },
  {
    key: 'deviceManagerUrl',
    type: AdvancedFormInputType.URL,
    label: __('fields.labels.deviceManagerUrl'),
    option: 'deviceManagerUrlProtocols',
    col: 24,
    rules: [{ required: true }],
  },
  {
    key: 'deviceManagerWebsocket',
    type: AdvancedFormInputType.URL,
    label: __('fields.labels.deviceManagerWebsocket'),
    option: 'deviceManagerWebsocketProtocols',
    col: 24,
    rules: [{ required: true }],
  },
  {
    key: 'rfidAntennaIds',
    type: AdvancedFormInputType.WORKSTATION_RFID_ANTENNAS,
    col: 24,
  },
  {
    key: 'balanceId',
    type: AdvancedFormInputType.WORKSTATION_BALANCE,
    col: 24,
  },
  AdvancedFormFieldBuilder.buildAttribute('attributes', AdvancedFormAttributeType.SIMPLE, false, 'workstation'),
]

export const WORKSTATIONS_DATA: DetailCell[] = [
  { title: __(`fields.labels.code`), attribute: 'code', columns: 24 },
  { title: __(`fields.labels.type`), attribute: 'type', columns: 12 },
  { title: __(`fields.labels.description`), attribute: 'description', columns: 12 },
  { title: __(`fields.labels.deviceManagerUrl`), attribute: 'deviceManagerUrl', columns: 12 },
  { title: __(`fields.labels.deviceManagerWebsocket`), attribute: 'deviceManagerWebsocket', columns: 12 },
  { title: __(`fields.labels.place`), attribute: 'place', columns: 12, type: 'codeDesc' },
  { title: __(`fields.labels.balance`), attribute: 'balance.code', columns: 12 },
  { title: __(`fields.labels.creationDate`), attribute: 'creationDate', type: 'date', columns: 12 },
  { title: __(`fields.labels.lastModifyDate`), attribute: 'lastModifyDate', type: 'date', columns: 12 },
]

export const WORKSTATION_ANTENNAS_COLUMNS: AdvancedTableColumn[] = [
  {
    key: 'code',
    dataIndex: 'code',
    title: __('fields.labels.code'),
    type: AdvancedTableContentKind.STRING,
  },
  {
    key: 'description',
    dataIndex: 'description',
    className: 'stw-large',
    title: __('fields.labels.description'),
    type: AdvancedTableContentKind.STRING,
  },
  {
    key: 'zone',
    dataIndex: 'zone',
    title: __('fields.labels.zone'),
    type: AdvancedTableContentKind.CODE_DESC,
  },
  {
    key: 'reader.settings.ipAddress',
    className: 'stw-small',
    dataIndex: 'ipAddress',
    title: __('fields.labels.settings.ipAddress'),
    type: AdvancedTableContentKind.STRING,
  },
  {
    key: 'txPower',
    dataIndex: 'txPower',
    className: 'stw-small',
    title: __('fields.labels.txPower'),
    type: AdvancedTableContentKind.NUMBER,
  },
  {
    key: 'rxSensitivity',
    className: 'stw-small',
    dataIndex: 'rxSensitivity',
    title: __('fields.labels.rxSensitivity'),
    type: AdvancedTableContentKind.NUMBER,
  },
]
