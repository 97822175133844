import { AxiosRequestConfig } from 'axios'
import BaseResource, { PageResult } from './BaseResource'
import {
  api,
  MovementType,
  responseErrorCheck,
  StwChecklist,
  StwFullOutbound,
  StwFullParcelEntry,
  StwItem,
  StwOperation,
  StwOutbound,
  StwParcel,
  StwParcelEntry,
  StwPlace,
  StwProduct,
  StwUser,
  StwZone,
} from '.'
import Parcels from './Parcels'
import Items from './Items'
import Products from './Products'
import Users from './Users'
import Places from './Places'
import Zones from './Zones'
import Operations from './Operations'
import Checklists from './Checklists'

export default class Outbounds extends BaseResource {
  static endpoint = '/api/v1/outbounds' ///logistics

  static async getByIdFull(id): Promise<StwFullOutbound> {
    const outbound = await this.get<StwOutbound>(id)
    const user = await Users.get<StwUser>(outbound.creationUserId)
    const places = await Places.searchByIds<StwPlace>([outbound.destinationPlaceId, outbound.originPlaceId])
    const zone = await Zones.get<StwZone>(outbound.destinationZoneId)
    const operation = await Operations.get<StwOperation>(outbound.operationId)
    const entries: PageResult<StwParcelEntry> = await Outbounds.parcelEntries(outbound.id, { size: 10000000 })

    let totalDetected = 0
    let totalUnexpected = 0
    let totalExpected = 0
    let checklist

    if (outbound.checklistId) {
      checklist = await Checklists.get<StwChecklist>(outbound.checklistId)
      if (checklist.itemIds) {
        totalExpected = checklist.itemIds.length
      } else {
        totalExpected = checklist.products.map((p) => p.quantity).reduce((q1, q2) => q1 + q2)
      }
    }
    entries.content.forEach((pe) => {
      totalDetected += pe.detected
      totalUnexpected += pe.unexpected
      const entry = entries.content.filter((e) => e.productCode === pe.productCode)[0]
      entry.detected += pe.detected
      entry.unexpected += pe.unexpected
    })

    return {
      ...outbound,
      type: MovementType.OUTBOUND,
      creationUser: user,
      destinationZone: zone,
      operation: operation,
      checklist: checklist,
      originPlace: places[`${outbound.originPlaceId}`],
      destinationPlace: places[`${outbound.destinationPlaceId}`],
      totalDetected: totalDetected,
      totalExpected: totalExpected,
      totalUnexpected: totalUnexpected,
    }
  }

  static async searchFull(params = {}): Promise<PageResult<StwFullOutbound>> {
    const outbounds = await this.search<StwOutbound>(params)

    const users = await Users.searchByIds<StwUser>(outbounds.content.map((pe) => pe.creationUserId))

    const places = await Places.searchByIds<StwPlace>([
      ...outbounds.content.map((pe) => pe.destinationPlaceId),
      ...outbounds.content.map((pe) => pe.originPlaceId),
    ])

    const zones = await Zones.searchByIds<StwPlace>(outbounds.content.map((outbound) => outbound.destinationZoneId))

    const operations = await Operations.searchByIds<StwOperation>(outbounds.content.map((pe) => pe.operationId))

    const checklists = await Checklists.searchByIds<StwChecklist>(outbounds.content.map((pe) => pe.checklistId))

    const fullOutbounds = outbounds.content.map((i) => {
      return Outbounds.parcelEntries(i.id, { size: 10000000 }).then((entries) => {
        let totalDetected = 0
        let totalUnexpected = 0
        let totalExpected = 0

        if (i.checklistId && checklists[`${i.checklistId}`]) {
          if (checklists[`${i.checklistId}`].itemIds) {
            totalExpected = checklists[`${i.checklistId}`].itemIds.length
          } else {
            totalExpected = checklists[`${i.checklistId}`].products.map((p) => p.quantity).reduce((q1, q2) => q1 + q2)
          }
        }

        entries.content.forEach((pe) => {
          totalDetected += pe.detected
          totalUnexpected += pe.unexpected

          const entry = entries.content.filter((e) => e.productCode === pe.productCode)[0]
          entry.detected += pe.detected
          entry.unexpected += pe.unexpected
        })

        return {
          ...i,
          type: MovementType.OUTBOUND,
          creationUser: users[`${i.creationUserId}`],
          destinationZone: zones[`${i.destinationZoneId}`],
          operation: operations[`${i.operationId}`],
          checklist: checklists[`${i.checklistId}`],
          originPlace: places[`${i.originPlaceId}`],
          destinationPlace: places[`${i.destinationPlaceId}`],
          totalDetected: totalDetected,
          totalExpected: totalExpected,
          totalUnexpected: totalUnexpected,
        }
      })
    })

    return {
      ...outbounds,
      content: await Promise.all(fullOutbounds),
    }
  }

  static parcelEntries(
    id: string,
    params = {},
    requestConfig?: AxiosRequestConfig
  ): Promise<PageResult<StwParcelEntry>> {
    return api
      .get<PageResult<StwFullParcelEntry>>(`${this.endpoint}/${id}/parcelentries`, params, requestConfig)
      .then(responseErrorCheck)
  }

  static async fullParcelentries(id: string, params = {}): Promise<PageResult<StwFullParcelEntry>> {
    const entries = await this.parcelEntries(id, params)
    const parcels = await Parcels.searchByIds<StwParcel>(entries.content.map((parcelEntry) => parcelEntry.parcelId))
    const items = await Items.searchByIds<StwItem>(entries.content.map((parcelEntry) => parcelEntry.itemId))
    const products = await Products.searchByCodes<StwProduct>(
      entries.content.filter((parcelEntry) => parcelEntry.productCode).map((parcelEntry) => parcelEntry.productCode!)
    )
    const fullEntries: StwFullParcelEntry[] = entries.content.map(
      ({ parcelId, itemId, productCode, ...parcelEntry }) => ({
        ...parcelEntry,
        parcel: parcels[`${parcelId}`],
        item: items[`${itemId!}`],
        product: itemId ? items[`${itemId}`]?.product ?? undefined : products[`${productCode}`],
      })
    )
    return {
      ...entries,
      content: fullEntries,
    }
  }
}
