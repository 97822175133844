import { Row, Col } from 'antd'
import { Component } from 'react'
import { DynamicImage, KeyValueGrid } from '.'
import { PRODUCT_IMAGE_DETAIL_SIZE } from '../constants'
import { DetailCell, StwProduct } from '../api'
import AppStore from '../shared/AppStore'

interface Props {
  loader: boolean
  product?: StwProduct
  data: DetailCell[]
}

export class ProductDetailBox extends Component<Props> {
  render() {
    const { loader, product, data } = this.props
    const ENABLED_PRODUCT_IMAGE = AppStore.getShowProductImage() && product
    return (
      <Row style={{ marginTop: 20, marginBottom: 20 }}>
        {ENABLED_PRODUCT_IMAGE && (
          <Col span={8} style={{ paddingRight: 20 }}>
            <DynamicImage record={product || undefined} size={PRODUCT_IMAGE_DETAIL_SIZE} skeleton={loader} />
          </Col>
        )}
        <Col span={ENABLED_PRODUCT_IMAGE ? 16 : 24}>
          <KeyValueGrid data={loader ? [] : product} fields={data} skeleton={loader} />
        </Col>
      </Row>
    )
  }
}
