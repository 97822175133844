import { Component } from 'react'
import { StylewherePage, TableList } from '../../../components'
import { JOB_COLUMNS, JOB_FILTERS } from '../../../config/Pages/Jobs'
import { __ } from '../../../shared/i18n'
import { AdvancedTableConfig } from '../../../components/AdvancedTable'
import { ExtendRouteComponentProps } from '../../../types'
import AsyncJobs from '../../../api/AsyncJobs'

const JOBS_TABLE_CONFIG: AdvancedTableConfig = {
  columns: JOB_COLUMNS,
  filterDefinitions: JOB_FILTERS,
  filterParameters: {},
  manageColumnsPrefix: 'jobs',
  activeDefaultClassColumn: true,
}

class JobImportList extends Component<ExtendRouteComponentProps> {
  render() {
    return (
      <StylewherePage {...this.props} noOverflow>
        <TableList
          title={`${__('menu.jobs.jobs')} - ${__('menu.jobs.import')}`}
          location={this.props.location}
          config={JOBS_TABLE_CONFIG}
          resource={{
            call: AsyncJobs.searchTableList,
            endpoint: AsyncJobs.endpoint,
            filters: { jobType: 'import' },
          }}
        />
      </StylewherePage>
    )
  }
}

export default JobImportList
