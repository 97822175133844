import { Component } from 'react'
import { StwTemplate } from '../../../api'
import { GridData, StylewherePage, DefaultHeader, NotFound, Section } from '../../../components'
import { __, T } from '../../../shared/i18n'
import { TEMPLATES_DETAIL } from '../../../config/Pages/Templates'
import Templates from '../../../api/Templates'
import { ExtendRouteComponentProps } from '../../../types'
import { getMultipleTitle, getBackURL, getFragmentObject } from '../../../shared/utils'

interface State {
  template: StwTemplate | undefined
  loader: boolean
}

class TemplatesDetail extends Component<ExtendRouteComponentProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      template: undefined,
      loader: true,
    }
  }

  componentDidMount() {
    this.initialize()
  }

  initialize = async () => {
    const { match } = this.props
    let template
    if (match && match.params && match.params.templateId) {
      template = await Templates.get(match.params.templateId).then((res) => res)
    }
    this.setState({
      template: template || undefined,
      loader: false,
    })
  }

  render() {
    const { template, loader } = this.state
    const { breadcrumbs, queryString } = this.props
    const fragment = getFragmentObject(template, 'code')
    return (
      <StylewherePage {...this.props} noOverflow fragment={fragment}>
        <DefaultHeader
          backPath={getBackURL(queryString, breadcrumbs)}
          multipleTitle={getMultipleTitle(template)}
          title={__(T.misc.not_found)}
          skeleton={{ active: loader, options: { multiple: true } }}
        />
        <Section customClass={`stw-section-page paged-header ${template || loader ? 'scroll transparent' : ''}`}>
          {template || loader ? (
            <GridData fields={TEMPLATES_DETAIL} record={loader ? [] : template} skeleton={loader} />
          ) : (
            <NotFound fullheight title={__(T.misc.noRecordFoundTitle)} />
          )}
        </Section>
      </StylewherePage>
    )
  }
}

export default TemplatesDetail
