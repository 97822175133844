import BaseResource from './BaseResource'
import { api, responseErrorCheck } from './api'

export default class ApplicationSetting extends BaseResource {
  static endpoint = '/api/v1/applicationSettings' ///profile

  static async client() {
    const params = {}
    return api.get<any>(`${this.endpoint}/client`, params).then(responseErrorCheck)
  }
}
