import { Component } from 'react'
import { Row } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { DetailCell, StwProduct, StwReportMetadata, StwReportQueryResult } from '../../../api'
import { advancedSortToApiValue, AdvancedTablePagination } from '../../../components/AdvancedTable'
import { __, T } from '../../../shared/i18n'
import { PageResult } from '../../../api/BaseResource'
import Reports from '../../../api/Reports'
import Products from '../../../api/Products'
import { Section, ProductDetailBox, TableListReport, NotFound } from '../../../components'
import { getDetailDataWithAttributesMapping } from '../../../shared/utils'
import { PRODUCT_MODAL_FIELDS } from '../../../config/Pages/Reports'
import { ExtendRouteComponentProps } from '../../../types'

interface Props extends ExtendRouteComponentProps {
  pickingEntryId: string
}

interface State {
  loader: boolean
  product?: StwProduct
  productDetailData: DetailCell[]
}

export default class PickingEntryReads extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      loader: true,
      productDetailData: PRODUCT_MODAL_FIELDS,
    }
  }

  componentDidMount = async () => {
    await this.initialize()
  }

  initialize = async () => {
    const { pickingEntryId } = this.props
    const dataProduct: DetailCell[] = await getDetailDataWithAttributesMapping(
      PRODUCT_MODAL_FIELDS,
      'product',
      0,
      'valueDesc',
      12
    )
    const { pickingReadReport } = await this.getPickingReadReport(pickingEntryId, {
      pageNumber: 1,
      pageSize: 10,
      sortValues: {},
    })
    if (pickingReadReport && pickingReadReport.content && pickingReadReport.content.length > 0) {
      const product = await Products.get<StwProduct>(pickingReadReport.content[0].productId)
      this.setState({
        product: product,
        loader: false,
        productDetailData: dataProduct,
      })
    } else {
      this.setState({
        loader: false,
      })
    }
  }

  getPickingReadReport = async (
    pickingEntryId: string,
    pagination: AdvancedTablePagination
  ): Promise<{
    pickingReadReportMetadata: StwReportMetadata
    pickingReadReport: PageResult<StwReportQueryResult>
  }> => {
    const pickingReadReportMetadata = await Reports.metadata('OPLOG_PICKING_READ')
    const pickingReadReport = await Reports.report('OPLOG_PICKING_READ', {
      pickingEntryId: pickingEntryId,
      page: pagination.pageNumber! - 1,
      size: pagination.pageSize,
      sort: advancedSortToApiValue(pagination.sortValues),
    })
    return { pickingReadReportMetadata, pickingReadReport }
  }

  getSectionTitle = () => {
    const { loader, product } = this.state
    return product || !loader ? __('fields.labels.pickingReads') : <LoadingOutlined />
  }

  render = () => {
    const { loader, product, productDetailData } = this.state
    const { pickingEntryId } = this.props
    return (
      <Section title={this.getSectionTitle()} parentPadding={15}>
        {loader || product ? (
          <>
            <ProductDetailBox loader={loader} product={product} data={productDetailData} />
            {product && (
              <TableListReport
                {...this.props}
                reportId="OPLOG_PICKING_READ"
                actions={[]}
                fixedTableHeight
                disabledReportExport
                headerType="unset"
                disabledColumnPrefix
                reportParams={{ pickingEntryId: pickingEntryId }}
                disableLocation
                disableColumnFilters
                numberRecodPage={10}
                disabledReloadData
                sortValues={{}}
                tableBorder
              />
            )}
          </>
        ) : (
          <Row style={{ marginTop: 15 }}>
            <NotFound title={__(T.misc.noRecordFoundTitle)} />
          </Row>
        )}
      </Section>
    )
  }
}
