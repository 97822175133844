import { Component, createRef } from 'react'
import { Form, FormInstance } from 'antd'
import { StwDeviceController } from '../../../api'
import { ExtendRouteComponentProps } from '../../../types'
import DeviceControllers from '../../../api/DeviceControllers'
import { __, T } from '../../../shared/i18n'
import { AttributeUtil } from '../../../config/utility/utility'
import { navigate } from '../../../shared/router'
import { DeviceControllerTypes, FormValidateMessages } from '../../../constants'
import { AdvancedForm, StylewherePage, DefaultHeader, Section } from '../../../components'
import { DEVICE_CONTROLLERS_FIELDS } from '../../../config/Pages/Devices/DeviceControllers'
import { FormUtility } from '../../../config/utility/formUtility'
import { getFragmentObject, getBackURL } from '../../../shared/utils'

interface State {
  deviceControllerId: string
  deviceController: StwDeviceController
  loader: boolean
  isCodeEditableByDescription: boolean
}

class DeviceControllerForm extends Component<ExtendRouteComponentProps, State> {
  formRef = createRef<FormInstance>()

  constructor(props) {
    super(props)
    this.state = {
      deviceControllerId: '',
      deviceController: {
        code: '',
        type: '',
      },
      isCodeEditableByDescription: true,
      loader: true,
    }
  }

  componentDidMount = async () => {
    let result
    if (this.props.match) {
      const deviceControllerId = this.props.match.params.controllerId
      if (deviceControllerId !== '' && deviceControllerId !== 'create') {
        result = await DeviceControllers.get<StwDeviceController>(deviceControllerId)
      }
    }

    this.setState(
      {
        deviceControllerId: result ? result.id! : '',
        deviceController: result || {},
        isCodeEditableByDescription: !result,
        loader: false,
      },
      this.updateFormFieldsValue
    )
  }

  updateFormFieldsValue = () => {
    if (this.formRef && this.formRef.current) {
      this.formRef.current.setFieldsValue(this.state.deviceController)
    }
  }

  handleChange = (key, value) => {
    const { deviceController, isCodeEditableByDescription } = this.state
    AttributeUtil.setAttribute(deviceController, key, value)
    if (key === 'description' && isCodeEditableByDescription) {
      FormUtility.handleDescription(value, deviceController, this.formRef)
    } else if (key === 'code') {
      this.setState({ isCodeEditableByDescription: false })
    }
    this.setState({
      deviceController: deviceController,
    })
  }

  store = () => {
    const { deviceController, deviceControllerId } = this.state
    if (deviceControllerId !== '') {
      DeviceControllers.update<StwDeviceController>(deviceController).then((value) => {
        this.redirect(value)
      })
    } else {
      DeviceControllers.insert<StwDeviceController>(deviceController).then((value) => {
        this.redirect(value)
      })
    }
  }

  redirect(controller: StwDeviceController) {
    if (controller && controller.id) {
      navigate(`/devices/controllers${this.props.queryString ?? ''}`)
    }
  }

  render() {
    const { deviceController, deviceControllerId, loader } = this.state
    const title = deviceControllerId !== '' ? __(T.deviceControllers.edit) : __(T.deviceControllers.create_new)
    const { breadcrumbs, queryString } = this.props
    const fragment = !loader ? getFragmentObject(deviceController, 'id', __(T.deviceControllers.create_new)) : undefined
    return (
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        onFinish={this.store}
        style={{ width: '100%', height: '100%' }}
        initialValues={deviceController}
        validateMessages={FormValidateMessages}
        scrollToFirstError
        ref={this.formRef}
      >
        <StylewherePage {...this.props} noOverflow fragment={fragment}>
          <DefaultHeader
            backPath={getBackURL(queryString, breadcrumbs)}
            title={loader ? '...' : title}
            skeleton={{ active: loader }}
            actions={[
              {
                label: __(T.misc.cancel),
                type: 'cancel',
                onClick: () => navigate(`/devices/controllers${queryString ?? ''}`),
              },
              {
                label: deviceControllerId !== '' ? __(T.misc.update) : __(T.misc.create),
                type: 'submit',
              },
            ]}
          />
          <Section customClass="stw-section-page paged-header scroll">
            <AdvancedForm
              record={deviceController}
              fields={DEVICE_CONTROLLERS_FIELDS}
              store={this.store}
              editing={deviceController.id !== undefined}
              handleChange={this.handleChange}
              parameters={{ type: DeviceControllerTypes }}
            />
          </Section>
        </StylewherePage>
      </Form>
    )
  }
}

export default DeviceControllerForm
