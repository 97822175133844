import BaseResource, { PageResult } from './BaseResource'
import { StwFullXspan, StwPlace, StwUser, StwXspan, StwZone } from './types'
import Places from './Places'
import Zones from './Zones'
import Users from './Users'

export default class Xspans extends BaseResource {
  static endpoint = '/api/v1/xspans' ///edge

  static async getFull(xspanId: string): Promise<StwFullXspan> {
    const xspan = await this.get<StwXspan>(xspanId)
    return Xspans.toFull(xspan)
  }

  static async searchFull(params = {}): Promise<PageResult<StwFullXspan>> {
    const xspans = await this.search<StwXspan>(params)
    const places = await Places.searchByIds<StwPlace>(xspans.content.map((xspan) => xspan.placeId))
    const zones = await Zones.searchByIds<StwZone>([
      ...xspans.content.map((xspan) => xspan.sector2ZoneId),
      ...xspans.content.map((xspan) => xspan.sector3ZoneId),
    ])
    const users = await Users.searchByIds<StwUser>(xspans.content.map((xspan) => xspan.userId))
    return {
      ...xspans,
      content: xspans.content.map(({ placeId, sector2ZoneId, sector3ZoneId, userId, ...xspan }) => ({
        ...xspan,
        place: places[`${placeId}`],
        sector2Zone: zones[`${sector2ZoneId}`],
        sector3Zone: zones[`${sector3ZoneId}`],
        user: users[`${userId}`],
      })),
    }
  }

  static async updateFull(fullXspan: StwFullXspan): Promise<void> {
    await Xspans.update<StwXspan>(Xspans.fromFull(fullXspan))
  }

  static async insertFull(fullXspan: StwFullXspan): Promise<StwFullXspan> {
    const xspan = await this.insert<StwXspan>(Xspans.fromFull(fullXspan))
    return Xspans.toFull(xspan)
  }

  static async toFull({
    userId,
    placeId,
    sector2ZoneId,
    sector3ZoneId,
    ...baseXspan
  }: StwXspan): Promise<StwFullXspan> {
    const places = await Places.searchByIds<StwPlace>([placeId])
    const zones = await Zones.searchByIds<StwZone>([sector2ZoneId, sector3ZoneId])
    const users = await Users.searchByIds<StwUser>([userId])
    return {
      ...baseXspan,
      place: places[`${placeId}`],
      sector2Zone: zones[`${sector2ZoneId}`],
      sector3Zone: zones[`${sector3ZoneId}`],
      user: users[`${userId}`],
    }
  }

  static fromFull({ user, place, sector2Zone, sector3Zone, ...baseXspan }: StwFullXspan): StwXspan {
    return {
      ...baseXspan,
      userId: user?.id ?? undefined,
      placeId: place?.id ?? undefined,
      sector2ZoneId: sector2Zone?.id ?? undefined,
      sector3ZoneId: sector3Zone?.id ?? undefined,
    }
  }
}
