import { Component, createRef, RefObject } from 'react'
import { Form, FormInstance } from 'antd'
import { StwEditableScanner, StwOperation, StwRfidAntenna, StwRfidReader, StwScanner } from '../../../api'
import { ExtendRouteComponentProps } from '../../../types'
import Scanners from '../../../api/Scanners'
import { __, T } from '../../../shared/i18n'
import { AttributeUtil } from '../../../config/utility/utility'
import { navigate } from '../../../shared/router'
import { FormValidateMessages, scannerDeviceControllerTypeFilters, scannerOperationFilters } from '../../../constants'
import { AdvancedForm, StylewherePage, DefaultHeader, Section } from '../../../components'
import { SCANNERS_FIELDS } from '../../../config/Pages/Devices/Scanners'
import Operations from '../../../api/Operations'
import RfidAntennas from '../../../api/RfidAntennas'
import { DeviceUtility } from '../../../config/utility/deviceUtility'
import { FormUtility } from '../../../config/utility/formUtility'
import { getFragmentObject, getBackURL } from '../../../shared/utils'

interface State {
  scanner: StwEditableScanner
  readerFilters: { [filterKey: string]: any }
  antennaFilters: { [filterKey: string]: any }
  targetZoneFilters: { [filterKey: string]: any }
  targetZoneRules: { required: boolean }
  loader: boolean
}

class ScannerForm extends Component<ExtendRouteComponentProps, State> {
  formRef = createRef<FormInstance>()

  constructor(props) {
    super(props)
    this.state = {
      scanner: {
        autoStart: true,
      },
      readerFilters: { placeId: '' },
      antennaFilters: { readerId: '' },
      targetZoneFilters: { placeId: '', type: 'STOCK' },
      targetZoneRules: { required: false },
      loader: true,
    }
  }

  componentDidMount = async () => {
    let editableScanner: StwEditableScanner = this.state.scanner
    if (this.props.match) {
      const scannerId = this.props.match.params.scannerId
      if (scannerId !== '' && scannerId !== 'create') {
        const scanner: StwScanner = await Scanners.get<StwScanner>(scannerId)
        editableScanner = await ScannerForm.mapScannerToEditableScanner(scanner)
      }
    }

    const { readerFilters, antennaFilters } = this.state
    readerFilters.placeId = editableScanner.place && editableScanner.place.id
    antennaFilters.readerId = editableScanner.reader && editableScanner.reader.id

    this.setState(
      {
        scanner: editableScanner,
        readerFilters: readerFilters,
        antennaFilters: antennaFilters,
        loader: false,
      },
      this.updateFormFieldsValue
    )
  }

  updateFormFieldsValue = () => {
    if (this.formRef && this.formRef.current) {
      this.formRef.current.setFieldsValue(this.state.scanner)
    }
  }

  private static async updateTargetZoneRules(scanner: StwEditableScanner, rules, formRef: RefObject<FormInstance>) {
    if (scanner.operation && scanner.operation.id) {
      const operation = await Operations.get<StwOperation>(scanner.operation.id)
      rules.required = operation && operation.type === 'TRANSFER_TO_ZONE'
    } else {
      rules.required = false
    }
    if (formRef.current) {
      await formRef.current.validateFields()
    }
  }

  private static updateCodeAndDescription(scanner: StwEditableScanner, formRef: RefObject<FormInstance>) {
    Scanners.search<StwScanner>({ page: 0, size: 1, placeId: scanner.place!.id })
      .then((scanners) => {
        return {
          code: `${scanner.place!.code}_S${scanners.totalElements + 1}`,
          description: scanner.place!.description && `${scanner.place!.description}_S${scanners.totalElements + 1}`,
        }
      })
      .then(({ code, description }) => {
        DeviceUtility.setFieldValue(scanner, 'code', code, formRef)
        DeviceUtility.setFieldValue(scanner, 'description', description, formRef)
      })
  }

  private static async mapScannerToEditableScanner(scanner: StwScanner): Promise<StwEditableScanner> {
    let reader: StwRfidReader | undefined
    if (scanner.rfidAntennas && scanner.rfidAntennas.length > 0) {
      const antennaId = scanner.rfidAntennas[0]
      const antenna = await RfidAntennas.get<StwRfidAntenna>(antennaId)
      reader = antenna.reader
    }

    return {
      id: scanner.id,
      code: scanner.code,
      description: scanner.description,
      deviceController: scanner.deviceController,
      autoStart: scanner.autoStart,
      targetZone: scanner.targetZone,
      user: scanner.user,
      rfidAntennas: scanner.rfidAntennas,
      operation: scanner.operation,
      transferToZoneEnabled: scanner.transferToZoneEnabled,
      lifespan: scanner.lifespan,
      creationDate: scanner.creationDate,
      lastModifyDate: scanner.lastModifyDate,
      place: reader?.place ?? undefined,
      reader: reader,
      attributes: scanner.attributes,
    }
  }

  handleChange = (key, value) => {
    const { scanner, readerFilters, antennaFilters, targetZoneFilters, targetZoneRules } = this.state
    AttributeUtil.setAttribute(scanner, key, value)
    switch (key) {
      case 'place': {
        this.handlePlaceChange(value, scanner, readerFilters, targetZoneFilters)
        break
      }
      case 'reader': {
        antennaFilters.readerId = scanner.reader && scanner.reader.id
        if (value === undefined) {
          FormUtility.setFieldValue(scanner, 'rfidAntennas', [], this.formRef)
        }
        this.setState({ scanner, antennaFilters })
        break
      }
      case 'operation': {
        ScannerForm.updateTargetZoneRules(scanner, targetZoneRules, this.formRef).then(() => {
          this.setState({ scanner, targetZoneRules })
        })
        break
      }
      case 'attributes':
        if (this.formRef && this.formRef.current) {
          this.formRef.current.setFieldsValue({ attributes: scanner.attributes })
        }
        break
      default: {
        this.setState({ scanner })
      }
    }
  }

  async store() {
    const { scanner } = this.state
    const scannerToSave: StwScanner = ScannerForm.mapEditableScannerToScanner(scanner)
    if (scannerToSave.id) {
      Scanners.update<StwScanner>(scannerToSave).then((value) => {
        this.redirect(value)
      })
    } else {
      Scanners.insert<StwScanner>(scannerToSave).then((value) => {
        this.redirect(value)
      })
    }
  }

  private redirect(scanner: StwScanner) {
    if (scanner && scanner.id) {
      navigate(`/devices/scanners${this.props.queryString ?? ''}`)
    }
  }

  private handlePlaceChange(
    value,
    scanner: StwEditableScanner,
    readerFilters: { [p: string]: any },
    targetZoneFilters: { [p: string]: any }
  ) {
    if (value) {
      ScannerForm.updateCodeAndDescription(scanner, this.formRef)
      readerFilters.placeId = scanner.place!.id
      targetZoneFilters.placeId = scanner.place!.id
    } else {
      DeviceUtility.resetCodeAndDescription(scanner, this.formRef)
      readerFilters.placeId = undefined
      targetZoneFilters.placeId = undefined
    }

    this.handleChange('reader', undefined)
    FormUtility.setFieldValue(scanner, 'reader', undefined, this.formRef)
    FormUtility.setFieldValue(scanner, 'targetZone', undefined, this.formRef)

    this.setState({
      scanner,
      readerFilters,
      targetZoneFilters,
    })
  }

  private static mapEditableScannerToScanner(editableScanner: StwEditableScanner): StwScanner {
    return {
      id: editableScanner.id,
      code: editableScanner.code,
      description: editableScanner.description,
      deviceController: editableScanner.deviceController,
      autoStart: editableScanner.autoStart,
      targetZone: editableScanner.targetZone,
      user: editableScanner.user,
      rfidAntennas: editableScanner.rfidAntennas,
      operation: editableScanner.operation,
      transferToZoneEnabled: editableScanner.transferToZoneEnabled,
      lifespan: editableScanner.lifespan,
      creationDate: editableScanner.creationDate,
      lastModifyDate: editableScanner.lastModifyDate,
      attributes: editableScanner.attributes,
    }
  }

  render() {
    const { scanner, readerFilters, antennaFilters, targetZoneFilters, targetZoneRules, loader } = this.state
    const title = scanner.id ? __(T.scanners.edit) : __(T.scanners.create_new)
    const { breadcrumbs, queryString } = this.props
    const fragment = !loader ? getFragmentObject(scanner, 'id', __(T.scanners.create_new)) : undefined
    return (
      <Form
        ref={this.formRef}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        onFinish={async () => {
          await this.store()
        }}
        style={{ width: '100%', height: '100%' }}
        initialValues={scanner}
        validateMessages={FormValidateMessages}
        scrollToFirstError
      >
        <StylewherePage {...this.props} noOverflow fragment={fragment}>
          <DefaultHeader
            backPath={getBackURL(queryString, breadcrumbs)}
            title={loader ? '...' : title}
            skeleton={{ active: loader }}
            actions={[
              {
                label: __(T.misc.cancel),
                type: 'cancel',
                onClick: () => navigate(`/devices/scanners${queryString ?? ''}`),
              },
              {
                label: scanner.id ? __(T.misc.update) : __(T.misc.create),
                type: 'submit',
              },
            ]}
          />
          <Section customClass="stw-section-page paged-header scroll">
            <AdvancedForm
              record={scanner}
              fields={SCANNERS_FIELDS}
              store={this.store}
              editing={scanner.id !== undefined}
              handleChange={this.handleChange}
              parameters={{
                readerFilters: readerFilters,
                antennaFilters: antennaFilters,
                deviceControllerTypeFilters: scannerDeviceControllerTypeFilters,
                operationFilters: scannerOperationFilters,
                targetZoneFilters: targetZoneFilters,
                targetZoneRules: [targetZoneRules],
              }}
            />
          </Section>
        </StylewherePage>
      </Form>
    )
  }
}

export default ScannerForm
