import { Input, Modal, Row } from 'antd'
import { Component } from 'react'
import { StwProductQuantityUI } from '../../api'
import { __, T } from '../../shared/i18n'

interface Props {
  visible: boolean
  callback: (confirm: boolean, value: number) => void
  icon?: any
  productQuantity?: StwProductQuantityUI
}

interface State {
  value: number
}

export class AdvancedFormModalChecklistQuantity extends Component<Props, State> {
  state = {
    value: 0,
  }

  componentDidMount = () => {
    const { productQuantity } = this.props
    if (productQuantity) {
      this._set(productQuantity.quantity)
    }
  }

  componentDidUpdate = (precProps) => {
    const { productQuantity } = this.props
    if (
      productQuantity &&
      productQuantity.quantity &&
      (!precProps.productQuantity || precProps.productQuantity.quantity !== productQuantity.quantity)
    ) {
      this._set(productQuantity.quantity)
    }
    return true
  }

  _set = (value) => {
    this.setState({ value: value })
  }

  handleChangeValue = (val) => {
    this.setState({ value: val })
  }

  action = (confirm) => {
    const { callback } = this.props
    const { value } = this.state
    callback(confirm, value!)
  }

  render() {
    const { visible, icon, productQuantity } = this.props
    const { value } = this.state
    return (
      <Modal
        centered
        open={visible}
        closable={false}
        onOk={() => this.action(true)}
        okText={__(T.misc.confirm)}
        onCancel={() => this.action(false)}
        cancelText={__(T.misc.cancel)}
      >
        {icon && <p style={{ textAlign: 'center', paddingTop: '20px' }}>{icon}</p>}
        <p style={{ textAlign: 'center', marginBottom: 0 }}>{__(T.titles.modalchecklistQuantity)}</p>
        {productQuantity && <p style={{ fontWeight: 700, textAlign: 'center' }}>{productQuantity.product.code}</p>}
        <Row style={{ flexDirection: 'column', alignItems: 'center' }}>
          <p style={{ marginBottom: 5 }}>
            <b>Quantity</b>
          </p>
          <Input
            id="modalValue"
            onChange={(event) => this.handleChangeValue(event.target.value)}
            placeholder="Insert data"
            value={value}
            style={{ textAlign: 'center', width: '50%' }}
          />
        </Row>
      </Modal>
    )
  }
}
