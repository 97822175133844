import { Component, PropsWithChildren, ReactNode } from 'react'
import { Layout } from 'antd'
import QueueAnim from 'rc-queue-anim'
import { RouteChildrenProps } from 'react-router-dom'
import '../theme/App.less'
import { PageBread, PageContent, PageHeader } from '.'
import { BreadcrumbsFragmentType, BreadcrumbsType } from './PageBread'
import { ModalPage } from './ModalPage'
import {
  AdvancedTableColumns,
  AdvancedTableConfig,
  AdvancedTableFilterValues,
  AdvancedTablePagination,
} from './AdvancedTable'

interface Props extends RouteChildrenProps, PropsWithChildren {
  title?: string
  actions?: ReadonlyArray<ReactNode>
  backPath?: string
  tabs?: ReadonlyArray<{ label: string; url: string; active: boolean; hidden: boolean }> // TODO bisogna trovare un altro modo per passare la configurazione dei tab, senza dover ripeterla in ogni tab
  breadcrumbs?: BreadcrumbsType
  fragment?: BreadcrumbsFragmentType[]
  directpath?: boolean
  pathlevel?: number
  pageHeaderClassName?: string
  numberData?: any
  managementColumns?: (...args: any[]) => any
  visibleManagerColumns?: boolean
  columns?: AdvancedTableColumns
  refreshTableColumns?: (...args: any[]) => any
  noOverflow?: boolean
  filters?: AdvancedTableConfig
  onFiltersChanged?: (filterKey: string, filterValue: any) => void
  onFiltersApplied?: (filterValues: AdvancedTableFilterValues) => void
  pagination?: AdvancedTablePagination
  filterApplied?: number
  queryString?: string
  manageColumnsPrefix?: string
  reloadData?: () => void
  multipleTitle?: any
  skeleton?: boolean
  skeletonOptions?: any
}

interface State {
  backPath: string
  breads?: BreadcrumbsType | undefined
  filterOpened: boolean
}

export class Page extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      backPath: '',
      filterOpened: false,
    }
  }

  componentDidMount() {
    const { breadcrumbs, fragment } = this.props
    if (breadcrumbs) {
      this.setBreadcrumbs(breadcrumbs, fragment)
    }
  }

  shouldComponentUpdate = (nextProps) => {
    const { breadcrumbs, fragment } = this.props
    if (
      JSON.stringify(breadcrumbs) !== JSON.stringify(nextProps.breadcrumbs) ||
      JSON.stringify(fragment) !== JSON.stringify(nextProps.fragment) ||
      (!fragment && nextProps.fragment)
    ) {
      this.setBreadcrumbs(nextProps.breadcrumbs, nextProps.fragment)
    }
    return true
  }

  setBreadcrumbs = async (breadcrumbs, fragment) => {
    const { queryString } = this.props
    const breadList = breadcrumbs
    let back = ''
    if (fragment && fragment.length > 0 && breadList) {
      const index = breadList.fragments.findIndex((bread) => bread.label === fragment[0].label)
      if (index === -1) {
        if (breadList.fragments[breadList.fragments.length - 1].path)
          back = breadList.fragments[breadList.fragments.length - 1].path || ''
        if (queryString && queryString !== '') {
          breadList.fragments[breadList.fragments.length - 1].path += queryString
        }
        breadList.fragments[breadList.fragments.length - 1].active = false
        for (let f = 0; f < fragment.length; f++) breadList.fragments.push(fragment[f])
      } else if (breadList.fragments.length > 2 && breadList.fragments[breadList.fragments.length - 2].path) {
        back = breadList.fragments[breadList.fragments.length - 2].path || ''
      }
      if (back !== '' && queryString && queryString !== '' && back.indexOf('?') === -1) {
        back += queryString
      }
    }
    this.setState({
      backPath: back,
      breads: breadList,
    })
  }

  /* to remove */
  shouldRenderPageHeader = () => {
    const { title, actions, backPath, tabs, multipleTitle, skeleton } = this.props
    return (
      (multipleTitle && multipleTitle.length > 0) ||
      (title !== undefined && title !== '') ||
      (actions !== undefined && actions.length > 0) ||
      (backPath !== undefined && backPath !== '') ||
      (tabs !== undefined && tabs.length > 0) ||
      skeleton ||
      true
    )
  }

  filterPanelOpened = (status) => {
    this.setState({ filterOpened: status })
  }

  render() {
    const {
      title,
      actions,
      tabs,
      children,
      pageHeaderClassName,
      numberData,
      columns,
      managementColumns,
      visibleManagerColumns,
      refreshTableColumns,
      noOverflow,
      location,
      history,
      match,
      filters,
      onFiltersApplied,
      onFiltersChanged,
      pagination,
      filterApplied,
      manageColumnsPrefix,
      reloadData,
      multipleTitle,
      skeleton,
      skeletonOptions,
    } = this.props
    const { backPath, breads, filterOpened } = this.state
    return (
      <Layout className="fullheight">
        <Layout className="site-layout">
          {breads && <PageBread breadcrumbs={breads} />}
          <PageHeader
            title={title}
            multipleTitle={multipleTitle}
            actions={actions}
            backPath={backPath}
            tabs={tabs}
            pageHeaderClassName={pageHeaderClassName}
            numberData={numberData}
            columns={columns}
            managementColumns={managementColumns}
            visibleManagerColumns={visibleManagerColumns}
            refreshTableColumns={refreshTableColumns}
            filters={filters}
            onFiltersChanged={onFiltersChanged}
            onFiltersApplied={onFiltersApplied}
            pagination={pagination}
            filterApplied={filterApplied}
            manageColumnsPrefix={manageColumnsPrefix}
            reloadData={reloadData}
            filterPanelOpened={this.filterPanelOpened}
            filterOpened={filterOpened}
            skeleton={skeleton}
            skeletonOptions={skeletonOptions}
          />
          <PageContent noOverflow={noOverflow}>
            <QueueAnim duration={300}>
              {filterOpened ? <div key="tabl-opacity" className="stw-table-opacity" /> : null}
            </QueueAnim>
            {children}
          </PageContent>
          {location && <ModalPage location={location} history={history} match={match} />}
        </Layout>
      </Layout>
    )
  }
}
