import Dashboard from './Dashboard'
import Login from './Login'
import ProductDetail from './Report/Products/detail'

import ReportList from './Report/Common/list'
import ReportAsnDetail from './Report/Asn/detail'
import ParcelDetail from './Report/Parcel/detail'
import ReportChecklistDetail from './Report/Checklist/detail'
import ReportPalletDetail from './Report/Pallet/detail'

import ItemDetail from './Report/Items/detail'

import ZoneList from './Configuration/Zone/list'
import ZoneDetail from './Configuration/Zone/detail'
import ZoneForm from './Configuration/Zone/form'

import PlacesList from './Configuration/Places/list'
import PlaceDetail from './Configuration/Places/detail'
import PlaceForm from './Configuration/Places/form'

import OperationsList from './Configuration/Settings/Operations/list'
import OperationForm from './Configuration/Settings/Operations/form'

import ItemConfigurationList from './Configuration/Settings/ItemConfigurations/list'
import ItemConfigurationForm from './Configuration/Settings/ItemConfigurations/form'

import ApplicationSettingList from './Configuration/Settings/ApplicationSetting/list'
import ApplicationSettingForm from './Configuration/Settings/ApplicationSetting/form'
import ApplicationSettingDetail from './Configuration/Settings/ApplicationSetting/detail'

import EnumerationTypesList from './Configuration/Settings/EnumerationTypes/list'
import EnumerationTypesDetail from './Configuration/Settings/EnumerationTypes/detail'
import EnumerationTypesForm from './Configuration/Settings/EnumerationTypes/form'
import RoleList from './Configuration/UsersRoles/Roles/list'
import RoleForm from './Configuration/UsersRoles/Roles/form'
import RoleDetail from './Configuration/UsersRoles/Roles/detail'
import RoleMappingList from './Configuration/UsersRoles/RoleMappings/list'
import RoleMappingDetail from './Configuration/UsersRoles/RoleMappings/detail'
import RoleMappingForm from './Configuration/UsersRoles/RoleMappings/form'
import UserList from './Configuration/UsersRoles/Users/list'
import UserForm from './Configuration/UsersRoles/Users/form'
import EntityAttributesList from './Configuration/EntityAttributes/list'
import ItemConfigurationDetail from './Configuration/Settings/ItemConfigurations/detail'
import OperationsDetail from './Configuration/Settings/Operations/detail'

import DeviceControllersList from './Devices/DeviceControllers/list'
import DeviceControllerDetail from './Devices/DeviceControllers/detail'
import DeviceControllerForm from './Devices/DeviceControllers/form'
import ScannersList from './Devices/Scanners/list'
import ScannerDetail from './Devices/Scanners/detail'
import ScannerForm from './Devices/Scanners/form'
import XspanList from './Devices/Xspan/list'
import XspanDetail from './Devices/Xspan/detail'
import XspanForm from './Devices/Xspan/form'
import RfidAntennasList from './Devices/RfidAntennas/list'
import RfidAntennaDetail from './Devices/RfidAntennas/detail'
import RfidAntennaForm from './Devices/RfidAntennas/form'
import PrintersList from './Devices/Printers/list'
import PrinterDetail from './Devices/Printers/detail'
import PrinterForm from './Devices/Printers/form'
import BarcodeReadersList from './Devices/BarcodeReaders/list'
import BarcodeReaderDetail from './Devices/BarcodeReaders/detail'
import BarcodeReaderForm from './Devices/BarcodeReaders/form'
import BalancesList from './Devices/Balances/list'
import BalanceDetail from './Devices/Balances/detail'
import BalanceForm from './Devices/Balances/form'
import RfidReadersList from './Devices/RfidReaders/list'
import RfidReaderDetail from './Devices/RfidReaders/detail'
import RfidReaderForm from './Devices/RfidReaders/form'
import WorkstationList from './Devices/Workstations/list'
import WorkstationForm from './Devices/Workstations/form'
import WorkstationDetail from './Devices/Workstations/detail'
import ConveyorList from './Devices/Conveyors/list'
import ConveyorDetail from './Devices/Conveyors/detail'
import ConveyorForm from './Devices/Conveyors/form'

import UserDetail from './Configuration/UsersRoles/Users/detail'
import CapabilitiesGroupList from './Configuration/UsersRoles/CapabilitiesGroup/list'
import CapabilitiesGroupDetail from './Configuration/UsersRoles/CapabilitiesGroup/detail'
import CapabilitiesGroupForm from './Configuration/UsersRoles/CapabilitiesGroup/form'
import SimpleParcelListOperationDetail from './Operations/SimpleParcelList/detail'
import SimpleItemList from './Operations/SimpleItemList/detail'
import OperationDetail from './Operations/Common/detail'
import ProductForm from './Report/Products/form'
import TransferToZoneOperationDetail from './Operations/TransferToZone/detail'
import PackingOperationDetail from './Operations/Packing/detail'
import InboundOperationDetail from './Operations/Inbound/detail'
import OutboundOperationDetail from './Operations/Outbound/detail'
import InventoryParcelOperationDetail from './Operations/InventoryParcel/detail'
import ChecklistForm from './Report/Checklist/form'
import InventoryOperationDetail from './Operations/Inventory/detail'
import ItemIdentifierReportDetail from './Report/ItemIdentifier/detail'
import NotificationList from './Notifications/list'

import { CAPABILITIES } from '../config/utility/capabilityUtility'
import SortingOperationDetail from './Operations/Sorting/detail'
import PickingEntryReportDetail from './Report/PickingEntry/detail'
import JobUserList from './Jobs/User/list'
import JobSystemList from './Jobs/System/list'
import JobImportList from './Jobs/Import/list'
import JobExportList from './Jobs/Export/list'
import PickingOperationDetail from './Operations/Picking/detail'
import PickingFlowsList from './Configuration/PickingFlows/list'
import PickingFlowDetail from './Configuration/PickingFlows/detail'
import PickingFlowForm from './Configuration/PickingFlows/form'

import ProductionOrderDetail from './Report/ProductionOrder/detail'
import ProductionOrderRowDetail from './Report/ProductionOrderRow/detail'

import ItemTypeList from './Configuration/Settings/ItemTypes/list'
import ItemTypeDetail from './Configuration/Settings/ItemTypes/detail'
import ItemTypeForm from './Configuration/Settings/ItemTypes/form'

import TemplatesList from './Configuration/Templates/list'
import TemplatesDetail from './Configuration/Templates/detail'
import TemplatesForm from './Configuration/Templates/form'

import ReportSessionItemListDetail from './Report/SimpleItemSessionList/detail'
import SessionItemSessionListOperationDetail from './Operations/SimpleItemSessionList/detail'

export const Routes = {
  '/': { component: Dashboard, private: true },
  '/login': { component: Login, public: true },
  '/dashboard': { component: Dashboard, private: true },

  '/reports/product/:productId': {
    component: ProductDetail,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_PRODUCT_VIEW],
  },
  '/reports/product/:productId/form': {
    component: ProductForm,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_PRODUCT_EDIT],
  },
  '/reports/checklist/:recordId': {
    component: ReportChecklistDetail,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_CHECKLIST_VIEW],
  },
  '/operationlogs/:operationType/:operationId/:operationInstanceId/simpleItemSession/:recordId': {
    component: ReportSessionItemListDetail,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_SIMPLE_ITEM_SESSION_LIST_VIEW],
  },
  '/reports/simpleItemSession/:recordId': {
    component: ReportSessionItemListDetail,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_SIMPLE_ITEM_SESSION_LIST_VIEW],
  },
  '/reports/checklist/:recordId/form': {
    component: ChecklistForm,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_CHECKLIST_EDIT],
  },
  '/reports/itemIdentifier/:itemIdentifierId': {
    component: ItemIdentifierReportDetail,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_ITEM_IDENTIFIER_VIEW],
  },
  '/reports/:reportId': { component: ReportList, private: true },
  '/reports/item/:itemId': { component: ItemDetail, private: true, capabilities: [CAPABILITIES.RESOURCE_ITEM_VIEW] },
  '/reports/simpleItemSessionEntry/:itemId': {
    component: ItemDetail,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_ITEM_VIEW],
  },
  '/reports/productionorders/:productionorderId': {
    component: ProductionOrderDetail,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_PRODUCTIONORDER_VIEW],
  },
  '/reports/productionorderrows/:itemId': {
    component: ProductionOrderRowDetail,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_PRODUCTIONORDERROW_VIEW],
  },
  '/reports/asn/:recordId': {
    component: ReportAsnDetail,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_ASN_VIEW],
  },
  '/reports/asn/:asnId/parcel/:recordId': {
    component: ParcelDetail,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_PARCEL_VIEW],
  },
  '/reports/:reportType/:reportId/item/:itemId': {
    component: ItemDetail,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_ITEM_VIEW],
  },
  '/reports/parcel/:recordId': {
    component: ParcelDetail,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_PARCEL_VIEW],
  },
  '/reports/pallet/:recordId': {
    component: ReportPalletDetail,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_PALLET_VIEW],
  },
  '/reports/pickingEntry/:recordId': {
    component: PickingEntryReportDetail,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_PICKING_ENTRY_VIEW],
  },
  '/configuration/zones': { component: ZoneList, private: true, capabilities: [CAPABILITIES.CONTROL_PANEL_ZONE_VIEW] },
  '/configuration/zones/:zoneId': {
    component: ZoneDetail,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_ZONE_VIEW],
  },
  '/configuration/zones/:zoneId/form': {
    component: ZoneForm,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_ZONE_EDIT],
  },
  '/configuration/places': {
    component: PlacesList,
    private: true,
    capabilities: [CAPABILITIES.CONTROL_PANEL_PLACE_VIEW],
  },
  '/configuration/places/:placeId': {
    component: PlaceDetail,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_PLACE_VIEW],
  },
  '/configuration/places/:placeId/form': {
    component: PlaceForm,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_PLACE_EDIT],
  },
  '/configuration/settings/operations': {
    component: OperationsList,
    private: true,
    capabilities: [CAPABILITIES.CONTROL_PANEL_OPERATION_VIEW],
  },
  '/configuration/settings/operations/:operationId': {
    component: OperationsDetail,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_OPERATION_VIEW],
  },
  '/configuration/settings/operations/:operationId/form': {
    component: OperationForm,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_OPERATION_EDIT],
  },
  '/configuration/settings/itemConfigurations': {
    component: ItemConfigurationList,
    private: true,
    capabilities: [CAPABILITIES.CONTROL_PANEL_ITEM_CONFIGURATION_VIEW],
  },
  '/configuration/settings/itemConfigurations/:itemConfigurationId': {
    component: ItemConfigurationDetail,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_ITEM_CONFIGURATION_VIEW],
  },
  '/configuration/settings/itemConfigurations/:itemConfigurationId/form': {
    component: ItemConfigurationForm,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_ITEM_CONFIGURATION_EDIT],
  },
  '/configuration/settings/applications': {
    component: ApplicationSettingList,
    private: true,
    capabilities: [CAPABILITIES.CONTROL_PANEL_APPLICATION_SETTING_VIEW],
  },
  '/configuration/settings/applications/:applicationId': {
    component: ApplicationSettingDetail,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_APPLICATION_SETTING_VIEW],
  },
  '/configuration/settings/applications/:applicationId/form': {
    component: ApplicationSettingForm,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_APPLICATION_SETTING_EDIT],
  },
  '/configuration/settings/itemTypes': {
    component: ItemTypeList,
    private: true,
    capabilities: [CAPABILITIES.CONTROL_PANEL_ITEM_TYPE_VIEW],
  },
  '/configuration/settings/itemTypes/:itemTypeId': {
    component: ItemTypeDetail,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_ITEM_TYPE_VIEW],
  },
  '/configuration/settings/itemTypes/:itemTypeId/form': {
    component: ItemTypeForm,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_ITEM_TYPE_EDIT],
  },
  '/configuration/templates': {
    component: TemplatesList,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_TEMPLATES_VIEW],
  },
  '/configuration/templates/:templateId': {
    component: TemplatesDetail,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_TEMPLATES_VIEW],
  },
  '/configuration/templates/:templateId/form': {
    component: TemplatesForm,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_TEMPLATES_EDIT],
  },
  '/configuration/enumerations': {
    component: EnumerationTypesList,
    private: true,
    capabilities: [CAPABILITIES.CONTROL_PANEL_ENUMERATION_TYPE_VIEW],
  },
  '/configuration/enumerations/:typeId': {
    component: EnumerationTypesDetail,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_ENUMERATION_TYPE_VIEW],
  },
  '/configuration/enumerations/:typeId/form': {
    component: EnumerationTypesForm,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_ENUMERATION_TYPE_EDIT],
  },
  '/configuration/usersRoles/roles': {
    component: RoleList,
    private: true,
    capabilities: [CAPABILITIES.CONTROL_PANEL_ROLE_VIEW],
  },
  '/configuration/usersRoles/roles/:roleId': {
    component: RoleDetail,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_ROLE_VIEW],
  },
  '/configuration/usersRoles/roles/:roleId/form': {
    component: RoleForm,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_ROLE_EDIT],
  },

  '/configuration/usersRoles/capabilitiesGroup': {
    component: CapabilitiesGroupList,
    private: true,
    capabilities: [CAPABILITIES.CONTROL_PANEL_CAPABILITY_GROUP_VIEW],
  },
  '/configuration/usersRoles/capabilitiesGroup/:capabilitiesGroupId': {
    component: CapabilitiesGroupDetail,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_CAPABILITY_GROUP_VIEW],
  },
  '/configuration/usersRoles/capabilitiesGroup/:capabilitiesGroupId/form': {
    component: CapabilitiesGroupForm,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_CAPABILITY_GROUP_EDIT],
  },

  '/configuration/usersRoles/roleMappings': {
    component: RoleMappingList,
    private: true,
    capabilities: [CAPABILITIES.CONTROL_PANEL_ROLE_MAPPING_VIEW],
  },
  '/configuration/usersRoles/roleMappings/:roleMappingId': {
    component: RoleMappingDetail,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_ROLE_MAPPING_VIEW],
  },
  '/configuration/usersRoles/roleMappings/:roleMappingId/form': {
    component: RoleMappingForm,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_ROLE_MAPPING_EDIT],
  },
  '/configuration/usersRoles/users': {
    component: UserList,
    private: true,
    capabilities: [CAPABILITIES.CONTROL_PANEL_USER_VIEW],
  },
  '/configuration/usersRoles/users/:userId': {
    component: UserDetail,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_USER_VIEW],
  },
  '/configuration/usersRoles/users/:userId/form': {
    component: UserForm,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_USER_EDIT],
  },
  '/configuration/entityAttributes': {
    component: EntityAttributesList,
    private: true,
  },
  '/configuration/entityAttributes/:entity': {
    component: EntityAttributesList,
    private: true,
  },
  '/configuration/pickingFlows': {
    component: PickingFlowsList,
    private: true,
    capabilities: [CAPABILITIES.CONTROL_PANEL_PICKING_VIEW],
  },
  '/configuration/pickingFlows/:pickingFlowId': {
    component: PickingFlowDetail,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_PICKING_VIEW],
  },
  '/configuration/pickingFlows/:pickingFlowId/form': {
    component: PickingFlowForm,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_PICKING_EDIT],
  },
  '/operationlogs/:operationType/:operationId': { component: ReportList, private: true },
  '/operationlogs/INVENTORY_PARCEL/:operationId/:operationInstanceId': {
    component: InventoryParcelOperationDetail,
    private: true,
  },
  '/operationlogs/INVENTORY/:operationId/:operationInstanceId': {
    component: InventoryOperationDetail,
    private: true,
  },
  '/operationlogs/INBOUND/:operationId/:operationInstanceId': {
    component: InboundOperationDetail,
    private: true,
  },
  '/operationlogs/OUTBOUND/:operationId/:operationInstanceId': {
    component: OutboundOperationDetail,
    private: true,
  },
  '/operationlogs/PACKING/:operationId/:operationInstanceId': {
    component: PackingOperationDetail,
    private: true,
  },
  '/operationlogs/TRANSFER_TO_ZONE/:operationId/:operationInstanceId': {
    component: TransferToZoneOperationDetail,
    private: true,
  },
  '/operationlogs/SIMPLE_ITEM_LIST/:operationId/:operationInstanceId': { component: SimpleItemList, private: true },
  '/operationlogs/:operationType/:operationId/:operationInstanceId/item/:itemId': {
    component: ItemDetail,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_ITEM_VIEW],
  },
  '/operationlogs/SIMPLE_PARCEL_LIST/:operationId/:operationInstanceId': {
    component: SimpleParcelListOperationDetail,
    private: true,
  },
  '/operationlogs/:operationType/:operationId/:operationInstanceId/parcel/:recordId': {
    component: ParcelDetail,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_PARCEL_VIEW],
  },
  '/operationlogs/SORTING/:operationId/:operationInstanceId': { component: SortingOperationDetail, private: true },
  '/operationlogs/PICKING/:operationId/:operationInstanceId': { component: PickingOperationDetail, private: true },
  '/operationlogs/SIMPLE_ITEM_SESSION_LIST/:operationId/:operationInstanceId': {
    component: SessionItemSessionListOperationDetail,
    private: true,
  },
  '/operationlogs/:operationType/:operationId/:operationInstanceId': { component: OperationDetail, private: true },
  '/devices/controllers': {
    component: DeviceControllersList,
    private: true,
    capabilities: [CAPABILITIES.CONTROL_PANEL_DEVICE_CONTROLLER_VIEW],
  },
  '/devices/controllers/:controllerId': {
    component: DeviceControllerDetail,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_DEVICE_CONTROLLER_VIEW],
  },
  '/devices/controllers/:controllerId/form': {
    component: DeviceControllerForm,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_DEVICE_CONTROLLER_EDIT],
  },

  '/devices/scanners': {
    component: ScannersList,
    private: true,
    capabilities: [CAPABILITIES.CONTROL_PANEL_SCANNER_VIEW],
  },
  '/devices/scanners/:scannerId': {
    component: ScannerDetail,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_SCANNER_VIEW],
  },
  '/devices/scanners/:scannerId/form': {
    component: ScannerForm,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_SCANNER_EDIT],
  },
  '/devices/xspans': {
    component: XspanList,
    private: true,
    capabilities: [CAPABILITIES.CONTROL_PANEL_XSPAN_READER_VIEW],
  },
  '/devices/xspans/:xspanId': {
    component: XspanDetail,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_XSPAN_READER_VIEW],
  },
  '/devices/xspans/:xspanId/form': {
    component: XspanForm,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_XSPAN_READER_EDIT],
  },
  '/devices/antennas': {
    component: RfidAntennasList,
    private: true,
    capabilities: [CAPABILITIES.CONTROL_PANEL_RFID_ANTENNA_VIEW],
  },
  '/devices/antennas/:antennaId': {
    component: RfidAntennaDetail,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_RFID_ANTENNA_VIEW],
  },
  '/devices/antennas/:antennaId/form': {
    component: RfidAntennaForm,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_RFID_ANTENNA_EDIT],
  },

  '/devices/printers': {
    component: PrintersList,
    private: true,
    capabilities: [CAPABILITIES.CONTROL_PANEL_PRINTER_VIEW],
  },
  '/devices/printers/:printerId': {
    component: PrinterDetail,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_PRINTER_VIEW],
  },
  '/devices/printers/:printerId/form': {
    component: PrinterForm,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_PRINTER_EDIT],
  },

  '/devices/barcodeReaders': {
    component: BarcodeReadersList,
    private: true,
    capabilities: [CAPABILITIES.CONTROL_PANEL_BARCODE_READER_VIEW],
  },
  '/devices/barcodeReaders/:barcodeReaderId': {
    component: BarcodeReaderDetail,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_BARCODE_READER_VIEW],
  },
  '/devices/barcodeReaders/:barcodeReaderId/form': {
    component: BarcodeReaderForm,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_BARCODE_READER_EDIT],
  },

  '/devices/balances': {
    component: BalancesList,
    private: true,
    capabilities: [CAPABILITIES.CONTROL_PANEL_BALANCE_VIEW],
  },
  '/devices/balances/:balanceId': {
    component: BalanceDetail,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_BALANCE_VIEW],
  },
  '/devices/balances/:balanceId/form': {
    component: BalanceForm,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_BALANCE_EDIT],
  },
  '/devices/rfidReaders': {
    component: RfidReadersList,
    private: true,
    capabilities: [CAPABILITIES.CONTROL_PANEL_RFID_READER_VIEW],
  },
  '/devices/rfidReaders/:rfidReaderId': {
    component: RfidReaderDetail,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_RFID_READER_VIEW],
  },
  '/devices/rfidReaders/:rfidReaderId/form': {
    component: RfidReaderForm,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_RFID_READER_EDIT],
  },
  '/devices/workstations': {
    component: WorkstationList,
    private: true,
    capabilities: [CAPABILITIES.CONTROL_PANEL_WORKSTATION_VIEW],
  },
  '/devices/workstations/:workstationId': {
    component: WorkstationDetail,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_WORKSTATION_VIEW],
  },
  '/devices/workstations/:workstationId/form': {
    component: WorkstationForm,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_WORKSTATION_EDIT],
  },
  '/devices/conveyors': {
    component: ConveyorList,
    private: true,
    capabilities: [CAPABILITIES.CONTROL_PANEL_CONVEYOR_VIEW],
  },
  '/devices/conveyors/:conveyorId': {
    component: ConveyorDetail,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_CONVEYOR_VIEW],
  },
  '/devices/conveyors/:conveyorId/form': {
    component: ConveyorForm,
    private: true,
    capabilities: [CAPABILITIES.RESOURCE_CONVEYOR_EDIT],
  },
  '/notifications': { component: NotificationList, private: true },
  '/jobs': { component: NotificationList, private: true },
  '/jobs/user': { component: JobUserList, private: true },
  '/jobs/system': { component: JobSystemList, private: true },
  '/jobs/import': { component: JobImportList, private: true },
  '/jobs/export': { component: JobExportList, private: true },
}
