import { DetailCell } from '../../api'
import { __ } from '../../shared/i18n'
import { AdvancedTableColumn, AdvancedTableContentKind } from '../../components/AdvancedTable'

export const MOVEMENT_PACKING_FIELD: DetailCell[] = [
  {
    attribute: 'type',
    type: 'enum',
  },
  {
    attribute: 'description',
    columns: 16,
  },
  {
    attribute: 'creationDate',
    type: 'date',
  },
  {
    attribute: 'creationUser',
    type: 'user',
  },
  {
    attribute: 'destinationZone',
    type: 'codeDesc',
  },
  {
    attribute: 'checklist',
    type: 'codeDesc',
  },
  {
    attribute: 'operation',
    type: 'codeDesc',
  },
]

export const MOVEMENT_FIELD: DetailCell[] = [
  {
    attribute: 'type',
    type: 'enum',
  },
  {
    attribute: 'description',
    columns: 16,
  },
  {
    attribute: 'creationDate',
    type: 'date',
  },
  {
    attribute: 'creationUser',
    type: 'user',
  },
  {
    attribute: 'destinationPlace',
    type: 'codeDesc',
  },
  {
    attribute: 'originPlace',
    type: 'codeDesc',
  },
  {
    attribute: 'destinationZone',
    type: 'codeDesc',
  },
  {
    attribute: 'asn',
    type: 'codeDesc',
  },
  {
    attribute: 'asn.shippingDate',
    type: 'date',
    title: __('fields.labels.shippingDate'),
  },
  {
    attribute: 'asn.receivingDate',
    type: 'date',
    title: __('fields.labels.receivingDate'),
  },
  {
    attribute: 'checklist',
    type: 'codeDesc',
  },
  {
    attribute: 'operation',
    type: 'codeDesc',
  },
]

export const PRODUCT_ROW_COL: AdvancedTableColumn[] = [
  {
    key: 'product.code',
    sortable: false,
    title: __('code'),
  },
  {
    key: 'product.description',
    sortable: false,
    title: __('description'),
    className: 'stw-large',
  },
  {
    key: 'detected',
    type: AdvancedTableContentKind.NUMBER,
    sortable: false,
    className: 'stw-small',
  },
  {
    key: 'unexpected',
    type: AdvancedTableContentKind.NUMBER,
    sortable: false,
    className: 'stw-small',
  },
]

export const PRODUCT_ROW_COL_PACKING: AdvancedTableColumn[] = [
  {
    key: 'product.code',
    sortable: false,
    title: __('code'),
  },
  {
    key: 'product.description',
    sortable: false,
    title: __('description'),
    className: 'stw-extra-large',
  },
]
