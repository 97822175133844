import { Component } from 'react'
import {
  GridData,
  StylewherePage,
  DefaultHeader,
  NotFound,
  Section,
  GridCards,
  Loader,
  AdvancedTable,
} from '../../../components'
import { DetailCell, StwAsn, StwFullInbound, StwFullOutbound } from '../../../api'
import Outbounds from '../../../api/Outbounds'
import Inbounds from '../../../api/Inbounds'
import { __, T } from '../../../shared/i18n'
import { ExtendRouteComponentProps } from '../../../types'
import { PARCEL_ENTRIES_COLUMNS } from '../../../config/Pages/Reports'
import { AdvancedTableSortValues } from '../../../components/AdvancedTable/advancedTableTypes'
import Reports from '../../../api/Reports'
import { TabBar } from '../../../components/TabBar'
import { SkeletonAttributeReport } from '../../../constants'
import { getCards, getFragmentObject, getMultipleTitle, getBackURL } from '../../../shared/utils'

interface State {
  record: StwAsn | null
  asnCells: DetailCell[]
  loader: boolean
  loaderData: boolean
  entries: StwFullInbound[] | StwFullOutbound[]
  tabSelected: string
  sortValues: AdvancedTableSortValues
  totalDetected: number
  totalUnexpected: number
  totalExpected: number
}

class ReportAsnDetail extends Component<ExtendRouteComponentProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      record: null,
      asnCells: [],
      loader: true,
      loaderData: false,
      entries: [],
      tabSelected: '',
      sortValues: {},
      totalDetected: 0,
      totalUnexpected: 0,
      totalExpected: 0,
    }
  }

  componentDidMount() {
    this.initialize()
  }

  initialize = async () => {
    let recordId = ''
    let asnDetail
    let tab = ''
    let entries
    if (this.props.match) {
      recordId = this.props.match.params.recordId
      asnDetail = (
        await Reports.report('asn', {
          id: recordId,
          page: 0,
          size: 1,
          sort: [],
        })
      ).content[0]

      const configuration = await Reports.getConfiguration('checklist')

      // chiamata api se zoneId è valido
      if (asnDetail && asnDetail.code) {
        // await getReportPlaces(asnDetail)
        // await getReportZones(asnDetail)
        tab = 'OUTBOUND'
      }
      entries = await this.getEntries(asnDetail.code, tab)

      const totalUnexpected = entries.content.reduce((sum, e) => sum + e.totalUnexpected, 0)
      const totalExpected = entries.content.reduce((sum, e) => sum + e.totalExpected, 0)
      const totalDetected = entries.content.reduce((sum, e) => sum + e.totalDetected, 0)
      this.setState({
        loader: false,
        record: asnDetail,
        asnCells: configuration,
        tabSelected: tab,
        entries: entries ? entries.content : [],
        totalExpected: totalExpected,
        totalUnexpected: totalUnexpected,
        totalDetected: totalDetected,
      })
    }
  }

  changeTab = async (id) => {
    const { tabSelected } = this.state
    if (id !== tabSelected) {
      this.setState(
        {
          tabSelected: id,
          loaderData: true,
          entries: [],
        },
        this.getData
      )
    }
  }

  getEntries = async (asnCode, tab) => {
    let entries
    if (tab === 'INBOUND') {
      entries = await Inbounds.searchFull({ asnCode: asnCode, page: 0, size: 1000000 })
    } else {
      entries = await Outbounds.searchFull({ asnCode: asnCode, page: 0, size: 1000000 })
    }
    return entries
  }

  getData = async () => {
    const entries = await this.getEntries(this.state.record!.code, this.state.tabSelected)
    const totalUnexpected = entries.content.reduce((sum, e) => sum + e.totalUnexpected, 0)
    const totalExpected = entries.content.reduce((sum, e) => sum + e.totalExpected, 0)
    const totalDetected = entries.content.reduce((sum, e) => sum + e.totalDetected, 0)
    this.setState({
      loaderData: false,
      entries: entries ? entries.content : [],
      totalExpected: totalExpected,
      totalUnexpected: totalUnexpected,
      totalDetected: totalDetected,
    })
  }

  render() {
    const {
      record,
      asnCells,
      sortValues,
      entries,
      loader,
      loaderData,
      tabSelected,
      totalUnexpected,
      totalDetected,
      totalExpected,
    } = this.state
    const { breadcrumbs, queryString } = this.props
    const fragment = getFragmentObject(record, 'id')
    return (
      <StylewherePage {...this.props} noOverflow fragment={fragment}>
        <DefaultHeader
          backPath={getBackURL(queryString, breadcrumbs)}
          multipleTitle={getMultipleTitle(record)}
          title={__(T.misc.not_found)}
          skeleton={{ active: loader, options: { multiple: true } }}
        />
        <Section customClass={`stw-section-page paged-header ${record || loader ? 'scroll transparent' : ''}`}>
          {record || loader ? (
            <>
              <GridData
                fields={loader ? SkeletonAttributeReport : asnCells}
                record={loader ? [] : record}
                skeleton={loader}
              />
              {!loader && (
                <Section style={{ marginTop: 15 }}>
                  <TabBar
                    tabs={[
                      { label: 'OUTBOUND', value: 'OUTBOUND' },
                      { label: 'INBOUND', value: 'INBOUND' },
                    ]}
                    selected={tabSelected}
                    onChangeTab={this.changeTab}
                  />
                  {loaderData && <Loader />}
                  {!loaderData && (
                    <>
                      <GridCards
                        cards={getCards(totalDetected, totalExpected - totalUnexpected, totalExpected, totalUnexpected)}
                      />
                      <AdvancedTable
                        loader={loader}
                        tableTitle={{ title: __(T.asn.parcels), emptyTitle: __(T.asn.noParcelsFoundTitle) }}
                        pagination={{
                          pageNumber: 1,
                          pageSize: entries.length,
                          sortValues: sortValues,
                        }}
                        columns={PARCEL_ENTRIES_COLUMNS}
                        filters={{
                          filterDefinitions: [],
                        }}
                        content={[...entries]}
                        totalElements={entries.length}
                        disabledNoDataFound
                        disabledPagination
                        activeDefaultClassColumn
                      />
                    </>
                  )}
                </Section>
              )}
            </>
          ) : (
            <NotFound fullheight title={__(T.misc.noRecordFoundTitle)} />
          )}
        </Section>
      </StylewherePage>
    )
  }
}

export default ReportAsnDetail
