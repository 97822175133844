import { Component, Fragment } from 'react'
import { Col, Row, Button } from 'antd'
import dayjs from 'dayjs'
import { DetailCell } from '../api'
import { KeyValueCell } from './KeyValueCell'
import { AttributeUtil } from '../config/utility/utility'
import { __ } from '../shared/i18n'

interface Props {
  fields: DetailCell[]
  data: any
  skeleton?: boolean
}

export class KeyValueGrid extends Component<Props> {
  formatDate = (value: string): string => {
    if (!value || value === 'n/a' || value === '') return 'n/a'
    return dayjs(value).format('DD/MM/YYYY HH:mm:ss')
  }

  openUrl = (url) => {
    window.open(url, '_blank')
  }

  getValue = (field: DetailCell, data) => {
    const value = AttributeUtil.getAttribute(data, field.attribute)
    switch (field.type) {
      case 'codeDesc':
        if (value) {
          return value.description ? `${value.code} - ${value.description}` : value.code
        }
        return 'n/a'
      case 'valueDesc':
        if (value && value.value) {
          return value.description ? `${value.value} - ${value.description}` : value.value
        }
        return 'n/a'
      case 'user':
        return value ? value.username : 'n/a'
      case 'enum':
        return value ? __(`enum.${value}`) : 'n/a'
      case 'date':
        return value ? this.formatDate(value) : 'n/a'
      case 'boolean':
        return value ? __('misc.yes') : __('misc.no')
      case 'stringList':
        return value && value.length > 0
          ? value.reduce((previousValue, currentValue) => previousValue.concat(' - ').concat(currentValue))
          : 'n/a'
      case 'json':
        return value ? <pre id="json">{JSON.stringify(value, undefined, 2)}</pre> : 'n/a'
      case 'custom':
        return field.render ? field.render(value) : 'n/a'
      case 'link':
        return (
          <Button
            onClick={() => this.openUrl(value)}
            style={{ padding: 0, whiteSpace: 'normal', textAlign: 'left' }}
            type="link"
          >
            {value}
          </Button>
        )
      case 'number':
        return value !== '' && typeof value === 'number' ? value : 'n/a'
      default:
        return value && value !== '' ? value : 'n/a'
    }
  }

  render() {
    const { fields, data, skeleton } = this.props
    return (
      <Row gutter={[15, 15]} style={{ marginBottom: '0px' }}>
        {fields.map((field) => {
          return (
            <Fragment key={field.attribute}>
              <Col
                key={field.attribute}
                xxl={field.columns || 8}
                xl={field.columns || 8}
                lg={field.columns || 8}
                md={24}
                xs={24}
              >
                <KeyValueCell
                  key={`${field.attribute}.keyValue`}
                  title={skeleton ? '' : field.title || __(`fields.labels.${field.attribute}`)}
                  value={skeleton ? '' : field.render?.(data) || this.getValue(field, data)}
                  skeleton={skeleton}
                  type={field.type || ''}
                />
              </Col>
              {field.fillCols && <Col key={`${field.attribute}.fill`} span={field.fillCols} />}
            </Fragment>
          )
        })}
      </Row>
    )
  }
}
