import { Component } from 'react'
import { Modal, Radio, Form } from 'antd'
import { __ } from '../shared/i18n'
import DraggableCheckboxList from './DraggableCheckboxList'
import { AdvancedTableColumn } from './AdvancedTable'
import AsyncJobs from '../api/AsyncJobs'
import { ModalHeader, ModalAddButton } from '.'

interface Props {
  title: string
  filenamePrefix?: string
  endpoint: string
  columns: AdvancedTableColumn[]
  visible?: boolean
  hide: () => void
}

interface State {
  exportColumns: any[]
  exportType: 'csv' | 'xls'
}

export class AdvancedTableExportModal extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      exportColumns: props.columns,
      exportType: 'csv',
    }
  }

  doExport = async () => {
    const { filenamePrefix, endpoint, title } = this.props
    const { exportColumns } = this.state
    await AsyncJobs.insert(
      {
        jobType: 'export',
        description: `${title}_${filenamePrefix || 'export'}_${new Date().getTime()}`,
        jobData: {
          downloadUrl: endpoint,
          exportFileFormat: this.state.exportType ?? 'csv',
          exportColumns: exportColumns
            .filter((exportColumn) => exportColumn.hidden === false)
            .map((exportColumn, index) => ({
              seqNumber: index,
              jsonField: exportColumn.dataIndex,
              fileColumnLabel: __(`fields.labels.${exportColumn.key}`, exportColumn.title),
            })),
        },
      },
      undefined,
      this.getInsertJobMessage()
    )
  }

  getInsertJobMessage = () => {
    const href = '/jobs/export'
    return (
      <>
        {__('jobs.requestReceived')} <a href={href}>{__('jobs.link')}</a>
      </>
    )
  }

  handleCancel = () => {
    this.hide()
  }

  handleOk = () => {
    this.doExport().then(this.hide)
  }

  hide = () => {
    this.setState({ exportColumns: [] }, this.props.hide)
  }

  onTypeChange = (value) => {
    this.setState({ exportType: value.target.value })
  }

  render() {
    const { visible, columns } = this.props
    const exportTypeOptions = [
      {
        label: 'CSV',
        value: 'csv',
      },
      {
        label: 'Excel',
        value: 'xls',
      },
    ]
    return (
      <Modal
        centered
        title={<ModalHeader close={this.handleCancel} title={__('messages.export.select_column_to_export')} />}
        onOk={this.handleOk}
        closable={false}
        onCancel={this.handleCancel}
        open={visible}
        footer={null}
        width="40%"
        className="stw-workstation-rfid-antennas-modal"
      >
        <Form.Item label={__('fields.labels.format')} name="layout">
          <Radio.Group onChange={this.onTypeChange} value={this.state.exportType} optionType="button">
            {exportTypeOptions.map((option) => (
              <Radio
                style={option.value === this.state.exportType ? { backgroundColor: '#ceff00' } : {}}
                key={option.value}
                value={option.value}
              >
                {option.label}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
        <DraggableCheckboxList
          items={columns}
          field="hidden"
          style={{ maxHeight: '50vh' }}
          itemRender={(item: any) => __(`fields.labels.${item.key}`, item.title)}
          onChange={(items) => this.setState({ exportColumns: items })}
        />
        <ModalAddButton pb={5} mTop={35} close={this.handleCancel} confirmType="button" save={this.handleOk} />
      </Modal>
    )
  }
}
