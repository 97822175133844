import { Component, createRef } from 'react'
import { Form, FormInstance } from 'antd'
import ItemTypes from 'core/api/ItemTypes'
import _ from 'lodash'
import { AdvancedForm, ModalAddOption, StylewherePage, Section, DefaultHeader } from '../../../../components'
import { ExtendRouteComponentProps } from '../../../../types'
import Operations from '../../../../api/Operations'
import OperationOptions from '../../../../api/OperationOptions'
import { OPERATIONS_FIELD } from '../../../../config/Pages/Settings/Operations'
import { navigate } from '../../../../shared/router'
import { __, T } from '../../../../shared/i18n'
import { SimpleAttributesMap, StwItemType, StwOperation, StwOperationOption, StwOperationType } from '../../../../api'
import { AttributeUtil } from '../../../../config/utility/utility'
import { FormValidateMessages, IdentifierTypes, OperationClients, OperationTypes } from '../../../../constants'
import { FormUtility } from '../../../../config/utility/formUtility'
import { AdvancedFormInputType } from '../../../../components/AdvancedForm/advancedFormTypes'
import { OPTIONS_DEFINITION } from '../../../../config/Pages/Settings/OperationOptions'
import { getFragmentObject, getBackURL } from '../../../../shared/utils'

type StwOperationWithArrayOptions = Omit<StwOperation, 'options'> & {
  options: { [key: string]: string | string[] }
}

interface State {
  operation: StwOperationWithArrayOptions
  loader: boolean
  options: any[]
  isCodeEditableByDescription: boolean
  isAddOptionModalVisible: boolean
  batchValidateStatuses: { id: string; description: string }[]
}

class OperationForm extends Component<ExtendRouteComponentProps, State> {
  enabledOperation = false
  formRef = createRef<FormInstance>()

  constructor(props) {
    super(props)
    this.state = {
      operation: { options: {}, displayPriority: 0 },
      options: [],
      batchValidateStatuses: [],
      loader: true,
      isCodeEditableByDescription: true,
      isAddOptionModalVisible: false,
    }
  }

  componentDidMount() {
    this.initialize()
  }

  getBatchValidateStatuses = async (operation: {
    id?: string
    type?: StwOperationType
  }): Promise<{ id: string; description: string }[]> => {
    const batchValidateStatuses = await Operations.getBatchValidateStatuses(operation)
    return batchValidateStatuses.map((batchValidateStatus) => ({
      id: batchValidateStatus,
      description: batchValidateStatus,
    }))
  }

  initialize = async () => {
    let result: StwOperation = {}
    let options
    let batchValidateStates
    if (this.props.match) {
      const operationId = this.props.match.params.operationId
      if (operationId !== '' && operationId !== 'create') {
        result = await Operations.get<StwOperation>(operationId)
        this.enabledOperation = result && result.enabled ? result.enabled : false
        if (result) {
          if (!result.actions || result.actions === null) {
            result.actions = []
          }
          if (result.formSchema) {
            result.formSchema = JSON.stringify(result.formSchema, undefined, 2)
          }
          options = await this.getOperationOptions(result.type)

          //add custom options
          const keys = Object.keys(result.options || {})
          let index
          for (let k = 0; k < keys.length; k++) {
            index = options.findIndex((option) => option.optionKey === keys[k])
            if (index < 0 && !keys[k].includes('itemTypeConfig_')) {
              options.push({
                optionKey: keys[k],
                operationType: result.type,
                data: this.getOptionData(`${keys[k]}`),
              })
            }
          }

          //set default value for system options
          let optKey
          const hasOption = (idx: number, key: string) => options[idx].optionKey === key
          for (let k = 0; k < options.length; k++) {
            index = keys.findIndex((key) => hasOption(k, key))
            if (index === -1) {
              if (options[k].data && options[k].data.defaultValue !== undefined) {
                optKey = options[k].optionKey //.replace('options.', '')
                if (!result.options || !result.options[optKey]) {
                  if (!result.options) result.options = {}
                  result.options[optKey] = `${options[k].data.defaultValue}`
                }
              }
            }
          }

          const optsItemConfig = await this.getItemTypeConfigOptions(result.type, result.options || {})
          options = options.concat(optsItemConfig)

          // retrieve batch validate states from operationtype
          batchValidateStates = await this.getBatchValidateStatuses(result)
        }
      }
    }
    this.setState(
      {
        operation: this.wrapArrayOptions(result) || {},
        isCodeEditableByDescription: !result.id,
        loader: false,
        options: options || [],
        batchValidateStatuses: batchValidateStates || [],
      },
      this.updateFormFieldsValue
    )
  }

  getItemTypeConfigOptions = async (type, options) => {
    const opts: any = []
    if (type === 'ENCODING') {
      const itemTypes = await ItemTypes.search<StwItemType>()
      itemTypes.content.forEach((itemType) => {
        opts.push({
          optionKey: `itemTypeConfig_${itemType.code}`,
          operationType: type,
          data: this.getOptionDataItemType(`itemTypeConfig_${itemType.code}`, options, itemType.id || ''),
        })
      })
    }
    return opts
  }

  updateFormFieldsValue = () => {
    if (this.formRef && this.formRef.current) {
      this.formRef.current.setFieldsValue(this.state.operation)
    }
  }

  getOperationOptions = async (type) => {
    const options = await OperationOptions.search<StwOperationOption>({ operationType: type, sort: 'optionKey' })
    const opts: any[] = []
    let optionKey
    let optionKeyReplace
    for (let o = 0; o < options.content.length; o++) {
      optionKey = options.content[o].optionKey
      optionKeyReplace = optionKey.replace('options.', '')
      opts.push({
        id: options.content[o].id || true,
        optionKey: options.content[o].optionKey,
        operationType: type,
        data: this.getOptionData(`${optionKeyReplace}`),
      })
    }
    return opts
  }

  getOptionDataItemType = (optionKey, options, itemTypeId: string) => {
    const obj = OPTIONS_DEFINITION.find((element) => element.key === `options.itemTypeConfig_`)
    if (obj) {
      return {
        key: optionKey,
        description:
          "Indica il codice della ItemConfiguration da usare, tra quelle valide, quando si fa l'encoding di un prodotto con itemType <XXX>",
        type: 'AUTOCOMPLETE',
        placeholder: 'Item type',
        autocomplete: {
          endpoint: `/api/v1/itemConfigurations?itemTypeIds=${itemTypeId}`,
          sort: 'code,asc',
          multiple: false,
          selectionOption: options && options[optionKey] ? options[optionKey] : '',
        },
        col: 24,
        rules: [
          {
            required: true, // false
          },
        ],
        defaultValue: '',
      }
    }
    return obj
  }

  getOptionData = (optionKey) => {
    let obj: any = OPTIONS_DEFINITION.find((element) => {
      return element.key === `options.${optionKey}` || ''
    })
    if (optionKey.includes('itemTypeConfig_')) {
      obj = _.clone(OPTIONS_DEFINITION.find((element) => element.key === `options.itemTypeConfig_`))
      obj.defaultValue = 'scarpe'
      // obj.autocomplete.selectionOption = result.options.itemTypeConfig_default
      obj.key = optionKey
    }
    if (!obj) {
      obj = {
        key: `options.${optionKey}`,
        description: '',
        type: AdvancedFormInputType.TEXT,
        defaultValue: '',
        placeholder: __('misc.insertValue'),
        rules: [{ required: true }],
        col: 24,
      }
    }
    return obj
  }

  handleChange = async (key, value) => {
    const { operation, isCodeEditableByDescription } = this.state
    AttributeUtil.setAttribute(operation, key, value)
    if (key === 'description' && isCodeEditableByDescription) {
      FormUtility.handleDescription(value, operation, this.formRef)
      this.setState({
        operation: operation,
      })
    } else if (key === 'code') {
      this.setState({ isCodeEditableByDescription: false })
    } else if (key === 'type') {
      let opts: any[] = await this.getOperationOptions(value)
      const optsItemConfig = await this.getItemTypeConfigOptions(value, operation.options || {})
      opts = opts.concat(optsItemConfig)

      const options = {}
      for (let o = 0; o < opts.length; o++) {
        options[opts[o].optionKey] = opts[o].data.defaultValue
      }
      operation.options = options
      const batchValidateStatuses = await this.getBatchValidateStatuses(operation)
      this.setState({
        options: opts,
        operation: operation,
        batchValidateStatuses,
      })
      if (this.formRef && this.formRef.current) {
        this.formRef.current.setFieldsValue({ options: options })
      }
    } else {
      this.setState({
        operation: operation,
      })
      if (this.formRef && this.formRef.current) {
        if (key === 'attributes') {
          this.formRef.current.setFieldsValue({ attributes: operation.attributes })
        } else if (key === 'actions') {
          this.formRef.current.setFieldsValue({ actions: operation.actions })
        }
      }
    }
  }

  handleChangeOptions = (key: string, value) => {
    const { operation, options } = this.state
    const operationOptions = operation.options || {}
    const optionKey = key.replace(/options./g, '')
    if (options && !options.find((option) => option.optionKey === optionKey)) {
      options.push({
        optionKey: optionKey,
        operationType: operation.type,
        data: this.getOptionData(optionKey),
      })
    }
    operationOptions[optionKey] = value
    this.setState(
      (prevState) => ({
        operation: {
          ...prevState.operation,
          options: operationOptions,
        },
        options,
      }),
      () => {
        if (this.formRef.current) {
          this.formRef.current.setFieldsValue({ options: operationOptions })
        }
      }
    )
  }

  handleRemoveOption = (key: string) => {
    const { operation, options } = this.state
    const optionKey = key.replace(/options./g, '')
    const index = options.findIndex((option) => option.optionKey === optionKey)
    options.splice(index, 1)
    const castedOptions: { [key: string]: string | string[] } = {}
    let keyOpt = ''
    for (let o = 0; o < options.length; o++) {
      keyOpt = options[o].optionKey
      if (operation.options && operation.options[keyOpt])
        castedOptions[options[o].optionKey] = operation.options[keyOpt]
    }
    this.setState((prevState) => ({
      operation: {
        ...prevState.operation,
        options: castedOptions,
      },
      options,
    }))
  }

  store = () => {
    const operation = this.unwrapArrayOptions(this.state.operation)
    const { queryString } = this.props
    //fix for empty formSchema
    if (operation.formSchema === '') {
      operation.formSchema = '{}'
    }
    //si rimappano i details
    if (operation.actions) {
      const keys = Object.keys(operation.actions)
      const actions: any[] = []
      for (let k = 0; k < keys.length; k++) {
        actions.push(operation.actions[keys[k]])
      }
      operation.actions = actions
    }
    Object.keys(operation.options ?? {}).forEach((key) => {
      if (!key.includes('itemTypeConfig_')) return
      operation.options![key] = (operation.options![key] as any).code
    })

    if (!operation.displayPriority) {
      operation.displayPriority = 0
    }

    if (operation.options) {
      const keys = Object.keys(operation.options || {})
      for (let k = 0; k < keys.length; k++) {
        if (operation.options[keys[k]] === '') {
          delete operation.options[keys[k]]
        }
      }
    }
    if (operation && operation.id) {
      Operations.update<StwOperation>(operation).then(() => {
        if (operation.type !== 'TAG_INFO') {
          this.dispatchEvent(operation.enabled)
        }
        navigate(`/configuration/settings/operations${queryString ?? ''}`)
      })
    } else {
      Operations.insert<StwOperation>(operation).then((_operation) => {
        if (_operation && _operation.id) {
          this.dispatchEvent(operation.enabled)
          navigate(`/configuration/settings/operations${queryString ?? ''}`)
        }
      })
    }
  }

  dispatchEvent = (enabled) => {
    //dispatch event if is changed enabled field
    if (this.enabledOperation !== enabled) {
      document.dispatchEvent(new Event('operationLogs'))
    }
  }

  showAddOption = () => {
    this.setState({ isAddOptionModalVisible: true })
  }

  hideAddOption = () => {
    this.setState({ isAddOptionModalVisible: false })
  }

  addOption = (option) => {
    this.handleChangeOptions(`options.${option.key}`, option.value)
    this.hideAddOption()
  }

  wrapArrayOptions = (operation: StwOperation): StwOperationWithArrayOptions => {
    const operationWithArrayOptions: StwOperationWithArrayOptions = { ...operation, options: {} }
    if (operation.options) {
      for (const key in operation.options) {
        if (Object.prototype.hasOwnProperty.call(operation.options, key)) {
          const value = operation.options[key].split(',')
          operationWithArrayOptions.options[key] = value.length === 0 || value.length > 1 ? value : value[0]
        }
      }
    }
    return operationWithArrayOptions
  }

  unwrapArrayOptions = (operationWithArrayOptions: StwOperationWithArrayOptions): StwOperation => {
    const options: SimpleAttributesMap = {}
    for (const key in operationWithArrayOptions.options) {
      if (Object.prototype.hasOwnProperty.call(operationWithArrayOptions.options, key)) {
        const value = operationWithArrayOptions.options[key]
        options[key] = Array.isArray(value) ? value.join(',') : value
      }
    }
    return { ...operationWithArrayOptions, options }
  }

  render() {
    const { breadcrumbs, queryString } = this.props
    const { operation, options, loader, isAddOptionModalVisible, batchValidateStatuses } = this.state
    const title = operation.id ? __(T.operation.edit) : __(T.operation.create_new)
    const fragment = !loader ? getFragmentObject(operation, 'id', __(T.operation.create_new)) : undefined
    return (
      <>
        <Form
          ref={this.formRef}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          layout="vertical"
          onFinish={this.store}
          style={{ width: '100%', height: '100%' }}
          initialValues={operation}
          validateMessages={FormValidateMessages}
          scrollToFirstError
        >
          <StylewherePage {...this.props} noOverflow fragment={fragment}>
            <DefaultHeader
              backPath={getBackURL(queryString, breadcrumbs)}
              title={loader ? '...' : title}
              skeleton={{ active: loader }}
              actions={[
                {
                  label: __(T.misc.cancel),
                  type: 'cancel',
                  onClick: () => navigate(`/configuration/settings/operations${queryString ?? ''}`),
                },
                {
                  label: operation.id ? __(T.misc.update) : __(T.misc.create),
                  type: 'submit',
                },
              ]}
            />
            <Section customClass="stw-section-page paged-header scroll">
              <AdvancedForm
                record={operation}
                handleChange={this.handleChange}
                handleChangeOptions={this.handleChangeOptions}
                handleRemoveOption={this.handleRemoveOption}
                parameters={{
                  types: OperationTypes,
                  options: options,
                  attributeKeys: IdentifierTypes,
                  operationClients: OperationClients,
                  ignoreStates: batchValidateStatuses,
                  warningStates: batchValidateStatuses,
                  errorStates: batchValidateStatuses,
                  ignoreWithReasonStates: batchValidateStatuses,
                }}
                fields={OPERATIONS_FIELD}
                editing={operation.id ? operation.id !== '' : false}
                showAddOption={this.showAddOption}
              />
            </Section>
          </StylewherePage>
        </Form>
        <ModalAddOption
          visible={isAddOptionModalVisible}
          options={options}
          save={this.addOption}
          close={this.hideAddOption}
          optionsDefinition={OPTIONS_DEFINITION}
        />
      </>
    )
  }
}

export default OperationForm
