import { default as i18next, TOptions } from 'i18next'
import { initReactI18next } from 'react-i18next'

import en from '../translations/en'
import it from '../translations/it'

const resources = {
  en: { translation: en },
  it: { translation: it },
}

i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  })

////////////////////////////////////////////////////
// HACK: use languages auto-completion
////////////////////////////////////////////////////
interface TranslationCategory {
  [key: string]: string
}

let TRANSLATION_MAP = en

// replace object values with keys
Object.keys(en).forEach((catKey, idx) => {
  const subCat: TranslationCategory = {}
  Object.keys(en[catKey]).forEach((key) => {
    subCat[key] = `${catKey}.${key}`
  })

  if (idx === 0) {
    TRANSLATION_MAP = {} as typeof en
  }

  TRANSLATION_MAP[catKey] = subCat
})
export { TRANSLATION_MAP as T }
////////////////////////////////////////////////////

// eslint-disable-next-line @typescript-eslint/naming-convention
export const __ = (key: string, defaultValue?: string) => {
  const result = i18next.t(key)
  return result !== key || defaultValue === undefined ? result : defaultValue
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const __UP = (key: string, interpolationMap?: TOptions) => i18next.t(key, interpolationMap).toUpperCase()

export const changeLanguage = (locale: string) => {
  i18next.changeLanguage(locale)
}

export default i18next
