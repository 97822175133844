import BaseResource from './BaseResource'
import { StwChecklist, StwFullChecklist, StwPlace, StwUser } from './types'
import Places from './Places'
import Users from './Users'

export default class Checklists extends BaseResource {
  static endpoint = '/api/v1/checklists' // /logistics

  static async getFullChecklist(recordId: string): Promise<StwFullChecklist> {
    const checklist = await this.get<StwChecklist>(recordId)

    const places = await Places.searchByIds<StwPlace>([checklist.checklistPlaceId, checklist.destinationPlaceId!])
    const users = await Users.searchByIds<StwUser>([
      checklist.creationUserId,
      checklist.lastModifyUserId,
      checklist.confirmationUserId,
    ])

    /*let items: StwItem[] = []
    if (checklist.type === StwChecklistType.CHECKLIST_BY_ITEM && checklist.itemIds.length > 0) {
      items = await Items.search<StwItem>({ ids: checklist.itemIds, size: 1000, page: 0 }).then((res) => res.content)
    }

    const fullProducts: StwFullChecklistProduct[] = []
    if (checklist.type === StwChecklistType.CHECKLIST_BY_PRODUCT_TYPE) {
      const productCodes = checklist.products ? checklist.products.map((product) => product.productCode) : []
      if (productCodes.length > 0) {
        const products = await Products.search<StwProduct>({ equalCodes: productCodes })
        for (const product of products.content) {
          if (checklist.products != null) {
            const originalProduct = checklist.products.filter((value) => value.productCode === product.code)[0]

            fullProducts.push({
              product: product,
              quantity: originalProduct.quantity,
              attributes: originalProduct.attributes,
            })
          }
        }
      }
    }*/

    return {
      ...checklist,
      checklistPlace: places[`${checklist.checklistPlaceId}`],
      destinationPlace: places[`${checklist.destinationPlaceId}`],
      creationUser: users[`${checklist.creationUserId}`],
      lastModifyUser: users[`${checklist.creationUserId}`],
      confirmationUser: users[`${checklist.confirmationUserId}`],
      //items: items,
      //products: fullProducts,
    }
  }
}
