import { Component, ReactNode } from 'react'
import { PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons'
import { Button, Tag } from 'antd'
import { GridData, StylewherePage, Section, DefaultHeader, NotFound, AdvancedTable } from '../../../components'
import { DetailCell, StwFullScanner, StwRfidAntenna, StwScanner, StwScannerStatus } from '../../../api'
import Scanners from '../../../api/Scanners'
import { __, T } from '../../../shared/i18n'
import { ExtendRouteComponentProps } from '../../../types'
import { SCANNER_ANTENNAS_COLUMNS, SCANNERS_DATA } from '../../../config/Pages/Devices/Scanners'
import RfidAntennas from '../../../api/RfidAntennas'
import { CAPABILITIES, hasAnyCapabilities } from '../../../config/utility/capabilityUtility'
import {
  getDetailDataWithAttributesMapping,
  getMultipleTitle,
  getBackURL,
  getFragmentObject,
} from '../../../shared/utils'
import { DEFAULT_PAGE_SIZE } from '../../../constants'

interface State {
  scanner: StwFullScanner | undefined
  scannerStatus: StwScannerStatus | undefined
  timeouts: any[]
  loader: boolean
  detailData: DetailCell[]
}

class ScannerDetail extends Component<ExtendRouteComponentProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      scanner: undefined,
      scannerStatus: undefined,
      detailData: SCANNERS_DATA,
      timeouts: [],
      loader: true,
    }
  }

  componentDidMount = async () => {
    if (this.props.match) {
      const scannerId = this.props.match.params.scannerId
      if (scannerId) {
        const scanner = await Scanners.get<StwScanner>(scannerId).then((res) => res)
        const data: DetailCell[] = await getDetailDataWithAttributesMapping(SCANNERS_DATA, 'scanner', 2)

        await RfidAntennas.search<StwRfidAntenna>({ ids: scanner.rfidAntennas }).then((res) => {
          const antennas = res.content
          const fullScanner: StwFullScanner = ScannerDetail.mapScannerToFullScanner(scanner, antennas)
          this.setState(
            {
              scanner: fullScanner,
              detailData: data,
              loader: false,
            },
            this.fetchScannerStatus
          )
        })
      }
    }
  }

  fetchScannerStatus = async () => {
    const { timeouts, scanner } = this.state
    if (scanner && hasAnyCapabilities(CAPABILITIES.RESOURCE_SCANNER_VIEW)) {
      const scannerStatus = await Scanners.getScannerStatus(scanner.id!)
      this.setState({
        scannerStatus,
        timeouts,
      })
      timeouts.push(
        setTimeout(() => {
          this.fetchScannerStatus()
        }, 2000)
      )
    }
  }

  componentWillUnmount() {
    const { timeouts } = this.state
    timeouts.forEach((timeout) => clearTimeout(timeout))
    this.setState({ timeouts: [] })
  }

  private static mapScannerToFullScanner(scanner: StwScanner, antennas: StwRfidAntenna[]): StwFullScanner {
    return {
      id: scanner.id,
      code: scanner.code,
      description: scanner.description,
      deviceController: scanner.deviceController,
      autoStart: scanner.autoStart,
      targetZone: scanner.targetZone,
      user: scanner.user,
      rfidFullAntennas: antennas,
      operation: scanner.operation,
      transferToZoneEnabled: scanner.transferToZoneEnabled,
      lifespan: scanner.lifespan,
      creationDate: scanner.creationDate,
      lastModifyDate: scanner.lastModifyDate,
      attributes: scanner.attributes,
    }
  }

  getEditStatusActionsList = () => {
    const { scannerStatus } = this.state
    if (!scannerStatus) {
      return []
    }
    const toRender = (
      <>
        {this.getStatusButtonAction(scannerStatus.status)}
        {this.getStatusLabel(scannerStatus.status)}
      </>
    )
    return [toRender]
  }

  getStatusButtonAction = (status: string) => {
    return (
      hasAnyCapabilities(CAPABILITIES.RESOURCE_SCANNER_MANAGE) && (
        <>
          {status === 'STOPPED' && (
            <Button className="stylewhere-button-primary" key="1" onClick={this.startScanner} style={{ minWidth: '0' }}>
              <PlayCircleOutlined />
            </Button>
          )}
          {status === 'RUNNING' && (
            <Button className="stylewhere-button-primary" key="1" onClick={this.stopScanner} style={{ minWidth: '0' }}>
              <PauseCircleOutlined />
            </Button>
          )}
          {status === 'Unknown' && (
            <>
              <Button className="stylewhere-button-primary" key="1" disabled style={{ minWidth: '0' }}>
                <PlayCircleOutlined />
              </Button>
              <Button className="stylewhere-button-primary" key="2" disabled style={{ minWidth: '0' }}>
                <PauseCircleOutlined />
              </Button>
            </>
          )}
        </>
      )
    )
  }

  startScanner = () => {
    const { scanner } = this.state
    if (scanner) {
      Scanners.startScanner(scanner.id!)
    }
  }

  stopScanner = () => {
    const { scanner } = this.state
    if (scanner) {
      Scanners.stopScanner(scanner.id!)
    }
  }

  getStatusLabel = (status: string): ReactNode => {
    return (
      hasAnyCapabilities(CAPABILITIES.RESOURCE_SCANNER_VIEW) && (
        <Tag style={{ height: '40px', paddingTop: '10px' }}>
          <b>{status}</b>
        </Tag>
      )
    )
  }

  render() {
    const { scanner, loader, detailData } = this.state
    const { breadcrumbs, queryString } = this.props
    const fragment = getFragmentObject(scanner, 'code')
    return (
      <StylewherePage {...this.props} noOverflow fragment={fragment}>
        <DefaultHeader
          backPath={getBackURL(queryString, breadcrumbs)}
          multipleTitle={getMultipleTitle(scanner, ['code', 'description'])}
          title={__(T.misc.not_found)}
          skeleton={{ active: loader, options: { multiple: true } }}
          customActions={scanner ? this.getEditStatusActionsList() : []}
        />
        <Section customClass={`stw-section-page paged-header ${scanner || loader ? 'scroll transparent' : ''}`}>
          {scanner || loader ? (
            <>
              <GridData fields={detailData || SCANNERS_DATA} record={scanner} skeleton={loader} />
              <Section>
                <AdvancedTable
                  loader={loader}
                  tableTitle={{ title: __(T.menu.antennas), emptyTitle: __(T.scanners.noAntennasFoundTitle) }}
                  pagination={{
                    pageNumber: 1,
                    pageSize: DEFAULT_PAGE_SIZE,
                    sortValues: {},
                  }}
                  columns={SCANNER_ANTENNAS_COLUMNS}
                  filters={{
                    filterDefinitions: [],
                  }}
                  content={[...((scanner && scanner.rfidFullAntennas) ?? [])]}
                  totalElements={scanner && scanner.rfidFullAntennas ? scanner.rfidFullAntennas.length : 0}
                  disabledPagination
                  disabledNoDataFound
                  activeDefaultClassColumn
                />
              </Section>
            </>
          ) : (
            <NotFound fullheight title={__(T.misc.noRecordFoundTitle)} />
          )}
        </Section>
      </StylewherePage>
    )
  }
}

export default ScannerDetail
