import { Component, createRef } from 'react'
import { Modal, Space, Col, notification, Form, FormInstance } from 'antd'
import { AdvancedFormAttribute } from './advancedFormTypes'
import { __ } from '../../shared/i18n'
import { AdvancedForm, ModalHeader, ModalAddButton } from '..'
import { FormValidateMessages } from '../../constants'

interface Props {
  visible: boolean
  title: string
  confirm: any
  close: () => void
  attribute: AdvancedFormAttribute | null
  configuration: any
  parameters?: any
  attributes: AdvancedFormAttribute[]
}

interface State {
  add: boolean
  record: any
}

export class AdvancedFormModalAttribute extends Component<Props, State> {
  formRef = createRef<FormInstance>()

  state = {
    add: false,
    record: {},
  }

  componentDidMount = () => {
    const { attribute } = this.props
    if (attribute) {
      this._set(attribute)
    }
  }

  shouldComponentUpdate = (nextProps) => {
    const { visible } = this.props
    if (nextProps.visible && !visible) {
      this._set(nextProps.attribute)
    }
    return true
  }

  _getRecord = (attr) => {
    if (!attr) {
      return null
    }
    const { configuration } = this.props
    const record = {}
    for (let c = 0; c < configuration.columns.length; c++) {
      record[configuration.columns[c].key] = attr && attr[configuration.columns[c].field]
    }
    return record
  }

  _set = (attr) => {
    const data = this._getRecord(attr)
    this.setState({
      add: attr && attr.add,
      record: data,
    })
    if (this.formRef && this.formRef.current) {
      this.formRef.current.setFieldsValue(data)
    }
  }

  handleChange = (key, field, val) => {
    const tmp = this.state.record || {}
    if (field === 'modalRole') {
      val = val.toUpperCase()
    }
    tmp[field] = val
    this.setState({
      record: tmp,
    })
    if (this.formRef && this.formRef.current) {
      this.formRef.current.setFieldsValue(tmp)
    }
  }

  save = () => {
    const { confirm, configuration } = this.props
    const { add, record } = this.state
    let c = 0
    let error = false
    while (c < configuration.columns.length && !error) {
      if (configuration.columns[c].rules[0].required && record[configuration.columns[c].key] === '') {
        this.openNotification(configuration.columns[c].error || __('misc.genericErrorRequired'))
        error = true
      }
      if (
        configuration.columns[c].checkDuplicate &&
        this.checkDuplicate(record[configuration.columns[c].key], configuration.columns[c].field)
      ) {
        this.openNotification(configuration.columns[c].duplicate || __('misc.genericErrorDuplicate'))
        error = true
      }
      c++
    }

    if (!error) {
      const obj: any = { add: add }
      for (c = 0; c < configuration.columns.length; c++) {
        obj[configuration.columns[c].field] = record[configuration.columns[c].key] || ''
      }
      confirm(obj)
    }
  }

  checkDuplicate = (key, field) => {
    const tmp: AdvancedFormAttribute[] = this.props.attributes
    const index = tmp.findIndex((attribute) => attribute[field] === key)
    return index !== -1
  }

  openNotification = (text) => {
    notification.error({
      message: __('misc.validationError'),
      description: text,
      placement: 'bottomRight',
      duration: 3,
    })
  }

  render() {
    const { title, visible, configuration, parameters, close } = this.props
    const { record } = this.state
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const thisRef = this
    return (
      <Modal
        centered
        title={<ModalHeader close={close} title={title} />}
        open={visible}
        footer={null}
        closable={false}
        onCancel={() => close()}
        width="30%"
        className="stw-workstation-rfid-antennas-modal"
      >
        <Col span={24}>
          {record && (
            <Form
              ref={this.formRef}
              layout="vertical"
              onFinish={async () => {
                this.save()
              }}
              style={{ width: '100%', height: '100%' }}
              initialValues={record}
              validateMessages={FormValidateMessages}
            >
              <Space size="small" style={{ width: '100%' }} className="stylewhere-form-attributes" direction="vertical">
                {configuration.columns &&
                  configuration.columns.map((column, i) => {
                    return (
                      <AdvancedForm
                        key={`modal_key_${i}`}
                        record={record}
                        handleChange={(k, val) => thisRef.handleChange(k, column.key, val)}
                        parameters={parameters}
                        //help={<span />}
                        fields={[
                          {
                            key: column.key,
                            label: column.label,
                            placeholder: column.placeholder,
                            type: column.type,
                            rules: column.rules,
                            col: 24,
                            option: column.option || '',
                          },
                        ]}
                      />
                    )
                  })}
                <ModalAddButton mTop={15} close={close} pb={5} />
              </Space>
            </Form>
          )}
        </Col>
      </Modal>
    )
  }
}
