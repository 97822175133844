import { Component, createRef } from 'react'
import { Form, FormInstance, Row } from 'antd'
import { StwBalance, StwPlace } from '../../api'
import { BalanceType, FormValidateMessages, ScalesTypes } from '../../constants'
import Balances from '../../api/Balances'
import { AttributeUtil } from '../../config/utility/utility'
import Places from '../../api/Places'
import { BALANCES_FIELDS } from '../../config/Pages/Devices/Balances'
import { AdvancedForm, Section, SkeletonFormModal } from '..'
import { AdvancedFormButton, AdvancedFormField } from '../AdvancedForm/advancedFormTypes'

const FORM_NAME = 'form-page-scale'

interface Props {
  balanceId: string
  buttonActions?: AdvancedFormButton[]
  title?: string
  onSave: any
  setFormName?: (...args: any[]) => any
  noPaddingPage?: boolean
  formWidth?: string
  placeId?: string
  displayFormInRow?: boolean
}

interface State {
  balance: StwBalance
  loader: boolean
  fields: AdvancedFormField[]
}

export class AdvancedBalanceForm extends Component<Props, State> {
  formRef = createRef<FormInstance>()

  constructor(props) {
    super(props)

    if (this.props.setFormName) {
      this.props.setFormName(FORM_NAME)
    }

    this.state = {
      balance: {
        settings: {
          port: '23',
        },
        deviceType: BalanceType[0].id,
      },
      fields: [],
      loader: true,
    }
  }

  async componentDidMount() {
    await this.initialize()
  }

  shouldComponentUpdate = (nextProps) => {
    const nextBalanceId = nextProps.balanceId
    const currentBalanceId = this.props.balanceId
    if (nextBalanceId && nextBalanceId !== '' && nextBalanceId !== currentBalanceId) {
      this.setState({ loader: true }, this.initialize)
    }
    return true
  }

  initialize = async () => {
    const { balanceId, placeId } = this.props
    let balance: StwBalance = this.state.balance
    const fields = BALANCES_FIELDS
    if (balanceId) {
      balance = await Balances.get<StwBalance>(balanceId)
      if (balance.settings && !balance.settings.port) {
        balance.settings.port = '23'
      }
    } else if (placeId && placeId !== '') {
      const data = await this.getCodeAndDescription(placeId)

      fields[0].onlyCreate = true
      fields[fields.length - 1].onlyCreate = false
      balance.place = { id: placeId }
      balance.code = data.code
      balance.description = data.description
    }

    this.setState(
      {
        balance: balance,
        loader: false,
        fields: fields,
      },
      this.updateFormFieldsValue
    )
  }

  handleChange = async (key, value) => {
    const { balance } = this.state
    if (key === 'place') {
      const data = await this.getCodeAndDescription(value.id)
      balance.place = { id: value.id }
      balance.code = data.code
      balance.description = data.description
    } else {
      AttributeUtil.setAttribute(balance, key, value)
    }
    this.setState(
      {
        balance: balance,
      },
      this.updateFormFieldsValue
    )
  }

  updateFormFieldsValue = () => {
    if (this.formRef && this.formRef.current) {
      this.formRef.current.setFieldsValue(this.state.balance)
    }
  }

  getCodeAndDescription = async (placeId: string) => {
    let code = ''
    let description = ''
    if (placeId && placeId !== '') {
      const place = await Places.get<StwPlace>(placeId)
      const balances = await Balances.search<StwBalance>({ page: 0, size: 1, placeId: placeId })
      if (place && place.description) {
        code = `${place.code}_S${balances.totalElements + 1}`
        description = place.description && `${place.description}_S${balances.totalElements + 1}`
      }
    }
    return { code: code, description: description }
  }

  store = async () => {
    const { onSave } = this.props
    const { balance } = this.state
    if (balance.id) {
      Balances.update<StwBalance>(balance).then((value) => onSave(value))
    } else {
      Balances.insert<StwBalance>(balance).then((value) => onSave(value))
    }
  }

  render() {
    const { title, buttonActions, noPaddingPage, formWidth, placeId, displayFormInRow } = this.props
    const { balance, loader, fields } = this.state
    const fromModal = placeId ? placeId !== '' : false
    return (
      <Row style={{ width: '100%', height: loader ? '100%' : 'auto' }}>
        {loader ? (
          <SkeletonFormModal fromModal={fromModal} animate />
        ) : (
          <Form
            id={FORM_NAME}
            ref={this.formRef}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            layout="vertical"
            onFinish={async () => {
              await this.store()
            }}
            style={{ width: '100%', height: '100%' }}
            initialValues={balance}
            validateMessages={FormValidateMessages}
            scrollToFirstError={!fromModal}
          >
            <Section fill noPaddingPage={noPaddingPage}>
              <AdvancedForm
                title={title}
                buttonActions={buttonActions}
                record={balance}
                width={formWidth || '70%'}
                fields={fields}
                store={this.store}
                editing={balance.id !== undefined || fromModal}
                handleChange={this.handleChange}
                displayFormInRow={displayFormInRow}
                parameters={{
                  scalesTypes: ScalesTypes,
                }}
              />
            </Section>
          </Form>
        )}
      </Row>
    )
  }
}
