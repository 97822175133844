import { FilterDropdownProps } from 'antd/lib/table/interface'
import { ReactNode } from 'react'

export type AdvancedTableContent<T> = ReadonlyArray<T>

export enum AdvancedTableContentKind {
  STRING = 'STRING',
  DATE = 'DATE',
  BOOLEAN = 'BOOLEAN',
  MULTIPLE_STRING = 'MULTIPLE_STRING',
  IDENTIFIERS = 'IDENTIFIERS',
  COUNTER = 'COUNTER',
  STATUS = 'STATUS',
  DATE_TIME = 'DATE_TIME',
  NUMBER = 'NUMBER',
  ATTRIBUTES = 'ATTRIBUTES',
  CODE_DESC = 'CODE_DESC',
}

export interface AdvancedTableConfig {
  columns?: AdvancedTableColumn[]
  filterDefinitions: AdvancedTableFiltersDef
  filterParameters?: { [key: string]: object }
  entity?: string
  mappingAttributeIndex?: number
  activeDefaultClassColumn?: boolean
  disableManagerColumns?: boolean
  disableNumberData?: boolean
  disabledFilters?: boolean
  disabledScroll?: boolean
  disableColumnFilters?: boolean
  fixedTableHeight?: boolean
  manageColumnsPrefix?: string
  disabledNoDataFound?: boolean
  disabledPagination?: boolean
  disableLocation?: boolean
  skeletonOptions?: any
  hiddenEmptyTable?: boolean
  exportModalTitle?: string
  exportModalFilenamePrefix?: string
  disabledReloadData?: boolean
  tableBorder?: boolean
}

export interface AdvancedTablePagination {
  pageNumber?: number
  pageSize: number
  sortValues: AdvancedTableSortValues
  filterValues?: AdvancedTableFilterValues
}

export type AdvancedTableFiltersDef = ReadonlyArray<AdvancedTableFilter>

export interface AdvancedTableFilter {
  key: string // the filter key (e.g. likeCodes)
  label?: string // the filter input label
  type: AdvancedTableFilterKind // the filter input type
  column?: string // the header column
  col?: number
  option?: string
  select?: {
    option: string
  }
  autocomplete?: {
    endpoint: string
    sort: string
    multiple?: boolean
    fieldLabel?: string
    additionalParams?: any
  }
  selectWithCustomSelection?: boolean
  format?: string
  maxTagCount?: number
  dependencies?: string[]
}

export interface AdvancedTableFilterValues {
  [parameter: string]: any
}

export enum AdvancedTableFilterKind {
  STRING = 'STRING',
  STRING_LIST = 'STRING_LIST',
  ENUM = 'ENUM',
  AUTOCOMPLETE = 'AUTOCOMPLETE',
  STRING_AUTOCOMPLETE = 'STRING_AUTOCOMPLETE',
  DATE_RANGE = 'DATE_RANGE',
  BOOLEAN = 'BOOLEAN',
  SELECT = 'SELECT',
}

export type AdvancedTableSortValues = {
  [parameter: string]: AdvancedTableSortDirection
}

export enum AdvancedTableSortDirection {
  asc = 'asc',
  desc = 'desc',
}

export type AdvancedTableColumns = ReadonlyArray<AdvancedTableColumn>

export interface AdvancedTableColumn {
  key: string
  title?: string
  type?: AdvancedTableContentKind
  render?: (text, record, index) => ReactNode
  filterDropdown?: (props: FilterDropdownProps) => ReactNode
  filterIcon?: ReactNode
  sortable?: boolean
  dataIndex?: string
  hidden?: boolean
  unmanageable?: boolean
  fixedType?: 'left' | 'right'
  width?: 'small' | 'medium' | 'large'
  className?:
    | 'stw-extra-small'
    | 'stw-small'
    | 'stw-small-medium'
    | 'stw-medium'
    | 'stw-extra-medium'
    | 'stw-large'
    | 'stw-extra-large'
  reportActions?: any
  basePath?: string
}
