import { AdvancedTableFilterKind, AdvancedTableFiltersDef } from '../../../components/AdvancedTable'
import {
  AdvancedFormAttributeType,
  AdvancedFormField,
  AdvancedFormFieldBuilder,
  AdvancedFormInputType,
} from '../../../components/AdvancedForm/advancedFormTypes'
import { __ } from '../../../shared/i18n'
import { DetailCell } from '../../../api'
import { showModal } from '../../../components/modalPageUtils'

export const ROLES_FILTERS: AdvancedTableFiltersDef = [
  {
    key: 'equalCodes',
    label: __('fields.labels.code'),
    type: AdvancedTableFilterKind.STRING_LIST,
    column: 'code',
    col: 8,
  },
  {
    key: 'description',
    label: __('fields.labels.description'),
    type: AdvancedTableFilterKind.STRING,
    column: 'description',
    col: 8,
  },
  {
    key: 'capabilityGroupIds',
    label: __('fields.labels.capabilityGroups'),
    type: AdvancedTableFilterKind.AUTOCOMPLETE,
    column: 'capabilityGroups',
    autocomplete: {
      endpoint: '/api/v1/capabilitygroups',
      sort: 'code,asc',
      multiple: true,
    },
    col: 8,
  },
  {
    key: 'operationIds',
    label: __('fields.labels.operations'),
    type: AdvancedTableFilterKind.AUTOCOMPLETE,
    column: 'operations',
    autocomplete: {
      endpoint: '/api/v1/operations',
      sort: 'code,asc',
      multiple: true,
    },
    col: 8,
  },
  {
    key: 'creationDate',
    label: __('fields.labels.creationDate'),
    type: AdvancedTableFilterKind.DATE_RANGE,
    column: 'creationDate',
    col: 8,
  },
  {
    key: 'lastModifyDate',
    label: __('fields.labels.lastModifyDate'),
    type: AdvancedTableFilterKind.DATE_RANGE,
    column: 'lastModifyDate',
    col: 8,
  },
]

export const ROLES_FIELDS: AdvancedFormField[] = [
  AdvancedFormFieldBuilder.buildText('description', [{ required: false }]),
  {
    key: 'code',
    type: AdvancedFormInputType.UNIQUE_TEXT,
    label: __('fields.labels.code'),
    placeholder: __('fields.placeholders.code'),
    rules: [{ required: true }],
    unique: {
      endpoint: '/api/v1/roles',
      parameter: 'equalCodes',
    },
  },
  AdvancedFormFieldBuilder.buildSlider('priority', [{ required: false }], { min: 0, max: 9 }),
  AdvancedFormFieldBuilder.buildSelect('homepage', 'homepagePaths', [{ required: true }]),
  {
    key: 'capabilityGroups',
    type: AdvancedFormInputType.OBJECT_LIST_WITH_CARDS,
    col: 24,
    placeholder: __('misc.select'),
    uniqueValueKey: 'id',
    autocomplete: {
      endpoint: '/api/v1/capabilitygroups',
      sort: 'code,asc',
    },
    objectListWithCard: {
      handleView: (record) =>
        showModal({
          type: 'CAPABILITY_LIST',
          data: { capabilities: record.capabilities },
        }),
    },
  },
  {
    key: 'operations',
    type: AdvancedFormInputType.OBJECT_LIST_WITH_CARDS,
    col: 24,
    placeholder: __('misc.select'),
    uniqueValueKey: 'id',
    autocomplete: {
      endpoint: '/api/v1/operations',
      sort: 'code,asc',
    },
    rules: [{ required: false }],
  },
  AdvancedFormFieldBuilder.buildAttribute('attributes', AdvancedFormAttributeType.SIMPLE, false, 'role'),
]

export const ROLES_DATA: DetailCell[] = [
  { title: 'id', attribute: 'id', columns: 24 },
  { title: 'code', attribute: 'code', columns: 12 },
  { title: 'description', attribute: 'description', columns: 12 },
  { title: 'priority', attribute: 'priority', columns: 12 },
  { title: 'homepage', attribute: 'homepage', columns: 12 },
  { title: 'Creation date', attribute: 'creationDate', type: 'date', columns: 12 },
  { title: 'Last modify date', attribute: 'lastModifyDate', type: 'date', columns: 12 },
]
