/* eslint-disable func-names */
import { Component } from 'react'
import { Form, Input } from 'antd'
import { __ } from '../../shared/i18n'
import { AdvancedFormField } from './advancedFormTypes'

interface Props {
  record: any
  item: AdvancedFormField
  handleChange: any
  readonly: boolean
  labelHidden?: boolean
  marginBottom?: number
}

const { TextArea } = Input

export class AdvancedFormTextareaField extends Component<Props> {
  render() {
    const { readonly, item, handleChange, labelHidden, marginBottom } = this.props
    return (
      <Form.Item
        name={item.key.split('.')}
        className="stylewhere-form-item"
        rules={item.rules}
        style={{ marginBottom: marginBottom || 24 }}
        label={labelHidden ? null : __(`fields.labels.${item.key}`, item.label)}
      >
        <TextArea
          id={item.key}
          rows={item.rows || 10}
          readOnly={readonly}
          onChange={(e) => handleChange(e)}
          // defaultValue={AttributeUtil.getAttribute(record, item.key)}
          placeholder={item.placeholder}
        />
      </Form.Item>
    )
  }
}
