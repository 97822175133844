import { Component } from 'react'
import {
  KeyValueGrid,
  StylewherePage,
  DefaultHeader,
  NotFound,
  Section,
  GridCards,
  TableListReport,
} from '../../../components'
import {
  DetailCell,
  StwOperation,
  StwOperationAction,
  StwFullInventoryParcel,
  StwReportQueryResult,
} from '../../../api'
import InventoryParcels from '../../../api/InventoryParcels'
import { __, T } from '../../../shared/i18n'
import { AdvancedTableSortDirection } from '../../../components/AdvancedTable'
import Reports from '../../../api/Reports'
import { SkeletonAttributeReport } from '../../../constants'
import {
  getFragmentObject,
  getMultipleTitle,
  getBackURL,
  getCards,
  getHeaderOperationActions,
} from '../../../shared/utils'
import { ExtendRouteComponentProps } from '../../../types'
import Operations from '../../../api/Operations'

interface State {
  reportInstance?: StwReportQueryResult
  configuration: DetailCell[]
  loader: boolean
  inventoryParcel?: StwFullInventoryParcel
  actions: StwOperationAction[]
}

class InventoryParcelOperationDetail extends Component<ExtendRouteComponentProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      configuration: [],
      loader: true,
      inventoryParcel: {
        zones: [],
        ignoreZones: [],
      },
      actions: [],
    }
  }

  componentDidMount = async () => {
    const { match } = this.props
    if (match && match.params && match.params.operationInstanceId) {
      const params = { operationId: match.params.operationId }
      const reportId = await Operations.getReportId(match.params.operationId)
      const instance: any = await Reports.reportInstance(reportId, match.params.operationInstanceId, params)
      if (instance && instance.id) {
        const operation: StwOperation = await Operations.get<StwOperation>(match.params.operationId)
        const inventoryParcelId = instance.id
        const inventoryParcel = await InventoryParcels.getFull(inventoryParcelId)
        const configuration = await Reports.getConfiguration(reportId, params)
        this.setState({
          reportInstance: instance,
          configuration: configuration,
          loader: false,
          inventoryParcel: inventoryParcel,
          actions: operation && operation.actions && operation.actions !== null ? operation.actions : [],
        })
      } else {
        this.setState({
          loader: false,
        })
      }
    } else {
      this.setState({
        loader: false,
      })
    }
  }

  decorateWithZones(configuration: DetailCell[]): DetailCell[] {
    const { inventoryParcel } = this.state
    return [
      ...configuration,
      {
        title: __('fields.labels.zones'),
        attribute: 'zones',
        type: 'custom',
        render: () =>
          inventoryParcel && inventoryParcel.zones && inventoryParcel.zones.length > 0
            ? inventoryParcel.zones.map((zone) => `${zone.code} - ${zone.description}`).join(', ')
            : 'n/a',
      },
    ]
  }

  render(): JSX.Element {
    const { breadcrumbs, queryString, match } = this.props
    const { reportInstance, configuration, loader, actions } = this.state
    const fragment = getFragmentObject(reportInstance, 'id')
    return (
      <StylewherePage {...this.props} noOverflow fragment={fragment}>
        <DefaultHeader
          backPath={getBackURL(queryString, breadcrumbs)}
          multipleTitle={getMultipleTitle(reportInstance)}
          title={__(T.misc.not_found)}
          skeleton={{ active: loader, options: { multiple: true } }}
          actions={
            reportInstance
              ? getHeaderOperationActions(
                  'logistics',
                  'inventoryParcel',
                  actions,
                  match.params.operationId,
                  match.params.operationInstanceId
                )
              : []
          }
        />
        <Section customClass={`stw-section-page paged-header ${reportInstance || loader ? 'scroll transparent' : ''}`}>
          {reportInstance || loader ? (
            <>
              <Section>
                <KeyValueGrid
                  skeleton={loader}
                  fields={loader ? SkeletonAttributeReport : this.decorateWithZones(configuration)}
                  data={reportInstance}
                />
                {!loader && reportInstance && (
                  <GridCards
                    mTop={20}
                    cards={getCards(
                      reportInstance.detectedParcelsCount,
                      reportInstance.detectedAndExpectedParcelsCount !== undefined
                        ? reportInstance.detectedAndExpectedParcelsCount
                        : -1,
                      reportInstance.expectedParcelsCount !== undefined ? reportInstance.expectedParcelsCount : -1,
                      reportInstance.unexpectedParcelsCount !== undefined ? reportInstance.unexpectedParcelsCount : -1,
                      reportInstance.missingParcelsCount !== undefined ? reportInstance.missingParcelsCount : -1
                    )}
                  />
                )}
              </Section>
              {reportInstance && (
                <TableListReport
                  {...this.props}
                  reportId="inventoryParcelEntry"
                  actions={[]}
                  fixedTableHeight
                  disabledReportExport
                  headerType="boxed"
                  title={__('fields.labels.parcels')}
                  customColumnPrefix="inventoryParcelDetailEntries"
                  reportActions={{
                    active: true,
                    onlyedit: false,
                  }}
                  reportParams={{ inventoryParcelId: reportInstance.id }}
                  disableLocation
                  pathDetail="reports/parcel"
                  sortValues={{ code: AdvancedTableSortDirection.asc }}
                />
              )}
            </>
          ) : (
            <NotFound fullheight title={__(T.misc.noRecordFoundTitle)} />
          )}
        </Section>
      </StylewherePage>
    )
  }
}

export default InventoryParcelOperationDetail
