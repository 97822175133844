import { Component } from 'react'
import { Form, Button, Space } from 'antd'
import { AdvancedTableFilter, AdvancedTablePagination, AdvancedTableFilterKind } from './advancedTableTypes'
import { advancedTableFilterDefinitionToAdvancedFormField } from './advancedTableFilter'
import { AdvancedForm } from '..'
import { __, T } from '../../shared/i18n'

interface State {
  filterValue: any | undefined
}

interface Props {
  filter: AdvancedTableFilter
  setSelectedKeys: any
  selectedKeys: any
  confirm: any
  clearFilters: any
  pagination: AdvancedTablePagination
  onFilter: any
  className: string
  filterParameters?: any
}

export class AdvancedTableColumnFilter extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      filterValue: undefined,
    }
  }

  componentDidMount() {
    this._init(this.props.pagination)
  }

  _init = (pagination) => {
    const { filter } = this.props
    if (pagination && pagination.filterValues) {
      this.setState({
        filterValue: {
          filters: {
            [filter.key]: pagination.filterValues[filter.key] || undefined,
          },
        },
      })
    }
  }

  shouldComponentUpdate = (nextProps) => {
    const { pagination } = this.props
    if (nextProps.pagination && !pagination) {
      this._init(nextProps.pagination)
    }
    return true
  }

  onChange = (value) => {
    const { filter } = this.props
    this.setState({
      filterValue: {
        filters: {
          [filter.key]: value,
        },
      },
    })
  }

  getValue = () => {
    const { filter } = this.props
    const { filterValue } = this.state
    return filterValue.filters[filter.key]
  }

  checkValue = () => {
    const { filter, pagination } = this.props
    if (pagination && pagination.filterValues && pagination.filterValues[filter.key]) {
      if (Array.isArray(pagination.filterValues[filter.key])) {
        return pagination.filterValues[filter.key].length > 0
      }
      return true
    }
    return false
  }

  reset = () => {
    const { filterValue } = this.state
    const { filter, onFilter } = this.props
    if (filterValue.filters[filter.key]) {
      if (Array.isArray(filterValue.filters[filter.key])) {
        onFilter(filter.key, [])
      }
      onFilter(filter.key, undefined)
    }
  }

  getClassPanel = () => {
    const { filter, className } = this.props
    if (filter.type === AdvancedTableFilterKind.DATE_RANGE) {
      return 'stw-large'
    }
    if (filter.type === AdvancedTableFilterKind.AUTOCOMPLETE) {
      return 'stw-large'
    }
    return className
  }

  render() {
    const { filter, filterParameters, onFilter } = this.props
    const { filterValue } = this.state
    const classPanel = this.getClassPanel()
    return (
      <div className={`column-filter ${classPanel}`} style={{ padding: 8 }}>
        {filterValue && (
          <Form labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} layout="vertical" initialValues={filterValue}>
            <AdvancedForm
              fields={[
                advancedTableFilterDefinitionToAdvancedFormField({
                  ...filter,
                  col: 24,
                  key: `filters.${filter.key}`,
                  format: 'YYYY-MM-DD HH:mm:ss',
                  maxTagCount: 1,
                }),
              ]}
              record={filterValue}
              parameters={filterParameters || {}}
              labelHidden
              handleChange={(key, value) => {
                this.onChange(value)
              }} // useless
              handleApplyFilters={() => {}}
              width="100%"
            />
            <Space style={{ justifyContent: 'flex-end', width: '100%' }}>
              {this.checkValue() && (
                <Button
                  className="stylewhere-button-secondary"
                  onClick={() => {
                    this.reset()
                  }}
                >
                  {__(T.misc.reset)}
                </Button>
              )}
              <Button
                className="stylewhere-button-primary"
                onClick={() => {
                  onFilter(filter.key, this.getValue())
                }}
              >
                {__(T.misc.apply)}
              </Button>
            </Space>
          </Form>
        )}
      </div>
    )
  }
}
