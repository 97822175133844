import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import {
  StwChecklistType,
  StwCreateChecklistCommandUI,
  StwItem,
  StwItemIdentifier,
  StwProductQuantityUI,
} from '../../api'
import {
  AdvancedFormAttributeType,
  AdvancedFormField,
  AdvancedFormInputType,
} from '../../components/AdvancedForm/advancedFormTypes'
import { __ } from '../../shared/i18n'
import { ITEMS_COLUMNS, PRODUCTS_COLUMNS } from './Reports'
import Items from '../../api/Items'
import { AdvancedTableRowColumn } from '../../components'
import { AdvancedTableColumn } from '../../components/AdvancedTable'

const BASE_CHECKLIST_FIELDS: AdvancedFormField[] = [
  {
    key: 'code',
    type: AdvancedFormInputType.UNIQUE_TEXT,
    label: __('fields.labels.code'),
    placeholder: __('fields.placeholders.code'),
    rules: [{ required: true }],
    unique: {
      endpoint: '/api/v1/checklists',
      parameter: 'equalCodes',
    },
  },
  {
    key: 'type',
    label: __('fields.labels.type'),
    type: AdvancedFormInputType.SELECT,
    placeholder: __('fields.placeholders.type'),
    rules: [{ required: true }],
    option: 'checklistType',
  },
  {
    key: 'operationType',
    label: __('fields.labels.operationType'),
    type: AdvancedFormInputType.SELECT,
    placeholder: __('fields.placeholders.operationType'),
    rules: [{ required: true }],
    option: 'operationTypes',
  },
  {
    key: 'operationId',
    label: __('fields.labels.operation'),
    type: AdvancedFormInputType.AUTOCOMPLETE,
    placeholder: __('fields.placeholders.operation'),
    option: 'operationFilter',
    rules: [{ required: true }],
    autocomplete: {
      endpoint: '/api/v1/operations',
      sort: 'code,asc',
      multiple: false,
      withIdValues: true,
    },
    readonly: (record) => {
      return !record.operationType
    },
  },
  {
    key: 'checklistPlaceId',
    label: __('fields.labels.checklistPlaceId'),
    type: AdvancedFormInputType.AUTOCOMPLETE,
    placeholder: __('fields.placeholders.checklistPlaceId'),
    autocomplete: {
      endpoint: '/api/v1/places',
      sort: 'code,asc',
      multiple: false,
      withIdValues: true,
    },
  },
  {
    key: 'destinationPlaceId',
    label: __('fields.labels.destinationPlaceId'),
    type: AdvancedFormInputType.AUTOCOMPLETE,
    placeholder: __('fields.placeholders.destinationPlaceId'),
    autocomplete: {
      endpoint: '/api/v1/places',
      sort: 'code,asc',
      multiple: false,
      withIdValues: true,
    },
  },
  {
    key: 'createAsn',
    label: __('fields.labels.createAsn'),
    type: AdvancedFormInputType.SWITCH,
  },
  {
    key: 'createParcel',
    label: __('fields.labels.createParcel'),
    type: AdvancedFormInputType.SWITCH,
  },
  {
    key: 'attributes',
    label: __('fields.labels.attributes.title'),
    type: AdvancedFormInputType.ATTRIBUTES,
    subtype: AdvancedFormAttributeType.SIMPLE,
    col: 24,
    activeAddModal: true,
    erasable: true,
  },
]

export function checklistFields(checklist: StwCreateChecklistCommandUI): AdvancedFormField[] {
  return [
    ...BASE_CHECKLIST_FIELDS,
    checklist.type === StwChecklistType.CHECKLIST_BY_ITEM
      ? {
          key: 'items',
          label: __('fields.labels.items'),
          type: AdvancedFormInputType.OBJECT_LIST,
          placeholder: __('fields.placeholders.items'),
          objectList: {
            endpoint: '/api/v1/itemIdentifiers',
            sort: 'code,asc',
            columns: ITEMS_COLUMNS,
            canDelete: true,
            mapSelection: async (itemIdentifier: StwItemIdentifier) => {
              const item = await Items.get<StwItem>(itemIdentifier.itemId)
              return item
            },
          },
          rules: [{ required: checklist.type === StwChecklistType.CHECKLIST_BY_ITEM }],
          col: 24,
        }
      : {
          key: 'products',
          label: __('fields.labels.products'),
          type: AdvancedFormInputType.CHECKLIST_PRODUCTS,
          placeholder: __('fields.placeholders.products'),
          objectList: {
            endpoint: '/api/v1/products',
            sort: 'code,asc',
            columns: PRODUCTS_COLUMNS,
            canDelete: true,
          },
          rules: [{ required: checklist.type === StwChecklistType.CHECKLIST_BY_PRODUCT_TYPE }],
          col: 24,
        },
  ]
}

export function checklistProductColumns(
  onEdit: (productQuantity: StwProductQuantityUI) => void,
  onRemove: (productQuantity: StwProductQuantityUI) => void
): AdvancedTableColumn[] {
  return [
    {
      key: 'product.code',
      dataIndex: 'productCode',
      sortable: false,
      title: __('fields.labels.productCode'),
    },
    {
      key: 'product.description',
      dataIndex: 'productDescription',
      sortable: false,
      title: __('fields.labels.productDescription'),
    },
    {
      key: 'quantity',
      sortable: false,
      title: __('fields.labels.quantity'),
    },
    {
      key: 'action',
      unmanageable: true,
      hidden: false,
      sortable: false,
      fixedType: 'right',
      render: (text, record) => (
        <AdvancedTableRowColumn
          actions={[
            { content: <EditOutlined />, type: 'default', onclick: () => onEdit(record) },
            { content: <DeleteOutlined />, type: 'default', onclick: () => onRemove(record) },
          ]}
        />
      ),
    },
  ]
}
