import { Component } from 'react'
import { Col, Modal, Row, Typography, Button, Space } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import { __ } from '../../shared/i18n'
import { StwRfidReaderAntennas } from '../../api'
import { AdvancedRfidReaderForm } from '..'

interface State {
  formName: string
}

interface Props {
  visible: boolean
  onCancel: () => void
  onConfirm: (antennaId: string[]) => void
  placeId: string
}

const { Title } = Typography

export class WorkstationRfidAntennasCreationModal extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      formName: '',
    }
  }

  setFormName = (name: string) => {
    this.setState({ formName: name })
  }

  onSave = async (rfidreader: StwRfidReaderAntennas) => {
    const { onConfirm } = this.props
    if (rfidreader) {
      const tmp: string[] = []
      if (rfidreader.antennas) {
        rfidreader.antennas.map((antenna) => tmp.push(antenna.id))
      }
      onConfirm(tmp)
    }
  }

  render() {
    const { visible, onCancel, placeId } = this.props
    const { formName } = this.state
    return (
      <Modal
        title={
          <Row gutter={22} style={{ marginTop: '20px' }}>
            <Col span={18}>
              <Title level={3}>
                <b>{__('rfidReaders.create_new')}</b>
              </Title>
            </Col>
            <Col span={6} style={{ justifyContent: 'flex-end', display: 'flex' }}>
              <CloseCircleOutlined style={{ fontSize: 22 }} onClick={() => onCancel()} />
            </Col>
          </Row>
        }
        footer={null}
        closable={false}
        onCancel={() => onCancel()}
        open={visible}
        width="60%"
        className="stw-workstation-rfid-antennas-modal"
      >
        {visible && (
          <AdvancedRfidReaderForm
            formWidth="100%"
            noPaddingPage
            readerId=""
            placeId={placeId}
            onSave={this.onSave}
            setFormName={this.setFormName}
            cardCol={12}
            displayFormInRow
          />
        )}
        <Space size="large" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 30 }}>
          <Button className="stylewhere-button-default" onClick={() => onCancel()} key="1">
            {__('misc.cancel')}
          </Button>
          <Button className="stylewhere-button-primary" htmlType="submit" key="2" form={formName}>
            {__('misc.confirm')}
          </Button>
        </Space>
      </Modal>
    )
  }
}
