import BaseResource, { PageResult } from './BaseResource'
import { StwFullRfidAntenna, StwRfidAntenna, StwXspan, StwZone } from './types'
import Zones from './Zones'
import { api, responseErrorCheck } from './api'
import { mapRfidReaderToFull } from '../components/Devices/AdvancedRfidReaderFormUtils'

export default class RfidAntennas extends BaseResource {
  static endpoint = '/api/v1/rfidAntennas' ///edge

  static async getFull(rfidAntennaId: string): Promise<StwFullRfidAntenna> {
    const rfidAntenna = await this.get<StwRfidAntenna>(rfidAntennaId)
    return RfidAntennas.toFull(rfidAntenna)
  }

  static async searchFull(params = {}): Promise<PageResult<StwFullRfidAntenna>> {
    const rfidAntennas = await this.search<StwRfidAntenna>(params)
    const zones =
      rfidAntennas && rfidAntennas.content
        ? await Zones.searchByIds<StwZone>(rfidAntennas.content.map((rfidAntenna) => rfidAntenna.zoneId))
        : []
    return {
      ...rfidAntennas,
      content: rfidAntennas.content
        ? rfidAntennas.content.map(({ zoneId, reader, ...rfidAntenna }) => ({
            ...rfidAntenna,
            zone: zoneId ? zones[`${zoneId}`] : undefined,
            reader: mapRfidReaderToFull(reader!),
          }))
        : [],
    }
  }

  static async searchTableList(endpoint, params = {}): Promise<PageResult<StwFullRfidAntenna>> {
    const rfidAntennas = await api.get<PageResult<StwRfidAntenna>>(endpoint, params, {}).then(responseErrorCheck)
    const zones =
      rfidAntennas && rfidAntennas.content
        ? await Zones.searchByIds<StwZone>(rfidAntennas.content.map((rfidAntenna) => rfidAntenna.zoneId))
        : []
    return {
      ...rfidAntennas,
      content: rfidAntennas.content
        ? rfidAntennas.content.map(({ zoneId, reader, ...rfidAntenna }) => ({
            ...rfidAntenna,
            zone: zoneId ? zones[`${zoneId}`] : undefined,
            reader: mapRfidReaderToFull(reader!),
          }))
        : [],
    }
  }

  static async updateFull(fullRfidAntenna: StwFullRfidAntenna): Promise<StwFullRfidAntenna> {
    return RfidAntennas.update<StwRfidAntenna>(RfidAntennas.fromFull(fullRfidAntenna)).then((rfidAntenna) =>
      RfidAntennas.toFull(rfidAntenna)
    )
  }

  static async insertFull(fullRfidAntenna: StwFullRfidAntenna): Promise<StwFullRfidAntenna> {
    const rfidAntenna = await this.insert<StwXspan>(RfidAntennas.fromFull(fullRfidAntenna))
    return RfidAntennas.toFull(rfidAntenna)
  }

  static async toFull({ zoneId, ...baseRfidAntenna }: StwRfidAntenna): Promise<StwFullRfidAntenna> {
    const zone = zoneId ? await Zones.get<StwZone>(zoneId) : undefined
    return {
      ...baseRfidAntenna,
      zone: zone,
    }
  }

  static fromFull({ zone, ...baseRfidAntenna }: StwFullRfidAntenna): StwRfidAntenna {
    return {
      ...baseRfidAntenna,
      zoneId: zone?.id ?? undefined,
    }
  }
}
