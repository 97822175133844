import { Component, createRef } from 'react'
import { Form, FormInstance } from 'antd'
import { AdvancedForm, StylewherePage, Section, DefaultHeader } from '../../../components'
import PickingFlows from '../../../api/PickingFlows'
import { ExtendRouteComponentProps } from '../../../types'
import { navigate } from '../../../shared/router'
import { __, T } from '../../../shared/i18n'
import { StwFlow, StwFullFlow } from '../../../api'
import { AttributeUtil } from '../../../config/utility/utility'
import { FormValidateMessages, PickingFlowDiscriminators } from '../../../constants'
import { FormUtility } from '../../../config/utility/formUtility'
import { PICKING_FLOW_FIELDS } from '../../../config/Pages/PickingFlows'
import { getFragmentObject, getBackURL } from '../../../shared/utils'

interface State {
  pickingFlow: StwFullFlow
  loader: boolean
  isCodeEditableByDescription: boolean
}

class PickingFlowForm extends Component<ExtendRouteComponentProps, State> {
  formRef = createRef<FormInstance>()

  constructor(props) {
    super(props)
    this.state = {
      pickingFlow: {
        sortingEnabled: false,
      },
      loader: true,
      isCodeEditableByDescription: true,
    }
  }

  componentDidMount() {
    this.initialize()
  }

  initialize = async () => {
    const { match } = this.props
    let pickingFlow
    if (match && match.params && match.params.pickingFlowId) {
      const pickingFlowId = match.params.pickingFlowId
      if (pickingFlowId !== '' && pickingFlowId !== 'create') {
        const result: StwFlow = await PickingFlows.get(pickingFlowId)
        pickingFlow = this.mapPickingFlowToFull(result)
      }
    }

    this.setState(
      {
        pickingFlow: pickingFlow || { sortingEnabled: false },
        isCodeEditableByDescription: !pickingFlow,
        loader: false,
      },
      this.updateFormFieldsValue
    )
  }

  updateFormFieldsValue = () => {
    if (this.formRef && this.formRef.current) {
      this.formRef.current.setFieldsValue(this.state.pickingFlow)
    }
  }

  mapPickingFlowToFull = (flow: StwFlow): StwFullFlow => {
    return {
      id: flow.id,
      code: flow.code,
      sortingEnabled: flow.sortingEnabled,
      lastModifyDate: flow.lastModifyDate,
      discriminatorKey: flow.discriminatorKey,
      creationDate: flow.creationDate,
      description: flow.description,
      operation: {
        id: flow.operationId,
      },
    }
  }

  handleChange = (key, value) => {
    const { pickingFlow, isCodeEditableByDescription } = this.state
    if (key === 'operation') {
      value = { id: value }
    }
    AttributeUtil.setAttribute(pickingFlow, key, value)
    if (key === 'description' && isCodeEditableByDescription) {
      FormUtility.handleDescription(value, pickingFlow, this.formRef)
    } else if (key === 'code') {
      this.setState({ isCodeEditableByDescription: false })
    }

    this.setState({
      pickingFlow: pickingFlow,
    })
  }

  store = () => {
    const { pickingFlow } = this.state
    const { queryString } = this.props
    const flow = this.mapFullFlowToPlain(pickingFlow)
    if (pickingFlow.id) {
      PickingFlows.update<StwFlow>(flow).then(() => {
        navigate(`/configuration/pickingFlows${queryString ?? ''}`)
      })
    } else {
      PickingFlows.insert<StwFlow>(flow).then((newPickingFlow) => {
        if (newPickingFlow && newPickingFlow.id) {
          navigate(`/configuration/pickingFlows${queryString ?? ''}`)
        }
      })
    }
  }

  mapFullFlowToPlain = (full: StwFullFlow): StwFlow => {
    return {
      id: full.id,
      code: full.code,
      description: full.description,
      operationId: full.operation!.id,
      discriminatorKey: full.discriminatorKey,
      sortingEnabled: full.sortingEnabled,
      creationDate: full.creationDate,
      lastModifyDate: full.lastModifyDate,
    }
  }

  render() {
    const { pickingFlow, loader } = this.state
    const title = pickingFlow.id ? __(T.pickingFlow.edit) : __(T.pickingFlow.create_new)
    const { breadcrumbs, queryString } = this.props
    const fragment = !loader ? getFragmentObject(pickingFlow, 'id', __(T.pickingFlow.create_new)) : undefined
    return (
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        onFinish={this.store}
        style={{ width: '100%', height: '100%' }}
        ref={this.formRef}
        initialValues={pickingFlow}
        validateMessages={FormValidateMessages}
        scrollToFirstError
      >
        <StylewherePage {...this.props} noOverflow fragment={fragment}>
          <DefaultHeader
            backPath={getBackURL(queryString, breadcrumbs)}
            title={loader ? '...' : title}
            skeleton={{ active: loader }}
            actions={[
              {
                label: __(T.misc.cancel),
                type: 'cancel',
                onClick: () => navigate(`/configuration/pickingFlows${queryString ?? ''}`),
              },
              {
                label: pickingFlow.id ? __(T.misc.update) : __(T.misc.create),
                type: 'submit',
              },
            ]}
          />
          <Section customClass="stw-section-page paged-header scroll">
            <AdvancedForm
              record={pickingFlow}
              handleChange={this.handleChange}
              parameters={{ pickingFlowDiscriminators: PickingFlowDiscriminators }}
              fields={PICKING_FLOW_FIELDS}
              store={this.store}
            />
          </Section>
        </StylewherePage>
      </Form>
    )
  }
}

export default PickingFlowForm
