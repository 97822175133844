import { Component, createRef } from 'react'
import { Modal, FormInstance, Col, Form, Space } from 'antd'
import { T, __ } from '../../shared/i18n'
import { ModalHeader, AdvancedForm, ModalAddButton } from '..'
import { StwEnumerationValues } from '../../api'
import { VALUES_FIELDS } from '../../config/Pages/Settings/EnumerationValues'
import { FormValidateMessages } from '../../constants'
import { AttributeUtil } from '../../config/utility/utility'

interface Props {
  visible: boolean
  close: () => void
  value: StwEnumerationValues
  save: (value: StwEnumerationValues) => void
}

interface State {
  data: StwEnumerationValues | undefined
}

export class ModalValueForm extends Component<Props, State> {
  formRef = createRef<FormInstance>()

  constructor(props) {
    super(props)
    this.state = {
      data: undefined,
    }
  }

  componentDidMount() {
    const { value } = this.props
    this.setState({
      data: value,
    })
    if (this.formRef && this.formRef.current) {
      this.formRef.current.setFieldsValue(value)
    }
  }

  handleChange = (key, value) => {
    const { data } = this.state
    AttributeUtil.setAttribute(data, key, value)
    if (key === 'attributes') {
      if (this.formRef && this.formRef.current) {
        this.formRef.current.setFieldsValue({ attributes: data ? data.attributes : {} })
      }
    }
    this.setState({
      data: data,
    })
  }

  confirm = () => {
    this.props.save(this.state.data || {})
  }

  render() {
    const { visible, close } = this.props
    const { data } = this.state
    return (
      <Modal
        title={<ModalHeader close={close} title={__(T.misc.value)} />}
        centered
        open={visible}
        closable={false}
        footer={null}
        onCancel={() => close()}
        width="50%"
        className="stw-workstation-rfid-antennas-modal stw-workstation-no-errors"
      >
        <Col span={24}>
          {data && (
            <Form
              ref={this.formRef}
              layout="vertical"
              onFinish={async () => {
                this.confirm()
              }}
              style={{ width: '100%', height: '100%' }}
              initialValues={data}
              validateMessages={FormValidateMessages}
            >
              <Space
                size="small"
                style={{ width: '100%' }}
                className="stylewhere-form-attributes modal-attribute"
                direction="vertical"
              >
                <AdvancedForm record={data} handleChange={this.handleChange} fields={VALUES_FIELDS} />
                <ModalAddButton pb={5} mTop={0} close={close} />
              </Space>
            </Form>
          )}
        </Col>
      </Modal>
    )
  }
}
