import { Component } from 'react'
import { DetailCell, StwUser } from '../../../../api'
import Users from '../../../../api/Users'
import { AdvancedTable, GridData, StylewherePage, DefaultHeader, NotFound, Section } from '../../../../components'
import { __, T } from '../../../../shared/i18n'
import { USER_PLACES_COLUMNS, USERS_DATA } from '../../../../config/Pages/UsersRoles/Users'
import { ExtendRouteComponentProps } from '../../../../types'
import { DEFAULT_PAGE_SIZE, SkeltonAttribute } from '../../../../constants'
import {
  getDetailDataWithAttributesMapping,
  getMultipleTitle,
  getBackURL,
  getFragmentObject,
} from '../../../../shared/utils'

interface State {
  user: StwUser
  capabilitiesConfiguration: DetailCell[]
  loader: boolean
  detailData: DetailCell[]
}

class UserDetail extends Component<ExtendRouteComponentProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      user: {
        username: '',
      },
      loader: true,
      capabilitiesConfiguration: [],
      detailData: USERS_DATA,
    }
  }

  componentDidMount = async () => {
    const { match } = this.props
    let user
    let capabilitiesConfiguration: DetailCell[] = []
    const data: DetailCell[] = await getDetailDataWithAttributesMapping(USERS_DATA, 'user', 2)
    if (match && match.params && match.params.userId) {
      user = await Users.get<StwUser>(match.params.userId).then((res) => res)
      capabilitiesConfiguration = this.getCapabilitiesConfiguration(user)
    }
    this.setState({
      user: user && !user.errorId ? user : undefined,
      detailData: data,
      loader: false,
      capabilitiesConfiguration: capabilitiesConfiguration,
    })
  }

  getCapabilitiesConfiguration(user: StwUser): DetailCell[] {
    const capabilitiesConfiguration: DetailCell[] = []
    if (user && user.capabilities) {
      user.capabilities.forEach((value, index) => {
        capabilitiesConfiguration.push({
          title: 'Capability '.concat(String(index)),
          attribute: value,
          columns: 8,
        })
      })
    }
    return capabilitiesConfiguration
  }

  render() {
    const { user, loader, capabilitiesConfiguration, detailData } = this.state
    const { breadcrumbs, queryString } = this.props
    const viewCapabilities = loader || (user && user.capabilities && user.capabilities.length > 0)
    const totalElements = user && user.userPlaces ? user.userPlaces.length : 0
    const fragment = getFragmentObject(user, 'username')
    return (
      <StylewherePage {...this.props} noOverflow fragment={fragment}>
        <DefaultHeader
          backPath={getBackURL(queryString, breadcrumbs)}
          multipleTitle={getMultipleTitle(user, ['id', 'username'])}
          title={__(T.misc.not_found)}
          skeleton={{ active: loader, options: { multiple: true } }}
        />
        <Section customClass={`stw-section-page paged-header ${user || loader ? 'scroll transparent' : ''}`}>
          {user || loader ? (
            <>
              <GridData fields={detailData} record={user || {}} skeleton={loader} />
              <Section>
                <AdvancedTable
                  loader={loader}
                  tableTitle={{ title: __(T.misc.userPlaces_title), emptyTitle: __(T.user.noPlacesFoundTitle) }}
                  pagination={{
                    pageNumber: 1,
                    pageSize: DEFAULT_PAGE_SIZE,
                    sortValues: {},
                  }}
                  columns={USER_PLACES_COLUMNS}
                  filters={{
                    filterDefinitions: [],
                  }}
                  content={[...((user && user.userPlaces) ?? [])]}
                  totalElements={totalElements}
                  disabledPagination
                  disabledNoDataFound
                  activeDefaultClassColumn
                />
              </Section>
              {viewCapabilities && (
                <GridData
                  title={__(T.misc.capabilities_title)}
                  fields={loader ? SkeltonAttribute : capabilitiesConfiguration}
                  record={loader ? [] : user.capabilities}
                  skeleton={loader}
                />
              )}
            </>
          ) : (
            <NotFound fullheight title={__(T.misc.noRecordFoundTitle)} />
          )}
        </Section>
      </StylewherePage>
    )
  }
}

export default UserDetail
