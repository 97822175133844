import { Component } from 'react'
import { ITEM_TYPES_DATA } from '../../../../config/Pages/Settings/ItemTypes'
import { StwItemType } from '../../../../api'
import ItemTypes from '../../../../api/ItemTypes'
import { GridData, StylewherePage, DefaultHeader, NotFound, Section } from '../../../../components'
import { __, T } from '../../../../shared/i18n'
import { ExtendRouteComponentProps } from '../../../../types'
import { getMultipleTitle, getBackURL, getFragmentObject } from '../../../../shared/utils'

interface State {
  itemType: StwItemType | undefined
  loader: boolean
}

class ItemTypeDetail extends Component<ExtendRouteComponentProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      itemType: undefined,
      loader: true,
    }
  }

  componentDidMount() {
    this.initialize()
  }

  initialize = async () => {
    let itemType: StwItemType = {}
    if (this.props.match) {
      const itemTypeId = this.props.match.params.itemTypeId
      if (itemTypeId) {
        itemType = await ItemTypes.get<StwItemType>(itemTypeId)
      }
    }
    this.setState({
      itemType: itemType.id ? itemType : undefined,
      loader: false,
    })
  }

  render() {
    const { itemType, loader } = this.state
    const { breadcrumbs, queryString } = this.props
    const fragment = getFragmentObject(itemType, 'code')
    return (
      <StylewherePage {...this.props} noOverflow fragment={fragment}>
        <DefaultHeader
          backPath={getBackURL(queryString, breadcrumbs)}
          multipleTitle={getMultipleTitle(itemType)}
          title={__(T.misc.not_found)}
          skeleton={{ active: loader, options: { multiple: true } }}
        />
        <Section customClass={`stw-section-page paged-header ${itemType || loader ? 'scroll transparent' : ''}`}>
          {itemType || loader ? (
            <GridData fields={ITEM_TYPES_DATA} record={loader ? [] : itemType} skeleton={loader} />
          ) : (
            <NotFound fullheight title={__(T.misc.noRecordFoundTitle)} />
          )}
        </Section>
      </StylewherePage>
    )
  }
}

export default ItemTypeDetail
