import {
  AdvancedTableColumn,
  AdvancedTableContentKind,
  AdvancedTableFilterKind,
  AdvancedTableFiltersDef,
} from '../../../components/AdvancedTable'
import {
  AdvancedFormAttributeType,
  AdvancedFormField,
  AdvancedFormFieldBuilder,
  AdvancedFormInputType,
} from '../../../components/AdvancedForm/advancedFormTypes'
import { __ } from '../../../shared/i18n'
import { DetailCell, StwEditableScanner, StwScanner } from '../../../api'

export const SCANNERS_FILTERS: AdvancedTableFiltersDef = [
  {
    key: 'equalCodes',
    label: __('fields.labels.code'),
    type: AdvancedTableFilterKind.STRING_LIST,
    column: 'code',
    col: 8,
  },
  {
    key: 'description',
    label: __('fields.labels.description'),
    type: AdvancedTableFilterKind.STRING,
    column: 'description',
    col: 8,
  },
  {
    key: 'deviceControllerId',
    label: __('fields.labels.deviceController'),
    type: AdvancedTableFilterKind.AUTOCOMPLETE,
    column: 'deviceController.code',
    autocomplete: {
      endpoint: '/api/v1/deviceControllers',
      sort: 'code,asc',
      multiple: false,
    },
    option: 'deviceControllerTypeFilters',
    col: 8,
  },
  {
    key: 'targetZoneIds',
    label: __('fields.labels.targetZone'),
    type: AdvancedTableFilterKind.AUTOCOMPLETE,
    column: 'targetZone',
    autocomplete: {
      endpoint: '/api/v1/zones',
      sort: 'code,asc',
      multiple: true,
    },
    col: 8,
  },
  {
    key: 'userId',
    label: __('fields.labels.user'),
    type: AdvancedTableFilterKind.AUTOCOMPLETE,
    column: 'user.username',
    autocomplete: {
      endpoint: '/api/v1/users',
      sort: 'username,asc',
      multiple: false,
    },
    col: 8,
  },
  {
    key: 'operationIds',
    label: __('fields.labels.operation'),
    type: AdvancedTableFilterKind.AUTOCOMPLETE,
    column: 'operation.code',
    autocomplete: {
      endpoint: '/api/v1/operations',
      sort: 'code,asc',
      multiple: true,
    },
    col: 8,
  },
  {
    key: 'creationDate',
    label: __('fields.labels.creationDate'),
    type: AdvancedTableFilterKind.DATE_RANGE,
    column: 'creationDate',
    col: 8,
  },
  {
    key: 'lastModifyDate',
    label: __('fields.labels.lastModifyDate'),
    type: AdvancedTableFilterKind.DATE_RANGE,
    column: 'lastModifyDate',
    col: 8,
  },
]

export const SCANNERS_FIELDS: AdvancedFormField[] = [
  AdvancedFormFieldBuilder.buildAutocomplete(
    'place',
    '/api/v1/places',
    'code,asc',
    undefined,
    undefined,
    [{ required: true }],
    __('fields.labels.place'),
    __('fields.placeholders.place')
  ),
  {
    key: 'code',
    type: AdvancedFormInputType.UNIQUE_TEXT,
    label: __('fields.labels.code'),
    placeholder: __('fields.placeholders.code'),
    rules: [{ required: true }],
    unique: {
      endpoint: '/api/v1/scanners',
      parameter: 'equalCodes',
    },
  },
  AdvancedFormFieldBuilder.buildText('description'),
  AdvancedFormFieldBuilder.buildAutocomplete(
    'deviceController',
    '/api/v1/deviceControllers',
    'code,asc',
    undefined,
    undefined,
    [{ required: true }],
    __('fields.labels.deviceController'),
    __('fields.placeholders.deviceController'),
    false,
    false,
    false,
    undefined,
    'deviceControllerTypeFilters'
  ),
  AdvancedFormFieldBuilder.buildAutocomplete(
    'reader',
    '/api/v1/rfidReaders',
    'code,asc',
    undefined,
    undefined,
    [{ required: true }],
    __('fields.labels.rfidReader'),
    __('fields.placeholders.rfidReader'),
    false,
    false,
    false,
    undefined,
    'readerFilters',
    undefined,
    (record: StwEditableScanner) => record.place === undefined || record.place.id === undefined
  ),
  {
    key: 'rfidAntennas',
    type: AdvancedFormInputType.AUTOCOMPLETE,
    rules: [{ required: true }],
    placeholder: __('fields.placeholders.rfidAntenna'),
    label: __('fields.labels.rfidAntenna'),
    activeAddModal: false,
    displayInRow: false,
    option: 'antennaFilters',
    autocomplete: {
      endpoint: '/api/v1/rfidAntennas',
      sort: 'code,asc',
      multiple: true,
      withIdValues: true,
    },
    readonly: (record: StwEditableScanner) => record.reader === undefined,
  },
  AdvancedFormFieldBuilder.buildAutocomplete(
    'user',
    '/api/v1/users',
    'username,asc',
    undefined,
    undefined,
    [{ required: true }],
    __('fields.labels.user'),
    __('fields.placeholders.user')
  ),
  AdvancedFormFieldBuilder.buildSwitch('autoStart'),
  AdvancedFormFieldBuilder.buildNumber('lifespan', [{ required: true }], { min: 0 }),
  AdvancedFormFieldBuilder.buildAutocomplete(
    'operation',
    '/api/v1/operations',
    'code,asc',
    undefined,
    undefined,
    [{ required: true }],
    __('fields.labels.operation'),
    __('fields.placeholders.operation'),
    false,
    false,
    false,
    undefined,
    'operationFilters'
  ),
  AdvancedFormFieldBuilder.buildAutocomplete(
    'targetZone',
    '/api/v1/zones',
    'code,asc',
    undefined,
    undefined,
    [],
    __('fields.labels.targetZone'),
    __('fields.placeholders.targetZone'),
    false,
    false,
    false,
    undefined,
    'targetZoneFilters',
    'targetZoneRules',
    (record: StwEditableScanner) => record.place === undefined || record.place.id === undefined
  ),
  AdvancedFormFieldBuilder.buildAttribute('attributes', AdvancedFormAttributeType.SIMPLE, false, 'scanner'),
]

export const SCANNERS_COLUMNS: AdvancedTableColumn[] = [
  {
    key: 'code',
    dataIndex: 'code',
    title: __('fields.labels.code'),
  },
  {
    key: 'description',
    dataIndex: 'description',
    title: __('fields.labels.description'),
  },
  {
    key: 'deviceController.code',
    dataIndex: 'deviceController',
    title: __('fields.labels.deviceController'),
  },
  {
    key: 'targetZone',
    dataIndex: 'targetZone',
    title: __('fields.labels.targetZone'),
    type: AdvancedTableContentKind.CODE_DESC,
  },
  {
    key: 'user.username',
    dataIndex: 'user',
    title: __('fields.labels.user'),
  },
  {
    key: 'rfidAntennas',
    dataIndex: 'rfidAntennas',
    title: __('fields.labels.rfidAntennasNumber'),
    sortable: false,
    render: (text, record: StwScanner) => {
      return (
        <>
          <div>{record.rfidAntennas ? record.rfidAntennas.length : 0}</div>
        </>
      )
    },
  },
  {
    key: 'autoStart',
    dataIndex: 'autoStart',
    title: __('fields.labels.autoStart'),
    type: AdvancedTableContentKind.BOOLEAN,
  },
  {
    key: 'operation.code',
    dataIndex: 'operation',
    title: __('fields.labels.operation'),
  },
  {
    key: 'lifespan',
    dataIndex: 'lifespan',
    title: __('fields.labels.lifespan'),
  },
  {
    key: 'creationDate',
    dataIndex: 'creationDate',
    title: __('fields.labels.creationDate'),
    type: AdvancedTableContentKind.DATE_TIME,
  },
  {
    key: 'lastModifyDate',
    dataIndex: 'lastModifyDate',
    title: __('fields.labels.lastModifyDate'),
    type: AdvancedTableContentKind.DATE_TIME,
  },
]

export const SCANNERS_DATA: DetailCell[] = [
  { title: 'id', attribute: 'id', columns: 24 },
  { title: __(`fields.labels.code`), attribute: 'code', columns: 12 },
  { title: __(`fields.labels.description`), attribute: 'description', columns: 12 },
  { title: __(`fields.labels.code`), attribute: 'deviceController.code', columns: 12 },
  { title: __(`fields.labels.targetZone`), attribute: 'targetZone', columns: 12, type: 'codeDesc' },
  { title: __(`fields.labels.user`), attribute: 'user.username', columns: 12 },
  { title: __(`fields.labels.autostart`), attribute: 'autoStart', columns: 12, type: 'boolean' },
  { title: __(`fields.labels.operation`), attribute: 'operation.code', columns: 12 },
  { title: __(`fields.labels.lifespan`), attribute: 'lifespan', columns: 12 },
  { title: __(`fields.labels.creationDate`), attribute: 'creationDate', type: 'date', columns: 12 },
  { title: __(`fields.labels.lastModifyDate`), attribute: 'lastModifyDate', type: 'date', columns: 12 },
]

export const SCANNER_ANTENNAS_COLUMNS: AdvancedTableColumn[] = [
  {
    key: 'code',
  },
  {
    key: 'description',
  },
  {
    title: __('fields.labels.reader'),
    key: 'reader.code',
  },
  {
    key: 'readerPort',
  },
  {
    title: __('fields.labels.zone'),
    key: 'zone.code',
  },
  {
    key: 'txPower',
  },
  {
    key: 'rxSensitivity',
  },
]
