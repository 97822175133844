/* eslint-disable func-names */
import { Component } from 'react'
import { Form, InputNumber } from 'antd'
import { __ } from '../../shared/i18n'
import { AdvancedFormField } from './advancedFormTypes'

interface Props {
  record: any
  item: AdvancedFormField
  rules?: any[]
  handleChange: any
  labelHidden?: boolean
}

export class AdvancedFormInputNumberField extends Component<Props> {
  render() {
    const { item, handleChange, rules, labelHidden } = this.props
    return (
      <Form.Item
        name={item.key.split('.')}
        className="stylewhere-form-item"
        rules={rules}
        label={labelHidden ? null : __(`fields.labels.${item.key}`, item.label)}
      >
        <InputNumber
          style={{ width: '100%' }}
          id={item.key}
          onChange={(e) => handleChange(e)}
          value={item.range && item.range.value}
          min={item.range && item.range.min}
          max={item.range && item.range.max}
          placeholder={__(`fields.placeholders.${item.key}`, item.placeholder)}
        />
      </Form.Item>
    )
  }
}
