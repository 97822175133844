import { AxiosRequestConfig } from 'axios'
import { PageResult } from './BaseResource'
import { api, responseErrorCheck } from './api'

export default class AutocompleteApi {
  static search<T>(endpoint: string, params = {}, requestConfig?: AxiosRequestConfig): Promise<PageResult<T>> {
    return api.get<PageResult<T>>(endpoint, params, requestConfig).then(responseErrorCheck)
  }

  static searchNoPage<T>(endpoint: string, params = {}, requestConfig?: AxiosRequestConfig): Promise<T[]> {
    return api.get<PageResult<T>>(endpoint, params, requestConfig).then(responseErrorCheck)
  }

  static getCurrent<T>(endpoint: string, id: string, requestConfig?: AxiosRequestConfig): Promise<T> {
    return api.get<PageResult<T>>(`${endpoint}/${id}`, requestConfig).then(responseErrorCheck)
  }
}
