import {
  AdvancedTableColumn,
  AdvancedTableContentKind,
  AdvancedTableFilterKind,
  AdvancedTableFiltersDef,
} from '../../../components/AdvancedTable'
import { __ } from '../../../shared/i18n'
import { AdvancedTableRowColumn } from '../../../components'
import {
  AdvancedFormAttributeType,
  AdvancedFormField,
  AdvancedFormFieldBuilder,
  AdvancedFormInputType,
} from '../../../components/AdvancedForm/advancedFormTypes'
import { CAPABILITIES, getActionsTableColumns } from '../../utility/capabilityUtility'

export const XSPAN_FILTERS: AdvancedTableFiltersDef = [
  {
    key: 'equalCodes',
    label: __('fields.labels.code'),
    type: AdvancedTableFilterKind.STRING_LIST,
    column: 'code',
    col: 8,
  },
  {
    key: 'description',
    label: __('fields.labels.description'),
    type: AdvancedTableFilterKind.STRING,
    column: 'description',
    col: 8,
  },
  {
    key: 'deviceControllerId',
    label: __('fields.labels.deviceController'),
    type: AdvancedTableFilterKind.AUTOCOMPLETE,
    column: 'deviceController.code',
    autocomplete: {
      endpoint: '/api/v1/deviceControllers',
      sort: 'code,asc',
      multiple: false,
    },
    option: 'deviceControllerTypeFilters',
    col: 8,
  },
  {
    key: 'placeIds',
    label: __('fields.labels.place'),
    type: AdvancedTableFilterKind.AUTOCOMPLETE,
    column: 'place',
    autocomplete: {
      endpoint: '/api/v1/places',
      sort: 'code,asc',
      multiple: true,
    },
    col: 8,
  },
  {
    key: 'sector2ZoneIds',
    label: __('fields.labels.sector2Zone'),
    type: AdvancedTableFilterKind.AUTOCOMPLETE,
    column: 'sector2Zone',
    autocomplete: {
      endpoint: '/api/v1/zones',
      sort: 'code,asc',
      multiple: true,
    },
    col: 8,
  },
  {
    key: 'sector3ZoneIds',
    label: __('fields.labels.sector3Zone'),
    type: AdvancedTableFilterKind.AUTOCOMPLETE,
    column: 'sector3Zone',
    autocomplete: {
      endpoint: '/api/v1/zones',
      sort: 'code,asc',
      multiple: true,
    },
    col: 8,
  },
  {
    key: 'userId',
    label: __('fields.labels.user'),
    type: AdvancedTableFilterKind.AUTOCOMPLETE,
    column: 'user.username',
    autocomplete: {
      endpoint: '/api/v1/users',
      sort: 'id,asc',
      multiple: false,
    },
    col: 8,
  },
  {
    key: 'creationDate',
    label: __('fields.labels.creationDate'),
    type: AdvancedTableFilterKind.DATE_RANGE,
    column: 'creationDate',
    col: 8,
  },
  {
    key: 'lastModifyDate',
    label: __('fields.labels.lastModifyDate'),
    type: AdvancedTableFilterKind.DATE_RANGE,
    column: 'lastModifyDate',
    col: 8,
  },
]

export const XSPAN_COLUMNS: Array<AdvancedTableColumn> = [
  {
    key: 'code',
  },
  {
    key: 'description',
  },
  {
    title: __('fields.labels.deviceController'),
    key: 'deviceController.code',
  },
  {
    title: __('fields.labels.operation'),
    key: 'operation.code',
  },
  {
    title: __(`fields.labels.place`),
    key: 'place',
    type: AdvancedTableContentKind.CODE_DESC,
  },
  {
    title: __(`fields.labels.sector2Zone`),
    key: 'sector2Zone',
    type: AdvancedTableContentKind.CODE_DESC,
  },
  {
    title: __(`fields.labels.sector3Zone`),
    key: 'sector3Zone',
    type: AdvancedTableContentKind.CODE_DESC,
  },
  {
    title: __('fields.labels.user'),
    key: 'user.username',
  },
  {
    key: 'autoStart',
    type: AdvancedTableContentKind.BOOLEAN,
  },
  {
    key: 'ipAddress',
    type: AdvancedTableContentKind.STRING,
  },
  {
    key: 'creationDate',
    type: AdvancedTableContentKind.DATE_TIME,
  },
  {
    key: 'lastModifyDate',
    type: AdvancedTableContentKind.DATE_TIME,
  },
  {
    key: 'action',
    width: 'small',
    fixedType: 'right',
    sortable: false,
    unmanageable: true,
    render: (text, record) => (
      <AdvancedTableRowColumn
        actions={getActionsTableColumns(record.id, '/devices/xspans', CAPABILITIES.RESOURCE_XSPAN_READER_EDIT)}
      />
    ),
  },
]

export const XSPAN_FIELDS: AdvancedFormField[] = [
  AdvancedFormFieldBuilder.buildAutocomplete(
    'place',
    '/api/v1/places',
    'code,asc',
    undefined,
    undefined,
    [{ required: true }],
    __('fields.labels.place'),
    __('fields.placeholders.place')
  ),
  {
    key: 'code',
    type: AdvancedFormInputType.UNIQUE_TEXT,
    label: __('fields.labels.code'),
    placeholder: __('fields.placeholders.code'),
    rules: [{ required: true }],
    unique: {
      endpoint: '/api/v1/xspans',
      parameter: 'equalCodes',
    },
  },
  AdvancedFormFieldBuilder.buildText('description'),
  AdvancedFormFieldBuilder.buildAutocomplete(
    'deviceController',
    '/api/v1/deviceControllers',
    'code,asc',
    undefined,
    undefined,
    [{ required: true }],
    __('fields.labels.deviceController'),
    __('fields.placeholders.deviceController'),
    false,
    false,
    false,
    undefined,
    'deviceControllerTypeFilters'
  ),
  AdvancedFormFieldBuilder.buildAutocomplete(
    'operation',
    '/api/v1/operations',
    'code,asc',
    undefined,
    undefined,
    [{ required: true }],
    __('fields.labels.operation'),
    __('fields.placeholders.operation'),
    false,
    false,
    false,
    undefined,
    'operationTypeFilters'
  ),
  AdvancedFormFieldBuilder.buildText('ipAddress', [{ required: true }]),
  AdvancedFormFieldBuilder.buildSlider('txPower', [{ required: true }], { min: 10, max: 30 }),
  AdvancedFormFieldBuilder.buildSelect('mode', 'modes', [{ required: true }]),
  AdvancedFormFieldBuilder.buildSelect('fieldOfView', 'fieldsOfView', [{ required: true }]),
  AdvancedFormFieldBuilder.buildNumber('tagAgeInterval', [{ required: true }], { min: 0 }),
  AdvancedFormFieldBuilder.buildSwitch('autoStart', undefined, undefined, undefined, undefined, undefined, [
    { required: false },
  ]),
  AdvancedFormFieldBuilder.buildNumber('maxConnectionTimeout', [{ required: true }], { min: 0 }),
  AdvancedFormFieldBuilder.buildSwitch('keepAliveEnabled', undefined, undefined, undefined, undefined, undefined, [
    { required: false },
  ]),
  AdvancedFormFieldBuilder.buildNumber(
    'keepAlivePeriod',
    [],
    { min: 0 },
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    'keepAlivePeriodRules'
  ),
  AdvancedFormFieldBuilder.buildSwitch(
    'linkMonitorModeEnabled',
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    [{ required: false }]
  ),
  AdvancedFormFieldBuilder.buildNumber(
    'linkDownThreshold',
    [],
    { min: 0 },
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    'linkDownThresholdRules'
  ),
  AdvancedFormFieldBuilder.buildAutocomplete(
    'sector2Zone',
    '/api/v1/zones',
    'code,asc',
    undefined,
    undefined,
    [{ required: true }],
    __('fields.labels.sector2Zone'),
    __('fields.placeholders.sector2Zone'),
    false,
    false,
    false,
    undefined,
    'sector2ZonesFilters'
  ),
  AdvancedFormFieldBuilder.buildAutocomplete(
    'sector3Zone',
    '/api/v1/zones',
    'code,asc',
    undefined,
    undefined,
    [{ required: true }],
    __('fields.labels.sector3Zone'),
    __('fields.placeholders.sector3Zone'),
    false,
    false,
    false,
    undefined,
    'sector3ZonesFilters'
  ),
  AdvancedFormFieldBuilder.buildAutocomplete(
    'user',
    '/api/v1/users',
    'username,asc',
    undefined,
    undefined,
    [{ required: true }],
    __('fields.labels.user'),
    __('fields.placeholders.user')
  ),
  AdvancedFormFieldBuilder.buildSwitch('transferToZoneEnabled', undefined, undefined, undefined, undefined, undefined, [
    { required: false },
  ]),
  AdvancedFormFieldBuilder.buildAttribute('attributes', AdvancedFormAttributeType.SIMPLE, false, 'xspan'),
]
