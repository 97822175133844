import { Component, PropsWithChildren } from 'react'
import { Layout } from 'antd'
import '../../theme/App.less'
import { PageBread, PageContent } from '..'
import { BreadcrumbsFragmentType, BreadcrumbsType } from '../PageBread'
import { ModalPage } from '../ModalPage'
import { getBreadcrumbs } from '../../shared/utils'
import { ExtendRouteComponentProps } from '../../types'

interface Props extends ExtendRouteComponentProps, PropsWithChildren {
  fragment?: BreadcrumbsFragmentType[]
  noOverflow?: boolean
}

interface State {
  breads?: BreadcrumbsType | undefined
}

export class StylewherePage extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      breads: undefined,
    }
  }

  componentDidMount() {
    const { breadcrumbs, fragment } = this.props
    if (breadcrumbs) {
      this.setBreadcrumbs(breadcrumbs, fragment)
    }
  }

  shouldComponentUpdate = (nextProps) => {
    const { breadcrumbs, fragment } = this.props
    if (
      JSON.stringify(breadcrumbs) !== JSON.stringify(nextProps.breadcrumbs) ||
      JSON.stringify(fragment) !== JSON.stringify(nextProps.fragment) ||
      (!fragment && nextProps.fragment)
    ) {
      this.setBreadcrumbs(nextProps.breadcrumbs, nextProps.fragment)
    }
    return true
  }

  setBreadcrumbs = async (breadcrumbs, fragment) => {
    const breads = getBreadcrumbs(this.props.queryString, breadcrumbs, fragment)
    this.setState({
      breads: breads.breads,
    })
  }

  render() {
    const { children, noOverflow, location } = this.props
    const { breads } = this.state
    return (
      <Layout className="fullheight">
        <Layout className="site-layout">
          {breads && <PageBread breadcrumbs={breads} />}
          <PageContent noOverflow={noOverflow}>{children}</PageContent>
          {location && <ModalPage location={location} />}
        </Layout>
      </Layout>
    )
  }
}
