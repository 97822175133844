import { Component } from 'react'
import { Button, Col, Input, Modal, Row, Typography } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import { __ } from '../../shared/i18n'
import RfidAntennas from '../../api/RfidAntennas'
import { StwRfidAntenna } from '../../api'
import WorkstationRfidAntennasModalCollapseList from './WorkstationRfidAntennasModalCollapseList'
import { Loader } from '..'
import { CAPABILITIES, hasAnyCapabilities } from '../../config/utility/capabilityUtility'
import { MAX_SIZE_LIMIT } from '../../constants'

type StwRfidAntennaWithSelection = {
  rfidAntenna: StwRfidAntenna
  selected: boolean
}

interface Props {
  rfidAntennaIds: string[]
  visible: boolean
  onConfirm: (selected: string[]) => void
  onCancel: () => void
  handleNewAntennas: () => void
  placeId: string
  refreshCounter?: number
  workstationId: string
}

interface State {
  rfidAntennasWithSelection: StwRfidAntennaWithSelection[]
  loader: boolean
  currentPlaceId: string
  refresh: number
}

const { Title } = Typography

export class WorkstationRfidAntennasModal extends Component<Props, State> {
  loading = false

  constructor(props) {
    super(props)
    this.state = {
      rfidAntennasWithSelection: [],
      loader: true,
      currentPlaceId: '',
      refresh: 0,
    }
  }

  componentDidMount() {
    const { placeId } = this.props
    if (placeId !== '') {
      this.loading = true
      this.attachLoaderAntennas(placeId, 0)
    }
  }

  attachLoaderAntennas = (placeId, counter) => {
    this.setState(
      {
        loader: true,
        rfidAntennasWithSelection: [],
        currentPlaceId: placeId,
        refresh: counter,
      },
      this.loadAntennas
    )
  }

  loadAntennas = async () => {
    const { currentPlaceId } = this.state
    const rfidAntennasWithSelection = await this.getRfidAntennasWithSelection(currentPlaceId)
    this.setState({
      rfidAntennasWithSelection: rfidAntennasWithSelection,
      loader: false,
    })
    this.loading = false
  }

  getRfidAntennasWithSelection = async (placeId = '', rfidAntennaSearch = '') => {
    return RfidAntennas.searchFull({
      size: MAX_SIZE_LIMIT,
      search: rfidAntennaSearch,
      placeIds: placeId,
      freeOrWorkstationId: this.props.workstationId,
    })
      .then((response) => (response ? response.content : []))
      .then((rfidAntennas) => rfidAntennas.map((rfidAntenna) => this.decorateWithSelection(rfidAntenna)))
  }

  decorateWithSelection(rfidAntenna: StwRfidAntenna): StwRfidAntennaWithSelection {
    return { rfidAntenna: rfidAntenna, selected: this.isSelected(rfidAntenna) }
  }

  isSelected(rfidAntenna: StwRfidAntenna) {
    return this.props.rfidAntennaIds.some((rfidAntennaId) => rfidAntennaId === rfidAntenna.id)
  }

  shouldComponentUpdate = (nextProps) => {
    const { rfidAntennaIds, visible } = this.props
    const { currentPlaceId, refresh } = this.state
    const tmp = this.state.rfidAntennasWithSelection
    if (nextProps.placeId !== '') {
      if (nextProps.visible && nextProps.visible !== visible) {
        if (tmp.length === 0 || refresh !== nextProps.refreshCounter || currentPlaceId !== nextProps.placeId) {
          if (!this.loading) {
            this.loading = true
            this.attachLoaderAntennas(nextProps.placeId, nextProps.refreshCounter)
          }
        } else {
          for (let s = 0; s < tmp.length; s++) {
            tmp[s].selected = false
          }

          for (let s = 0; s < rfidAntennaIds.length; s++) {
            const selectedRfidAntennaIndex = tmp.findIndex((record) => record.rfidAntenna.id === rfidAntennaIds[s])
            if (selectedRfidAntennaIndex >= 0) {
              tmp[selectedRfidAntennaIndex].selected = true
            }
          }

          this.setState({
            rfidAntennasWithSelection: tmp,
          })
        }
      }
    }
    return true
  }

  onSelect = (selectedRfidAntennaId: string) => {
    const tmp = this.state.rfidAntennasWithSelection
    const selectedRfidAntennaIndex = tmp.findIndex((record) => record.rfidAntenna.id === selectedRfidAntennaId)
    if (selectedRfidAntennaIndex >= 0) {
      tmp[selectedRfidAntennaIndex].selected = !tmp[selectedRfidAntennaIndex].selected
      this.setState({
        rfidAntennasWithSelection: tmp,
      })
    }
  }

  confirmChoose = () => {
    const { rfidAntennasWithSelection } = this.state
    const { onConfirm } = this.props
    const selected: any[] = []
    for (let s = 0; s < rfidAntennasWithSelection.length; s++) {
      if (rfidAntennasWithSelection[s].selected) {
        selected.push(rfidAntennasWithSelection[s].rfidAntenna.id)
      }
    }
    onConfirm(selected)
  }

  render() {
    const { visible, onCancel, handleNewAntennas } = this.props
    const { rfidAntennasWithSelection, loader, currentPlaceId } = this.state
    return (
      <Modal
        title={
          <Row gutter={22} style={{ marginTop: '20px' }}>
            <Col span={18}>
              <Title level={3}>
                <b>{__('misc.associate_antennas')}</b>
              </Title>
            </Col>
            <Col span={6} style={{ justifyContent: 'flex-end', display: 'flex' }}>
              <CloseCircleOutlined style={{ fontSize: 22 }} onClick={() => onCancel()} />
            </Col>
          </Row>
        }
        closable={false}
        open={visible}
        okText={__('misc.confirm')}
        onOk={() => this.confirmChoose()}
        onCancel={() => onCancel()}
        width="80%"
        className="stw-workstation-rfid-antennas-modal"
      >
        {!loader && (
          <Row gutter={24}>
            <Col span={24} style={{ marginBottom: '20px' }}>
              <Input
                style={{ borderRadius: '10px' }}
                placeholder="Search"
                suffix={
                  hasAnyCapabilities(CAPABILITIES.RESOURCE_RFID_READER_EDIT) && (
                    <div>
                      {/*<SearchOutlined style={{ fontSize: 22 }} />*/}
                      <Button onClick={() => handleNewAntennas()} className="stylewhere-button-primary">
                        {__('rfidReaders.create_new')}
                      </Button>
                    </div>
                  )
                }
                onChange={(event) => {
                  this.getRfidAntennasWithSelection(currentPlaceId, event.target.value).then((response) =>
                    this.setState({ rfidAntennasWithSelection: response })
                  )
                }}
              />
            </Col>

            <Col span={24}>
              <WorkstationRfidAntennasModalCollapseList
                rfidAntennasWithSelection={rfidAntennasWithSelection}
                onSelect={this.onSelect}
              />
            </Col>
          </Row>
        )}
        {loader && <Loader />}
      </Modal>
    )
  }
}
