import { Component } from 'react'
import { Row, Col } from 'antd'
import { Card } from '../types'
import { GridCardsRowHead, GridCardsRowBody } from '.'

interface Props {
  card: Card
}

export class GridCardsRow extends Component<Props> {
  render() {
    const { card } = this.props
    return (
      <Col className="stylewhere-card">
        {card.rows.length > 1 ? (
          <Row className="stylewhere-card-head">
            {card.rows.map((element, i) => {
              return (
                <Row key={`card${i}`} style={{ padding: 10 }} className="stylewhere-card-content">
                  <Row className="stylewhere-card-head">
                    <GridCardsRowHead
                      col={16}
                      icon={element.icon}
                      iconBkgColor={element.iconBkgColor}
                      label={element.label}
                    />
                    <GridCardsRowBody
                      col={8}
                      minH={84}
                      mTop={0}
                      bkgColor={element.bkgColor}
                      iconBkgColor={element.iconBkgColor}
                      counter={element.counter}
                      counter2={element.counter2}
                    />
                  </Row>
                </Row>
              )
            })}
          </Row>
        ) : (
          <Row className="stylewhere-card-content">
            <GridCardsRowHead
              col={24}
              icon={card.rows[0].icon}
              iconBkgColor={card.rows[0].iconBkgColor}
              label={card.rows[0].label}
            />
            <GridCardsRowBody
              col={24}
              mTop={15}
              bkgColor={card.rows[0].bkgColor}
              iconBkgColor={card.rows[0].iconBkgColor}
              counter={card.rows[0].counter}
              counter2={card.rows[0].counter2}
            />
          </Row>
        )}
      </Col>
    )
  }
}
