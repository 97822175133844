import { Component } from 'react'
import { StylewherePage, TableList } from '../../../../components'
import { OPERATION_COLUMNS, OPERATION_FILTERS } from '../../../../config/Pages/Settings/Operations'
import { __, T } from '../../../../shared/i18n'
import Operations from '../../../../api/Operations'
import { AdvancedTableConfig } from '../../../../components/AdvancedTable'
import { BooleanComboValues, OperationClients, OperationTypes } from '../../../../constants'
import { ExtendRouteComponentProps } from '../../../../types'
import { getTabsSettings } from '../../../../config/Pages/Settings/Tabs'
import { CAPABILITIES, getActionsList } from '../../../../config/utility/capabilityUtility'

const TABLE_CONFIG: AdvancedTableConfig = {
  columns: OPERATION_COLUMNS,
  filterDefinitions: OPERATION_FILTERS,
  filterParameters: { types: OperationTypes, enabledValues: BooleanComboValues, operationClients: OperationClients },
  manageColumnsPrefix: 'operations',
  activeDefaultClassColumn: true,
}

class OperationsList extends Component<ExtendRouteComponentProps> {
  render() {
    return (
      <StylewherePage {...this.props} noOverflow>
        <TableList
          tabs={getTabsSettings(1)}
          location={this.props.location}
          config={TABLE_CONFIG}
          resource={{
            call: Operations.searchTableList,
            endpoint: Operations.endpoint,
          }}
          actions={getActionsList(
            CAPABILITIES.RESOURCE_OPERATION_EDIT,
            __(T.operation.create_new),
            '/configuration/settings/operations/create/form'
          )}
        />
      </StylewherePage>
    )
  }
}

export default OperationsList
