import { Component } from 'react'
import dayjs from 'dayjs'
import { Button, Typography, Row } from 'antd'
import { LoadingOutlined, ExportOutlined, EyeOutlined, ImportOutlined, InboxOutlined } from '@ant-design/icons'
import {
  GridData,
  StylewherePage,
  DefaultHeader,
  NotFound,
  AdvancedTable,
  Section,
  AdvancedTableRowColumn,
  KeyValueGrid,
  GridCards,
  TableList,
} from '../../../components'
import {
  MovementType,
  StwFullInbound,
  StwFullOutbound,
  StwFullPacking,
  StwFullParcel,
  StwFullParcelEntry,
  StwProduct,
  DetailCell,
  StwOutbound,
  StwInbound,
  StwPacking,
} from '../../../api'
import Parcels from '../../../api/Parcels'
import { __, T } from '../../../shared/i18n'
import { BreadcrumbsFragmentType } from '../../../components/PageBread'
import { PARCEL_CELLS } from '../../../config/Pages/Reports'
import { Tab, TabBar } from '../../../components/TabBar'
import Outbounds from '../../../api/Outbounds'
import Inbounds from '../../../api/Inbounds'
import Packings from '../../../api/Packings'
import {
  AdvancedTableColumns,
  AdvancedTableSortDirection,
  AdvancedTableConfig,
  AdvancedTableColumn,
  AdvancedTableContentKind,
} from '../../../components/AdvancedTable'
import { showModal } from '../../../components/modalPageUtils'
import { MOVEMENT_FIELD, PRODUCT_ROW_COL, PRODUCT_ROW_COL_PACKING } from '../../../config/Pages/Parcels'
import { ExtendRouteComponentProps } from '../../../types'
import { CAPABILITIES, hasAnyCapabilities } from '../../../config/utility/capabilityUtility'
import {
  getCards,
  getFragment,
  getMultipleTitle,
  getBackURL,
  canRenderAttributes,
  getAttributeConfiguration,
} from '../../../shared/utils'
import { SkeltonAttribute } from '../../../constants'

const { Text } = Typography

interface State {
  record: StwFullParcel | null
  fragment?: BreadcrumbsFragmentType[]
  loader: boolean
  tabs: Tab[]
  tabSelected: string
  tabSelectedType: string
  tabDataLoading: boolean
  currentOp?: StwFullOutbound | StwFullInbound | StwFullPacking
  entries: OperationRow[]
  rowCols: AdvancedTableColumns
  attributeFields: DetailCell[]
  tableValueConfig: AdvancedTableConfig
  movementLoaded: boolean
  movementLoading: boolean
  isEmptyEntries: boolean
}

interface OperationRow {
  product: StwProduct
  detected: number
  unexpected: number
  itemType: boolean
}

class ParcelDetail extends Component<ExtendRouteComponentProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      record: null,
      loader: true,
      tabs: [],
      rowCols: [],
      attributeFields: [],
      tableValueConfig: {
        filterDefinitions: [],
      },
      movementLoaded: false,
      movementLoading: false,
      isEmptyEntries: true,
      tabSelected: '',
      tabSelectedType: '',
      tabDataLoading: false,
      entries: [],
    }
  }

  componentDidMount() {
    this.initialize()
  }

  getParcelEntriesColumns = (id) => {
    const cols: AdvancedTableColumn[] = [
      {
        key: 'detected',
        title: __(`fields.labels.detected`),
        render(text, record) {
          return <AdvancedTableRowColumn options={{ color: '#70CDEE' }} value={record.detected} tag />
        },
        sortable: false,
        className: 'stw-medium',
      },
      {
        key: 'unexpected',
        title: __(`fields.labels.unexpected`),
        render(text, record) {
          return record.unexpected >= 0 ? (
            <AdvancedTableRowColumn options={{ color: 'rgb(117, 235, 168)' }} value={record.unexpected} tag />
          ) : (
            <>n/a</>
          )
        },
        sortable: false,
        className: 'stw-medium',
      },
      {
        key: 'productCode',
        title: __(`fields.labels.itemProductCode`),
        sortable: false,
        type: AdvancedTableContentKind.STRING,
        className: 'stw-medium',
      },
      {
        key: 'creationDate',
        dataIndex: 'creationDate',
        sortable: false,
        title: __('fields.labels.creationDate'),
        type: AdvancedTableContentKind.DATE_TIME,
        className: 'stw-medium',
      },
    ]
    if (hasAnyCapabilities(CAPABILITIES.RESOURCE_ITEM_VIEW)) {
      cols.push({
        key: 'action',
        fixedType: 'right',
        sortable: false,
        unmanageable: true,
        className: 'stw-extra-small',
        render: (text, record) => (
          <AdvancedTableRowColumn
            actions={[
              {
                content: <EyeOutlined />,
                type: 'default',
                path: `/reports/parcel/${id}/item/${record.itemId}`,
              },
            ]}
          />
        ),
      })
    }
    return cols
  }

  initialize = async () => {
    const { match } = this.props
    let recordId = ''

    if (match && match.params) {
      recordId = match.params.recordId

      const fullParcel = await Parcels.getFullParcel(recordId)
      const fragments: BreadcrumbsFragmentType[] = []
      if (match.params.asnId) {
        fragments.push(getFragment(match.params.asnId, false, `/reports/asn/${match.params.asnId}`))
        fragments.push(getFragment(__(T.report.parcel), false, '/reports/parcel'))
      } else if (match.params.operationType && match.params.operationId && match.params.operationInstanceId) {
        fragments.push(
          getFragment(
            match.params.operationInstanceId,
            false,
            `/operationlogs/${match.params.operationType}/${match.params.operationId}/${match.params.operationInstanceId}`
          )
        )
        fragments.push(getFragment(__(T.report.parcel), false, '/reports/parcel'))
      }
      fragments.push(getFragment(recordId, true, undefined))

      this.setState({
        loader: false,
        record: fullParcel,
        fragment: fragments,
        attributeFields: getAttributeConfiguration(fullParcel),
        tableValueConfig: {
          columns: this.getParcelEntriesColumns(fullParcel.id),
          filterDefinitions: [],
          filterParameters: {},
          activeDefaultClassColumn: false,
          fixedTableHeight: true,
          disableLocation: true,
          disableColumnFilters: true,
          skeletonOptions: {},
          disabledNoDataFound: true,
          hiddenEmptyTable: true,
        },
      })
    }
  }

  setEmtpyContent = (num) => {
    this.setState({ isEmptyEntries: num === 0 })
  }

  initMovement = () => {
    this.setState({ movementLoading: true }, this.loadMovement)
  }

  getHistoryTabs = async (code) => {
    const params = { parcelCode: code }
    const outbounds = await Outbounds.search<StwOutbound>(params)
    const packings = await Packings.search<StwPacking>(params)
    const inbounds = await Inbounds.search<StwInbound>(params)

    const operations: any[] = [
      ...outbounds.content.map((o) => {
        return { ...o, type: 'OUTBOUND' }
      }),
      ...inbounds.content.map((o) => {
        return { ...o, type: 'INBOUND' }
      }),
      ...packings.content.map((o) => {
        return { ...o, type: 'PACKING' }
      }),
    ].sort((o1, o2) => (dayjs(o1.creationDate).isBefore(dayjs(o2.creationDate)) ? -1 : 1))

    const tabs: Tab[] = operations.map((op) => {
      return {
        value: op.id,
        type: op.type,
        label: dayjs(op.creationDate).format('DD/MM/YYYY HH:mm:ss'),
        iconRender: () => this.getOpIcon(op),
      }
    })
    return tabs
  }

  loadMovement = async () => {
    const { record } = this.state
    if (record) {
      const tabs = await this.getHistoryTabs(record.code)
      this.setState({
        tabs: tabs,
        movementLoaded: true,
        movementLoading: false,
      })
    } else {
      this.setState({
        movementLoaded: true,
        movementLoading: false,
      })
    }
  }

  showDetail(row: OperationRow) {
    showModal({
      type: 'MOVEMENT_PRODUCT_ITEMS',
      data: { movId: this.state.currentOp!.id, movType: this.state.currentOp!.type, prodId: row.product.id },
    })
  }

  getOpIcon(op: StwFullPacking | StwFullInbound | StwFullOutbound | undefined) {
    switch (op?.type) {
      case MovementType.INBOUND:
        return <ExportOutlined />
      case MovementType.OUTBOUND:
        return <ImportOutlined />
      default:
        return <InboxOutlined />
    }
  }

  async onChangeTab(tab: Tab) {
    this.setState(
      {
        tabSelected: tab.value,
        tabSelectedType: tab.type || '',
        tabDataLoading: true,
        entries: [],
      },
      this.loadTabData
    )
  }

  loadTabData = async () => {
    const { tabSelected, tabSelectedType } = this.state
    let currentOp: StwFullInbound | StwFullOutbound | StwFullPacking | undefined
    let parcelEntries: StwFullParcelEntry[]
    if (tabSelectedType === 'OUTBOUND') {
      currentOp = await Outbounds.getByIdFull(tabSelected)
      parcelEntries = (await Outbounds.fullParcelentries(tabSelected, { size: 10000000 })).content
    } else if (tabSelectedType === 'INBOUND') {
      currentOp = await Inbounds.getByIdFull(tabSelected)
      parcelEntries = (await Inbounds.fullParcelentries(tabSelected, { size: 10000000 })).content
    } else {
      currentOp = await Packings.getByIdFull(tabSelected)
      parcelEntries = (await Packings.fullParcelentries(tabSelected, { size: 10000000 })).content
    }

    const distinctProducts: StwProduct[] = parcelEntries
      .map((p) => (p.item ? p.item.product : p.product!))
      .filter(
        (v, index, arr) =>
          arr.map((a) => (a ? a.code : '')).filter((code, i, a) => a.indexOf(v ? v.code : '') === index).length > 0
      )

    const entries: OperationRow[] = distinctProducts.map((p) => {
      return {
        product: p,
        detected: 0,
        unexpected: 0,
        itemType: false,
      }
    })

    parcelEntries.forEach((pe) => {
      const entry = entries.filter(
        (e) =>
          (pe.item && e.product && e.product.code === pe.item.product?.code) ||
          (e.product && e.product.code === pe.product?.code)
      )[0]
      if (entry) {
        entry.detected += pe.detected
        entry.unexpected += pe.unexpected
        entry.itemType = pe.item !== undefined
      }
    })

    this.setState({
      tabDataLoading: false,
      currentOp: currentOp,
      entries: entries,
      rowCols: [
        ...(currentOp?.type === MovementType.PACKING ? PRODUCT_ROW_COL_PACKING : PRODUCT_ROW_COL),
        {
          key: 'action',
          className: 'stw-extra-small',
          fixedType: 'right',
          sortable: false,
          unmanageable: true,
          render: (text, record) => (
            <>
              {record.itemType && (
                <AdvancedTableRowColumn
                  actions={[
                    {
                      content: <EyeOutlined />,
                      type: 'default',
                      onclick: () => this.showDetail(record),
                    },
                  ]}
                />
              )}
            </>
          ),
        },
      ],
    })
  }

  render() {
    const {
      record,
      fragment,
      loader,
      tabs,
      tabSelected,
      entries,
      currentOp,
      rowCols,
      attributeFields,
      tableValueConfig,
      movementLoaded,
      movementLoading,
      isEmptyEntries,
      tabDataLoading,
    } = this.state
    const { breadcrumbs, queryString } = this.props
    return (
      <StylewherePage {...this.props} noOverflow fragment={fragment}>
        <DefaultHeader
          backPath={getBackURL(queryString, breadcrumbs)}
          multipleTitle={getMultipleTitle(record)}
          title={__(T.misc.not_found)}
          skeleton={{ active: loader, options: { multiple: true } }}
        />
        <Section customClass={`stw-section-page paged-header ${record || loader ? 'scroll transparent' : ''}`}>
          {record || loader ? (
            <>
              <GridData fields={PARCEL_CELLS} record={loader ? [] : record} skeleton={loader} />
              {canRenderAttributes(record, attributeFields, loader) && (
                <GridData
                  title={__(T.misc.attributes_title)}
                  fields={loader ? SkeltonAttribute : attributeFields}
                  record={loader || !record ? [] : record.attributes}
                  skeleton={loader}
                />
              )}

              {record && (
                <TableList
                  title={__(T.report.parcelEntries)}
                  titleEmpty={__(T.report.emptyParcelEntries)}
                  location={this.props.location}
                  headerType="boxed"
                  config={tableValueConfig}
                  isEmptyContentCallback={this.setEmtpyContent}
                  resource={{
                    call: Parcels.searchTableList,
                    endpoint: `${Parcels.endpoint}/${record.id}/parcelEntries`,
                  }}
                />
              )}

              {!isEmptyEntries && (
                <Section>
                  {!movementLoading && !movementLoaded && (
                    <Button onClick={this.initMovement} className="stylewhere-button-primary">
                      {__(T.report.parcelViewHistory)}
                    </Button>
                  )}

                  {movementLoading && (
                    <Row style={{ alignItems: 'center' }}>
                      <LoadingOutlined style={{ fontSize: 24 }} />
                      <Text style={{ fontSize: 14, marginLeft: 10, lineHeight: '40px' }}>{__(T.misc.loadingDate)}</Text>
                    </Row>
                  )}

                  {movementLoaded && tabs.length === 0 && (
                    <Text strong style={{ flex: 1, fontSize: 20, color: '#000' }}>
                      {__(T.report.parcelEmptyHistory)}
                    </Text>
                  )}

                  {movementLoaded && tabs.length > 0 && (
                    <>
                      <TabBar
                        tabs={tabs}
                        selected={tabSelected}
                        onChangeTab={(value) => {
                          this.onChangeTab(value)
                        }}
                        autoselect
                        returnObject
                      />
                      {tabDataLoading && (
                        <Row style={{ alignItems: 'center' }}>
                          <LoadingOutlined style={{ fontSize: 24 }} />
                          <Text style={{ fontSize: 14, marginLeft: 10, lineHeight: '40px' }}>
                            {__(T.misc.loadingDate)}
                          </Text>
                        </Row>
                      )}

                      {!tabDataLoading && currentOp && (
                        <>
                          <KeyValueGrid fields={MOVEMENT_FIELD} data={currentOp} />
                          {currentOp && currentOp.type !== 'PACKING' && (
                            <GridCards
                              mTop={20}
                              cards={getCards(
                                (currentOp as StwFullOutbound | StwFullInbound).totalDetected,
                                (currentOp as StwFullOutbound | StwFullInbound).totalDetected,
                                (currentOp as StwFullOutbound | StwFullInbound).totalExpected,
                                (currentOp as StwFullOutbound | StwFullInbound).totalUnexpected
                              )}
                            />
                          )}
                          <AdvancedTable
                            loader={loader}
                            tableTitle={{
                              title: __('fields.labels.products'),
                              emptyTitle: __(T.parcel.noParcelContentFoundTitle),
                            }}
                            pagination={{
                              pageSize: entries!.length,
                              pageNumber: 1,
                              sortValues: { creationDate: AdvancedTableSortDirection.desc },
                            }}
                            columns={rowCols}
                            filters={{
                              filterDefinitions: [],
                            }}
                            content={[...entries]}
                            totalElements={entries.length}
                            disabledNoDataFound
                            disabledPagination
                            activeDefaultClassColumn
                          />
                        </>
                      )}
                    </>
                  )}
                </Section>
              )}
            </>
          ) : (
            <NotFound fullheight title={__(T.misc.noRecordFoundTitle)} />
          )}
        </Section>
      </StylewherePage>
    )
  }
}

export default ParcelDetail
