import { Component } from 'react'
import { Card, notification, Skeleton } from 'antd'
import Copy from '../../icon/copy.png'
import { __ } from '../shared/i18n'

interface Props {
  title: string
  value: string
  skeleton?: boolean
  type?: string
}

export class KeyValueCell extends Component<Props> {
  copyText = () => {
    const { value } = this.props
    if (navigator && navigator.clipboard) {
      navigator.clipboard.writeText(value)

      notification.success({
        message: __('misc.copyToClipboard'),
        placement: 'bottomRight',
        duration: 3,
      })
    }
  }

  render() {
    const { title, value, skeleton, type } = this.props
    return (
      <Card
        onClick={skeleton || type === 'link' ? () => {} : this.copyText}
        className="detail-cell"
        title={skeleton ? null : title}
      >
        {skeleton ? (
          <>
            <Skeleton className="stw-skeleton-base" title={false} round active paragraph={{ rows: 1, width: '60%' }} />
            <Skeleton className="stw-skeleton-base" title={false} round active paragraph={{ rows: 1, width: '80%' }} />
          </>
        ) : (
          <p style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{value}</p>
        )}
        {!skeleton && type !== 'link' && <div className="copy" style={{ backgroundImage: `url(${Copy})` }} />}
      </Card>
    )
  }
}
