import {
  AdvancedTableColumn,
  AdvancedTableContentKind,
  AdvancedTableFilterKind,
  AdvancedTableFiltersDef,
} from '../../components/AdvancedTable'
import {
  AdvancedFormAttributeType,
  AdvancedFormField,
  AdvancedFormFieldBuilder,
  AdvancedFormInputType,
} from '../../components/AdvancedForm/advancedFormTypes'
import { __ } from '../../shared/i18n'
import { AdvancedTableRowColumn } from '../../components'
import { DetailCell } from '../../api'
import { CAPABILITIES, getActionsTableColumns } from '../utility/capabilityUtility'

export const PLACE_FILTERS: AdvancedTableFiltersDef = [
  {
    key: 'equalCodes',
    label: __('fields.labels.code'),
    col: 8,
    type: AdvancedTableFilterKind.STRING_LIST,
    column: 'code',
  },
  {
    key: 'likeExternalCode',
    label: __('fields.labels.externalCode'),
    col: 8,
    type: AdvancedTableFilterKind.STRING_LIST,
    column: 'externalCode',
  },
  {
    key: 'description',
    label: __('fields.labels.description'),
    type: AdvancedTableFilterKind.STRING,
    column: 'description',
    col: 8,
  },
  {
    key: 'address',
    label: __('fields.labels.address'),
    type: AdvancedTableFilterKind.STRING,
    column: 'address',
    col: 8,
  },
  {
    key: 'type',
    label: __('fields.labels.type'),
    type: AdvancedTableFilterKind.ENUM,
    column: 'type',
    select: {
      option: 'types',
    },
    col: 8,
  },
  {
    key: 'likeCountryCodes',
    label: __('fields.labels.countryCode'),
    col: 8,
    type: AdvancedTableFilterKind.STRING_LIST,
    column: 'countryCode',
  },
  {
    key: 'labels',
    label: __('fields.labels.labels'),
    type: AdvancedTableFilterKind.STRING,
    column: 'labels',
    col: 8,
  },
  {
    key: 'creationDate',
    label: __('fields.labels.creationDate'),
    type: AdvancedTableFilterKind.DATE_RANGE,
    column: 'creationDate',
    col: 8,
  },
  {
    key: 'lastModifyDate',
    label: __('fields.labels.lastModifyDate'),
    type: AdvancedTableFilterKind.DATE_RANGE,
    column: 'lastModifyDate',
    col: 8,
  },
]

export const PLACE_TYPES = [
  {
    id: 'SUPPLIER',
    description: 'SUPPLIER',
  },
  {
    id: 'WAREHOUSE',
    description: 'WAREHOUSE',
  },
  {
    id: 'STORE',
    description: 'STORE',
  },
  {
    id: 'CUSTOMER',
    description: 'CUSTOMER',
  },
]

export const PLACE_FIELDS: AdvancedFormField[] = [
  AdvancedFormFieldBuilder.buildText('description'),
  {
    key: 'code',
    type: AdvancedFormInputType.UNIQUE_TEXT,
    label: __('fields.labels.code'),
    placeholder: __('fields.placeholders.code'),
    rules: [{ required: true }],
    unique: {
      endpoint: '/api/v1/places',
      parameter: 'equalCodes',
    },
  },
  AdvancedFormFieldBuilder.buildSelect('type', 'types', [{ required: true }]),
  AdvancedFormFieldBuilder.buildText('externalCode'),
  AdvancedFormFieldBuilder.buildText('address', undefined, 8, undefined, undefined, undefined, false, true),
  AdvancedFormFieldBuilder.buildText('countryCode', undefined, 4, undefined, undefined, undefined, false, false, 16),
  {
    label: __(`fields.labels.labels`),
    key: 'labels',
    type: AdvancedFormInputType.STRING_LIST,
    placeholder: __(`fields.placeholders.labels`),
  },
  AdvancedFormFieldBuilder.buildText('latitude', undefined, 6, undefined, undefined, undefined, false, true),
  AdvancedFormFieldBuilder.buildText('longitude', undefined, 6, undefined, undefined, undefined, false, false, 12),
  AdvancedFormFieldBuilder.buildAttribute('attributes', AdvancedFormAttributeType.SIMPLE, false, 'place'),
]

export const PLACE_COLUMNS: Array<AdvancedTableColumn> = [
  {
    title: __(`fields.labels.code`),
    key: 'code',
    dataIndex: 'code',
    fixedType: 'left',
    className: 'stw-medium',
  },
  {
    title: __(`fields.labels.externalCode`),
    key: 'externalCode',
    dataIndex: 'externalCode',
    className: 'stw-small',
  },
  {
    title: __(`fields.labels.description`),
    key: 'description',
    dataIndex: 'description',
    className: 'stw-large',
  },
  {
    title: __(`fields.labels.type`),
    key: 'type',
    dataIndex: 'type',
    className: 'stw-small',
  },
  {
    title: __(`fields.labels.address`),
    key: 'address',
    dataIndex: 'address',
    className: 'stw-extra-medium',
  },
  {
    title: __(`fields.labels.countryCode`),
    key: 'countryCode',
    dataIndex: 'countryCode',
  },
  {
    title: __(`fields.labels.labels`),
    key: 'labels',
    dataIndex: 'labels',
    type: AdvancedTableContentKind.MULTIPLE_STRING,
    sortable: false,
    width: 'medium',
  },
  {
    title: __(`fields.labels.latitude`),
    key: 'latitude',
    dataIndex: 'latitude',
    type: AdvancedTableContentKind.NUMBER,
    className: 'stw-small',
  },
  {
    title: __(`fields.labels.longitude`),
    key: 'longitude',
    dataIndex: 'longitude',
    type: AdvancedTableContentKind.NUMBER,
    className: 'stw-small',
  },
  {
    key: 'creationDate',
    title: __(`fields.labels.creationDate`),
    dataIndex: 'creationDate',
    type: AdvancedTableContentKind.DATE_TIME,
  },
  {
    key: 'lastModifyDate',
    title: __(`fields.labels.lastModifyDate`),
    dataIndex: 'lastModifyDate',
    type: AdvancedTableContentKind.DATE_TIME,
  },
  {
    key: 'action',
    width: 'small',
    fixedType: 'right',
    sortable: false,
    unmanageable: true,
    className: 'stw-small',
    render: (text, record) => (
      <AdvancedTableRowColumn
        actions={getActionsTableColumns(record.id, '/configuration/places', CAPABILITIES.RESOURCE_PLACE_EDIT)}
      />
    ),
  },
]

export const PLACE_DETAIL: DetailCell[] = [
  { title: 'id', attribute: 'id', columns: 24 },
  { title: __(`fields.labels.code`), attribute: 'code', columns: 12 },
  { title: __(`fields.labels.description`), attribute: 'description', columns: 12 },
  { title: __(`fields.labels.type`), attribute: 'type', columns: 12 },
  { title: __(`fields.labels.address`), attribute: 'address', columns: 12 },
  { title: __(`fields.labels.countryCode`), attribute: 'countryCode', columns: 12 },
  { title: __(`fields.labels.labels`), attribute: 'labels', columns: 12, type: 'stringList' },
  { title: __(`fields.labels.latitude`), attribute: 'latitude', columns: 12 },
  { title: __(`fields.labels.longitude`), attribute: 'longitude', columns: 12 },
  { title: __(`fields.labels.creationDate`), attribute: 'creationDate', type: 'date', columns: 12 },
  { title: __(`fields.labels.lastModifyDate`), attribute: 'lastModifyDate', type: 'date', columns: 12 },
]
