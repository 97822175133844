import { Component } from 'react'
import { Modal, Typography, Row, Col, Button } from 'antd'
import dayjs from 'dayjs'
import { __, T } from '../../shared/i18n'
import Logo from '../../assets/img/logo-extended.png'

interface Props {
  visible: boolean
  close: any
}

const { Text } = Typography

export class ModalApplicationInfo extends Component<Props> {
  render() {
    const { visible, close } = this.props
    return (
      <Modal centered open={visible} closable={false} footer={null} onCancel={() => close()}>
        <Row className="modal-user-row">
          <div className="logo" style={{ backgroundImage: `url(${Logo})` }} />
        </Row>
        <Row className="modal-user-row application">
          <Col span={24}>
            <Text strong>{__(T.misc.version)}</Text>
            <Text>{process.env.REACT_APP_VERSION || '5.0.1-dev'}</Text>
          </Col>
        </Row>
        <Row className="modal-user-row application">
          <Col span={24}>
            <Text strong>{__(T.misc.buildDate)}</Text>
            <Text>{dayjs(process.env.REACT_APP_BUILD || '2022-02-08T10:00:00').format('MMM DD, YYYY HH:mm')}</Text>
          </Col>
        </Row>
        <Row className="modal-user-row application">
          <Col span={24}>
            <Text>{__(T.misc.copyright)} &copy; 2018 - 2022 Temera Srl</Text>
            <Text>{__(T.misc.rightReserved)}</Text>
          </Col>
        </Row>
        <Row className="modal-user-row with-button">
          <Button className="stylewhere-button-default" onClick={() => close()}>
            {__(T.misc.close)}
          </Button>
        </Row>
      </Modal>
    )
  }
}
