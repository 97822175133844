import { Component } from 'react'
import { ExtendRouteComponentProps } from '../../../types'
import { __, T } from '../../../shared/i18n'
import { navigate } from '../../../shared/router'
import { AdvancedRfidReaderForm, StylewherePage, DefaultHeader, Section } from '../../../components'
import { getBackURL } from '../../../shared/utils'

interface State {
  readerId: string
  loader: boolean
  formName: string
}

class RfidReaderForm extends Component<ExtendRouteComponentProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      readerId: '',
      loader: true,
      formName: '',
    }
  }

  componentDidMount = async () => {
    let readerId
    if (this.props.match && this.props.match.params.rfidReaderId !== 'create') {
      readerId = this.props.match.params.rfidReaderId
    }
    this.setState({
      readerId: readerId,
      loader: false,
    })
  }

  setFormName = (name: string) => {
    this.setState({ formName: name })
  }

  render() {
    const { readerId, formName, loader } = this.state
    const { breadcrumbs, queryString } = this.props
    const title = readerId ? __(T.rfidReaders.edit) : __(T.rfidReaders.create_new)
    const fragment = !loader
      ? [
          {
            label: readerId || __(T.rfidReaders.create_new),
            active: true,
          },
        ]
      : undefined
    return (
      <StylewherePage {...this.props} noOverflow fragment={fragment}>
        <DefaultHeader
          backPath={getBackURL(queryString, breadcrumbs)}
          title={loader ? '...' : title}
          skeleton={{ active: loader }}
          actions={[
            {
              label: __(T.misc.cancel),
              type: 'cancel',
              onClick: () => navigate(`/devices/rfidReaders${queryString ?? ''}`),
            },
            {
              label: readerId ? __(T.misc.update) : __(T.misc.create),
              type: 'submit',
              formName: formName,
            },
          ]}
        />
        <Section customClass="stw-section-page paged-header scroll">
          <AdvancedRfidReaderForm
            formWidth="100%"
            readerId={readerId}
            setFormName={this.setFormName}
            onSave={() => navigate(`/devices/rfidReaders${queryString ?? ''}`)}
          />
        </Section>
      </StylewherePage>
    )
  }
}

export default RfidReaderForm
