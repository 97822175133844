import {
  AdvancedTableColumn,
  AdvancedTableContentKind,
  AdvancedTableFilterKind,
  AdvancedTableFiltersDef,
} from '../../../components/AdvancedTable'
import {
  AdvancedFormField,
  AdvancedFormFieldBuilder,
  AdvancedFormInputType,
} from '../../../components/AdvancedForm/advancedFormTypes'
import { __ } from '../../../shared/i18n'
import { DetailCell } from '../../../api'

export const BARCODE_READERS_FILTERS: AdvancedTableFiltersDef = [
  {
    key: 'equalCodes',
    label: __('fields.labels.code'),
    type: AdvancedTableFilterKind.STRING_LIST,
    column: 'code',
    col: 8,
  },
  {
    key: 'description',
    label: __('fields.labels.description'),
    type: AdvancedTableFilterKind.STRING,
    column: 'description',
    col: 8,
  },
  {
    key: 'deviceType',
    label: __('fields.labels.deviceType'),
    type: AdvancedTableFilterKind.ENUM,
    column: 'deviceType',
    select: {
      option: 'barcodeReaderType',
    },
    col: 8,
  },
  {
    key: 'placeIds',
    label: __('fields.labels.place'),
    type: AdvancedTableFilterKind.AUTOCOMPLETE,
    column: 'place',
    col: 8,
    autocomplete: {
      endpoint: '/api/v1/places',
      sort: 'code,asc',
      multiple: true,
    },
  },
  {
    key: 'creationDate',
    label: __('fields.labels.creationDate'),
    type: AdvancedTableFilterKind.DATE_RANGE,
    column: 'creationDate',
    col: 8,
  },
  {
    key: 'lastModifyDate',
    label: __('fields.labels.lastModifyDate'),
    type: AdvancedTableFilterKind.DATE_RANGE,
    column: 'lastModifyDate',
    col: 8,
  },
]

export const BARCODE_READERS_FIELDS: AdvancedFormField[] = [
  AdvancedFormFieldBuilder.buildAutocomplete(
    'place',
    '/api/v1/places',
    'code,asc',
    undefined,
    undefined,
    [{ required: true }],
    __('fields.labels.place'),
    __('fields.placeholders.place'),
    false
  ),
  {
    key: 'code',
    type: AdvancedFormInputType.UNIQUE_TEXT,
    label: __('fields.labels.code'),
    placeholder: __('fields.placeholders.code'),
    rules: [{ required: true }],
    unique: {
      endpoint: '/api/v1/barcodeReaders',
      parameter: 'equalCodes',
    },
  },
  AdvancedFormFieldBuilder.buildText('description'),
  AdvancedFormFieldBuilder.buildSelect('deviceType', 'barcodeReaderType', [{ required: true }]),
  AdvancedFormFieldBuilder.buildText('ipAddress', [{ required: true }]),
  {
    key: 'dataSocketPort',
    type: AdvancedFormInputType.NUMBER,
    ruleParameter: 'dataSocketPortRules',
  },
  AdvancedFormFieldBuilder.buildNumber('maxConnectionTimeout'),
  AdvancedFormFieldBuilder.buildSwitch('keepAliveEnabled'),
]

export const BARCODE_READERS_KEEP_ALIVE_FIELDS: AdvancedFormField[] = [
  AdvancedFormFieldBuilder.buildNumber(
    'keepAlivePeriod',
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    'keepAliveRules'
  ),
  AdvancedFormFieldBuilder.buildNumber(
    'keepAliveSocketPort',
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    'keepAliveRules'
  ),
]

export const BARCODE_READERS_COLUMNS: AdvancedTableColumn[] = [
  {
    key: 'code',
    dataIndex: 'code',
    title: __('fields.labels.code'),
  },
  {
    key: 'description',
    dataIndex: 'description',
    title: __('fields.labels.description'),
  },
  {
    key: 'deviceType',
    dataIndex: 'deviceType',
    title: __('fields.labels.deviceType'),
    type: AdvancedTableContentKind.STRING,
  },
  {
    key: 'place',
    dataIndex: 'place',
    title: __('fields.labels.place'),
    type: AdvancedTableContentKind.CODE_DESC,
  },
  {
    key: 'ipAddress',
    dataIndex: 'ipAddress',
    title: __('fields.labels.ipAddress'),
    type: AdvancedTableContentKind.STRING,
  },
  {
    key: 'creationDate',
    dataIndex: 'creationDate',
    title: __('fields.labels.creationDate'),
    type: AdvancedTableContentKind.DATE_TIME,
  },
  {
    key: 'lastModifyDate',
    dataIndex: 'lastModifyDate',
    title: __('fields.labels.lastModifyDate'),
    type: AdvancedTableContentKind.DATE_TIME,
  },
]

export const BARCODE_READERS_DATA: DetailCell[] = [
  { title: __('fields.labels.id'), attribute: 'id', columns: 24 },
  { title: __('fields.labels.code'), attribute: 'code', columns: 12 },
  { title: __('fields.labels.description'), attribute: 'description', columns: 12 },
  { title: __('fields.labels.place'), attribute: 'place', columns: 12, type: 'codeDesc' },
  { title: __('fields.labels.deviceType'), attribute: 'deviceType', columns: 12 },
  { title: __('fields.labels.ipAddress'), attribute: 'ipAddress', columns: 12 },
  { title: __('fields.labels.dataSocketPort'), attribute: 'dataSocketPort', columns: 12, type: 'number' },
  { title: __('fields.labels.maxConnectionTimeout'), attribute: 'maxConnectionTimeout', columns: 12, type: 'number' },
  { title: __('fields.labels.keepAliveEnabled'), attribute: 'keepAliveEnabled', columns: 12, type: 'boolean' },
  { title: __('fields.labels.keepAlivePeriod'), attribute: 'keepAlivePeriod', columns: 12, type: 'number' },
  { title: __('fields.labels.keepAliveSocketPort'), attribute: 'keepAliveSocketPort', columns: 12, type: 'number' },
  { title: __('fields.labels.creationDate'), attribute: 'creationDate', type: 'date', columns: 12 },
  { title: __('fields.labels.lastModifyDate'), attribute: 'lastModifyDate', type: 'date', columns: 12 },
]
