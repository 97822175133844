import { Component } from 'react'
import { GridData, StylewherePage, DefaultHeader, NotFound, Section, TableList } from '../../../components'
import { DetailCell, StwPallet } from '../../../api'
import Pallets from '../../../api/Pallets'
import { __, T } from '../../../shared/i18n'
import { AdvancedTableConfig, AdvancedTableColumn, AdvancedTableSortDirection } from '../../../components/AdvancedTable'
import { PALLET_DATA } from '../../../config/Pages/Reports'
import { ExtendRouteComponentProps } from '../../../types'
import {
  getFragmentObject,
  getMultipleTitle,
  getBackURL,
  canRenderAttributes,
  getTableActionsReport,
} from '../../../shared/utils'
import { SkeltonAttribute, DEFAULT_PAGE_SIZE } from '../../../constants'
import Reports from '../../../api/Reports'

interface State {
  record: StwPallet | undefined
  attributeFields: DetailCell[]
  loader: boolean
  parcelsTableConfig: AdvancedTableConfig
}

class ReportPalletDetail extends Component<ExtendRouteComponentProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      record: undefined,
      attributeFields: [],
      loader: true,
      parcelsTableConfig: {
        filterDefinitions: [],
      },
    }
  }

  componentDidMount() {
    this.initialize()
  }

  initialize = async () => {
    const { match } = this.props
    let recordId = ''
    let record
    let parcelsConfig: AdvancedTableConfig = { filterDefinitions: [] }
    const attributeConfiguration: DetailCell[] = []
    if (match) {
      recordId = match.params.recordId
      record = await Pallets.get<StwPallet>(recordId).then((res) => res)
      if (record && record.attributes) {
        for (const attributesKey in record.attributes) {
          if (Object.prototype.hasOwnProperty.call(record.attributes, attributesKey)) {
            attributeConfiguration.push({ title: attributesKey, attribute: attributesKey, columns: 8 })
          }
        }
      }
      const report: any = await Reports.metadata('parcel')
      if (report && !report.errorId) {
        parcelsConfig = {
          columns: this.getTableColumns(report),
          filterDefinitions: [],
          filterParameters: {},
          activeDefaultClassColumn: true,
          fixedTableHeight: true,
          disableLocation: true,
          disableColumnFilters: true,
          disabledNoDataFound: true,
          skeletonOptions: {},
          hiddenEmptyTable: true,
        }
      }
    }
    this.setState({
      loader: false,
      record: record,
      attributeFields: attributeConfiguration,
      parcelsTableConfig: parcelsConfig,
    })
  }

  getBasePath = () => {
    const { breadcrumbs } = this.props
    if (breadcrumbs) return breadcrumbs.fragments[breadcrumbs.fragments.length - 1].path
    return ''
  }

  getTableColumns = (reportMetadata): AdvancedTableColumn[] => {
    const basePath = this.getBasePath()
    const reportActions = getTableActionsReport('parcel')
    const tableColumns: AdvancedTableColumn[] = reportMetadata.columns.map((column) => ({
      key: column.parameter,
      title: column.description,
      dataIndex: column.parameter,
      type: column.type,
      hidden: column.hidden || column.technicalField,
      sortable: !column.unsortable,
      unmanageable: column.technicalField,
    }))
    if (reportActions.active) {
      tableColumns.push({
        title: '',
        key: 'action',
        className: reportActions.customClass || 'stw-extra-small',
        fixedType: 'right',
        unmanageable: true,
        sortable: false,
        reportActions: reportActions,
        basePath: basePath,
      })
    }
    return tableColumns
  }

  render() {
    const { record, loader, attributeFields, parcelsTableConfig } = this.state
    const { breadcrumbs, queryString } = this.props
    const fragment = getFragmentObject(record, 'id')
    return (
      <StylewherePage {...this.props} noOverflow fragment={fragment}>
        <DefaultHeader
          backPath={getBackURL(queryString, breadcrumbs)}
          multipleTitle={getMultipleTitle(record)}
          title={__(T.misc.not_found)}
          skeleton={{ active: loader, options: { multiple: true } }}
        />
        <Section customClass={`stw-section-page paged-header ${record || loader ? 'scroll transparent' : ''}`}>
          {record || loader ? (
            <>
              <GridData fields={PALLET_DATA} record={loader ? [] : record} skeleton={loader} />
              {canRenderAttributes(record, attributeFields, loader) && (
                <GridData
                  title={__(T.misc.attributes_title)}
                  fields={loader ? SkeltonAttribute : attributeFields}
                  record={loader || !record ? [] : record.attributes}
                  skeleton={loader}
                />
              )}
              {record && parcelsTableConfig.columns && (
                <TableList
                  title={__('fields.labels.parcels')}
                  titleEmpty={__(T.pallet.noParcelsFoundTitle)}
                  location={this.props.location}
                  defaultPagination={{
                    pageNumber: 1,
                    pageSize: DEFAULT_PAGE_SIZE,
                    sortValues: { code: AdvancedTableSortDirection.asc },
                  }}
                  config={parcelsTableConfig}
                  resource={{
                    call: Reports.reportData,
                    endpoint: `${Reports.endpoint}/parcel`,
                    filters: { palletCode: record.code },
                  }}
                  reportId="parcel"
                  enabledReportExport={false}
                  headerType="boxed"
                />
              )}
            </>
          ) : (
            <NotFound fullheight title={__(T.misc.noRecordFoundTitle)} />
          )}
        </Section>
      </StylewherePage>
    )
  }
}

export default ReportPalletDetail
