import {
  AdvancedTableColumn,
  AdvancedTableContentKind,
  AdvancedTableFilterKind,
  AdvancedTableFiltersDef,
} from '../../../components/AdvancedTable'
import {
  AdvancedFormField,
  AdvancedFormFieldBuilder,
  AdvancedFormAttributeType,
} from '../../../components/AdvancedForm/advancedFormTypes'
import { __ } from '../../../shared/i18n'
import { DetailCell } from '../../../api'
import { CAPABILITIES } from '../../utility/capabilityUtility'

export const CONVEYOR_FILTERS: AdvancedTableFiltersDef = [
  {
    key: 'externalId',
    label: __('fields.labels.externalId'),
    type: AdvancedTableFilterKind.STRING,
    column: 'externalId',
    col: 8,
  },
  {
    key: 'description',
    label: __('fields.labels.description'),
    type: AdvancedTableFilterKind.STRING,
    column: 'description',
    col: 8,
  },
  {
    key: 'zoneIds',
    label: __('fields.labels.zone'),
    type: AdvancedTableFilterKind.AUTOCOMPLETE,
    column: 'zone',
    autocomplete: {
      endpoint: '/api/v1/zones',
      sort: 'code,asc',
      multiple: true,
    },
    col: 8,
  },
  {
    key: 'operationIds',
    label: __('fields.labels.operation'),
    type: AdvancedTableFilterKind.AUTOCOMPLETE,
    column: 'operation',
    option: 'operationTypeFilters',
    autocomplete: {
      endpoint: '/api/v1/operations',
      sort: 'code,asc',
      multiple: true,
    },
    col: 8,
  },
  {
    key: 'userIds',
    label: __('fields.labels.user'),
    type: AdvancedTableFilterKind.AUTOCOMPLETE,
    column: 'user.username',
    autocomplete: {
      endpoint: '/api/v1/users',
      sort: 'id,asc',
      multiple: false,
    },
    col: 8,
  },
  {
    key: 'creationDate',
    label: __('fields.labels.creationDate'),
    type: AdvancedTableFilterKind.DATE_RANGE,
    column: 'creationDate',
    col: 8,
  },
  {
    key: 'lastModifyDate',
    label: __('fields.labels.lastModifyDate'),
    type: AdvancedTableFilterKind.DATE_RANGE,
    column: 'lastModifyDate',
    col: 8,
  },
]

export const CONVEYOR_FIELDS: AdvancedFormField[] = [
  AdvancedFormFieldBuilder.buildText('externalId', [{ required: true }]),
  AdvancedFormFieldBuilder.buildText('description'),
  AdvancedFormFieldBuilder.buildAutocomplete(
    'zone',
    '/api/v1/zones',
    'code,asc',
    undefined,
    undefined,
    [{ required: true }],
    __('fields.labels.zone'),
    __('fields.placeholders.zone'),
    false,
    false,
    false,
    undefined
  ),
  AdvancedFormFieldBuilder.buildAutocomplete(
    'user',
    '/api/v1/users',
    'username,asc',
    undefined,
    undefined,
    [{ required: true }],
    __('fields.labels.user'),
    __('fields.placeholders.user'),
    false,
    false,
    false,
    undefined
  ),
  AdvancedFormFieldBuilder.buildAutocomplete(
    'operation',
    '/api/v1/operations',
    'code,asc',
    undefined,
    undefined,
    [{ required: true }],
    __('fields.labels.operation'),
    __('fields.placeholders.operation'),
    false,
    false,
    false,
    undefined,
    'operationTypeFilters'
  ),
  AdvancedFormFieldBuilder.buildAttribute('settings', AdvancedFormAttributeType.SIMPLE, true),
  AdvancedFormFieldBuilder.buildAttribute('attributes', AdvancedFormAttributeType.SIMPLE, true),
]

export const CONVEYOR_COLUMNS: AdvancedTableColumn[] = [
  {
    key: 'externalId',
    dataIndex: 'externalId',
    title: __('fields.labels.externalId'),
    type: AdvancedTableContentKind.STRING,
  },
  {
    key: 'description',
    dataIndex: 'description',
    title: __('fields.labels.description'),
    type: AdvancedTableContentKind.STRING,
  },
  {
    key: 'zone',
    dataIndex: 'zone',
    title: __('fields.labels.zone'),
    type: AdvancedTableContentKind.CODE_DESC,
  },
  {
    key: 'operation',
    dataIndex: 'operation',
    title: __('fields.labels.operation'),
    type: AdvancedTableContentKind.CODE_DESC,
    className: 'stw-extra-large',
  },
  {
    key: 'user.username',
    dataIndex: 'user',
    title: __('fields.labels.user'),
  },
  {
    key: 'creationDate',
    dataIndex: 'creationDate',
    title: __('fields.labels.creationDate'),
    type: AdvancedTableContentKind.DATE_TIME,
  },
  {
    key: 'lastModifyDate',
    dataIndex: 'lastModifyDate',
    title: __('fields.labels.lastModifyDate'),
    type: AdvancedTableContentKind.DATE_TIME,
  },
  {
    key: 'action',
    className: 'stw-small',
    fixedType: 'right',
    unmanageable: true,
    hidden: false,
    sortable: false,
    basePath: '/devices/conveyors',
    reportActions: {
      active: true,
      onlyedit: false,
      editCapabilites: CAPABILITIES.RESOURCE_CONVEYOR_EDIT,
      canDelete: true,
      customClass: 'stw-small-medium',
    },
  },
]

export const CONVEYOR_DATA: DetailCell[] = [
  { title: __('fields.labels.externalId'), attribute: 'externalId', columns: 12 },
  { title: __('fields.labels.description'), attribute: 'description', columns: 12 },
  { title: __('fields.labels.zone'), attribute: 'zone', columns: 12, type: 'codeDesc' },
  { title: __('fields.labels.operation'), attribute: 'operation', columns: 12, type: 'codeDesc' },
  { title: __('fields.labels.user'), attribute: 'user', columns: 12, type: 'user' },
  { title: __('fields.labels.creationDate'), attribute: 'creationDate', type: 'date', columns: 12 },
  { title: __('fields.labels.lastModifyDate'), attribute: 'lastModifyDate', type: 'date', columns: 12 },
]
