import { ConfigProvider, Layout, Modal, notification } from 'antd'
import 'antd/dist/reset.css'
import dayjs from 'dayjs'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import { ModalNotificationDetail, ModalSearch, Sidebar, ErrorPage } from '.'
import { ModalType, StwNotification, StwOperation } from '../api'
import { MAX_PAGE_SIZE } from '../api/BaseResource'
import Notifications from '../api/Notifications'
import Operations from '../api/Operations'
import Reports from '../api/Reports'
import { BaseMenu } from '../config/Menu'
import { Routes } from '../pages'
import { keycloak, Router as StyleWhereRouter } from '../shared'
import AppStore from '../shared/AppStore'
import { __, T } from '../shared/i18n'
import { checkSelectedMenu, getCurrentPath, getSelectedMenu } from '../shared/utils'
import { MenuEntryType, SubmenuEntryType } from '../types'
import { ModalHeader } from './Modal/ModalHeader'
import { BreadcrumbsType } from './PageBread'
import { getReportListCapability } from '../config/utility/capabilityUtility'

const App: React.FC = () => {
  const [authenticated, setauthenticated] = useState<boolean>(true)
  const [loading, setloading] = useState<boolean>(true)
  const [withRoles, setWithRoles] = useState<boolean>(false)
  const [menu, setmenu] = useState<MenuEntryType[]>([])
  const [menuSelected, setmenuSelected] = useState<string>('')
  const [numberUpdateMenu, setnumberUpdateMenu] = useState<number>(0)
  const [submenuSelected, setsubmenuSelected] = useState<SubmenuEntryType | undefined>(undefined)
  const [breadcrumbs, setbreadcrumbs] = useState<BreadcrumbsType | undefined>(undefined)
  const [queryString, setQueryString] = useState<string>('')
  const [notificationsList, setNotificationsList] = useState<StwNotification[]>([])
  const [lastNotificationsList, setLastNotificationsList] = useState<StwNotification[]>([])
  const [notificationObject, setNotificationObject] = useState<any>(undefined)
  const [notificationInterval, setNotificationInterval] = useState<NodeJS.Timeout | undefined>(undefined)
  const [visibleNotificationDetail, setVisibleNotificationDetail] = useState<boolean>(false)
  const [visibleSearch, setVisibleSearch] = useState<boolean>(false)
  const [modals, setmodals] = useState<ModalType[]>([])
  const sessionExpiredTimeout = useRef<NodeJS.Timeout | null>(null)
  const keycloakInitialized = useRef(false)

  const paths = Object.keys(Routes)
  const activeRoutes = paths.filter((path) =>
    authenticated
      ? (!Routes[path].public || Routes[path].private) && AppStore.hasAnyCapabilities(Routes[path].capabilities)
      : Routes[path].public
  )
  const appStoreLoaded = useRef(false)
  const listenerAttach = useRef(false)

  useHotkeys(
    'mod+k',
    () => {
      setVisibleSearch(true)
    },
    {
      preventDefault: (e) => {
        e.preventDefault()
        return true
      },
    }
  )

  const onError = () => {
    sessionExpiredTimeout.current = setTimeout(() => {
      setauthenticated(false)
      setloading(false)
      setmenu([])
      setmenuSelected('')
      setQueryString('')
      setsubmenuSelected(undefined)
      setbreadcrumbs(undefined)
    }, 5000)
  }

  const populateNotificationsSubmenu = useCallback(async (notificationElement) => {
    const getNotificationsUnread = async () => {
      try {
        const results: number = await Notifications.get('countUnread')
        return results
      } catch (e) {
        return 0
      }
    }

    const index = getSubmenuIndex('notifications')
    const list: SubmenuEntryType[] = Notifications.getNotificationMenu(notificationElement)
    const unreadCount: number = await getNotificationsUnread()
    return {
      unread: unreadCount || 0,
      index: index,
      entries: list || [],
    }
  }, [])

  const updateNotification = useCallback(
    async (resetMenu = false) => {
      const results = await getNotifications()
      setNotificationsList(results.content)
      if (resetMenu) {
        const notifications = await populateNotificationsSubmenu(results.content)
        if (notifications.index >= 0) {
          menu[notifications.index].submenus = notifications.entries
          menu[notifications.index].counter = notifications.unread > 0 ? notifications.unread : -1
          menu[notifications.index].hidden = notifications.entries.length === 0
          setmenu(menu)
        }
      }
    },
    [menu, populateNotificationsSubmenu]
  )

  useEffect(() => {
    const refreshNotifications = () => {
      if (!notificationInterval) {
        const intervalReference = setInterval(() => {
          updateNotification()
        }, 30000)
        setNotificationInterval(intervalReference)
      }
    }

    const onAuthSuccess = async () => {
      const getMenu = async (logged, notificationElement) => {
        let menuList: MenuEntryType[] = []
        if (logged) {
          menuList = BaseMenu
          menuList.forEach((value) => {
            value.submenus &&
              value.submenus
                .filter((submenu) => !submenu.path)
                .forEach((submenu) => (submenu.path = submenu.getPath!()))
          })

          const populateOperationsSubmenu = async () => {
            const index = getSubmenuIndex('operationLogs')
            let entries: SubmenuEntryType[] = []
            if (index >= 0) {
              const getOperationSubmenuEntries = async () => {
                try {
                  const { content } = await Operations.search<StwOperation>({
                    enabled: true,
                    showUserOperations: true,
                    size: MAX_PAGE_SIZE,
                    sort: ['displayPriority,asc', 'description,asc'],
                  })
                  return content.map((operation) => ({
                    label: operation.description || '',
                    path: `/operationlogs/${operation.type}/${operation.id}`,
                    hidden: operation.type === 'TAG_INFO',
                  }))
                } catch (e) {
                  return []
                }
              }
              entries = await getOperationSubmenuEntries()
            }
            return {
              index: index,
              entries: entries,
            }
          }
          const operations = await populateOperationsSubmenu()
          if (operations.index >= 0) {
            menuList[operations.index].submenus = operations.entries || []
            menuList[operations.index].hidden = menuList[operations.index].submenus.length === 0
          }
          const populateReportsSubmenu = async () => {
            const index = getSubmenuIndex('reports')
            let entries: SubmenuEntryType[] = []
            if (index >= 0) {
              const getReportsSubmenuEntries = async () => {
                try {
                  const reports = await Reports.reports()
                  return reports.map((report) => ({
                    label: report.description,
                    path: `/reports/${report.id}`,
                    hidden: !getReportListCapability(report.id),
                  }))
                } catch (e) {
                  return []
                }
              }
              entries = await getReportsSubmenuEntries()
            }
            return {
              index: index,
              entries: entries,
            }
          }
          const reports = await populateReportsSubmenu()
          if (reports.index >= 0) {
            menuList[reports.index].submenus = reports.entries || []
            menuList[reports.index].hidden = menuList[reports.index].submenus.length === 0
          }
          const notifications = await populateNotificationsSubmenu(notificationElement)
          if (notifications.index >= 0) {
            menuList[notifications.index].submenus = notifications.entries
            menuList[notifications.index].counter = notifications.unread > 0 ? notifications.unread : -1
            menuList[notifications.index].hidden = notifications.entries.length === 0
          }
        }
        return menuList
      }

      if (!listenerAttach.current) {
        const refreshMenu = async () => {
          const results = await getNotifications()
          const menuList = await getMenu(authenticated, results.content)
          setnumberUpdateMenu(dayjs().unix())
          setmenu(menuList)
          setNotificationsList(results.content)
          setLastNotificationsList(results.content)
        }
        listenerAttach.current = true
        document.addEventListener('operationLogs', () => {
          refreshMenu()
        })
        document.addEventListener('notifications', () => {
          refreshMenu()
        })
      }

      !!sessionExpiredTimeout.current && clearTimeout(sessionExpiredTimeout.current)
      sessionExpiredTimeout.current = null

      if (!appStoreLoaded.current) {
        appStoreLoaded.current = true
        await AppStore.loadInitialData()
        setWithRoles(AppStore.loggedUser.capabilities && AppStore.loggedUser.capabilities.length > 0)
        setauthenticated(true)
        setnumberUpdateMenu(0)
        if (AppStore.loggedUser.capabilities ? AppStore.loggedUser.capabilities.length > 0 : false) {
          const results = await getNotifications()
          const menuList = await getMenu(true, results.content)
          const selected: any = await getSelectedMenu(menuList)
          const bread: BreadcrumbsType =
            selected.breadcrumbs && selected.breadcrumbs.icon ? selected.breadcrumbs : undefined
          setmenu(menuList)
          setNotificationsList(results.content)
          setLastNotificationsList(results.content)
          setmenuSelected(selected.menu)
          setsubmenuSelected(selected.submenu !== null ? selected.submenu : undefined)
          setbreadcrumbs(bread)
          setQueryString(window.location.search)
          StyleWhereRouter.history.listen((location) => {
            locationChange(menuList, location.pathname)
          })
          refreshNotifications()
        }
        setloading(false)
      }
    }

    // Usa ref invece di uno stato perché keykloack.init schianta se chiamato due volte,
    // come avviene sempre con StrictMode
    if (!keycloakInitialized.current) {
      keycloakInitialized.current = true

      keycloak.onAuthSuccess = () => onAuthSuccess()
      keycloak.onAuthError = () => onError()
      keycloak.onAuthRefreshError = () => onError()
      keycloak.onTokenExpired = () => {
        // Stop fetching notifications
        if (notificationInterval) {
          clearInterval(notificationInterval)
          setNotificationInterval(undefined)
        }
        // Refresh expired token
        keycloak.updateToken(5)
      }
      keycloak.onAuthRefreshSuccess = () => {
        // Refresh appstore a resume fetching notifications
        AppStore.loadAuthToken()
        refreshNotifications()
      }
      keycloak.init({ onLoad: 'login-required', checkLoginIframe: false })
    }
  }, [authenticated, notificationInterval, populateNotificationsSubmenu, updateNotification])

  const getNotifications = async () => {
    try {
      const results = await Notifications.search<StwNotification>({
        sort: 'creationDate,desc',
      })
      return results
    } catch (e) {
      return { content: [] }
    }
  }

  useEffect(() => {
    const handleKeyboardClick = (event) => {
      if (event.keyCode === 27 && visibleSearch) {
        setVisibleSearch(false)
      }
    }
    window.addEventListener('keydown', handleKeyboardClick, false)
    return () => {
      window.removeEventListener('keydown', handleKeyboardClick)
    }
  }, [visibleSearch])

  const handleNotificationDetail = useCallback(
    async (item) => {
      setNotificationObject(item)
      setVisibleNotificationDetail(item !== undefined)
      if (item && !item.read) {
        //make read
        await Notifications.post(`${item.id}/markAsRead`)
        updateNotification(true)
      }
    },
    [updateNotification]
  )

  useEffect(() => {
    const checkNotifications = async (notificationsElement) => {
      const notificationToOpen: StwNotification[] = []
      if (notificationsElement && notificationsElement.length > 0) {
        for (let c = 0; c < notificationsElement.length; c++) {
          if (!notificationsElement[c].read) {
            const obj = lastNotificationsList.find((element) => {
              return element.id === notificationsElement[c].id
            })
            if (!obj) {
              notificationToOpen.push(notificationsElement[c])
            }
          }
        }
      }
      if (notificationToOpen.length > 0) {
        const notifications = await populateNotificationsSubmenu(notificationsElement)
        if (notifications.index >= 0) {
          menu[notifications.index].submenus = notifications.entries
          menu[notifications.index].counter = notifications.unread > 0 ? notifications.unread : -1
          menu[notifications.index].hidden = notifications.entries.length === 0
          setmenu(menu)
        }
        setLastNotificationsList(notificationsElement)
        notificationToOpen.map((element) =>
          notification.success({
            message: __('misc.notifications.new'),
            description: element.description,
            className: 'stw-notification-alert',
            placement: 'bottomRight',
            duration: 8,
            onClick: () => handleNotificationDetail(element),
          })
        )
      }
    }
    checkNotifications(notificationsList)
  }, [handleNotificationDetail, lastNotificationsList, menu, notificationsList, populateNotificationsSubmenu])

  useEffect(() => {
    async function init() {
      AppStore.openModal = (modal: ModalType) => setmodals([...modals, modal])
      AppStore.closeModal = (id: string) => {
        const modalIndex = modals.findIndex((modal) => modal.id === id)
        if (modalIndex === -1) return
        modals[modalIndex].visible = false
        setmodals(modals.filter((m) => m.visible))
      }
    }

    init()
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const locationChange = async (menuList, path) => {
    const selected: any = await getSelectedMenu(menuList)
    const bread: BreadcrumbsType = selected.breadcrumbs && selected.breadcrumbs.icon ? selected.breadcrumbs : undefined
    setmenuSelected(selected.menu)
    setsubmenuSelected(selected.submenu !== null ? selected.submenu : undefined)
    setbreadcrumbs(bread)

    const currentPath = path || (await getCurrentPath(false, 3))
    const selectedMenu: any = await checkSelectedMenu(menuList, currentPath)
    if (selectedMenu.menu !== '') {
      setQueryString(window.location.search)
    }
  }

  const getSubmenuIndex = (key: string) => {
    return BaseMenu.findIndex((submenu) => submenu.key === key)
  }

  const renderComponent = (path, props) => {
    const StylewhereComponent = Routes[path].component
    return (
      <StylewhereComponent
        component={Routes[path].component}
        {...props}
        queryString={queryString}
        breadcrumbs={breadcrumbs}
        handleNotificationDetail={handleNotificationDetail}
        notificationsList={notificationsList}
      />
    )
  }

  const menuClick = (selected) => {
    setmenuSelected(selected)
  }

  const handleCloseSearch = () => {
    setVisibleSearch(false)
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          fontSize: 12,
          fontFamily: 'Roboto, sans-serif',
          colorPrimary: '#333',
        },
        components: {
          Breadcrumb: {
            fontSize: 14,
          },
          Table: {
            fontSize: 13,
          },
          Image: {
            colorBgBase: '#fff',
            colorBgContainer: '#fff',
          },
          Input: {
            fontSize: 14,
          },
          Form: {
            labelFontSize: 14,
          },
          Tag: {
            fontSizeSM: 12,
          },
        },
      }}
    >
      {!loading && (
        <Layout className="site-page">
          {withRoles ? (
            <>
              {authenticated && (
                <Sidebar
                  numberUpdateMenu={numberUpdateMenu}
                  menu={menu}
                  menuSelected={menuSelected}
                  subMenuSelected={submenuSelected}
                  menuClick={menuClick}
                  handleNotificationDetail={handleNotificationDetail}
                  loggedUser={AppStore.loggedUser}
                />
              )}
              <Router history={StyleWhereRouter.history}>
                <Switch>
                  {activeRoutes.map((path) => (
                    <Route render={(props) => renderComponent(path, props)} path={path} key={path} exact />
                  ))}
                  <Redirect to={authenticated ? '/' : '/login'} />
                </Switch>
              </Router>
              <ModalNotificationDetail
                notification={notificationObject}
                visible={visibleNotificationDetail}
                close={handleNotificationDetail}
              />
              {menu.length > 0 && (
                <ModalSearch
                  visible={visibleSearch}
                  close={handleCloseSearch}
                  menu={menu}
                  subMenuSelected={submenuSelected}
                />
              )}
              {modals.map((modal) => {
                if ('modal' in modal) return modal.modal
                const onClose = () => {
                  modal.visible = false
                  setmodals(modals.filter((m) => m.visible))
                  modal?.onCancel?.({} as any)
                }
                return (
                  <Modal
                    {...modal}
                    title={<ModalHeader close={onClose} title={modal.title ?? ''} />}
                    key={modal.id}
                    open
                    closable={false}
                    onCancel={onClose}
                    footer={null}
                    centered
                    className="stw-workstation-rfid-antennas-modal"
                  >
                    {typeof modal.body === 'function' ? modal.body() : modal.body}
                  </Modal>
                )
              })}
            </>
          ) : (
            <ErrorPage title="Stylewhere" text={__(T.misc.noRoles)} />
          )}
        </Layout>
      )}
    </ConfigProvider>
  )
}

export default App
