import {
  AdvancedTableColumn,
  AdvancedTableContentKind,
  AdvancedTableFilterKind,
  AdvancedTableFiltersDef,
} from '../../../components/AdvancedTable'
import {
  AdvancedFormField,
  AdvancedFormFieldBuilder,
  AdvancedFormInputType,
} from '../../../components/AdvancedForm/advancedFormTypes'
import { __ } from '../../../shared/i18n'
import { AdvancedTableRowColumn } from '../../../components'
import { DetailCell } from '../../../api'
import { CAPABILITIES, getActionsTableColumns } from '../../utility/capabilityUtility'

export const APPLICATION_FILTERS: AdvancedTableFiltersDef = [
  {
    key: 'equalCodes',
    label: __('fields.labels.code'),
    type: AdvancedTableFilterKind.STRING_LIST,
    column: 'code',
    col: 8,
  },
  {
    key: 'description',
    label: __('fields.labels.description'),
    type: AdvancedTableFilterKind.STRING,
    column: 'description',
    col: 8,
  },
  {
    key: 'value',
    label: __('fields.labels.value'),
    type: AdvancedTableFilterKind.STRING,
    column: 'value',
    col: 8,
  },
  {
    key: 'type',
    label: __('fields.labels.type'),
    type: AdvancedTableFilterKind.ENUM,
    column: 'type',
    select: {
      option: 'applicationTypes',
    },
    col: 8,
  },
  {
    key: 'publicAccessible',
    label: __('fields.labels.publicAccessible'),
    type: AdvancedTableFilterKind.BOOLEAN,
    column: 'publicAccessible',
    col: 8,
  },
  {
    key: 'clients',
    label: __('fields.labels.clients'),
    type: AdvancedTableFilterKind.SELECT,
    option: 'applicationClients',
    column: 'clients',
    col: 8,
    selectWithCustomSelection: true,
  },
  {
    key: 'creationDate',
    label: __('fields.labels.creationDate'),
    type: AdvancedTableFilterKind.DATE_RANGE,
    column: 'creationDate',
    col: 8,
  },
  {
    key: 'lastModifyDate',
    label: __('fields.labels.lastModifyDate'),
    type: AdvancedTableFilterKind.DATE_RANGE,
    column: 'lastModifyDate',
    col: 8,
  },
]

export const APPLICATION_FIELD: AdvancedFormField[] = [
  AdvancedFormFieldBuilder.buildText('description'),
  {
    key: 'code',
    type: AdvancedFormInputType.UNIQUE_TEXT,
    label: __('fields.labels.code'),
    placeholder: __('fields.placeholders.code'),
    rules: [{ required: true }],
    unique: {
      endpoint: '/api/v1/applicationSettings',
      parameter: 'equalCodes',
    },
  },
  AdvancedFormFieldBuilder.buildText('value', [{ required: true }]),
  AdvancedFormFieldBuilder.buildSelect('type', 'applicationTypes', [{ required: true }]),
  {
    key: 'publicAccessible',
    type: AdvancedFormInputType.SWITCH,
    placeholder: __('misc.disabled'),
    rules: [{ required: false }],
  },
  {
    key: 'clients',
    type: AdvancedFormInputType.SELECT,
    placeholder: __('fields.placeholders.clients'),
    rules: [{ required: false }],
    option: 'applicationClients',
    selectWithCustomSelection: true,
  },
]

export const APPLICATION_COLUMNS: AdvancedTableColumn[] = [
  {
    title: __(`fields.labels.code`),
    key: 'code',
    dataIndex: 'code',
  },
  {
    title: __(`fields.labels.description`),
    key: 'description',
    dataIndex: 'description',
    className: 'stw-extra-medium',
  },
  {
    title: __(`fields.labels.value`),
    key: 'value',
    dataIndex: 'value',
  },
  {
    title: __(`fields.labels.type`),
    key: 'type',
    dataIndex: 'type',
  },
  {
    key: 'publicAccessible',
    title: __(`fields.labels.publicAccessible`),
    dataIndex: 'publicAccessible',
    type: AdvancedTableContentKind.BOOLEAN,
  },
  {
    key: 'clients',
    title: __(`fields.labels.clients`),
    dataIndex: 'clients',
    type: AdvancedTableContentKind.MULTIPLE_STRING,
    className: 'stw-extra-medium',
    sortable: false,
  },
  {
    key: 'creationDate',
    title: __(`fields.labels.creationDate`),
    dataIndex: 'creationDate',
    type: AdvancedTableContentKind.DATE_TIME,
  },
  {
    key: 'lastModifyDate',
    title: __(`fields.labels.lastModifyDate`),
    dataIndex: 'lastModifyDate',
    type: AdvancedTableContentKind.DATE_TIME,
  },
  {
    key: 'action',
    className: 'stw-extra-small',
    fixedType: 'right',
    unmanageable: true,
    hidden: false,
    sortable: false,
    render: (text, record) => (
      <AdvancedTableRowColumn
        actions={getActionsTableColumns(
          record.id,
          '/configuration/settings/applications',
          CAPABILITIES.RESOURCE_APPLICATION_SETTING_EDIT
        )}
      />
    ),
  },
]

export const APPLICATION_DATA: DetailCell[] = [
  { title: 'id', attribute: 'id', columns: 24 },
  { title: __(`fields.labels.code`), attribute: 'code', columns: 12 },
  { title: __(`fields.labels.description`), attribute: 'description', columns: 12 },
  { title: __(`fields.labels.value`), attribute: 'value', columns: 12 },
  { title: __(`fields.labels.type`), attribute: 'type', columns: 12 },
  { title: __(`fields.labels.publicAccessible`), attribute: 'publicAccessible', columns: 12, type: 'boolean' },
  { title: __(`fields.labels.clients`), attribute: 'clients', columns: 12, type: 'stringList' },
  { title: __(`fields.labels.creationDate`), attribute: 'creationDate', type: 'date', columns: 12 },
  { title: __(`fields.labels.lastModifyDate`), attribute: 'lastModifyDate', type: 'date', columns: 12 },
]
