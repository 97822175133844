import { Component } from 'react'
import { Typography, Skeleton } from 'antd'

interface Props {
  list: any
  skeleton?: boolean
}

const { Text } = Typography

export class PageHeaderTitle extends Component<Props> {
  render() {
    const { list, skeleton } = this.props
    return (
      <div className="stw-multiple-title">
        {list.map((element, i) => {
          return !skeleton ? (
            <div key={`header-title-${i}`} className="stw-multiple-title-element">
              <Text className="stw-multiple-title-element-label">{element.label}</Text>
              <Text className="stw-multiple-title-element-value">{element.value}</Text>
            </div>
          ) : (
            <div key={`header-title-${i}`} className="stw-multiple-title-element">
              <Skeleton
                className={`stw-skeleton-base ${element.class || ''}`}
                title={false}
                round
                active
                paragraph={{ rows: 1, width: element.widthLabel || '60%' }}
              />
              <Skeleton
                className={`stw-skeleton-base ${element.class || ''}`}
                title={false}
                round
                active
                paragraph={{ rows: 1, width: element.widthDesc || '100%' }}
              />
            </div>
          )
        })}
      </div>
    )
  }
}
