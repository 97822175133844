import { AdvancedTableColumn } from '../components/AdvancedTable'
import ClientUserDatas from '../api/ClientUserDatas'
import { refreshFixedColumns } from './utils'

class ColumnsStorage {
  async load(key: string, columns: any): Promise<AdvancedTableColumn[]> {
    //console.log('LOAD STORAGE')
    const data = await ClientUserDatas.get(`cp-${key}`)
    let storageColumns: StorageCol[] = data as StorageCol[]

    if (storageColumns === null || storageColumns === undefined) {
      storageColumns = columns
    }

    if (!storageColumns) {
      return Promise.all(columns)
    }

    const mergedCols: AdvancedTableColumn[] = storageColumns
      //elimino le colonne non più presenti
      .filter((stCol) => columns.filter((col) => col.key === stCol.key).length > 0)
      // creo una copia dell'AdvancedTableColumn con la visibilità storicizzata
      .map(
        (stCol) =>
          columns
            .filter((col) => col.key === stCol.key)
            .map((col) => {
              return { ...col, hidden: stCol.hidden }
            })[0]
      )

    // aggiungo le colonne mancanti
    columns
      .filter((col) => storageColumns.filter((stCol) => stCol.key === col.key).length === 0)
      .forEach((col: AdvancedTableColumn) => {
        mergedCols.push({ ...col })
      })

    // aggiunta per modificare le colonne fixed se almeno una ha fixedType a left
    const cols = refreshFixedColumns(mergedCols)
    return Promise.all(cols)
  }

  async save(key: string, columns: AdvancedTableColumn[]): Promise<void> {
    //console.log('SAVE LOAD STORAGE')
    const storageCols: StorageCol[] = columns.map((col) => {
      return {
        key: col.key,
        hidden: col.hidden,
      }
    })
    return ClientUserDatas.insertByCode(`cp-${key}`, storageCols)
  }

  async remove(key: string): Promise<void> {
    //console.log('REMOVE LOAD STORAGE')
    return ClientUserDatas.delete(`cp-${key}`)
  }

  async hasStorageColumns(key: string): Promise<boolean> {
    //console.log('HAS LOAD STORAGE')
    const st = await ClientUserDatas.get(`cp-${key}`)
    if (st) {
      const tmp: StorageCol[] = st as StorageCol[]
      return tmp.length > 0
    }
    return false
  }
}

interface StorageCol {
  key: string
  hidden: boolean | undefined
}

export default new ColumnsStorage()
