import {
  AdvancedTableColumn,
  AdvancedTableContentKind,
  AdvancedTableFilterKind,
  AdvancedTableFiltersDef,
} from '../../components/AdvancedTable'
import {
  AdvancedFormField,
  AdvancedFormFieldBuilder,
  AdvancedFormInputType,
} from '../../components/AdvancedForm/advancedFormTypes'
import { __ } from '../../shared/i18n'
import { AdvancedTableRowColumn } from '../../components'
import { DetailCell } from '../../api'
import { CAPABILITIES, getActionsTableColumns } from '../utility/capabilityUtility'

export const PICKING_FLOW_FILTERS: AdvancedTableFiltersDef = [
  {
    key: 'equalCodes',
    label: __('fields.labels.code'),
    col: 8,
    type: AdvancedTableFilterKind.STRING_LIST,
    column: 'code',
  },
  {
    key: 'description',
    label: __('fields.labels.description'),
    type: AdvancedTableFilterKind.STRING,
    column: 'description',
    col: 8,
  },
  {
    key: 'sorting',
    label: __('fields.labels.sorting'),
    type: AdvancedTableFilterKind.BOOLEAN,
    column: 'sortingEnabled',
    col: 8,
  },
  {
    key: 'operationId',
    label: __('fields.labels.operationId'),
    type: AdvancedTableFilterKind.STRING,
    column: 'operation.code',
    col: 8,
  },
  {
    key: 'discriminator',
    label: __('fields.labels.discriminator'),
    type: AdvancedTableFilterKind.ENUM,
    column: 'discriminatorKey',
    select: {
      option: 'pickingFlowDiscriminator',
    },
    col: 8,
  },
  {
    key: 'creationDate',
    label: __('fields.labels.creationDate'),
    type: AdvancedTableFilterKind.DATE_RANGE,
    column: 'creationDate',
    col: 8,
  },
  {
    key: 'lastModifyDate',
    label: __('fields.labels.lastModifyDate'),
    type: AdvancedTableFilterKind.DATE_RANGE,
    column: 'lastModifyDate',
    col: 8,
  },
]

export const PICKING_FLOW_FIELDS: AdvancedFormField[] = [
  AdvancedFormFieldBuilder.buildText('description', [{ required: true }]),
  {
    key: 'code',
    type: AdvancedFormInputType.UNIQUE_TEXT,
    label: __('fields.labels.code'),
    placeholder: __('fields.placeholders.code'),
    rules: [{ required: true }],
    unique: {
      endpoint: '/api/v1/pickingFlows',
      parameter: 'equalCodes',
    },
  },
  AdvancedFormFieldBuilder.buildSelect('discriminatorKey', 'pickingFlowDiscriminators', [{ required: true }]),
  {
    type: AdvancedFormInputType.AUTOCOMPLETE,
    key: 'operation',
    rules: [{ required: true }],
    placeholder: __('fields.placeholders.operation'),
    label: __('fields.labels.operation'),
    autocomplete: {
      endpoint: '/api/v1/operations',
      sort: 'code,asc',
      withIdValues: true,
    },
  },
  AdvancedFormFieldBuilder.buildSwitch(
    'sortingEnabled',
    undefined,
    __('fields.labels.sorting'),
    __('fields.placeholders.sorting'),
    undefined,
    undefined,
    [{ required: false }]
  ),
]

export const PICKING_FLOW_COLUMNS: Array<AdvancedTableColumn> = [
  {
    title: __(`fields.labels.code`),
    key: 'code',
    dataIndex: 'code',
    fixedType: 'left',
    className: 'stw-medium',
  },
  {
    title: __(`fields.labels.description`),
    key: 'description',
    dataIndex: 'description',
    className: 'stw-large',
  },
  {
    title: __(`fields.labels.sorting`),
    key: 'sortingEnabled',
    dataIndex: 'sortingEnabled',
    className: 'stw-small',
    type: AdvancedTableContentKind.BOOLEAN,
  },
  {
    title: __(`fields.labels.discriminator`),
    key: 'discriminatorKey',
    dataIndex: 'discriminatorKey',
    className: 'stw-extra-medium',
  },
  {
    title: __(`fields.labels.operation`),
    key: 'operation.code',
    dataIndex: 'operationCode',
    className: 'stw-extra-medium',
  },
  {
    key: 'creationDate',
    title: __(`fields.labels.creationDate`),
    dataIndex: 'creationDate',
    type: AdvancedTableContentKind.DATE_TIME,
  },
  {
    key: 'lastModifyDate',
    title: __(`fields.labels.lastModifyDate`),
    dataIndex: 'lastModifyDate',
    type: AdvancedTableContentKind.DATE_TIME,
  },
  {
    key: 'action',
    width: 'small',
    fixedType: 'right',
    sortable: false,
    unmanageable: true,
    className: 'stw-small',
    render: (text, record) => (
      <AdvancedTableRowColumn
        actions={getActionsTableColumns(record.id, '/configuration/pickingFlows', CAPABILITIES.RESOURCE_PICKING_EDIT)}
      />
    ),
  },
]

export const PICKING_FLOW_DETAIL: DetailCell[] = [
  { title: __(`fields.labels.id`), attribute: 'id', columns: 12 },
  { title: __(`fields.labels.code`), attribute: 'code', columns: 12 },
  { title: __(`fields.labels.description`), attribute: 'description', columns: 12 },
  { title: __(`fields.labels.operation`), attribute: 'operation', columns: 12, type: 'codeDesc' },
  { title: __(`fields.labels.discriminatorKey`), attribute: 'discriminatorKey', columns: 12 },
  { title: __(`fields.labels.sorting`), attribute: 'sortingEnabled', columns: 12, type: 'boolean' },
  { title: __(`fields.labels.creationDate`), attribute: 'creationDate', type: 'date', columns: 12 },
  { title: __(`fields.labels.lastModifyDate`), attribute: 'lastModifyDate', type: 'date', columns: 12 },
]
