import { Component, createRef } from 'react'
import { Form, FormInstance } from 'antd'
import { StwCapabilitiesGroup } from '../../../../api'
import { ExtendRouteComponentProps } from '../../../../types'
import { __, T } from '../../../../shared/i18n'
import { navigate } from '../../../../shared/router'
import { AdvancedForm, StylewherePage, DefaultHeader, Section } from '../../../../components'
import { CapabilitiesGroupFeatureTypes, FormValidateMessages } from '../../../../constants'
import { AttributeUtil } from '../../../../config/utility/utility'
import CapabilitiesGroup from '../../../../api/CapabilitiesGroup'
import { CAPABILITY_GROUPS_FIELDS } from '../../../../config/Pages/UsersRoles/CapabilitiesGroup'
import { FormUtility } from '../../../../config/utility/formUtility'
import { getFragmentObject, getBackURL } from '../../../../shared/utils'

interface State {
  capabilitiesGroup: StwCapabilitiesGroup
  loader: boolean
  isCodeEditableByDescription: boolean
}

class CapabilitiesGroupForm extends Component<ExtendRouteComponentProps, State> {
  formRef = createRef<FormInstance>()

  constructor(props) {
    super(props)
    this.state = {
      capabilitiesGroup: {},
      loader: true,
      isCodeEditableByDescription: true,
    }
  }

  componentDidMount() {
    this.initialize()
  }

  initialize = async () => {
    let result
    if (this.props.match) {
      const capabilitiesGroupId = this.props.match.params.capabilitiesGroupId
      if (capabilitiesGroupId !== '' && capabilitiesGroupId !== 'create') {
        result = await CapabilitiesGroup.get<StwCapabilitiesGroup>(capabilitiesGroupId)
      }
    }

    this.setState(
      {
        capabilitiesGroup: result || {},
        isCodeEditableByDescription: !result,
        loader: false,
      },
      this.updateFormFieldsValue
    )
  }

  updateFormFieldsValue = () => {
    if (this.formRef && this.formRef.current) {
      this.formRef.current.setFieldsValue(this.state.capabilitiesGroup)
    }
  }

  handleChange = (key, value) => {
    const { capabilitiesGroup, isCodeEditableByDescription } = this.state
    AttributeUtil.setAttribute(capabilitiesGroup, key, value)
    if (key === 'description' && isCodeEditableByDescription) {
      FormUtility.handleDescription(value, capabilitiesGroup, this.formRef)
    }
    if (key === 'code') {
      this.setState({ isCodeEditableByDescription: false })
    }
    this.setState({
      capabilitiesGroup: capabilitiesGroup,
    })
  }

  store = () => {
    const { capabilitiesGroup } = this.state
    const { queryString } = this.props
    if (capabilitiesGroup.id) {
      CapabilitiesGroup.update<StwCapabilitiesGroup>(capabilitiesGroup).then(() => {
        navigate(`/configuration/usersRoles/capabilitiesGroup${queryString ?? ''}`)
      })
    } else {
      CapabilitiesGroup.insert<StwCapabilitiesGroup>(capabilitiesGroup).then((newCapabilitiesGroup) => {
        if (newCapabilitiesGroup && newCapabilitiesGroup.id) {
          navigate(`/configuration/usersRoles/capabilitiesGroup${queryString ?? ''}`)
        }
      })
    }
  }

  render() {
    const { breadcrumbs, queryString } = this.props
    const { capabilitiesGroup, loader } = this.state
    const title = capabilitiesGroup.id ? __(T.capabilitiesGroup.edit) : __(T.capabilitiesGroup.create_new)
    const fragment = !loader
      ? getFragmentObject(capabilitiesGroup, 'id', __(T.capabilitiesGroup.create_new))
      : undefined
    return (
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        onFinish={this.store}
        style={{ width: '100%', height: '100%' }}
        ref={this.formRef}
        initialValues={capabilitiesGroup}
        validateMessages={FormValidateMessages}
        scrollToFirstError
      >
        <StylewherePage {...this.props} noOverflow fragment={fragment}>
          <DefaultHeader
            backPath={getBackURL(queryString, breadcrumbs)}
            title={loader ? '...' : title}
            skeleton={{ active: loader }}
            actions={[
              {
                label: __(T.misc.cancel),
                type: 'cancel',
                onClick: () => navigate(`/configuration/usersRoles/capabilitiesGroup${queryString ?? ''}`),
              },
              {
                label: capabilitiesGroup.id ? __(T.misc.update) : __(T.misc.create),
                type: 'submit',
              },
            ]}
          />
          <Section customClass="stw-section-page paged-header scroll">
            <AdvancedForm
              record={capabilitiesGroup}
              fields={CAPABILITY_GROUPS_FIELDS}
              store={this.store}
              editing={capabilitiesGroup.id !== undefined}
              handleChange={this.handleChange}
              parameters={{
                featureType: CapabilitiesGroupFeatureTypes,
              }}
            />
          </Section>
        </StylewherePage>
      </Form>
    )
  }
}

export default CapabilitiesGroupForm
