import { Component } from 'react'
import { Col, notification, Row } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { AdvancedTable, AdvancedTableColumns } from '../AdvancedTable'
import SelectWithAutocompletion from './SelectWithAutocompletion'
import { __ } from '../../shared/i18n'
import { AdvancedTableRowColumn } from '../AdvancedTableRowColumn'

interface Props {
  value?: any
  onChange?: (value: any) => void
  placeholder: string
  endpoint: string
  sort: string
  filters?: any
  uniqueValueError?: string
  columns: AdvancedTableColumns
  canDelete?: boolean
  mapSelection?: (selection: any) => Promise<any>
}

interface State {
  selectWithAutocompletionValue: any
}

class TableWithAutocompletion extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      selectWithAutocompletionValue: undefined,
    }
  }

  getColumns = () => {
    const columns = this.props.columns.map((column) => ({ ...column, sortable: false }))
    if (this.props.canDelete) {
      columns.push({
        key: 'action',
        unmanageable: true,
        hidden: false,
        sortable: false,
        fixedType: 'right',
        render: (text, record) => (
          <AdvancedTableRowColumn
            actions={[
              {
                content: <DeleteOutlined />,
                type: 'default',
                onclick: () => this.handleRemove(record),
              },
            ]}
          />
        ),
      })
    }
    return columns
  }

  getFilters = () => {
    const { excludedIds, ...filters } = this.props.filters ?? {}
    return {
      ...filters,
      excludedIds: Array.from(
        new Set([...(excludedIds ?? []), this.getSelectedValues().map((selectedValue) => selectedValue.id)])
      ),
    }
  }

  getSelectedValues = () => {
    return Array.isArray(this.props.value ?? []) ? this.props.value ?? [] : [this.props.value]
  }

  handleRemove = (valueToRemove: any) => {
    this.updateSelectedValues(this.getSelectedValues().filter((selectedValue) => selectedValue.id !== valueToRemove.id))
  }

  handleSelectWithAutocompletionChange = async () => {
    const { selectWithAutocompletionValue } = this.state
    const selectedValues = this.getSelectedValues()
    if (selectWithAutocompletionValue) {
      const tableWithAutocompletionValue = this.props.mapSelection
        ? await this.props.mapSelection(selectWithAutocompletionValue)
        : selectWithAutocompletionValue
      if (this.isSelected(tableWithAutocompletionValue, selectedValues)) {
        this.showDuplicateError()
      } else {
        this.updateSelectedValues([...selectedValues, tableWithAutocompletionValue])
        this.setState({ selectWithAutocompletionValue: undefined })
      }
    }
  }

  isSelected = (value: any, selectedValues: any[]) =>
    selectedValues.some((selectedValue) => selectedValue.id === value.id)

  showDuplicateError = () =>
    notification.error({
      message: __('misc.validationError'),
      description: this.props.uniqueValueError ?? __('misc.duplicateEntries'),
      placement: 'bottomRight',
      duration: 3,
    })

  updateSelectedValues = (selectedValues: any) => {
    const { onChange } = this.props
    if (onChange) {
      onChange(selectedValues)
    }
  }

  render = () => {
    const { selectWithAutocompletionValue } = this.state
    const { placeholder, endpoint, sort } = this.props
    const selectedValues = this.getSelectedValues()
    return (
      <Row gutter={24}>
        {selectedValues.length > 0 && (
          <Col span={24} style={{ marginBottom: '25px' }}>
            <AdvancedTable
              pagination={{
                pageNumber: 1,
                pageSize: selectedValues.length,
                sortValues: {},
              }}
              columns={this.getColumns()}
              filters={{ filterDefinitions: [] }}
              content={selectedValues}
              totalElements={selectedValues.length}
              disabledScroll
            />
          </Col>
        )}
        <Col span={24}>
          <div className="stw-inner-form">
            <div className="stw-inner-form-input">
              <SelectWithAutocompletion
                value={selectWithAutocompletionValue}
                onChange={(value: any) => this.setState({ selectWithAutocompletionValue: value })}
                placeholder={placeholder}
                endpoint={endpoint}
                sort={sort}
                filters={this.getFilters()}
                withAdd
                onAdd={this.handleSelectWithAutocompletionChange}
              />
            </div>
          </div>
        </Col>
      </Row>
    )
  }
}

export default TableWithAutocompletion
