export class AttributeUtil {
  public static getAttribute(data: any, attribute: string) {
    const { resolvedData, resolvedAttribute } = AttributeUtil.resolveAttribute(data, attribute)
    return resolvedData ? resolvedData[resolvedAttribute] : undefined
  }

  public static setAttribute(data: any, attribute: string, value: any) {
    const { resolvedData, resolvedAttribute } = AttributeUtil.resolveAttribute(data, attribute)
    resolvedData[resolvedAttribute] = value
  }

  public static truncateString(str: string, max: number) {
    if (str.length > max) str = `${str.substr(0, max)}...`
    return str
  }

  private static resolveAttribute(data: any, attribute: string) {
    let [resolvedData, resolvedAttribute] = [data, attribute]
    const attributes = attribute.split('.')
    if (attributes.length > 1) {
      resolvedData = attributes.slice(0, -1).reduce((currentData, currentAttribute) => {
        if (currentData[currentAttribute] === undefined) {
          currentData[currentAttribute] = {}
        }
        return currentData[currentAttribute]
      }, data)
      ;[resolvedAttribute] = attributes.slice(-1)
    }
    return { resolvedData, resolvedAttribute }
  }
}
