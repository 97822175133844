import BaseResource from './BaseResource'
import { api, responseErrorCheck } from './api'

export default class SimpleItemSessionList extends BaseResource {
  static endpoint = '/api/v1/operations/simpleItemSessionList' ///edge

  static async executeAction(listId: string, operationId: string, code: string) {
    const decodePayload = {
      operationId: operationId,
      actionCode: 'pdfMissionPrint',
      attributes: {
        printTemplateCode: code,
      },
    }
    const res = await api.post(`${this.endpoint}/${listId}/executeAction `, decodePayload, { responseType: 'blob' })
    return responseErrorCheck(res)
  }
}
