import { Component } from 'react'
import { DefaultReportDetail } from '../../../components'
import { ExtendRouteComponentProps } from '../../../types'

class ItemIdentifierReportDetail extends Component<ExtendRouteComponentProps> {
  render() {
    const { match } = this.props
    return (
      <DefaultReportDetail {...this.props} reportId="itemIdentifier" reportInstanceId={match.params.itemIdentifierId} />
    )
  }
}

export default ItemIdentifierReportDetail
