import { Component } from 'react'
import { Button, Typography, Space } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { AdvancedForm } from '..'

import { __ } from '../../shared/i18n'

interface Props {
  attribute: any
  handleChange: any
  handleRemove: any
  record?: any
  configuration: any
  parameters?: any
  keyBlock: string
  index: number
  entity?: string
  erasable?: boolean
}

interface State {
  attr: any
}

const { Text } = Typography

export class AdvancedFormAttributesSingle extends Component<Props, State> {
  timerUpdates
  lastData

  handleChange = (key, value, field) => {
    if (this.timerUpdates) {
      clearTimeout(this.timerUpdates)
    }
    this.lastData = {
      value: value,
      field: field,
    }
    this.timerUpdates = setTimeout(() => {
      this.timerHandleChange()
    }, 500)
  }

  timerHandleChange = () => {
    const { handleChange, index } = this.props
    handleChange(index, this.lastData.field, this.lastData.value)
  }

  getValueFieldKey = (column) => {
    const { configuration, attribute, keyBlock, index } = this.props
    if (configuration.suffix) {
      if (configuration.useIndex) {
        return `${configuration.useIndexPrefix || 'details'}.${configuration.prefix}${index}.${column.field}`
      }
      return `${configuration.prefix}.${attribute.key}.${column.field}`
    }
    return `${keyBlock || 'attributes'}.${attribute.key}`
  }

  render() {
    const { attribute, handleRemove, configuration, parameters, record, index, entity, erasable } = this.props
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const thisRef = this
    const canDelete = (!entity || entity === '') && erasable
    return (
      <div className="stylewhere-editable-table-row">
        {configuration.columns &&
          configuration.columns.map((column, i) => {
            return (
              <div
                key={`editable-column-${i}`}
                className={`stylewhere-editable-table-row-column${
                  !canDelete && i === configuration.columns.length - 1 ? ' nomr' : ''
                }`}
              >
                {!column.editable ? (
                  <Space direction="vertical" size="small">
                    <Text strong>
                      {__(`fields.labels.${attribute[column.field] || ''}`, attribute[column.field] || '')}
                      {/*<Text style={{ color: '#ff4d4f', marginLeft: 5 }}>*</Text>*/}
                    </Text>
                    {attribute.message && attribute.message !== '' && (
                      <Text style={{ fontSize: 13, lineHeight: '17px', display: 'inline-block' }}>
                        {attribute.message}
                      </Text>
                    )}
                  </Space>
                ) : (
                  <AdvancedForm
                    record={record}
                    handleChange={(k, val) => thisRef.handleChange(k, val, column.field)}
                    parameters={parameters}
                    //help={<span />}
                    fields={[
                      {
                        key: thisRef.getValueFieldKey(column),
                        label: column.label,
                        placeholder: column.placeholder,
                        type: column.type,
                        rules: column.rules,
                        col: 24,
                        option: column.option || '',
                      },
                    ]}
                    labelHidden
                  />
                )}
              </div>
            )
          })}
        {canDelete && (
          <div className="stylewhere-editable-table-row-action">
            <Button className="stylewhere-button-default" onClick={() => handleRemove(index)}>
              <DeleteOutlined />
            </Button>
          </div>
        )}
      </div>
    )
  }
}
