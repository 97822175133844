import { Component } from 'react'
import { Col, Space } from 'antd'
import { SubmenuEntryType } from '../../types'
import { getNotificationTime, truncateString } from '../../shared/utils'

interface Props {
  item: SubmenuEntryType
  handleNotificationDetail: any
}

interface State {
  isread: boolean
}

export class SidebarMenuNotification extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      isread: false,
    }
  }

  componentDidMount() {
    this.init()
  }

  init = async () => {
    const { item } = this.props
    this.setState({
      isread: item.read || false,
    })
  }

  shouldComponentUpdate = (nextProps) => {
    const { isread } = this.state
    if (nextProps.item && nextProps.item.read !== isread) {
      this.setState({
        isread: nextProps.item.read,
      })
    }
    return true
  }

  openLink = (e, url) => {
    e.preventDefault()
    e.stopPropagation()
    window.open(url, '_blank')
  }

  openDetail = (e) => {
    const { item, handleNotificationDetail } = this.props
    e.preventDefault()
    e.stopPropagation()
    handleNotificationDetail(item)
  }

  render() {
    const { isread } = this.state
    const { item } = this.props
    return (
      <Space
        className="stw-sider-notification"
        size="small"
        direction="vertical"
        onClick={(event) => this.openDetail(event)}
      >
        <div className="stw-sider-notification-title">
          <div className={`status${!isread ? ' unread' : ''}`} />
          <span className="label">{truncateString(item.description, 60)}</span>
        </div>
        <span className="time">{getNotificationTime(item.creationDate)}</span>
        {false && item.link && item.link !== '' && (
          <Col onClick={(event) => this.openLink(event, item.link)} span={24}>
            <div className="stw-sider-notification-link" />
          </Col>
        )}
      </Space>
    )
  }
}
