import { Component } from 'react'
import { ExtendRouteComponentProps } from '../../../types'
import { StylewherePage, DefaultHeader, Section, GridData, NotFound } from '../../../components'
import { __, T } from '../../../shared/i18n'
import Printers from '../../../api/Printers'
import { DetailCell, StwPrinter } from '../../../api'
import { PRINTERS_DATA } from '../../../config/Pages/Devices/Printers'
import {
  getDetailDataWithAttributesMapping,
  getMultipleTitle,
  getBackURL,
  getFragmentObject,
} from '../../../shared/utils'

interface State {
  printer: StwPrinter | undefined
  loader: boolean
  detailData: DetailCell[]
}

class PrinterDetail extends Component<ExtendRouteComponentProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      printer: {},
      loader: true,
      detailData: PRINTERS_DATA,
    }
  }

  componentDidMount = async () => {
    let printer
    const data: DetailCell[] = await getDetailDataWithAttributesMapping(PRINTERS_DATA, 'printer', 2)
    if (this.props.match) {
      const printerId = this.props.match.params.printerId
      if (printerId) {
        printer = await Printers.get<StwPrinter>(printerId).then((res) => res)
      }
    }

    this.setState({
      printer: printer,
      detailData: data,
      loader: false,
    })
  }

  render() {
    const { printer, loader, detailData } = this.state
    const { breadcrumbs, queryString } = this.props
    const fragment = getFragmentObject(printer, 'code')
    return (
      <StylewherePage {...this.props} noOverflow fragment={fragment}>
        <DefaultHeader
          backPath={getBackURL(queryString, breadcrumbs)}
          multipleTitle={getMultipleTitle(printer)}
          title={__(T.misc.not_found)}
          skeleton={{ active: loader, options: { multiple: true } }}
        />
        <Section customClass={`stw-section-page paged-header ${printer || loader ? 'scroll transparent' : ''}`}>
          {printer || loader ? (
            <GridData fields={detailData || PRINTERS_DATA} record={printer || {}} skeleton={loader} />
          ) : (
            <NotFound fullheight title={__(T.misc.noRecordFoundTitle)} />
          )}
        </Section>
      </StylewherePage>
    )
  }
}

export default PrinterDetail
