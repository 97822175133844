import { __ } from '../../../shared/i18n'
import { CAPABILITIES, hasAnyCapabilities } from '../../utility/capabilityUtility'

export function getTabs(active) {
  return [
    {
      label: __('titles.users'),
      url: '/configuration/usersRoles/users',
      active: active === 1,
      hidden: !hasAnyCapabilities(CAPABILITIES.CONTROL_PANEL_USER_VIEW),
    },
    {
      label: __('titles.capabilitiesGroup'),
      url: '/configuration/usersRoles/capabilitiesGroup',
      active: active === 2,
      hidden: !hasAnyCapabilities(CAPABILITIES.CONTROL_PANEL_CAPABILITY_GROUP_VIEW),
    },
    {
      label: __('titles.roles'),
      url: '/configuration/usersRoles/roles',
      active: active === 3,
      hidden: !hasAnyCapabilities(CAPABILITIES.CONTROL_PANEL_ROLE_VIEW),
    },
    {
      label: __('titles.roleMap'),
      url: '/configuration/usersRoles/roleMappings',
      active: active === 4,
      hidden: !hasAnyCapabilities(CAPABILITIES.CONTROL_PANEL_ROLE_MAPPING_VIEW),
    },
  ]
}
