import { __ } from '../../../shared/i18n'
import { CAPABILITIES, hasAnyCapabilities } from '../../utility/capabilityUtility'

export function getTabsSettings(active) {
  return [
    {
      label: __('titles.operations'),
      url: '/configuration/settings/operations',
      active: active === 1,
      hidden: !hasAnyCapabilities(CAPABILITIES.CONTROL_PANEL_OPERATION_VIEW),
    },
    {
      label: __('titles.itemconfiguration'),
      url: '/configuration/settings/itemConfigurations',
      active: active === 2,
      hidden: !hasAnyCapabilities(CAPABILITIES.CONTROL_PANEL_ITEM_CONFIGURATION_VIEW),
    },
    {
      label: __('titles.applications'),
      url: '/configuration/settings/applications',
      active: active === 3,
      hidden: !hasAnyCapabilities(CAPABILITIES.CONTROL_PANEL_APPLICATION_SETTING_VIEW),
    },
    {
      label: __('titles.itemType'),
      url: '/configuration/settings/itemTypes',
      active: active === 4,
      hidden: !hasAnyCapabilities(CAPABILITIES.CONTROL_PANEL_ITEM_TYPE_VIEW),
    },
  ]
}
