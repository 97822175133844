import { Component } from 'react'
import { StylewherePage, TableList } from '../../../components'
import { __, T } from '../../../shared/i18n'
import { AdvancedTableConfig } from '../../../components/AdvancedTable'
import { ExtendRouteComponentProps } from '../../../types'
import { CAPABILITIES, getActionsList } from '../../../config/utility/capabilityUtility'
import PickingFlows from '../../../api/PickingFlows'
import { PICKING_FLOW_COLUMNS, PICKING_FLOW_FILTERS } from '../../../config/Pages/PickingFlows'
import { BooleanComboEnabledValues, PickingFlowDiscriminators } from '../../../constants'

const APPLICATION_SETTING_TABLE_CONFIG: AdvancedTableConfig = {
  columns: PICKING_FLOW_COLUMNS,
  filterDefinitions: PICKING_FLOW_FILTERS,
  filterParameters: { enabledValues: BooleanComboEnabledValues, pickingFlowDiscriminator: PickingFlowDiscriminators },
  manageColumnsPrefix: 'pickingFlows',
  activeDefaultClassColumn: true,
}

class PickingFlowsList extends Component<ExtendRouteComponentProps> {
  render() {
    return (
      <StylewherePage {...this.props} noOverflow>
        <TableList
          title={__(T.menu.pickingFlows)}
          location={this.props.location}
          config={APPLICATION_SETTING_TABLE_CONFIG}
          resource={{
            call: PickingFlows.searchFull,
            endpoint: PickingFlows.endpoint,
          }}
          actions={getActionsList(
            CAPABILITIES.RESOURCE_PICKING_EDIT,
            __(T.pickingFlow.create_new),
            '/configuration/pickingFlows/create/form'
          )}
        />
      </StylewherePage>
    )
  }
}

export default PickingFlowsList
