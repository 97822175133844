import { Rule } from 'antd/lib/form'
import { AdvancedTableColumns } from '../AdvancedTable/advancedTableTypes'

export enum AdvancedFormInputType {
  TEXT = 'TEXT',
  UNIQUE_TEXT = 'UNIQUE_TEXT',
  TEXTAREA = 'TEXTAREA',
  SELECT = 'SELECT',
  SELECT_AUTOCOMPLETE = 'SELECT_AUTOCOMPLETE',
  DATE = 'DATE',
  RANGE_DATE = 'RANGE_DATE',
  SWITCH = 'SWITCH',
  NUMBER = 'NUMBER',
  STRING_LIST = 'STRING_LIST',
  ATTRIBUTES = 'ATTRIBUTES',
  OPTIONS = 'OPTIONS',
  INLINE_OPTIONS = 'INLINE_OPTIONS',
  AUTOCOMPLETE = 'AUTOCOMPLETE',
  STRING_AUTOCOMPLETE = 'STRING_AUTOCOMPLETE',
  TITLE = 'TITLE',
  OBJECT_LIST = 'OBJECT_LIST',
  OBJECT_LIST_WITH_CARDS = 'OBJECT_LIST_WITH_CARDS',
  CHECKLIST_PRODUCTS = 'CHECKLIST_PRODUCTS',
  OBJECT_FORM_LIST = 'OBJECT_FORM_LIST',
  SLIDER = 'SLIDER',
  URL = 'URL',
  WORKSTATION_RFID_ANTENNAS = 'WORKSTATION_RFID_ANTENNAS',
  WORKSTATION_BALANCE = 'WORKSTATION_BALANCE',
  NOT_IMPLEMENTED = 'NOT_IMPLEMENTED',
  JSON = 'JSON',
}

export interface AdvancedFormButton {
  label: string
  primary: boolean
  type: 'submit' | 'navigate' | 'handler' | 'reset'
  handlerCallback?: any
  navigatePath?: string
  formName?: string
}

export interface AdvancedFormAutocompleteFieldOption {
  value: string
  label: string
}

export interface AdvancedFormAttribute {
  add?: boolean
  key?: string
  value?: any
  description?: string
  identifierType?: string
  role?: string
  message?: string
}

export class AdvancedFormFieldBuilder {
  static buildAutocomplete = (
    key: string,
    endpoint: string,
    sort: string,
    col?: number,
    fillColSpan?: number,
    rules?: Rule[],
    label?: string,
    placeholder?: string,
    multiple?: boolean,
    activeAddModal?: boolean,
    displayInRow?: boolean,
    displayInlineCol?: number,
    option?: string,
    ruleParameter?: string,
    readonly?: (record: any) => boolean
  ): AdvancedFormField => {
    return {
      type: AdvancedFormInputType.AUTOCOMPLETE,
      key: key,
      col: col,
      fillColSpan: fillColSpan,
      rules: rules,
      placeholder: placeholder,
      label: label,
      activeAddModal: activeAddModal,
      displayInRow: displayInRow,
      displayInlineCol: displayInlineCol,
      option: option,
      ruleParameter: ruleParameter,
      autocomplete: {
        endpoint: endpoint,
        sort: sort,
        multiple: multiple,
      },
      readonly: readonly,
    }
  }

  static buildText = (
    key: string,
    rules?: Rule[],
    col?: number,
    fillColSpan?: number,
    label?: string,
    placeholder?: string,
    onlyCreate?: boolean,
    displayInRow?: boolean,
    displayInlineCol?: number,
    hidden?: boolean
  ): AdvancedFormField => {
    return {
      type: AdvancedFormInputType.TEXT,
      key: key,
      col: col,
      fillColSpan: fillColSpan,
      rules: rules,
      label: label,
      placeholder: placeholder,
      onlyCreate: onlyCreate,
      displayInRow: displayInRow,
      displayInlineCol: displayInlineCol,
      hidden: hidden,
    }
  }

  static buildNumber = (
    key: string,
    rules?: Rule[],
    range?: any,
    col?: number,
    label?: string,
    placeholder?: string,
    displayInRow?: boolean,
    displayInlineCol?: number,
    ruleParameter?: string,
    defaultValue?: number
  ): AdvancedFormField => {
    return {
      type: AdvancedFormInputType.NUMBER,
      key: key,
      col: col,
      rules: rules,
      label: label,
      placeholder: placeholder,
      displayInRow: displayInRow,
      displayInlineCol: displayInlineCol,
      range: range,
      ruleParameter: ruleParameter,
      defaultValue: defaultValue,
    }
  }

  static buildSlider = (
    key: string,
    rules?: Rule[],
    range?: any,
    col?: number,
    label?: string,
    placeholder?: string,
    displayInRow?: boolean,
    displayInlineCol?: number
  ): AdvancedFormField => {
    return {
      type: AdvancedFormInputType.SLIDER,
      key: key,
      col: col,
      rules: rules,
      label: label,
      placeholder: placeholder,
      displayInRow: displayInRow,
      displayInlineCol: displayInlineCol,
      range: range,
    }
  }

  static buildSelect = (
    key: string,
    parameterOption: string,
    rules?: Rule[],
    col?: number,
    fillColSpan?: number,
    label?: string,
    placeholder?: string,
    displayInRow?: boolean,
    displayInlineCol?: number,
    onlyCreate?: boolean
  ): AdvancedFormField => {
    return {
      key: key,
      type: AdvancedFormInputType.SELECT,
      col: col,
      fillColSpan: fillColSpan,
      label: label,
      option: parameterOption,
      placeholder: placeholder,
      rules: rules,
      displayInRow: displayInRow,
      displayInlineCol: displayInlineCol,
      onlyCreate: onlyCreate,
    }
  }

  static buildSwitch = (
    key: string,
    col?: number,
    label?: string,
    placeholder?: string,
    displayInRow?: boolean,
    displayInlineCol?: number,
    rules?: any
  ): AdvancedFormField => {
    return {
      type: AdvancedFormInputType.SWITCH,
      key: key,
      col: col,
      rules: rules ?? [{ required: true }],
      label: label,
      placeholder: placeholder,
      displayInRow: displayInRow,
      displayInlineCol: displayInlineCol,
    }
  }

  static buildAttribute = (
    key: string,
    subtype: AdvancedFormAttributeType,
    added = false,
    entity = '',
    erasable = true
  ): AdvancedFormField => {
    return {
      key: key,
      type: AdvancedFormInputType.ATTRIBUTES,
      col: 24,
      subtype: subtype,
      activeAddModal: added,
      entity: entity,
      erasable: erasable,
    }
  }
}

export interface AdvancedFormField {
  type: AdvancedFormInputType
  col?: number
  key: string
  option?: string
  label?: string
  placeholder?: string
  description?: string
  defaultValue?: string | number
  rules?: Rule[]
  ruleParameter?: string
  format?: string
  rows?: number
  entity?: string
  //TODO: INSERT, UPDATE, READONLY, FULL
  onlyCreate?: boolean
  hidden?: boolean
  subtype?: AdvancedFormAttributeType
  fillColSpan?: number
  displayInRow?: boolean
  displayInlineCol?: number
  uniqueValue?: boolean
  uniqueValueKey?: string
  uniqueValueError?: string
  activeAddModal?: boolean
  marginBottom?: number
  autocomplete?: {
    endpoint: string
    sort: string
    multiple?: boolean
    propertyId?: string
    withIdValues?: boolean
    fieldLabel?: string
    selectionOption?: any
    additionalParams?: any
  }
  dependencies?: string[]
  objectList?: {
    endpoint: string
    sort: string
    columns: AdvancedTableColumns
    fields?: AdvancedFormField[]
    parameters?: any
    canDelete?: boolean
    propertyId?: string
    mapSelection?: (value: any) => Promise<any>
  }
  objectListWithCard?: {
    handleView?: (record) => void
  }
  range?: {
    min: number
    max?: number
    value?: number
  }
  unique?: {
    endpoint: string
    parameter: string
    errorMessage?: string
  }
  readonly?: (record: any) => boolean
  selectWithCustomSelection?: boolean
  erasable?: boolean
  sizeAutocomplete?: number
  maxTagCount?: number
  select?: {
    separator: string
  }
  autoSelectable?: boolean
  sorting?: boolean //only for AdvancedFormInputType.OBJECT_LIST_WITH_CARDS
}

export enum AdvancedFormAttributeType {
  SIMPLE = 'SIMPLE',
  IDENTIFIER = 'IDENTIFIER',
  PRODUCT = 'PRODUCT',
  ENUMERATION = 'ENUMERATION',
  ACTION = 'ACTION',
}
