/* eslint-disable func-names */
import { Component } from 'react'
import { Form } from 'antd'
import { __ } from '../../shared/i18n'
import SelectWithAutocompletion from '../Input/SelectWithAutocompletion'

interface Props {
  handleChange: (value: any) => void
  label?: string
  withoutLabel?: boolean
  rules?: any[]
  name: string
  placeholder?: string
  selector?: string
  endpoint: string
  sort: string
  filters?: any
  readonly?: boolean
  withMultipleSelection?: boolean
  withIdValues?: boolean
  maxTagCount?: number
  fieldLabel?: string
  autoSelectable?: boolean
  selectionOption?: any
}

export class AdvancedFormAutocompleteField extends Component<Props> {
  getLabel = () => {
    return this.props.withoutLabel ? null : this.props.label ?? __(`fields.labels.${this.props.name}`)
  }

  handleChange = (value: any) => {
    const { handleChange } = this.props
    if (handleChange) {
      handleChange(value)
    }
  }

  render() {
    const {
      name,
      rules,
      placeholder,
      selector,
      readonly,
      withMultipleSelection,
      endpoint,
      sort,
      filters,
      withIdValues,
      maxTagCount,
      fieldLabel,
      autoSelectable,
      selectionOption,
    } = this.props
    return (
      <Form.Item name={name.split('.')} rules={rules} label={this.getLabel()} className="stylewhere-form-item">
        <SelectWithAutocompletion
          onChange={this.handleChange}
          placeholder={placeholder ?? __(`fields.placeholders.${name}`)}
          selector={selector}
          withMultipleSelection={withMultipleSelection}
          readonly={readonly}
          endpoint={endpoint}
          sort={sort}
          filters={filters}
          withIdValues={withIdValues}
          maxTagCount={maxTagCount}
          fieldLabel={fieldLabel}
          autoSelectable={autoSelectable}
          selectionOption={selectionOption}
        />
      </Form.Item>
    )
  }
}
