import { stringify } from 'qs'
import { notification } from 'antd'
import { ApiErrorResponse, ApiResponse, create } from 'apisauce'
import { ConnectionError } from '../shared/errors'
import config from '../config'
import { __, T } from '../shared/i18n'

const showError = (message: string, description = '') => {
  notification.error({
    message: message,
    description: description,
    duration: 5, // to change for live
    placement: 'bottomRight',
  })
}

/**
 * Will throw an exception if server returned an error
 * @param res
 */
const getError = (res) => {
  let error = `${(res && res.status) || 'Unknown'} Status`
  if (res && res.data) {
    if (T.serverError[res.data.errorId]) {
      error = __(T.serverError[res.data.errorId])
    } else {
      error = res.data.errorId
    }
  }
  return error
}

export const responseErrorCheck = <T = any>(res: ApiResponse<T, any>) => {
  if (res?.status === 0 || res?.problem === 'UNKNOWN_ERROR') {
    throw new ConnectionError(res as ApiErrorResponse<any>)
  }

  if (res && (res.status === 200 || res.status === 204)) {
    return res.data as T
  }

  if (
    res.status === 400 &&
    res.config?.url?.indexOf('simpleItemSessionList') !== -1 &&
    res.config?.url?.indexOf('executeAction ') !== -1
  ) {
    throw new Error('')
  } else if (res.status === 401 && res.config?.url === '/login') {
    showError('Credential error')
  } else if (res.status === 401) {
    showError('Not authorized')
  } else if (res?.status === 403 && res?.data?.errorCode === 'CAPABILITIES_MISSING') {
    showError(`${__(T.serverError.missingCapabilities)}: ${res.data.payload.join(', ')}`)
  }
  // else if (res.status === 403) {
  //   throw new Unauthorized(res as ApiErrorResponse<any>)
  // }
  else if (res.status === 404) {
    //check to remove notification for images
    if (!(res.data && res.data.payload && res.data.payload.entity === 'Image')) {
      const error = getError(res)
      showError(error, `${res?.data?.message ?? '404'}`)
    }
  } else if (res?.status && res?.status >= 400) {
    const errorHasCode = res.data?.errorCode !== undefined || res.data?.messageCode !== undefined
    const error = getError(res)
    showError(
      error,
      `${res.data?.errorCode ?? res.data?.messageCode ?? ''}${errorHasCode ? ': ' : ''}${res.data?.message}` ??
        'Generic Error'
    )
  } else if (res?.status === 500 && res?.data?.message === 'CAPABILITIES_MISSING') {
    showError(`${__(T.serverError.missingCapabilities)}: ${res.data.payload.join(', ')}`)
  } else if (res.data && res.data.error?.message) {
    showError('Error')
  } else if (!res || !res.data) {
    showError('Malformed Response from server')
  } else {
    showError('Error')
  }
  return res.data
}

/**
 * Apisauce params serializer adapted to TMR API
 */
const paramsSerializer = (params: any) =>
  stringify(params, {
    // TMR API handles multiple values with format ?code=A&code=B, but default serializer
    // uses format ?code[]=A&code[]=B so override with option arrayFormat: repeat
    arrayFormat: 'repeat',
    // TMR API date format is unix timestamp in milliseconds
    // Note: this will not usually be used as date params are converted to timestamp
    // as soon as they're parsed from user input
    serializeDate: (Date) => Date.getTime().toString(),
  })

const api = create({
  baseURL: !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? config.endpoint : config.endpoint,
  timeout: 60000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  paramsSerializer,
})

api.axiosInstance.interceptors.response.use(
  (response) => response,
  // eslint-disable-next-line consistent-return
  (error) => {
    // if on login page respond with 401
    if (error.request.responseURL.includes('/login')) {
      return Promise.reject(error)
    }
    if (error.response.status === 401) {
      // alert('session expired')
      // DeviceEventEmitter.emit('AccessTokenExpired')
    } else {
      return Promise.reject(error)
    }
  }
)

// export const translateErrorMessage = (message: string) => {
//   return message
// }

// export default create({
//   baseURL: 'https://localhost', // this is useless, each resource will define the baseUrl
//   timeout: 30000,
// })

export { api }
