import { Component, createRef, RefObject } from 'react'
import { Form, FormInstance } from 'antd'
import { StwFullRfidAntenna } from '../../../api'
import { BreadcrumbsFragmentType } from '../../../components/PageBread'
import { ExtendRouteComponentProps } from '../../../types'
import { __, T } from '../../../shared/i18n'
import { AttributeUtil } from '../../../config/utility/utility'
import { navigate } from '../../../shared/router'
import { FormValidateMessages, rfidAntennaZoneFilter } from '../../../constants'
import { AdvancedForm, StylewherePage, DefaultHeader, Section } from '../../../components'
import RfidAntennas from '../../../api/RfidAntennas'
import { ANTENNAS_FIELDS } from '../../../config/Pages/Devices/RfidAntennas'
import { DeviceUtility } from '../../../config/utility/deviceUtility'
import { getFragmentObject, getBackURL } from '../../../shared/utils'

interface State {
  antenna: StwFullRfidAntenna
  readerFilters: { [filterKey: string]: any }
  loader: boolean
  fragment?: BreadcrumbsFragmentType[]
}

class RfidAntennaForm extends Component<ExtendRouteComponentProps, State> {
  formRef = createRef<FormInstance>()

  constructor(props) {
    super(props)
    this.state = {
      readerFilters: { placeId: '' },
      antenna: {
        rxSensitivity: -80,
        txPower: 10,
      },
      loader: true,
    }
  }

  async componentDidMount() {
    await this.initialize()
  }

  shouldComponentUpdate(nextProps: Readonly<ExtendRouteComponentProps>): boolean {
    const { antenna, loader } = this.state
    const nextAntennaId = nextProps.match.params.antennaId
    const currentAntennaId = antenna.id
    if (
      !loader &&
      nextAntennaId &&
      nextAntennaId !== '' &&
      nextAntennaId !== 'create' &&
      nextAntennaId !== currentAntennaId
    ) {
      this.setState({ loader: true }, this.initialize)
    }
    return true
  }

  async initialize() {
    const { match } = this.props
    let antenna: StwFullRfidAntenna = this.state.antenna
    if (match) {
      const antennaId = match.params.antennaId
      if (antennaId !== '' && antennaId !== 'create') {
        antenna = await RfidAntennas.getFull(antennaId)
      }
    }
    this.setState(
      {
        antenna: antenna,
        loader: false,
      },
      this.updateFormFieldsValue
    )
  }

  updateFormFieldsValue = () => {
    if (this.formRef && this.formRef.current) {
      this.formRef.current.setFieldsValue(this.state.antenna)
    }
  }

  handleChange = (key, value) => {
    const { antenna } = this.state
    AttributeUtil.setAttribute(antenna, key, value)
    if (key === 'reader') {
      this.handleReaderChange(antenna, value)
    } else if (key === 'zone') {
      this.handleZoneChange(antenna, value)
    }
    this.setState({ antenna })
  }

  async store() {
    const { antenna } = this.state
    if (antenna.id) {
      RfidAntennas.updateFull(antenna).then((value) => {
        this.redirect(value)
      })
    } else {
      RfidAntennas.insertFull(antenna).then((value) => {
        this.redirect(value)
      })
    }
  }

  redirect(antenna: StwFullRfidAntenna) {
    if (antenna && antenna.id) {
      navigate(`/devices/antennas${this.props.queryString ?? ''}`)
    }
  }

  handleReaderChange(antenna: StwFullRfidAntenna, value) {
    if (value) {
      this.updateCodeAndDescription(antenna, this.formRef)
    } else {
      DeviceUtility.resetCodeAndDescription(antenna, this.formRef)
    }
  }

  updateCodeAndDescription(antenna: StwFullRfidAntenna, formRef: RefObject<FormInstance>) {
    RfidAntennas.search<StwFullRfidAntenna>({ page: 0, size: 1, readerId: antenna.reader!.id }).then(
      ({ totalElements }) => {
        DeviceUtility.setFieldValue(antenna, 'code', `${antenna.reader!.code}_A${totalElements + 1}`, formRef)
        DeviceUtility.setFieldValue(
          antenna,
          'description',
          antenna.reader!.description && `${antenna.reader!.description}_A${totalElements + 1}`,
          formRef
        )
        this.setState({ antenna })
      }
    )
  }

  handleZoneChange(antenna: StwFullRfidAntenna, value) {
    if (value === undefined) {
      DeviceUtility.setFieldValue(antenna, 'reader', undefined, this.formRef)
      this.handleReaderChange(antenna, undefined)
    } else if (value.place) {
      this.setState(({ readerFilters }) => {
        readerFilters.placeId = value.place!.id!
        return { readerFilters }
      })
    }
  }

  render() {
    const { antenna, readerFilters, loader } = this.state
    const title = antenna.id ? __(T.antennas.edit) : __(T.antennas.create_new)
    const { breadcrumbs, queryString } = this.props
    const fragment = !loader ? getFragmentObject(antenna, 'id', __(T.antennas.create_new)) : undefined
    return (
      <Form
        ref={this.formRef}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        onFinish={async () => {
          await this.store()
        }}
        style={{ width: '100%', height: '100%' }}
        initialValues={antenna}
        validateMessages={FormValidateMessages}
        scrollToFirstError
      >
        <StylewherePage {...this.props} noOverflow fragment={fragment}>
          <DefaultHeader
            backPath={getBackURL(queryString, breadcrumbs)}
            title={loader ? '...' : title}
            skeleton={{ active: loader }}
            actions={[
              {
                label: __(T.misc.cancel),
                type: 'cancel',
                onClick: () => navigate(`/devices/workstations${queryString ?? ''}`),
              },
              {
                label: antenna.id ? __(T.misc.update) : __(T.misc.create),
                type: 'submit',
              },
            ]}
          />
          <Section customClass="stw-section-page paged-header scroll">
            <AdvancedForm
              record={antenna}
              fields={ANTENNAS_FIELDS}
              store={this.store}
              editing={antenna.id !== undefined}
              handleChange={this.handleChange}
              parameters={{
                readerFilters: readerFilters,
                zoneFilter: rfidAntennaZoneFilter,
              }}
            />
          </Section>
        </StylewherePage>
      </Form>
    )
  }
}

export default RfidAntennaForm
