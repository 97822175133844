import { Component, createRef } from 'react'
import { Form, FormInstance } from 'antd'
import { AdvancedForm, StylewherePage, Section, DefaultHeader } from '../../../components'
import Templates from '../../../api/Templates'
import { ExtendRouteComponentProps } from '../../../types'
import { navigate } from '../../../shared/router'
import { __, T } from '../../../shared/i18n'
import { StwTemplate } from '../../../api'
import { AttributeUtil } from '../../../config/utility/utility'
import { FormUtility } from '../../../config/utility/formUtility'
import { TEMPLATES_FIELDS } from '../../../config/Pages/Templates'
import { getFragmentObject, getBackURL } from '../../../shared/utils'
import { FormValidateMessages, TemplateTypeValues, TemplateOutputTypeValues } from '../../../constants'

interface State {
  template: StwTemplate
  loader: boolean
  isCodeEditableByDescription: boolean
}

class TemplatesForm extends Component<ExtendRouteComponentProps, State> {
  formRef = createRef<FormInstance>()

  constructor(props) {
    super(props)
    this.state = {
      template: {},
      loader: true,
      isCodeEditableByDescription: true,
    }
  }

  componentDidMount() {
    this.initialize()
  }

  initialize = async () => {
    const { match } = this.props
    let template
    if (match && match.params && match.params.templateId) {
      const templateId = match.params.templateId
      if (templateId !== '' && templateId !== 'create') {
        template = await Templates.get(templateId)
      }
    }

    this.setState(
      {
        template: template ?? {},
        isCodeEditableByDescription: !template,
        loader: false,
      },
      this.updateFormFieldsValue
    )
  }

  updateFormFieldsValue = () => {
    if (this.formRef && this.formRef.current) {
      this.formRef.current.setFieldsValue(this.state.template)
    }
  }

  handleChange = (key, value) => {
    const { template, isCodeEditableByDescription } = this.state
    AttributeUtil.setAttribute(template, key, value)
    if (key === 'description' && isCodeEditableByDescription) {
      FormUtility.handleDescription(value, template, this.formRef)
    } else if (key === 'code') {
      this.setState({ isCodeEditableByDescription: false })
    }
    this.setState({
      template: template,
    })
  }

  store = () => {
    const { template } = this.state
    const { queryString } = this.props
    if (template.id) {
      Templates.update<StwTemplate>(template).then(() => {
        navigate(`/configuration/templates${queryString ?? ''}`)
      })
    } else {
      Templates.insert<StwTemplate>(template).then((newTemplate) => {
        if (newTemplate && newTemplate.id) {
          navigate(`/configuration/templates${queryString ?? ''}`)
        }
      })
    }
  }

  render() {
    const { template, loader } = this.state
    const title = template.id ? __(T.templates.edit) : __(T.templates.create_new)
    const { breadcrumbs, queryString } = this.props
    const fragment = !loader ? getFragmentObject(template, 'id', __(T.templates.create_new)) : undefined
    return (
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        onFinish={this.store}
        style={{ width: '100%', height: '100%' }}
        ref={this.formRef}
        initialValues={template}
        validateMessages={FormValidateMessages}
        scrollToFirstError
      >
        <StylewherePage {...this.props} noOverflow fragment={fragment}>
          <DefaultHeader
            backPath={getBackURL(queryString, breadcrumbs)}
            title={loader ? '...' : title}
            skeleton={{ active: loader }}
            actions={[
              {
                label: __(T.misc.cancel),
                type: 'cancel',
                onClick: () => navigate(`/configuration/templates${queryString ?? ''}`),
              },
              {
                label: template.id ? __(T.misc.update) : __(T.misc.create),
                type: 'submit',
              },
            ]}
          />
          <Section customClass="stw-section-page paged-header scroll">
            <AdvancedForm
              record={template}
              handleChange={this.handleChange}
              parameters={{ templateType: TemplateTypeValues, templateOutputType: TemplateOutputTypeValues }}
              fields={TEMPLATES_FIELDS}
              store={this.store}
            />
          </Section>
        </StylewherePage>
      </Form>
    )
  }
}

export default TemplatesForm
