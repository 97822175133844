import { AxiosRequestConfig } from 'axios'
import BaseResource, { PageResult } from './BaseResource'
import {
  api,
  MovementType,
  responseErrorCheck,
  StwFullPacking,
  StwFullParcelEntry,
  StwItem,
  StwOperation,
  StwPacking,
  StwParcel,
  StwParcelEntry,
  StwProduct,
  StwUser,
  StwZone,
} from '.'
import Parcels from './Parcels'
import Items from './Items'
import Products from './Products'
import Users from './Users'
import Zones from './Zones'
import Operations from './Operations'

export default class Packings extends BaseResource {
  static endpoint = '/api/v1/packings' ///logistics

  static async getByIdFull(id): Promise<StwFullPacking> {
    const packing = await this.get<StwPacking>(id)
    const user = await Users.get<StwUser>(packing.creationUserId)
    const zone = await Zones.get<StwZone>(packing.destinationZoneId)
    const operation = await Operations.get<StwOperation>(packing.operationId)
    //const entries: PageResult<StwParcelEntry> = await Packings.parcelEntries(packing.id, { size: 10000000 })
    return {
      ...packing,
      type: MovementType.PACKING,
      creationUser: user,
      destinationZone: zone,
      operation: operation,
    }
  }

  static async searchFull(params = {}): Promise<PageResult<StwFullPacking>> {
    const packings = await this.search<StwPacking>(params)
    const users = await Users.searchByIds<StwUser>(packings.content.map((pe) => pe.creationUserId))
    const zones = await Zones.searchByIds<StwZone>(packings.content.map((pe) => pe.destinationZoneId))
    const operations = await Operations.searchByIds<StwOperation>(packings.content.map((pe) => pe.operationId))
    const fullPackings = packings.content.map((i) => {
      return Packings.parcelEntries(i.id, { size: 10000000 }).then((entries) => {
        entries.content.forEach((pe) => {
          const entry = entries.content.filter((e) => e.productCode === pe.productCode)[0]
          entry.detected += pe.detected
          entry.unexpected += pe.unexpected
        })
        return {
          ...i,
          type: MovementType.PACKING,
          creationUser: users[`${i.creationUserId}`],
          destinationZone: zones[`${i.destinationZoneId}`],
          operation: operations[`${i.operationId}`],
        }
      })
    })
    return {
      ...packings,
      content: await Promise.all(fullPackings),
    }
  }

  static parcelEntries(
    id: string,
    params = {},
    requestConfig?: AxiosRequestConfig
  ): Promise<PageResult<StwParcelEntry>> {
    return api.get(`${this.endpoint}/${id}/parcelentries`, params, requestConfig).then(responseErrorCheck)
  }

  static async fullParcelentries(id: string, params = {}): Promise<PageResult<StwFullParcelEntry>> {
    const entries = await this.parcelEntries(id, params)
    const parcels = await Parcels.searchByIds<StwParcel>(entries.content.map((parcelEntry) => parcelEntry.parcelId))
    const items = await Items.searchByIds<StwItem>(entries.content.map((parcelEntry) => parcelEntry.itemId!))
    const products = await Products.searchByCodes<StwProduct>(
      entries.content.filter((parcelEntry) => parcelEntry.productCode).map((parcelEntry) => parcelEntry.productCode!)
    )
    const fullEntries: StwFullParcelEntry[] = entries.content.map(
      ({ parcelId, itemId, productCode, ...parcelEntry }) => ({
        ...parcelEntry,
        parcel: parcels[`${parcelId}`],
        item: items[`${itemId}`],
        product: products[`${productCode}`],
      })
    )
    return {
      ...entries,
      content: fullEntries,
    }
  }
}
