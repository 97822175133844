import { Component } from 'react'
import { Row, Col } from 'antd'
import { StylewherePage, TableList, Section } from '../../../components'
import { TabBar } from '../../../components/TabBar'
import { ATTRIBUTE_COLUMNS, ATTRIBUTE_ENTITIES } from '../../../config/Pages/EntityAttributes'
import AttributeMappings from '../../../api/AttributeMappings'
import { ExtendRouteComponentProps } from '../../../types'
import { navigate } from '../../../shared/router'
import { AdvancedTableConfig, AdvancedTableSortDirection } from '../../../components/AdvancedTable'
import { CAPABILITIES, hasAnyCapabilities } from '../../../config/utility/capabilityUtility'

interface State {
  selectedEntity: any
  tableConfig?: AdvancedTableConfig
  forceDataUpdate: number
}

class EntityAttributesList extends Component<ExtendRouteComponentProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      selectedEntity: undefined,
      forceDataUpdate: 1,
    }
  }

  componentDidMount = () => {
    this.init()
  }

  init = async () => {
    const { match } = this.props
    let selected = ATTRIBUTE_ENTITIES[0]
    if (match && match.params.entity) {
      const find = ATTRIBUTE_ENTITIES.find((element) => element.value === match.params.entity)
      if (find) {
        selected = find
      }
    }
    const cols = [...ATTRIBUTE_COLUMNS]
    if (hasAnyCapabilities(CAPABILITIES.RESOURCE_ATTRIBUTE_MAPPING_EDIT)) {
      cols.push({
        key: 'action',
        className: 'stw-extra-small',
        fixedType: 'right',
        sortable: false,
        unmanageable: true,
        reportActions: {
          onlyedit: true,
          isAttribute: true,
        },
      })
    }
    this.setState({
      selectedEntity: selected,
      tableConfig: {
        columns: cols,
        filterDefinitions: [],
        filterParameters: {},
        activeDefaultClassColumn: true,
        disableManagerColumns: true,
      },
    })
  }

  shouldComponentUpdate = (nextProps) => {
    const { selectedEntity, forceDataUpdate } = this.state
    const { match } = nextProps
    if (selectedEntity && match && match.params.entity && match.params.entity !== selectedEntity.value) {
      const find = ATTRIBUTE_ENTITIES.find((element) => element.value === match.params.entity)
      this.setState({
        selectedEntity: find,
        forceDataUpdate: forceDataUpdate + 1,
      })
    }
    return true
  }

  onTabChange = (entity) => {
    navigate(`/configuration/entityAttributes/${entity}`)
  }

  render() {
    const { selectedEntity, tableConfig, forceDataUpdate } = this.state
    const hasCapabilites = hasAnyCapabilities(CAPABILITIES.RESOURCE_ATTRIBUTE_MAPPING_EDIT)
    return (
      <Row style={{ width: '100%', height: '100%' }}>
        <Col span={5} style={{ paddingLeft: 15, height: '100%' }}>
          <Section fullheight scrollable>
            <TabBar
              tabs={ATTRIBUTE_ENTITIES}
              selected={selectedEntity ? selectedEntity.value : ''}
              onChangeTab={async (value) => {
                this.onTabChange(value)
              }}
              customClassName="stylewhere-tab-bar-vertical"
            />
          </Section>
        </Col>
        <Col span={19}>
          <StylewherePage {...this.props} noOverflow>
            {tableConfig && selectedEntity && (
              <TableList
                title={selectedEntity ? selectedEntity.label : ''}
                location={this.props.location}
                config={tableConfig}
                resource={{ call: AttributeMappings.searchTableList, endpoint: AttributeMappings.endpoint }}
                reportParams={{ entity: selectedEntity.value }}
                sortValues={{ code: AdvancedTableSortDirection.asc }}
                addAttribute={{
                  selectedEntity: selectedEntity,
                  hasCapabilites: hasCapabilites,
                }}
                forceDataUpdate={forceDataUpdate}
              />
            )}
          </StylewherePage>
        </Col>
      </Row>
    )
  }
}

export default EntityAttributesList
