import { Component } from 'react'
import { Form, Row, Col } from 'antd'
import { __ } from '../../shared/i18n'
import { SectionTitle } from './SectionTitle'
import ProductListWithAutocompletion from '../Input/ProductListWithAutocompletion'

interface Props {
  handleChange: (value) => void
  label?: string
  rules?: any[]
  name: string
  placeholder?: string
  selector?: string
  endpoint: string
  sort: string
  filters?: any
  uniqueValueError?: string
}

export class AdvancedFormInputCheckListProductList extends Component<Props> {
  render() {
    const { handleChange, rules, name, placeholder, endpoint, sort, filters, uniqueValueError } = this.props
    return (
      <>
        <Row style={{ marginTop: 20 }}>
          <Col span={24}>
            <SectionTitle title={__(`misc.${name}_title`)} marginBottom={20} />
          </Col>
        </Row>
        <Form.Item name={name.split('.')} rules={rules} className="stylewhere-form-item">
          <ProductListWithAutocompletion
            onChange={handleChange}
            placeholder={placeholder ?? __(`fields.placeholders.${this.props.name}`)}
            endpoint={endpoint}
            sort={sort}
            filters={filters}
            uniqueValueError={uniqueValueError}
          />
        </Form.Item>
      </>
    )
  }
}
