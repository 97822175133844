import { SortOrder } from 'antd/es/table/interface'
import { AdvancedTableColumns, AdvancedTableSortDirection, AdvancedTableSortValues } from './advancedTableTypes'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function advancedTableSortValuesFromLocation(location, columns: AdvancedTableColumns): AdvancedTableSortValues {
  const queryParameters = new URLSearchParams(location.search)

  if (!queryParameters || queryParameters.getAll('sort').length === 0) {
    return columns.some((column) => column.key === 'lastModifyDate')
      ? { lastModifyDate: AdvancedTableSortDirection.desc }
      : { creationDate: AdvancedTableSortDirection.desc }
  }

  return queryParameters.getAll('sort').reduce((sorts, sort) => {
    const [parameter, direction] = sort.split(',').slice(0, 2)
    return { ...sorts, [parameter]: AdvancedTableSortDirection[direction as keyof typeof AdvancedTableSortDirection] }
  }, {} as AdvancedTableSortValues)
}

export function advancedTableSortDirectionToSortOrder(sort: AdvancedTableSortDirection): SortOrder {
  switch (sort) {
    case AdvancedTableSortDirection.asc:
      return 'ascend'
    case AdvancedTableSortDirection.desc:
      return 'descend'
    default:
      return null
  }
}

export function advancedTableSortDirectionFromSortOrder(order?: SortOrder): AdvancedTableSortDirection | undefined {
  switch (order) {
    case 'ascend':
      return AdvancedTableSortDirection.asc
    case 'descend':
      return AdvancedTableSortDirection.desc
    default:
      return undefined
  }
}

export function advancedTableSortValuesToQueryParameters(sortValues: AdvancedTableSortValues) {
  return Object.keys(sortValues).map((parameter: string) => `${parameter},${sortValues![parameter]}`)
}
