import { Component } from 'react'
import { StylewherePage, TableList } from '../../../../components'
import { __, T } from '../../../../shared/i18n'
import { AdvancedTableConfig } from '../../../../components/AdvancedTable'
import { ExtendRouteComponentProps } from '../../../../types'
import { CAPABILITIES, getActionsList } from '../../../../config/utility/capabilityUtility'
import { getTabsSettings } from '../../../../config/Pages/Settings/Tabs'
import ItemTypes from '../../../../api/ItemTypes'
import { ITEM_TYPE_COLUMNS, ITEM_TYPE_FILTERS } from '../../../../config/Pages/Settings/ItemTypes'
import { IdentifierTypes } from '../../../../constants'

const ITEM_TYPE_TABLE_CONFIG: AdvancedTableConfig = {
  columns: ITEM_TYPE_COLUMNS,
  filterDefinitions: ITEM_TYPE_FILTERS,
  filterParameters: { types: IdentifierTypes },
  manageColumnsPrefix: 'itemTypes',
  activeDefaultClassColumn: true,
}

class ItemTypeList extends Component<ExtendRouteComponentProps> {
  render() {
    return (
      <StylewherePage {...this.props} noOverflow>
        <TableList
          tabs={getTabsSettings(4)}
          location={this.props.location}
          config={ITEM_TYPE_TABLE_CONFIG}
          resource={{
            call: ItemTypes.searchTableList,
            endpoint: ItemTypes.endpoint,
          }}
          actions={getActionsList(
            CAPABILITIES.RESOURCE_ITEM_TYPE_EDIT,
            __(T.itemtype.create_new),
            '/configuration/settings/itemTypes/create/form'
          )}
        />
      </StylewherePage>
    )
  }
}

export default ItemTypeList
