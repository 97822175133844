import {
  AdvancedTableColumn,
  AdvancedTableContentKind,
  AdvancedTableFilterKind,
  AdvancedTableFiltersDef,
} from '../../../components/AdvancedTable'
import {
  AdvancedFormField,
  AdvancedFormFieldBuilder,
  AdvancedFormInputType,
} from '../../../components/AdvancedForm/advancedFormTypes'
import { __ } from '../../../shared/i18n'
import { DetailCell, StwRfidReaderWithCounter } from '../../../api'

export const RFID_READERS_FILTERS: AdvancedTableFiltersDef = [
  {
    key: 'equalCodes',
    label: __('fields.labels.code'),
    type: AdvancedTableFilterKind.STRING_LIST,
    column: 'code',
    col: 8,
  },
  {
    key: 'description',
    label: __('fields.labels.description'),
    type: AdvancedTableFilterKind.STRING,
    column: 'description',
    col: 8,
  },
  {
    key: 'deviceType',
    label: __('fields.labels.deviceType'),
    type: AdvancedTableFilterKind.ENUM,
    col: 8,
    column: 'deviceType',
    select: { option: 'rfidReaderTypes' },
  },
  {
    key: 'placeIds',
    label: __('fields.labels.place'),
    type: AdvancedTableFilterKind.AUTOCOMPLETE,
    column: 'place',
    autocomplete: {
      endpoint: '/api/v1/places',
      sort: 'code,asc',
      multiple: true,
    },
    col: 8,
  },
  {
    key: 'creationDate',
    label: __('fields.labels.creationDate'),
    type: AdvancedTableFilterKind.DATE_RANGE,
    column: 'creationDate',
    col: 8,
  },
  {
    key: 'lastModifyDate',
    label: __('fields.labels.lastModifyDate'),
    type: AdvancedTableFilterKind.DATE_RANGE,
    column: 'lastModifyDate',
    col: 8,
  },
]

export const RFID_READERS_FIELDS: AdvancedFormField[] = [
  AdvancedFormFieldBuilder.buildAutocomplete(
    'place',
    '/api/v1/places',
    'code,asc',
    undefined,
    undefined,
    [{ required: true }],
    __('fields.labels.place'),
    __('fields.placeholders.place')
  ),
  {
    key: 'code',
    type: AdvancedFormInputType.UNIQUE_TEXT,
    label: __('fields.labels.code'),
    placeholder: __('fields.placeholders.code'),
    rules: [{ required: true }],
    unique: {
      endpoint: '/api/v1/rfidReaders',
      parameter: 'equalCodes',
    },
  },
  AdvancedFormFieldBuilder.buildText('description'),
  AdvancedFormFieldBuilder.buildSelect(
    'deviceType',
    'rfidReaderTypes',
    [{ required: true }],
    undefined,
    undefined,
    undefined,
    undefined,
    false,
    undefined,
    true
  ),
]

export const RFID_READERS_CAEN_SLATE_NORDIC_ID_SAMPO_NATIVE_FIELDS: AdvancedFormField[] = [
  AdvancedFormFieldBuilder.buildSelect('connectionMode', 'rfidReaderConnectionModes', [{ required: true }]),
  AdvancedFormFieldBuilder.buildText(
    'ipAddress',
    [{ required: true }],
    undefined,
    undefined,
    undefined,
    undefined,
    false,
    false,
    undefined,
    true
  ),
  AdvancedFormFieldBuilder.buildNumber('maxConnectionTimeout', [{ required: true }], { min: 0 }),
]

export const RFID_READERS_NORDIC_ID_SAMPO_LABID_FIELDS: AdvancedFormField[] = [
  AdvancedFormFieldBuilder.buildText('ipAddress', [{ required: true }]),
  AdvancedFormFieldBuilder.buildNumber('maxConnectionTimeout', [{ required: true }], { min: 0 }),
]

export const RFID_READERS_GPO_COLUMNS: AdvancedTableColumn[] = [
  {
    key: 'portNumber',
    dataIndex: 'portNumber',
    title: __('fields.labels.portNumber'),
  },
  {
    key: 'gpoMode',
    dataIndex: 'gpoMode',
    title: __('fields.labels.gpoMode'),
  },
  {
    key: 'pulseDuration',
    dataIndex: 'pulseDuration',
    title: __('fields.labels.pulseDuration'),
  },
]

export const RFID_READERS_GPO_FIELDS: AdvancedFormField[] = [
  AdvancedFormFieldBuilder.buildNumber(
    'portNumber',
    [{ required: true }],
    { min: 1, max: 512 },
    8,
    undefined,
    undefined,
    true
  ),
  AdvancedFormFieldBuilder.buildSelect(
    'gpoMode',
    'rfidReaderGpoModes',
    [{ required: true }],
    8,
    undefined,
    undefined,
    undefined,
    true
  ),
  AdvancedFormFieldBuilder.buildNumber(
    'pulseDuration',
    [{ required: false }],
    undefined,
    8,
    undefined,
    undefined,
    true
  ),
]

// IMPINJ_SPEEDWAY_OCTANE START

export const RFID_READERS_IMPINJ_SPEEDWAY_OCTANE_FIELDS: AdvancedFormField[] = [
  AdvancedFormFieldBuilder.buildText('ipAddress', [{ required: true }]),
  AdvancedFormFieldBuilder.buildNumber('maxConnectionTimeout', [{ required: true }], { min: 0 }),
  AdvancedFormFieldBuilder.buildSelect('readerMode', 'rfidReaderReaderModes', [{ required: true }]),
  AdvancedFormFieldBuilder.buildSelect('searchMode', 'rfidReaderSearchModes', [{ required: true }]),
  AdvancedFormFieldBuilder.buildSelect('session', 'rfidReaderSession', [{ required: true }]),
]

export const RFID_READERS_IMPINJ_SPEEDWAY_OCTANE_ADVANCED_FIELDS: AdvancedFormField[] = [
  AdvancedFormFieldBuilder.buildSwitch('keepAliveEnabled'),
  AdvancedFormFieldBuilder.buildNumber('keepAlivePeriod', [{ required: false }]),
  AdvancedFormFieldBuilder.buildSwitch('linkMonitorModeEnabled'),
  AdvancedFormFieldBuilder.buildNumber('linkDownThreshold', [{ required: false }]),
  AdvancedFormFieldBuilder.buildSelect('triggerTimeoutSelect', 'triggerTimeoutSelectValues', [{ required: false }]),
  {
    key: 'ports',
    label: __('fields.labels.ports'),
    type: AdvancedFormInputType.OBJECT_FORM_LIST,
    col: 24,
    uniqueValueKey: 'portNumber',
    uniqueValueError: __('misc.duplicatePortNumber'),
    placeholder: __('fields.placeholders.ports'),
    objectList: {
      endpoint: '',
      sort: 'code,asc',
      columns: RFID_READERS_GPO_COLUMNS,
      fields: RFID_READERS_GPO_FIELDS,
      canDelete: true,
    },
  },
]

export const RFID_READERS_IMPINJ_SPEEDWAY_OCTANE_TRIGGER_ENABLED_FIELDS: AdvancedFormField[] = [
  AdvancedFormFieldBuilder.buildSelect('startType', 'rfidReaderStartStopTypes', [{ required: true }]),
  AdvancedFormFieldBuilder.buildNumber('gpioStartPort', [{ required: true }], { min: 0 }),
  AdvancedFormFieldBuilder.buildSelect('stopType', 'rfidReaderStartStopTypes', [{ required: true }]),
  AdvancedFormFieldBuilder.buildNumber('gpioStopPort', [{ required: true }], { min: 0 }),
  AdvancedFormFieldBuilder.buildNumber('debounceTime', [{ required: true }], { min: 0 }),
]

export const RFID_READERS_IMPINJ_SPEEDWAY_OCTANE_TIMEOUT_ENABLED_FIELDS: AdvancedFormField[] = [
  AdvancedFormFieldBuilder.buildSelect('startType', 'rfidReaderStartStopTypes', [{ required: true }]),
  AdvancedFormFieldBuilder.buildNumber('gpioStartPort', [{ required: true }], { min: 0 }),
  AdvancedFormFieldBuilder.buildNumber('debounceTime', [{ required: true }], { min: 0 }),
  AdvancedFormFieldBuilder.buildNumber('timeout', [{ required: true }], { min: 0 }),
]

// IMPINJ_SPEEDWAY_OCTANE END

export const RFID_READERS_RFID_EMULATOR_FIELDS: AdvancedFormField[] = [
  AdvancedFormFieldBuilder.buildSelect('emulationMode', 'rfidReaderEmulationModeSelectValues', [{ required: true }]),
  AdvancedFormFieldBuilder.buildText('epcs', [{ required: false }]),
  AdvancedFormFieldBuilder.buildNumber(
    'minEpcDelay',
    [{ required: true }],
    { min: 0 },
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    1000
  ),
  AdvancedFormFieldBuilder.buildNumber(
    'maxEpcDelay',
    [{ required: true }],
    { min: 0 },
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    5000
  ),
]

// CAEN_QUATTRO START

export const RFID_READERS_CAEN_QUATTRO_FIELDS: AdvancedFormField[] = [
  AdvancedFormFieldBuilder.buildSelect('connectionMode', 'rfidReaderConnectionModes', [{ required: true }]),
  AdvancedFormFieldBuilder.buildText('ipAddress', [{ required: false }]),
  AdvancedFormFieldBuilder.buildNumber('maxConnectionTimeout', [{ required: true }], { min: 0 }),
  AdvancedFormFieldBuilder.buildSelect('memoryBank', 'rfidReaderReaderMemoryBanks', [{ required: true }]),
  AdvancedFormFieldBuilder.buildNumber('socketPort', [{ required: true }]),
]

export const RFID_READERS_CAEN_QUATTRO_ADVANCED_FIELDS: AdvancedFormField[] = [
  AdvancedFormFieldBuilder.buildNumber('gpioPolling', [{ required: true }]),
  AdvancedFormFieldBuilder.buildSelect('triggerTimeoutSelect', 'triggerTimeoutSelectValues', [{ required: false }]),
  {
    key: 'ports',
    label: __('fields.labels.ports'),
    type: AdvancedFormInputType.OBJECT_FORM_LIST,
    col: 24,
    uniqueValueKey: 'portNumber',
    uniqueValueError: __('misc.duplicatePortNumber'),
    placeholder: __('fields.placeholders.ports'),
    objectList: {
      endpoint: '',
      sort: 'code,asc',
      columns: RFID_READERS_GPO_COLUMNS,
      fields: RFID_READERS_GPO_FIELDS,
      canDelete: true,
    },
  },
]

export const RFID_READERS_CAEN_QUATTRO_TRIGGER_ENABLED_FIELDS: AdvancedFormField[] = [
  AdvancedFormFieldBuilder.buildSelect('startType', 'rfidReaderStartStopTypes', [{ required: true }]),
  AdvancedFormFieldBuilder.buildNumber('gpioStartPort', [{ required: true }], { min: 0 }),
  AdvancedFormFieldBuilder.buildSelect('stopType', 'rfidReaderStartStopTypes', [{ required: true }]),
  AdvancedFormFieldBuilder.buildNumber('gpioStopPort', [{ required: true }], { min: 0 }),
  AdvancedFormFieldBuilder.buildNumber('debounceTime', [{ required: true }], { min: 0 }),
  AdvancedFormFieldBuilder.buildText('loginUsername', [{ required: true }]),
  AdvancedFormFieldBuilder.buildText('loginPassword', [{ required: true }]),
]

export const RFID_READERS_CAEN_QUATTRO_TIMEOUT_ENABLED_FIELDS: AdvancedFormField[] = [
  AdvancedFormFieldBuilder.buildSelect('startType', 'rfidReaderStartStopTypes', [{ required: true }]),
  AdvancedFormFieldBuilder.buildNumber('gpioStartPort', [{ required: true }], { min: 0 }),
  AdvancedFormFieldBuilder.buildNumber('debounceTime', [{ required: true }], { min: 0 }),
  AdvancedFormFieldBuilder.buildNumber('timeout', [{ required: true }], { min: 0 }),
  AdvancedFormFieldBuilder.buildText('loginUsername', [{ required: true }]),
  AdvancedFormFieldBuilder.buildText('loginPassword', [{ required: true }]),
]

// CAEN_QUATTRO END

// ZEBRA_FX_SERIES START

export const RFID_READERS_ZEBRA_FX_SERIES_FIELDS: AdvancedFormField[] = [
  AdvancedFormFieldBuilder.buildText('ipAddress', [{ required: true }]),
  AdvancedFormFieldBuilder.buildNumber('maxConnectionTimeout', [{ required: true }], { min: 0 }),
  AdvancedFormFieldBuilder.buildNumber('socketPort', [{ required: false }]),
  AdvancedFormFieldBuilder.buildSlider('rxSensitivity', [{ required: false }], { min: -120, max: 120 }),
]

export const RFID_READERS_ZEBRA_FX_SERIES_ADVANCED_FIELDS: AdvancedFormField[] = [
  AdvancedFormFieldBuilder.buildSelect('triggerTimeoutSelect', 'triggerTimeoutSelectValues', [{ required: false }]),
  {
    key: 'ports',
    label: __('fields.labels.ports'),
    type: AdvancedFormInputType.OBJECT_FORM_LIST,
    col: 24,
    uniqueValueKey: 'portNumber',
    uniqueValueError: __('misc.duplicatePortNumber'),
    placeholder: __('fields.placeholders.ports'),
    objectList: {
      endpoint: '',
      sort: 'code,asc',
      columns: RFID_READERS_GPO_COLUMNS,
      fields: RFID_READERS_GPO_FIELDS,
      canDelete: true,
    },
  },
]

export const RFID_READERS_ZEBRA_FX_SERIES_TRIGGER_ENABLED_FIELDS: AdvancedFormField[] = [
  AdvancedFormFieldBuilder.buildSelect('startType', 'rfidReaderStartStopTypes', [{ required: true }]),
  AdvancedFormFieldBuilder.buildNumber('gpioStartPort', [{ required: true }], { min: 0 }),
  AdvancedFormFieldBuilder.buildSelect('stopType', 'rfidReaderStartStopTypes', [{ required: true }]),
  AdvancedFormFieldBuilder.buildNumber('gpioStopPort', [{ required: true }], { min: 0 }),
  AdvancedFormFieldBuilder.buildNumber('debounceTime', [{ required: true }], { min: 0 }),
  AdvancedFormFieldBuilder.buildText('loginUsername', [{ required: true }]),
  AdvancedFormFieldBuilder.buildText('loginPassword', [{ required: true }]),
]

export const RFID_READERS_ZEBRA_FX_SERIES_TIMEOUT_ENABLED_FIELDS: AdvancedFormField[] = [
  AdvancedFormFieldBuilder.buildSelect('startType', 'rfidReaderStartStopTypes', [{ required: true }]),
  AdvancedFormFieldBuilder.buildNumber('gpioStartPort', [{ required: true }], { min: 0 }),
  AdvancedFormFieldBuilder.buildNumber('debounceTime', [{ required: true }], { min: 0 }),
  AdvancedFormFieldBuilder.buildNumber('timeout', [{ required: true }], { min: 0 }),
  AdvancedFormFieldBuilder.buildText('loginUsername', [{ required: true }]),
  AdvancedFormFieldBuilder.buildText('loginPassword', [{ required: true }]),
]

// ZEBRA_FX_SERIES END

export const RFID_READERS_COLUMNS: AdvancedTableColumn[] = [
  {
    key: 'code',
    dataIndex: 'code',
    title: __('fields.labels.code'),
    fixedType: 'left',
  },
  {
    key: 'description',
    dataIndex: 'description',
    title: __('fields.labels.description'),
    className: 'stw-extra-medium',
  },
  {
    key: 'deviceType',
    dataIndex: 'deviceType',
    title: __('fields.labels.deviceType'),
    className: 'stw-extra-medium',
  },
  {
    key: 'numAntennas',
    dataIndex: 'numAntennas',
    className: 'stw-small',
    title: __('fields.labels.rfidAntennasNumber'),
    sortable: false,
    render: (text, record: StwRfidReaderWithCounter) => {
      return (
        <>
          <div>{record.numAntennas}</div>
        </>
      )
    },
  },
  {
    key: 'place',
    dataIndex: 'place',
    title: __('fields.labels.place'),
    sortable: false,
    type: AdvancedTableContentKind.CODE_DESC,
  },
  {
    key: 'settings.ipAddress',
    type: AdvancedTableContentKind.STRING,
  },
  {
    key: 'creationDate',
    dataIndex: 'creationDate',
    title: __('fields.labels.creationDate'),
    type: AdvancedTableContentKind.DATE_TIME,
  },
  {
    key: 'lastModifyDate',
    dataIndex: 'lastModifyDate',
    title: __('fields.labels.lastModifyDate'),
    type: AdvancedTableContentKind.DATE_TIME,
  },
]

export const RFID_READERS_DATA: DetailCell[] = [
  { title: 'id', attribute: 'id', columns: 24 },
  { title: 'code', attribute: 'code', columns: 12 },
  { title: 'description', attribute: 'description', columns: 12 },
  { title: 'place', attribute: 'place', columns: 12, type: 'codeDesc' },
  { title: 'deviceType', attribute: 'deviceType', columns: 12 },
  { title: 'Creation date', attribute: 'creationDate', type: 'date', columns: 12 },
  { title: 'Last modify date', attribute: 'lastModifyDate', type: 'date', columns: 12 },
]

export const RFID_READER_GPO_PORTS_COLUMNS: AdvancedTableColumn[] = [
  {
    key: 'portNumber',
    dataIndex: 'portNumber',
    title: __('fields.labels.portNumber'),
    type: AdvancedTableContentKind.NUMBER,
  },
  {
    key: 'pulseDuration',
    dataIndex: 'pulseDuration',
    title: __('fields.labels.pulseDuration'),
    type: AdvancedTableContentKind.NUMBER,
  },
  {
    key: 'gpoMode',
    dataIndex: 'gpoMode',
    title: __('fields.labels.gpoMode'),
  },
]

export const RFID_READER_ANTENNAS_COLUMNS: AdvancedTableColumn[] = [
  {
    key: 'code',
    dataIndex: 'code',
    className: 'stw-extra-medium',
    title: __('fields.labels.code'),
    type: AdvancedTableContentKind.STRING,
  },
  {
    key: 'description',
    dataIndex: 'description',
    className: 'stw-large',
    title: __('fields.labels.description'),
    type: AdvancedTableContentKind.STRING,
  },
  {
    key: 'zone',
    dataIndex: 'zone',
    className: 'stw-large',
    title: __('fields.labels.zone'),
    type: AdvancedTableContentKind.CODE_DESC,
  },
  {
    key: 'txPower',
    dataIndex: 'txPower',
    className: 'stw-small',
    title: __('fields.labels.txPower'),
    type: AdvancedTableContentKind.NUMBER,
  },
  {
    key: 'rxSensitivity',
    dataIndex: 'rxSensitivity',
    className: 'stw-small',
    title: __('fields.labels.rxSensitivity'),
    type: AdvancedTableContentKind.NUMBER,
  },
]
