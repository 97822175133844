import {
  AdvancedTableColumn,
  AdvancedTableContentKind,
  AdvancedTableFilterKind,
  AdvancedTableFiltersDef,
} from '../../../components/AdvancedTable'
import {
  AdvancedFormField,
  AdvancedFormFieldBuilder,
  AdvancedFormInputType,
} from '../../../components/AdvancedForm/advancedFormTypes'
import { __ } from '../../../shared/i18n'
import { DetailCell, StwFullRfidAntenna } from '../../../api'

export const ANTENNAS_FILTERS: AdvancedTableFiltersDef = [
  {
    key: 'equalCodes',
    label: __('fields.labels.code'),
    type: AdvancedTableFilterKind.STRING_LIST,
    column: 'code',
    col: 8,
  },
  {
    key: 'description',
    label: __('fields.labels.description'),
    type: AdvancedTableFilterKind.STRING,
    column: 'description',
    col: 8,
  },
  {
    key: 'readerId',
    label: __('fields.labels.rfidReader'),
    type: AdvancedTableFilterKind.AUTOCOMPLETE,
    column: 'reader.code',
    col: 8,
    autocomplete: {
      endpoint: '/api/v1/rfidReaders',
      sort: 'code,asc',
      multiple: false,
    },
  },
  {
    key: 'creationDate',
    label: __('fields.labels.creationDate'),
    type: AdvancedTableFilterKind.DATE_RANGE,
    column: 'creationDate',
    col: 8,
  },
  {
    key: 'lastModifyDate',
    label: __('fields.labels.lastModifyDate'),
    type: AdvancedTableFilterKind.DATE_RANGE,
    column: 'lastModifyDate',
    col: 8,
  },
]

export const ANTENNAS_FIELDS: AdvancedFormField[] = [
  AdvancedFormFieldBuilder.buildAutocomplete(
    'zone',
    '/api/v1/zones',
    'code,asc',
    undefined,
    undefined,
    [{ required: true }],
    __('fields.labels.zone'),
    __('fields.placeholders.zone'),
    false,
    undefined,
    undefined,
    undefined,
    'zoneFilter'
  ),
  {
    key: 'reader',
    label: __('fields.labels.rfidReader'),
    type: AdvancedFormInputType.AUTOCOMPLETE,
    placeholder: __('fields.placeholders.rfidReader'),
    autocomplete: {
      endpoint: '/api/v1/rfidReaders',
      sort: 'code,asc',
      multiple: false,
    },
    rules: [{ required: true }],
    readonly: (rfidAntenna: StwFullRfidAntenna) => rfidAntenna.zone === undefined,
    option: 'readerFilters',
  },
  {
    key: 'code',
    type: AdvancedFormInputType.UNIQUE_TEXT,
    label: __('fields.labels.code'),
    placeholder: __('fields.placeholders.code'),
    rules: [{ required: true }],
    unique: {
      endpoint: '/api/v1/rfidAntennas',
      parameter: 'equalCodes',
    },
  },
  AdvancedFormFieldBuilder.buildText('description'),
  AdvancedFormFieldBuilder.buildNumber('readerPort', [{ required: true }], { min: 1, max: 512 }),
  AdvancedFormFieldBuilder.buildSlider('txPower', [{ required: false }], { min: 10, max: 30 }),
  AdvancedFormFieldBuilder.buildSlider('rxSensitivity', [{ required: false }], { min: -80, max: 0 }),
]

export const ANTENNAS_COLUMNS: AdvancedTableColumn[] = [
  {
    key: 'code',
    dataIndex: 'code',
    title: __('fields.labels.code'),
  },
  {
    key: 'description',
    dataIndex: 'description',
    title: __('fields.labels.description'),
    className: 'stw-extra-medium',
  },
  {
    key: 'reader.code',
    dataIndex: 'reader',
    title: __('fields.labels.reader'),
    type: AdvancedTableContentKind.STRING,
  },
  {
    key: 'readerPort',
    dataIndex: 'readerPort',
    title: __('fields.labels.readerPort'),
    type: AdvancedTableContentKind.NUMBER,
  },
  {
    key: 'zone',
    dataIndex: 'zone',
    title: __('fields.labels.zone'),
    type: AdvancedTableContentKind.CODE_DESC,
  },
  {
    key: 'creationDate',
    dataIndex: 'creationDate',
    title: __('fields.labels.creationDate'),
    type: AdvancedTableContentKind.DATE_TIME,
  },
  {
    key: 'lastModifyDate',
    dataIndex: 'lastModifyDate',
    title: __('fields.labels.lastModifyDate'),
    type: AdvancedTableContentKind.DATE_TIME,
  },
]

export const ANTENNAS_DATA: DetailCell[] = [
  { title: 'id', attribute: 'id', columns: 24 },
  { title: 'code', attribute: 'code', columns: 12 },
  { title: 'description', attribute: 'description', columns: 12 },
  { title: 'zone', attribute: 'zone', columns: 12, type: 'codeDesc' },
  { title: 'reader', attribute: 'reader.code', columns: 12 },
  { title: 'readerPort', attribute: 'readerPort', columns: 12 },
  { title: 'txPower', attribute: 'txPower', columns: 12 },
  { title: 'rxSensitivity', attribute: 'rxSensitivity', columns: 12 },
  { title: 'Creation date', attribute: 'creationDate', type: 'date', columns: 12 },
  { title: 'Last modify date', attribute: 'lastModifyDate', type: 'date', columns: 12 },
]
