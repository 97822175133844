import { Component } from 'react'
import { StwNotification } from '../../api'
import { NotificationRowElement } from '..'

interface Props {
  notifications: StwNotification[]
  loader: boolean
  handleDelete: any
  handleDetail: any
  canDelete: boolean
}

export class NotificationRow extends Component<Props> {
  render() {
    const { notifications, loader, handleDelete, handleDetail, canDelete } = this.props
    return (
      <>
        {notifications.map((notification, i) => {
          return (
            <NotificationRowElement
              key={i}
              notification={notification}
              loader={loader}
              handleDelete={handleDelete}
              handleDetail={handleDetail}
              canDelete={canDelete}
            />
          )
        })}
      </>
    )
  }
}
