import { Component } from 'react'
import { Button, Card, Col, Tag, Typography } from 'antd'
import Meta from 'antd/es/card/Meta'
import { CloseOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { StwFullRfidAntenna } from '../../api'
import { __ } from '../../shared/i18n'

interface Props {
  rfidAntenna: StwFullRfidAntenna
  onRemove: () => void
  onAdd: () => void
}

const { Text } = Typography

export default class AdvancedFormWorkstationRfidAntennasFieldCard extends Component<Props> {
  render() {
    const { rfidAntenna, onRemove, onAdd } = this.props
    return (
      <Col span={6} style={{ marginTop: '25px' }}>
        {false && !rfidAntenna && (
          <Button
            style={{ height: '100%', width: '100%', borderRadius: 10 }}
            type="dashed"
            size="large"
            icon={<PlusCircleOutlined />}
            onClick={() => onAdd()}
          >
            {__('misc.add_antenna')}
          </Button>
        )}
        {rfidAntenna && (
          <Card className="stw-grouping-card-content">
            <Meta
              title={
                <div className="stw-grouping-card-content-row">
                  <div className="stw-grouping-card-content-row-info">
                    <Text strong>{rfidAntenna.code}</Text>
                    <Text>{rfidAntenna.zone ? rfidAntenna.zone.code : ''}</Text>
                    <div className="stw-grouping-card-content-row-tag">
                      <Tag>
                        <b>Tx Pw</b>: {rfidAntenna.txPower}
                      </Tag>
                      <Tag>
                        <b>Rx Sn</b>: {rfidAntenna.rxSensitivity}
                      </Tag>
                    </div>
                  </div>
                  <div className="stw-grouping-card-content-row-btn">
                    <Button
                      type="primary"
                      shape="circle"
                      size="middle"
                      icon={<CloseOutlined />}
                      onClick={() => onRemove()}
                      className="stw-workstation-rfid-antennas-card-remove-button"
                      style={{
                        float: 'right',
                        marginTop: '1.5em',
                        marginRight: '0.65em',
                      }}
                    />
                  </div>
                </div>
              }
            />
          </Card>
        )}
      </Col>
    )
  }
}
