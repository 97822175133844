import { Component } from 'react'
import { Typography } from 'antd'
import { __ } from 'core/shared/i18n'
import MonacoEditor from '@monaco-editor/react'
import { AdvancedFormField } from './advancedFormTypes'

interface Props {
  record: any
  item: AdvancedFormField
  name?: any
  handleChange: any
  readonly: boolean
  labelHidden?: boolean
  help?: any
}

const { Text } = Typography

export class AdvancedFormJsonEditorField extends Component<Props> {
  handleEditorChange = (value) => this.props.handleChange(value)

  render() {
    const { item, record } = this.props
    return (
      <>
        <Text>
          <b>{item.label || __(`fields.labels.${item.key}`)}</b>
        </Text>
        <MonacoEditor
          height="50vh"
          language="json"
          defaultValue={record[item.key] ? record[item.key] : '{}'}
          value={record[item.key] ? record[item.key] : '{}'}
          onChange={this.handleEditorChange}
          theme="vs-dark"
        />
      </>
    )
  }
}
