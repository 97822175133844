import { Component } from 'react'
import { StylewherePage, TableList } from '../../../components'
import { BALANCES_COLUMNS, BALANCES_FILTERS } from '../../../config/Pages/Devices/Balances'
import { __, T } from '../../../shared/i18n'
import { AdvancedTableConfig } from '../../../components/AdvancedTable'
import { ExtendRouteComponentProps } from '../../../types'
import { CAPABILITIES, getActionsList } from '../../../config/utility/capabilityUtility'
import Balances from '../../../api/Balances'

const DEVICE_SCALES_TABLE_CONFIG: AdvancedTableConfig = {
  columns: BALANCES_COLUMNS,
  filterDefinitions: BALANCES_FILTERS,
  manageColumnsPrefix: 'balances',
  activeDefaultClassColumn: true,
}

class BalancesList extends Component<ExtendRouteComponentProps> {
  render() {
    return (
      <StylewherePage {...this.props} noOverflow>
        <TableList
          title={__(T.menu.scales)}
          location={this.props.location}
          config={DEVICE_SCALES_TABLE_CONFIG}
          resource={{
            call: Balances.searchTableList,
            endpoint: Balances.endpoint,
          }}
          actions={getActionsList(
            CAPABILITIES.RESOURCE_BALANCE_EDIT,
            __(T.scales.create_new),
            '/devices/balances/create/form'
          )}
        />
      </StylewherePage>
    )
  }
}

export default BalancesList
