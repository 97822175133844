import { Component } from 'react'
import { Button, Col, Row } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import { SectionTitle } from './SectionTitle'
import { __ } from '../../shared/i18n'
import AdvancedFormWorkstationBalanceFieldCard from './AdvancedFormWorkstationBalanceFieldCard'

interface Props {
  balanceId?: string
  handleChange: (value) => void
  showBalanceModal: any
  placeId?: string
}

export class AdvancedFormWorkstationBalanceField extends Component<Props> {
  render() {
    const { balanceId, handleChange, showBalanceModal, placeId } = this.props
    return (
      <>
        <Row gutter={24} style={{ marginTop: 30 }}>
          <Col span={24}>
            <SectionTitle title={__('fields.labels.balance')} marginBottom={20} />
          </Col>
          <Col span={24}>
            {balanceId && (
              <AdvancedFormWorkstationBalanceFieldCard balanceId={balanceId} onRemove={() => handleChange(undefined)} />
            )}
          </Col>
        </Row>
        <Row gutter={24} style={{ marginBottom: 20 }}>
          <Col span={24}>
            <Button
              type="dashed"
              block
              disabled={placeId === ''}
              size="large"
              className="stw-grouping-card-button"
              onClick={() => showBalanceModal()}
              icon={<PlusCircleOutlined />}
            >
              <b> {balanceId ? __('misc.change_scale') : __('misc.set_scale')}</b>
            </Button>
          </Col>
        </Row>
      </>
    )
  }
}
