import { Component } from 'react'
import { Button, Card, Col, notification, Row } from 'antd'
import { CloseOutlined, EyeOutlined } from '@ant-design/icons'
import Text from 'antd/es/typography/Text'
import SelectWithAutocompletion from './SelectWithAutocompletion'
import { __, T } from '../../shared/i18n'

interface Props {
  value?: any
  onChange?: (value: any) => void
  onView?: (value: any) => void
  placeholder: string
  selector?: string
  endpoint: string
  sort: string
  filters?: any
  uniqueValueError?: string
  sizeAutocomplete?: number
  sorting?: boolean
}

interface State {
  selectWithAutocompletionValue: any
}

class CardListWithAutocompletion extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      selectWithAutocompletionValue: undefined,
    }
  }

  getFilters = () => {
    const { excludedIds, ...filters } = this.props.filters ?? {}
    return {
      ...filters,
      excludedIds: Array.from(
        new Set([...(excludedIds ?? []), ...this.getSelectedValues().map((selectedValue) => selectedValue.id)])
      ),
    }
  }

  getSelectedValues = () => {
    const { value, sorting } = this.props
    const arr = Array.isArray(value ?? []) ? value ?? [] : [value]
    return sorting ? arr.sort((a, b) => (a.code.toLowerCase() > b.code.toLowerCase() ? 1 : -1)) : arr
  }

  handleRemove = (valueToRemove: any) => {
    const { selector } = this.props
    const selectedValues = this.getSelectedValues()
    const indexToRemove = selectedValues.findIndex(
      (selectedValue) => selectedValue[selector ?? 'id'] === valueToRemove[selector ?? 'id']
    )
    selectedValues.splice(indexToRemove, 1)
    this.updateSelectedValues(selectedValues)
  }

  handleSelectWithAutocompletionChange = (selectWithAutocompletionValue) => {
    this.setState({ selectWithAutocompletionValue }, () => {
      const selectedValues = this.getSelectedValues()
      if (selectWithAutocompletionValue) {
        if (this.isSelected(selectWithAutocompletionValue, selectedValues)) {
          this.showDuplicateError()
        } else {
          this.updateSelectedValues([...selectedValues, selectWithAutocompletionValue])
          this.setState({ selectWithAutocompletionValue: undefined })
        }
      }
    })
  }

  isSelected = (value: any, selectedValues: any[]) =>
    selectedValues.some(
      (selectedValue) => selectedValue[this.props.selector ?? 'id'] === value[this.props.selector ?? 'id']
    )

  updateSelectedValues = (selectedValues: any) => {
    const { onChange } = this.props
    if (onChange) {
      onChange(selectedValues)
    }
  }

  showDuplicateError = () =>
    notification.error({
      message: __(T.misc.validationError),
      description: this.props.uniqueValueError ?? __('misc.duplicateEntries'),
      placement: 'bottomRight',
      duration: 3,
    })

  render = () => {
    const { selectWithAutocompletionValue } = this.state
    const { placeholder, selector, endpoint, sort, onView, sizeAutocomplete } = this.props
    const selectedValues = this.getSelectedValues()
    return (
      <>
        <Row gutter={24}>
          <Col span={12} style={{ marginBottom: '5px' }}>
            <SelectWithAutocompletion
              value={selectWithAutocompletionValue}
              onChange={(value: any) => this.handleSelectWithAutocompletionChange(value)}
              placeholder={placeholder}
              selector={selector}
              endpoint={endpoint}
              sort={sort}
              filters={this.getFilters()}
              sizeAutocomplete={sizeAutocomplete}
            />
          </Col>
        </Row>
        {selectedValues.length > 0 && (
          <Row gutter={[16, 16]} style={{ marginTop: '16px' }}>
            {selectedValues.map((selectedValue) => (
              <Col lg={8} md={12} xs={24}>
                <Card className="stw-grouping-card-content">
                  <div className="stw-grouping-card-content-row">
                    <div
                      className="stw-grouping-card-content-row-info"
                      style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                      <Text strong>{selectedValue.code}</Text>
                      <Text style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {selectedValue.description}
                      </Text>
                    </div>
                    {onView && (
                      <div
                        className="stw-grouping-card-content-row-btn"
                        style={{ marginRight: 5, marginBottom: 'auto', marginTop: 'auto' }}
                      >
                        <Button
                          type="primary"
                          shape="circle"
                          size="middle"
                          icon={<EyeOutlined />}
                          onClick={() => onView(selectedValue)}
                          className="stw-workstation-balance-card-remove-button"
                          style={{ float: 'right', marginTop: '1.5em', marginRight: '1.25em' }}
                        />
                      </div>
                    )}
                    <div
                      className="stw-grouping-card-content-row-btn"
                      style={{ marginBottom: 'auto', marginTop: 'auto' }}
                    >
                      <Button
                        type="primary"
                        shape="circle"
                        size="middle"
                        icon={<CloseOutlined />}
                        onClick={() => this.handleRemove(selectedValue)}
                        className="stw-workstation-balance-card-remove-button"
                        style={{ float: 'right', marginTop: '1.5em', marginRight: '1.25em' }}
                      />
                    </div>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        )}
      </>
    )
  }
}

export default CardListWithAutocompletion
