import { DeleteOutlined } from '@ant-design/icons'
import { Button, Space, Typography } from 'antd'
import { Component } from 'react'
import { AdvancedForm } from '..'
import {
  OPTION_EXPECTED_MODE,
  OPTION_INVENTORY_POPULATE_UNKNOWN_ITEMS_ACTION,
  OPTION_READING_MODE,
  OPTION_READINGS_DISPLAY_MODE,
  OPTION_SHIPPING_MODE,
  OPTION_SYNC_ASYNC,
  OPTION_TRUE_FALSE,
  OPTION_YES_NO_WARNING,
  OPTION_ENCODING_INITIALTYPE,
  OPTION_ENCODING_PRODUCTIONORDERROW_SELECTION_MODE,
  OPTION_YES_NO,
  OPTION_FORCE_ASSOCIATION,
  OPTION_IDENTIFIER_EXISTENCE,
  OPTION_TAG_SERIALIZATION,
  OPTION_UHF_WRITE,
  OPTION_PRE_ENCODING_CHECK,
  OPTION_SESSION_UPDATE_POLICY,
  POST_CONFIRM_ACTION,
  PERSIST_PARCEL_CONTENT_SCANNINGS,
  READINGS_REMOVE_MODE,
  START_ASN_SCREEN
} from '../../config/Pages/Settings/OperationOptions'
import { __ } from '../../shared/i18n'

interface Props {
  option: any
  record: any
  handleChangeOptions: any
  handleRemoveOption: any
  parameters: any
}

const { Text } = Typography

export class AdvancedFormInlineOptionSingle extends Component<Props> {
  timerUpdates
  lastData

  handleChange = (key, value) => {
    if (this.timerUpdates) {
      clearTimeout(this.timerUpdates)
    }
    this.lastData = {
      key: key,
      value: value,
    }
    this.timerUpdates = setTimeout(() => {
      this.timerHandleChange()
    }, 500)
  }

  timerHandleChange = () => {
    const { handleChangeOptions } = this.props
    handleChangeOptions(this.lastData.key, this.lastData.value)
  }

  render() {
    const { option, record, handleRemoveOption, parameters } = this.props
    return option.data ? (
      <div className="stylewhere-editable-table-row">
        <div className="stylewhere-editable-table-row-column">
          <Space direction="vertical" size="small">
            <Text className="ant-form-item-required" strong>
              {__(`fields.labels.${option.optionKey || ''}`, option.optionKey || '')}
              {/*<Text style={{ color: '#ff4d4f', marginLeft: 5 }}>*</Text>*/}
            </Text>
            {option.data.description && option.data.description !== '' && (
              <Text style={{ fontSize: 13, lineHeight: '17px', display: 'inline-block' }}>
                {option.data.description}
              </Text>
            )}
          </Space>
        </div>
        <div className="stylewhere-editable-table-row-column">
          <AdvancedForm
            record={record}
            handleChange={(k, v) => this.handleChange(k, v)}
            parameters={{
              ...parameters,
              yes_no_warning: OPTION_YES_NO_WARNING,
              true_false: OPTION_TRUE_FALSE,
              sync_async: OPTION_SYNC_ASYNC,
              expected_mode: OPTION_EXPECTED_MODE,
              readings_display_mode: OPTION_READINGS_DISPLAY_MODE,
              shipping_mode: OPTION_SHIPPING_MODE,
              reading_mode: OPTION_READING_MODE,
              inventory_populate_unknown_items_action: OPTION_INVENTORY_POPULATE_UNKNOWN_ITEMS_ACTION,
              encoding_initialtype: OPTION_ENCODING_INITIALTYPE,
              productionOrderRowSelectionMode: OPTION_ENCODING_PRODUCTIONORDERROW_SELECTION_MODE,
              identifierExistence: OPTION_IDENTIFIER_EXISTENCE,
              uhfWrite: OPTION_UHF_WRITE,
              forceAssociation: OPTION_FORCE_ASSOCIATION,
              yes_no: OPTION_YES_NO,
              tagSerialization: OPTION_TAG_SERIALIZATION,
              transitZoneFilters: { type: 'STOCK' },
              preEncodingCheck: OPTION_PRE_ENCODING_CHECK,
              session_update_policy: OPTION_SESSION_UPDATE_POLICY,
              post_confirm_action: POST_CONFIRM_ACTION,
              persist_parcel_content_scannings:PERSIST_PARCEL_CONTENT_SCANNINGS,
              readings_remove_mode:READINGS_REMOVE_MODE,
              start_asn_screen:START_ASN_SCREEN
            }}
            fields={[option.data]}
            help={<span />}
            labelHidden
          />
        </div>
        <div className="stylewhere-editable-table-row-action">
          {!option.id && (
            <Button className="stylewhere-button-default" onClick={() => handleRemoveOption(option.optionKey)}>
              <DeleteOutlined />
            </Button>
          )}
        </div>
      </div>
    ) : null
  }
}
