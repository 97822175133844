import {
  AdvancedTableColumn,
  AdvancedTableContentKind,
  AdvancedTableFilterKind,
  AdvancedTableFiltersDef,
} from '../../components/AdvancedTable'
import {
  AdvancedFormField,
  AdvancedFormFieldBuilder,
  AdvancedFormInputType,
} from '../../components/AdvancedForm/advancedFormTypes'
import { __ } from '../../shared/i18n'
import { AdvancedTableRowColumn } from '../../components'
import { DetailCell } from '../../api'
import { CAPABILITIES, getActionsTableColumns } from '../utility/capabilityUtility'

export const TEMPLATES_FILTERS: AdvancedTableFiltersDef = [
  {
    key: 'equalCodes',
    label: __('fields.labels.code'),
    col: 8,
    type: AdvancedTableFilterKind.STRING_LIST,
    column: 'code',
  },
  {
    key: 'description',
    label: __('fields.labels.description'),
    type: AdvancedTableFilterKind.STRING,
    column: 'description',
    col: 8,
  },
  {
    key: 'templateType',
    label: __('fields.labels.templateType'),
    type: AdvancedTableFilterKind.ENUM,
    column: 'templateType',
    select: {
      option: 'templateType',
    },
    col: 8,
  },
  {
    key: 'outputType',
    label: __('fields.labels.outputType'),
    type: AdvancedTableFilterKind.ENUM,
    column: 'outputType',
    select: {
      option: 'templateOutputType',
    },
    col: 8,
  },
  {
    key: 'creationDate',
    label: __('fields.labels.creationDate'),
    type: AdvancedTableFilterKind.DATE_RANGE,
    column: 'creationDate',
    col: 8,
  },
  {
    key: 'lastModifyDate',
    label: __('fields.labels.lastModifyDate'),
    type: AdvancedTableFilterKind.DATE_RANGE,
    column: 'lastModifyDate',
    col: 8,
  },
]

export const TEMPLATES_FIELDS: AdvancedFormField[] = [
  AdvancedFormFieldBuilder.buildText('description', [{ required: true }]),
  {
    key: 'code',
    type: AdvancedFormInputType.UNIQUE_TEXT,
    label: __('fields.labels.code'),
    placeholder: __('fields.placeholders.code'),
    rules: [{ required: true }],
    unique: {
      endpoint: '/api/v1/templates',
      parameter: 'equalCodes',
    },
  },
  AdvancedFormFieldBuilder.buildSelect('templateType', 'templateType', [{ required: true }]),
  AdvancedFormFieldBuilder.buildSelect('outputType', 'templateOutputType', [{ required: true }]),
  {
    key: 'templateBody',
    col: 18,
    rows: 30,
    type: AdvancedFormInputType.TEXTAREA,
    label: __('fields.labels.templateBody'),
    placeholder: __('fields.placeholders.templateBody'),
    rules: [{ required: true }],
  },
]

export const TEMPLATES_COLUMNS: Array<AdvancedTableColumn> = [
  {
    title: __(`fields.labels.code`),
    key: 'code',
    dataIndex: 'code',
    fixedType: 'left',
    className: 'stw-medium',
  },
  {
    title: __(`fields.labels.description`),
    key: 'description',
    dataIndex: 'description',
    className: 'stw-large',
  },
  {
    title: __(`fields.labels.templateType`),
    key: 'templateType',
    dataIndex: 'templateType',
    className: 'stw-medium',
  },
  {
    title: __(`fields.labels.outputType`),
    key: 'outputType',
    dataIndex: 'templateType',
    className: 'stw-medium',
  },
  {
    title: __(`fields.labels.creationUser`),
    key: 'creationUser.username',
    dataIndex: 'creationUser.username',
    className: 'stw-extra-medium',
  },
  {
    key: 'creationDate',
    title: __(`fields.labels.creationDate`),
    dataIndex: 'creationDate',
    type: AdvancedTableContentKind.DATE_TIME,
  },
  {
    key: 'lastModifyDate',
    title: __(`fields.labels.lastModifyDate`),
    dataIndex: 'lastModifyDate',
    type: AdvancedTableContentKind.DATE_TIME,
  },
  {
    key: 'action',
    width: 'small',
    fixedType: 'right',
    sortable: false,
    unmanageable: true,
    className: 'stw-small',
    render: (text, record) => (
      <AdvancedTableRowColumn
        actions={getActionsTableColumns(record.id, '/configuration/templates', CAPABILITIES.RESOURCE_TEMPLATES_EDIT)}
      />
    ),
  },
]

export const TEMPLATES_DETAIL: DetailCell[] = [
  { title: __(`fields.labels.id`), attribute: 'id', columns: 12 },
  { title: __(`fields.labels.code`), attribute: 'code', columns: 12 },
  { title: __(`fields.labels.description`), attribute: 'description', columns: 12 },
  { title: __(`fields.labels.templateType`), attribute: 'templateType', columns: 12 },
  { title: __(`fields.labels.outputType`), attribute: 'outputType', columns: 12 },
  { title: __(`fields.labels.templateBody`), attribute: 'templateBody', columns: 24 },
  { title: __(`fields.labels.creationDate`), attribute: 'creationDate', type: 'date', columns: 12 },
  { title: __(`fields.labels.lastModifyDate`), attribute: 'lastModifyDate', type: 'date', columns: 12 },
]
