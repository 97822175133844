import { Component } from 'react'
import { StylewherePage, TableList } from '../../../components'
import { __, T } from '../../../shared/i18n'
import { AdvancedTableConfig, AdvancedTableColumn } from '../../../components/AdvancedTable'
import { ExtendRouteComponentProps } from '../../../types'
import { CAPABILITIES, getActionsList } from '../../../config/utility/capabilityUtility'
import { SCANNERS_COLUMNS, SCANNERS_FILTERS } from '../../../config/Pages/Devices/Scanners'
import Scanners from '../../../api/Scanners'
import { scannerDeviceControllerTypeFilters } from '../../../constants'
import { getTableActionsReport } from '../../../shared/utils'

interface State {
  tableConfig: AdvancedTableConfig | undefined
}

class ScannersList extends Component<ExtendRouteComponentProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      tableConfig: undefined,
    }
  }

  componentDidMount = async () => {
    this.setState({
      tableConfig: {
        columns: this.getTableColumns(),
        filterDefinitions: SCANNERS_FILTERS,
        filterParameters: {
          deviceControllerTypeFilters: scannerDeviceControllerTypeFilters,
        },
        manageColumnsPrefix: 'barcodeReaders',
        activeDefaultClassColumn: true,
      },
    })
  }

  getTableColumns = () => {
    const t: AdvancedTableColumn[] = [
      ...SCANNERS_COLUMNS,
      {
        key: 'action',
        width: 'small',
        fixedType: 'right',
        unmanageable: true,
        hidden: false,
        sortable: false,
        reportActions: getTableActionsReport('scanners'),
        basePath: '/devices/scanners',
      },
    ]
    return t
  }

  render() {
    const { tableConfig } = this.state
    return (
      <StylewherePage {...this.props} noOverflow>
        {tableConfig && (
          <TableList
            title={__(T.menu.scanners)}
            location={this.props.location}
            config={tableConfig}
            resource={{
              call: Scanners.searchTableList,
              endpoint: Scanners.endpoint,
            }}
            actions={getActionsList(
              CAPABILITIES.RESOURCE_SCANNER_EDIT,
              __(T.scanners.create_new),
              '/devices/scanners/create/form'
            )}
            deleteRecord={{
              call: Scanners.deleteTableList,
              endpoint: Scanners.endpoint,
              title: `${__(T.misc.delete_record)} ${__(T.scanners.scanner)}?`,
              subtitle: __(T.misc.delete_noundo),
            }}
          />
        )}
      </StylewherePage>
    )
  }
}

export default ScannersList
