import BaseResource, { PageResult } from './BaseResource'
import { StwBalance, StwFullWorkstation, StwPlace, StwRfidAntenna, StwWorkstation, StwZone } from './types'
import Places from './Places'
import RfidAntennas from './RfidAntennas'
import Balances from './Balances'
import Zones from './Zones'

export default class Workstations extends BaseResource {
  static endpoint = '/api/v1/workstations' ///edge

  static async searchFull(params = {}): Promise<PageResult<StwFullWorkstation>> {
    const workstations = await Workstations.search<StwWorkstation>(params)
    const places = await Places.searchByIds(workstations.content.map((workstation) => workstation.placeId))
    const rfidAntennas = await RfidAntennas.searchByIds(
      workstations.content.flatMap((workstation) => workstation.rfidAntennaIds)
    )
    const balances = await Balances.searchByIds(workstations.content.map((workstation) => workstation.balanceId))
    return {
      ...workstations,
      content: workstations.content.map(({ placeId, rfidAntennaIds, balanceId, ...workstation }) => ({
        ...workstation,
        place: places[`${placeId}`],
        rfidAntennas: rfidAntennaIds ? rfidAntennaIds.map((rfidAntennaId) => rfidAntennas[`${rfidAntennaId}`]) : [],
        balance: balances[`${balanceId}`],
      })),
    }
  }

  static async getFullWorkstation(recordId: string): Promise<StwFullWorkstation> {
    const workstation = await this.get<StwWorkstation>(recordId)

    const places = await Places.searchByIds<StwPlace>([workstation.placeId])
    const balances = await Balances.searchByIds<StwBalance>([workstation.balanceId])
    const rfidAntennas = await RfidAntennas.searchByIds<StwRfidAntenna>(workstation.rfidAntennaIds!)
    const rfidAntennasWorkstation = workstation.rfidAntennaIds
      ? workstation.rfidAntennaIds.map((rfidAntennaId) => rfidAntennas[`${rfidAntennaId}`])
      : []
    const zones = rfidAntennasWorkstation
      ? await Zones.searchByIds<StwZone>(rfidAntennasWorkstation.map((rfidAntenna) => rfidAntenna.zoneId))
      : []
    return {
      ...workstation,
      place: places[`${workstation.placeId}`],
      balance: balances[`${workstation.balanceId}`],
      rfidAntennas:
        rfidAntennasWorkstation.length > 0
          ? rfidAntennasWorkstation.map(({ zoneId, ...rfidAntenna }) => ({
              ...rfidAntenna,
              zone: zoneId ? zones[`${zoneId}`] : undefined,
            }))
          : [],
    }
  }
}
