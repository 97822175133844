import { Component } from 'react'
import { Button } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import { navigate } from '../shared/router'
import { __ } from '../shared/i18n'

interface Props {
  path: string
  label?: string
}

export class ButtonNewEntry extends Component<Props> {
  render() {
    const { path, label } = this.props
    return (
      <Button className="stylewhere-button-primary" onClick={() => navigate(path)} key="1">
        <PlusCircleOutlined /> {label || __('misc.add_new')}
      </Button>
    )
  }
}
