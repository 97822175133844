import { Component, createRef } from 'react'
import { Form, FormInstance } from 'antd'
import { AdvancedForm, StylewherePage, DefaultHeader, Section } from '../../../components'
import { __, T } from '../../../shared/i18n'
import { StwChecklist, StwChecklistType, StwCreateChecklistCommandUI, StwProduct } from '../../../api'
import { ExtendRouteComponentProps } from '../../../types'
import { AttributeUtil } from '../../../config/utility/utility'
import { FormUtility } from '../../../config/utility/formUtility'
import { navigate } from '../../../shared/router'
import { CheckListTypes, FormValidateMessages, OperationTypes } from '../../../constants'
import Checklists from '../../../api/Checklists'
import { checklistFields } from '../../../config/Pages/Checklists'
import { getBackURL } from '../../../shared/utils'

interface State {
  checklist: StwCreateChecklistCommandUI
  loader: boolean
  isCodeEditableByDescription: boolean
}

class ChecklistForm extends Component<ExtendRouteComponentProps, State> {
  formRef = createRef<FormInstance>()

  constructor(props) {
    super(props)
    this.state = {
      checklist: {
        items: [],
        attributes: {},
        createParcel: false,
        createAsn: false,
        type: StwChecklistType.CHECKLIST_BY_ITEM,
      },
      loader: true,
      isCodeEditableByDescription: true,
    }
  }

  componentDidMount = async () => {
    const checklist: StwCreateChecklistCommandUI = {
      items: [],
      attributes: {},
      createParcel: false,
      createAsn: false,
      type: StwChecklistType.CHECKLIST_BY_PRODUCT_TYPE,
    }
    this.setState({
      loader: false,
      checklist: checklist,
    })
  }

  handleChange = async (key, value) => {
    const { checklist, isCodeEditableByDescription } = this.state
    AttributeUtil.setAttribute(checklist, key, value)
    if (key === 'description' && isCodeEditableByDescription) {
      FormUtility.handleDescription(value, checklist, this.formRef)
    } else if (key === 'code') {
      this.setState({ isCodeEditableByDescription: false })
    } else if (key === 'type') {
      if (StwChecklistType.CHECKLIST_BY_ITEM === value) {
        checklist.products = undefined
      } else {
        checklist.items = undefined
      }
    } else if (key === 'operationType') {
      FormUtility.setFieldValue(checklist, 'operationId', undefined, this.formRef)
    } else if (key === 'attributes') {
      if (this.formRef && this.formRef.current) {
        this.formRef.current.setFieldsValue({ attributes: checklist.attributes })
      }
    }
    this.setState({
      checklist: checklist,
    })
  }

  store = () => {
    const { checklist } = this.state
    Checklists.insert<StwChecklist>(
      checklist.type === StwChecklistType.CHECKLIST_BY_ITEM
        ? {
            attributes: checklist.attributes,
            checklistPlaceId: checklist.checklistPlaceId,
            code: checklist.code,
            createAsn: checklist.createAsn,
            createParcel: checklist.createParcel,
            destinationPlaceId: checklist.destinationPlaceId,
            operationId: checklist.operationId,
            operationType: checklist.operationType,
            type: checklist.type,
            itemIds: checklist.items!.map((item) => item.id),
          }
        : {
            attributes: checklist.attributes,
            checklistPlaceId: checklist.checklistPlaceId,
            code: checklist.code,
            createAsn: checklist.createAsn,
            createParcel: checklist.createParcel,
            destinationPlaceId: checklist.destinationPlaceId,
            operationId: checklist.operationId,
            operationType: checklist.operationType,
            type: checklist.type,
            products: checklist.products!.map(({ quantity, product }) => ({
              quantity: quantity,
              productCode: product.code!,
            })),
          }
    ).then((value) => this.redirect(value))
  }

  private redirect(checklist: StwProduct) {
    if (checklist && checklist.id) {
      navigate(`/reports/checklist${this.props.queryString ?? ''}`)
    }
  }

  render() {
    const { checklist, loader } = this.state
    const { breadcrumbs, queryString } = this.props
    const title = __(T.checklist.create_new)
    const fragment = [{ label: __(T.checklist.create_new), active: true }]
    return (
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        onFinish={this.store}
        style={{ width: '100%', height: '100%' }}
        ref={this.formRef}
        initialValues={checklist}
        validateMessages={FormValidateMessages}
        scrollToFirstError
      >
        <StylewherePage {...this.props} noOverflow fragment={fragment}>
          <DefaultHeader
            backPath={getBackURL(queryString, breadcrumbs)}
            title={loader ? '...' : title}
            skeleton={{ active: loader }}
            actions={[
              {
                label: __(T.misc.cancel),
                type: 'cancel',
                onClick: () => navigate(`/reports/checklist${queryString ?? ''}`),
              },
              {
                label: __(T.misc.create),
                type: 'submit',
              },
            ]}
          />
          <Section customClass="stw-section-page paged-header scroll">
            <AdvancedForm
              record={checklist}
              handleChange={this.handleChange}
              formRef={this.formRef}
              fields={checklistFields(checklist)}
              editing={checklist.code !== ''}
              parameters={{
                operationTypes: OperationTypes,
                checklistType: CheckListTypes,
                operationFilter: { type: checklist.operationType },
              }}
            />
          </Section>
        </StylewherePage>
      </Form>
    )
  }
}

export default ChecklistForm
