import { Component } from 'react'
import { Form } from 'antd'
import { __ } from '../../shared/i18n'
import CardListWithAutocompletion from '../Input/CardListWithAutocompletion'

interface Props {
  handleChange: (value: any) => void
  handleView?: (value: any) => void
  label?: string
  rules?: any[]
  name: string
  placeholder?: string
  selector?: string
  endpoint: string
  sort: string
  filters?: any
  uniqueValueError?: string
  sizeAutocomplete?: number
  sorting?: boolean
}

export class AdvancedFormInputObjectListWithCards extends Component<Props> {
  getLabel = () => this.props.label ?? __(`fields.labels.${this.props.name}`)

  render() {
    const {
      handleChange,
      handleView,
      rules,
      sizeAutocomplete,
      name,
      placeholder,
      selector,
      endpoint,
      sort,
      filters,
      uniqueValueError,
      sorting,
    } = this.props
    return (
      <Form.Item name={name.split('.')} rules={rules} label={this.getLabel()} className="stylewhere-form-item">
        <CardListWithAutocompletion
          onChange={handleChange}
          onView={handleView}
          placeholder={placeholder ?? __(`fields.placeholders.${name}`)}
          selector={selector}
          endpoint={endpoint}
          sort={sort}
          filters={filters}
          uniqueValueError={uniqueValueError}
          sizeAutocomplete={sizeAutocomplete}
          sorting={sorting}
        />
      </Form.Item>
    )
  }
}
