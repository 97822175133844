import { Button, Col, Form, FormInstance, Row, notification } from 'antd'
import { Component, createRef } from 'react'
import { DeleteOutlined } from '@ant-design/icons'
import { __ } from '../../shared/i18n'
import { SectionTitle } from './SectionTitle'
import { AdvancedTable, AdvancedTableColumns } from '../AdvancedTable'
import { AdvancedTableRowColumn } from '../AdvancedTableRowColumn'
import { AttributeUtil } from '../../config/utility/utility'
import { AdvancedFormField } from './advancedFormTypes'
import { AdvancedForm } from '../AdvancedForm'
import { FormValidateMessages } from '../../constants'
import { Section } from '../Section'

interface Props {
  record: any
  handleChange: (value) => void
  propertyId?: string
  canDelete?: boolean
  columns: AdvancedTableColumns
  fields: AdvancedFormField[]
  attribute: string
  parameters: string
  uniqueValueKey?: string | null
  uniqueValueError?: string
}

interface State {
  currentValue: {
    newValue: any
    values: any[]
  }
  columns: AdvancedTableColumns
}

export class AdvancedFormInputObjectFormList extends Component<Props, State> {
  formRef = createRef<FormInstance>()

  constructor(props) {
    super(props)
    this.state = {
      currentValue: {
        newValue: {},
        values: [],
      },
      columns: this.props.columns,
    }
  }

  componentDidMount() {
    const { canDelete, record, columns, attribute } = this.props
    if (canDelete) {
      const values = AttributeUtil.getAttribute(record, attribute) || []
      this.setState({
        currentValue: {
          values: values,
          newValue: {},
        },
        columns: [
          ...columns,
          {
            key: 'action',
            unmanageable: true,
            hidden: false,
            sortable: false,
            fixedType: 'right',
            render: (text, r) => (
              <AdvancedTableRowColumn
                actions={[{ content: <DeleteOutlined />, type: 'default', onclick: () => this.removeValue(r) }]}
              />
            ),
          },
        ],
      })
    }
  }

  handleChangeData = (key, value) => {
    const { currentValue } = this.state
    AttributeUtil.setAttribute(currentValue.newValue, key, value)
    this.setState({
      currentValue: {
        values: this.state.currentValue.values,
        newValue: currentValue.newValue,
      },
    })
  }

  add = () => {
    const { currentValue } = this.state
    const { handleChange, fields, uniqueValueKey, uniqueValueError } = this.props
    if (this.formRef.current) {
      const keys: string[] = fields.map((value) => value.key)
      this.formRef.current.validateFields(keys).then(() => {
        if (currentValue && currentValue.newValue) {
          if (uniqueValueKey && currentValue.newValue[uniqueValueKey]) {
            const index = currentValue.values.findIndex(
              (object) => object[uniqueValueKey] === currentValue.newValue[uniqueValueKey]
            )
            if (index >= 0) {
              //toast
              notification.error({
                message: __('misc.validationError'),
                description: uniqueValueError,
                placement: 'bottomRight',
                duration: 3,
              })
              return
            }
          }
          const newList = [...currentValue.values, currentValue.newValue]
          this.setState(
            {
              currentValue: {
                values: newList,
                newValue: {},
              },
            },
            () => {
              if (this.formRef.current) {
                this.formRef.current.resetFields(keys)
              }
            }
          )
          handleChange(newList)
        }
      })
    }
  }

  removeValue = (value: any) => {
    if (value) {
      const { handleChange } = this.props
      const { currentValue } = this.state

      const newList = currentValue.values.filter((v) => v !== value)
      this.setState({
        currentValue: {
          values: newList,
          newValue: {},
        },
      })
      handleChange([...newList])
    }
  }

  render() {
    const { currentValue, columns } = this.state
    const { attribute, fields, parameters } = this.props

    return (
      <Row style={{ marginTop: 20, marginBottom: 20 }}>
        <Col span={24}>
          <SectionTitle title={__(`misc.${attribute}_title`)} marginBottom={20} />
        </Col>
        {currentValue.values && currentValue.values.length > 0 && (
          <Col span={24}>
            <AdvancedTable
              pagination={{
                pageNumber: 1,
                pageSize: currentValue.values.length,
                sortValues: {},
              }}
              columns={columns}
              filters={{
                filterDefinitions: [],
              }}
              content={currentValue.values}
              totalElements={currentValue.values.length}
            />
          </Col>
        )}
        <Col span={24}>
          <Form
            ref={this.formRef}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            layout="vertical"
            onFinish={() => this.add()}
            style={{ width: '100%' }}
            initialValues={currentValue.newValue}
            validateMessages={FormValidateMessages}
          >
            <div className="stw-nested-form">
              <div className="stw-nested-form-input">
                <Section>
                  <AdvancedForm
                    key="nested-form"
                    fields={fields}
                    width="100%"
                    record={currentValue.newValue}
                    parameters={parameters}
                    handleChange={(key, value) => this.handleChangeData(key, value)}
                  />
                </Section>
              </div>
              <div className="stw-inner-form-add">
                <Button
                  disabled={!currentValue.newValue}
                  ghost={!currentValue.newValue}
                  className="stylewhere-button-secondary"
                  onClick={() => this.add()}
                  block
                >
                  {__('misc.add_new')}
                </Button>
              </div>
            </div>
          </Form>
        </Col>
      </Row>
    )
  }
}
