import { Component } from 'react'
import { Form, Input } from 'antd'
import { Rule } from 'antd/lib/form'
import AutocompleteApi from '../../api/AutocompleteApi'
import { __ } from '../../shared/i18n'

interface Props {
  record: any
  name: string
  label?: string
  rules?: Rule[]
  placeholder?: string
  handleChange: any
  endpoint: string
  parameter: string
  errorMessage?: string
  readonly?: boolean
}

class AdvancedFormUniqueInputField extends Component<Props> {
  checkUniquenessTimeout?: any
  checkUniquenessIdentifier = Math.random()

  checkUniquenessWithDelay = (delay: number) => (rule: any, value: any, callback: any) => {
    if (this.checkUniquenessTimeout) {
      clearTimeout(this.checkUniquenessTimeout)
    }
    this.checkUniquenessTimeout = setTimeout(() => this.checkUniqueness(rule, value, callback), delay)
  }

  checkUniqueness = async (rule: any, value: any, callback: any) => {
    this.checkUniquenessIdentifier = Math.random()
    const currentUniquenessIdentifier = this.checkUniquenessIdentifier
    const values = await this.getEqualValues(value)
    if (currentUniquenessIdentifier === this.checkUniquenessIdentifier && values !== undefined && values.length > 0) {
      const fieldName = __(`fields.label.${this.props.name}`, this.props.label)
      return callback(new Error(this.props.errorMessage ?? `${fieldName} already exists.`))
    }
    return callback()
  }

  getEqualValues = async (value?: any) => {
    let values: any[] = []
    if (value) {
      values = await AutocompleteApi.search(this.props.endpoint, {
        [this.props.parameter]: value,
        excludedIds: this.props.record.id ? [this.props.record.id] : undefined,
      }).then((response) => response.content)
    }
    return values
  }

  render() {
    const { readonly } = this.props
    return (
      <Form.Item
        name={this.props.name.split('.')}
        className="stylewhere-form-item"
        rules={[...(this.props.rules ?? []), { validator: this.checkUniquenessWithDelay(300) }]}
        label={__(`fields.label.${this.props.name}`, this.props.label)}
      >
        <Input
          readOnly={readonly}
          disabled={readonly}
          id={this.props.name}
          placeholder={__(`fields.placeholder.${this.props.name}`, this.props.placeholder)}
          onChange={(e) => this.props.handleChange(e)}
        />
      </Form.Item>
    )
  }
}

export default AdvancedFormUniqueInputField
