import { Component, createRef } from 'react'
import { Form, FormInstance } from 'antd'
import { StwConveyor } from '../../../api'
import { ExtendRouteComponentProps } from '../../../types'
import { __, T } from '../../../shared/i18n'
import { AttributeUtil } from '../../../config/utility/utility'
import { navigate } from '../../../shared/router'
import { AdvancedForm, StylewherePage, DefaultHeader, Section } from '../../../components'
import { getFragmentObject, getBackURL } from '../../../shared/utils'
import Conveyors from '../../../api/Conveyors'
import { CONVEYOR_FIELDS } from '../../../config/Pages/Devices/Conveyors'
import { FormValidateMessages, ConveyorsOperationFilters } from '../../../constants'

interface State {
  conveyor: StwConveyor
  loader: boolean
}

class ConveyorForm extends Component<ExtendRouteComponentProps, State> {
  formRef = createRef<FormInstance>()

  constructor(props) {
    super(props)
    this.state = {
      conveyor: {},
      loader: true,
    }
  }

  componentDidMount = async () => {
    let result
    if (this.props.match) {
      const conveyorId = this.props.match.params.conveyorId
      if (conveyorId !== '' && conveyorId !== 'create') {
        result = await Conveyors.get<StwConveyor>(conveyorId)
      }
    }
    this.setState(
      {
        conveyor: result || {},
        loader: false,
      },
      this.updateFormFieldsValue
    )
  }

  updateFormFieldsValue = () => {
    if (this.formRef && this.formRef.current) {
      this.formRef.current.setFieldsValue(this.state.conveyor)
    }
  }

  handleChange = (key, value) => {
    const { conveyor } = this.state
    AttributeUtil.setAttribute(conveyor, key, value)
    this.setState(
      {
        conveyor: conveyor,
      },
      this.updateFormFieldsValue
    )
  }

  store = () => {
    const { conveyor } = this.state
    if (conveyor.id) {
      Conveyors.update<StwConveyor>(conveyor).then((value) => {
        this.redirect(value)
      })
    } else {
      Conveyors.insert<StwConveyor>(conveyor).then((value) => {
        this.redirect(value)
      })
    }
  }

  redirect(controller: StwConveyor) {
    if (controller && controller.id) {
      navigate(`/devices/conveyors${this.props.queryString ?? ''}`)
    }
  }

  render() {
    const { conveyor, loader } = this.state
    const { breadcrumbs, queryString } = this.props
    const title = conveyor.id ? __(T.conveyors.edit) : __(T.conveyors.create_new)
    const fragment = !loader ? getFragmentObject(conveyor, 'id', __(T.conveyors.create_new)) : undefined
    return (
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        onFinish={this.store}
        style={{ width: '100%', height: '100%' }}
        initialValues={conveyor}
        validateMessages={FormValidateMessages}
        scrollToFirstError
        ref={this.formRef}
      >
        <StylewherePage {...this.props} noOverflow fragment={fragment}>
          <DefaultHeader
            backPath={getBackURL(queryString, breadcrumbs)}
            title={loader ? '...' : title}
            skeleton={{ active: loader }}
            actions={[
              {
                label: __(T.misc.cancel),
                type: 'cancel',
                onClick: () => navigate(`/devices/conveyors${queryString ?? ''}`),
              },
              {
                label: conveyor.id ? __(T.misc.update) : __(T.misc.create),
                type: 'submit',
              },
            ]}
          />
          <Section customClass="stw-section-page paged-header scroll">
            <AdvancedForm
              record={conveyor}
              fields={CONVEYOR_FIELDS}
              store={this.store}
              editing={conveyor.id !== undefined}
              handleChange={this.handleChange}
              parameters={{
                operationTypeFilters: ConveyorsOperationFilters,
              }}
            />
          </Section>
        </StylewherePage>
      </Form>
    )
  }
}

export default ConveyorForm
