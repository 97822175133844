import { Component } from 'react'
import { Menu, Button } from 'antd'
import { MenuEntryType, SubmenuEntryType } from '../../types'
import { __ } from '../../shared/i18n'
import { hasAnyCapabilities } from '../../config/utility/capabilityUtility'
import { SidebarMenuNotification } from '..'

interface Props {
  menu: MenuEntryType[]
  collapsed: boolean
  selected: string
  submenuSelected?: SubmenuEntryType
  handleClick: any
  seeAllHandler: any
  handleNotificationDetail: any
}

const { SubMenu } = Menu

export class SidebarMenu extends Component<Props> {
  isSelected = (path) => {
    const { submenuSelected } = this.props
    return (
      submenuSelected &&
      (submenuSelected.path === path || (submenuSelected.selectedPath && submenuSelected.selectedPath === path))
    )
  }

  getMenuClass = (element, item) => {
    let className = 'stw-sider-submenu-element'
    if (item.notifications) {
      className += ` notification${element.read === 0 ? ' active' : ''}`
    } else if (this.isSelected(element.path)) {
      className += ' active'
    }
    return className
  }

  canRenderMenuElement = (element: MenuEntryType): boolean => {
    return hasAnyCapabilities(...(element.capabilities ?? [])) && !element.hidden
  }

  render() {
    const { menu, selected, handleClick, collapsed, seeAllHandler, handleNotificationDetail } = this.props
    return (
      <Menu style={{ flex: 1 }} mode="vertical" theme="dark" selectedKeys={[selected || '']} onClick={handleClick}>
        {menu.map((item: MenuEntryType, i) => {
          // eslint-disable-next-line no-nested-ternary
          return !this.canRenderMenuElement(item) ? null : collapsed ? (
            <SubMenu
              popupClassName={`stw-stylewhere-submenu${i}`}
              popupOffset={[10, 0]}
              key={item.key}
              icon={selected === item.key ? item.iconActive : item.icon}
              title={item.label}
            >
              {item.submenus.map((element) =>
                !element.hidden ? (
                  <Menu.Item
                    className={this.getMenuClass(element, item)}
                    key={item.notifications ? element.id : element.path}
                  >
                    {item.notifications ? (
                      <SidebarMenuNotification handleNotificationDetail={handleNotificationDetail} item={element} />
                    ) : (
                      <div>{element.label}</div>
                    )}
                  </Menu.Item>
                ) : null
              )}
              {item.notifications && (
                <Button className="stylewhere-button-default" onClick={() => seeAllHandler()}>
                  {__('misc.notifications.seeAll')}
                </Button>
              )}
            </SubMenu>
          ) : (
            <Menu.Item className="ant-badge" key={item.key} icon={selected === item.key ? item.iconActive : item.icon}>
              {item.label}
              {item.counter && item.counter > 0 && <div className="ant-badge-count">{item.counter}</div>}
            </Menu.Item>
          )
        })}
      </Menu>
    )
  }
}
