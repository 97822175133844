import { refresh } from '../shared/router'

export const MODAL_PARAM = 'modal'

export interface ModalConfig {
  type:
    | 'MOVEMENT_PRODUCT_ITEMS'
    | 'INVENTORY_ENTRY_ITEMS'
    | 'CAPABILITY_GROUP_LIST'
    | 'CAPABILITY_LIST'
    | 'SORTING_GROUP_READS'
    | 'PICKING_ENTRY_READS'
  data: any
}

export function readModalConfig(locationSearch: any): ModalConfig | undefined {
  const queryParameters = new URLSearchParams(locationSearch)
  const modalParam = queryParameters.get(MODAL_PARAM)
  if (modalParam) {
    return JSON.parse(atob(modalParam))
  }
  return undefined
}

export function showModal(modalConfig: ModalConfig): void {
  const jsonConfig = JSON.stringify(modalConfig)
  const params: any = {}
  params[MODAL_PARAM] = btoa(jsonConfig)
  refresh(params)
}

export function hideModal(): void {
  const params: any = {}
  params[MODAL_PARAM] = undefined
  refresh(params)
}
