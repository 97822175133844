import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { App } from './core/components'
const container = document.getElementById('root')
if (container === null) {
  throw new Error('Missing root element')
}
const root = createRoot(container)
root.render(
  <StrictMode>
    <App />
  </StrictMode>
)
