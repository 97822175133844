/* eslint-disable func-names */
import { Component } from 'react'
import { Layout, Row } from 'antd'
import { Auth } from '../api/Auth'
import { navigate } from '../shared/router'
import { MenuEntryType, SubmenuEntryType } from '../types'
import { ModalApplicationInfo, ModalUserData, SidebarUser, SidebarMenu, SidebarMenuChild, SidebarHeader } from '.'

const { Sider } = Layout

interface Props {
  menuSelected?: string
  subMenuSelected?: SubmenuEntryType
  menu: MenuEntryType[]
  menuClick: any
  numberUpdateMenu?: number
  handleNotificationDetail: any
  loggedUser: any
}

interface State {
  collapsed: boolean
  selected: string
  selectedMenu?: MenuEntryType
  submenuSelected?: SubmenuEntryType
  userModalVisible: boolean
  applicationModalVisible: boolean
}

export class Sidebar extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      collapsed: false, // set to false for close submenu
      selected: '',
      selectedMenu: undefined,
      submenuSelected: undefined,
      userModalVisible: false,
      applicationModalVisible: false,
    }
  }

  componentDidMount() {
    // Forza esecuzione di shouldComponentUpdate
    // (è un bug di shouldComponentUpdate, non dovrebbe usato per cambiare stato!)
    this.setState({})
  }

  shouldComponentUpdate = (nextProps) => {
    const { selected, submenuSelected } = this.state
    if (nextProps.menuSelected !== selected) {
      const menu = this.getSelectedMenu(nextProps.menuSelected)
      if (selected === '') {
        this.setState({
          selected: nextProps.menuSelected,
          selectedMenu: menu,
          submenuSelected: nextProps.subMenuSelected,
        })
      } else {
        this.setState({
          selected: nextProps.menuSelected || '',
          selectedMenu: menu,
          submenuSelected: nextProps.subMenuSelected,
        })
      }
    } else if (
      nextProps.subMenuSelected &&
      (!submenuSelected ||
        (submenuSelected && JSON.stringify(nextProps.subMenuSelected) !== JSON.stringify(submenuSelected)))
    ) {
      const menu = this.getSelectedMenu(nextProps.menuSelected)
      if (menu && menu.notifications) {
        this.setState({
          submenuSelected: nextProps.subMenuSelected,
          collapsed: true,
        })
      } else {
        this.setState({
          submenuSelected: nextProps.subMenuSelected,
        })
      }
    } else if (this.props.numberUpdateMenu !== nextProps.numberUpdateMenu) {
      const menu = this.getSelectedMenu(nextProps.menuSelected)
      this.setState({
        selectedMenu: menu,
      })
    }
    return true
  }

  setSubMenuSelected = () => {
    const { selected } = this.state
    const menu = this.getSelectedMenu(selected)
    this.setState({
      selectedMenu: menu,
    })
  }

  getSubMenuNumber = (submenus) => {
    let counter = 0
    for (let s = 0; s < submenus.length; s++) {
      if (!submenus[s].hidden) {
        counter++
      }
    }
    return counter++
  }

  getSelectedMenu = (selected) => {
    const { menu } = this.props
    const obj = menu.find((element) => {
      return element.key === selected
    })
    return obj || undefined
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    })
  }

  handleClick = (e) => {
    const { collapsed } = this.state
    if (e.key.indexOf('key-notifications') !== -1) {
      return
    }
    if (collapsed) {
      navigate(e.key)
    } else {
      this.props.menuClick(e.key)
    }
  }

  closeSubMenu = () => {
    this.setState({
      selected: '',
      collapsed: false,
    })
  }

  submenuClickHandler = (submenu: SubmenuEntryType, event: any) => {
    navigate(submenu.path)
    event.preventDefault()
  }

  logout = () => {
    Auth.logout()
  }

  getFirstChar = (str, length = 1) => {
    if (str && str !== null && str !== '') {
      return str.substr(0, length).toUpperCase()
    }
    return '-'
  }

  getUserData = () => {
    const { loggedUser } = this.props
    const user = {
      sigla: '',
      username: '',
      name: '',
      surname: null,
      complete: '',
      email: null,
      roles: [],
      places: [],
    }
    if (loggedUser) {
      user.username = loggedUser.username
      user.name = loggedUser.name ? loggedUser.name : null
      user.surname = loggedUser.surname ? loggedUser.surname : null
      user.email = loggedUser.email ? loggedUser.email : null
      user.roles = loggedUser.roles || []
      user.places = loggedUser.userPlaces || []
      if (user.name !== null) {
        if (user.username !== null) {
          user.sigla = this.getFirstChar(user.name) + this.getFirstChar(user.surname)
          user.complete = `${user.name} ${user.surname}`
        } else {
          user.complete = user.name
          user.sigla = this.getFirstChar(user.name, 2)
        }
      } else {
        user.sigla = this.getFirstChar(user.username, 2)
        user.complete = user.username
      }
    }
    return user
  }

  managerUserDataModal = () => {
    this.setState({ userModalVisible: !this.state.userModalVisible })
  }

  managerApplicationInfoModal = () => {
    this.setState({ applicationModalVisible: !this.state.applicationModalVisible })
  }

  seeAllNotificationsHandler = () => {
    navigate(`/notifications`)
  }

  render() {
    const { collapsed, selected, selectedMenu, submenuSelected, userModalVisible, applicationModalVisible } = this.state
    const { menu, handleNotificationDetail } = this.props
    const userData = this.getUserData()
    return (
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <Row style={{ flexDirection: 'column', height: '100%', flexFlow: 'column' }}>
          <SidebarHeader collapsed={collapsed} toggle={this.toggle} />
          <Row className="stw-sider-menu-row overflow">
            <SidebarMenu
              menu={menu}
              collapsed={collapsed}
              selected={selected}
              submenuSelected={submenuSelected}
              handleClick={this.handleClick}
              seeAllHandler={this.seeAllNotificationsHandler}
              handleNotificationDetail={handleNotificationDetail}
            />
            {selected !== '' && !collapsed && (
              <SidebarMenuChild
                selectedMenu={selectedMenu}
                submenuSelected={submenuSelected}
                submenuClickHandler={this.submenuClickHandler}
                seeAllHandler={this.seeAllNotificationsHandler}
                handleNotificationDetail={handleNotificationDetail}
              />
            )}
          </Row>
          <SidebarUser
            collapsed={collapsed}
            managerApplicationInfoModal={this.managerApplicationInfoModal}
            managerUserDataModal={this.managerUserDataModal}
            logout={this.logout}
            userData={userData}
          />
        </Row>
        <ModalUserData userData={userData} visible={userModalVisible} close={this.managerUserDataModal} />
        <ModalApplicationInfo visible={applicationModalVisible} close={this.managerApplicationInfoModal} />
      </Sider>
    )
  }
}
