import { AxiosRequestConfig } from 'axios'
import BaseResource, { PageResult } from './BaseResource'
import { api, responseErrorCheck } from './api'
import { StwFullItemEvent } from './types'

export default class ItemEvents extends BaseResource {
  static endpoint = '/api/v1/itemEvents/item/' ///tracking

  static async getItemEvents(
    endpoint: string,
    params = {},
    requestConfig?: AxiosRequestConfig
  ): Promise<PageResult<StwFullItemEvent>> {
    return api.get<PageResult<StwFullItemEvent>>(endpoint, params, requestConfig).then(responseErrorCheck)
  }
}
