import { Component } from 'react'
import { Checkbox, Collapse, List, Tag } from 'antd'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import CollapsePanel from 'antd/es/collapse/CollapsePanel'
import { StwFullRfidAntenna, StwFullRfidReader } from '../../api'
import { __ } from '../../shared/i18n'

type StwRfidAntennaWithSelection = {
  rfidAntenna: StwFullRfidAntenna
  selected: boolean
}

interface Props {
  rfidReader: StwFullRfidReader
  rfidAntennasWithSelection: StwRfidAntennaWithSelection[]
  onSelect: (rfidAntennaId: string, selected: boolean) => void
}

export default class WorkstationRfidAntennasModalCollapse extends Component<Props> {
  render() {
    const { rfidReader, rfidAntennasWithSelection, onSelect } = this.props
    return (
      <Collapse
        className="stw-workstation-rfid-antennas-collapse"
        defaultActiveKey={[]}
        expandIconPosition="end"
        expandIcon={(props) => {
          return props.isActive ? <UpOutlined style={{ fontSize: 16 }} /> : <DownOutlined style={{ fontSize: 16 }} />
        }}
      >
        <CollapsePanel
          key="1"
          header={
            <>
              {rfidReader.code} <br />
              {__('fields.labels.type')}: <b>{rfidReader.deviceType}</b>
              {rfidReader.ipAddress && ` - ${__('fields.labels.ipAddress')} `}
              {rfidReader.ipAddress && <b>{rfidReader.ipAddress}</b>}
            </>
          }
        >
          <List
            style={{ width: '100%' }}
            itemLayout="horizontal"
            dataSource={rfidAntennasWithSelection}
            renderItem={(rfidAntennaWithSelection, index) => (
              <List.Item
                style={{
                  paddingLeft: 16,
                  paddingRight: 16,
                  backgroundColor: index % 2 === 0 ? 'white' : '#ececec',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  onSelect(rfidAntennaWithSelection.rfidAntenna.id!, !rfidAntennaWithSelection.selected)
                }}
                actions={[
                  <Checkbox
                    checked={rfidAntennaWithSelection.selected}
                    onChange={() => {
                      //onSelect(rfidAntennaWithSelection.rfidAntenna.id!, event.target.checked)
                    }}
                  />,
                ]}
              >
                <List.Item.Meta
                  title={
                    <>
                      <b>{rfidAntennaWithSelection.rfidAntenna.code}</b> -{' '}
                      {rfidAntennaWithSelection.rfidAntenna.zone ? rfidAntennaWithSelection.rfidAntenna.zone.code : ''}
                    </>
                  }
                  description={
                    <>
                      <Tag style={{ borderRadius: '5px' }}>
                        <b>{__('fields.labels.txPower')}</b>: {rfidAntennaWithSelection.rfidAntenna.txPower}
                      </Tag>
                      <Tag style={{ borderRadius: '5px' }}>
                        <b>{__('fields.labels.rxSensitivity')}</b>: {rfidAntennaWithSelection.rfidAntenna.rxSensitivity}
                      </Tag>
                    </>
                  }
                />
              </List.Item>
            )}
          />
        </CollapsePanel>
      </Collapse>
    )
  }
}
