import { Component } from 'react'
import { StwFullFlow } from '../../../api'
import { GridData, StylewherePage, DefaultHeader, NotFound, Section } from '../../../components'
import { __, T } from '../../../shared/i18n'
import { PICKING_FLOW_DETAIL } from '../../../config/Pages/PickingFlows'
import PickingFlows from '../../../api/PickingFlows'
import { ExtendRouteComponentProps } from '../../../types'
import { getMultipleTitle, getBackURL, getFragmentObject } from '../../../shared/utils'

interface State {
  pickingFlow: StwFullFlow | undefined
  loader: boolean
}

class PickingFlowDetail extends Component<ExtendRouteComponentProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      pickingFlow: undefined,
      loader: true,
    }
  }

  componentDidMount() {
    this.initialize()
  }

  initialize = async () => {
    const { match } = this.props
    let pickingFlow
    if (match && match.params && match.params.pickingFlowId) {
      pickingFlow = await PickingFlows.getFull(match.params.pickingFlowId).then((res) => res)
    }
    this.setState({
      pickingFlow: pickingFlow || undefined,
      loader: false,
    })
  }

  render() {
    const { pickingFlow, loader } = this.state
    const { breadcrumbs, queryString } = this.props
    const fragment = getFragmentObject(pickingFlow, 'code')
    return (
      <StylewherePage {...this.props} noOverflow fragment={fragment}>
        <DefaultHeader
          backPath={getBackURL(queryString, breadcrumbs)}
          multipleTitle={getMultipleTitle(pickingFlow)}
          title={__(T.misc.not_found)}
          skeleton={{ active: loader, options: { multiple: true } }}
        />
        <Section customClass={`stw-section-page paged-header ${pickingFlow || loader ? 'scroll transparent' : ''}`}>
          {pickingFlow || loader ? (
            <GridData fields={PICKING_FLOW_DETAIL} record={loader ? [] : pickingFlow} skeleton={loader} />
          ) : (
            <NotFound fullheight title={__(T.misc.noRecordFoundTitle)} />
          )}
        </Section>
      </StylewherePage>
    )
  }
}

export default PickingFlowDetail
