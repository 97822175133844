import { Component } from 'react'
import { Col, Typography, Tag } from 'antd'
import { ExclamationOutlined, SafetyOutlined, QuestionOutlined } from '@ant-design/icons'

const { Title } = Typography

interface Props {
  icon: string
  label: string
  iconBkgColor: string
  col: number
}

export class GridCardsRowHead extends Component<Props> {
  getIcon = () => {
    const { icon } = this.props
    if (icon === 'SAFETY') {
      return <SafetyOutlined style={{ fontSize: 20, color: '#FFF' }} />
    }
    if (icon === 'MISSING') {
      return <QuestionOutlined style={{ fontSize: 20, color: '#FFF' }} />
    }
    return <ExclamationOutlined style={{ fontSize: 20, color: '#FFF' }} />
  }
  render() {
    const { label, col, iconBkgColor } = this.props
    return (
      <Col span={col || 24} className="stylewhere-card-head">
        <Tag color={iconBkgColor}>{this.getIcon()}</Tag>
        <Title level={4}>{label}</Title>
      </Col>
    )
  }
}
