import { Component } from 'react'
import { ExtendRouteComponentProps } from '../../../types'
import { TableListReport, StylewherePage } from '../../../components'
import { getEnabledImageReport, getTableActionsReport, getHeaderActionsReport } from '../../../shared/utils'
import { CAPABILITIES, hasAnyCapabilities } from '../../../config/utility/capabilityUtility'

class ReportList extends Component<ExtendRouteComponentProps> {
  render() {
    const { match, breadcrumbs } = this.props
    const reportId = match.params.reportId
    const operationType = match.params.operationType
    return (
      <StylewherePage {...this.props} noOverflow>
        <TableListReport
          {...this.props}
          reportId={reportId}
          operationType={operationType}
          enabledColumnImage={getEnabledImageReport(reportId)}
          reportActions={getTableActionsReport(reportId)}
          actions={getHeaderActionsReport(reportId, breadcrumbs)}
          operationId={match.params.operationId}
          disabledReportExport={!hasAnyCapabilities(CAPABILITIES.RESOURCE_ASYNCJOBS_EDIT)}
        />
      </StylewherePage>
    )
  }
}

export default ReportList
