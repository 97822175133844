/* eslint-disable func-names */
import { Component } from 'react'
import { Button, Form, Space } from 'antd'
import { navigate } from '../../shared/router'
import { AdvancedFormButton } from './advancedFormTypes'

interface Props {
  actions?: AdvancedFormButton[]
}

export class AdvancedFormButtons extends Component<Props> {
  render() {
    const { actions } = this.props
    return (
      <Form.Item className="stylewhere-form-buttons" name="submit" style={{ marginTop: 20 }}>
        <Space size="middle" style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {actions!.map((action) => {
            return this.getButtonByType(action)
          })}
        </Space>
      </Form.Item>
    )
  }

  private getButtonByType(action: AdvancedFormButton) {
    switch (action.type) {
      case 'navigate':
        return (
          <Button
            key={action.label}
            className={action.primary ? 'stylewhere-button-primary' : 'stylewhere-button-default'}
            onClick={() => navigate(action.navigatePath)}
          >
            {action.label}
          </Button>
        )
      case 'handler':
        return (
          <Button
            key={action.label}
            className={action.primary ? 'stylewhere-button-primary' : 'stylewhere-button-default'}
            onClick={action.handlerCallback}
          >
            {action.label}
          </Button>
        )
      case 'submit':
        return action.formName ? (
          <Button key={action.label} form={action.formName} className="stylewhere-button-primary" htmlType="submit">
            {action.label}
          </Button>
        ) : (
          <Button key={action.label} className="stylewhere-button-primary" htmlType="submit">
            {action.label}
          </Button>
        )
      case 'reset':
        return (
          <Button key={action.label} className="stylewhere-button-default" htmlType="reset">
            {action.label}
          </Button>
        )
      default:
        return <div key={action.label}>Bottone di tipo {action.type} da implementare</div>
    }
  }
}
