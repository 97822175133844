import { Component } from 'react'
import { DefaultReportDetail } from '../../../components'
import { ExtendRouteComponentProps } from '../../../types'

class PickingEntryReportDetail extends Component<ExtendRouteComponentProps> {
  render() {
    const { match } = this.props
    return <DefaultReportDetail {...this.props} reportId="pickingEntry" reportInstanceId={match.params.recordId} />
  }
}

export default PickingEntryReportDetail
