import { __, T } from '../shared/i18n'

import IconReport from '../../icon/menu/icon-menu-report.png'
import IconReportBlack from '../../icon/menu/icon-menu-report-black.png'
import IconReportGreen from '../../icon/menu/icon-menu-report-green.png'
import IconOperationLogs from '../../icon/menu/icon-menu-operation-logs.png'
import IconOperationLogsBlack from '../../icon/menu/icon-menu-operation-logs-black.png'
import IconOperationLogsGreen from '../../icon/menu/icon-menu-operation-logs-green.png'
import IconConfiguration from '../../icon/menu/icon-menu-configuration.png'
import IconConfigurationBlack from '../../icon/menu/icon-menu-configuration-black.png'
import IconConfigurationGreen from '../../icon/menu/icon-menu-configuration-green.png'
import IconDevice from '../../icon/menu/icon-menu-devices.png'
import IconDeviceBlack from '../../icon/menu/icon-menu-devices-black.png'
import IconDeviceGreen from '../../icon/menu/icon-menu-devices-green.png'
import IconNotification from '../../icon/menu/icon-menu-notification.png'
import IconNotificationBlack from '../../icon/menu/icon-menu-notification-black.png'
import IconNotificationGreen from '../../icon/menu/icon-menu-notification-green.png'
import IconJobs from '../../icon/menu/icon-menu-jobs.png'
import IconJobsBlack from '../../icon/menu/icon-menu-jobs-black.png'
import IconJobsGreen from '../../icon/menu/icon-menu-jobs-green.png'

import { CAPABILITIES, hasAnyCapabilities } from './utility/capabilityUtility'

export const BaseMenu = [
  {
    label: __(T.menu.reports),
    key: 'reports',
    icon: <div className="icona" style={{ backgroundImage: `url(${IconReport})` }} />,
    iconBlack: <div className="icona" style={{ backgroundImage: `url(${IconReportBlack})` }} />,
    iconActive: <div className="icona" style={{ backgroundImage: `url(${IconReportGreen})` }} />,
    submenus: [],
  },
  {
    label: __(T.menu.operations),
    key: 'operationLogs',
    icon: <div className="icona" style={{ backgroundImage: `url(${IconOperationLogs})` }} />,
    iconBlack: <div className="icona" style={{ backgroundImage: `url(${IconOperationLogsBlack})` }} />,
    iconActive: <div className="icona" style={{ backgroundImage: `url(${IconOperationLogsGreen})` }} />,
    submenus: [],
  },
  {
    label: __(T.menu.configuration),
    key: 'configuration',
    icon: <div className="icona" style={{ backgroundImage: `url(${IconConfiguration})` }} />,
    iconBlack: <div className="icona" style={{ backgroundImage: `url(${IconConfigurationBlack})` }} />,
    iconActive: <div className="icona" style={{ backgroundImage: `url(${IconConfigurationGreen})` }} />,
    capabilities: [
      CAPABILITIES.CONTROL_PANEL_PLACE_VIEW,
      CAPABILITIES.CONTROL_PANEL_ZONE_VIEW,
      CAPABILITIES.CONTROL_PANEL_USER_VIEW,
      CAPABILITIES.CONTROL_PANEL_CAPABILITY_GROUP_VIEW,
      CAPABILITIES.CONTROL_PANEL_ROLE_VIEW,
      CAPABILITIES.CONTROL_PANEL_ROLE_MAPPING_VIEW,
      CAPABILITIES.CONTROL_PANEL_OPERATION_VIEW,
      CAPABILITIES.CONTROL_PANEL_ITEM_CONFIGURATION_VIEW,
      CAPABILITIES.CONTROL_PANEL_APPLICATION_SETTING_VIEW,
      CAPABILITIES.CONTROL_PANEL_ENUMERATION_TYPE_VIEW,
      CAPABILITIES.CONTROL_PANEL_ATTRIBUTE_MAPPING_VIEW,
      CAPABILITIES.CONTROL_PANEL_PICKING_VIEW,
    ],
    submenus: [
      {
        label: __(T.menu.places),
        path: '/configuration/places',
        capabilities: [CAPABILITIES.CONTROL_PANEL_PLACE_VIEW],
      },
      {
        label: __(T.menu.zones),
        path: '/configuration/zones',
        capabilities: [CAPABILITIES.CONTROL_PANEL_ZONE_VIEW],
      },
      {
        label: __(T.menu.usersRoles),
        // path: '/configuration/usersRoles/users',
        getPath: () => {
          if (hasAnyCapabilities(CAPABILITIES.CONTROL_PANEL_USER_VIEW)) {
            return '/configuration/usersRoles/users'
          }
          if (hasAnyCapabilities(CAPABILITIES.CONTROL_PANEL_CAPABILITY_GROUP_VIEW)) {
            return '/configuration/usersRoles/capabilitiesGroup'
          }
          if (hasAnyCapabilities(CAPABILITIES.CONTROL_PANEL_ROLE_VIEW)) {
            return '/configuration/usersRoles/roles'
          }
          if (hasAnyCapabilities(CAPABILITIES.CONTROL_PANEL_ROLE_MAPPING_VIEW)) {
            return '/configuration/usersRoles/roleMappings'
          }
          return ''
        },
        capabilities: [
          CAPABILITIES.CONTROL_PANEL_USER_VIEW,
          CAPABILITIES.CONTROL_PANEL_CAPABILITY_GROUP_VIEW,
          CAPABILITIES.CONTROL_PANEL_ROLE_VIEW,
          CAPABILITIES.CONTROL_PANEL_ROLE_MAPPING_VIEW,
        ],
        unsearchable: false,
      },
      {
        label: __(T.menu.users),
        path: '/configuration/usersRoles/users',
        capabilities: [CAPABILITIES.CONTROL_PANEL_USER_VIEW],
        hidden: true,
      },
      {
        label: __(T.menu.capabilitiesGroup),
        path: '/configuration/usersRoles/capabilitiesGroup',
        capabilities: [CAPABILITIES.CONTROL_PANEL_CAPABILITY_GROUP_VIEW],
        hidden: true,
      },
      {
        label: __(T.menu.roles),
        path: '/configuration/usersRoles/roles',
        capabilities: [CAPABILITIES.CONTROL_PANEL_ROLE_VIEW],
        hidden: true,
      },
      {
        label: __(T.menu.roleMappings),
        path: '/configuration/usersRoles/roleMappings',
        capabilities: [CAPABILITIES.CONTROL_PANEL_ROLE_MAPPING_VIEW],
        hidden: true,
      },
      {
        label: __(T.menu.settings),
        // path: '/configuration/settings/operations',
        getPath: () => {
          if (hasAnyCapabilities(CAPABILITIES.CONTROL_PANEL_OPERATION_VIEW)) {
            return '/configuration/settings/operations'
          }
          if (hasAnyCapabilities(CAPABILITIES.CONTROL_PANEL_ITEM_CONFIGURATION_VIEW)) {
            return '/configuration/settings/itemConfigurations'
          }
          if (hasAnyCapabilities(CAPABILITIES.CONTROL_PANEL_APPLICATION_SETTING_VIEW)) {
            return '/configuration/settings/applications'
          }
          return ''
        },
        capabilities: [
          CAPABILITIES.CONTROL_PANEL_OPERATION_VIEW,
          CAPABILITIES.CONTROL_PANEL_ITEM_CONFIGURATION_VIEW,
          CAPABILITIES.CONTROL_PANEL_APPLICATION_SETTING_VIEW,
        ],
        unsearchable: false,
      },
      {
        label: __(T.menu.operations),
        path: '/configuration/settings/operations',
        capabilities: [CAPABILITIES.CONTROL_PANEL_OPERATION_VIEW],
        hidden: true,
      },
      {
        label: __(T.menu.itemConfigurations),
        path: '/configuration/settings/itemConfigurations',
        capabilities: [CAPABILITIES.CONTROL_PANEL_ITEM_CONFIGURATION_VIEW],
        hidden: true,
      },
      {
        label: __(T.menu.itemTypes),
        path: '/configuration/settings/itemTypes',
        capabilities: [CAPABILITIES.CONTROL_PANEL_ITEM_TYPE_VIEW],
        hidden: true,
      },
      {
        label: __(T.menu.applications),
        path: '/configuration/settings/applications',
        capabilities: [CAPABILITIES.CONTROL_PANEL_APPLICATION_SETTING_VIEW],
        hidden: true,
      },
      {
        label: `${__('menu.enumerations.label')}`,
        path: '/configuration/enumerations',
        capabilities: [CAPABILITIES.CONTROL_PANEL_ENUMERATION_TYPE_VIEW],
      },
      {
        label: __(T.menu.entityAttributes),
        path: '/configuration/entityAttributes',
        capabilities: [CAPABILITIES.CONTROL_PANEL_ATTRIBUTE_MAPPING_VIEW],
      },
      {
        label: __(T.menu.pickingFlows),
        path: '/configuration/pickingFlows',
        capabilities: [CAPABILITIES.CONTROL_PANEL_PICKING_VIEW],
      },
      {
        label: __(T.menu.templates),
        path: '/configuration/templates',
        capabilities: [CAPABILITIES.RESOURCE_TEMPLATES_VIEW],
      },
    ],
  },
  {
    label: __(T.menu.devices),
    key: 'devices',
    icon: <div className="icona" style={{ backgroundImage: `url(${IconDevice})` }} />,
    iconBlack: <div className="icona" style={{ backgroundImage: `url(${IconDeviceBlack})` }} />,
    iconActive: <div className="icona" style={{ backgroundImage: `url(${IconDeviceGreen})` }} />,
    capabilities: [
      CAPABILITIES.CONTROL_PANEL_WORKSTATION_VIEW,
      CAPABILITIES.CONTROL_PANEL_RFID_READER_VIEW,
      CAPABILITIES.CONTROL_PANEL_RFID_ANTENNA_VIEW,
      CAPABILITIES.CONTROL_PANEL_DEVICE_CONTROLLER_VIEW,
      CAPABILITIES.CONTROL_PANEL_PRINTER_VIEW,
      CAPABILITIES.CONTROL_PANEL_SCANNER_VIEW,
      CAPABILITIES.CONTROL_PANEL_XSPAN_READER_VIEW,
      CAPABILITIES.CONTROL_PANEL_BALANCE_VIEW,
      CAPABILITIES.CONTROL_PANEL_BARCODE_READER_VIEW,
    ],
    submenus: [
      {
        label: __(T.menu.workstations),
        path: '/devices/workstations',
        capabilities: [CAPABILITIES.CONTROL_PANEL_WORKSTATION_VIEW],
      },
      {
        label: __(T.menu.rfidReaders),
        path: '/devices/rfidReaders',
        capabilities: [CAPABILITIES.CONTROL_PANEL_RFID_READER_VIEW],
      },
      {
        label: __(T.menu.antennas),
        path: '/devices/antennas',
        capabilities: [CAPABILITIES.CONTROL_PANEL_RFID_ANTENNA_VIEW],
      },
      {
        label: __(T.menu.deviceControllers),
        path: '/devices/controllers',
        capabilities: [CAPABILITIES.CONTROL_PANEL_DEVICE_CONTROLLER_VIEW],
      },
      {
        label: __(T.menu.printers),
        path: '/devices/printers',
        capabilities: [CAPABILITIES.CONTROL_PANEL_PRINTER_VIEW],
      },
      {
        label: __(T.menu.scanners),
        path: '/devices/scanners',
        capabilities: [CAPABILITIES.CONTROL_PANEL_SCANNER_VIEW],
      },
      {
        label: __(T.menu.xspans),
        path: '/devices/xspans',
        capabilities: [CAPABILITIES.CONTROL_PANEL_XSPAN_READER_VIEW],
      },
      {
        label: __(T.menu.scales),
        path: '/devices/balances',
        capabilities: [CAPABILITIES.CONTROL_PANEL_BALANCE_VIEW],
      },
      {
        label: __(T.menu.barcodeReaders),
        path: '/devices/barcodeReaders',
        capabilities: [CAPABILITIES.CONTROL_PANEL_BARCODE_READER_VIEW],
      },
      {
        label: __(T.menu.conveyors),
        path: '/devices/conveyors',
      },
    ],
  },
  {
    label: __('menu.jobs.jobs'),
    key: 'jobs',
    icon: <div className="icona" style={{ backgroundImage: `url(${IconJobs})` }} />,
    iconBlack: <div className="icona" style={{ backgroundImage: `url(${IconJobsBlack})` }} />,
    iconActive: <div className="icona" style={{ backgroundImage: `url(${IconJobsGreen})` }} />,
    capabilities: [CAPABILITIES.RESOURCE_ASYNCJOBS_VIEW],
    submenus: [
      {
        label: __('menu.jobs.user'),
        path: '/jobs/user',
        capabilities: [CAPABILITIES.RESOURCE_ASYNCJOBS_VIEW],
      },
      {
        label: __('menu.jobs.system'),
        path: '/jobs/system',
        capabilities: [CAPABILITIES.RESOURCE_ASYNCJOBS_VIEW],
      },
      {
        label: __('menu.jobs.import'),
        path: '/jobs/import',
        capabilities: [CAPABILITIES.RESOURCE_ASYNCJOBS_VIEW],
      },
      {
        label: __('menu.jobs.export'),
        path: '/jobs/export',
        capabilities: [CAPABILITIES.RESOURCE_ASYNCJOBS_VIEW],
      },
    ],
  },
  {
    label: __(T.menu.notifications),
    key: 'notifications',
    icon: <div className="icona" style={{ backgroundImage: `url(${IconNotification})` }} />,
    iconBlack: <div className="icona" style={{ backgroundImage: `url(${IconNotificationBlack})` }} />,
    iconActive: <div className="icona" style={{ backgroundImage: `url(${IconNotificationGreen})` }} />,
    notifications: true,
    path: '/notifications',
    submenus: [],
  },
]
