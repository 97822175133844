import { Component } from 'react'
import { GridData, StylewherePage, DefaultHeader, NotFound, Section, TableListReport } from '../../../components'
import { DetailCell, StwChecklistType, StwFullChecklist, StwReportQueryResult } from '../../../api'
import Checklists from '../../../api/Checklists'
import { __, T } from '../../../shared/i18n'
import { ExtendRouteComponentProps } from '../../../types'
import { SkeltonAttribute, SkeletonAttributeReport } from '../../../constants'
import Reports from '../../../api/Reports'
import {
  getMultipleTitle,
  getBackURL,
  canRenderAttributes,
  getFragmentObject,
  getAttributeConfiguration,
} from '../../../shared/utils'

interface State {
  reportInstance?: StwReportQueryResult
  configuration?: DetailCell[]
  record: StwFullChecklist | null
  loader: boolean
  attributeFields: DetailCell[]
}

class ReportChecklistDetail extends Component<ExtendRouteComponentProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      attributeFields: [],
      configuration: undefined,
      loader: true,
      record: null,
    }
  }

  componentDidMount() {
    this.initialize()
  }

  initialize = async () => {
    const { match } = this.props
    if (match && match.params && match.params.recordId) {
      const recordId = match.params.recordId
      const fullChecklist = await Checklists.getFullChecklist(recordId)
      const res = await Reports.report('checklist', {
        id: recordId,
        page: 0,
        size: 1,
      })
      if (res && res.content && res.content.length > 0) {
        const configuration = await Reports.getConfiguration('checklist')
        this.setState({
          reportInstance: res.content[0],
          configuration: configuration,
          loader: false,
          record: fullChecklist,
          attributeFields: getAttributeConfiguration(fullChecklist),
        })
      } else {
        this.setState({ loader: false })
      }
    }
  }

  render() {
    const { configuration, reportInstance, loader, record, attributeFields } = this.state
    const { breadcrumbs, queryString } = this.props
    const fragment = getFragmentObject(reportInstance, 'id')
    return (
      <StylewherePage {...this.props} noOverflow fragment={fragment}>
        <DefaultHeader
          backPath={getBackURL(queryString, breadcrumbs)}
          multipleTitle={getMultipleTitle(reportInstance)}
          title={__(T.misc.not_found)}
          skeleton={{ active: loader, options: { multiple: true } }}
        />
        <Section customClass={`stw-section-page paged-header ${record || loader ? 'scroll transparent' : ''}`}>
          {record || loader ? (
            <>
              <GridData
                fields={configuration || SkeletonAttributeReport}
                record={loader ? [] : reportInstance}
                skeleton={loader}
              />
              {record && (
                <TableListReport
                  {...this.props}
                  reportId={record.type === StwChecklistType.CHECKLIST_BY_ITEM ? 'item' : 'checklistProduct'}
                  actions={[]}
                  fixedTableHeight
                  disabledReportExport
                  headerType="boxed"
                  title={
                    record.type === StwChecklistType.CHECKLIST_BY_ITEM ? __(T.titles.items) : __(T.titles.products)
                  }
                  titleEmpty={
                    record.type === StwChecklistType.CHECKLIST_BY_ITEM
                      ? __(T.checklist.noItemsFoundTitle)
                      : __(T.checklist.noProductsFoundTitle)
                  }
                  customColumnPrefix="checklistDetailEntries"
                  reportActions={{
                    active: false,
                    onlyedit: false,
                  }}
                  reportParams={{ checklistId: record.id }}
                  disableLocation
                  disabledNoDataFound
                  hiddenEmptyTable
                />
              )}
              {canRenderAttributes(record, attributeFields, loader) && (
                <GridData
                  title={__(T.misc.attributes_title)}
                  fields={loader ? SkeltonAttribute : attributeFields}
                  record={loader || !record ? [] : record.attributes}
                  skeleton={loader}
                />
              )}
            </>
          ) : (
            <NotFound fullheight title={__(T.misc.noRecordFoundTitle)} />
          )}
        </Section>
      </StylewherePage>
    )
  }
}

export default ReportChecklistDetail
