import { Component } from 'react'
import { StylewherePage, TableList } from '../../../../components'
import { __, T } from '../../../../shared/i18n'
import { AdvancedTableConfig } from '../../../../components/AdvancedTable'
import { ExtendRouteComponentProps } from '../../../../types'
import { CAPABILITIES, getActionsList } from '../../../../config/utility/capabilityUtility'
import { getTabsSettings } from '../../../../config/Pages/Settings/Tabs'
import ItemConfigurations from '../../../../api/ItemConfigurations'
import {
  ITEM_CONFIGURATION_COLUMNS,
  ITEM_CONFIGURATION_FILTERS,
} from '../../../../config/Pages/Settings/ItemConfigurations'
import { IdentifierTypes } from '../../../../constants'

const ITEM_CONFIGURATION_TABLE_CONFIG: AdvancedTableConfig = {
  columns: ITEM_CONFIGURATION_COLUMNS,
  filterDefinitions: ITEM_CONFIGURATION_FILTERS,
  filterParameters: { types: IdentifierTypes },
  manageColumnsPrefix: 'itemConfigurations',
  entity: 'zone',
  activeDefaultClassColumn: true,
}

class ItemConfigurationList extends Component<ExtendRouteComponentProps> {
  render() {
    return (
      <StylewherePage {...this.props} noOverflow>
        <TableList
          tabs={getTabsSettings(2)}
          location={this.props.location}
          config={ITEM_CONFIGURATION_TABLE_CONFIG}
          resource={{
            call: ItemConfigurations.searchTableList,
            endpoint: ItemConfigurations.endpoint,
          }}
          actions={getActionsList(
            CAPABILITIES.RESOURCE_ITEM_CONFIGURATION_EDIT,
            __(T.itemconfiguration.create_new),
            '/configuration/settings/itemConfigurations/create/form'
          )}
        />
      </StylewherePage>
    )
  }
}

export default ItemConfigurationList
