/* eslint-disable func-names */
import { DatePicker, Form } from 'antd'
import dayjs from 'dayjs'
import { Component } from 'react'
import { __ } from '../../shared/i18n'
import { AdvancedFormField } from './advancedFormTypes'

interface Props {
  record: any
  item: AdvancedFormField
  handleChange: any
  labelHidden?: boolean
}

const { RangePicker } = DatePicker

export class AdvancedFormRangeDateField extends Component<Props> {
  render() {
    const { item, handleChange, labelHidden } = this.props
    return (
      <Form.Item
        name={item.key.split('.')}
        className="stylewhere-form-item"
        rules={item.rules}
        label={labelHidden ? null : __(`fields.labels.${item.key}`, item.label)}
      >
        <RangePicker
          id={item.key}
          allowEmpty={[true, true]}
          style={{ width: '100%' }}
          onChange={(e) => handleChange(e)}
          showTime={{
            defaultValue: [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')],
          }}
          format="DD/MM/YYYY HH:mm:ss" //"YYYY-MM-DD HH:mm:ss"
          placeholder={[item.placeholder || __('misc.from'), item.placeholder || __('misc.to')]}
        />
      </Form.Item>
    )
  }
}
