import { Component } from 'react'
import { LoadingOutlined, MenuOutlined } from '@ant-design/icons'
import { Row, Button, Skeleton, notification, Dropdown, Menu } from 'antd'
import { PageHeader as AntdPageHeader } from '@ant-design/pro-layout'
import { navigate } from '../../shared/router'
import { PageHeaderTitle, Tabs } from '..'
import { StylewhereTab } from './Tabs'
import Operations from '../../api/Operations'
import { __, T } from '../../shared/i18n'
import IconBack from '../../../icon/icon-back-button.png'

interface HeaderAction {
  label: string
  type: 'submit' | 'cancel' | 'operationAction'
  onClick?: (...args: any[]) => any
  buttonClass?: string
  formName?: string
  param?: any
  loading?: boolean
}

interface Props {
  title?: string
  actions?: HeaderAction[]
  backPath?: string
  tabs?: StylewhereTab[]
  pageHeaderClassName?: string
  multipleTitle?: any
  skeleton?: any
  customActions?: any[]
}

interface State {
  selectedTab?: string
  actions?: HeaderAction[]
  currentAction?: HeaderAction | undefined
}

export class DefaultHeader extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      selectedTab: '',
      actions: [],
      currentAction: undefined,
    }
  }

  componentDidMount = () => {
    const { actions } = this.props
    if (actions) {
      this.setState({ actions: this.props.actions })
    }
  }

  shouldComponentUpdate = (nextProps) => {
    const { actions } = this.props
    if (nextProps.actions && (!actions || JSON.stringify(nextProps.actions) !== JSON.stringify(actions))) {
      this.setState({ actions: nextProps.actions })
    }
    return true
  }

  getActionButton = (action, keyIndex) => {
    if (action.type === 'submit') {
      if (action.formName) {
        return (
          <Button
            className={action.buttonClass || 'stylewhere-button-primary'}
            htmlType="submit"
            key={`header_action_${keyIndex}`}
            form={action.formName}
          >
            {action.label}
          </Button>
        )
      }
      return (
        <Button
          className={action.buttonClass || 'stylewhere-button-primary'}
          htmlType="submit"
          key={`header_action_${keyIndex}`}
        >
          {action.label}
        </Button>
      )
    }
    return (
      <Button
        className={action.buttonClass || 'stylewhere-button-default'}
        onClick={action.type === 'operationAction' ? () => this.executeAction(action) : action.onClick}
        key={`header_action_${keyIndex}`}
        loading={action.loading}
      >
        {action.loading ? '' : action.label}
      </Button>
    )
  }

  executeAction = (action) => {
    const { actions } = this.state
    if (actions) {
      const index = actions.findIndex((e) => e.param.actionCode === action.param.actionCode)
      if (index >= 0) {
        actions[index].loading = true
        this.setState(
          {
            actions: actions,
            currentAction: action,
          },
          this.callExcuteAction
        )
      }
    }
  }

  callExcuteAction = async () => {
    const { currentAction, actions } = this.state
    if (actions && currentAction) {
      const result = await Operations.executeAction(
        currentAction.param.prefix,
        currentAction.param.operation,
        currentAction.param.detailId,
        currentAction.param.operationId,
        currentAction.param.actionCode
      )
      if (result) {
        notification.success({
          message: __(T.misc.operationActionSuccess),
          placement: 'bottomRight',
          duration: 3,
        })
      } else {
        notification.error({
          message: __(T.misc.operationActionError),
          placement: 'bottomRight',
          duration: 3,
        })
      }
      const index = actions.findIndex((e) => e.param.actionCode === currentAction.param.actionCode)
      if (index >= 0) {
        actions[index].loading = false
        this.setState({ actions: actions, currentAction: undefined })
      }
    }
  }

  renderAction = (actions) => {
    if (actions) {
      return actions.map((action, index) => {
        return (
          <Menu.Item onClick={() => this.executeAction(action)} key={`action_${index}`}>
            {action.loading ? <LoadingOutlined style={{ fontSize: 20 }} /> : action.label}
          </Menu.Item>
        )
      })
    }
    return null
  }

  getActionMenu = (actions) => {
    return <Menu>{this.renderAction(actions)}</Menu>
  }

  getDropdown = (actions) => {
    const actionMenu = this.getActionMenu(actions)
    return actions ? (
      <Dropdown overlay={actionMenu} placement="bottomRight">
        <Button className="stylewhere-button-secondary">
          {__(T.misc.actions)}
          <MenuOutlined />
        </Button>
      </Dropdown>
    ) : null
  }

  getExtras = () => {
    const { skeleton, customActions } = this.props
    const { actions } = this.state
    let tmp: any = []
    if (skeleton && !skeleton.active) {
      if (actions) {
        if (actions.length <= 2) {
          for (let a = 0; a < actions.length; a++) {
            tmp.push(this.getActionButton(actions[a], a))
          }
        } else {
          tmp.push(this.getDropdown(actions))
        }
      } else if (customActions) {
        tmp = customActions
      }
    }
    return tmp
  }

  handleTabButtonClick = (tab: { label: string; url: string }) => {
    this.setState({ selectedTab: tab.label })
    navigate(tab.url)
  }

  getPageTitle = () => {
    const { title, multipleTitle, tabs, skeleton } = this.props
    if (skeleton.active) {
      if (multipleTitle) {
        return (
          <PageHeaderTitle
            list={[
              { widthLabel: '60%', widthDesc: '100%' },
              { class: 'small', widthLabel: '60%', widthDesc: '100%' },
              { class: 'small', widthLabel: '100%', widthDesc: '80%' },
            ]}
            skeleton
          />
        )
      }
      return (
        <Skeleton
          className={`stw-skeleton-base ${(skeleton.options && skeleton.options.titleClass) || ''}`}
          title={false}
          round
          active
          paragraph={{ rows: 1, width: '100%' }}
        />
      )
    }
    if (tabs) {
      return <Tabs tabs={tabs || []} selected={this.state.selectedTab || ''} change={this.handleTabButtonClick} />
    }
    if (multipleTitle && multipleTitle.length > 0) {
      return <PageHeaderTitle list={multipleTitle} />
    }
    return title
  }

  render() {
    const { backPath, pageHeaderClassName } = this.props
    return (
      <Row style={{ width: '100%', position: 'relative', marginBottom: 15 }}>
        <AntdPageHeader
          className={pageHeaderClassName || ''}
          style={{ width: '100%' }}
          ghost={false}
          title={this.getPageTitle()}
          extra={this.getExtras()}
          onBack={backPath ? () => navigate(backPath) : undefined}
          backIcon={<div style={{ backgroundImage: `url(${IconBack})` }} />}
        />
      </Row>
    )
  }
}
