import { Component } from 'react'
import { StwReportQueryResult, DetailCell, StwOperation, StwOperationAction } from 'core/api'
import { AdvancedTableSortDirection } from 'core/components/AdvancedTable'
import Reports from 'core/api/Reports'
import { KeyValueGrid, StylewherePage, DefaultHeader, NotFound, Section, TableListReport } from '../../../components'
import { __, T } from '../../../shared/i18n'
import { SkeletonAttributeReport } from '../../../constants'
import { getFragmentObject, getMultipleTitle, getBackURL, getHeaderOperationActions } from '../../../shared/utils'
import { ExtendRouteComponentProps } from '../../../types'
import Operations from '../../../api/Operations'

interface State {
  reportInstance?: StwReportQueryResult
  configuration: DetailCell[]
  loader: boolean
  operationInstanceId: string | undefined
  actions: StwOperationAction[]
}

export default class SimpleParcelListOperationDetail extends Component<ExtendRouteComponentProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      operationInstanceId: undefined,
      configuration: [],
      loader: true,
      actions: [],
    }
  }

  async componentDidMount(): Promise<void> {
    const { match } = this.props
    if (match && match.params && match.params.operationInstanceId) {
      const params = { operationId: match.params.operationId }
      const reportId = await Operations.getReportId(match.params.operationId)
      const instance: any = await Reports.reportInstance(reportId, match.params.operationInstanceId, params)
      if (instance && instance.id) {
        const operation: StwOperation = await Operations.get<StwOperation>(match.params.operationId)
        const configuration = await Reports.getConfiguration(reportId, params)
        this.setState({
          reportInstance: instance,
          configuration: configuration,
          operationInstanceId: match.params.operationInstanceId,
          loader: false,
          actions: operation && operation.actions && operation.actions !== null ? operation.actions : [],
        })
      } else {
        this.setState({
          loader: false,
        })
      }
    } else {
      this.setState({
        loader: false,
      })
    }
  }

  getDetailPath = () => {
    const { match } = this.props
    if (match.params.operationId && match.params.operationInstanceId)
      return `/operationlogs/SIMPLE_PARCEL_LIST/${match.params.operationId}/${match.params.operationInstanceId}/parcel`
    return `/reports/parcel`
  }

  render() {
    const { breadcrumbs, queryString, match } = this.props
    const { reportInstance, configuration, loader, operationInstanceId, actions } = this.state
    const fragment = getFragmentObject(reportInstance, 'id')
    return (
      <StylewherePage {...this.props} noOverflow fragment={fragment}>
        <DefaultHeader
          backPath={getBackURL(queryString, breadcrumbs)}
          multipleTitle={getMultipleTitle(reportInstance)}
          title={__(T.misc.not_found)}
          skeleton={{ active: loader, options: { multiple: true } }}
          actions={
            reportInstance
              ? getHeaderOperationActions(
                  'logistics',
                  'simpleParcelList',
                  actions,
                  match.params.operationId,
                  match.params.operationInstanceId
                )
              : []
          }
        />
        <Section customClass={`stw-section-page paged-header ${reportInstance || loader ? 'scroll transparent' : ''}`}>
          {reportInstance || loader ? (
            <>
              <Section>
                <KeyValueGrid
                  fields={loader ? SkeletonAttributeReport : configuration}
                  data={reportInstance}
                  skeleton={loader}
                />
              </Section>
              {operationInstanceId && (
                <TableListReport
                  {...this.props}
                  reportId="parcel"
                  actions={[]}
                  fixedTableHeight
                  disabledReportExport
                  headerType="boxed"
                  title={__(T.titles.parcels)}
                  customColumnPrefix="simpleParcelListDetailParcels"
                  reportActions={{
                    active: true,
                    onlyedit: false,
                  }}
                  reportParams={{ simpleParcelListId: operationInstanceId }}
                  disableLocation
                  pathDetail={this.getDetailPath()}
                  sortValues={{ creationDate: AdvancedTableSortDirection.desc }}
                />
              )}
            </>
          ) : (
            <NotFound fullheight title={__(T.misc.noRecordFoundTitle)} />
          )}
        </Section>
      </StylewherePage>
    )
  }
}
