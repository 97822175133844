import { Component } from 'react'
import { Button, Card, Col, Tag, Typography } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import Meta from 'antd/es/card/Meta'
import { StwBalance } from '../../api'
import Balances from '../../api/Balances'
import { __ } from '../../shared/i18n'

interface Props {
  balanceId: string
  onRemove: () => void
}

interface State {
  balance?: StwBalance
}

const { Text } = Typography

export default class AdvancedFormWorkstationBalanceFieldCard extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this._init()
  }

  _init = async () => {
    await this.setBalance(this.props.balanceId)
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (this.props.balanceId !== prevProps.balanceId) {
      this._init()
    }
  }

  setBalance = async (balanceId: string) => {
    const balance = await Balances.get<StwBalance>(balanceId)
    this.setState({ balance })
  }

  render() {
    const { onRemove } = this.props
    const { balance } = this.state
    return (
      <Col span={24} style={{ padding: 0, marginBottom: 25 }}>
        {balance && (
          <Card className="stw-grouping-card-content">
            <Meta
              title={
                <div className="stw-grouping-card-content-row">
                  <div className="stw-grouping-card-content-row-info">
                    <Text strong>{balance.code}</Text>
                    <Text>{balance.description}</Text>
                    <div className="stw-grouping-card-content-row-tag">
                      <Tag style={{ fontWeight: 300 }}>
                        <b style={{ fontWeight: 700 }}>{__('fields.labels.ipAddress')}</b>:
                        {balance.settings && balance.settings.ipAddress ? ` ${balance.settings.ipAddress}` : ' n/a'}
                      </Tag>
                      <Tag style={{ fontWeight: 300 }}>
                        <b style={{ fontWeight: 700 }}>{__('fields.labels.port')}</b>:
                        {balance.settings && balance.settings.port ? ` ${balance.settings.port}` : ' n/a'}
                      </Tag>
                    </div>
                  </div>
                  <div className="stw-grouping-card-content-row-btn">
                    <Button
                      type="primary"
                      shape="circle"
                      size="middle"
                      icon={<CloseOutlined />}
                      onClick={() => onRemove()}
                      className="stw-workstation-balance-card-remove-button"
                      style={{ float: 'right', marginTop: '1.5em', marginRight: '1.25em' }}
                    />
                  </div>
                </div>
              }
            />
          </Card>
        )}
      </Col>
    )
  }
}
