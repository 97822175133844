import { Component } from 'react'
import { Button } from 'antd'
import { Page, NotificationRow, NotFound, Section } from '../../components'
import { ExtendRouteComponentProps } from '../../types'
import { __ } from '../../shared/i18n'
import { StwNotification } from '../../api'

interface State {
  notifications: StwNotification[]
  loader: boolean
  firstLoad: boolean
}

const SkeletonNotification: StwNotification[] = [
  {
    id: '1',
  },
  {
    id: '2',
  },
  {
    id: '3',
  },
  {
    id: '4',
  },
  {
    id: '5',
  },
]

class NotificationList extends Component<ExtendRouteComponentProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      notifications: SkeletonNotification,
      loader: true,
      firstLoad: true,
    }
  }

  componentDidMount = () => {
    this.getNotifications()
  }

  shouldComponentUpdate = (nextProps) => {
    const { notifications } = this.state
    if (nextProps.notificationsList && JSON.stringify(nextProps.notificationsList) !== JSON.stringify(notifications)) {
      this.setState({
        notifications: nextProps.notificationsList,
      })
    }
    return true
  }

  getNotifications = async () => {
    this.setState({
      notifications: this.props.notificationsList || [],
      loader: false,
      firstLoad: false,
    })
  }

  handleDeleteAll = async () => {
    this.setState({ loader: true, notifications: SkeletonNotification })
    //delete all notifications
    document.dispatchEvent(new Event('notifications'))
  }

  handleDelete = async () => {
    this.setState({ loader: true, notifications: SkeletonNotification })
    //delete single notification
    document.dispatchEvent(new Event('notifications'))
  }

  handleDetail = (notification) => {
    this.props.handleNotificationDetail(notification)
  }

  render() {
    const { location, history, match, breadcrumbs } = this.props
    const { notifications, loader, firstLoad } = this.state
    const canDelete = false
    return (
      <Page
        title={__('menu.notifications')}
        location={location}
        history={history}
        match={match}
        breadcrumbs={breadcrumbs}
        actions={
          canDelete && !loader
            ? [
                <Button className="stylewhere-button-secondary" onClick={() => this.handleDeleteAll()} key="1">
                  {__('misc.removeAll')}
                </Button>,
              ]
            : []
        }
        skeleton={loader && firstLoad}
      >
        {notifications && notifications.length > 0 ? (
          <NotificationRow
            notifications={notifications}
            loader={loader}
            handleDetail={this.handleDetail}
            handleDelete={this.handleDelete}
            canDelete={canDelete}
          />
        ) : (
          <Section fullheight>
            <NotFound title={__('misc.noRecordFoundTitle')} text={__('misc.noRecordFoundText')} fullheight />
          </Section>
        )}
      </Page>
    )
  }
}

export default NotificationList
