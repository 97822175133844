import { Button, Card, Space } from 'antd'
import { Component } from 'react'
import { CloseOutlined } from '@ant-design/icons'
import { AdvancedForm } from '../AdvancedForm'
import { __ } from '../../shared/i18n'
import { StwFullRfidReaderForm, StwPlace, StwRfidAntenna } from '../../api'
import { AdvancedRfidReaderPlaceholderAntennaCard } from './AdvancedRfidReaderPlaceholderAntennaCard'
import { AdvancedFormField, AdvancedFormInputType } from '../AdvancedForm/advancedFormTypes'

interface Props {
  handleChange: (port, key, value) => void
  handleDelete: (port) => void
  antenna: StwRfidAntenna
  readerPort: number
  title: string
  place?: StwPlace
  rfidReader?: StwFullRfidReaderForm
}

interface State {
  antenna: StwRfidAntenna
  fieldsAntenna: AdvancedFormField[]
}

export class AdvancedRfidReaderAntennaCard extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      antenna: this.props.antenna,
      fieldsAntenna: [],
    }
  }

  componentDidMount() {
    this.initialize()
  }

  initialize = () => {
    const { antenna } = this.state
    const fields = antenna ? this.getFields(antenna.readerPort) : []
    this.setState({ fieldsAntenna: fields })
  }

  shouldComponentUpdate = (nextProps) => {
    const { antenna } = this.state
    if (
      nextProps.antenna &&
      nextProps.antenna.readerPort &&
      JSON.stringify(nextProps.antenna) !== JSON.stringify(antenna)
    ) {
      this.setState({
        antenna: nextProps.antenna,
        fieldsAntenna: this.getFields(nextProps.antenna.readerPort),
      })
    } else if (!nextProps.antenna && antenna) {
      this.setState({
        antenna: nextProps.antenna,
        fieldsAntenna: [],
      })
    }
    return true
  }

  createAntenna = async () => {
    const { handleChange, readerPort } = this.props
    handleChange(readerPort, 'new', '')
  }

  deleteAntenna = () => {
    const { antenna } = this.state
    const { handleDelete } = this.props
    handleDelete(antenna.readerPort)
  }

  handleChange = (key, value) => {
    const { handleChange, readerPort } = this.props
    handleChange(readerPort, key, value)
  }

  getCloseButton() {
    return (
      <Space className="stylewhere-table-actions" onClick={this.deleteAntenna}>
        <Button type="default">
          <CloseOutlined />
        </Button>
      </Space>
    )
  }

  getFields = (readerPort) => {
    const fields: AdvancedFormField[] = [
      {
        key: `antenna${readerPort}.code`,
        label: __('fields.labels.code'),
        type: AdvancedFormInputType.TEXT,
        col: 24,
        placeholder: __('fields.placeholders.code'),
        rules: [{ required: true }],
      },
      {
        key: `antenna${readerPort}.description`,
        label: __('fields.labels.description'),
        type: AdvancedFormInputType.TEXT,
        col: 24,
        placeholder: __('fields.placeholders.description'),
      },
      {
        key: `antenna${readerPort}.zoneId`,
        label: __('fields.labels.zone'),
        type: AdvancedFormInputType.AUTOCOMPLETE,
        placeholder: __('fields.placeholders.zone'),
        autocomplete: {
          endpoint: '/api/v1/zones',
          sort: 'code,asc',
          multiple: false,
          withIdValues: true,
        },
        col: 24,
        option: 'conditions',
        rules: [{ required: true }],
        autoSelectable: true,
      },
      {
        key: `antenna${readerPort}.txPower`,
        type: AdvancedFormInputType.SLIDER,
        label: __('fields.labels.txPower'),
        range: { min: 10, max: 30 },
        col: 24,
      },
      {
        key: `antenna${readerPort}.rxSensitivity`,
        type: AdvancedFormInputType.SLIDER,
        label: __('fields.labels.rxSensitivity'),
        range: { min: -80, max: 0 },
        col: 24,
      },
    ]
    return fields
  }

  render() {
    const { title, place, rfidReader } = this.props
    const { antenna, fieldsAntenna } = this.state
    return (
      <>
        {antenna && antenna.readerPort && (
          <Card
            headStyle={{ color: '#333' }}
            size="small"
            className="swt-antenna-card"
            title={title}
            extra={this.getCloseButton()}
          >
            <AdvancedForm
              key="nested-form"
              fields={fieldsAntenna}
              record={rfidReader}
              width="100%"
              parameters={{ conditions: { placeIds: place?.id ?? '', type: 'STOCK' } }}
              handleChange={(key, value) => this.handleChange(key, value)}
            />
          </Card>
        )}
        {!antenna && <AdvancedRfidReaderPlaceholderAntennaCard handleOnClick={this.createAntenna} />}
      </>
    )
  }
}
