import { Component } from 'react'
import { Modal } from 'antd'
import { __, T } from '../../shared/i18n'

interface Props {
  visible: boolean
  icon?: any
  title: string
  subtitle?: string
  callback: any
}

export class ModalConfirm extends Component<Props> {
  render() {
    const { title, visible, subtitle, icon, callback } = this.props
    return (
      <Modal
        centered
        open={visible}
        closable={false}
        onOk={() => callback(true)}
        okText={__(T.misc.confirm)}
        onCancel={() => callback(false)}
        cancelText={__(T.misc.cancel)}
      >
        {icon && <p style={{ textAlign: 'center', paddingTop: '30px' }}>{icon}</p>}
        <p style={{ fontWeight: 700, textAlign: 'center' }}>{title}</p>
        {subtitle && subtitle !== '' && <p style={{ fontWeight: 300, textAlign: 'center' }}>{subtitle}</p>}
      </Modal>
    )
  }
}
