import BaseResource, { PageResult } from './BaseResource'
import { StwBaseReport, StwReportMetadata, StwReportQueryResult, DetailCell } from './types'
import { api, responseErrorCheck } from './api'
import { AdvancedTableContentKind } from '../components/AdvancedTable'
import { __ } from '../shared/i18n'

export default class Reports extends BaseResource {
  static endpoint = '/api/v1/reports' ///report

  static reports(): Promise<ReadonlyArray<StwBaseReport>> {
    return api.get<ReadonlyArray<StwBaseReport>>(`${this.endpoint}`).then(responseErrorCheck)
  }

  static metadata(id: string, params?: any): Promise<StwReportMetadata> {
    return api.get<StwReportMetadata>(`${this.endpoint}/${id}`, params).then(responseErrorCheck)
  }

  static report(id: string, params: any): Promise<PageResult<StwReportQueryResult>> {
    return api.get<PageResult<StwReportQueryResult>>(`${this.endpoint}/${id}/execute`, params).then(responseErrorCheck)
  }

  static reportData(id: string, params: any): Promise<PageResult<StwReportQueryResult>> {
    return api.get<PageResult<StwReportQueryResult>>(`${this.endpoint}/execute`, params).then(responseErrorCheck)
  }

  static reportInstance(
    reportId: string,
    reportInstanceId: string,
    params?: any
  ): Promise<StwReportQueryResult | undefined> {
    return Reports.report(reportId, { ...params, id: reportInstanceId })
      .then((response) => (response ? response.content : []))
      .then((reportInstances) => (reportInstances.length > 0 ? reportInstances[0] : undefined))
  }

  static async getConfiguration(reportId: string, params: any = {}): Promise<DetailCell[]> {
    const reportMetadata = await Reports.metadata(reportId, params)
    const details: DetailCell[] = []
    for (let c = 0; c < reportMetadata.columns.length; c++) {
      details.push({
        title: reportMetadata.columns[c].description ?? __(`fields.labels.${reportMetadata.columns[c].parameter}`),
        attribute: reportMetadata.columns[c].parameter,
        columns: 8,
        type: Reports.advancedTableContentKindToDetailCellType(reportMetadata.columns[c].type),
      })
    }
    return details
  }

  static advancedTableContentKindToDetailCellType(advancedTableContentKind: AdvancedTableContentKind) {
    switch (advancedTableContentKind) {
      case AdvancedTableContentKind.STRING:
      case AdvancedTableContentKind.MULTIPLE_STRING:
      case AdvancedTableContentKind.IDENTIFIERS:
      case AdvancedTableContentKind.STATUS:
      case AdvancedTableContentKind.NUMBER:
        return 'string'
      case AdvancedTableContentKind.DATE:
      case AdvancedTableContentKind.DATE_TIME:
        return 'date'
      case AdvancedTableContentKind.BOOLEAN:
        return 'boolean'
      default:
        return undefined
    }
  }
}
