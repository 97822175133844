import { FormInstance } from 'antd'
import { RefObject } from 'react'
import { FormUtility } from './formUtility'

export class DeviceUtility extends FormUtility {
  //TODO capire come portare a bordo anche l'updateCodeAndDescription

  public static resetCodeAndDescription(entity, formRef: RefObject<FormInstance>): void {
    super.setFieldValue(entity, 'code', '', formRef)
    super.setFieldValue(entity, 'description', '', formRef)
  }
}
