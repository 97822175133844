import { Component } from 'react'
import { Button, Row, Select } from 'antd'
import { uniqueBy } from 'core/shared/utils'
import AutocompleteApi from '../../api/AutocompleteApi'
import { __ } from '../../shared/i18n'

interface Props {
  value?: any
  onChange?: (value: any) => void
  placeholder: string
  selector?: string
  withMultipleSelection?: boolean
  readonly?: boolean
  endpoint: string
  sort: string
  filters?: any
  withAdd?: boolean
  onAdd?: () => void
  withIdValues?: boolean
  hideSelectedValues?: boolean
  sizeAutocomplete?: number
  maxTagCount?: number
  fieldLabel?: string
  autoSelectable?: boolean
  selectionOption?: any
}

interface State {
  selections: any[]
  suggestions: any[]
  checkUniqueSuggestion: boolean
  searchSelection: boolean
  updating: boolean
}

class SelectWithAutocompletion extends Component<Props, State> {
  searchTimeout?: any
  searchIdentifier = Math.random()

  constructor(props) {
    super(props)
    this.state = {
      selections: [],
      suggestions: [],
      checkUniqueSuggestion: true,
      searchSelection: true,
      updating: false,
    }
  }

  componentDidMount = () => {
    this.startUpdateValues()
  }

  componentDidUpdate = async (prevProps) => {
    await this.checkSearchSelection(prevProps.value)
    if (this.selectionsChanged() || this.excludedIdsChanged(prevProps)) {
      this.startUpdateValues()
    }
  }

  checkSearchSelection = async (value) => {
    if (!this.state.searchSelection && JSON.stringify(value) !== JSON.stringify(this.props.value)) {
      await this.setState({ searchSelection: true })
    }
  }

  get_selection_values = (values) => {
    const { selector } = this.props
    const tmp: any = []
    for (let v = 0; v < values.length; v++) {
      tmp.push(values[v][selector || 'id'] || values[v])
    }
    return tmp
  }

  async getSelections(): Promise<any[]> {
    const { value, withIdValues, endpoint, selectionOption } = this.props
    let values = Array.isArray(value ?? []) ? value ?? [] : [value]
    if (withIdValues && values.length > 0) {
      if (!selectionOption) {
        values = await AutocompleteApi.search(endpoint, { ids: this.get_selection_values(values) }).then(
          (response) => response.content ?? []
        )
      } else {
        const filter = {}
        filter[selectionOption.filter] = selectionOption.singleValue ? value : this.get_selection_values(values)
        values = await AutocompleteApi.search(endpoint, filter).then((response) => response.content ?? [])
      }
      if (values.length === 0) {
        this.setState({ searchSelection: false })
      }
    }
    return values
  }

  selectionsChanged() {
    const { selections, searchSelection } = this.state
    const { value, withIdValues, selector } = this.props
    const currentSelections = Array.isArray(value ?? []) ? value ?? [] : [value]
    const previousSelections = selections
    return (
      searchSelection &&
      (currentSelections.length !== selections.length ||
        currentSelections.some(
          (currentSelection) =>
            !previousSelections.some((previousSelection) =>
              withIdValues
                ? previousSelection[selector ?? 'id'] === currentSelection
                : previousSelection[selector ?? 'id'] === currentSelection[selector ?? 'id']
            )
        ))
    )
  }

  excludedIdsChanged(prevProps) {
    if (!this.props.filters || !prevProps.filters) {
      return false
    }
    const currentIds = Array.isArray(this.props.filters.excludedIds)
      ? this.props.filters.excludedIds ?? []
      : [this.props.filters.excludedIds]
    const prevIds = Array.isArray(prevProps.filters.excludedIds)
      ? prevProps.filters.excludedIds ?? []
      : [prevProps.filters.excludedIds]
    return currentIds.length !== prevIds.length
  }

  startUpdateValues = () => {
    if (!this.state.updating) {
      this.setState({ updating: true }, this.updateValues)
    }
  }

  async updateValues() {
    const selections = await this.getSelections()
    const suggestions = await this.getSuggestions()
    this.setState({ selections: selections, suggestions: suggestions }, this.checkUniqueSuggestion)
  }

  checkUniqueSuggestion = () => {
    const { suggestions, checkUniqueSuggestion } = this.state
    const { autoSelectable, selectionOption } = this.props
    if (autoSelectable && checkUniqueSuggestion && suggestions.length === 1) {
      this.handleChange(suggestions[0].id)
      this.setState({ checkUniqueSuggestion: false, updating: false })
    } else {
      if (selectionOption && selectionOption !== '') {
        const defaultSuggestion = suggestions.find((suggestion) => suggestion.code === selectionOption)
        if (defaultSuggestion) {
          this.handleChange(defaultSuggestion.id)
        }
      }
      this.setState({ updating: false })
    }
  }

  getAdditionalFilters = () => {
    const { excludedIds, ...additionalFilters } = this.props.filters ?? {}
    return additionalFilters
  }

  getExcludedIds = () => {
    return Array.from(
      new Set<string>([...(this.props.filters?.excludedIds ?? []), ...this.state.selections.map((value) => value.id)])
    )
  }

  renderOptions = () => {
    const data = uniqueBy([...this.state.suggestions, ...this.state.selections], this.props.selector ?? 'id')
    return data
      .map((opt) => this.toOption(opt))
      .map((opt: any) => (
        <Select.Option key={opt.value} value={opt.value}>
          {opt.label}
        </Select.Option>
      ))
  }

  getSelectedValues = () => {
    return this.state.selections.map(this.toOptionValue)
  }

  getSuggestions = async (search?: string) => {
    const { endpoint, sort, sizeAutocomplete } = this.props
    return AutocompleteApi.search<any>(endpoint, {
      page: 0,
      size: sizeAutocomplete || 20,
      sort: sort,
      search: search,
      excludedIds: this.getExcludedIds(),
      ...this.getAdditionalFilters(),
    }).then((response) => response.content ?? [])
  }

  handleChange = (value: any) => {
    const { selector, onChange, withIdValues, withMultipleSelection } = this.props
    const { selections, suggestions } = this.state
    if (onChange) {
      if (withIdValues) {
        onChange(value)
      } else {
        const objects = [...selections, ...suggestions]
        if (withMultipleSelection) {
          onChange(value.map((aValue) => objects.find((object) => object[selector ?? 'id'] === aValue)))
        } else {
          onChange(objects.find((object) => object[selector ?? 'id'] === value))
        }
      }
    }
  }

  handleSearch = async (search?: string) => {
    this.searchIdentifier = Math.random()
    const currentSearchIdentifier = this.searchIdentifier
    const suggestions = await this.getSuggestions(search)
    if (currentSearchIdentifier === this.searchIdentifier) {
      this.setState({ suggestions: suggestions })
    }
  }

  handleSearchWithDelay = (delay: number) => (search?: string) => {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout)
    }
    this.searchTimeout = setTimeout(() => this.handleSearch(search), delay)
  }

  toOption = (entity: any) => {
    return {
      value: this.toOptionValue(entity),
      label: this.toOptionLabel(entity),
    }
  }

  toOptionLabel = (entity: any) => {
    let label: string
    if (this.props.fieldLabel) {
      label = entity[this.props.fieldLabel]
    } else if (entity.username) {
      label = entity.username
    } else if (entity.description && entity.code) {
      label = `${entity.code}: ${entity.description}`
    } else if (entity.code) {
      label = entity.code
    } else if (entity.identifiers) {
      label = `${entity.product.code} ${entity.product.description ?? ''} ${entity.identifiers
        .map((itemIdentifier) => `${itemIdentifier.type}: ${itemIdentifier.code}`)
        .join(', ')}`
    } else {
      label = entity[this.props.selector ?? 'id']
    }
    return label
  }

  toOptionValue = (entity: any) => {
    return entity[this.props.selector ?? 'id']
  }

  render() {
    const { placeholder, readonly, withMultipleSelection, withAdd, onAdd, maxTagCount } = this.props
    return (
      <Row gutter={24} className="stylewhere-select-with-autocompletion">
        <div style={{ flex: 1, flexDirection: 'row', width: '100%' }}>
          <Select
            value={this.getSelectedValues()}
            allowClear
            showSearch
            style={{ width: '100%' }}
            placeholder={placeholder}
            disabled={readonly}
            mode={withMultipleSelection ? 'multiple' : undefined}
            maxTagCount={maxTagCount}
            onSearch={this.handleSearchWithDelay(300)}
            onChange={this.handleChange}
            onClear={this.handleSearch}
            onFocus={() => this.handleSearch()}
            filterOption={false}
          >
            {this.renderOptions()}
          </Select>
        </div>
        {withAdd && onAdd && (
          <div className="stylewhere-select-with-autocompletion-button">
            <Button className="stylewhere-button-secondary" onClick={() => onAdd()}>
              {__('misc.add_new')}
            </Button>
          </div>
        )}
      </Row>
    )
  }
}

export default SelectWithAutocompletion
