import { Component } from 'react'
import { GridData, StylewherePage, Section, DefaultHeader, NotFound, TableList } from '../../../components'
import Places from '../../../api/Places'
import { __, T } from '../../../shared/i18n'
import { DetailCell, StwPlace } from '../../../api'
import { AdvancedTableConfig } from '../../../components/AdvancedTable'
import { getZoneColumns, getZoneFilters } from '../../../config/Pages/Zones'
import Zones from '../../../api/Zones'
import { ExtendRouteComponentProps } from '../../../types'
import { PLACE_DETAIL } from '../../../config/Pages/Places'
import {
  getDetailDataWithAttributesMapping,
  getMultipleTitle,
  getBackURL,
  getFragmentObject,
} from '../../../shared/utils'
import { ZoneTypes } from '../../../constants'

const ZONE_TABLE_CONFIG: AdvancedTableConfig = {
  columns: getZoneColumns(false),
  filterDefinitions: getZoneFilters(false),
  filterParameters: { types: ZoneTypes },
  manageColumnsPrefix: 'zones',
  entity: 'zone',
  activeDefaultClassColumn: true,
  fixedTableHeight: true,
  disableLocation: true,
  disableColumnFilters: true,
  skeletonOptions: {},
  disabledNoDataFound: true,
}

interface State {
  place: StwPlace | undefined
  loader: boolean
  detailData: DetailCell[]
}

class PlaceDetail extends Component<ExtendRouteComponentProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      place: undefined,
      loader: true,
      detailData: PLACE_DETAIL,
    }
  }

  async componentDidMount() {
    const { match } = this.props
    const data: DetailCell[] = await getDetailDataWithAttributesMapping(PLACE_DETAIL, 'place', 2)
    let place
    if (match && match.params && match.params.placeId) {
      place = await Places.get<StwPlace>(match.params.placeId).then((res) => res)
    }
    this.setState({
      place: place || undefined,
      loader: false,
      detailData: data,
    })
  }

  render() {
    const { breadcrumbs, queryString } = this.props
    const { place, loader, detailData } = this.state
    const fragment = getFragmentObject(place, 'code')
    return (
      <StylewherePage {...this.props} noOverflow fragment={fragment}>
        <DefaultHeader
          backPath={getBackURL(queryString, breadcrumbs)}
          multipleTitle={getMultipleTitle(place)}
          title={__(T.misc.not_found)}
          skeleton={{ active: loader, options: { multiple: true } }}
        />
        <Section customClass={`stw-section-page paged-header ${place || loader ? 'scroll transparent' : ''}`}>
          {place || loader ? (
            <>
              <GridData fields={detailData || PLACE_DETAIL} record={place || {}} skeleton={loader} />
              {place && (
                <TableList
                  title={__(T.menu.zones)}
                  location={this.props.location}
                  headerType="boxed"
                  config={ZONE_TABLE_CONFIG}
                  resource={{
                    call: Zones.searchTableList,
                    endpoint: Zones.endpoint,
                    filters: { placeId: place.id },
                  }}
                />
              )}
            </>
          ) : (
            <NotFound fullheight title={__(T.misc.noRecordFoundTitle)} />
          )}
        </Section>
      </StylewherePage>
    )
  }
}

export default PlaceDetail
