import { Component } from 'react'
import { Checkbox, Col, Typography } from 'antd'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { __ } from 'core/shared/i18n'
import DragIcon from '../../icon/drag-manage-column.png'

const { Text } = Typography

interface Props {
  items: any[]
  field: string
  itemRender: (item: any) => JSX.Element | string
  onChange: (items: any[]) => void
  style?: React.CSSProperties
}

interface State {
  items: any[]
  indeterminate: boolean
  checkAll: boolean
}

export default class DraggableCheckboxList extends Component<Props, State> {
  state: State = {
    items: JSON.parse(JSON.stringify(this.props.items)),
    indeterminate: true,
    checkAll: false,
  }

  onChange = (e, index) => {
    const { items } = this.state
    const { field } = this.props
    items[index][field] = !items[index][field]
    this.setState({
      items,
      indeterminate: true,
    })
    this.props.onChange(items)
  }

  reorder = (startIndex, endIndex) => {
    const { items } = this.state
    const result = Array.from(items)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    this.props.onChange(items)
    return result
  }

  onDragEnd = (result) => {
    if (result.destination) {
      const reorderItems = this.reorder(result.source.index, result.destination.index)
      this.setState({
        items: reorderItems,
      })
    }
  }

  getItemStyle = (isDragging, draggableStyle) => {
    return {
      // some basic styles to make the items look a bit nicer
      userSelect: 'none',

      // change background colour if dragging
      background: isDragging ? '#F2F2F2' : 'white',

      // styles we need to apply on draggables
      ...draggableStyle,
    }
  }

  getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'white' : 'white',
  })

  onCheckAllChange = (e) => {
    const { field } = this.props
    const { items } = this.state
    items.forEach((item) => {
      item[field] = !e.target.checked
    })
    this.setState({ items, indeterminate: false, checkAll: e.target.checked }, () => this.props.onChange(items))
  }

  render() {
    const { field, itemRender, style } = this.props
    const { items, indeterminate, checkAll } = this.state
    return (
      <div style={{ ...style, overflowY: 'scroll' }}>
        <Checkbox indeterminate={indeterminate} onChange={this.onCheckAllChange} checked={checkAll}>
          {__('misc.check_all')}
        </Checkbox>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={this.getListStyle(snapshot.isDraggingOver)}
              >
                {items.map((item, i) => {
                  return !item.unmanageable ? (
                    <Draggable key={item.key} draggableId={item.key} index={i}>
                      {(provided1, snapshot1) => (
                        <div
                          ref={provided1.innerRef}
                          {...provided1.draggableProps}
                          {...provided1.dragHandleProps}
                          style={this.getItemStyle(snapshot1.isDragging, provided1.draggableProps.style)}
                        >
                          <Col span={24} key={item.key} className="stylewhere-columns-manager-layer-column">
                            <div className="drag-icon" style={{ backgroundImage: `url(${DragIcon})` }} />
                            <Checkbox checked={!item[field]} onChange={(e) => this.onChange(e, i)}>
                              <Text style={{ flex: 1 }}>{itemRender(item)}</Text>
                            </Checkbox>
                          </Col>
                        </div>
                      )}
                    </Draggable>
                  ) : null
                })}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    )
  }
}
