import { Component } from 'react'
import { Button, Col, Row } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import { __ } from '../../shared/i18n'
import { SectionTitle } from '../index'
import AdvancedFormWorkstationRfidAntennasFieldCardList from './AdvancedFormWorkstationRfidAntennasFieldCardList'

interface Props {
  rfidAntennaIds: string[]
  handleChange: (value) => void
  showRfidAntennasModal: any
  placeId?: string
}

export class AdvancedFormWorkstationRfidAntennasField extends Component<Props> {
  render() {
    const { rfidAntennaIds, handleChange, showRfidAntennasModal, placeId } = this.props
    return (
      <>
        <Row gutter={24} style={{ marginTop: 10 }}>
          <Col span={24}>
            <SectionTitle title={__('fields.labels.rfidAntennas')} marginBottom={20} />
          </Col>
          <Col span={24}>
            <AdvancedFormWorkstationRfidAntennasFieldCardList
              rfidAntennaIds={rfidAntennaIds}
              handleChange={handleChange}
              onAddRfidAntenna={() => showRfidAntennasModal()}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Button
              type="dashed"
              block
              disabled={placeId === ''}
              size="large"
              className="stw-grouping-card-button"
              onClick={() => showRfidAntennasModal()}
              icon={<PlusCircleOutlined />}
            >
              <b> {__('misc.add_antennas')}</b>
            </Button>
          </Col>
        </Row>
      </>
    )
  }
}
