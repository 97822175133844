import { Component } from 'react'
import {
  ITEM_CONFIGURATION_DETAILS_COLUMN,
  ITEM_CONFIGURATIONS_DATA,
} from '../../../../config/Pages/Settings/ItemConfigurations'
import { ExtendRouteComponentProps } from '../../../../types'
import { AdvancedTableContent, AdvancedTablePagination } from '../../../../components/AdvancedTable'
import { StwItemConfiguration } from '../../../../api'
import ItemConfigurations from '../../../../api/ItemConfigurations'
import { AdvancedTable, GridData, StylewherePage, DefaultHeader, NotFound, Section } from '../../../../components'
import { __, T } from '../../../../shared/i18n'
import { getMultipleTitle, getBackURL, getFragmentObject } from '../../../../shared/utils'

interface State {
  itemConfiguration: StwItemConfiguration | undefined
  loader: boolean
  tableContent: AdvancedTableContent<any>
  totalElements: number
  tablePagination: AdvancedTablePagination
}

class ItemConfigurationDetail extends Component<ExtendRouteComponentProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      itemConfiguration: undefined,
      loader: true,
      tableContent: [],
      totalElements: 0,
      tablePagination: {
        filterValues: undefined,
        pageSize: 100,
        sortValues: {},
        pageNumber: 1,
      },
    }
  }

  componentDidMount() {
    this.initialize()
  }

  initialize = async () => {
    let itemConfiguration: StwItemConfiguration = {}
    if (this.props.match) {
      const itemConfigurationId = this.props.match.params.itemConfigurationId
      if (itemConfigurationId) {
        itemConfiguration = await ItemConfigurations.get<StwItemConfiguration>(itemConfigurationId)
      }
    }

    this.setState({
      itemConfiguration: itemConfiguration,
      tableContent: itemConfiguration.details || [],
      totalElements: itemConfiguration.details ? itemConfiguration.details.length : 0,
      loader: false,
    })
  }

  render() {
    const { itemConfiguration, loader, totalElements, tableContent, tablePagination } = this.state
    const { breadcrumbs, queryString } = this.props
    const fragment = getFragmentObject(itemConfiguration, 'code')
    return (
      <StylewherePage {...this.props} noOverflow fragment={fragment}>
        <DefaultHeader
          backPath={getBackURL(queryString, breadcrumbs)}
          multipleTitle={getMultipleTitle(itemConfiguration)}
          title={__(T.misc.not_found)}
          skeleton={{ active: loader, options: { multiple: true } }}
        />
        <Section
          customClass={`stw-section-page paged-header ${itemConfiguration || loader ? 'scroll transparent' : ''}`}
        >
          {itemConfiguration || loader ? (
            <>
              <GridData fields={ITEM_CONFIGURATIONS_DATA} record={loader ? [] : itemConfiguration} skeleton={loader} />
              <Section>
                <AdvancedTable
                  loader={loader}
                  tableTitle={{
                    title: __(T.misc.identifiers_title),
                    emptyTitle: __(T.itemconfiguration.noDetailsFoundTitle),
                  }}
                  pagination={tablePagination}
                  columns={ITEM_CONFIGURATION_DETAILS_COLUMN}
                  filters={{
                    filterDefinitions: [],
                  }}
                  content={tableContent}
                  totalElements={totalElements}
                  disabledNoDataFound
                  disabledPagination
                  activeDefaultClassColumn
                />
              </Section>
            </>
          ) : (
            <NotFound fullheight title={__(T.misc.noRecordFoundTitle)} />
          )}
        </Section>
      </StylewherePage>
    )
  }
}

export default ItemConfigurationDetail
