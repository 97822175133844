import { Component, createRef } from 'react'
import { Button, Form, FormInstance, Row } from 'antd'
import { AdvancedForm, Page, Section, SkeletonForm } from '../../../components'
import {
  AdvancedFormAttributeType,
  AdvancedFormField,
  AdvancedFormInputType,
} from '../../../components/AdvancedForm/advancedFormTypes'
import { __ } from '../../../shared/i18n'
import { StwProduct } from '../../../api'
import { BreadcrumbsFragmentType } from '../../../components/PageBread'
import { RouteParams } from '../../../types'
import { AttributeUtil } from '../../../config/utility/utility'
import { FormUtility } from '../../../config/utility/formUtility'
import { navigate } from '../../../shared/router'
import { FormValidateMessages } from '../../../constants'
import Products from '../../../api/Products'

const PRODUCT_FIELDS: AdvancedFormField[] = [
  {
    key: 'description',
    label: __('fields.labels.description'),
    type: AdvancedFormInputType.TEXT,
    col: 16,
    fillColSpan: 8,
    placeholder: __('fields.placeholders.description'),
  },
  {
    key: 'code',
    type: AdvancedFormInputType.UNIQUE_TEXT,
    label: __('fields.labels.code'),
    placeholder: __('fields.placeholders.code'),
    rules: [{ required: true }],
    unique: {
      endpoint: '/api/v1/products',
      parameter: 'equalCodes',
    },
    col: 16,
    fillColSpan: 8,
  },
  {
    key: 'itemType',
    type: AdvancedFormInputType.AUTOCOMPLETE,
    label: __('fields.labels.item_type'),
    placeholder: __('fields.placeholders.item_type'),
    autocomplete: {
      endpoint: '/api/v1/itemTypes',
      sort: 'code,asc',
      propertyId: 'code',
      multiple: false,
    },
    rules: [{ required: true }],
    col: 16,
    fillColSpan: 8,
  },
  {
    key: 'shortDescription',
    label: __('fields.labels.shortDescription'),
    type: AdvancedFormInputType.TEXT,
    col: 16,
    fillColSpan: 8,
    placeholder: __('fields.placeholders.shortDescription'),
  },
  {
    key: 'style.value',
    label: __('fields.labels.styleValue'),
    type: AdvancedFormInputType.TEXT,
    col: 16,
    fillColSpan: 8,
    placeholder: __('fields.placeholders.styleValue'),
  },
  {
    key: 'style.description',
    label: __('fields.labels.styleDescription'),
    type: AdvancedFormInputType.TEXT,
    col: 16,
    fillColSpan: 8,
    placeholder: __('fields.placeholders.styleDescription'),
  },
  {
    key: 'material.value',
    label: __('fields.labels.materialValue'),
    type: AdvancedFormInputType.TEXT,
    col: 16,
    fillColSpan: 8,
    placeholder: __('fields.placeholders.materialValue'),
  },
  {
    key: 'material.description',
    label: __('fields.labels.materialDescription'),
    type: AdvancedFormInputType.TEXT,
    col: 16,
    fillColSpan: 8,
    placeholder: __('fields.placeholders.materialDescription'),
  },
  {
    key: 'color.value',
    label: __('fields.labels.colorValue'),
    type: AdvancedFormInputType.TEXT,
    col: 16,
    fillColSpan: 8,
    placeholder: __('fields.placeholders.colorValue'),
  },
  {
    key: 'color.description',
    label: __('fields.labels.colorDescription'),
    type: AdvancedFormInputType.TEXT,
    col: 16,
    fillColSpan: 8,
    placeholder: __('fields.placeholders.colorDescription'),
  },
  {
    key: 'size.value',
    label: __('fields.labels.sizeValue'),
    type: AdvancedFormInputType.TEXT,
    col: 16,
    fillColSpan: 8,
    placeholder: __('fields.placeholders.sizeValue'),
  },
  {
    key: 'size.description',
    label: __('fields.labels.sizeDescription'),
    type: AdvancedFormInputType.TEXT,
    col: 16,
    fillColSpan: 8,
    placeholder: __('fields.placeholders.sizeDescription'),
  },
  {
    key: 'season',
    label: __('fields.labels.season'),
    type: AdvancedFormInputType.TEXT,
    col: 16,
    fillColSpan: 8,
    placeholder: __('fields.placeholders.season'),
  },
  {
    key: 'rfidEnabled',
    type: AdvancedFormInputType.SWITCH,
    label: __('fields.labels.rfidEnabled'),
    col: 16,
    fillColSpan: 8,
    placeholder: __('fields.placeholders.rfidEnabled'),
  },
  {
    key: 'drop',
    label: __('fields.labels.drop'),
    type: AdvancedFormInputType.TEXT,
    col: 16,
    fillColSpan: 8,
    placeholder: __('fields.placeholders.drop'),
  },
  {
    key: 'attributes',
    label: __('fields.labels.attributes.title'),
    type: AdvancedFormInputType.ATTRIBUTES,
    subtype: AdvancedFormAttributeType.PRODUCT,
    activeAddModal: false,
    col: 24,
    entity: 'product',
  },
]

interface State {
  product: StwProduct
  loader: boolean
  fragment?: BreadcrumbsFragmentType[]
  isCodeEditableByDescription: boolean
}

class ProductForm extends Component<RouteParams, State> {
  formRef = createRef<FormInstance>()

  constructor(props) {
    super(props)
    this.state = {
      product: {},
      loader: true,
      isCodeEditableByDescription: true,
    }
  }

  componentDidMount() {
    this.initialize()
  }

  shouldComponentUpdate = (nextProps) => {
    const { product, loader } = this.state
    if (
      nextProps.match.params.productId &&
      nextProps.match.params.productId !== '' &&
      nextProps.match.params.productId !== 'create' &&
      !loader &&
      product.id !== nextProps.match.params.productId
    ) {
      this.reinit()
    }
    return true
  }

  reinit = async () => {
    this.setState(
      {
        loader: true,
      },
      this.initialize
    )
  }

  initialize = async () => {
    let product: StwProduct = {}
    if (this.props.match) {
      const productId = this.props.match.params.productId
      if (productId && productId !== 'create') {
        product = await Products.get<StwProduct>(productId)
      }
    }
    this.setState({
      loader: false,
      product: product,
      isCodeEditableByDescription: !product.id,
      fragment: [
        {
          label: product.id ?? __('products.create_new'),
          active: true,
        },
      ],
    })
  }

  handleChange = async (key, value) => {
    const { product, isCodeEditableByDescription } = this.state
    AttributeUtil.setAttribute(product, key, value)
    if (key === 'description' && isCodeEditableByDescription) {
      FormUtility.handleDescription(value, product, this.formRef)
    } else if (key === 'code') {
      this.setState({ isCodeEditableByDescription: false })
    } else if (key === 'attributes') {
      if (this.formRef && this.formRef.current) {
        this.formRef.current.setFieldsValue({ attributes: product.attributes })
      }
    }
    this.setState({
      product: product,
    })
  }

  store = () => {
    const { product } = this.state
    if (product.id) {
      Products.update<StwProduct>(product).then((value) => this.redirect(value))
    } else {
      Products.insert<StwProduct>(product).then((value) => this.redirect(value))
    }
  }

  render() {
    const { product, loader, fragment } = this.state
    const { breadcrumbs, location, history, match, queryString } = this.props
    const title = product.id ? __('products.edit') : __('products.create_new')
    const btnSaveLabel = product.id ? __('misc.update') : __('misc.create')
    return (
      <Row style={{ width: '100%' }}>
        {loader ? (
          <SkeletonForm
            animate
            fragment={fragment}
            location={location}
            history={history}
            match={match}
            breadcrumbs={breadcrumbs}
          />
        ) : (
          <Form
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            layout="vertical"
            onFinish={this.store}
            style={{ width: '100%', height: '100%' }}
            ref={this.formRef}
            initialValues={product}
            validateMessages={FormValidateMessages}
            scrollToFirstError
          >
            <Page
              location={location}
              history={history}
              match={match}
              fragment={fragment}
              breadcrumbs={breadcrumbs}
              title={title}
              actions={[
                <Button
                  className="stylewhere-button-default"
                  onClick={() => navigate(`/reports/product${queryString ?? ''}`)}
                  key="1"
                >
                  {__('misc.cancel')}
                </Button>,
                <Button className="stylewhere-button-primary" htmlType="submit" key="2">
                  {btnSaveLabel}
                </Button>,
              ]}
            >
              <Section>
                <AdvancedForm
                  record={product}
                  handleChange={this.handleChange}
                  fields={PRODUCT_FIELDS}
                  editing={product.id !== ''}
                />
              </Section>
            </Page>
          </Form>
        )}
      </Row>
    )
  }

  private redirect(product: StwProduct) {
    if (product && product.id) {
      navigate(`/reports/product${this.props.queryString ?? ''}`)
    }
  }
}

export default ProductForm
