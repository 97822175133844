import { Component } from 'react'
import { StylewherePage, DefaultHeader, Section, GridData, NotFound } from '../../../components'
import { StwZone, DetailCell } from '../../../api'
import { ExtendRouteComponentProps } from '../../../types'
import Zones from '../../../api/Zones'
import { __, T } from '../../../shared/i18n'
import { ZONE_DETAIL } from '../../../config/Pages/Zones'
import {
  getDetailDataWithAttributesMapping,
  getMultipleTitle,
  getBackURL,
  getFragmentObject,
} from '../../../shared/utils'

interface State {
  zone: StwZone | undefined
  loader: boolean
  detailData?: DetailCell[]
}

class ZoneDetail extends Component<ExtendRouteComponentProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      zone: undefined,
      loader: true,
      detailData: ZONE_DETAIL,
    }
  }

  async componentDidMount() {
    const { match } = this.props
    const data: DetailCell[] = await getDetailDataWithAttributesMapping(ZONE_DETAIL, 'zone', 2)
    let zone
    if (match && match.params && match.params.zoneId) {
      zone = await Zones.get<StwZone>(match.params.zoneId).then((res) => res)
    }
    this.setState({
      zone: zone && !zone.errorId ? zone : undefined,
      loader: false,
      detailData: data,
    })
  }

  render() {
    const { queryString, breadcrumbs } = this.props
    const { zone, loader, detailData } = this.state
    const fragment = getFragmentObject(zone, 'code')
    return (
      <StylewherePage {...this.props} noOverflow fragment={fragment}>
        <DefaultHeader
          backPath={getBackURL(queryString, breadcrumbs)}
          multipleTitle={getMultipleTitle(zone)}
          title={__(T.misc.not_found)}
          skeleton={{ active: loader, options: { multiple: true } }}
        />
        <Section customClass={`stw-section-page paged-header ${zone || loader ? 'scroll transparent' : ''}`}>
          {zone || loader ? (
            <GridData fields={detailData || ZONE_DETAIL} record={zone || {}} skeleton={loader} />
          ) : (
            <NotFound fullheight title={__(T.misc.noRecordFoundTitle)} />
          )}
        </Section>
      </StylewherePage>
    )
  }
}

export default ZoneDetail
