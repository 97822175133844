import { Component } from 'react'
import { Skeleton, Row, Col } from 'antd'
import { Section } from '..'

interface Props {
  animate?: boolean
  fromModal?: boolean
}

export class SkeletonFormModal extends Component<Props> {
  render() {
    const { animate, fromModal } = this.props
    return (
      <Section fill={!fromModal} noPaddingPage={fromModal}>
        <Row gutter={[15, 15]}>
          <Col xs={24} xl={fromModal ? 24 : 12}>
            <Skeleton className="stw-skeleton-form-label" active={animate} paragraph={{ rows: 0 }} />
            <Skeleton.Input className="stw-skeleton-full" active={animate} size="large" />
            <Skeleton className="stw-skeleton-form-label mt" active={animate} paragraph={{ rows: 0 }} />
            <Skeleton.Input className="stw-skeleton-full" active={animate} size="large" />
            <Skeleton className="stw-skeleton-form-label mt" active={animate} paragraph={{ rows: 0 }} />
            <Skeleton.Input className="stw-skeleton-full" active={animate} size="large" />
            <Skeleton className="stw-skeleton-form-label mt" active={animate} paragraph={{ rows: 0 }} />
            <Skeleton.Input className="stw-skeleton-full" active={animate} size="large" />
          </Col>
        </Row>
      </Section>
    )
  }
}
