import BaseResource, { PageResult } from './BaseResource'
import { StwFlow, StwFullFlow, StwOperation } from './types'
import Operations from './Operations'

export default class PickingFlows extends BaseResource {
  static endpoint = '/api/v1/pickingFlows' ///logistics

  static async searchFull(endpoint, params = {}): Promise<PageResult<StwFullFlow>> {
    const flows = await BaseResource.searchTableList<StwFlow>(endpoint, params)
    const operationsIds = flows && flows.content ? flows.content.map((flow) => flow.operationId) : []
    const operations = await Operations.searchByIds<StwOperation>(operationsIds)
    const fullFlows: StwFullFlow[] =
      flows && flows.content
        ? flows.content.map((flow) => {
            return {
              ...flow,
              operation: operations[`${flow.operationId}`],
            }
          })
        : []
    return {
      ...flows,
      content: fullFlows,
    }
  }

  static async getFull(flowId: string): Promise<StwFullFlow> {
    const flow = await this.get<StwFlow>(flowId)
    const operation: StwOperation | undefined = flow.operationId
      ? await Operations.get<StwOperation>(flow.operationId)
      : undefined
    return {
      ...flow,
      operation,
    }
  }
}
