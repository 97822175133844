import { Component } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { TablePaginationConfig } from 'antd'
import Products from '../../../api/Products'
import { DetailCell, MovementType, StwFullParcelEntry, StwInbound, StwOutbound, StwProduct } from '../../../api'
import { AdvancedTable, ProductDetailBox, Section } from '../../../components'
import { __ } from '../../../shared/i18n'
import {
  AdvancedTableColumns,
  AdvancedTableContentKind,
  AdvancedTablePagination,
  AdvancedTableSortValues,
} from '../../../components/AdvancedTable'
import Outbounds from '../../../api/Outbounds'
import { PageResult } from '../../../api/BaseResource'
import Inbounds from '../../../api/Inbounds'
import { getDetailDataWithAttributesMapping } from '../../../shared/utils'

const PRODUCT_FIELD: DetailCell[] = [
  {
    attribute: 'code',
    columns: 12,
  },
  {
    attribute: 'description',
    columns: 12,
  },
  {
    attribute: 'color',
    columns: 12,
    type: 'valueDesc',
  },
  {
    attribute: 'material',
    columns: 12,
    type: 'valueDesc',
  },
  {
    attribute: 'style',
    columns: 12,
    type: 'valueDesc',
  },
  {
    attribute: 'size',
    columns: 12,
    type: 'valueDesc',
  },
]

const ITEM_ROWS: AdvancedTableColumns = [
  {
    sortable: false,
    unmanageable: true,
    key: 'item.identifiers',
    type: AdvancedTableContentKind.IDENTIFIERS,
    title: __('fields.labels.identifiers'),
  },
  {
    sortable: false,
    unmanageable: true,
    key: 'detected',
    type: AdvancedTableContentKind.BOOLEAN,
  },
  {
    sortable: false,
    unmanageable: true,
    key: 'unexpected',
    type: AdvancedTableContentKind.BOOLEAN,
  },
]

interface Props {
  productId: string
  movementId: string
  movementType: string
}

interface State {
  product?: StwProduct
  pagination: AdvancedTablePagination
  totalElements: number
  movement?: StwOutbound | StwInbound
  content: StwFullParcelEntry[]
  detailData?: DetailCell[]
  loader: boolean
}

export class MovmentProductItemReads extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      pagination: {
        pageSize: 10,
        sortValues: {},
        pageNumber: 1,
      },
      totalElements: 0,
      content: [],
      detailData: PRODUCT_FIELD,
      loader: true,
    }
  }

  componentDidMount() {
    this.initialize()
  }

  initialize = async () => {
    const { movementType, movementId } = this.props

    let movement
    if (movementType === MovementType.OUTBOUND) {
      movement = await Outbounds.get<StwOutbound>(movementId)
    } else if (movementType === MovementType.INBOUND) {
      movement = await Inbounds.get<StwInbound>(movementId)
    }

    const data: DetailCell[] = await getDetailDataWithAttributesMapping(PRODUCT_FIELD, 'product', 2, 'valueDesc', 12)
    const product = await Products.get<StwProduct>(this.props.productId)
    let entries: PageResult<StwFullParcelEntry>
    if (movementType === MovementType.OUTBOUND) {
      entries = await Outbounds.fullParcelentries(this.props.movementId, {
        page: this.state.pagination.pageNumber! - 1,
        size: this.state.pagination.pageSize,
        productCode: product.code,
      })
    } else {
      entries = await Inbounds.fullParcelentries(this.props.movementId, {
        page: this.state.pagination.pageNumber! - 1,
        size: this.state.pagination.pageSize,
        productCode: product.code,
      })
    }

    this.setState({
      product: product,
      movement: movement,
      content: entries.content,
      totalElements: entries.totalElements,
      detailData: data,
      loader: false,
    })
  }

  async handleTableChange(
    pagination: TablePaginationConfig
    // filters, // TODO handle table filters
    // sorts: SorterResult<any> | SorterResult<any>[]
  ) {
    let entries: PageResult<StwFullParcelEntry>
    const product = await Products.get<StwProduct>(this.props.productId)

    if (this.props.movementType === MovementType.OUTBOUND) {
      entries = await Outbounds.fullParcelentries(this.props.movementId, {
        page: pagination.current! - 1,
        size: pagination.pageSize,
        productCode: product.code,
      })
    } else {
      entries = await Inbounds.fullParcelentries(this.props.movementId, {
        page: pagination.current! - 1,
        size: pagination.pageSize,
        productCode: product.code,
      })
    }

    const paginationSort: AdvancedTableSortValues = {}
    this.setState({
      content: entries.content,
      totalElements: entries.totalElements,
      pagination: {
        pageNumber: pagination.current,
        pageSize: pagination.pageSize || 10,
        sortValues: paginationSort,
      },
    })
  }

  getTitle = () => {
    const { movement } = this.state
    const { movementType } = this.props
    return movement ? `${__(`enum.${movementType}`)} ${movement?.description || movement?.id}` : <LoadingOutlined />
  }

  render() {
    const { product, pagination, totalElements, content, detailData, loader } = this.state
    return (
      <Section parentPadding={15} title={this.getTitle()}>
        <ProductDetailBox loader={loader} product={product} data={detailData || PRODUCT_FIELD} />
        {content.length > 0 && (
          <AdvancedTable
            columns={ITEM_ROWS}
            content={content}
            totalElements={totalElements}
            pagination={pagination}
            border
            onTableChanged={(p) => {
              this.handleTableChange(p)
            }}
            activeDefaultClassColumn
          />
        )}
      </Section>
    )
  }
}
