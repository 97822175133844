import { Component } from 'react'
import dayjs from 'dayjs'
import { ExportOutlined, EyeOutlined, ImportOutlined } from '@ant-design/icons'
import {
  DetailCell,
  StwOperation,
  StwOperationAction,
  StwFullInbound,
  StwFullOutbound,
  StwFullParcel,
  StwFullParcelEntry,
  StwProduct,
  StwReportQueryResult,
} from '../../../api'
import {
  AdvancedTable,
  AdvancedTableRowColumn,
  SectionTitle,
  KeyValueGrid,
  StylewherePage,
  DefaultHeader,
  NotFound,
  Section,
  GridCards,
} from '../../../components'
import { __, T } from '../../../shared/i18n'
import { Tab, TabBar } from '../../../components/TabBar'
import { AdvancedTableColumns, AdvancedTableSortDirection } from '../../../components/AdvancedTable'
import { MOVEMENT_FIELD, MOVEMENT_PACKING_FIELD, PRODUCT_ROW_COL } from '../../../config/Pages/Parcels'
import { showModal } from '../../../components/modalPageUtils'
import Parcels from '../../../api/Parcels'
import Outbounds from '../../../api/Outbounds'
import Inbounds from '../../../api/Inbounds'
import { SkeletonAttributeReport, MAX_SIZE_LIMIT } from '../../../constants'
import {
  getFragmentObject,
  getMultipleTitle,
  getBackURL,
  getCards,
  getHeaderOperationActions,
} from '../../../shared/utils'
import { ExtendRouteComponentProps } from '../../../types'
import Operations from '../../../api/Operations'
import Reports from '../../../api/Reports'

interface OperationRow {
  product: StwProduct
  detected: number
  unexpected: number
  itemType: boolean
}

interface State {
  reportInstance?: StwReportQueryResult
  configuration: DetailCell[]
  loader: boolean
  parcel?: StwFullParcel
  parcelConfiguration: DetailCell[]
  parcelReportInstance?: StwReportQueryResult
  tabs: Tab[]
  selectedTab?: any
  outbounds: StwFullOutbound[]
  inbounds: StwFullInbound[]
  currentOp?: StwFullOutbound | StwFullInbound
  entries?: OperationRow[]
  unexpectedLabel: string
  detectedLabel: string
  rowCols: AdvancedTableColumns
  actions: StwOperationAction[]
}

class PackingOperationDetail extends Component<ExtendRouteComponentProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      configuration: [],
      parcelConfiguration: [],
      loader: true,
      tabs: [],
      inbounds: [],
      outbounds: [],
      detectedLabel: '0',
      unexpectedLabel: '0',
      actions: [],
      rowCols: [
        ...PRODUCT_ROW_COL,
        {
          key: 'action',
          width: 'small',
          fixedType: 'right',
          sortable: false,
          unmanageable: true,
          render: (text, record) => (
            <>
              {record.itemType && (
                <AdvancedTableRowColumn
                  actions={[
                    {
                      content: <EyeOutlined />,
                      type: 'default',
                      onclick: () => this.showDetail(record),
                    },
                  ]}
                />
              )}
            </>
          ),
        },
      ],
    }
  }

  componentDidMount = async () => {
    const { match } = this.props
    if (match && match.params && match.params.operationInstanceId) {
      const params = { operationId: match.params.operationId }
      const reportId = await Operations.getReportId(match.params.operationId)
      const instance: any = await Reports.reportInstance(reportId, match.params.operationInstanceId, params)
      if (instance && instance.id) {
        const operation: StwOperation = await Operations.get<StwOperation>(match.params.operationId)
        const configuration = await Reports.getConfiguration(reportId, params)
        const parcelId = instance.parcelId
        const parcelConfiguration = await Reports.getConfiguration('parcel')
        const parcelReportInstance = await Reports.reportInstance('parcel', parcelId)
        const parcel = await Parcels.getFullParcel(parcelId)
        const inbounds = await this.getInbounds(parcel.code)
        const outbounds = await this.getOutbounds(parcel.code)
        const operations = this.getOperations(inbounds, outbounds)
        const tabs = operations.map((opt) => ({
          value: opt.id,
          label: dayjs(opt.creationDate).format('DD/MM/YYYY HH:mm:ss'),
          iconRender: () => (opt.type === 'OUTBOUND' ? <ExportOutlined /> : <ImportOutlined />),
        }))

        this.setState({
          reportInstance: instance,
          configuration: configuration,
          loader: false,
          parcelConfiguration: parcelConfiguration,
          parcelReportInstance: parcelReportInstance,
          parcel: parcel,
          tabs: tabs,
          inbounds: inbounds,
          outbounds: outbounds,
          actions: operation && operation.actions && operation.actions !== null ? operation.actions : [],
        })
      } else {
        this.setState({
          loader: false,
        })
      }
    } else {
      this.setState({
        loader: false,
      })
    }
  }

  showDetail(row: OperationRow) {
    const { currentOp } = this.state
    showModal({
      type: 'MOVEMENT_PRODUCT_ITEMS',
      data: { movId: currentOp!.id, movType: currentOp!.type, prodId: row.product.id },
    })
  }

  async onTabChange(value) {
    const currentOperation = this.getCurrentOperation(this.state, value)
    const parcelEntries = await this.getParcelEntries(currentOperation)
    const products = this.getProducts(parcelEntries)
    const entries = this.getEntries(products)

    this.setState({
      selectedTab: value,
      currentOp: currentOperation,
      entries: entries,
      unexpectedLabel: `${currentOperation.totalUnexpected}`,
      detectedLabel: `${currentOperation.totalDetected}`,
    })
  }

  getCurrentOperation(state: State, value): StwFullOutbound | StwFullInbound {
    return (
      state.outbounds.find((outbound) => outbound.id === value) ??
      state.inbounds.find((inbound) => inbound.id === value)!
    )
  }

  async getParcelEntries(operation: StwFullInbound | StwFullOutbound): Promise<StwFullParcelEntry[]> {
    return (
      operation.type === 'INBOUND'
        ? Inbounds.fullParcelentries(operation.id, { size: MAX_SIZE_LIMIT })
        : Outbounds.fullParcelentries(operation.id, { size: MAX_SIZE_LIMIT })
    ).then((response) => response.content)
  }

  getProducts(parcelEntries: StwFullParcelEntry[]): (StwProduct | undefined)[] {
    const products = parcelEntries.map((parcelEntry) => parcelEntry.product)
    const productCodes = products.map((product) => product!.code)
    return products.filter((product, index) => productCodes.indexOf(product!.code) === index)
  }

  getEntries(products: (StwProduct | undefined)[]): OperationRow[] {
    return products.map((product) => ({
      product: product!,
      detected: 0,
      unexpected: 0,
      itemType: false,
    }))
  }

  async getOutbounds(parcelCode: string) {
    return Outbounds.searchFull({ parcelCode }).then((response) => response.content)
  }

  async getInbounds(parcelCode: string) {
    return Inbounds.searchFull({ parcelCode }).then((response) => response.content)
  }

  getOperations(inbounds: StwFullInbound[], outbounds: StwFullOutbound[]) {
    return [
      ...inbounds.map((inbound) => ({ ...inbound, type: 'INBOUND' })),
      ...outbounds.map((outbound) => ({ ...outbound, type: 'OUTBOUND' })),
    ].sort((leftOperation, rightOperation) =>
      dayjs(leftOperation.creationDate).isBefore(dayjs(rightOperation.creationDate)) ? -1 : 1
    )
  }

  render(): JSX.Element {
    const { breadcrumbs, queryString, match } = this.props
    const {
      tabs,
      selectedTab,
      currentOp,
      entries,
      unexpectedLabel,
      detectedLabel,
      rowCols,
      configuration,
      parcelConfiguration,
      reportInstance,
      parcelReportInstance,
      loader,
      actions,
    } = this.state
    const fragment = getFragmentObject(reportInstance, 'id')
    return (
      <StylewherePage {...this.props} noOverflow fragment={fragment}>
        <DefaultHeader
          backPath={getBackURL(queryString, breadcrumbs)}
          multipleTitle={getMultipleTitle(reportInstance)}
          title={__(T.misc.not_found)}
          skeleton={{ active: loader, options: { multiple: true } }}
          actions={
            reportInstance
              ? getHeaderOperationActions(
                  'logistics',
                  'packing',
                  actions,
                  match.params.operationId,
                  match.params.operationInstanceId
                )
              : []
          }
        />
        <Section customClass={`stw-section-page paged-header ${reportInstance || loader ? 'scroll transparent' : ''}`}>
          {reportInstance || loader ? (
            <>
              <Section>
                <KeyValueGrid
                  skeleton={loader}
                  fields={loader ? SkeletonAttributeReport : configuration}
                  data={reportInstance}
                />
              </Section>
              <Section>
                <SectionTitle title={__(T.titles.parcel)} marginBottom={20} />
                <KeyValueGrid
                  skeleton={loader}
                  fields={loader ? SkeletonAttributeReport : parcelConfiguration}
                  data={parcelReportInstance}
                />
                {!loader && tabs.length > 0 && (
                  <Section>
                    <TabBar
                      tabs={tabs}
                      selected={selectedTab}
                      onChangeTab={async (value) => {
                        await this.onTabChange(value)
                      }}
                      autoselect
                    />
                    {entries && (
                      <>
                        <KeyValueGrid
                          fields={currentOp?.type === 'PACKING' ? MOVEMENT_PACKING_FIELD : MOVEMENT_FIELD}
                          data={currentOp}
                        />
                        <GridCards mTop={20} cards={getCards(detectedLabel, -1, undefined, unexpectedLabel)} />
                        <AdvancedTable
                          loader={loader}
                          tableTitle={{
                            title: __('fields.labels.products'),
                            emptyTitle: __(T.outbound.noProductsFoundTitle),
                          }}
                          columns={rowCols}
                          content={entries}
                          totalElements={entries!.length}
                          pagination={{
                            pageSize: entries!.length,
                            pageNumber: 1,
                            sortValues: { creationDate: AdvancedTableSortDirection.desc },
                          }}
                          activeDefaultClassColumn
                          fixedTableHeight
                          disabledNoDataFound
                        />
                      </>
                    )}
                  </Section>
                )}
              </Section>
            </>
          ) : (
            <NotFound fullheight title={__(T.misc.noRecordFoundTitle)} />
          )}
        </Section>
      </StylewherePage>
    )
  }
}

export default PackingOperationDetail
