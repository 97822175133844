import { Component, Fragment } from 'react'
import { Button, Col, Input, notification, Row, Space } from 'antd'
import { AdvancedFormInputField } from './AdvancedFormInputField'
import { SectionTitle } from './SectionTitle'
import { StwOperationOption } from '../../api'
import { AdvancedFormInputType } from './advancedFormTypes'
import { __, T } from '../../shared/i18n'

interface Props {
  title?: string
  subtitle?: string
  options: StwOperationOption[]
  record: any
  handleChangeOptions: any
  handleRemoveOption: any
}

interface State {
  newOption: {
    key: string
    value: string
  }
}

export class AdvancedFormOptions extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      newOption: {
        key: '',
        value: '',
      },
    }
  }

  handleNewOption = (field: string, value) => {
    const { newOption } = this.state
    newOption[field] = value
    this.setState({ newOption })
  }

  sendNewOption = () => {
    const { handleChangeOptions, options } = this.props
    const { newOption } = this.state

    if (!newOption.key) {
      this.openNotification('Custom key is required')
      return
    }

    if (options.find((option) => option.optionKey === newOption.key)) {
      this.openNotification(`${newOption.key} already exist`)
      return
    }

    handleChangeOptions(`options.${newOption.key}`, newOption.value)
    this.clearNewOption()
  }

  openNotification = (text) => {
    notification.error({
      message: __(T.misc.validationError),
      description: text,
      placement: 'bottomRight',
      duration: 3,
    })
  }

  clearNewOption = () => {
    this.setState({
      newOption: {
        key: '',
        value: '',
      },
    })
  }

  render() {
    const { title, subtitle, options, record, handleChangeOptions, handleRemoveOption } = this.props
    const { newOption } = this.state

    return (
      <Row style={{ marginTop: 20, marginBottom: 20 }}>
        <Col span={24}>
          <SectionTitle noBorder title={title} subtitle={subtitle} marginBottom={20} />
        </Col>
        {options.length > 0 &&
          options.map((option) => {
            return (
              <Fragment key={option.optionKey}>
                <Col span={24}>
                  <Space className="stylewhere-form-options" style={{ display: 'flex', marginBottom: 8 }}>
                    <div>
                      <AdvancedFormInputField
                        item={{
                          type: AdvancedFormInputType.TEXT,
                          col: 0,
                          label: option.optionKey || '',
                          key: option.optionKey ? `options.${option.optionKey}` : '',
                          rules: [{ required: true }],
                          placeholder: __('misc.customValue'),
                        }}
                        readonly={false}
                        record={record}
                        handleChange={(event) => handleChangeOptions(event.target.id, event.target.value)}
                      />
                    </div>
                    {!option.id && (
                      <div>
                        <p style={{ padding: 0, marginBottom: 0 }}>
                          <b>&nbsp;</b>
                        </p>
                        <Button
                          className="stylewhere-button-secondary"
                          onClick={() => handleRemoveOption(newOption.key)}
                        >
                          {__('misc.remove')}
                        </Button>
                      </div>
                    )}
                  </Space>
                </Col>
              </Fragment>
            )
          })}
        <Col span={24}>
          <Space className="stylewhere-form-attributes" style={{ display: 'flex', marginBottom: 8 }}>
            <div>
              <p style={{ padding: 0, marginBottom: 5 }}>
                <b>{__('misc.customKey')}</b>
              </p>
              <Input
                id="attributekey"
                value={newOption.key}
                onChange={(event) => this.handleNewOption('key', event.target.value)}
                placeholder={__('misc.insertKey')}
              />
            </div>
            <div>
              <p style={{ padding: 0, marginBottom: 5 }}>
                <b>{__('misc.customValue')}</b>
              </p>
              <Input
                id="attributeValue"
                onChange={(event) => this.handleNewOption('value', event.target.value)}
                placeholder={__('misc.insertValue')}
                value={newOption.value}
              />
            </div>
            <div>
              <p style={{ padding: 0, marginBottom: 5 }}>
                <b>&nbsp;</b>
              </p>
              <Button className="stylewhere-button-secondary" onClick={() => this.sendNewOption()}>
                {__('misc.addNewValue')}
              </Button>
            </div>
          </Space>
        </Col>
      </Row>
    )
  }
}
