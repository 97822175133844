import { get, isNumber as isNumberLodash, uniqBy } from 'lodash'
import dayjs from 'dayjs'
import { __, T } from './i18n'
import AttributeMappings from '../api/AttributeMappings'
import { StwAttribute, DetailCell, DetailCeilType, ModalType } from '../api'
import { BreadcrumbsFragmentType } from '../components/PageBread'
import { AdvancedTableColumn, AdvancedTableContentKind } from '../components/AdvancedTable'
import { Card, CardElement, SubmenuEntryType } from '../types'
import AppStore from './AppStore'
import { CAPABILITIES, hasAnyCapabilities, getActionsList } from '../config/utility/capabilityUtility'
import { ReportActions } from '../components/Refactor/TableListReport'
import { AdvancedFormAttribute } from '../components/AdvancedForm/advancedFormTypes'

export const sleep = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const __isDev = process.env.NODE_ENV !== 'production'

export function truncateString(text = '', maxLength: number) {
  if (!text || text.length < maxLength) return text

  return `${text.substring(0, maxLength)}..`
}

export function getDatetime(timestamp?: number) {
  if (!timestamp) return undefined

  const twoDigits = (s) => `0${s}`.slice(-2)

  const date = new Date(timestamp)
  return `${twoDigits(date.getDate())}/${twoDigits(date.getMonth() + 1)}/${date.getFullYear()} ${twoDigits(
    date.getHours()
  )}:${twoDigits(date.getMinutes())}`
}

export function getListFromCommaSeparatedString(text?: string) {
  return text ? text.replace(/\s/g, '').split(',') : []
}

export const uniqueBy = <T>(array: T[], property: string) => uniqBy<T>(array, property)

export const getField = (object: any, path: string) => get(object, path)

export const isNumber = (number: any) => isNumberLodash(number)

export const getNotificationTime = (date: string | undefined) => {
  if (!date) return ''
  const now = dayjs().unix()
  const current = dayjs(date).unix()
  const diff = now - current
  let str = ''
  if (diff === 0) {
    str = __('misc.notifications.now')
  } else if (diff < 60) {
    str = diff + (diff === 1 ? __('misc.notifications.second') : __('misc.notifications.seconds'))
  } else if (diff < 3600) {
    const minute = Math.ceil(diff / 60)
    str = minute + (minute === 1 ? __('misc.notifications.minute') : __('misc.notifications.minutes'))
  } else if (diff < 216000) {
    const hour = Math.ceil(diff / 3600)
    str = hour + (hour === 1 ? __('misc.notifications.hour') : __('misc.notifications.hours'))
  } else if (diff < 432000) {
    const day = Math.ceil(diff / 86400)
    str = day + (day === 1 ? __('misc.notifications.day') : __('misc.notifications.days'))
  } else {
    str = dayjs(date).format('DD/MM/YYYY')
  }
  return str
}

export const getAttributeValue = (entity, column = 8) => {
  const attributeConfiguration: DetailCell[] = []
  if (entity && entity.attributes) {
    for (const attributesKey in entity.attributes) {
      if (Object.prototype.hasOwnProperty.call(entity.attributes, attributesKey)) {
        attributeConfiguration.push({ title: attributesKey, attribute: `attributes.${attributesKey}`, columns: column })
      }
    }
  }
  return attributeConfiguration
}

export const uniqueDataList = (fields: DetailCell[], value: any, index: number) => {
  if (value && value.attributes) {
    const cols: DetailCell[] = []
    const convert: DetailCell[] = getAttributeValue(value, 12)
    for (let c = 0; c < fields.length - index; c++) {
      cols.push(fields[c])
    }
    //add mapping attribute
    for (let c = 0; c < convert.length; c++) {
      cols.push(convert[c])
    }
    //add last columns
    for (let c = fields.length - index; c < fields.length; c++) {
      cols.push(fields[c])
    }
    return cols
  }
  return fields
}

export const getTableColumnsWithAttributesMapping = async (
  columns: AdvancedTableColumn[],
  entity: string,
  index: number
) => {
  let cols: AdvancedTableColumn[] = []
  if (entity !== '') {
    const results = await AttributeMappings.search<StwAttribute>({ entity: entity, size: 100, sort: 'code,asc' })
    if (results && results.content && results.content.length > 0) {
      for (let c = 0; c < columns.length - index; c++) {
        cols.push(columns[c])
      }
      //add mapping attribute
      for (let r = 0; r < results.content.length; r++) {
        cols.push({
          key: `attributes.${results.content[r].code}`,
          title: results.content[r].code,
          type: AdvancedTableContentKind.STRING,
          dataIndex: `attributes.${results.content[r].code}`,
          className: 'stw-medium',
          hidden: true,
        })
      }
      //add last columns
      for (let c = columns.length - index; c < columns.length; c++) {
        cols.push(columns[c])
      }
    } else {
      cols = columns
    }
  } else {
    cols = columns
  }
  return cols
}

export const getDetailDataWithAttributesMapping = async (
  columns: DetailCell[],
  entity: string,
  index: number,
  fieldType: DetailCeilType = 'string',
  col = 12
) => {
  let results
  try {
    results = await AttributeMappings.search<StwAttribute>({ entity: entity, size: 100, sort: 'code,asc' })
  } catch (e) {
    results = { content: [] }
  }
  let cols: DetailCell[] = []
  if (results && results.content && results.content.length > 0) {
    for (let c = 0; c < columns.length - index; c++) {
      cols.push(columns[c])
    }
    //add mapping attribute
    for (let r = 0; r < results.content.length; r++) {
      cols.push({
        attribute: `attributes.${results.content[r].code}`,
        title: __(`fields.labels.${results.content[r].code}`, results.content[r].code),
        type: fieldType || 'string',
        columns: col || 12,
      })
    }
    //add last columns
    for (let c = columns.length - index; c < columns.length; c++) {
      cols.push(columns[c])
    }
  } else {
    cols = columns
  }
  return cols
}

export const getCards = (read, expected, expected2, unexpected, missing = -1) => {
  const cards: Card[] = []
  if (read >= 0) {
    cards.push({ rows: [{ iconBkgColor: '#70CDEE', icon: 'SAFETY', label: __('fields.labels.read'), counter: read }] })
  }
  if (expected >= 0) {
    cards.push({
      rows: [
        {
          iconBkgColor: 'rgb(117, 235, 168)',
          icon: 'EXCLAMATION',
          label: __('fields.labels.expected'),
          counter: expected,
          counter2: expected2,
          bkgColor: 'rgb(117, 235, 168)',
        },
      ],
    })
  }
  if (unexpected >= 0) {
    const rows: CardElement[] = []
    rows.push({
      iconBkgColor: '#FF8474',
      icon: 'EXCLAMATION',
      label: __('fields.labels.unexpected'),
      counter: unexpected,
      bkgColor: '#F8F8F8',
    })
    if (missing >= 0) {
      rows.push({
        iconBkgColor: '#FFC665',
        icon: 'MISSING',
        label: __('fields.labels.missing'),
        counter: unexpected,
        bkgColor: '#F8F8F8',
      })
    }
    cards.push({
      rows: rows,
    })
  }
  return cards
}

export const getReadCard = (label, value) => {
  const obj: CardElement = { iconBkgColor: '#70CDEE', icon: 'SAFETY', label: label, counter: value }
  return obj
}

export const getExpectedCard = (label, value, value2) => {
  const obj: CardElement = {
    iconBkgColor: 'rgb(117, 235, 168)',
    bkgColor: 'rgb(117, 235, 168)',
    icon: 'EXCLAMATION',
    label: label,
    counter: value,
    counter2: value2,
  }
  return obj
}

export const getUnexpectedCard = (label, value) => {
  const obj: CardElement = {
    iconBkgColor: '#FF8474',
    bkgColor: '#F8F8F8',
    icon: 'EXCLAMATION',
    label: label,
    counter: value,
  }
  return obj
}

export const getMissingCard = (label, value) => {
  const obj: CardElement = {
    iconBkgColor: '#FFC665',
    bkgColor: '#F8F8F8',
    icon: 'MISSING',
    label: label,
    counter: value,
  }
  return obj
}

export const getQueryParamFromObject = (params: any, prefix = '') => {
  const str: string[] = []
  for (const p in params) {
    if (p in params) {
      const k = prefix ? `${prefix}` : p
      const v = params[p]
      if ((!!v && Array.isArray(v) && v.length > 0) || !Array.isArray(v)) {
        if (typeof v === 'object') {
          str.push(getQueryParamFromObject(v, k))
        } else {
          str.push(`${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
        }
      }
    }
  }
  return str.join('&')
}

export function openModal(modal: ModalType) {
  AppStore.openModal?.({ id: (+new Date()).toString(), visible: true, ...modal })
}

export function closeModal(id: string) {
  AppStore.closeModal?.(id)
}

/* App.tsx */
const setTitlePage = (selected) => {
  let title = 'Stylewhere'
  if (selected && selected.breadcrumbs && selected.breadcrumbs.fragments) {
    selected.breadcrumbs.fragments.map(
      (fragment) => (title = `${title} ${title === 'Stylewhere' ? '|' : '>'} ${fragment.label}`)
    )
  }
  document.title = title
}

const checkSubmenuPath = (submenus, path) => {
  return submenus.find((submenu) => submenu.path === path)
}

export const getCurrentPath = async (directpath, pathlevel) => {
  const path = window.location.pathname
  if (directpath) {
    return path
  }
  const fragments = path.split('/')
  if (fragments.length > 3) return fragments.slice(0, -(fragments.length - (pathlevel || 3))).join('/')
  return fragments.join('/')
}

export const checkSelectedMenu = (menuList, currentPath) => {
  const selected: any = { menu: '', submenu: null, breadcrumbs: {} }
  if (currentPath !== '' && currentPath !== '/') {
    let submenuIndex = 0
    while (submenuIndex < menuList.length && !selected.submenu) {
      if (menuList[submenuIndex].path) {
        if (menuList[submenuIndex].path === currentPath) {
          selected.submenu = true
          selected.menu = menuList[submenuIndex].key
          selected.breadcrumbs = {
            icon: menuList[submenuIndex].iconBlack,
            fragments: [
              {
                label: menuList[submenuIndex].label,
                active: true,
              },
            ],
          }
        }
      } else {
        selected.submenu = checkSubmenuPath(menuList[submenuIndex].submenus, currentPath)
        if (selected.submenu) {
          selected.menu = menuList[submenuIndex].key

          const tmp1: SubmenuEntryType = selected.submenu
          selected.breadcrumbs = {
            icon: menuList[submenuIndex].iconBlack,
            fragments: [
              {
                label: menuList[submenuIndex].label,
              },
              {
                label: tmp1.label,
                path: tmp1.path,
                active: true,
              },
            ],
          }
        }
      }
      submenuIndex++
    }
  }
  return selected
}

export const getSelectedMenu = async (menuList, path = null) => {
  let currentPath = path || (await getCurrentPath(false, 3))
  let selected: any = await checkSelectedMenu(menuList, currentPath)
  if (selected.menu === '') {
    currentPath = path || (await getCurrentPath(false, 4))
    selected = await checkSelectedMenu(menuList, currentPath)
  }
  if (selected.menu === '') {
    currentPath = path || (await getCurrentPath(false, 5))
    selected = await checkSelectedMenu(menuList, currentPath)
  }
  if (selected.menu === '') {
    currentPath = path || (await getCurrentPath(true, 3))
    selected = await checkSelectedMenu(menuList, currentPath)
  }
  //case limit for operationLogs disabled
  if (selected.menu === '' && currentPath.indexOf('operationlogs') !== -1) {
    selected = { menu: 'operationLogs', submenu: null, breadcrumbs: {} }
  }

  setTitlePage(selected)
  return selected
}
/* App.tsx */

export const getFragment = (label, active, path) => {
  const obj: BreadcrumbsFragmentType = {
    label: label,
    active: active,
    path: path,
  }
  return obj
}

export const getBreadcrumbs = (queryString, breadcrumbs, fragment) => {
  const breadList = breadcrumbs
  let back = ''
  if (fragment && fragment.length > 0 && breadList) {
    const index = breadList.fragments.findIndex((bread) => bread.label === fragment[0].label)
    if (index === -1) {
      if (breadList.fragments[breadList.fragments.length - 1].path)
        back = breadList.fragments[breadList.fragments.length - 1].path || ''
      if (queryString && queryString !== '') {
        breadList.fragments[breadList.fragments.length - 1].path += queryString
      }
      breadList.fragments[breadList.fragments.length - 1].active = false
      for (let f = 0; f < fragment.length; f++) breadList.fragments.push(fragment[f])
    } else if (breadList.fragments.length > 2 && breadList.fragments[breadList.fragments.length - 2].path) {
      back = breadList.fragments[breadList.fragments.length - 2].path || ''
    }
    if (back !== '' && queryString && queryString !== '' && back.indexOf('?') === -1) {
      back += queryString
    }
  }
  return {
    breads: breadList,
    backPath: back,
  }
}

export const getBackURL = (queryString, breadcrumbs) => {
  let back = ''
  if (breadcrumbs) {
    if (breadcrumbs.fragments[breadcrumbs.fragments.length - 1].path) {
      back = breadcrumbs.fragments[breadcrumbs.fragments.length - 1].path || ''
    } else if (breadcrumbs.fragments.length > 2 && breadcrumbs.fragments[breadcrumbs.fragments.length - 2].path) {
      back = breadcrumbs.fragments[breadcrumbs.fragments.length - 2].path || ''
    }
    if (back !== '' && queryString && queryString !== '' && back.indexOf('?') === -1) {
      back += queryString
    }
  }
  return back
}

export const refreshFixedColumns = (columns) => {
  const index = columns.findIndex((e) => e.fixedType === 'left')
  if (index > 0) {
    for (let m = 0; m < columns.length; m++) {
      if (m === 0) {
        columns[m].fixedType = 'left'
      } else if (columns[m].key === 'action') {
        columns[m].fixedType = 'right'
      } else {
        columns[m].fixedType = undefined
      }
    }
  }
  return columns
}

export const getMultipleTitle = (record, fields = ['id', 'code', 'description']) => {
  let list
  if (record) {
    list = []
    for (let f = 0; f < fields.length; f++) {
      if (record[fields[f]]) {
        list.push({
          label: __(`fields.labels.${fields[f]}`),
          value: record[fields[f]],
        })
      }
    }
  }
  return list
}

export const getFragmentObject = (record, field, label = '') => {
  if (record) {
    return [
      {
        label: record[field] || label,
        active: true,
      },
    ]
  }
  if (label !== '') {
    return [
      {
        label: label,
        active: true,
      },
    ]
  }
  return undefined
}

const getFilterDefinitionByKey = (key, filterDefinitions) => {
  if (filterDefinitions) {
    return filterDefinitions.filter((def) => def.key === key)[0]
  }
  return undefined
}

export const getFilterApplied = (tablePagination, filterDefinitions) => {
  const filterValues = tablePagination ? tablePagination.filterValues : undefined
  if (filterValues) {
    let counter = 0
    const filters: any[] = []
    for (const key of Object.keys(filterValues)) {
      if (filterValues[key]) {
        const def = getFilterDefinitionByKey(key, filterDefinitions)
        if (def) {
          const data: any = {
            key: key,
            value: filterValues[key],
            def: getFilterDefinitionByKey(key, filterDefinitions),
          }
          let ids: string[] = []
          if (data.value !== undefined) {
            if (data.value instanceof Array) {
              ids = data.value
            } else {
              ids = [data.value]
            }
          }
          counter += ids.length
          if (data.def && ids && ids.length > 0) {
            filters.push(data)
          }
        }
      }
    }
    return counter
  }
  return 0
}

export const getTagColor = (value: string) => {
  switch (value) {
    case 'RECEIVED':
    case 'CLOSED':
    case 'ASSOCIATED':
    case 'CREATED':
      return {
        color: 'success',
      }
    case 'DRAFT':
    case 'DELETED':
    case 'MOVE_AND_CLOSE':
    case 'REPLACED_ASSOCIATION':
      return {
        color: 'warning',
      }
    case 'IN_TRANSIT':
    case 'CLOSING':
    case 'PICK_IN_PROGRESS':
    case 'POPULATING':
      return {
        color: 'processing',
      }
    case 'OUTBOUND_ERROR':
    case 'INBOUND_ERROR':
    case 'POPULATE_ERROR':
    case 'CLOSE_ERROR':
    case 'NOT_ASSOCIATED':
      return {
        color: 'error',
      }
    default: {
      return {
        color: 'default',
      }
    }
  }
}

export const canRenderAttributes = (entity, attributeFields, skeleton = false) => {
  return skeleton || (entity && entity.attributes && attributeFields && attributeFields.length > 0)
}

export const getEnabledImageReport = (report) => {
  switch (report) {
    case 'item':
      return { active: AppStore.getShowProductImage(), index: 4 }
    case 'product':
      return { active: AppStore.getShowProductImage(), index: 2 }
    default:
      return { active: false, index: 0 }
  }
}

const hasCapabilitiesViewReport = (report) => {
  let capability = ''
  switch (report) {
    case 'item':
      capability = CAPABILITIES.RESOURCE_ITEM_VIEW
      break
    case 'productionorders':
      capability = CAPABILITIES.RESOURCE_PRODUCTIONORDER_VIEW
      break
    case 'productionorderrows':
      capability = CAPABILITIES.RESOURCE_PRODUCTIONORDERROW_VIEW
      break
    case 'simpleItemSessionEntry':
      capability = CAPABILITIES.RESOURCE_ITEM_VIEW
      break
    default:
      capability = ''
      break
  }
  return capability === '' ? true : hasAnyCapabilities(capability)
}

const getCreateNewReportCapability = (report) => {
  let capability = ''
  switch (report) {
    case 'product':
      capability = CAPABILITIES.RESOURCE_PRODUCT_EDIT
      break
    case 'xspan':
      capability = CAPABILITIES.RESOURCE_XSPAN_READER_EDIT
      break
    case 'workstation':
      capability = CAPABILITIES.RESOURCE_WORKSTATION_EDIT
      break
    case 'checklist':
      capability = CAPABILITIES.RESOURCE_CHECKLIST_EDIT
      break
    case 'antennas':
    case 'rfidReader':
      capability = CAPABILITIES.RESOURCE_RFID_READER_EDIT
      break
    default:
      capability = ''
      break
  }
  return capability
}

const getEditReportCapability = (report) => {
  let capability = ''
  switch (report) {
    case 'product':
      capability = CAPABILITIES.RESOURCE_PRODUCT_EDIT
      break
    case 'users':
      capability = CAPABILITIES.RESOURCE_USER_EDIT
      break
    case 'xspan':
      capability = CAPABILITIES.RESOURCE_XSPAN_READER_EDIT
      break
    case 'workstation':
      capability = CAPABILITIES.RESOURCE_WORKSTATION_EDIT
      break
    case 'checklist':
      capability = CAPABILITIES.RESOURCE_CHECKLIST_EDIT
      break
    case 'antennas':
    case 'rfidReader':
      capability = CAPABILITIES.RESOURCE_RFID_READER_EDIT
      break
    case 'barcodeReaders':
      capability = CAPABILITIES.RESOURCE_BARCODE_READER_EDIT
      break
    case 'scanners':
      capability = CAPABILITIES.RESOURCE_SCANNER_EDIT
      break
    default:
      capability = ''
      break
  }
  return capability
}

export const getTableActionsReport = (report): ReportActions => {
  //gestione edit and view
  const capability = getEditReportCapability(report)
  switch (report) {
    case 'product':
    case 'users':
    case 'xspan':
    case 'workstation':
      return { active: true, onlyedit: false, editCapabilites: capability }
    case 'antennas':
    case 'rfidReader':
    case 'scanners':
    case 'barcodeReaders':
      return {
        active: true,
        onlyedit: false,
        editCapabilites: capability,
        canDelete: true,
        customClass: 'stw-small-medium',
      }
    case 'simpleItemSessionEntry':
      return {
        active: hasCapabilitiesViewReport(report),
        onlyedit: false,
        editCapabilites: capability,
        fieldId: 'itemId',
      }
    default:
      return { active: hasCapabilitiesViewReport(report), onlyedit: true }
  }
}

const getCreateReportText = (report) => {
  let str = ''
  switch (report) {
    case 'product':
      str = __(T.products.create_new)
      break
    case 'users':
      str = __(T.user.create_new)
      break
    case 'xspan':
      str = __(T.xspans.create_new)
      break
    case 'checklist':
      str = __(T.checklist.create_new)
      break
    case 'workstation':
      str = __(T.workstations.create_new)
      break
    case 'antennas':
      str = __(T.antennas.create_new)
      break
    case 'rfidReader':
      str = __(T.rfidReaders.create_new)
      break
    default:
      str = __(T.misc.create_new)
      break
  }
  return str
}

export const getHeaderActionsReport = (report, breadcrumbs) => {
  const capability = getCreateNewReportCapability(report)
  const basePath = breadcrumbs ? breadcrumbs.fragments[breadcrumbs.fragments.length - 1].path : ''
  return capability !== '' ? getActionsList(capability, getCreateReportText(report), `${basePath}/create/form`) : []
}

export const getAttributeConfiguration = (entity): DetailCell[] => {
  const attributeConfiguration: DetailCell[] = []
  if (entity && entity.attributes) {
    for (const attributesKey in entity.attributes) {
      if (Object.prototype.hasOwnProperty.call(entity.attributes, attributesKey)) {
        attributeConfiguration.push({ title: attributesKey, attribute: attributesKey, columns: 8 })
      }
    }
  }
  return attributeConfiguration
}

export const getReportDescription = (reportInstance) => {
  return (
    reportInstance.username ||
    // eslint-disable-next-line no-nested-ternary
    (reportInstance.code
      ? reportInstance.description
        ? `${reportInstance.code}: ${reportInstance.description}`
        : reportInstance.code
      : reportInstance.description) ||
    reportInstance.id
  )
}

export const convertEnumerationAttributes = (attrs) => {
  const tmp: AdvancedFormAttribute[] = []
  if (attrs) {
    for (let k = 0; k < attrs.length; k++) {
      tmp.push({
        key: attrs[k],
      })
    }
  }
  return tmp
}

export const convertSimpleAttribute = (attrs, mapping) => {
  const tmp: AdvancedFormAttribute[] = []
  if (attrs) {
    let obj
    const keys = Object.keys(attrs)
    for (let k = 0; k < keys.length; k++) {
      const attr = mapping.find((attribute) => attribute.key === keys[k])
      if (!attr) {
        obj = {
          key: keys[k],
          value: attrs[keys[k]],
        }
        tmp.push(obj)
      }
    }
  }
  return tmp
}

export const convertProductAttribute = (attrs, mapping) => {
  const tmp: AdvancedFormAttribute[] = []
  if (attrs) {
    let obj
    const keys = Object.keys(attrs)
    for (let k = 0; k < keys.length; k++) {
      const attr = mapping.find((attribute) => attribute.key === keys[k])
      if (!attr) {
        obj = {
          key: keys[k],
          value: attrs[keys[k]].value,
          description: attrs[keys[k]].description || '',
        }
        tmp.push(obj)
      }
    }
  }
  return tmp
}

export const convertDetails = (attrs, configuration) => {
  const tmp: AdvancedFormAttribute[] = []
  if (attrs) {
    let obj
    const keys = Object.keys(attrs)
    for (let k = 0; k < keys.length; k++) {
      obj = {}
      for (let c = 0; c < configuration.columns.length; c++) {
        obj[configuration.columns[c].field] = attrs[keys[k]][configuration.columns[c].field] || ''
      }
      tmp.push(obj)
    }
  }
  return tmp
}

export const getHeaderOperationActions = (prefix, operation, actions, operationId, detailId) => {
  const isAdmin = AppStore.isAdmin()
  const tmp: any[] = []
  for (let a = 0; a < actions.length; a++) {
    if (actions[a].showAsCPActionButton && isAdmin) {
      tmp.push({
        label: actions[a].description,
        buttonClass: 'stylewhere-button-secondary',
        type: 'operationAction',
        param: {
          prefix: prefix,
          operation: operation,
          actionCode: actions[a].actionCode,
          operationId: operationId,
          detailId: detailId,
        },
      })
    }
  }
  return tmp
}
