import {
  AdvancedTableColumn,
  AdvancedTableContentKind,
  AdvancedTableFilterKind,
  AdvancedTableFiltersDef,
} from '../../../components/AdvancedTable'
import {
  AdvancedFormField,
  AdvancedFormFieldBuilder,
  AdvancedFormInputType,
} from '../../../components/AdvancedForm/advancedFormTypes'
import { __ } from '../../../shared/i18n'
import { DetailCell } from '../../../api'
import { AdvancedTableRowColumn } from '../../../components'
import { CAPABILITIES, getActionsTableColumns } from '../../utility/capabilityUtility'

export const DEVICE_CONTROLLERS_FILTERS: AdvancedTableFiltersDef = [
  {
    key: 'equalCodes',
    label: __('fields.labels.code'),
    type: AdvancedTableFilterKind.STRING_LIST,
    column: 'code',
    col: 8,
  },
  {
    key: 'type',
    label: __('fields.labels.type'),
    type: AdvancedTableFilterKind.ENUM,
    column: 'type',
    select: {
      option: 'types',
    },
    col: 8,
  },
  {
    key: 'description',
    label: __('fields.labels.description'),
    type: AdvancedTableFilterKind.STRING,
    column: 'description',
    col: 8,
  },
  {
    key: 'lastSeen',
    label: __('fields.labels.lastSeen'),
    type: AdvancedTableFilterKind.DATE_RANGE,
    column: 'lastSeen',
    col: 8,
  },
  {
    key: 'locked',
    label: __('fields.labels.locked'),
    type: AdvancedTableFilterKind.BOOLEAN,
    column: 'locked',
    col: 8,
  },
  {
    key: 'creationDate',
    label: __('fields.labels.creationDate'),
    type: AdvancedTableFilterKind.DATE_RANGE,
    column: 'creationDate',
    col: 8,
  },
  {
    key: 'lastModifyDate',
    label: __('fields.labels.lastModifyDate'),
    type: AdvancedTableFilterKind.DATE_RANGE,
    column: 'lastModifyDate',
    col: 8,
  },
]

export const DEVICE_CONTROLLERS_FIELDS: AdvancedFormField[] = [
  AdvancedFormFieldBuilder.buildText('description'),
  {
    key: 'code',
    type: AdvancedFormInputType.UNIQUE_TEXT,
    label: __('fields.labels.code'),
    placeholder: __('fields.placeholders.code'),
    rules: [{ required: true }],
    unique: {
      endpoint: '/api/v1/deviceControllers',
      parameter: 'equalCodes',
    },
  },
  AdvancedFormFieldBuilder.buildSelect('type', 'type', [{ required: true }]),
]

export const DEVICE_CONTROLLERS_COLUMNS: AdvancedTableColumn[] = [
  {
    key: 'code',
    dataIndex: 'code',
    title: __('fields.labels.code'),
  },
  {
    key: 'type',
    dataIndex: 'type',
    title: __('fields.labels.type'),
  },
  {
    key: 'description',
    dataIndex: 'description',
    title: __('fields.labels.description'),
  },
  {
    key: 'locked',
    dataIndex: 'locked',
    title: __('fields.labels.locked'),
    type: AdvancedTableContentKind.BOOLEAN,
  },
  {
    key: 'lastSeen',
    dataIndex: 'lastSeen',
    title: __('fields.labels.lastSeen'),
    type: AdvancedTableContentKind.DATE_TIME,
  },
  {
    key: 'creationDate',
    dataIndex: 'creationDate',
    title: __('fields.labels.creationDate'),
    type: AdvancedTableContentKind.DATE_TIME,
  },
  {
    key: 'lastModifyDate',
    dataIndex: 'lastModifyDate',
    title: __('fields.labels.lastModifyDate'),
    type: AdvancedTableContentKind.DATE_TIME,
  },
  {
    key: 'action',
    fixedType: 'right',
    className: 'stw-small',
    sortable: false,
    unmanageable: true,
    render: (text, record) => (
      <AdvancedTableRowColumn
        actions={getActionsTableColumns(
          record.id,
          '/devices/controllers',
          CAPABILITIES.RESOURCE_DEVICE_CONTROLLER_EDIT
        )}
      />
    ),
  },
]

export const DEVICE_CONTROLLERS_DATA: DetailCell[] = [
  { title: 'code', attribute: 'code', columns: 24 },
  { title: 'type', attribute: 'type', columns: 12 },
  { title: 'description', attribute: 'description', columns: 12 },
  { title: 'lastSeen', attribute: 'lastSeen', columns: 12 },
  { title: 'locked', attribute: 'locked', type: 'boolean', columns: 12 },
  { title: 'Last seen', attribute: 'lastSeen', type: 'date', columns: 12 },
  { title: 'Creation date', attribute: 'creationDate', type: 'date', columns: 12 },
  { title: 'Last modify date', attribute: 'lastModifyDate', type: 'date', columns: 12 },
]
