/* eslint-disable func-names */
import { Component } from 'react'
import { DatePicker, Form } from 'antd'
import { __ } from '../../shared/i18n'
import { AdvancedFormField } from './advancedFormTypes'

interface Props {
  record: any
  item: AdvancedFormField
  handleChange: any
}

export class AdvancedFormDateField extends Component<Props> {
  render() {
    const { item, handleChange } = this.props
    return (
      <Form.Item
        name={item.key.split('.')}
        className="stylewhere-form-item"
        rules={item.rules}
        label={item.label || __(`fields.labels.${item.key}`)}
      >
        <DatePicker
          id={item.key}
          style={{ width: '100%' }}
          onChange={(e) => handleChange(e)}
          format={item.format || 'DD/MM/YYYY'}
          placeholder={item.placeholder}
        />
      </Form.Item>
    )
  }
}
