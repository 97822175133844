import { Component } from 'react'
import { EyeOutlined } from '@ant-design/icons'
import {
  DetailCell,
  MovementType,
  StwOperation,
  StwOperationAction,
  StwFullInbound,
  StwFullParcel,
  StwFullParcelEntry,
  StwProduct,
  StwReportQueryResult,
} from '../../../api'
import {
  AdvancedTable,
  AdvancedTableColumn,
  AdvancedTableColumns,
  AdvancedTableSortDirection,
} from '../../../components/AdvancedTable'
import { PRODUCT_ROW_COL } from '../../../config/Pages/Parcels'
import {
  KeyValueGrid,
  StylewherePage,
  DefaultHeader,
  NotFound,
  Section,
  GridCards,
  SectionTitle,
  AdvancedTableRowColumn,
} from '../../../components'
import { showModal } from '../../../components/modalPageUtils'
import Parcels from '../../../api/Parcels'
import Inbounds from '../../../api/Inbounds'
import { __, T } from '../../../shared/i18n'
import { SkeletonAttributeReport, MAX_SIZE_LIMIT } from '../../../constants'
import {
  getCards,
  getFragmentObject,
  getMultipleTitle,
  getBackURL,
  getHeaderOperationActions,
  refreshFixedColumns,
} from '../../../shared/utils'
import { ExtendRouteComponentProps } from '../../../types'
import Operations from '../../../api/Operations'
import Reports from '../../../api/Reports'
import ColumnsStorage from '../../../shared/ColumnsStorage'

interface OperationRow {
  product: StwProduct
  detected: number
  unexpected: number
  itemType: boolean
}

interface State {
  reportInstance?: StwReportQueryResult
  configuration: DetailCell[]
  loader: boolean
  parcel?: StwFullParcel
  asnConfiguration: DetailCell[]
  asnReportInstance?: StwReportQueryResult
  parcelConfiguration: DetailCell[]
  parcelReportInstance?: StwReportQueryResult
  inbound?: StwFullInbound
  entries?: OperationRow[]
  unexpectedLabel: string
  detectedLabel: string
  expectedLabel: string
  rowCols: AdvancedTableColumns
  visibleManagerColumns: boolean
  actions: StwOperationAction[]
}

const PARCEL_CONTENT_TABLE_NAME = 'inboundParcelContent'

class InboundOperationDetail extends Component<ExtendRouteComponentProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      configuration: [],
      asnConfiguration: [],
      parcelConfiguration: [],
      loader: true,
      detectedLabel: '0',
      expectedLabel: '0',
      unexpectedLabel: '0',
      actions: [],
      rowCols: [],
      visibleManagerColumns: false,
    }
  }

  getParcelBaseColumns = () => {
    const result: AdvancedTableColumn[] = [
      ...PRODUCT_ROW_COL,
      {
        key: 'action',
        width: 'small',
        fixedType: 'right',
        sortable: false,
        unmanageable: true,
        render: (text, record) => (
          <>
            {record.itemType && (
              <AdvancedTableRowColumn
                actions={[
                  {
                    content: <EyeOutlined />,
                    type: 'default',
                    onclick: () => this.showDetail(record),
                  },
                ]}
              />
            )}
          </>
        ),
      },
    ]
    return result
  }

  getStorageColumns = async (
    manageColumnsPrefix: string | undefined,
    columns: AdvancedTableColumn[] | AdvancedTableColumns
  ): Promise<AdvancedTableColumn[]> => {
    if (manageColumnsPrefix) {
      return ColumnsStorage.load(manageColumnsPrefix, columns)
    }
    return Promise.all(columns)
  }

  async componentDidMount(): Promise<void> {
    await this.initialize()
  }

  async initialize() {
    const { match } = this.props
    if (match && match.params && match.params.operationInstanceId) {
      const params = { operationId: match.params.operationId }
      const reportId = await Operations.getReportId(match.params.operationId)
      const instance: any = await Reports.reportInstance(reportId, match.params.operationInstanceId, params)
      if (instance && instance.id) {
        const operation: StwOperation = await Operations.get<StwOperation>(match.params.operationId)
        const parcelId = instance!.parcelId
        const parcel = await this.getParcel(parcelId)
        const asnConfiguration = await Reports.getConfiguration('asn')
        const asnReportInstance = await Reports.reportInstance('asn', parcel.asn!.id!)
        const parcelConfiguration = await Reports.getConfiguration('parcel')
        const parcelReportInstance = await Reports.reportInstance('parcel', parcelId)
        const inbound = await Inbounds.getByIdFull(instance!.id)
        const configuration = await Reports.getConfiguration(reportId, params)
        await this.setEntriesAndCounters(inbound)
        const parcelContentStorageCols: AdvancedTableColumn[] = await this.getStorageColumns(
          PARCEL_CONTENT_TABLE_NAME,
          this.getParcelBaseColumns()
        )

        this.setState({
          reportInstance: instance,
          configuration: configuration,
          asnConfiguration,
          asnReportInstance,
          parcelConfiguration,
          parcelReportInstance,
          inbound,
          rowCols: parcelContentStorageCols,
          loader: false,
          actions: operation && operation.actions && operation.actions !== null ? operation.actions : [],
        })
      } else {
        this.setState({
          loader: false,
        })
      }
    } else {
      this.setState({
        loader: false,
      })
    }
  }

  managementColumns = () => {
    this.setState({ visibleManagerColumns: !this.state.visibleManagerColumns })
  }

  refreshTableColumns = async (columns) => {
    this.setState(
      {
        rowCols: refreshFixedColumns(columns),
      },
      this.managementColumns
    )
  }

  async getParcelEntries(inbound: StwFullInbound): Promise<StwFullParcelEntry[]> {
    return Inbounds.fullParcelentries(inbound.id, { size: MAX_SIZE_LIMIT }).then((response) => response.content)
  }

  getProducts(parcelEntries: StwFullParcelEntry[]): (StwProduct | undefined)[] {
    const products = parcelEntries.map((parcelEntry) =>
      parcelEntry.item ? parcelEntry.item.product : parcelEntry.product
    )
    const productCodes = products.map((product) => product!.code)
    return products.filter((product, index) => productCodes.indexOf(product!.code) === index)
  }

  getEntries(products: (StwProduct | undefined)[]): OperationRow[] {
    return products.map((product) => ({
      product: product!,
      detected: 0,
      unexpected: 0,
      itemType: false,
    }))
  }

  async getParcel(parcelId: string) {
    return Parcels.getFullParcel(parcelId)
  }

  showDetail(row: OperationRow) {
    showModal({
      type: 'MOVEMENT_PRODUCT_ITEMS',
      data: { movId: this.state.inbound!.id, movType: MovementType.INBOUND, prodId: row.product.id },
    })
  }

  async setEntriesAndCounters(inbound: StwFullInbound) {
    const parcelEntries = await this.getParcelEntries(inbound)
    const products = this.getProducts(parcelEntries)
    const entries = this.getEntries(products)
    parcelEntries.forEach((pe) => {
      const entry = entries.filter((e) =>
        pe.item ? e.product.code === pe.item.product.code : e.product.code === pe.product!.code
      )[0]
      entry.detected += pe.detected
      entry.unexpected += pe.unexpected
      entry.itemType = pe.item !== undefined
    })
    this.setState({
      entries: entries,
      expectedLabel: `${inbound!.totalExpected}`,
      unexpectedLabel: `${inbound!.totalUnexpected}`,
      detectedLabel: `${inbound!.totalDetected}`,
    })
  }

  render(): JSX.Element {
    const { breadcrumbs, queryString, match } = this.props
    const {
      entries,
      expectedLabel,
      unexpectedLabel,
      detectedLabel,
      rowCols,
      configuration,
      parcelConfiguration,
      asnConfiguration,
      reportInstance,
      parcelReportInstance,
      asnReportInstance,
      loader,
      actions,
    } = this.state
    const fragment = getFragmentObject(reportInstance, 'id')
    return (
      <StylewherePage {...this.props} noOverflow fragment={fragment}>
        <DefaultHeader
          backPath={getBackURL(queryString, breadcrumbs)}
          multipleTitle={getMultipleTitle(reportInstance)}
          title={__(T.misc.not_found)}
          skeleton={{ active: loader, options: { multiple: true } }}
          actions={
            reportInstance
              ? getHeaderOperationActions(
                  'logistics',
                  'inbound',
                  actions,
                  match.params.operationId,
                  match.params.operationInstanceId
                )
              : []
          }
        />
        <Section customClass={`stw-section-page paged-header ${reportInstance || loader ? 'scroll transparent' : ''}`}>
          {reportInstance || loader ? (
            <>
              <Section>
                <KeyValueGrid
                  fields={loader ? SkeletonAttributeReport : configuration}
                  data={reportInstance}
                  skeleton={loader}
                />
                <GridCards mTop={20} cards={getCards(detectedLabel, expectedLabel, undefined, unexpectedLabel)} />
              </Section>
              <Section>
                <SectionTitle title={__(T.titles.asn)} marginBottom={20} />
                <KeyValueGrid
                  fields={loader ? SkeletonAttributeReport : asnConfiguration}
                  data={asnReportInstance}
                  skeleton={loader}
                />
              </Section>
              <Section>
                <SectionTitle title={__(T.titles.parcel)} marginBottom={20} />
                <KeyValueGrid
                  fields={loader ? SkeletonAttributeReport : parcelConfiguration}
                  data={parcelReportInstance}
                  skeleton={loader}
                />
              </Section>
              <Section>
                <SectionTitle
                  title={
                    !loader && (entries?.length ?? 0) === 0
                      ? __(T.parcel.noParcelContentFoundTitle)
                      : __(T.titles.parcelContent)
                  }
                  columns={rowCols}
                  baseColumns={this.getParcelBaseColumns()}
                  managementColumns={this.managementColumns}
                  refreshTableColumns={this.refreshTableColumns}
                  visibleManagerColumns={this.state.visibleManagerColumns}
                  manageColumnsPrefix={PARCEL_CONTENT_TABLE_NAME}
                  managementFilters={() => {}}
                />
                <AdvancedTable
                  loader={loader}
                  columns={rowCols}
                  content={entries || []}
                  totalElements={entries ? entries.length : 0}
                  pagination={{
                    pageSize: entries ? entries.length : 0,
                    pageNumber: 1,
                    sortValues: { creationDate: AdvancedTableSortDirection.desc },
                  }}
                  activeDefaultClassColumn
                  fixedTableHeight
                  disabledNoDataFound
                />
              </Section>
            </>
          ) : (
            <NotFound fullheight title={__(T.misc.noRecordFoundTitle)} />
          )}
        </Section>
      </StylewherePage>
    )
  }
}

export default InboundOperationDetail
