import { Component } from 'react'
import { Checkbox, List, Tag } from 'antd'
import { StwBalance } from '../../api'
import { __ } from '../../shared/i18n'

type StwBalanceWithSelection = {
  balance: StwBalance
  selected: boolean
}

interface Props {
  index: number
  balanceWithSelection: StwBalanceWithSelection
  onSelect: (balanceId: string, selected: boolean) => void
}

export default class WorkstationBalanceSelectionModalCard extends Component<Props> {
  render() {
    const { index, balanceWithSelection, onSelect } = this.props
    return (
      <List.Item
        style={{
          paddingLeft: 16,
          paddingRight: 16,
          backgroundColor: index % 2 === 0 ? 'white' : '#ececec',
          border: '1px solid #d9d9d9',
          borderRadius: 8,
          cursor: 'pointer',
          marginBottom: 10,
        }}
        onClick={() => {
          onSelect(balanceWithSelection.balance.id!, !balanceWithSelection.selected)
        }}
        actions={[
          <Checkbox
            checked={balanceWithSelection.selected}
            onChange={() => {
              //onSelect(balanceWithSelection.balance.id!, event.target.checked)
            }}
          />,
        ]}
      >
        <List.Item.Meta
          title={
            <>
              <b>{balanceWithSelection.balance.code}</b> - {balanceWithSelection.balance.description}
            </>
          }
          description={
            <>
              <Tag style={{ borderRadius: '5px' }}>
                <b>{__('fields.labels.ipAddress')}</b>:{' '}
                {balanceWithSelection.balance.settings && balanceWithSelection.balance.settings.ipAddress
                  ? balanceWithSelection.balance.settings.ipAddress
                  : 'n/a'}
              </Tag>
              <Tag style={{ borderRadius: '5px' }}>
                <b>{__('fields.labels.port')}</b>:{' '}
                {balanceWithSelection.balance.settings && balanceWithSelection.balance.settings.port
                  ? balanceWithSelection.balance.settings.port
                  : 'n/a'}
              </Tag>
            </>
          }
        />
      </List.Item>
    )
  }
}
