import { Component } from 'react'
import { StylewherePage, TableList } from '../../../components'
import { CONVEYOR_COLUMNS, CONVEYOR_FILTERS } from '../../../config/Pages/Devices/Conveyors'
import { __, T } from '../../../shared/i18n'
import { AdvancedTableConfig } from '../../../components/AdvancedTable'
import { ExtendRouteComponentProps } from '../../../types'
import { CAPABILITIES, getActionsList } from '../../../config/utility/capabilityUtility'
import Conveyors from '../../../api/Conveyors'
import { ConveyorsOperationFilters } from '../../../constants'

const CONVEYORS_TABLE_CONFIG: AdvancedTableConfig = {
  columns: CONVEYOR_COLUMNS,
  filterDefinitions: CONVEYOR_FILTERS,
  manageColumnsPrefix: 'conveyors',
  activeDefaultClassColumn: true,
  filterParameters: {
    operationTypeFilters: ConveyorsOperationFilters,
  },
}

class ConveyorList extends Component<ExtendRouteComponentProps> {
  render() {
    return (
      <StylewherePage {...this.props} noOverflow>
        <TableList
          title={__(T.menu.conveyors)}
          location={this.props.location}
          config={CONVEYORS_TABLE_CONFIG}
          resource={{
            call: Conveyors.searchTableList,
            endpoint: `${Conveyors.endpoint}`,
          }}
          actions={getActionsList(
            CAPABILITIES.RESOURCE_CONVEYOR_EDIT,
            __(T.conveyors.create_new),
            '/devices/conveyors/create/form'
          )}
          deleteRecord={{
            call: Conveyors.deleteTableList,
            endpoint: Conveyors.endpoint,
            title: `${__(T.misc.delete_record)} ${__(T.conveyors.title)}?`,
            subtitle: __(T.misc.delete_noundo),
          }}
        />
      </StylewherePage>
    )
  }
}

export default ConveyorList
