import { Component } from 'react'
import { Row, Typography } from 'antd'
import { LeftCircleOutlined } from '@ant-design/icons'

interface Props {
  collapsed: boolean
  toggle: any
}

const { Title } = Typography

export class SidebarHeader extends Component<Props> {
  render() {
    const { collapsed, toggle } = this.props
    return (
      <Row className="stw-sider-menu-row" style={{ position: 'relative', paddingLeft: 10, paddingRight: 10 }}>
        <Title className="stw-sider-title">{collapsed ? 'S!' : 'Stylewhere'}</Title>
        <LeftCircleOutlined className="stw-sider-collapse" style={{ color: '#FFF', fontSize: 20 }} onClick={toggle} />
      </Row>
    )
  }
}
