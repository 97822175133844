import { Component } from 'react'
import { Row, Typography, Button } from 'antd'
import { Auth } from '../api/Auth'

const { Title, Text } = Typography

interface Props {
  title: string
  text?: string
}

export class ErrorPage extends Component<Props> {
  render() {
    const { title, text } = this.props
    return (
      <Row
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#FFF',
          borderRadius: 10,
        }}
      >
        <Title>{title}</Title>
        <Text strong>{text}</Text>
        <Button onClick={() => Auth.logout()} style={{ marginTop: 30 }} className="stylewhere-button-primary">
          Logout
        </Button>
      </Row>
    )
  }
}
