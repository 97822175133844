import { Component, PropsWithChildren } from 'react'
import { Layout } from 'antd'

const { Content } = Layout

interface Props extends PropsWithChildren {
  noOverflow?: boolean
}

export class PageContent extends Component<Props> {
  render() {
    const { noOverflow } = this.props
    return (
      <Content style={{ position: 'relative' }} className={noOverflow ? 'hidden' : ''}>
        {this.props.children}
      </Content>
    )
  }
}
