import { Component, createRef } from 'react'
import { Form, FormInstance, Row } from 'antd'
import { ExtendRouteComponentProps } from '../../../types'
import { StwPlace, StwWorkstation, StwWorkstationURL } from '../../../api'
import Workstations from '../../../api/Workstations'
import Places from '../../../api/Places'
import { __, T } from '../../../shared/i18n'
import { navigate } from '../../../shared/router'
import {
  AdvancedForm,
  StylewherePage,
  DefaultHeader,
  Section,
  WorkstationBalanceCreationModal,
  WorkstationBalanceSelectionModal,
  WorkstationRfidAntennasCreationModal,
  WorkstationRfidAntennasModal,
} from '../../../components'
import {
  FormValidateMessages,
  WorkstationDeviceManagerUrlType,
  WorkstationDeviceManagerWebSocketType,
} from '../../../constants'
import { WORKSTATION_FIELDS } from '../../../config/Pages/Devices/Workstations'
import { AttributeUtil } from '../../../config/utility/utility'
import { getFragmentObject, getBackURL } from '../../../shared/utils'

type StwEditableWorkstation = Omit<StwWorkstation, 'deviceManagerUrl' | 'deviceManagerWebsocket'> & {
  deviceManagerUrl?: StwWorkstationURL
  deviceManagerWebsocket?: StwWorkstationURL
}

interface State {
  workstation: StwEditableWorkstation
  loader: boolean
  isBalanceModalVisible: boolean
  isBalanceCreateModalVisible: boolean
  attachBalanceCreateModal: boolean
  balanceIdSnapshot?: string
  isRfidAntennasModalVisible: boolean
  attachRfidAntennasCreationModal: boolean
  isRfidAntennasCreationModalVisible: boolean
  refreshCounterBalance: number
  refreshCounterAntenna: number
}

export default class WorkstationForm extends Component<ExtendRouteComponentProps, State> {
  formRef = createRef<FormInstance>()

  constructor(props) {
    super(props)
    this.state = {
      workstation: {},
      loader: true,
      isBalanceModalVisible: false,
      isBalanceCreateModalVisible: false,
      attachBalanceCreateModal: false,
      isRfidAntennasModalVisible: false,
      isRfidAntennasCreationModalVisible: false,
      attachRfidAntennasCreationModal: false,
      refreshCounterBalance: 0,
      refreshCounterAntenna: 0,
    }
  }

  componentDidMount() {
    this._init()
  }

  _init = async () => {
    await this.initialize()
  }

  async initialize() {
    let workstation: StwEditableWorkstation = this.state.workstation
    if (this.props.match) {
      const workstationId = this.props.match.params.workstationId
      if (workstationId !== '' && workstationId !== 'create') {
        workstation = await this.getWorkstation(workstationId)
      } else {
        workstation.deviceManagerUrl = WorkstationForm.toURL('http://localhost:9181')
        workstation.deviceManagerWebsocket = WorkstationForm.toURL('ws://localhost:9180')
      }
    }
    this.setState(
      {
        workstation: workstation,
        loader: false,
      },
      this.updateFormFieldsValue
    )
  }

  updateFormFieldsValue = () => {
    if (this.formRef && this.formRef.current) {
      this.formRef.current.setFieldsValue(this.state.workstation)
    }
  }

  async getWorkstation(workstationId: string): Promise<StwEditableWorkstation> {
    const { deviceManagerUrl, deviceManagerWebsocket, ...workstation } =
      await Workstations.get<StwWorkstation>(workstationId)
    return {
      ...workstation,
      deviceManagerUrl: deviceManagerUrl ? WorkstationForm.toURL(deviceManagerUrl) : undefined,
      deviceManagerWebsocket: deviceManagerWebsocket ? WorkstationForm.toURL(deviceManagerWebsocket) : undefined,
    }
  }

  async store() {
    const { workstation } = this.state
    if (workstation.id) {
      await this.updateWorkstation(workstation)
    } else {
      await this.insertWorkstation(workstation)
    }
  }

  handleChange = async (key, value) => {
    const { workstation } = this.state
    if (key === 'placeId') {
      const obj: any = await this.getCodeAndDescription(value)
      if (this.formRef && this.formRef.current) {
        this.formRef.current.setFieldsValue({
          balanceId: undefined,
          rfidAntennaIds: [],
          description: obj.description,
          code: obj.code,
        })
      }
      AttributeUtil.setAttribute(workstation, key, value)
      AttributeUtil.setAttribute(workstation, 'balanceId', undefined)
      AttributeUtil.setAttribute(workstation, 'rfidAntennaIds', [])
      AttributeUtil.setAttribute(workstation, 'description', obj.description)
      AttributeUtil.setAttribute(workstation, 'code', obj.code)
    } else {
      AttributeUtil.setAttribute(workstation, key, value)
      if (key === 'attributes') {
        if (this.formRef && this.formRef.current) {
          this.formRef.current.setFieldsValue({ attributes: workstation.attributes })
        }
      }
    }
    this.setState({ workstation })
  }

  getCodeAndDescription = async (placeId) => {
    const obj = {
      code: '',
      description: '',
    }
    if (placeId !== '') {
      const place = await Places.get<StwPlace>(placeId)
      const balances = await Workstations.search<StwWorkstation>({ page: 0, size: 1, placeId: placeId })
      if (place && place.description) {
        obj.code = `${place.code}_W${balances.totalElements + 1}`
        obj.description = place.description && `${place.description}_W${balances.totalElements + 1}`
      }
    }
    return obj
  }

  private static toURL(address: string): StwWorkstationURL | undefined {
    try {
      const url = new URL(address)
      const tmp: StwWorkstationURL = {
        protocol: url.protocol,
        hostname: url.hostname,
        port: parseInt(url.port, 10),
      }
      return tmp
    } catch (_) {
      return undefined
    }
  }

  private static toAddress({ protocol, hostname, port }: StwWorkstationURL): string {
    return protocol && hostname && port ? `${protocol}//${hostname}:${port}` : ''
  }

  shouldComponentUpdate(nextProps: Readonly<ExtendRouteComponentProps>): boolean {
    const { workstation, loader } = this.state
    const nextWorkstationId = nextProps.match.params.workstationId
    if (
      !loader &&
      nextWorkstationId &&
      nextWorkstationId !== '' &&
      nextWorkstationId !== 'create' &&
      nextWorkstationId !== workstation.id
    ) {
      this.setState({ loader: true }, this.initialize)
    }
    return true
  }

  private async updateWorkstation({
    deviceManagerUrl,
    deviceManagerWebsocket,
    ...workstation
  }: StwEditableWorkstation): Promise<void> {
    await Workstations.update<StwWorkstation>({
      ...workstation,
      deviceManagerUrl: deviceManagerUrl ? WorkstationForm.toAddress(deviceManagerUrl) : '',
      deviceManagerWebsocket: deviceManagerWebsocket ? WorkstationForm.toAddress(deviceManagerWebsocket) : '',
    })
    navigate(`/devices/workstations${this.props.queryString ?? ''}`)
  }

  showBalanceModal = () => {
    this.setState({ isBalanceModalVisible: true })
  }

  handleBalanceModalConfirm = (balanceId) => {
    this.setState({
      isBalanceModalVisible: false,
      workstation: {
        ...this.state.workstation,
        balanceId: balanceId,
      },
    })
  }

  handleBalanceModalCancel = () => {
    this.setState({ isBalanceModalVisible: false })
  }

  handleNewScale = () => {
    this.setState({ isBalanceModalVisible: false }, this.openNewScaleModal)
  }

  openNewScaleModal = () => {
    setTimeout(() => {
      this.setState({ isBalanceCreateModalVisible: true, attachBalanceCreateModal: true })
    }, 200)
  }

  handleBalanceCreateModalConfirm = (balanceId) => {
    const refreshCounter = this.state.refreshCounterBalance + 1
    this.setState(
      {
        isBalanceCreateModalVisible: false,
        refreshCounterBalance: refreshCounter,
        workstation: {
          ...this.state.workstation,
          balanceId: balanceId,
        },
      },
      this.unattachBalanceCreateModal
    )
  }

  handleBalanceCreateModalCancel = () => {
    this.setState({ isBalanceCreateModalVisible: false }, this.unattachBalanceCreateModal)
  }

  unattachBalanceCreateModal = () => {
    setTimeout(() => {
      this.setState({ attachBalanceCreateModal: false })
    }, 500)
  }

  showRfidAntennasModal = () => {
    this.setState({ isRfidAntennasModalVisible: true })
  }

  handleRfidAntennasModalConfirm = (selected) => {
    this.setState({
      isRfidAntennasModalVisible: false,
      workstation: {
        ...this.state.workstation,
        rfidAntennaIds: selected,
      },
    })
  }

  handleRfidAntennaModalCancel = () => {
    this.setState({ isRfidAntennasModalVisible: false })
  }

  handleNewAntennas = () => {
    this.setState({ isRfidAntennasModalVisible: false }, this.openNewAntennasModal)
  }

  openNewAntennasModal = () => {
    setTimeout(() => {
      this.setState({ isRfidAntennasCreationModalVisible: true, attachRfidAntennasCreationModal: true })
    }, 200)
  }

  handleAntennasCreateModalConfirm = (antennaIds) => {
    const refreshCounter = this.state.refreshCounterAntenna + 1
    const tmp = this.state.workstation.rfidAntennaIds || []
    for (let a = 0; a < antennaIds.length; a++) tmp.push(antennaIds[a])
    this.setState(
      {
        isRfidAntennasCreationModalVisible: false,
        refreshCounterAntenna: refreshCounter,
        workstation: {
          ...this.state.workstation,
          rfidAntennaIds: tmp,
        },
      },
      this.unattachRfidAntennasCreationModal
    )
  }

  handleAntennasCreateModalCancel = () => {
    this.setState({ isRfidAntennasCreationModalVisible: false }, this.unattachRfidAntennasCreationModal)
  }

  unattachRfidAntennasCreationModal = () => {
    setTimeout(() => {
      this.setState({ attachRfidAntennasCreationModal: false })
    }, 500)
  }

  insertWorkstation = async ({ deviceManagerUrl, deviceManagerWebsocket, ...workstation }: StwEditableWorkstation) => {
    const newWorkstation = await Workstations.insert<StwWorkstation>({
      ...workstation,
      deviceManagerUrl: deviceManagerUrl ? WorkstationForm.toAddress(deviceManagerUrl) : '',
      deviceManagerWebsocket: deviceManagerWebsocket ? WorkstationForm.toAddress(deviceManagerWebsocket) : '',
    })
    if (newWorkstation && newWorkstation.id) {
      navigate(`/devices/workstations${this.props.queryString ?? ''}`)
    }
  }

  render() {
    const {
      workstation,
      loader,
      isBalanceCreateModalVisible,
      attachBalanceCreateModal,
      isBalanceModalVisible,
      isRfidAntennasModalVisible,
      isRfidAntennasCreationModalVisible,
      attachRfidAntennasCreationModal,
      refreshCounterBalance,
      refreshCounterAntenna,
    } = this.state
    const { breadcrumbs, queryString } = this.props
    const title = workstation.id ? __(T.workstations.edit) : __(T.workstations.create_new)
    const fragment = !loader ? getFragmentObject(workstation, 'id', __(T.workstations.create_new)) : undefined
    return (
      <Row style={{ width: '100%' }}>
        <Form
          ref={this.formRef}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          layout="vertical"
          onFinish={async () => {
            await this.store()
          }}
          style={{ width: '100%', height: '100%' }}
          initialValues={workstation}
          validateMessages={FormValidateMessages}
          scrollToFirstError
        >
          <StylewherePage {...this.props} noOverflow fragment={fragment}>
            <DefaultHeader
              backPath={getBackURL(queryString, breadcrumbs)}
              title={loader ? '...' : title}
              skeleton={{ active: loader }}
              actions={[
                {
                  label: __(T.misc.cancel),
                  type: 'cancel',
                  onClick: () => navigate(`/devices/workstations${queryString ?? ''}`),
                },
                {
                  label: workstation.id ? __(T.misc.update) : __(T.misc.create),
                  type: 'submit',
                },
              ]}
            />
            <Section customClass="stw-section-page paged-header scroll">
              <AdvancedForm
                record={workstation}
                width="100%"
                fields={WORKSTATION_FIELDS}
                store={() => this.store()}
                editing={workstation.id !== undefined}
                handleChange={(key, value) => this.handleChange(key, value)}
                parameters={{
                  deviceManagerUrlProtocols: WorkstationDeviceManagerUrlType,
                  deviceManagerWebsocketProtocols: WorkstationDeviceManagerWebSocketType,
                }}
                showBalanceModal={() => this.showBalanceModal()}
                showRfidAntennasModal={() => this.showRfidAntennasModal()}
              />
            </Section>
          </StylewherePage>
        </Form>
        {!loader && (
          <WorkstationBalanceSelectionModal
            refreshCounter={refreshCounterBalance}
            placeId={workstation.placeId || ''}
            balanceId={workstation.balanceId || ''}
            visible={isBalanceModalVisible}
            onConfirm={this.handleBalanceModalConfirm}
            onCancel={() => this.handleBalanceModalCancel()}
            handleNewScale={() => this.handleNewScale()}
            workstationId={workstation.id || ''}
          />
        )}
        {!loader && attachBalanceCreateModal && (
          <WorkstationBalanceCreationModal
            placeId={workstation.placeId || ''}
            visible={isBalanceCreateModalVisible}
            onConfirm={this.handleBalanceCreateModalConfirm}
            onCancel={() => this.handleBalanceCreateModalCancel()}
          />
        )}
        {!loader && (
          <WorkstationRfidAntennasModal
            refreshCounter={refreshCounterAntenna}
            placeId={workstation.placeId || ''}
            rfidAntennaIds={workstation.rfidAntennaIds || []}
            visible={isRfidAntennasModalVisible}
            onConfirm={this.handleRfidAntennasModalConfirm}
            onCancel={() => this.handleRfidAntennaModalCancel()}
            handleNewAntennas={() => this.handleNewAntennas()}
            workstationId={workstation.id || ''}
          />
        )}
        {!loader && attachRfidAntennasCreationModal && (
          <WorkstationRfidAntennasCreationModal
            placeId={workstation.placeId || ''}
            visible={isRfidAntennasCreationModalVisible}
            onConfirm={this.handleAntennasCreateModalConfirm}
            onCancel={() => this.handleAntennasCreateModalCancel()}
          />
        )}
      </Row>
    )
  }
}
