import { Component, createRef } from 'react'
import { Form, FormInstance } from 'antd'
import { StwRole, StwRoleMapping } from '../../../../api'
import { ExtendRouteComponentProps } from '../../../../types'
import { __, T } from '../../../../shared/i18n'
import { navigate } from '../../../../shared/router'
import { AdvancedForm, StylewherePage, DefaultHeader, Section } from '../../../../components'
import { FormValidateMessages } from '../../../../constants'
import { AttributeUtil } from '../../../../config/utility/utility'
import RoleMappings from '../../../../api/RoleMappings'
import { ROLE_MAPPINGS_FIELDS } from '../../../../config/Pages/UsersRoles/RoleMappings'
import { FormUtility } from '../../../../config/utility/formUtility'
import { getFragmentObject, getBackURL } from '../../../../shared/utils'

interface State {
  roleMappingId: string
  roleMapping: StwRoleMapping
  loader: boolean
  isCodeEditableByDescription: boolean
}

class RoleMappingForm extends Component<ExtendRouteComponentProps, State> {
  formRef = createRef<FormInstance>()

  constructor(props) {
    super(props)
    this.state = {
      roleMappingId: '',
      roleMapping: {
        code: '',
      },
      loader: true,
      isCodeEditableByDescription: true,
    }
  }

  componentDidMount() {
    this.initialize()
  }

  initialize = async () => {
    let result
    if (this.props.match) {
      const roleMappingId = this.props.match.params.roleMappingId
      if (roleMappingId !== '' && roleMappingId !== 'create') {
        result = await RoleMappings.get<StwRole>(roleMappingId)
      }
    }

    this.setState(
      {
        roleMappingId: result ? result.id! : '',
        roleMapping: result || {},
        isCodeEditableByDescription: !result,
        loader: false,
      },
      this.updateFormFieldsValue
    )
  }

  updateFormFieldsValue = () => {
    if (this.formRef && this.formRef.current) {
      this.formRef.current.setFieldsValue(this.state.roleMapping)
    }
  }

  handleChange = (key, value) => {
    const { roleMapping, isCodeEditableByDescription } = this.state
    AttributeUtil.setAttribute(roleMapping, key, value)
    if (key === 'description' && isCodeEditableByDescription) {
      FormUtility.handleDescription(value, roleMapping, this.formRef)
    } else if (key === 'code') {
      this.setState({ isCodeEditableByDescription: false })
    }
    this.setState({
      roleMapping: roleMapping,
    })
  }

  store = () => {
    const { roleMapping, roleMappingId } = this.state
    const { queryString } = this.props
    if (roleMappingId !== '') {
      RoleMappings.update<StwRole>(roleMapping).then(() => {
        navigate(`/configuration/usersRoles/roleMappings${queryString ?? ''}`)
      })
    } else {
      RoleMappings.insert<StwRole>(roleMapping).then((newRoleMapping) => {
        if (newRoleMapping && newRoleMapping.id) {
          navigate(`/configuration/usersRoles/roleMappings${queryString ?? ''}`)
        }
      })
    }
  }

  render() {
    const { roleMapping, roleMappingId, loader } = this.state
    const title = roleMappingId !== '' ? __(T.roleMapping.edit) : __(T.roleMapping.create_new)
    const { breadcrumbs, queryString } = this.props
    const fragment = !loader ? getFragmentObject(roleMapping, 'id', __(T.roleMapping.create_new)) : undefined
    return (
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        onFinish={this.store}
        style={{ width: '100%', height: '100%' }}
        ref={this.formRef}
        initialValues={roleMapping}
        validateMessages={FormValidateMessages}
        scrollToFirstError
      >
        <StylewherePage {...this.props} noOverflow fragment={fragment}>
          <DefaultHeader
            backPath={getBackURL(queryString, breadcrumbs)}
            title={loader ? '...' : title}
            skeleton={{ active: loader }}
            actions={[
              {
                label: __(T.misc.cancel),
                type: 'cancel',
                onClick: () => navigate(`/configuration/usersRoles/capabilitiesGroup${queryString ?? ''}`),
              },
              {
                label: roleMappingId !== '' ? __(T.misc.update) : __(T.misc.create),
                type: 'submit',
              },
            ]}
          />
          <Section customClass="stw-section-page paged-header scroll">
            <AdvancedForm
              record={roleMapping}
              fields={ROLE_MAPPINGS_FIELDS}
              store={this.store}
              editing={roleMapping.id !== undefined}
              handleChange={this.handleChange}
            />
          </Section>
        </StylewherePage>
      </Form>
    )
  }
}

export default RoleMappingForm
