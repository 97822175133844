import { Component } from 'react'
import { Space } from 'antd'

import IconReload from '../../../../icon/icon-reload-black.png'

interface Props {
  reloadData?: () => void
}

export class TableReloadButton extends Component<Props> {
  render() {
    const { reloadData } = this.props
    return reloadData ? (
      <Space size="small" className="stw-reload" onClick={() => reloadData()}>
        <div className="icona" style={{ backgroundImage: `url(${IconReload})` }} />
      </Space>
    ) : null
  }
}
