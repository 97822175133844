import { Component } from 'react'
import { StylewherePage, TableList } from '../../../components'
import { __, T } from '../../../shared/i18n'
import { AdvancedTableConfig, AdvancedTableColumn } from '../../../components/AdvancedTable'
import { ExtendRouteComponentProps } from '../../../types'
import { CAPABILITIES, getActionsList } from '../../../config/utility/capabilityUtility'
import { BARCODE_READERS_COLUMNS, BARCODE_READERS_FILTERS } from '../../../config/Pages/Devices/BarcodeReaders'
import BarcodeReaders from '../../../api/BarcodeReaders'
import { BarcodeReaderType } from '../../../constants'
import { getTableActionsReport } from '../../../shared/utils'

interface State {
  tableConfig: AdvancedTableConfig | undefined
}

class BarcodeReadersList extends Component<ExtendRouteComponentProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      tableConfig: undefined,
    }
  }

  componentDidMount = async () => {
    this.setState({
      tableConfig: {
        columns: this.getTableColumns(),
        filterDefinitions: BARCODE_READERS_FILTERS,
        filterParameters: { barcodeReaderType: BarcodeReaderType },
        manageColumnsPrefix: 'barcodeReaders',
        activeDefaultClassColumn: true,
      },
    })
  }

  getTableColumns = () => {
    const t: AdvancedTableColumn[] = [
      ...BARCODE_READERS_COLUMNS,
      {
        key: 'action',
        width: 'small',
        fixedType: 'right',
        unmanageable: true,
        hidden: false,
        sortable: false,
        reportActions: getTableActionsReport('barcodeReaders'),
        basePath: '/devices/barcodeReaders',
      },
    ]
    return t
  }

  render() {
    const { tableConfig } = this.state
    return (
      <StylewherePage {...this.props} noOverflow>
        {tableConfig && (
          <TableList
            title={__(T.menu.barcodeReaders)}
            location={this.props.location}
            config={tableConfig}
            resource={{
              call: BarcodeReaders.searchTableList,
              endpoint: BarcodeReaders.endpoint,
            }}
            actions={getActionsList(
              CAPABILITIES.RESOURCE_BARCODE_READER_EDIT,
              __(T.barcodeReaders.create_new),
              '/devices/barcodeReaders/create/form'
            )}
            deleteRecord={{
              call: BarcodeReaders.deleteTableList,
              endpoint: BarcodeReaders.endpoint,
              title: `${__(T.misc.delete_record)} ${__(T.barcodeReaders.barcodeReader)}?`,
              subtitle: __(T.misc.delete_noundo),
            }}
          />
        )}
      </StylewherePage>
    )
  }
}

export default BarcodeReadersList
