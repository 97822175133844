import { Component } from 'react'
import {
  GridData,
  StylewherePage,
  Section,
  DefaultHeader,
  NotFound,
  TableList,
  AdvancedTable,
} from '../../../components'
import { DetailCell, StwRfidReader } from '../../../api'
import { __, T } from '../../../shared/i18n'
import { ExtendRouteComponentProps } from '../../../types'
import { AdvancedTableConfig } from '../../../components/AdvancedTable'
import {
  RFID_READER_ANTENNAS_COLUMNS,
  RFID_READER_GPO_PORTS_COLUMNS,
  RFID_READERS_DATA,
} from '../../../config/Pages/Devices/RfidReaders'
import RfidReaders from '../../../api/RfidReaders'
import RfidAntennas from '../../../api/RfidAntennas'
import { DEFAULT_PAGE_SIZE, SkeltonAttribute } from '../../../constants'
import { getMultipleTitle, getBackURL, getFragmentObject } from '../../../shared/utils'

interface State {
  rfidReader: StwRfidReader | undefined
  settingsFields: DetailCell[]
  loader: boolean
}

const ANTENNAS_TABLE_CONFIG: AdvancedTableConfig = {
  columns: RFID_READER_ANTENNAS_COLUMNS,
  filterDefinitions: [],
  activeDefaultClassColumn: true,
  fixedTableHeight: true,
  disableLocation: true,
  disableColumnFilters: true,
  skeletonOptions: {},
  disabledNoDataFound: true,
  disabledScroll: false,
  disableManagerColumns: true,
}

class RfidReaderDetail extends Component<ExtendRouteComponentProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      rfidReader: undefined,
      settingsFields: [],
      loader: true,
    }
  }

  componentDidMount() {
    this.initialize()
  }

  initialize = async () => {
    let rfidReader: StwRfidReader = {}
    const settingsConfiguration: DetailCell[] = []
    if (this.props.match) {
      const rfidReaderId = this.props.match.params.rfidReaderId
      if (rfidReaderId) {
        rfidReader = await RfidReaders.get<StwRfidReader>(rfidReaderId).then((res) => res)
        if (rfidReader.settings) {
          const keys = Object.keys(rfidReader.settings)
          keys.forEach((key) => {
            settingsConfiguration.push({ title: key, attribute: key, columns: 8 })
          })
        }
      }
    }
    this.setState({
      rfidReader: rfidReader,
      settingsFields: settingsConfiguration,
      loader: false,
    })
  }

  render() {
    const { breadcrumbs, queryString } = this.props
    const { rfidReader, settingsFields, loader } = this.state
    const viewSettings = loader || (rfidReader && rfidReader.settings && Object.keys(rfidReader.settings).length > 0)
    const fragment = getFragmentObject(rfidReader, 'code')
    return (
      <StylewherePage {...this.props} noOverflow fragment={fragment}>
        <DefaultHeader
          backPath={getBackURL(queryString, breadcrumbs)}
          multipleTitle={getMultipleTitle(rfidReader)}
          title={__(T.misc.not_found)}
          skeleton={{ active: loader, options: { multiple: true } }}
        />
        <Section customClass={`stw-section-page paged-header ${rfidReader || loader ? 'scroll transparent' : ''}`}>
          {rfidReader || loader ? (
            <>
              <GridData fields={RFID_READERS_DATA} record={rfidReader || {}} skeleton={loader} />
              {rfidReader && (
                <TableList
                  title={__(T.misc.antennas_title)}
                  location={this.props.location}
                  headerType="boxed"
                  config={ANTENNAS_TABLE_CONFIG}
                  resource={{
                    call: RfidAntennas.searchTableList,
                    endpoint: RfidAntennas.endpoint,
                    filters: { readerId: rfidReader.id },
                  }}
                />
              )}
              {viewSettings && (
                <GridData
                  title={__(T.misc.settings_title)}
                  fields={loader ? SkeltonAttribute : settingsFields}
                  record={!rfidReader ? [] : rfidReader.settings}
                  skeleton={loader}
                />
              )}
              <Section>
                <AdvancedTable
                  loader={loader}
                  tableTitle={{ title: __(T.misc.ports_title), emptyTitle: __(T.rfidReaders.noPortsFoundTitle) }}
                  pagination={{
                    pageNumber: 1,
                    pageSize: DEFAULT_PAGE_SIZE,
                    sortValues: {},
                  }}
                  columns={RFID_READER_GPO_PORTS_COLUMNS}
                  filters={{
                    filterDefinitions: [],
                  }}
                  content={[...((rfidReader && rfidReader.ports) ?? [])]}
                  totalElements={rfidReader && rfidReader.ports ? rfidReader.ports.length : 0}
                  disabledPagination
                  disabledNoDataFound
                  activeDefaultClassColumn
                />
              </Section>
            </>
          ) : (
            <NotFound fullheight title={__(T.misc.noRecordFoundTitle)} />
          )}
        </Section>
      </StylewherePage>
    )
  }
}

export default RfidReaderDetail
