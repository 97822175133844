import AppStore from 'core/shared/AppStore'
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons'
import { ReactNode } from 'react'
import { ButtonNewEntry } from '../../components'

export const hasAnyCapabilities = (...capabilities: string[]): boolean => {
  return AppStore.hasAnyCapabilities(capabilities)
}

export const getEditActionsTableColumns = (recordId: string, path: string) => {
  const actions: any[] = []
  actions.push({ content: <EyeOutlined />, type: 'default', path: `${path}/${recordId}` })
  return actions
}

export const getActionsTableColumns = (
  recordId: string,
  path: string,
  capability: string,
  canDelete?: boolean,
  deleteCallback?: (recordId: string) => void
) => {
  const actions: any[] = []
  actions.push({ content: <EyeOutlined />, type: 'default', path: `${path}/${recordId}` })
  if (capability !== '' && hasAnyCapabilities(capability)) {
    actions.push({ content: <EditOutlined />, type: 'default', path: `${path}/${recordId}/form` })
  }
  if (canDelete && deleteCallback && capability !== '' && hasAnyCapabilities(capability)) {
    actions.push({ content: <DeleteOutlined />, type: 'default', onclick: () => deleteCallback(recordId) })
  }
  return actions
}

export const getActionsList = (capability: string, label: string, path: string): ReadonlyArray<ReactNode> => {
  return hasAnyCapabilities(capability) ? [<ButtonNewEntry key="header_add_button" label={label} path={path} />] : []
}

export const CAPABILITIES = {
  SYSTEM_ADMINISTRATION_PERFORM: 'System.Administration.perform',
  SYSTEM_DEV_VIEW: 'System.Dev.view',
  SYSTEM_DEV_EDIT: 'System.Dev.edit',

  // MENU
  CONTROL_PANEL_PLACE_VIEW: 'ControlPanel.Place.view',
  CONTROL_PANEL_ZONE_VIEW: 'ControlPanel.Zone.view',
  CONTROL_PANEL_USER_VIEW: 'ControlPanel.User.view',
  CONTROL_PANEL_CAPABILITY_GROUP_VIEW: 'ControlPanel.CapabilityGroup.view',
  CONTROL_PANEL_ROLE_VIEW: 'ControlPanel.Role.view',
  CONTROL_PANEL_ROLE_MAPPING_VIEW: 'ControlPanel.RoleMapping.view',
  CONTROL_PANEL_OPERATION_VIEW: 'ControlPanel.Operation.view',
  CONTROL_PANEL_ITEM_CONFIGURATION_VIEW: 'ControlPanel.ItemConfiguration.view',
  CONTROL_PANEL_ITEM_TYPE_VIEW: 'ControlPanel.ItemType.view',
  CONTROL_PANEL_ENUMERATION_TYPE_VIEW: 'ControlPanel.EnumerationType.view',
  CONTROL_PANEL_ENUMERATION_VALUE_VIEW: 'ControlPanel.EnumerationValue.view',
  CONTROL_PANEL_WORKSTATION_VIEW: 'ControlPanel.Workstation.view',
  CONTROL_PANEL_RFID_READER_VIEW: 'ControlPanel.RfidReader.view',
  CONTROL_PANEL_RFID_ANTENNA_VIEW: 'ControlPanel.RfidAntenna.view',
  CONTROL_PANEL_DEVICE_CONTROLLER_VIEW: 'ControlPanel.DeviceController.view',
  CONTROL_PANEL_PRINTER_VIEW: 'ControlPanel.Printer.view',
  CONTROL_PANEL_SCANNER_VIEW: 'ControlPanel.Scanner.view',
  CONTROL_PANEL_XSPAN_READER_VIEW: 'ControlPanel.XspanReader.view',
  CONTROL_PANEL_CONVEYOR_VIEW: 'ControlPanel.Conveyor.view',
  CONTROL_PANEL_BALANCE_VIEW: 'ControlPanel.Balance.view',
  CONTROL_PANEL_BARCODE_READER_VIEW: 'ControlPanel.BarcodeReader.view',
  CONTROL_PANEL_ATTRIBUTE_MAPPING_VIEW: 'ControlPanel.AttributeMapping.view',
  CONTROL_PANEL_APPLICATION_SETTING_VIEW: 'ControlPanel.ApplicationSetting.view',
  CONTROL_PANEL_PICKING_VIEW: 'ControlPanel.Picking.view',
  CONTROL_PANEL_PRODUCT_VIEW: 'ControlPanel.Product.view',
  CONTROL_PANEL_CHECKLIST_VIEW: 'ControlPanel.Checklist.view',
  CONTROL_PANEL_ITEM_IDENTIFIER_VIEW: 'ControlPanel.ItemIdentifier.view',

  // PROFILE
  RESOURCE_ZONE_VIEW: 'Resource.Zone.view',
  RESOURCE_ZONE_EDIT: 'Resource.Zone.edit',
  RESOURCE_PLACE_VIEW: 'Resource.Place.view',
  RESOURCE_PLACE_EDIT: 'Resource.Place.edit',
  RESOURCE_CAPABILITY_VIEW: 'Resource.Capability.view',
  RESOURCE_CAPABILITY_EDIT: 'Resource.Capability.edit',
  RESOURCE_CAPABILITY_GROUP_VIEW: 'Resource.CapabilityGroup.view',
  RESOURCE_CAPABILITY_GROUP_EDIT: 'Resource.CapabilityGroup.edit',
  RESOURCE_ENUMERATION_TYPE_VIEW: 'Resource.EnumerationType.view',
  RESOURCE_ENUMERATION_TYPE_EDIT: 'Resource.EnumerationType.edit',
  RESOURCE_ENUMERATION_VALUE_VIEW: 'Resource.EnumerationValue.view',
  RESOURCE_ENUMERATION_VALUE_EDIT: 'Resource.EnumerationValue.edit',
  RESOURCE_OPERATION_OPTION_VIEW: 'Resource.OperationOption.view',
  RESOURCE_OPERATION_OPTION_EDIT: 'Resource.OperationOption.edit',
  RESOURCE_OPERATION_VIEW: 'Resource.Operation.view',
  RESOURCE_OPERATION_EDIT: 'Resource.Operation.edit',
  RESOURCE_ROLE_MAPPING_VIEW: 'Resource.RoleMapping.view',
  RESOURCE_ROLE_MAPPING_EDIT: 'Resource.RoleMapping.edit',
  RESOURCE_ROLE_VIEW: 'Resource.Role.view',
  RESOURCE_ROLE_EDIT: 'Resource.Role.edit',
  RESOURCE_USER_PROFILE_VIEW: 'Resource.UserProfile.view',
  RESOURCE_USER_PROFILE_EDIT: 'Resource.UserProfile.edit',
  RESOURCE_USER_VIEW: 'Resource.User.view',
  RESOURCE_USER_EDIT: 'Resource.User.edit',
  RESOURCE_ATTRIBUTE_MAPPING_EDIT: 'Resource.AttributeMapping.edit',
  RESOURCE_APPLICATION_SETTING_VIEW: 'Resource.ApplicationSetting.view',
  RESOURCE_APPLICATION_SETTING_EDIT: 'Resource.ApplicationSetting.edit',
  RESOURCE_TEMPLATES_VIEW: 'Resource.Templating.view',
  RESOURCE_TEMPLATES_EDIT: 'Resource.Templating.edit',

  // TRACKING
  RESOURCE_ITEM_CONFIGURATION_VIEW: 'Resource.ItemConfiguration.view',
  RESOURCE_ITEM_CONFIGURATION_EDIT: 'Resource.ItemConfiguration.edit',
  RESOURCE_ITEM_TYPE_VIEW: 'Resource.ItemType.view',
  RESOURCE_ITEM_TYPE_EDIT: 'Resource.ItemType.edit',
  RESOURCE_ITEM_IDENTIFIER_VIEW: 'Resource.ItemIdentifier.view',
  RESOURCE_ITEM_VIEW: 'Resource.Item.view',
  RESOURCE_ITEM_EDIT: 'Resource.Item.edit',
  RESOURCE_ITEM_EVENT_VIEW: 'Resource.ItemEvent.view',
  RESOURCE_PRODUCT_EDIT: 'Resource.Product.edit',
  RESOURCE_PRODUCT_VIEW: 'Resource.Product.view',
  OPERATION_ITEM_LIST_PERFORM: 'Operation.SimpleItemList.perform',
  OPERATION_TTZ_PERFORM: 'Operation.ItemEvent.view',
  RESOURCE_PRODUCTIONORDER_VIEW: 'Resource.ProductionOrder.view',
  RESOURCE_PRODUCTIONORDER_EDIT: 'Resource.ProductionOrder.edit',
  RESOURCE_PRODUCTIONORDERROW_VIEW: 'Resource.ProductionOrderRow.view',
  RESOURCE_PRODUCTIONORDERROW_EDIT: 'Resource.ProductionOrderRow.edit',
  //EDGE
  RESOURCE_BALANCE_VIEW: 'Resource.Balance.view',
  RESOURCE_BALANCE_EDIT: 'Resource.Balance.edit',
  RESOURCE_BARCODE_READER_VIEW: 'Resource.BarcodeReader.view',
  RESOURCE_BARCODE_READER_EDIT: 'Resource.BarcodeReader.edit',
  RESOURCE_DEVICE_CONTROLLER_VIEW: 'Resource.DeviceController.view',
  RESOURCE_DEVICE_CONTROLLER_EDIT: 'Resource.DeviceController.edit',
  RESOURCE_PRINTER_VIEW: 'Resource.Printer.view',
  RESOURCE_PRINTER_EDIT: 'Resource.Printer.edit',
  RESOURCE_RFID_ANTENNA_VIEW: 'Resource.RfidAntenna.view',
  RESOURCE_RFID_ANTENNA_EDIT: 'Resource.RfidAntenna.edit',
  RESOURCE_RFID_READER_VIEW: 'Resource.RfidReader.view',
  RESOURCE_RFID_READER_EDIT: 'Resource.RfidReader.edit',
  RESOURCE_SCANNER_VIEW: 'Resource.Scanner.view',
  RESOURCE_SCANNER_EDIT: 'Resource.Scanner.edit',
  RESOURCE_SCANNER_MANAGE: 'Resource.Scanner.manage',
  RESOURCE_SCANNER_MONITOR: 'Resource.Scanner.monitor',
  RESOURCE_WORKSTATION_VIEW: 'Resource.Workstation.view',
  RESOURCE_WORKSTATION_EDIT: 'Resource.Workstation.edit',
  RESOURCE_XSPAN_READER_VIEW: 'Resource.XspanReader.view',
  RESOURCE_XSPAN_READER_EDIT: 'Resource.XspanReader.edit',
  RESOURCE_CONVEYOR_VIEW: 'Resource.Conveyor.view',
  RESOURCE_CONVEYOR_EDIT: 'Resource.Conveyor.edit',
  // INVENTORY
  RESOURCE_INVENTORY_VIEW: 'Resource.Inventory.view',
  RESOURCE_INVENTORY_VIEW_EXPECTED_COUNTERS:
    '" + CapabilityConstants.RESOURCE_INVENTORY_VIEW_EXPECTED_COUNTERS_CAPABILITY_STRING + ',
  OPERATION_INVENTORY_PERFORM: 'Operation.Inventory.perform',
  // LOGISTICS
  RESOURCE_ASN_VIEW: 'Resource.Asn.view',
  RESOURCE_CHECKLIST_VIEW: 'Resource.Checklist.view',
  RESOURCE_CHECKLIST_EDIT: 'Resource.Checklist.edit',
  RESOURCE_INBOUND_VIEW: 'Resource.Inbound.view',
  OPERATION_INBOUND_PERFORM: 'Operation.Inbound.perform',
  OPERATION_INBOUND_PERFORMFORCED: 'Operation.Inbound.performForced',
  RESOURCE_INVENTORYPARCELENTRY_VIEW: 'Resource.InventoryParcelEntry.view',
  RESOURCE_INVENTORYPARCEL_VIEW: 'Resource.InventoryParcel.view',
  RESOURCE_INVENTORYPARCEL_VIEW_EXPECTED_COUNTERS: 'Resource.InventoryParcel.viewExpectedCounters',
  RESOURCE_INVENTORYPARCEL_VIEW_EXPECTED_COUNTERS_CAPABILITY_STRING: 'Resource.InventoryParcel.viewExpectedCounters',
  RESOURCE_INVENTORY_VIEW_EXPECTED_COUNTERS_CAPABILITY_STRING: 'Resource.Inventory.viewExpectedCounters',
  OPERATION_INVENTORYPARCEL_PERFORM: 'Operation.InventoryParcel.perform',
  RESOURCE_OUTBOUND_VIEW: 'Resource.Outbound.view',
  OPERATION_OUTBOUND_PERFORM: 'Operation.Outbound.perform',
  OPERATION_OUTBOUND_PERFORMFORCED: 'Operation.Outbound.performForce',
  RESOURCE_PACKING_VIEW: 'Resource.Packing.view',
  OPERATION_PACKING_PERFORM: 'Operation.Packing.perform',
  OPERATION_PACKING_PERFORMFORCED: 'Operation.Packing.performForced',
  RESOURCE_PALLET_VIEW: 'Resource.Pallet.view',
  RESOURCE_PALLET_EDIT: 'Resource.Pallet.edit',
  RESOURCE_PARCEL_VIEW: 'Resource.Parcel.view',
  RESOURCE_SORTING_VIEW: 'Resource.Sorting.view',
  RESOURCE_SORTING_EDIT: 'Resource.Sorting.edit',
  RESOURCE_SORTING_PERFORM: 'Resource.Sorting.perform',
  OPERATION_SIMPLEPARCELLIST_PERFORM: 'Operation.SimpleParcelList.perform',
  RESOURCE_PICKING_VIEW: 'Resource.Picking.view',
  RESOURCE_PICKING_EDIT: 'Resource.Picking.edit',
  OPERATION_PICKING_PERFORM: 'Operation.Picking.perform',
  RESOURCE_PICKING_ENTRY_VIEW: 'Resource.PickingEntry.view',
  //Jobs
  RESOURCE_ASYNCJOBS_VIEW: 'Resource.AsyncJobs.view',
  RESOURCE_ASYNCJOBS_EDIT: 'Resource.AsyncJobs.edit',

  RESOURCE_SIMPLE_ITEM_SESSION_LIST_VIEW: 'Resource.SimpleItemSessionList.view',
  OPERATION_SIMPLE_ITEM_SESSION_LIST_VIEW: 'Operation.SimpleItemSessionList.perform',
}

export const getReportListCapability = (report) => {
  let capability = ''
  switch (report) {
    case 'product':
      capability = CAPABILITIES.CONTROL_PANEL_PRODUCT_VIEW
      break
    case 'checklist':
      capability = CAPABILITIES.CONTROL_PANEL_CHECKLIST_VIEW
      break
    default:
      capability = ''
      break
  }
  return capability === '' ? true : hasAnyCapabilities(capability)
}
