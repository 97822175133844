import { Component } from 'react'
import { ExtendRouteComponentProps } from '../../../types'
import { TableListReport, StylewherePage } from '../../../components'
import { getTableActionsReport, getHeaderActionsReport } from '../../../shared/utils'
import { __, T } from '../../../shared/i18n'

class WorkstationList extends Component<ExtendRouteComponentProps> {
  render() {
    const reportId = 'workstation'
    return (
      <StylewherePage {...this.props} noOverflow>
        <TableListReport
          {...this.props}
          reportId={reportId}
          title={__(T.menu.workstations)}
          reportActions={getTableActionsReport(reportId)}
          actions={getHeaderActionsReport(reportId, this.props.breadcrumbs)}
          disabledReportExport
        />
      </StylewherePage>
    )
  }
}

export default WorkstationList
