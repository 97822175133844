import { Component } from 'react'
import { StylewherePage, TableList } from '../../../../components'
import { ROLE_MAPPINGS_COLUMNS, ROLE_MAPPINGS_FILTERS } from '../../../../config/Pages/UsersRoles/RoleMappings'
import { __, T } from '../../../../shared/i18n'
import { AdvancedTableConfig } from '../../../../components/AdvancedTable'
import { ExtendRouteComponentProps } from '../../../../types'
import { CAPABILITIES, getActionsList } from '../../../../config/utility/capabilityUtility'
import RoleMappings from '../../../../api/RoleMappings'
import { getTabs } from '../../../../config/Pages/UsersRoles/Tabs'

const TABLE_CONFIG: AdvancedTableConfig = {
  columns: ROLE_MAPPINGS_COLUMNS,
  filterDefinitions: ROLE_MAPPINGS_FILTERS,
  manageColumnsPrefix: 'roleMappings',
  activeDefaultClassColumn: true,
}

class RoleMappingList extends Component<ExtendRouteComponentProps> {
  render() {
    return (
      <StylewherePage {...this.props} noOverflow>
        <TableList
          tabs={getTabs(4)}
          location={this.props.location}
          config={TABLE_CONFIG}
          resource={{
            call: RoleMappings.searchTableList,
            endpoint: RoleMappings.endpoint,
          }}
          actions={getActionsList(
            CAPABILITIES.RESOURCE_ROLE_MAPPING_EDIT,
            __(T.roleMapping.create_new),
            '/configuration/usersRoles/roleMappings/create/form'
          )}
        />
      </StylewherePage>
    )
  }
}

export default RoleMappingList
