import { Component } from 'react'
import { List } from 'antd'
import { Section } from '../../../../components'
import { __ } from '../../../../shared/i18n'
import { StwCapabilitiesGroup } from '../../../../api'

interface Props {
  capabilityGroups: StwCapabilitiesGroup[]
}

class CapabilityGroupListModal extends Component<Props> {
  render() {
    return (
      <Section title={`${__('titles.capabilitiesGroup')}`}>
        <div
          id="scrollableDiv"
          style={{
            height: 400,
            overflow: 'auto',
            padding: '0 16px',
          }}
        >
          <List
            split
            style={{ width: '100%', height: '1000' }}
            itemLayout="horizontal"
            dataSource={this.props.capabilityGroups}
            renderItem={(capabilityGroup, index) => (
              <List.Item key={index}>
                <List.Item.Meta title={capabilityGroup.code} description={capabilityGroup.description} />
              </List.Item>
            )}
          />
        </div>
      </Section>
    )
  }
}

export default CapabilityGroupListModal
