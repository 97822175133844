import BaseResource from './BaseResource'
import { StwFullInventory, StwInventory, StwInventoryCounters, StwInventoryMode, StwOperation, StwUser } from './types'
import Users from './Users'
import Operations from './Operations'
import { api, responseErrorCheck } from './api'

class Inventories extends BaseResource {
  static endpoint = '/api/v1/inventories' ///inventory

  static async getFull(inventoryId: string): Promise<StwFullInventory> {
    const inventory = await this.get<StwInventory>(inventoryId)
    const creationUser = inventory.creationUserId ? await Users.get<StwUser>(inventory.creationUserId) : undefined
    const closeUser = inventory.closeUserId ? await Users.get<StwUser>(inventory.closeUserId) : undefined
    const operation = inventory.operationId ? await Operations.get<StwOperation>(inventory.operationId) : undefined
    return {
      ...inventory,
      creationUser: creationUser,
      closeUser: closeUser,
      operation: operation,
      mode: Inventories.getInventoryMode(operation),
    }
  }

  private static isInventoryMode(retrieveExpectedMode: string | undefined): retrieveExpectedMode is StwInventoryMode {
    return (
      retrieveExpectedMode === 'NONE' ||
      retrieveExpectedMode === 'STW_STOCK' ||
      retrieveExpectedMode === 'EXTERNAL_BY_ITEM' ||
      retrieveExpectedMode === 'EXTERNAL_BY_PRODUCT'
    )
  }

  private static getInventoryMode(operation?: StwOperation): StwInventoryMode | undefined {
    const retrieveExpectedMode: string | undefined = Inventories.getRetrieveExpectedMode(operation)
    return Inventories.isInventoryMode(retrieveExpectedMode) ? retrieveExpectedMode : undefined
  }

  private static getRetrieveExpectedMode(operation?: StwOperation): string | undefined {
    return operation && operation.options ? operation.options.retrieveExpectedMode : undefined
  }

  static getCounters(inventoryId: string): Promise<StwInventoryCounters> {
    return api
      .get<StwInventoryCounters>(`/api/v1/operations/inventory/${inventoryId}/getCounters`)
      .then(responseErrorCheck)
  }
}

export default Inventories
