import { Component } from 'react'
import { StylewherePage, TableList } from '../../../../components'
import { __, T } from '../../../../shared/i18n'
import { AdvancedTableConfig } from '../../../../components/AdvancedTable'
import { ExtendRouteComponentProps } from '../../../../types'
import { CAPABILITIES, getActionsList } from '../../../../config/utility/capabilityUtility'
import EnumerationTypes from '../../../../api/EnumerationTypes'
import { TYPES_COLUMNS, TYPES_FILTERS } from '../../../../config/Pages/Settings/EnumerationTypes'

const ENUMERATION_TYPE_TABLE_CONFIG: AdvancedTableConfig = {
  columns: TYPES_COLUMNS,
  filterDefinitions: TYPES_FILTERS,
  filterParameters: {},
  manageColumnsPrefix: 'enumerationTypes',
  activeDefaultClassColumn: true,
}

class EnumerationTypesList extends Component<ExtendRouteComponentProps> {
  render() {
    return (
      <StylewherePage {...this.props} noOverflow>
        <TableList
          title={__(T.menu.enumerationTypes)}
          location={this.props.location}
          config={ENUMERATION_TYPE_TABLE_CONFIG}
          resource={{
            call: EnumerationTypes.searchTableList,
            endpoint: EnumerationTypes.endpoint,
          }}
          actions={getActionsList(
            CAPABILITIES.RESOURCE_ENUMERATION_TYPE_EDIT,
            __(T.enumeration.types.create_new),
            '/configuration/enumerations/create/form'
          )}
        />
      </StylewherePage>
    )
  }
}

export default EnumerationTypesList
