import { Component } from 'react'
import { Button, Checkbox, Col, Row, Typography } from 'antd'
import { SettingOutlined } from '@ant-design/icons'
import QueueAnim from 'rc-queue-anim'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { AdvancedTableColumns, AdvancedTableColumn } from '.'
import { T, __ } from '../../shared/i18n'

import DragIcon from '../../../icon/drag-manage-column.png'
import ColumnsStorage from '../../shared/ColumnsStorage'

interface Props {
  columns: AdvancedTableColumns
  baseColumns?: AdvancedTableColumns
  visibleManagerColumns: boolean
  managementColumns: (...args: any[]) => any
  refreshTableColumns?: (...args: any[]) => any
  customTop?: string
  manageColumnsPrefix?: string
  maxHeightPanel?: number
}

interface State {
  manageTableColumns: AdvancedTableColumn[]
  visible?: boolean
  storaged?: boolean
}

const { Title, Text } = Typography

export class AdvancedTableManagerColumns extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      manageTableColumns: [],
      storaged: false,
    }
  }

  shouldComponentUpdate = (nextProps) => {
    if (nextProps.visibleManagerColumns !== this.props.visibleManagerColumns) {
      this.getColumns(nextProps.visibleManagerColumns, nextProps.columns)
    }
    return true
  }

  hasLocalStorageColumns = async () => {
    const { manageColumnsPrefix } = this.props
    if (manageColumnsPrefix) {
      return ColumnsStorage.hasStorageColumns(manageColumnsPrefix)
    }
    return false
  }

  getStorageColumns = async (columns, storaged) => {
    const { manageColumnsPrefix } = this.props
    if (manageColumnsPrefix && storaged) {
      return ColumnsStorage.load(manageColumnsPrefix, columns)
    }
    //creo una copia come faceva il codice precedente
    return columns.map((col) => {
      return { ...col }
    })
  }

  getColumns = async (visible, cols) => {
    const storaged = await this.hasLocalStorageColumns()
    const storageCols = await this.getStorageColumns(cols, storaged)
    this.setState({
      visible: visible,
      manageTableColumns: storageCols,
      storaged: storaged,
    })
  }

  onChange = (e, index) => {
    const tmp = this.state.manageTableColumns
    tmp[index].hidden = !e.target.checked
    this.setState({
      manageTableColumns: tmp,
    })
  }

  apply = async () => {
    const { manageTableColumns } = this.state
    const { refreshTableColumns, manageColumnsPrefix } = this.props
    if (refreshTableColumns) {
      if (manageColumnsPrefix) {
        await ColumnsStorage.save(manageColumnsPrefix, manageTableColumns)
      }
      refreshTableColumns(manageTableColumns)
    }
  }

  reset = async () => {
    const { refreshTableColumns, manageColumnsPrefix, columns, baseColumns } = this.props
    if (refreshTableColumns) {
      if (manageColumnsPrefix) {
        await ColumnsStorage.remove(manageColumnsPrefix)
      }
      refreshTableColumns(baseColumns && Array.isArray(baseColumns) && baseColumns.length > 0 ? baseColumns : columns)
    }
  }

  reorder = (startIndex, endIndex) => {
    const { manageTableColumns } = this.state
    const result = Array.from(manageTableColumns)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  onDragEnd = (result) => {
    if (result.destination) {
      const col = this.reorder(result.source.index, result.destination.index)
      this.setState({
        manageTableColumns: col,
      })
    }
  }

  getItemStyle = (isDragging, draggableStyle) => {
    return {
      // some basic styles to make the items look a bit nicer
      userSelect: 'none',

      // change background colour if dragging
      background: isDragging ? '#F2F2F2' : 'white',

      // styles we need to apply on draggables
      ...draggableStyle,
    }
  }

  getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'white' : 'white',
  })

  render() {
    const { visible, manageTableColumns, storaged } = this.state
    const { managementColumns, maxHeightPanel, customTop } = this.props
    return (
      <QueueAnim type="right" duration={400}>
        {visible ? (
          <div
            key="management-columns-panel"
            style={{ top: customTop || '36px' }}
            className={`stylewhere-columns-manager-layer${visible ? ' visible' : ''}`}
          >
            <div className="stylewhere-columns-manager-layer-title">
              <Title level={5}>{__(T.misc.manage_columns)}</Title>
              {storaged && (
                <Button
                  icon={<SettingOutlined style={{ height: 14, marginRight: 3, color: '#333' }} />}
                  onClick={this.reset}
                  type="link"
                >
                  {__(T.misc.reset)}
                </Button>
              )}
            </div>
            {manageTableColumns && (
              <Row className="stylewhere-columns-manager-layer-content">
                <Col span={24}>
                  <Row
                    className="stylewhere-columns-manager-layer-content-overflow"
                    style={{ maxHeight: maxHeightPanel ? maxHeightPanel - 125 : 300 }}
                  >
                    <DragDropContext onDragEnd={this.onDragEnd}>
                      <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={this.getListStyle(snapshot.isDraggingOver)}
                          >
                            {manageTableColumns.map((col, i) => {
                              return !col.unmanageable ? (
                                <Draggable key={col.key} draggableId={col.key} index={i}>
                                  {(provided1, snapshot1) => (
                                    <div
                                      ref={provided1.innerRef}
                                      {...provided1.draggableProps}
                                      {...provided1.dragHandleProps}
                                      style={this.getItemStyle(snapshot1.isDragging, provided1.draggableProps.style)}
                                    >
                                      <Col span={24} key={col.key} className="stylewhere-columns-manager-layer-column">
                                        <div className="drag-icon" style={{ backgroundImage: `url(${DragIcon})` }} />
                                        <Checkbox checked={!col.hidden} onChange={(e) => this.onChange(e, i)}>
                                          <Text style={{ flex: 1 }}>{__(`fields.labels.${col.key}`, col.title)}</Text>
                                        </Checkbox>
                                      </Col>
                                    </div>
                                  )}
                                </Draggable>
                              ) : null
                            })}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Row>
                </Col>
                <Col span={24} className="stylewhere-columns-manager-layer-content-btn">
                  <Button className="stylewhere-button-default" onClick={() => managementColumns()}>
                    {__(T.misc.cancel)}
                  </Button>
                  <Button className="stylewhere-button-secondary" onClick={() => this.apply()}>
                    {__(T.misc.apply)}
                  </Button>
                </Col>
              </Row>
            )}
          </div>
        ) : (
          <div />
        )}
      </QueueAnim>
    )
  }
}
