import { Component } from 'react'
import { Col, Row } from 'antd'
import { EyeOutlined } from '@ant-design/icons'
import {
  AdvancedTable,
  GridData,
  KeyValueGrid,
  DynamicImage,
  AdvancedTableRowColumn,
  StylewherePage,
  DefaultHeader,
  NotFound,
  Section,
  TableList,
} from '../../../components'
import { DetailCell, StwFullItemEvent, StwItem, StwItemConfiguration } from '../../../api'
import Items from '../../../api/Items'
import { __, T } from '../../../shared/i18n'
import { BreadcrumbsFragmentType } from '../../../components/PageBread'
import {
  AdvancedTableColumn,
  AdvancedTableConfig,
  AdvancedTableContent,
  AdvancedTableContentKind,
  AdvancedTablePagination,
  AdvancedTableSortDirection,
} from '../../../components/AdvancedTable'
import { ExtendRouteComponentProps } from '../../../types'
import ItemEvents from '../../../api/ItemEvents'
import { DEFAULT_PAGE_SIZE, PRODUCT_IMAGE_DETAIL_SIZE } from '../../../constants'
import ItemConfigurations from '../../../api/ItemConfigurations'
import { getDetailDataWithAttributesMapping, getFragment, getMultipleTitle, getBackURL } from '../../../shared/utils'
import AppStore from '../../../shared/AppStore'

const ConfigurationDetailField: React.FC = (record: any) => {
  return (
    <>
      {(record.configurationDetails ?? []).map((c) => (
        <>
          <span>{`${c.identifierType} (${c.role})`}</span>
          <br />
        </>
      ))}
    </>
  )
}

const CONFIGURATION_ITEM_FIELDS: DetailCell[] = [
  { title: 'id', attribute: 'id', columns: 24 },
  { title: __('fields.labels.zone'), attribute: 'zone', type: 'codeDesc', columns: 12 },
  { title: __('fields.labels.place'), attribute: 'zone.place', type: 'codeDesc', columns: 12 },
  {
    title: __('fields.labels.configurationDetail'),
    render: ConfigurationDetailField,
    attribute: 'configurationDetail',
    columns: 12,
  },
  { title: __('fields.labels.itemType'), attribute: 'product.itemType.code', columns: 12 },
  { title: __('fields.labels.creationDate'), attribute: 'creationDate', type: 'date', columns: 12 },
  { title: __('fields.labels.lastModifyDate'), attribute: 'lastModifyDate', type: 'date', columns: 12 },
  { title: __('fields.labels.deleteDate'), attribute: 'deleteDate', type: 'date', columns: 12 },
]

const CONFIGURATION_PRODUCT_FIELDS: DetailCell[] = [
  { title: __('fields.labels.code'), attribute: 'code', columns: 12 },
  { title: __('fields.labels.description'), attribute: 'description', columns: 12 },
  { title: __('fields.labels.itemType'), attribute: 'itemType.code', columns: 12 },
  { title: __('fields.labels.color'), attribute: 'color.value', columns: 12 },
  { title: __('fields.labels.size'), attribute: 'size.value', columns: 12 },
  { title: __('fields.labels.material'), attribute: 'material.value', columns: 12 },
  { title: __('fields.labels.style'), attribute: 'style.value', columns: 12 },
  { title: __('fields.labels.season'), attribute: 'season', columns: 12 },
  { title: __('fields.labels.rfidEnabled'), attribute: 'rfidEnabled', columns: 12 },
  { title: __('fields.labels.drop'), attribute: 'drop', columns: 12 },
  { title: __('fields.labels.creationDate'), attribute: 'creationDate', type: 'date', columns: 12 },
  { title: __('fields.labels.lastModifyDate'), attribute: 'lastModifyDate', type: 'date', columns: 12 },
]

const ITEM_IDENTIFIER_COLUMNS: AdvancedTableColumn[] = [
  { title: 'type', dataIndex: 'type', key: 'type', sortable: false },
  { title: 'role', dataIndex: 'role', key: 'role', sortable: false },
  { title: 'code', dataIndex: 'code', key: 'code', sortable: false },
  {
    title: 'status',
    dataIndex: 'status',
    key: 'status',
    type: AdvancedTableContentKind.STATUS,
    sortable: false,
  },
]

interface State {
  item: StwItem | null
  fragment?: BreadcrumbsFragmentType[]
  loader: boolean
  detailData?: DetailCell[]
  productDetailData?: DetailCell[]
  tableContent: AdvancedTableContent<any>
  totalElements: number
  tablePagination: AdvancedTablePagination
  eventsTableConfig: AdvancedTableConfig
}

const defaultEventPagination = {
  pageNumber: 1,
  pageSize: DEFAULT_PAGE_SIZE,
  sortValues: { creationDate: AdvancedTableSortDirection.desc },
}

class ItemDetail extends Component<ExtendRouteComponentProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      item: null,
      loader: true,
      detailData: undefined,
      productDetailData: undefined,
      tableContent: [],
      totalElements: 0,
      tablePagination: {
        filterValues: undefined,
        pageSize: 100,
        sortValues: {},
        pageNumber: 1,
      },
      eventsTableConfig: {
        filterDefinitions: [],
      },
    }
  }

  getEventsColumn = (): AdvancedTableColumn[] => {
    return [
      {
        key: 'eventType',
        dataIndex: 'eventType',
        title: __('fields.labels.type'),
      },
      {
        key: 'user',
        dataIndex: 'user',
        title: __('fields.labels.user'),
        render: (text, record) => record.user.username,
      },
      {
        key: 'details',
        dataIndex: 'details',
        title: __('fields.labels.eventDetails'),
        className: 'stw-large',
        sortable: false,
        render: (text, record) => {
          return <>{this.getDetails(record)}</>
        },
      },
      {
        key: 'eventDate',
        dataIndex: 'eventDate',
        title: __('fields.labels.eventDate'),
        type: AdvancedTableContentKind.DATE_TIME,
      },
      {
        key: 'creationDate',
        dataIndex: 'creationDate',
        title: __('fields.labels.creationDate'),
        type: AdvancedTableContentKind.DATE_TIME,
      },
      {
        key: 'action',
        width: 'small',
        fixedType: 'right',
        className: 'stw-small',
        sortable: false,
        unmanageable: true,
        render: (text, record: StwFullItemEvent) => {
          return (
            <>
              {record.operationInstanceId && record.operationId && record.operationType && (
                <AdvancedTableRowColumn
                  actions={[
                    {
                      content: <EyeOutlined />,
                      type: 'default',
                      path: `/operationlogs/${record.operationType}/${record.operationId}/${record.operationInstanceId}`,
                    },
                  ]}
                />
              )}
            </>
          )
        },
      },
    ]
  }

  componentDidMount() {
    this.initialize()
  }

  initialize = async () => {
    const { match } = this.props
    let item
    const dataProduct: DetailCell[] = await getDetailDataWithAttributesMapping(
      CONFIGURATION_PRODUCT_FIELDS,
      'product',
      2,
      'valueDesc',
      12
    )
    const data: DetailCell[] = await getDetailDataWithAttributesMapping(CONFIGURATION_ITEM_FIELDS, 'item', 3)
    if (match && match.params && match.params.itemId) {
      item = await Items.get<StwItem>(match.params.itemId).then((res) => res)
    }

    const fragments: BreadcrumbsFragmentType[] = []
    if (match.params.operationType && match.params.operationId && match.params.operationInstanceId) {
      fragments.push(
        getFragment(
          match.params.operationInstanceId,
          false,
          `/operationlogs/${match.params.operationType}/${match.params.operationId}/${match.params.operationInstanceId}`
        )
      )
      fragments.push(getFragment(__(T.report.item), false, '/reports/item'))
    } else if (match.params.reportType && match.params.reportId) {
      fragments.push(
        getFragment(match.params.reportId, false, `/reports/${match.params.reportType}/${match.params.reportId}`)
      )
    }
    fragments.push(getFragment((item && item.id) || '', true, undefined))

    this.setState({
      tableContent: (item && item.identifiers) || [],
      totalElements: (item && item.identifiers && item.identifiers.length) || 0,
      item: item,
      detailData: data,
      productDetailData: dataProduct,
      fragment: fragments,
      loader: false,
      eventsTableConfig: {
        columns: this.getEventsColumn(),
        filterDefinitions: [],
        filterParameters: {},
        activeDefaultClassColumn: true,
        fixedTableHeight: true,
        disableLocation: true,
        disableColumnFilters: true,
        disabledNoDataFound: true,
        skeletonOptions: {},
        hiddenEmptyTable: true,
      },
    })
  }

  getSimpleDetails = (object: any, key: string, label = '') => {
    return object && object[key] ? (
      <div>
        {__(`fields.labels.${label !== '' ? label : key}`)}: {object[key]}
      </div>
    ) : null
  }

  getDetails = (event: StwFullItemEvent) => {
    let details
    switch (event.eventType) {
      case 'ITEM_OPERATION_COMPLETED': {
        if (event.attributes) {
          switch (event.operationType) {
            case 'PACKING': {
              details = (
                <>
                  {this.getSimpleDetails(event, 'operationLabel')}
                  {this.getSimpleDetails(event.attributes, 'parcelCode')}
                </>
              )
              break
            }
            case 'OUTBOUND': {
              details = (
                <>
                  {this.getSimpleDetails(event, 'operationLabel')}
                  {this.getSimpleDetails(event.attributes, 'parcelCode')}
                  {this.getSimpleDetails(event.attributes, 'asnCode')}
                  {this.getSimpleDetails(event.attributes, 'destinationPlace')}
                </>
              )
              break
            }
            case 'INBOUND': {
              details = (
                <>
                  {this.getSimpleDetails(event, 'operationLabel')}
                  {this.getSimpleDetails(event.attributes, 'parcelCode')}
                  {this.getSimpleDetails(event.attributes, 'asnCode')}
                  {this.getSimpleDetails(event.attributes, 'destinationPlace')}
                </>
              )
              break
            }
            case 'SIMPLE_ITEM_LIST': {
              details = (
                <>
                  {this.getSimpleDetails(event, 'operationLabel')}
                  {this.getSimpleDetails(event.attributes, 'operationPlace')}
                </>
              )
              break
            }
            case 'PICKING': {
              details = (
                <>
                  {this.getSimpleDetails(event, 'operationLabel')}
                  {this.getSimpleDetails(event.attributes, 'operationPlace')}
                  {this.getSimpleDetails(event.attributes, 'description')}
                </>
              )
              break
            }
            case 'SORTING': {
              details = (
                <>
                  {this.getSimpleDetails(event, 'operationLabel')}
                  {this.getSimpleDetails(event.attributes, 'code')}
                  {this.getSimpleDetails(event.attributes, 'operationPlace')}
                </>
              )
              break
            }
          }
        }
        break
      }
      case 'ITEM_ATTRIBUTE_DELETE':
        details = (
          <>
            {this.getSimpleDetails(event, 'operationLabel')}
            {event.keyItemAttributes && (
              <div>
                {/*{__('fields.labels.attributes')}:{' '}*/}
                {event.keyItemAttributes.map((attribute) => (
                  <span>{attribute}, </span>
                ))}
              </div>
            )}
          </>
        )
        break
      case 'ITEM_ATTRIBUTE_UPDATE':
        details = (
          <>
            {event.itemAttributes && Object.keys(event.itemAttributes).length > 0 && (
              <div>
                {this.getSimpleDetails(event, 'operationLabel')}
                {Object.keys(event.itemAttributes).map((key) => (
                  <div>
                    {key} {event.itemAttributes && event.itemAttributes[key] && <>- {event.itemAttributes[key]}</>}
                  </div>
                ))}
              </div>
            )}
          </>
        )
        break
      case 'ITEM_CONFIGURATION_REWRITED':
        details = (
          <>
            {event.itemConfigurationDetails && (
              <div>
                {event.itemConfigurationDetails
                  .sort((d1, d2) => (d1.identifierType! > d2.identifierType! ? 1 : -1))
                  .map((detail) => (
                    <div>
                      <span>
                        {__('fields.labels.role')}: {detail.role}
                      </span>
                      <span>
                        {__('fields.labels.identifierType')}: {detail.identifierType}
                      </span>
                    </div>
                  ))}
                <div>
                  {__('fields.labels.identifiers')}:{' '}
                  {event
                    .itemIdentifiers!.sort((d1, d2) => (d1.type! > d2.type! ? 1 : -1))
                    .map((i) => i.code)
                    .join(', ')}
                </div>
                <div>
                  {__('fields.labels.itemType')}: {event.itemType}
                </div>
              </div>
            )}
          </>
        )
        break
      case 'ITEM_CREATED':
        details = (
          <>
            {this.getSimpleDetails(event, 'operationLabel')}
            {this.getSimpleDetails(event, 'itemType')}
            {this.getSimpleDetails(event.zone, 'code', 'zone')}
            {this.getSimpleDetails(event.itemProduct, 'code', 'product')}
            {event.itemAttributes && Object.keys(event.itemAttributes).length > 0 && (
              <div>
                {__('fields.labels.attributes.title')}:{' '}
                {Object.keys(event.itemAttributes).map((key) => (
                  <div>
                    {key} {event.itemAttributes && event.itemAttributes[key] && <>- {event.itemAttributes[key]}</>}
                  </div>
                ))}
              </div>
            )}
          </>
        )
        break
      case 'ITEM_PRODUCT_UPDATED':
        details = event.itemProduct && (
          <>
            {this.getSimpleDetails(event, 'operationLabel')}
            {this.getSimpleDetails(event.itemProduct, 'code', 'product')}
          </>
        )
        break
      case 'ITEM_TRANSFERRED':
        details = (
          <>
            {this.getSimpleDetails(event, 'operationLabel')}
            {this.getSimpleDetails(event.destinationZone, 'code', 'destinationZone')}
          </>
        )
        break
    }
    return details
  }

  getItemConfigurationDetail = async (itemConfigurationId: string) => {
    const configuration = await ItemConfigurations.get<StwItemConfiguration>(itemConfigurationId)
    return (
      <div>
        <>
          {configuration.code}
          {configuration.description && <>- {configuration.description}</>}
        </>
      </div>
    )
  }

  render() {
    const {
      item,
      loader,
      fragment,
      totalElements,
      detailData,
      productDetailData,
      tableContent,
      tablePagination,
      eventsTableConfig,
    } = this.state
    const { breadcrumbs, queryString } = this.props
    const ENABLED_PRODUCT_IMAGE = AppStore.getShowProductImage() && item
    return (
      <StylewherePage {...this.props} noOverflow fragment={fragment}>
        <DefaultHeader
          backPath={!loader ? getBackURL(queryString, breadcrumbs) : ''}
          multipleTitle={getMultipleTitle(item)}
          title={__(T.misc.not_found)}
          skeleton={{ active: loader, options: { multiple: true } }}
        />
        <Section customClass={`stw-section-page paged-header ${item || loader ? 'scroll transparent' : ''}`}>
          {item || loader ? (
            <>
              <GridData
                fields={detailData || CONFIGURATION_ITEM_FIELDS}
                record={loader ? [] : item}
                skeleton={loader}
              />
              <Section>
                <AdvancedTable
                  loader={loader}
                  tableTitle={{ title: __(T.misc.identifiers_title), emptyTitle: __(T.item.noIdentifiersFoundTitle) }}
                  pagination={tablePagination}
                  columns={ITEM_IDENTIFIER_COLUMNS}
                  filters={{
                    filterDefinitions: [],
                  }}
                  content={tableContent}
                  totalElements={totalElements}
                  disabledNoDataFound
                  disabledPagination
                  activeDefaultClassColumn
                />
              </Section>
              <Section title={__('misc.products_title')}>
                <Row style={{ marginTop: 20 }}>
                  <Col span={ENABLED_PRODUCT_IMAGE ? 16 : 24}>
                    <KeyValueGrid
                      data={loader || !item ? [] : item.product}
                      fields={productDetailData || CONFIGURATION_PRODUCT_FIELDS}
                      skeleton={loader}
                    />
                  </Col>
                  {ENABLED_PRODUCT_IMAGE && (
                    <Col span={8} style={{ paddingLeft: 20 }}>
                      <DynamicImage
                        record={item ? item.product : undefined}
                        size={PRODUCT_IMAGE_DETAIL_SIZE}
                        skeleton={loader}
                      />
                    </Col>
                  )}
                </Row>
              </Section>
              {item && (
                <TableList
                  title={__(T.misc.itemEvents_title)}
                  titleEmpty={__(T.item.noEventsFoundTitle)}
                  location={this.props.location}
                  defaultPagination={defaultEventPagination}
                  headerType="boxed"
                  config={eventsTableConfig}
                  resource={{
                    call: ItemEvents.getItemEvents,
                    endpoint: `${ItemEvents.endpoint}/${item.id}`,
                  }}
                />
              )}
            </>
          ) : (
            <NotFound fullheight title={__(T.misc.noRecordFoundTitle)} />
          )}
        </Section>
      </StylewherePage>
    )
  }
}

export default ItemDetail
