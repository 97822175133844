import { Component } from 'react'
import { Col, Row } from 'antd'
import { SectionTitle } from './SectionTitle'
import { AdvancedFormSelectField } from './AdvancedFormSelectField'
import { AdvancedFormInputType } from './advancedFormTypes'
import { __ } from '../../shared/i18n'
import { AdvancedFormInputField, AdvancedFormInputNumberField } from '..'

interface Props {
  keyPrefix: string
  title?: string
  protocols: ReadonlyArray<{ id: string; description: string }>
  handleChange: (key, value) => void
  url?: URL
  rules?: any[]
}

interface State {
  protocol?: string
  hostname?: string
  port?: string
}

export class AdvancedFormUrlEditorField extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = props.url
      ? {
          protocol: props.url.protocol,
          hostname: props.url.hostname,
          port: props.url.port,
        }
      : {}
  }

  componentDidMount() {
    const { url } = this.props
    this.setValues(url)
  }

  setValues = (url) => {
    this.setState({
      protocol: url ? url.protocol : '',
      hostname: url ? url.hostname : '',
      port: url ? url.port : '',
    })
  }

  shouldComponentUpdate = (nextProps) => {
    const { protocol, hostname, port } = this.state
    if (
      nextProps.url &&
      (nextProps.url.protocol !== protocol ||
        nextProps.url.hostname !== hostname ||
        (port && parseInt(nextProps.url.port, 10) !== parseInt(port, 10)))
    ) {
      this.setValues(nextProps.url)
    }
    return true
  }

  render() {
    const { keyPrefix, title, protocols, handleChange, rules } = this.props
    const { protocol, hostname, port } = this.state
    return (
      <Row gutter={24} style={{ marginTop: 10 }}>
        <Col span={24}>
          <SectionTitle title={title} marginBottom={20} />
        </Col>
        <Col xxl={12} xl={12} lg={14} md={14} xs={24}>
          <Row gutter={24}>
            <Col xs={24} md={8} lg={8}>
              <AdvancedFormSelectField
                item={{
                  key: `${keyPrefix}.protocol`,
                  type: AdvancedFormInputType.SELECT,
                  label: __('fields.labels.protocol'),
                  option: 'protocols',
                  rules: rules,
                  col: 24,
                }}
                record={protocol}
                handleChange={(value) => handleChange(`${keyPrefix}.protocol`, value)}
                options={protocols}
                readonly={false}
              />
            </Col>
            <Col xs={24} md={10} lg={10} style={{ paddingLeft: 0, paddingRight: 0 }}>
              <AdvancedFormInputField
                record={{ hostname }}
                item={{
                  key: `${keyPrefix}.hostname`,
                  type: AdvancedFormInputType.TEXT,
                  label: __('fields.labels.hostname'),
                  rules: rules,
                  col: 24,
                }}
                handleChange={(event) => handleChange(`${keyPrefix}.hostname`, event.target.value)}
                readonly={false}
              />
            </Col>
            <Col xs={24} md={6} lg={6}>
              <AdvancedFormInputNumberField
                record={port}
                rules={rules}
                item={{
                  key: `${keyPrefix}.port`,
                  type: AdvancedFormInputType.NUMBER,
                  label: __('fields.labels.port'),
                  col: 24,
                }}
                handleChange={(value) => handleChange(`${keyPrefix}.port`, value)}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}
