import {
  AdvancedTableColumn,
  AdvancedTableFilterKind,
  AdvancedTableFiltersDef,
} from '../../../components/AdvancedTable'
import {
  AdvancedFormAttributeType,
  AdvancedFormField,
  AdvancedFormFieldBuilder,
  AdvancedFormInputType,
} from '../../../components/AdvancedForm/advancedFormTypes'
import { __ } from '../../../shared/i18n'
import { DetailCell } from '../../../api'

export const USERS_FILTERS: AdvancedTableFiltersDef = [
  {
    key: 'equalUsernames',
    label: __('fields.labels.username'),
    type: AdvancedTableFilterKind.STRING_LIST,
    column: 'username',
    col: 8,
  },
  {
    key: 'email',
    label: __('fields.labels.email'),
    type: AdvancedTableFilterKind.STRING,
    column: 'email',
    col: 8,
  },
  {
    key: 'name',
    label: __('fields.labels.name'),
    type: AdvancedTableFilterKind.STRING,
    column: 'name',
    col: 8,
  },
  {
    key: 'surname',
    label: __('fields.labels.surname'),
    type: AdvancedTableFilterKind.STRING,
    column: 'surname',
    col: 8,
  },
  {
    key: 'placeIds',
    label: __('fields.labels.userPlaces'),
    type: AdvancedTableFilterKind.AUTOCOMPLETE,
    column: 'userPlaces',
    autocomplete: {
      endpoint: '/api/v1/places',
      sort: 'code,asc',
      multiple: true,
    },
    col: 8,
  },
  {
    key: 'creationDate',
    label: __('fields.labels.creationDate'),
    type: AdvancedTableFilterKind.DATE_RANGE,
    column: 'creationDate',
    col: 8,
  },
  {
    key: 'lastModifyDate',
    label: __('fields.labels.lastModifyDate'),
    type: AdvancedTableFilterKind.DATE_RANGE,
    column: 'lastModifyDate',
    col: 8,
  },
]

export const USER_PLACES_COLUMNS: AdvancedTableColumn[] = [
  {
    key: 'code',
  },
  {
    key: 'type',
  },
  {
    key: 'description',
  },
  {
    key: 'address',
  },
  {
    key: 'latitude',
  },
  {
    key: 'longitude',
  },
]

export const USERS_FIELDS: AdvancedFormField[] = [
  {
    key: 'username',
    type: AdvancedFormInputType.TEXT,
    // rules: [{ required: true }],
    readonly: (record) => record.id !== undefined,
  },
  {
    key: 'email',
    type: AdvancedFormInputType.TEXT,
    // rules: [{ required: true }],
    readonly: (record) => record.id !== undefined,
  },
  {
    key: 'name',
    type: AdvancedFormInputType.TEXT,
    // rules: [{ required: true }],
    readonly: (record) => record.id !== undefined,
  },
  {
    key: 'surname',
    type: AdvancedFormInputType.TEXT,
    // rules: [{ required: true }],
    readonly: (record) => record.id !== undefined,
  },
  {
    key: 'userPlaces',
    type: AdvancedFormInputType.OBJECT_LIST_WITH_CARDS,
    col: 24,
    placeholder: __('misc.select'),
    autocomplete: {
      endpoint: '/api/v1/places',
      sort: 'code,asc',
    },
    uniqueValueKey: 'id',
    rules: [{ required: false }],
  },
  AdvancedFormFieldBuilder.buildAttribute('attributes', AdvancedFormAttributeType.SIMPLE, false, 'user'),
]

export const USERS_DATA: DetailCell[] = [
  { title: 'id', attribute: 'id', columns: 24 },
  { title: __('fields.labels.username'), attribute: 'username', columns: 12 },
  { title: __('fields.labels.email'), attribute: 'email', columns: 12 },
  { title: __('fields.labels.name'), attribute: 'name', columns: 12 },
  { title: __('fields.labels.surname'), attribute: 'surname', columns: 12 },
  { title: __('fields.labels.creationDate'), attribute: 'creationDate', type: 'date', columns: 12 },
  { title: __('fields.labels.lastModifyDate'), attribute: 'lastModifyDate', type: 'date', columns: 12 },
]
