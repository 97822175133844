import { Component } from 'react'
import { Button, Badge } from 'antd'

import IconSearchBlack from '../../../../icon/icon-search-black.png'
import IconSearch from '../../../../icon/icon-search.png'

interface Props {
  active?: boolean
  filterOpened?: boolean
  filterApplied?: number
  managementFilters: () => void
  customStyle?: any
}

export class TableHeaderFilterButton extends Component<Props> {
  render() {
    const { filterApplied, filterOpened, managementFilters, active, customStyle } = this.props
    return active ? (
      <Button
        style={customStyle}
        onClick={() => managementFilters()}
        className={filterOpened ? 'stylewhere-button-secondary btn-filter' : 'stylewhere-button-default btn-filter'}
      >
        {filterApplied && filterApplied > 0 ? (
          <Badge
            size="default"
            style={{ backgroundColor: '#1890ff', fontSize: 11, fontWeight: 700 }}
            count={filterApplied}
          >
            <div style={{ backgroundImage: `url(${filterOpened ? IconSearch : IconSearchBlack})` }} />
          </Badge>
        ) : (
          <div style={{ backgroundImage: `url(${filterOpened ? IconSearch : IconSearchBlack})` }} />
        )}
      </Button>
    ) : null
  }
}
