/* eslint-disable func-names */
import { Component } from 'react'
import { Form, Select } from 'antd'
import { __ } from '../../shared/i18n'
import { AdvancedFormField } from './advancedFormTypes'

interface Props {
  record: any
  item: AdvancedFormField
  handleChange: any
  options: any | null
  readonly: boolean
  labelHidden?: boolean
  help?: any
  withCustomSelection?: boolean
  maxTagCount?: number
  separator?: string
}

const { Option } = Select

export class AdvancedFormSelectField extends Component<Props> {
  render() {
    const { item, handleChange, options, readonly, labelHidden, help, withCustomSelection, maxTagCount, separator } =
      this.props
    return (
      <>
        <Form.Item
          name={item.key.split('.')}
          className="stylewhere-form-item"
          rules={item.rules}
          help={help || null}
          label={labelHidden ? null : __(`fields.labels.${item.key}`, item.label)}
        >
          <Select
            showSearch
            allowClear
            disabled={readonly}
            style={{ width: '100%' }}
            placeholder={item.placeholder ? item.placeholder : __(`fields.placeholders.${item.key}`)}
            onChange={handleChange}
            mode={withCustomSelection ? 'tags' : undefined}
            filterSort={AdvancedFormSelectField.compare}
            maxTagCount={maxTagCount}
            tokenSeparators={separator !== undefined ? [separator] : undefined}
          >
            {options &&
              options.map((option: any, p) => (
                <Option key={`option${p}`} value={option.id}>
                  {option.description || option.code}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </>
    )
  }

  private static compare(optionA, optionB): number {
    return optionA.value.toLowerCase().localeCompare(optionB.value.toLowerCase())
  }
}
