import { Component } from 'react'
import { Row, Menu, Typography, Dropdown, Tag } from 'antd'
import { __, T } from '../../shared/i18n'
import AppStore from '../../shared/AppStore'
import { sleep } from '../../shared/utils'

interface Props {
  managerUserDataModal: any
  managerApplicationInfoModal: any
  logout: any
  collapsed: boolean
  userData: any
}

const { Text } = Typography

export class SidebarUser extends Component<Props> {
  enabledLanguage = true

  getStationURL = () => {
    let url
    if (window.location && window.location.origin && window.location.hostname !== 'localhost') {
      url = window.location.origin
      url = url.replace('controlpanel', 'station')
    }
    return url
  }

  openRfidStation = () => {
    window.open(this.getStationURL(), '_blank')
  }

  changeLanguage = () => {
    AppStore.changeLanguage(AppStore.language === 'it' ? 'en' : 'it')
    sleep(100).then(() => {
      //Router.refresh()
      window.location.reload()
    })
  }

  getUserMenu = () => {
    const { managerUserDataModal, managerApplicationInfoModal, logout } = this.props
    const station = this.getStationURL()
    return (
      <Menu>
        {station && (
          <Menu.Item key="station-link" className="user-menu-item" onClick={this.openRfidStation} title={null}>
            <div className="user-menu-icon station" />
            <Text className="user-menu-text">{__(T.misc.stationLink)}</Text>
          </Menu.Item>
        )}
        {this.enabledLanguage && (
          <Menu.Item key="language" className="user-menu-item" onClick={this.changeLanguage} title={null}>
            <div className="user-menu-icon language" />
            <Text className="user-menu-text">{AppStore.language === 'it' ? __(T.misc.en) : __(T.misc.it)}</Text>
          </Menu.Item>
        )}
        <Menu.Item key="profile" className="user-menu-item" onClick={managerUserDataModal} title={null}>
          <div className="user-menu-icon profile" />
          <Text className="user-menu-text">{__(T.misc.userInfo)}</Text>
        </Menu.Item>
        <Menu.Item key="application" className="user-menu-item" onClick={managerApplicationInfoModal} title={null}>
          <div className="user-menu-icon application" />
          <Text className="user-menu-text">{__(T.misc.applicationInfo)}</Text>
        </Menu.Item>
        <Menu.Item key="logout" className="user-menu-item" onClick={logout} title={null}>
          <div className="user-menu-icon logout" />
          <Text className="user-menu-text">{__(T.misc.logout)}</Text>
        </Menu.Item>
      </Menu>
    )
  }

  render() {
    const { collapsed, userData } = this.props
    const userMenu = this.getUserMenu()
    return (
      <Row className="stw-sider-user-box">
        <Dropdown
          overlayClassName="stw-dropdown-user"
          trigger={['click']}
          overlay={userMenu}
          placement={collapsed ? 'topRight' : 'top'}
        >
          <div className="stw-sider-user">
            <div className="stw-sider-user-tag">
              <Tag>{userData.sigla}</Tag>
            </div>
            <div className="stw-sider-user-info">
              <div>
                <Text strong>{userData.complete}</Text>
                {userData.email !== null && <Text>{userData.email}</Text>}
              </div>
            </div>
          </div>
        </Dropdown>
      </Row>
    )
  }
}
