import { Component, createRef } from 'react'
import { Form, FormInstance } from 'antd'
import QueueAnim from 'rc-queue-anim'
import { AdvancedForm } from './AdvancedForm'
import {
  AdvancedTableColumns,
  AdvancedTableFiltersDef,
  AdvancedTableFilter,
  advancedTableFilterDefinitionsToAdvancedFormFields,
  AdvancedTableFilterValues,
  AdvancedTablePagination,
} from './AdvancedTable'
import { AdvancedFormButton } from './AdvancedForm/advancedFormTypes'
import { AdvancedTableFilterValuesButtons } from './AdvancedTable/AdvancedTableFilterValuesButtons'
import { AttributeUtil } from '../config/utility/utility'
import { T, __ } from '../shared/i18n'
import ColumnsStorage from '../shared/ColumnsStorage'

interface Props {
  managementFilters: (...args: any[]) => any
  managementColumns?: (...args: any[]) => any
  visibleManagerColumns?: boolean
  columns?: AdvancedTableColumns
  refreshTableColumns?: (...args: any[]) => any
  filters?: AdvancedTableFiltersDef
  onFiltersChanged?: (filterKey: string, filterValue: any) => void
  onFiltersApplied?: (filterPanelValues: AdvancedTableFilterValues) => void
  pagination?: AdvancedTablePagination
  filterApplied?: number
  manageColumnsPrefix?: string
  filterPanelOpened?: (status) => void
  filterOpened?: boolean
  maxHeightPanel: number
  filterParameters?: any
  disabledMaxHeightPanel?: boolean
}

interface State {
  visibleFilters: boolean
  filterPanelValues?: AdvancedTableFilterValues
  defFilters: any
}

export class Filters extends Component<Props, State> {
  formRefFilters = createRef<FormInstance>()

  wrapperRef
  keyboardEvents: any[] = []
  timerKeyboardEvents

  constructor(props) {
    super(props)
    this.state = {
      visibleFilters: false,
      filterPanelValues: {},
      defFilters: [],
    }
    this.wrapperRef = createRef<HTMLDivElement>()
  }

  componentDidMount() {
    document.addEventListener('keyup', this.handleKeyboardClick, false)
    document.addEventListener('click', this.handleClickOutside, false)
    this.updateFilters()
  }

  getPaginationValues = (values) => {
    const tmp: AdvancedTableFilterValues = {}
    const keys = Object.keys(values)
    for (let k = 0; k < keys.length; k++) {
      tmp[keys[k]] = values[keys[k]]
    }
    return tmp
  }

  shouldComponentUpdate = (nextProps) => {
    const { filterPanelValues, visibleFilters } = this.state
    if (
      nextProps.pagination &&
      nextProps.pagination.filterValues &&
      JSON.stringify(nextProps.pagination.filterValues) !== JSON.stringify(filterPanelValues)
    ) {
      this.setState({
        filterPanelValues: this.getPaginationValues(nextProps.pagination.filterValues || {}),
      })
    }
    if (visibleFilters !== nextProps.filterOpened) {
      if (nextProps.filterOpened) {
        this.setState({ visibleFilters: nextProps.filterOpened }, this.timerFocusFirstFilter)
      } else {
        setTimeout(() => {
          this.setState({ visibleFilters: nextProps.filterOpened })
        }, 200)
      }
    } else if (nextProps.filterOpened) {
      this.updateFilters()
    }
    return true
  }

  timerFocusFirstFilter = () => {
    setTimeout(() => {
      this.focusFirstFilter()
    }, 200)
  }

  focusFirstFilter = () => {
    //focus on first filter
    const { defFilters } = this.state
    if (defFilters && Array.isArray(defFilters) && defFilters.length > 0) {
      const firstKey = defFilters[0].key
      const selector = document.getElementById(firstKey)
      if (selector) selector.focus()
    }
  }

  updateFilters = async () => {
    const { defFilters } = this.state
    const filters = await this.getFilters()
    if (JSON.stringify(defFilters) !== JSON.stringify(filters)) {
      this.setState({ defFilters: filters })
    }
  }

  detectClickRangePicker = (event) => {
    return (
      event.target.localName === 'th' ||
      event.target.localName === 'span' ||
      event.target.className.indexOf('ant-picker-header-view') !== -1 ||
      event.target.className.indexOf('ant-picker-cell') !== -1 ||
      event.target.className.indexOf('ant-picker-super-prev-icon') !== -1 ||
      event.target.className.indexOf('ant-picker-header-super-prev-btn') !== -1 ||
      event.target.className.indexOf('ant-picker-header-prev-btn') !== -1 ||
      event.target.className.indexOf('ant-picker-prev-icon') !== -1 ||
      event.target.className.indexOf('ant-picker-next-icon') !== -1 ||
      event.target.className.indexOf('ant-picker-header-super-next-btn') !== -1 ||
      event.target.className.indexOf('ant-picker-super-next-icon') !== -1 ||
      event.target.className.indexOf('ant-picker-body') !== -1 ||
      event.target.className.indexOf('ant-picker-time-panel-cell-inner') !== -1 ||
      event.target.className.indexOf('ant-picker-time-panel-column') !== -1 ||
      event.target.className.indexOf('ant-picker-ranges') !== -1 ||
      event.target.className.indexOf('ant-btn-sm') !== -1 ||
      event.target.className.indexOf('ant-picker-header') !== -1 ||
      event.target.className.indexOf('ant-picker-year-btn') !== -1 ||
      event.target.className.indexOf('ant-picker-month-btn') !== -1
    )
  }

  handleClickOutside = (event) => {
    const { visibleFilters } = this.state
    if (
      visibleFilters &&
      this.wrapperRef &&
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target) &&
      event.target.className.indexOf?.('ant-select-item-option') === -1 &&
      event.target.parentNode.className.indexOf('btn-filter') === -1 &&
      event.target.parentNode.className.indexOf('ant-space-item') === -1 &&
      event.target.parentNode.className.indexOf('ant-badge') === -1 &&
      !this.detectClickRangePicker(event)
    ) {
      this.props.managementFilters()
    }
  }

  timeoutKeyboardEvents = () => {
    if (this.timerKeyboardEvents) {
      clearTimeout(this.timerKeyboardEvents)
      this.timerKeyboardEvents = undefined
    }
    this.timerKeyboardEvents = setTimeout(() => {
      this.keyboardEvents = []
    }, 500)
  }

  handleKeyboardClick = (event) => {
    const { filters, managementFilters } = this.props
    const { visibleFilters } = this.state
    if (!visibleFilters && filters) {
      //ctrl+z+x open filter panel automatically
      if (event.keyCode === 90 || event.keyCode === 17 || event.keyCode === 88) {
        const index = this.keyboardEvents.findIndex((e) => e.code === event.keyCode)
        if (index === -1) {
          this.timeoutKeyboardEvents()
          this.keyboardEvents.push({ code: event.keyCode })
          if (this.keyboardEvents.length === 3) {
            managementFilters()
            this.keyboardEvents = []
          }
        }
      }
    }
  }

  managerColumnsPanel = () => {
    const { visibleFilters } = this.state
    const { filterPanelOpened } = this.props
    if (visibleFilters && filterPanelOpened) {
      filterPanelOpened(!visibleFilters)
    }
    this.callManagementColumns()
  }

  callManagementColumns = () => {
    const { managementColumns } = this.props
    managementColumns?.()
  }

  onFilterPanelChanged = (filterKey: string, filterValue: any) => {
    const { filterPanelValues } = this.state
    AttributeUtil.setAttribute(filterPanelValues, filterKey, filterValue)
    this.setState({ filterPanelValues: filterPanelValues })
  }

  directApplyFilters = () => {
    if (this.formRefFilters && this.formRefFilters.current) {
      this.formRefFilters.current.submit()
    }
  }

  onSubmitApplyFilter = (filters) => {
    this.setState({ filterPanelValues: filters })
    this.applyFilters(filters)
  }

  applyFilters = (filters) => {
    const { onFiltersApplied, filterPanelOpened } = this.props
    if (filterPanelOpened) {
      filterPanelOpened(false)
    }
    onFiltersApplied?.(filters || [])
  }

  clearForm = () => {
    this.props.managementFilters()
  }

  getStorageColumns = async (columns) => {
    const { manageColumnsPrefix } = this.props
    if (manageColumnsPrefix) {
      return ColumnsStorage.load(manageColumnsPrefix, columns)
    }
    return columns
  }

  getFilters = async () => {
    const { columns, filters } = this.props
    const storageCols = await this.getStorageColumns(columns)
    const filterDefinitions = filters || []
    if (filterDefinitions.length === 0) {
      return []
    }
    let filtersList: AdvancedTableFilter[] = []
    const defHidden: AdvancedTableFilter[] = []
    const notFoundFilters: AdvancedTableFilter[] = []

    //creo lista di filtri seguendo l'ordinamento delle cols
    storageCols.forEach((stCol) => {
      const col = filterDefinitions.find((filter) => filter.column === stCol.key)
      if (col) {
        if (!stCol.hidden) {
          filtersList.push(col)
        } else {
          defHidden.push(col)
        }
      }
    })

    //aggiungo i filtri per cui non esiste la col
    filterDefinitions.forEach((filter) => {
      const columnFound = storageCols.find((col) => filter.column === col.key)
      if (!columnFound) {
        notFoundFilters.push(filter)
      }
    })

    filtersList = filtersList.concat(notFoundFilters)
    return filtersList
  }

  render() {
    const { filters, maxHeightPanel, filterParameters, filterApplied, disabledMaxHeightPanel } = this.props
    const { visibleFilters, filterPanelValues, defFilters } = this.state
    const btnApply: AdvancedFormButton = {
      label: __(T.misc.apply),
      type: 'submit',
      primary: true,
    }

    const btnClear: AdvancedFormButton = {
      label: __(T.misc.close),
      type: 'reset',
      primary: false,
    }
    return (
      <>
        <QueueAnim ref={this.wrapperRef} type="bottom" duration={400}>
          {visibleFilters && defFilters.length > 0 ? (
            <div
              key="filter-panel"
              style={{
                display: visibleFilters ? 'block' : 'none',
                maxHeight: !disabledMaxHeightPanel ? maxHeightPanel : 'unset',
              }}
              className="stylewhere-filters-form absolute"
            >
              <Form
                ref={this.formRefFilters}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                layout="vertical"
                onFinish={(values: AdvancedTableFilterValues) => this.onSubmitApplyFilter(values)}
                onReset={() => this.clearForm()}
                initialValues={filterPanelValues}
              >
                <AdvancedForm
                  fields={advancedTableFilterDefinitionsToAdvancedFormFields(defFilters)}
                  record={filterPanelValues}
                  parameters={filterParameters || {}}
                  handleChange={(key, value) => this.onFilterPanelChanged(key, value)} // useless
                  handleApplyFilters={this.directApplyFilters}
                  buttonActions={[btnClear, btnApply]}
                  width="100%"
                />
              </Form>
            </div>
          ) : (
            <div />
          )}
        </QueueAnim>
        {filters && (
          <AdvancedTableFilterValuesButtons
            filterDefinitions={filters}
            filterValues={filterPanelValues || []}
            filterApplied={filterApplied || 0}
            filterChangedHandler={this.onSubmitApplyFilter || undefined}
            enabledResetAll
            customClass="inheader"
          />
        )}
      </>
    )
  }
}
