import { Component } from 'react'
import { Breadcrumb, Row } from 'antd'
import { navigate } from '../shared/router'
import { truncateString } from '../shared/utils'

export type BreadcrumbsFragmentType = {
  label: string
  active?: boolean
  path?: string
}

export type BreadcrumbsType = {
  icon: any
  fragments: Array<BreadcrumbsFragmentType>
}

interface Props {
  breadcrumbs?: BreadcrumbsType
}

export class PageBread extends Component<Props> {
  private static getFragmentClassname(fragment: BreadcrumbsFragmentType) {
    let className = ''
    if (fragment.active) {
      className = 'active'
    } else if (fragment.path) {
      className = 'selectable'
    }
    return className
  }

  private static onFragmentClickHandler(fragment: BreadcrumbsFragmentType): void {
    if (fragment.path && !fragment.active) {
      navigate(fragment.path)
    }
  }

  render() {
    const { breadcrumbs } = this.props
    return (
      <Row className="stw-breadcrumbs-row">
        {breadcrumbs && breadcrumbs.icon && breadcrumbs.icon}{' '}
        <Breadcrumb
          items={breadcrumbs?.fragments.map((fragment, key) => ({
            key,
            title: fragment.active ? truncateString(fragment.label, 60) : fragment.label,
            onClick: () => PageBread.onFragmentClickHandler(fragment),
            className: PageBread.getFragmentClassname(fragment),
          }))}
        ></Breadcrumb>
      </Row>
    )
  }
}
