import { AdvancedTableFilterKind, AdvancedTableFiltersDef } from '../../../components/AdvancedTable'
import {
  AdvancedFormField,
  AdvancedFormFieldBuilder,
  AdvancedFormInputType,
} from '../../../components/AdvancedForm/advancedFormTypes'
import { __ } from '../../../shared/i18n'
import { DetailCell } from '../../../api'

export const CAPABILITY_GROUPS_FILTERS: AdvancedTableFiltersDef = [
  {
    key: 'equalCodes',
    label: __('fields.labels.code'),
    type: AdvancedTableFilterKind.STRING_LIST,
    column: 'code',
    col: 8,
  },
  {
    key: 'description',
    label: __('fields.labels.description'),
    type: AdvancedTableFilterKind.STRING,
    column: 'description',
    col: 8,
  },
  {
    key: 'featureType',
    label: __('fields.labels.featureType'),
    type: AdvancedTableFilterKind.ENUM,
    column: 'featureType',
    col: 8,
    select: {
      option: 'types',
    },
  },
  {
    key: 'creationDate',
    label: __('fields.labels.creationDate'),
    type: AdvancedTableFilterKind.DATE_RANGE,
    column: 'creationDate',
    col: 8,
  },
  {
    key: 'lastModifyDate',
    label: __('fields.labels.lastModifyDate'),
    type: AdvancedTableFilterKind.DATE_RANGE,
    column: 'lastModifyDate',
    col: 8,
  },
]

export const CAPABILITY_GROUPS_FIELDS: AdvancedFormField[] = [
  AdvancedFormFieldBuilder.buildText('description'),
  AdvancedFormFieldBuilder.buildText('code', [{ required: true }]),
  AdvancedFormFieldBuilder.buildSelect('featureType', 'featureType', [{ required: true }]),
  {
    key: 'capabilities',
    type: AdvancedFormInputType.OBJECT_LIST_WITH_CARDS,
    col: 24,
    placeholder: __('misc.select'),
    uniqueValueKey: 'id',
    sizeAutocomplete: 100,
    sorting: true,
    autocomplete: {
      endpoint: '/api/v1/capabilities',
      sort: 'code,asc',
    },
  },
]

export const CAPABILITY_GROUPS_DATA: DetailCell[] = [
  { title: 'id', attribute: 'id', columns: 24 },
  { title: 'code', attribute: 'code', columns: 12 },
  { title: 'description', attribute: 'description', columns: 12 },
  { title: 'featureType', attribute: 'featureType', columns: 12 },
  { title: 'Creation date', attribute: 'creationDate', type: 'date', columns: 12 },
  { title: 'Last modify date', attribute: 'lastModifyDate', type: 'date', columns: 12 },
]
