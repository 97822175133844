/* eslint-disable func-names */
import { Component } from 'react'
import { Form, AutoComplete, Typography } from 'antd'
import { __ } from '../../shared/i18n'
import { AdvancedFormField } from './advancedFormTypes'

interface Props {
  record: any
  item: AdvancedFormField
  handleChange: any
  options: any | null
  readonly: boolean
  labelHidden?: boolean
  help?: any
  defaultValue?: any
}

interface State {
  options: any
  filterOptions: any
}

const { Option } = AutoComplete
const { Text } = Typography

export class AdvancedFormSelectAutocompleteField extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      options: [],
      filterOptions: [],
    }
  }

  componentDidMount() {
    const { options } = this.props
    this.setState({ options: options, filterOptions: options })
  }

  shouldComponentUpdate = (nextProps) => {
    const { options } = this.state
    if (options.length < nextProps.options.length) {
      this.setState({ options: nextProps.options, filterOptions: nextProps.options })
    }
    return true
  }

  handleSearch = (value: string) => {
    const { options } = this.state
    const res: any[] = []
    for (let o = 0; o < options.length; o++) {
      if (options[o].description && options[o].description.indexOf(value) !== -1) {
        res.push(options[o])
      } else if (options[o].code && options[o].code.indexOf(value) !== -1) {
        res.push(options[o])
      }
    }
    this.setState({ filterOptions: res })
  }

  render() {
    const { item, handleChange, readonly, labelHidden, help, defaultValue } = this.props
    const { filterOptions } = this.state
    return (
      <>
        <Form.Item
          name={item.key.split('.')}
          className="stylewhere-form-item"
          rules={item.rules}
          help={help || null}
          label={labelHidden ? null : __(`fields.labels.${item.key}`, item.label)}
        >
          <AutoComplete
            allowClear
            disabled={readonly}
            style={{ width: '100%' }}
            placeholder={
              item.placeholder
                ? __(`fields.placeholders.${item.placeholder}` || item.placeholder)
                : __(`fields.placeholders.${item.key}`)
            }
            onChange={handleChange}
            onSearch={this.handleSearch}
            defaultValue={defaultValue}
          >
            {filterOptions &&
              filterOptions.map((option: any, p) => (
                <Option key={`option${p}`} value={option.id} className={option.message ? 'hasmessage' : ''}>
                  <Text style={{ fontWeight: 700, display: 'inline-block', width: '100%' }}>
                    {option.description || option.code}
                  </Text>
                  {option.message && (
                    <Text style={{ fontSize: 12, lineHeight: '16px', display: 'inline-block', width: '100%' }}>
                      {option.message}
                    </Text>
                  )}
                </Option>
              ))}
          </AutoComplete>
        </Form.Item>
      </>
    )
  }
}
