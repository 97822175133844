import { Component } from 'react'
import { Typography, Row, Input } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'
import QueueAnim from 'rc-queue-anim'
import { hasAnyCapabilities } from '../../config/utility/capabilityUtility'
import { navigate } from '../../shared/router'
import { SubmenuEntryType } from '../../types'
import { __ } from '../../shared/i18n'

interface Props {
  visible: boolean
  close: any
  menu: any
  subMenuSelected?: SubmenuEntryType
}

interface State {
  options: any
  filterOptions: any
  showResult: boolean
  maxHeight: number
  selected: string
}

const { Text } = Typography
const maxElement = 6
const searchInPath = false

export class ModalSearch extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      options: [],
      filterOptions: [],
      showResult: false,
      maxHeight: 500,
      selected: '',
    }
  }

  componentDidMount() {
    this._init()
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
    document.addEventListener('keyup', this.handleKeyboardClick, false)
  }

  handleResize = () => {
    const { innerHeight: height } = window
    const h = height - 160
    this.setState({ maxHeight: h })
  }

  _init = () => {
    const { menu } = this.props
    const tmp: any[] = []
    for (let m = 0; m < menu.length; m++) {
      if (!menu[m].notifications) {
        for (let s = 0; s < menu[m].submenus.length; s++) {
          if (
            !menu[m].submenus[s].unsearchable &&
            (!menu[m].submenus[s].capabilities || hasAnyCapabilities(menu[m].submenus[s].capabilities))
          ) {
            const obj = {
              id: `menu_${m}_${s}`,
              parent: menu[m].label,
              label: menu[m].submenus[s].label,
              path: menu[m].submenus[s].path,
              index: 0,
            }
            tmp.push(obj)
          }
        }
      } else {
        tmp.push({
          id: `menu_${m}`,
          label: menu[m].label,
          path: menu[m].path,
          index: 0,
        })
      }
    }
    this.setState({ showResult: true, options: tmp }, this.timerFocusInput)
  }

  shouldComponentUpdate = (nextProps) => {
    const { visible } = this.props
    if (nextProps.visible && nextProps.visible !== visible) {
      this.timerFocusInput()
    }
    return true
  }

  timerFocusInput = () => {
    this.search('')
    setTimeout(() => {
      this.focusInput()
    }, 150)
  }

  focusInput = () => {
    const selector = document.getElementById('search-key')
    if (selector) {
      selector.focus()
    }
  }

  handleChange = (event) => {
    this.search(event.target.value)
  }

  search = (value) => {
    const { options } = this.state
    const res: any[] = []
    let o = 0
    while (o < options.length) {
      if (value !== '') {
        if (searchInPath) {
          // if search enabled in path
          if (options[o].label && options[o].label.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
            options[o].index = options[o].label.toLowerCase().indexOf(value.toLowerCase())
            res.splice(0, 0, options[o])
          } else if (options[o].path && options[o].path.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
            options[o].index = options[o].label.toLowerCase().indexOf(value.toLowerCase()) + 100
            res.push(options[o])
          }
        } else if (options[o].label && options[o].label.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
          options[o].index = options[o].label.toLowerCase().indexOf(value.toLowerCase())
          res.push(options[o])
        }
      } else {
        res.push(options[o])
      }
      o++
    }

    // to limit the number of elements displayed to maxElement
    if (res.length > maxElement) {
      res.splice(maxElement, res.length - maxElement)
    }

    // to sort the results by the index obtained from the search
    res.sort(function compare(a, b) {
      return a.index - b.index
    })
    this.setState({ filterOptions: res, showResult: true, selected: res.length > 0 ? res[0].id : '' })
  }

  onMouseEnter = (event, id) => {
    this.setState({ selected: id })
  }

  onMouseLeave = () => {
    const { filterOptions } = this.state
    this.setState({ selected: filterOptions.length > 0 ? filterOptions[0].id : '' })
  }

  handleKeyboardClick = (event) => {
    const { showResult, selected } = this.state
    const { visible } = this.props
    if (visible) {
      const tmp = this.state.filterOptions
      if (showResult && tmp.length > 0) {
        const index = tmp.findIndex((e) => e.id === selected)
        if (event.keyCode === 40) {
          // arrow down
          if (index === -1) {
            this.setState({ selected: tmp[0].id })
          } else if (index < tmp.length - 1) {
            this.setState({ selected: tmp[index + 1].id })
          }
        } else if (event.keyCode === 38 && index > 0) {
          this.setState({ selected: tmp[index - 1].id })
        } else if (event.keyCode === 13 && selected !== '') {
          const sel = tmp.find((e) => e.id === selected)
          if (sel) {
            this.handleSelect(sel.path)
          }
        }
      }
    }
  }

  handleSelect = (path: string) => {
    const { close, subMenuSelected } = this.props
    const currentPage = subMenuSelected && subMenuSelected.path && subMenuSelected.path === path
    close()
    if (!currentPage) {
      navigate(path)
    }
  }

  render() {
    const { visible, close } = this.props
    const { filterOptions, showResult, maxHeight, selected } = this.state
    return (
      <>
        <QueueAnim duration={200} type="alpha">
          {visible ? [<Row key="1" className="stw-omni-search-layer" onClick={() => close()} />] : null}
        </QueueAnim>
        <QueueAnim duration={200} type="top">
          {visible
            ? [
                <div key="2" className="stw-omni-search-input">
                  <div className={`stw-omni-search-input-box${showResult ? ' results' : ''}`}>
                    <Input id="search-key" onChange={(e) => this.handleChange(e)} placeholder="Search" />
                  </div>
                  {showResult && (
                    <div
                      onMouseLeave={() => this.onMouseLeave()}
                      className="stw-omni-search-results"
                      style={{ maxHeight: maxHeight }}
                    >
                      {filterOptions.length > 0 ? (
                        <>
                          {filterOptions.map((option: any, p) => (
                            <Row
                              onClick={() => this.handleSelect(option.path)}
                              key={`menu_${p}`}
                              className={`stw-omni-search-result${option.id === selected ? ' active' : ''}`}
                              onMouseEnter={(event) => this.onMouseEnter(event, option.id)}
                            >
                              <div className="stw-omni-search-result-content">
                                <Text>{option.label}</Text>
                                <Text className="path">{option.path}</Text>
                              </div>
                              <ArrowRightOutlined />
                            </Row>
                          ))}
                        </>
                      ) : (
                        <div className="stw-omni-search-result">
                          <div className="stw-omni-search-result-content">
                            <Text>{__('misc.noSearchMatch')}</Text>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>,
              ]
            : null}
        </QueueAnim>
      </>
    )
  }
}
