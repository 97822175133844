import { Component } from 'react'
import { DefaultReportDetail } from '../../../components'
import { ExtendRouteComponentProps } from '../../../types'

class ProductionOrderRowDetail extends Component<ExtendRouteComponentProps> {
  render() {
    const { match } = this.props
    return <DefaultReportDetail {...this.props} reportId="productionorderrows" reportInstanceId={match.params.itemId} />
  }
}

export default ProductionOrderRowDetail
