import { Component } from 'react'
import { Button } from 'antd'

export interface Tab {
  value: any
  label: string
  iconRender?: any
  type?: string
}

interface Props {
  tabs: Tab[]
  selected: any
  autoselect?: boolean
  onChangeTab: (value: any) => void
  customClassName?: string
  returnObject?: boolean
}

interface State {
  selected: any
}

export class TabBar extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      selected: null,
    }
  }

  componentDidMount() {
    const { selected, autoselect, onChangeTab, tabs, returnObject } = this.props
    if (selected) {
      this.refreshSelected()
    } else if (autoselect) {
      onChangeTab(returnObject ? tabs[0] : tabs[0].value)
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const { selected } = this.props
    if (selected && selected !== prevProps.selected) {
      this.refreshSelected()
    }
  }

  refreshSelected() {
    this.setState({ selected: this.props.selected })
  }

  render() {
    const { tabs, onChangeTab, customClassName, returnObject } = this.props
    const { selected } = this.state
    return (
      <>
        {tabs.length > 0 && (
          <div className={`stylewhere-tab-page stylewhere-tab-bar ${customClassName || ''}`}>
            {tabs.map((tab, i) => {
              return (
                <span key={`tab-${i}`}>
                  <Button
                    title={tab.label}
                    onClick={() => onChangeTab(returnObject ? tab : tab.value)}
                    className={`ant-page-header-tab ${tab.value === selected ? 'selected' : ''}`}
                  >
                    {tab.iconRender && tab.iconRender()}
                    {tab.label}
                  </Button>
                </span>
              )
            })}
          </div>
        )}
      </>
    )
  }
}
