import { Component } from 'react'
import { Button, Typography } from 'antd'
import { EditOutlined, EyeOutlined } from '@ant-design/icons'
import {
  GridData,
  StylewherePage,
  Section,
  DefaultHeader,
  NotFound,
  ModalValueDetail,
  ModalValueForm,
  AdvancedTableRowColumn,
  TableList,
} from '../../../../components'
import EnumerationTypes from '../../../../api/EnumerationTypes'
import EnumerationValues from '../../../../api/EnumerationValues'
import { StwEnumerationTypes, StwEnumerationValues } from '../../../../api'
import { __, T } from '../../../../shared/i18n'
import {
  AdvancedTableConfig,
  AdvancedTableContentKind,
  AdvancedTableColumn,
} from '../../../../components/AdvancedTable'
import { ExtendRouteComponentProps } from '../../../../types'
import { TYPES_DETAIL } from '../../../../config/Pages/Settings/EnumerationTypes'
import { VALUES_FILTERS } from '../../../../config/Pages/Settings/EnumerationValues'
import { CAPABILITIES, hasAnyCapabilities } from '../../../../config/utility/capabilityUtility'
import { getMultipleTitle, getBackURL, getFragmentObject } from '../../../../shared/utils'

interface State {
  type: StwEnumerationTypes | undefined
  loader: boolean
  valueDetailVisible: boolean
  valueDetailObject: StwEnumerationValues | undefined
  formValueVisible: boolean
  formValueObject: StwEnumerationValues | undefined
  tableValueConfig: AdvancedTableConfig
  forceDataUpdate: number
}

const { Text } = Typography

class EnumerationTypesDetail extends Component<ExtendRouteComponentProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      type: undefined,
      loader: true,
      valueDetailVisible: false,
      valueDetailObject: undefined,
      formValueVisible: false,
      formValueObject: undefined,
      tableValueConfig: {
        filterDefinitions: VALUES_FILTERS,
      },
      forceDataUpdate: 0,
    }
  }

  getValueColumns = (): AdvancedTableColumn[] => {
    return [
      {
        title: __(`fields.labels.code`),
        key: 'code',
        dataIndex: 'code',
      },
      {
        title: __(`fields.labels.description`),
        key: 'description',
        dataIndex: 'description',
        className: 'stw-extra-medium',
      },
      {
        key: 'attributes',
        title: __(`fields.labels.attributes.title`),
        dataIndex: 'attributes',
        className: 'stw-extra-medium',
        type: AdvancedTableContentKind.ATTRIBUTES,
      },
      {
        key: 'creationDate',
        title: __(`fields.labels.creationDate`),
        dataIndex: 'creationDate',
        type: AdvancedTableContentKind.DATE_TIME,
      },
      {
        key: 'lastModifyDate',
        title: __(`fields.labels.lastModifyDate`),
        dataIndex: 'lastModifyDate',
        type: AdvancedTableContentKind.DATE_TIME,
      },
      {
        key: 'action',
        className: 'stw-small',
        fixedType: 'right',
        sortable: false,
        unmanageable: true,
        render: (text, record) => (
          <AdvancedTableRowColumn
            actions={[
              { content: <EyeOutlined />, type: 'default', onclick: () => this.openDetailValue(record) },
              hasAnyCapabilities(CAPABILITIES.RESOURCE_ENUMERATION_VALUE_EDIT) && {
                content: <EditOutlined />,
                type: 'default',
                onclick: () => this.editValue(record),
              },
            ]}
          />
        ),
      },
    ]
  }

  async componentDidMount() {
    const { match } = this.props
    const typeObj = await EnumerationTypes.get<StwEnumerationTypes>(match.params.typeId).then((res) => res)
    this.setState({
      type: typeObj || undefined,
      loader: false,
      tableValueConfig: {
        columns: this.getValueColumns(),
        filterDefinitions: VALUES_FILTERS,
        filterParameters: {},
        activeDefaultClassColumn: true,
        fixedTableHeight: true,
        disableLocation: true,
        disableColumnFilters: true,
        skeletonOptions: {},
      },
    })
  }

  openDetailValue = (record) => {
    this.setState({ valueDetailVisible: true, valueDetailObject: record })
  }

  closeDetailValue = () => {
    this.setState({ valueDetailVisible: false })
  }

  getValue = (record) => {
    const { type } = this.state
    const attributes = {}
    let k = 0
    if (type && type.attributeKeys) {
      for (k = 0; k < type.attributeKeys.length; k++) {
        attributes[type.attributeKeys[k]] =
          record && record.attributes[type.attributeKeys[k]] ? record.attributes[type.attributeKeys[k]] : ''
      }
    }

    if (record && record.attributes) {
      const keys = Object.keys(record.attributes)
      for (k = 0; k < keys.length; k++) {
        if (attributes[keys[k]] === undefined) {
          attributes[keys[k]] = record.attributes[keys[k]]
        }
      }
    }
    const init: StwEnumerationValues = {
      code: record ? record.code : '',
      description: record && record.description ? record.description : '',
      enumerationType: type || undefined,
      attributes: attributes,
    }
    if (record) init.id = record.id
    return init
  }

  addValue = () => {
    this.setState({ formValueVisible: true, formValueObject: this.getValue(undefined) })
  }

  editValue = (record) => {
    this.setState({ formValueVisible: true, formValueObject: this.getValue(record) })
  }

  saveValue = (value: StwEnumerationValues) => {
    if (value.id && value.id !== '') {
      EnumerationValues.update<StwEnumerationValues>(value).then(() => {
        this.setState({ formValueVisible: false, forceDataUpdate: this.state.forceDataUpdate + 1 }, this.reload)
      })
    } else {
      EnumerationValues.insert<StwEnumerationValues>(value).then(() => {
        this.setState({ formValueVisible: false, forceDataUpdate: this.state.forceDataUpdate + 1 }, this.reload)
      })
    }
  }

  reload = () => {
    this.resetData()
  }

  closeFormValue = () => {
    this.setState({ formValueVisible: false }, this.resetData)
  }

  resetData = () => {
    setTimeout(() => {
      this.destroy()
    }, 500)
  }

  destroy = () => {
    this.setState({ formValueObject: undefined })
  }

  render() {
    const { breadcrumbs, queryString } = this.props
    const {
      type,
      loader,
      forceDataUpdate,
      formValueObject,
      formValueVisible,
      valueDetailObject,
      valueDetailVisible,
      tableValueConfig,
    } = this.state
    const fragment = getFragmentObject(type, 'code')
    return (
      <StylewherePage {...this.props} noOverflow fragment={fragment}>
        <DefaultHeader
          backPath={getBackURL(queryString, breadcrumbs)}
          multipleTitle={getMultipleTitle(type)}
          title={__(T.misc.not_found)}
          skeleton={{ active: loader, options: { multiple: true } }}
        />
        <Section customClass={`stw-section-page paged-header ${type || loader ? 'scroll transparent' : ''}`}>
          {type || loader ? (
            <>
              <GridData fields={TYPES_DETAIL} record={type || {}} skeleton={loader} />
              {type && (
                <TableList
                  title={__(T.misc.value)}
                  location={this.props.location}
                  headerType="boxed"
                  config={tableValueConfig}
                  forceDataUpdate={forceDataUpdate}
                  actions={[
                    <Button className="stylewhere-button-primary" onClick={() => this.addValue()}>
                      <Text style={{ color: '#333' }}>{__(T.misc.createValue)}</Text>
                    </Button>,
                  ]}
                  resource={{
                    call: EnumerationValues.searchTableList,
                    endpoint: EnumerationValues.endpoint,
                    filters: { enumerationTypeIds: [type.id] },
                  }}
                />
              )}
            </>
          ) : (
            <NotFound fullheight title={__(T.misc.noRecordFoundTitle)} />
          )}
        </Section>
        {type && (
          <ModalValueDetail value={valueDetailObject} visible={valueDetailVisible} close={this.closeDetailValue} />
        )}
        {formValueObject && (
          <ModalValueForm
            value={formValueObject}
            save={this.saveValue}
            visible={formValueVisible}
            close={this.closeFormValue}
          />
        )}
      </StylewherePage>
    )
  }
}

export default EnumerationTypesDetail
